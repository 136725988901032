import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { useDispatch } from 'react-redux'
import { removeBillingContractSchema } from '../../../../../redux/Company/actions'
import { ConfirmModal } from './../../../../ConfirmModal'
import { formattedDate, formattedNumberRub } from '../../../../../utils'
import { Icon, UI } from '../../../../../layout'

const styles = {
  root: {
    marginTop: 40,
    position: 'relative',
  },
  title: {
    paddingRight: 300,
    color: '#504E5B',
    fontSize: 15,
    lineHeight: 1.47,
  },
  cards: {
    marginTop: 15,
  },
  card: {
    background: 'rgba(221, 221, 221, 0.7)',
    width: 259,
    height: 150,
    marginBottom: 25,
    padding: 20,
    borderRadius: 16,
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: 20,
    },
  },
  value: {
    color: '#000',
    fontSize: 36,
    lineHeight: 1.5,
    fontWeight: 'bold',
  },
  key: {
    color: '#4A4A4A',
    fontSize: 14,
    lineHeight: 1.5,
  },
  wrapper: {
    '& + &': {
      marginTop: 25,
      paddingTop: 25,
      borderTop: [1, 'solid', '#ddd'],
    },
  },
  rates: {
    marginTop: 15,
  },
  rate: {
    color: '#212529',
    fontSize: 14,
    lineHeight: 1.71,
    '& span': {
      color: '#3DD2CC',
      fontWeight: 'bold',
    },
  },
  actions: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  edit: {
    width: 18,
    height: 20,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      fill: '#4A4A4A',
      display: 'block',
    },
  },
  remove: {
    ...UI.removeButton,
    marginLeft: 20,
    transform: 'translateY(-9px)',
  },
}

const Schema = ({
  classes,
  companyId,
  number,
  schema = {}, 
  onEditSchema,
}) => {
  const dispatch = useDispatch()

  const {
    schemaId,
    validFrom,
    validTo,
    excessRate,
    decreaseRate,
    includeVAT,
    standardRates, 
    totalRates, 
    totalRatesByAmount,
    totalDebtRatesByAmount,
    decreaseRates,
  } = schema

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const onOpenConfirmModal = () => setShowConfirmModal(true)
  const onCloseConfirmModal = () => setShowConfirmModal(false)

  const onRemoveSchema = () => dispatch(removeBillingContractSchema({ companyId, number, schemaId }))

  const cards = [
    {
      key: 'Доля агента <br/> при повышении',
      value: excessRate ? `${excessRate}%` : '-',
    },
    {
      key: 'Компенсация агентом <br/> за понижение',
      value: decreaseRate ? `${decreaseRate}%` : '-',
    },
    {
      key: 'Включая <br/> НДС',
      value: includeVAT ? 'да' : 'нет',
    },
  ]

  const rates = [
    {
      title: 'Стандартные ставки по конкретной гарантии',
      items: standardRates,
      getValue: ({ fromAmount, rate }) => 
        `Сумма гарантии равна или выше <span>${formattedNumberRub(fromAmount)} руб</span> ставка <span>${rate}%</span>`,
    },
    {
      title: 'Стандартные ставки (накопительно)',
      items: totalRates,
      getValue: ({ fromCount, rate }) => 
        `Кол-во равно или выше <span>${fromCount} шт</span> ставка <span>${rate}%</span>`,
    },
    {
      title: 'Стандартные ставки (накопительно, руб)',
      items: totalRatesByAmount,
      getValue: ({ fromAmount, rate }) => 
        `Сумма сделок по БГ равна или выше <span>${formattedNumberRub(fromAmount)} руб</span> ставка <span>${rate}%</span>`,
    },
    {
      title: 'Стоимость снижения комиссии',
      items: decreaseRates,
      getValue: ({ fromPercent, rate }) => 
        `Снижение комиссии выше <span>${fromPercent}%</span> компенсация агентом за понижение <span>${rate}%</span>`,
    },
    {
      title: 'Стандартные ставки (от суммы задолжности на одного клиента)',
      items: totalDebtRatesByAmount,
      getValue: ({ fromAmount, rate }) => 
        `Задолженность на одного клиента равна или выше <span>${formattedNumberRub(fromAmount)} руб</span> ставка <span>${rate}%</span>`,
    },
  ]

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        Схема №{schemaId}
        {' '} 
        (с {formattedDate(validFrom)} по {formattedDate(validTo)})
      </div>
      <div className={classes.actions}>
        <div className={classes.edit} onClick={() => onEditSchema(schema)}>
          <Icon iconName='edit' />
        </div>
        <div className={classes.remove} onClick={onOpenConfirmModal}>Удалить</div>
      </div>
      <div className={classes.cards}>
        {cards.map(({ key, value}, index) =>
          <div className={classes.card} key={`card-${index}`}>
            <div className={classes.value}>{value}</div>
            <div className={classes.key} dangerouslySetInnerHTML={{ __html: key }} />
          </div>
        )}
      </div>
      {rates.map(({ title, items, getValue }, index) =>
        <div className={classes.wrapper} key={`wrapper-${index}`}>
          <div className={classes.title}>{title}</div>
          <div className={classes.rates}>
            {items && items.length
              ? items.map(({ fromAmount, fromCount, fromPercent, rate }, subIndex) =>
                  <div 
                    className={classes.rate} 
                    key={`rate-${index}-${subIndex}`}
                    dangerouslySetInnerHTML={{ 
                      __html: getValue({ fromAmount, fromCount, fromPercent, rate }), 
                    }}
                  />
                )
              : <div className={classes.rate}>-</div>
            }
          </div>
        </div>
      )}
      {showConfirmModal &&
        <ConfirmModal
          title={`Вы действительно хотите удалить схему №${schemaId}?`}
          submitTitle='Удалить'
          onCancel={onCloseConfirmModal}
          onSubmit={onRemoveSchema}
        />
      }
    </div>
  )
}

Schema.propTypes = {
  companyId: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  onEditSchema: PropTypes.func.isRequired,
}

export default injectSheet(styles)(Schema)