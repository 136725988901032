import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
  root: {
    color: '#59A2F5',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 500,
    display: 'inline-block',
    cursor: 'pointer',
    '&.active': {
      fontWeight: 'bold',
    },
  },
}

const Url = ({ 
  children, 
  active, 
  onClick, 
  classes,
  style,
}) =>
  <div
    className={classnames(classes.root, { active })}
    style={{ color: style.color }}
    onClick={onClick}
  >
    {children}
  </div>

export default injectSheet(styles)(Url)