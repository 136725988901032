import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
  wrapper: {
    background: '#f4f4f4',
    marginTop: 10,
    padding: [18, 70, 18, 74],
    borderRadius: 16,
    position: 'relative',
  },
}

const Wrapper = ({ 
  children, 
  className,
  classes, 
}) =>
  <div className={classnames(classes.wrapper, className)}>{children}</div>

export default injectSheet(styles)(Wrapper)