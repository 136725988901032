import * as actionTypes from './actionTypes'

const initialState = {
  contracts: [],
  filters: {},
  isError: false,
  isFetching: false,
  contractType: {},
  contractStatus: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTRACTS:
      return {
        ...state,
        isError: false,
        isFetching: true
      }
    case actionTypes.GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: action.data,
        isError: false,
        isFetching: false
      }
    case actionTypes.GET_CONTRACTS_ERROR:
      return {
        ...state,
        isError: true,
        isFetching: false
      }
    case actionTypes.GET_CONTRACT_TYPE:
      return {
        ...state,
        isError: false,
        isFetching: true
      }
    case actionTypes.GET_CONTRACT_TYPE_SUCCESS:
      return {
        ...state,
        contractType: action.data,
        isError: false,
        isFetching: false
      }
    case actionTypes.GET_CONTRACT_TYPE_ERROR:
      return {
        ...state,
        isError: true,
        isFetching: false
      }
    case actionTypes.GET_CONTRACT_STATUS:
      return {
        ...state,
        isError: false,
        isFetching: true
      }
    case actionTypes.GET_CONTRACT_STATUS_SUCCESS:
      return {
        ...state,
        contractStatus: action.data,
        isError: false,
        isFetching: false
      }
    case actionTypes.GET_CONTRACT_STATUS_ERROR:
      return {
        ...state,
        isError: true,
        isFetching: false
      }
    default:
      return state
  }
}