import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import { startFactoringProcessSupplyUpdate } from '../../../../redux/Factoring/actions'
import { InputText, Fieldset, RowGrid, Col12, Modal2, Checkbox, Radio } from '../../../../layout'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'
import { YYYYMMDD } from '../../../../utils'

const useStyles = createUseStyles({
  supplyOperationForm: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
})

const originalsOptions = [
  { value: true, label: 'Оригиналы документов' },
  { value: false, label: 'Копии документов' },
]

export const SupplyOperationForm = ({
  selected,
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [updateDate, setUpdateDate] = useState(YYYYMMDD(new Date()))
  const [deferDate, setDeferDate] = useState('')
  const [waitingDate, setWaitingDate] = useState('')
  const [adjustmentAmount, setAdjustmentAmount] = useState('')
  const [calcAdjustment, setCalcAdjustment] = useState(false)
  const [updateDeferDate, setUpdateDeferDate] = useState(false)
  const [updateWaitingDate, setUpdateWaitingDate] = useState(false)
  const [closeByLetter, setCloseByLetter] = useState(false)
  const [recalcCommission, setRecalcCommission] = useState(false)
  const [recalcDates, setRecalcDates] = useState(false)
  const [hasOriginals, setHasOriginals] = useState(null)
  const [submitted, setSubmitted] = useState(0)

  const touched = useMemo(() => submitted > 0, [submitted])

  const payload = {
    supplies: selected?.map(item => ({
      supplyId: item,
      deferDate: deferDate || null,
      waitingDate: waitingDate || null,
      adjustmentAmount: adjustmentAmount || null,
      updateDate,
      updateDeferDate,
      updateWaitingDate,
      calcAdjustment,
      recalcDates,
      closeByLetter,
      recalcCommission,
      hasOriginals,
    }))
  }

  const errors = {
    ...(updateDate?.trim() ? {} : { updateDate: 'Не указана дата изменения' }),
    ...(updateDeferDate && !deferDate?.trim() ? { deferDate: 'Не указана дата отсрочки' } : {}),
    ...(updateWaitingDate && !waitingDate?.trim() ? { waitingDate: 'Не указана дата ожидания' } : {}),
    ...(calcAdjustment && !adjustmentAmount?.trim() ? { adjustmentAmount: 'Не указана сумма' } : {}),
  }

  const isValid = Object.keys(errors)?.length === 0

  const onStartFactoringProcessSupplyUpdate = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(startFactoringProcessSupplyUpdate(payload, 'factoring-pa-supply-update-init', onSuccess))
    }
  }

  useEffect(() => {
    if (!calcAdjustment) {
      setAdjustmentAmount('')
    }
  }, [calcAdjustment])

  useEffect(() => {
    if (!updateDeferDate) {
      setDeferDate('')
    }
  }, [updateDeferDate])

  useEffect(() => {
    if (!updateWaitingDate) {
      setWaitingDate('')
    }
  }, [updateWaitingDate])

  useEffect(() => {
    if (touched) {
      onStartFactoringProcessSupplyUpdate()
    }
  // eslint-disable-next-line
  }, [touched, submitted])

  console.log('payload:', payload)
  console.log('errors:', errors)

  return (
    <Modal2 className={classes.supplyOperationForm} onClose={onClose}>
      <div className={classes.wrapper}>
        <H3>Операции по поставкам</H3>
        <Form>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='date' 
                  name='updateDate'
                  label='Дата изменения'
                  value={updateDate}
                  onChange={e => setUpdateDate(e.target.value)}
                  error={touched && errors?.updateDate}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Checkbox
                  id='updateDeferDate'
                  name='updateDeferDate'
                  label='Изменить дату отсрочки'
                  checked={updateDeferDate}
                  onChange={e => setUpdateDeferDate(e.target.checked)}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          {updateDeferDate &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <InputText
                    type='date' 
                    name='deferDate'
                    label='Дата отсрочки'
                    value={deferDate}
                    onChange={e => setDeferDate(e.target.value)}
                    error={touched && errors?.deferDate}
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          <Fieldset>
            <RowGrid>
              <Col12>
                <Checkbox
                  id='updateWaitingDate'
                  name='updateWaitingDate'
                  label='Изменить дату ожидания'
                  checked={updateWaitingDate}
                  onChange={e => setUpdateWaitingDate(e.target.checked)}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          {updateWaitingDate &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <InputText
                    type='date' 
                    name='waitingDate'
                    label='Дата ожидания'
                    value={waitingDate}
                    onChange={e => setWaitingDate(e.target.value)}
                    error={touched && errors?.waitingDate}
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          <Fieldset>
            <RowGrid>
              <Col12>
                <Checkbox
                  id='calcAdjustment'
                  name='calcAdjustment'
                  label='Корректировка'
                  checked={calcAdjustment}
                  onChange={e => setCalcAdjustment(e.target.checked)}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          {calcAdjustment &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <InputText
                    type='text' 
                    name='adjustmentAmount'
                    label='Сумма'
                    value={adjustmentAmount}
                    onChange={e => setAdjustmentAmount(e.target.value)}
                    error={touched && errors?.adjustmentAmount}
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          <Fieldset>
            <RowGrid>
              <Col12>
                <Checkbox
                  id='closeByLetter'
                  name='closeByLetter'
                  label='Закрытие по письму'
                  checked={closeByLetter}
                  onChange={e => setCloseByLetter(e.target.checked)}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Checkbox
                  id='recalcCommission'
                  name='recalcCommission'
                  label='Пересчет комиссий'
                  checked={recalcCommission}
                  onChange={e => setRecalcCommission(e.target.checked)}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Checkbox
                  id='recalcDates'
                  name='recalcDates'
                  label='Актуализация дат'
                  checked={recalcDates}
                  onChange={e => setRecalcDates(e.target.checked)}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                {originalsOptions.map(({ value, label }, index) =>
                  <Radio
                    key={index}
                    id={`hasOriginals-${value}`}
                    name='hasOriginals'
                    label={label}
                    value={value}
                    checked={hasOriginals === value}
                    onChange={() => setHasOriginals(value)}
                  />
                )}
              </Col12>
            </RowGrid>
          </Fieldset>
        </Form>
        <ButtonWrapper>
          <Button
            disabled={touched && !isValid}
            onClick={() => setSubmitted(submitted + 1)}
          >
            Создать
          </Button>
        </ButtonWrapper>
      </div>
    </Modal2>
  )
}