import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { getPrintGuaranteeDocumentUrls, completePrintGuarantee } from '../../services/api/task'
import { setErrorContent } from '../../redux/Error/actions'
import Overlay from '../Overlay'
import { getSelectedTasks } from '../../redux/Tasks/selectors'

export class PrintGuarantee extends Component {
  state = {
    loading: false,
  }

  async componentWillMount() {
    const { dispatch, selectedTasks } = this.props
    
    const ids = selectedTasks
      .filter(item => item && item.bulkActions && item.bulkActions.includes('printGuarantee'))
      .map(item => item.id)
    
    try {
      this.setState({ loading: true })
      const { isSuccess, ...data } = await getPrintGuaranteeDocumentUrls(ids)
      if (!isSuccess) {
        dispatch(setErrorContent(`Код ошибки: ${data.error_code}`))
      }
      const { guarantee_url, dhl_url } = data
      this.setState({
        loading: false, 
        ...data, 
        activeUrl: guarantee_url,
        dhl_url,
      })
    } 
    catch(err) {
      dispatch(setErrorContent(err.message))
    }
  }

  onSubmit = async () => {
    const { onSuccess, dispatch, selectedTasks } = this.props
    
    const ids = selectedTasks
      .filter(item => item && item.bulkActions && item.bulkActions.includes('printGuarantee'))
      .map(item => item.id)

    try {
      this.setState({ loading: true })
      const { isSuccess, ...data } = await completePrintGuarantee(ids)
      if (!isSuccess) {
        dispatch(setErrorContent(`Ошибка: ${data.message}`))
      }
    }
    catch(err) {
      dispatch(setErrorContent(err.message))
    }
    finally {
      this.setState({ loading: false })
      if (onSuccess) {
        onSuccess()
      }
    }
  }

  onCancel = () => {
    if (this.props.onCancel) { 
      this.props.onCancel()
    }
  }

  setActiveUrl(url) {
    this.setState({ activeUrl: url })
  }

  render() {
    const { 
      loading,  
      guarantee_url, 
      dhl_url, 
      activeUrl,
    } = this.state

    return (
      <Fragment>
        <div className='modal-content__header'>
          <div className='modal-content__title modal-content__title--task'>
            <span className='icon icon-ok'/>
            Печать гарантий
          </div>
        </div>
        <div className='modal-content__body modal-content__body--print-guarantee'>
          {loading 
            ? <Overlay>Загрузка данных</Overlay>
            : <div className='print-guarantee'>
                <div className='form-row'>
                  <div className='col'>
                    <div className='form-group'>
                      <ul className='nav nav-tabs'>
                        <li className='nav-item'>
                          <span 
                            className={classnames('nav-link', {
                              'active': guarantee_url === activeUrl,
                            })} 
                            onClick={this.setActiveUrl.bind(this, guarantee_url)}
                          >
                            Гарантия
                          </span>
                        </li>
                        {dhl_url &&
                          <li className='nav-item'>
                            <span 
                              className={classnames('nav-link', {
                                'active': dhl_url === activeUrl,
                              })} 
                              onClick={this.setActiveUrl.bind(this, dhl_url)}
                            >
                              DHL
                            </span>
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='col'>
                    <div className='form-group'>
                      <a 
                        className='btn-form btn-form--none' 
                        href={activeUrl} 
                        target='_blank' 
                        download={guarantee_url === activeUrl ? 'guarantee.pdf' : 'dhl.pdf'} 
                        rel='noopener noreferrer'
                      >
                        Скачать PDF
                      </a>
                    </div>
                  </div>
                </div>
                <div className='pdf-viewer-wrapper'>
                  <object 
                    width='100%' 
                    height='100%'
                    ng-if='documentSrc' 
                    data={`${activeUrl}#scrollbar=true&toolbar=true&navpanes=true&view=FitH,top`} 
                    type='application/pdf' 
                  >
                    <p>
                      Браузер не поддерживает отображенияе PDF документов. 
                      Пожалуйста скачайте PDF документ для его просмотра: 
                      <a href={activeUrl}>Скачать документ</a>
                    </p>
                  </object>
                </div>
              </div>
          }
        </div>
        <div className='modal-content__footer'>
          <button className='btn-form btn-form--none' onClick={this.onCancel}>Отменить</button>
          <button className='btn-form btn-form--ok' onClick={this.onSubmit} disabled={loading}>Распечатано</button>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ Bulk, Tasks }) => {
  return {
    selectedItems: Object.keys(Bulk.selectedItems),
    selectedTasks: getSelectedTasks(Tasks),
  }
}

export default connect(mapStateToProps)(PrintGuarantee)