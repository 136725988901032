import React, { useState } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { useDispatch } from 'react-redux'
import { getFile } from '../../../../redux/Files/actions'
import Dropdown from '../../../Dropdown'
import { UI } from './../../../../layout'
import { API_URL, IS_PASSIONFRUIT, } from './../../../../config'

const styles = {
  root: {
    background: '#EBFAF9',
    marginTop: 30,
    padding: [20, 30],
    borderRadius: 16,
  },
  actions: {
    marginTop: 20,
  },
  dropdown: {
    marginLeft: 0,
    marginRight: 0,
    '& + &': {
      marginTop: 10,
    },
  },
  item: {
    width: '33.33%',
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      paddingLeft: 20,
    },
  },
  label: {
    paddingBottom: 4,
    color: '#000',
    fontSize: 14,
    lineHeight: 1.21,
    fontWeight: 500,
  },
  title: UI.title,
  add: {
    ...UI.addButton,
    width: '100%',
  },
}

const months = [
  { id: '01', name: 'Январь' },
  { id: '02', name: 'Февраль' },
  { id: '03', name: 'Март' },
  { id: '04', name: 'Апрель' },
  { id: '05', name: 'Май' },
  { id: '06', name: 'Июнь' },
  { id: '07', name: 'Июль' },
  { id: '08', name: 'Август' },
  { id: '09', name: 'Сентябрь' },
  { id: '10', name: 'Октябрь' },
  { id: '11', name: 'Ноябрь' },
  { id: '12', name: 'Декабрь' },
]

const currentYear = new Date().getFullYear()

const years = [
  { id: currentYear, name: currentYear },
  { id: currentYear - 1, name: currentYear - 1 },
  { id: currentYear - 2, name: currentYear - 2 },
  { id: currentYear - 3, name: currentYear - 3 },
  { id: currentYear - 4, name: currentYear - 4 },
  { id: currentYear - 5, name: currentYear - 5 },
  { id: currentYear - 6, name: currentYear - 6 },
  { id: currentYear - 7, name: currentYear - 7 },
  { id: currentYear - 8, name: currentYear - 8 },
  { id: currentYear - 9, name: currentYear - 9 },
  { id: currentYear - 10, name: currentYear - 10 },
]

const Report = ({
  classes,
  companyId,
  link,
}) => {
  const dispatch = useDispatch()
  const [month, setMonth] = useState(months[0].id)
  const [year, setYear] = useState(years[0].id)

  const onChangeMonth = (e, id) => setMonth(id)
  const onChangeYear = (e, id) => setYear(id)
  const templateExtension = IS_PASSIONFRUIT ? 'docx' : 'xlsx'

  const onGetFile = ({ year, month, name }) => {
    const baseUrl = link || `${API_URL}/doc-template/form/bgAgentAct/bg_agentAct.${templateExtension}`
    const url = `${baseUrl}?companyId=${companyId}&period=${year}-${month}`
    dispatch(getFile({ url, name }))
  }

  const valid = year && month

  return (
    <>
      <div className={classes.root}>
        <div className={classes.title}>Сформировать отчёт</div>
        <div className={classes.actions}>
          <div className={classes.item}>
            <div className={classes.label}>Год</div>
            <Dropdown 
              name='year'
              className={classes.dropdown} 
              list={years} 
              onSelectItem={onChangeYear}
            />
          </div>
          <div className={classes.item}>
            <div className={classes.label}>Месяц</div>
            <Dropdown 
              name='month' 
              className={classes.dropdown}
              list={months} 
              onSelectItem={onChangeMonth}
            />
          </div>
          <div className={classes.item}>
            <div className={classes.label}>&nbsp;</div>
              <button 
                type='button'
                className={classnames(classes.add, 'btn-form--ok')}
                onClick={() => valid && onGetFile({
                  year,
                  month,
                  name: `bg_agentAct-${year}-${month}.${templateExtension}`,
                })}
                disabled={!valid}
              >
                Сформировать отчёт
              </button>
          </div>
        </div>
      </div>
      <hr />
    </>
  )
}

export default injectSheet(styles)(Report)