import React from 'react'
import { Link } from 'react-router-dom'
import { useStyles } from '../../Layout/DetailsTable/styles'

export const FundsDetailsItem = ({
  data: {
    orderAmount,
    orderCreatedDate,
    principalDisplayName,
    orderID,
    principalINN,
    orderNumber,
    principalCompanyId,
    orderRequiredExecutionDate,
    taskCandidateGroup,
    taskStartDate,
  },
  onClick,
}) => {
  const classes = useStyles()
  return (
    <tr>
      <td style={{ width: '28%' }} className={classes.tdLeft}>
        <div className={classes.tableText}>
          <h5 className={classes.tableTextMajor}>{taskCandidateGroup[0]}</h5>
          <p className={classes.tableTextMinor}>Открыта {taskStartDate}</p>
        </div>
      </td>
      <td style={{ width: '15%' }} className={classes.tdLeft}>
        <div className={classes.tableText}>
          <h5 className={classes.tableTextMajor}>
            <Link to={`/orders/${orderID}`} onClick={onClick}>
              {orderNumber}
            </Link>
          </h5>
          <p>{`от ${orderCreatedDate}`}</p>
        </div>
      </td>
      <td style={{ width: '32%' }} className={classes.tdLeft}>
        <div className={classes.tableText}>
          <h5 className={classes.tableTextMajor}>
            <Link
              to={`/orders?client-deal=${principalCompanyId}&section=home`}
              className={classes.tableTextMajor}
              onClick={onClick}
            >
              {principalDisplayName}
            </Link>
          </h5>
          <p className={classes.tableTextMinor}>ИНН: {principalINN}</p>
        </div>
      </td>
      <td style={{ width: '25%' }} className={classes.tdRight}>
        <div className={classes.tableText}>
          <h5>₽ {orderAmount}</h5>
          <p className={classes.tableTextMinor}>оформление до {orderRequiredExecutionDate}</p>
        </div>
      </td>
    </tr>
  )
}