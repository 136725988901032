import React, { useEffect, useMemo, useState } from 'react'
import { uniqBy } from 'lodash'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createFactoringRate } from '../../../../redux/Factoring/actions'
import { Modal2, InputText, Select2, Fieldset, RowGrid, Col6, Col12 } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Search } from '../../../../services/api'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'
import { onlyDigitsWithDot } from '../../../../utils'
import {
  FACTORING_EMPTY_TYPE_OPTION,
  FACTORING_ENTITY_TYPE_OPTIONS,
} from '../../../../config'

const useStyles = createUseStyles({
  addRateForm: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
})

const TYPES_THAT_HIDE_PERIODS = ['MRASH', 'MRISC', 'MKAP', 'DDOP', 'MCRED', 'REF']

const DEFAULT_SUPPLIER_OR_DEBTOR = {
  id: '',
  INN: '',
  OGRN: '',
  displayName: '',
}

const rateTypesMap = (item) => ({
  value: item?.type,
  name: item?.description,
})

export const AddRateForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams()

  const {
    // rateTypes,
    rateConfig,
  } = useSelector(state => state?.Factoring)

  const [entityType, setEntityType] = useState(FACTORING_ENTITY_TYPE_OPTIONS[0].value)
  const [supplier, setSupplier] = useState(DEFAULT_SUPPLIER_OR_DEBTOR)
  const [debtor, setDebtor] = useState(DEFAULT_SUPPLIER_OR_DEBTOR)
  const [companyName, setCompanyName] = useState('')
  const [entityId, setEntityId] = useState('')
  const [type, setType] = useState('')
  const [beginDate, setBeginDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [beginPeriod, setBeginPeriod] = useState('')
  const [endPeriod, setEndPeriod] = useState('')
  const [value, setValue] = useState('')
  const [submitted, setSubmitted] = useState(0)

  const touched = useMemo(() => submitted > 0, [submitted])

  const isClient = entityType === 'CLIENT'
  const isAgreementPB = entityType === 'AGREEMENT_PB'
  const isAgreementPBDebtor = entityType === 'AGREEMENT_PB_DEBTOR'
  const isSupplierOrDebtor = entityType === 'SUPPLIER_DEBTOR'

  const showPeriods = TYPES_THAT_HIDE_PERIODS.indexOf(type) === -1

  const rateTypesByEntityType = rateConfig?.find(item => item?.productType === entityType)?.rateTypes || []

  const availableRateTypes = uniqBy(
    rateConfig?.reduce((result, item) => [...result, ...item?.rateTypes], []),
    'type'
  )

  const getFilteredEntityTypeOptions = () => {
    const availableRateTypesByType = rateConfig?.reduce((result, item) => {
      if (item?.rateTypes?.find(rateType => rateType?.type === type)) {
        result.push(item?.productType)
      }
      return result
    }, [])
    const filteredEntityTypeOptions = FACTORING_ENTITY_TYPE_OPTIONS?.reduce((result, item) => {
      if (availableRateTypesByType?.indexOf(item?.value) > -1) {
        result.push(item)
      }
      return result
    }, [FACTORING_EMPTY_TYPE_OPTION])
    return filteredEntityTypeOptions
  }

  const entityTypeOptions = type
    ? getFilteredEntityTypeOptions()
    : FACTORING_ENTITY_TYPE_OPTIONS

  const formattedRateTypesByEntityType = rateTypesByEntityType?.map(rateTypesMap)
  const formattedAvailableRateTypes = availableRateTypes?.map(rateTypesMap)

  const typesOptions = entityType
    ? [FACTORING_EMPTY_TYPE_OPTION, ...formattedRateTypesByEntityType]
    : [FACTORING_EMPTY_TYPE_OPTION, ...formattedAvailableRateTypes]

    // console.log('rateTypes:', rateTypes)
    // console.log('rateTypesByEntityType:', rateTypesByEntityType)
    // console.log('rateConfig:', rateConfig)
    // console.log('entityTypeOptions:', entityTypeOptions)
    // console.log('typesOptions:', typesOptions)
    // console.log('type:', type)
    // console.log('availableRateTypes:', availableRateTypes)

  const payload = {
    entityType,
    entityId: isSupplierOrDebtor
      ? `${supplier?.id}@${debtor?.id}`
      : isAgreementPBDebtor
        ? `${entityId}@${debtor?.id}`
        : entityId,
    type,
    beginDate,
    endDate,
    ...(showPeriods ? { beginPeriod: Number(beginPeriod) } : {}),
    ...(showPeriods ? { endPeriod: Number(endPeriod) } : {}),
    value: Number(value),
  }

  const errors = {
    ...(entityType?.trim() ? {} : { entityType: 'Не выбран тип продукта' }),
    ...(isClient && !entityId?.trim() ? { entityId: 'Не выбран владелец продукта' } : {}),
    ...((isAgreementPB || isAgreementPBDebtor) && !entityId?.trim() ? { entityId: 'Не выбран номер договора факторинга' } : {}),
    ...(isSupplierOrDebtor && !supplier?.id?.trim() ? { supplier: 'Не указан поставщик' } : {}),
    ...((isSupplierOrDebtor || isAgreementPBDebtor) && !debtor?.id?.trim() ? { debtor: 'Не указан дебитор' } : {}),
    ...(type?.trim() ? {} : { type: 'Не выбран тип ставки' }),
    ...(beginDate?.trim() ? {} : { beginDate: 'Не указана дата начала' }),
    //...(endDate?.trim() ? {} : { endDate: 'Не указана дата окончания' }),
    ...(showPeriods && !beginPeriod?.trim() ? { beginPeriod: 'Не указано начало периода' } : {}), 
    ...(showPeriods && !endPeriod?.trim() ? { endPeriod: 'Не указан конец периода' } : {}), 
    ...(value?.trim() ? {} : { value: 'Не указано значение' }),    
  }

  const isValid = Object.keys(errors)?.length === 0

  const onChangeEntityType = (e) => {
    setEntityType(e?.target?.value)
    onClearEntityId()
  }

  const onChangeEntityId = (payload) => {
    setEntityId(payload.id)
    setCompanyName(payload.displayName)
  }
  const onClearEntityId = () => {
    setEntityId('')
    setCompanyName('')
  }

  const onChangeSupplier = (data) => setSupplier(data)
  const onClearSupplier = () => setSupplier(DEFAULT_SUPPLIER_OR_DEBTOR)

  const onChangeDebtor = (data) => setDebtor(data)
  const onClearDebtor = () => setDebtor(DEFAULT_SUPPLIER_OR_DEBTOR)

  const onCreateFactoringRate = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(createFactoringRate(payload, params.entityType, onSuccess))
    }
  }

  useEffect(() => {
    if (touched) {
      onCreateFactoringRate()
    }
  // eslint-disable-next-line
  }, [touched, submitted])

  console.log('payload [CURRENT]:', payload)
  console.log('errors', errors)

  return (
    <Modal2 className={classes.addRateForm} onClose={onClose}>
      <div className={classes.wrapper}>
        <H3>Добавить ставку</H3>
        <Form>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Select2
                  label='Тип продукта'
                  name='entityType'
                  options={entityTypeOptions}
                  value={entityType}
                  onChange={onChangeEntityType}
                  error={touched && errors.entityType}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          {isClient &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <TextFieldWithAutoComplete
                    classNames={{
                      container: 'filter-input',
                      input: 'filter-input__input',
                    }}
                    name='entityId'
                    placeholder='Владелец продукта'
                    label='Владелец продукта'
                    value={companyName}
                    defaultValue={companyName}
                    onSelect={onChangeEntityId}
                    onClear={onClearEntityId}
                    payloadKeys={['id', 'displayName']}
                    meta={{
                      touched,
                      error: errors.entityId,
                    }}
                    withoutLink
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {(isAgreementPB || isAgreementPBDebtor) &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <TextFieldWithAutoComplete
                    classNames={{
                      container: 'filter-input',
                      input: 'filter-input__input',
                    }}
                    name='entityId'
                    placeholder='Номер договора факторинга'
                    label='Номер договора факторинга'
                    value={companyName}
                    defaultValue={companyName}
                    onSelect={onChangeEntityId}
                    onClear={onClearEntityId}
                    payloadKeys={['id', 'number']}
                    request={Search.getFactoringContractSuggestions}
                    requestKey='id'
                    requestSubKeys={['displayName', 'supplierInn', 'startDate']}
                    meta={{
                      touched,
                      error: errors.entityId,
                    }}
                    withoutLink
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {isSupplierOrDebtor &&
            <Fieldset>
              <TextFieldWithAutoComplete
                classNames={{
                  container: 'filter-input',
                  input: 'filter-input__input',
                }}
                name='supplier'
                placeholder='Поставщик'
                label='Поставщик'
                value={supplier?.displayName}
                defaultValue={supplier?.displayName}
                onSelect={onChangeSupplier}
                onClear={onClearSupplier}
                payloadKeys={['id', 'INN', 'OGRN', 'displayName']}
                meta={{
                  touched,
                  error: errors.supplier,
                }}
                withoutLink
              />
            </Fieldset>
          }
          {(isSupplierOrDebtor || isAgreementPBDebtor) &&
            <Fieldset>
              <TextFieldWithAutoComplete
                classNames={{
                  container: 'filter-input',
                  input: 'filter-input__input',
                }}
                name='debtor'
                placeholder='Дебитор'
                label='Дебитор'
                value={debtor?.displayName}
                defaultValue={debtor?.displayName}
                onSelect={onChangeDebtor}
                onClear={onClearDebtor}
                payloadKeys={['id', 'INN', 'OGRN', 'displayName']}
                meta={{
                  touched,
                  error: errors.debtor,
                }}
                withoutLink
              />
            </Fieldset>
          }
          <Fieldset>
            <RowGrid>
              <Col12>
                <Select2
                  label='Тип ставки'
                  name='type'
                  options={typesOptions}
                  value={type}
                  onChange={e => setType(e.target.value)}
                  error={touched && errors.type}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col6>
                <InputText
                  type='date' 
                  name='beginDate'
                  label='Дата начала'
                  value={beginDate}
                  onChange={e => setBeginDate(e.target.value)}
                  error={touched && errors?.beginDate}
                />
              </Col6>
              <Col6>
                <InputText
                  type='date' 
                  name='endDate'
                  label='Дата окончания'
                  value={endDate}
                  onChange={e => setEndDate(e.target.value)}
                  // error={touched && errors?.endDate}
                />
              </Col6>
            </RowGrid>
          </Fieldset>
          {showPeriods &&
            <Fieldset>
              <RowGrid>
                <Col6>
                  <InputText
                    type='text' 
                    name='beginPeriod'
                    label='Начало периода'
                    value={beginPeriod}
                    onChange={e => setBeginPeriod(e.target.value)}
                    error={touched && errors?.beginPeriod}
                  />
                </Col6>
                <Col6>
                  <InputText
                    type='text' 
                    name='endPeriod'
                    label='Конец периода'
                    value={endPeriod}
                    onChange={e => setEndPeriod(e.target.value)}
                    error={touched && errors?.endPeriod}
                  />
                </Col6>
              </RowGrid>
            </Fieldset>
          }
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='value'
                  label='Значение'
                  value={value}
                  onChange={e => setValue(onlyDigitsWithDot(e.target.value))}
                  error={touched && errors?.value}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        </Form>
        <ButtonWrapper>
          <Button
            disabled={touched && !isValid}
            onClick={() => setSubmitted(submitted + 1)}
          >
            Создать
          </Button>
        </ButtonWrapper>
      </div>
    </Modal2>
  )
}