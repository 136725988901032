import Cookies from 'js-cookie'
import * as actionTypes from './actionTypes'
import { User } from '../../services/api'
import { setErrorContent } from '../Error/actions'
import { setSettings } from '../Settings/actions'
import { SETTINGS } from './../../config'

export const logoutProcess = (message = '') => async (dispatch, getState) => {
  const { settings: { authType }, keycloak } = getState().User
  try {
    await User.closeSession()
  } catch (err) {
    dispatch(setErrorContent(err))
  }
  if (authType === 'keycloak' && Object.keys(keycloak).length) {
    if (keycloak.authenticated) {
      keycloak.logout()
      Cookies.remove('JWT')
    }
  }
  if (authType === 'standard') {
    Cookies.remove('session_id')
    Cookies.remove('JSESSIONID')
  }

  if (message) {
    dispatch(setErrorContent(message))
  }

  dispatch({ type: actionTypes.LOGOUT_SUCCESS })
}

export const loginUser = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.LOGIN_FETCH })
    const { isSuccess, ...res } = await User.login({ username, password })
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    if (res.session) {
      Cookies.set('session_id', res.session.session_id, { expires: 1 })
      Cookies.set('JSESSIONID', res.session.JSESSIONID, { expires: 1 })
    }
    dispatch({
      type: actionTypes.LOGIN_SUCCESS,
      data: Object.assign(
        {},
        { ...res.user },
        {
          processDefinitionKeys: res.process_definition_keys,
          session_id: res.session && res.session.session_id,
        },
      ),
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.LOGIN_ERROR })
  }
}

export const authenticationUser = (needNotification) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.AUTH_FETCH })
    const { isSuccess, ...res } = await User.auth()
    if (!isSuccess) {
      // dispatch(logoutProcess(needNotification ? res.message : ''))
      dispatch({ type: actionTypes.AUTH_ERROR })
      return
    }
    if (res.session) {
      Cookies.set('session_id', res.session.session_id, { expires: 1 })
      Cookies.set('JSESSIONID', res.session.JSESSIONID, { expires: 1 })
    }
    dispatch({ type: actionTypes.AUTH_SUCCESS, data: Object.assign(
      {},
      { ...res.user },
      {
        processDefinitionKeys: res.process_definition_keys,
        session_id: res.session && res.session.session_id,
      },
      {
        filters: res.filters,
      }
    ) })
    dispatch(setSettings(res))

  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.AUTH_ERROR })
  }
}

export const setKeycloak = (keycloak) => (dispatch) => 
  dispatch({ 
    type: actionTypes.SET_KEYCLOAK, 
    keycloak, 
  })

export const logoutUser = (authType) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.LOGOUT_FETCH })
    if (authType === 'standard') {
      const { isSuccess, ...res } = await User.logout()
      if (!isSuccess) {
        if (res.needLogout) {
          dispatch(logoutProcess(res.message))
          return
        }
        throw new Error(res.message)
      }
    }
    dispatch(logoutProcess())
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.LOGOUT_ERROR })
  }
}

export const getAppSettings = () => async (dispatch) => 
  dispatch({ 
    type: actionTypes.GET_SETTINGS_SUCCESS, 
    data: SETTINGS, 
  })