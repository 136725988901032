import * as types from './actionTypes'
import { DEFAULT_PAGE_SIZE } from './../../config'

const initialState = {
  isFetching: false,
  isFetchingNext: false,
  filters: {},
  order: [],
  tasks: {},
  task: [...Array(DEFAULT_PAGE_SIZE).keys()],
  page: 0,
  more: false,
  amount_min: 1000000,
  amount_max: 15000000,
  task_types: [],
  activeTaskId: null,
  _byIds: {},
  selectedTaskIds: {},
}

const tasksAsMap = (tasks = []) => {
  return tasks.reduce((map, task) => {
    map[task.id] = task
    return map
  }, {})
}

export const _byIds = (state = {}, action) => {
  switch (action.type) {
    case types.TASKS_SUCCESS: {
      const { task: tasks } = action.data
      return tasksAsMap(tasks)
    }
    case types.NEXT_TASKS_SUCCESS: {
      const { task } = action.data
      return {
        ...state,
        ...tasksAsMap(task)
      }
    }
    case types.TASKS_INSERT: {
      const { task } = action.data
      return {
        ...state, 
        [task.id]: task
      }
    }
    default: {
      return state
    }
  }
}

export const selectedTaskIds = (state = {}, action) => {
  switch (action.type) {
    case types.TASK_SELECT:
      return {
        ...state,
        [action.data]: true
      }
    case types.TASK_UNSELECT:
      delete state[action.data]
      return {
        ...state
      }
    case types.TASK_UNSELECT_ALL:
      return {}
    default:
      return state
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_UNSELECT:
    case types.TASK_SELECT:
    case types.TASK_UNSELECT_ALL:
      return {
        ...state,
        selectedTaskIds: selectedTaskIds(state.selectedTaskIds, action)
      }
    case types.TASKS_FETCH:
      return { 
        ...state, 
        isFetching: true, 
      }
    case types.TASKS_SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
        _byIds: _byIds(state._byIds, action),
      }
    case types.TASKS_ERROR:
      return { 
        ...state, 
        isFetching: false, 
      }
    case types.NEXT_TASKS_FETCH:
      return { 
        ...state, 
        isFetchingNext: true,
      }
    case types.NEXT_TASKS_SUCCESS:
      return {
        ...state,
        isFetchingNext: false,
        more: action.data.more,
        page: action.data.page,
        order: state.order.concat(action.data.order),
        task: [...state.task, ...action.data.task],
        _byIds: _byIds(state._byIds, action),
      }
    case types.TASKS_INSERT:
      return {
        ...state, 
        task: [action.data.task, ...state.task],
        _byIds: _byIds(state._byIds, action)
      }
    case types.NEXT_TASKS_ERROR:
      return { 
        ...state,
        isFetching: false,
      }
    case types.TASKS_SET_FILTER:
      return {
        ...state,
        filters: Object.assign({}, state.filters, action.data.filters),
      }
    case types.TASKS_CLEAR_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
      }
    case types.TASKS_SET_ASSIGNED_TASK:
      const taskPos = state.task.findIndex(item => item.id === action.data.taskId)    
      if (taskPos === -1) {
        return state
      }
      const task = state.task[taskPos]      
      const hasAssignee = task.items.find(item => item.type === 'assignee')

      if (hasAssignee) {
        task.items.map(item => {
          if (item.type === 'assignee') {
            item.title = action.data.username
          }
          return item
        })
      } else {
        task.items.push({
          type: 'assignee',
          title: action.data.username, 
        })
      }
      
      task.isAssigned = action.data.isAssigned

      return {
        ...state,
        task: [
          ...state.task.slice(0,taskPos), 
          {...task}, 
          ...state.task.slice(taskPos + 1)
        ]
      }
    case types.TASK_SET_ACTIVE:
      return {
        ...state,
        activeTaskId: action.data,
      }
    default:
      return state
  }
}