import * as actionTypes from './actionTypes'
import { 
  // createAction, 
  createActionAsync, 
  // hasAccess, 
} from '../actions'
import * as CompanyService from '../../services/api/company'

export const getCompanyInfo = createActionAsync([
  actionTypes.COMPANY_GET_INFO_PENDING,
  actionTypes.COMPANY_GET_INFO_SUCCESS,
  actionTypes.COMPANY_GET_INFO_ERROR,
], CompanyService.getCompanyInfo)

export const getCompanyLimits = createActionAsync([
  actionTypes.COMPANY_GET_LIMITS_PENDING,
  actionTypes.COMPANY_GET_LIMITS_SUCCESS,
  actionTypes.COMPANY_GET_LIMITS_ERROR,
], CompanyService.getCompanyLimits)

export const deleteCompanyLimits = createActionAsync([
  actionTypes.COMPANY_DELETE_LIMITS_PENDING,
  actionTypes.COMPANY_DELETE_LIMITS_SUCCESS,
  actionTypes.COMPANY_DELETE_LIMITS_ERROR,
], CompanyService.deleteCompanyLimits)

export const getCompanyOrders = createActionAsync([
  actionTypes.COMPANY_GET_ORDERS_PENDING,
  actionTypes.COMPANY_GET_ORDERS_SUCCESS,
  actionTypes.COMPANY_GET_ORDERS_ERROR,
], CompanyService.getCompanyOrders)

export const getCompanyOrder = createActionAsync([
  actionTypes.COMPANY_GET_ORDER_PENDING,
  actionTypes.COMPANY_GET_ORDER_SUCCESS,
  actionTypes.COMPANY_GET_ORDER_ERROR,
], CompanyService.getCompanyOrder)

export const getCompanyAgentLogins = createActionAsync([
  actionTypes.COMPANY_GET_AGENT_LOGINS_PENDING,
  actionTypes.COMPANY_GET_AGENT_LOGINS_SUCCESS,
  actionTypes.COMPANY_GET_AGENT_LOGINS_ERROR,
], CompanyService.getCompanyAgentLogins)

export const getCompanyClientLogins = createActionAsync([
  actionTypes.COMPANY_GET_CLIENT_LOGINS_PENDING,
  actionTypes.COMPANY_GET_CLIENT_LOGINS_SUCCESS,
  actionTypes.COMPANY_GET_CLIENT_LOGINS_ERROR,
], CompanyService.getCompanyClientLogins)

export const addCompanyClientLogin = createActionAsync([
  actionTypes.COMPANY_ADD_CLIENT_LOGIN_PENDING,
  actionTypes.COMPANY_ADD_CLIENT_LOGIN_SUCCESS,
  actionTypes.COMPANY_ADD_CLIENT_LOGIN_ERROR,
], CompanyService.addCompanyClientLogin)

export const addCompanyAgentLogin = createActionAsync([
  actionTypes.COMPANY_ADD_AGENT_LOGIN_PENDING,
  actionTypes.COMPANY_ADD_AGENT_LOGIN_SUCCESS,
  actionTypes.COMPANY_ADD_AGENT_LOGIN_ERROR,
], CompanyService.addCompanyAgentLogin)

export const removeCompanyClientLogin = createActionAsync([
  actionTypes.COMPANY_REMOVE_CLIENT_LOGIN_PENDING,
  actionTypes.COMPANY_REMOVE_CLIENT_LOGIN_SUCCESS,
  actionTypes.COMPANY_REMOVE_CLIENT_LOGIN_ERROR,
], CompanyService.removeCompanyClientLogin)

export const getCompanyAgentStats = createActionAsync([
  actionTypes.COMPANY_GET_AGENT_STATS_PENDING,
  actionTypes.COMPANY_GET_AGENT_STATS_SUCCESS,
  actionTypes.COMPANY_GET_AGENT_STATS_ERROR,
], CompanyService.getCompanyAgentStats)

export const getCompanyAgentStatsByStatus = createActionAsync([
  actionTypes.COMPANY_GET_AGENT_STATS_BY_STATUS_PENDING,
  actionTypes.COMPANY_GET_AGENT_STATS_BY_STATUS_SUCCESS,
  actionTypes.COMPANY_GET_AGENT_STATS_BY_STATUS_ERROR,
], CompanyService.getCompanyAgentStatsByStatus)

export const removeCompanyAgentLogin = createActionAsync([
  actionTypes.COMPANY_REMOVE_AGENT_LOGIN_PENDING,
  actionTypes.COMPANY_REMOVE_AGENT_LOGIN_SUCCESS,
  actionTypes.COMPANY_REMOVE_AGENT_LOGIN_ERROR,
], CompanyService.removeCompanyAgentLogin)

export const getCompanyAddress = createActionAsync([
  actionTypes.COMPANY_GET_ADDRESS_PENDING,
  actionTypes.COMPANY_GET_ADDRESS_SUCCESS,
  actionTypes.COMPANY_GET_ADDRESS_ERROR,
], CompanyService.getCompanyAddress)

export const getCompanyBankAccounts = createActionAsync([
  actionTypes.COMPANY_GET_BANK_ACCOUNTS_PENDING,
  actionTypes.COMPANY_GET_BANK_ACCOUNTS_SUCCESS,
  actionTypes.COMPANY_GET_BANK_ACCOUNTS_ERROR,
], CompanyService.getCompanyBankAccounts)

export const getCompanyStaffInfo = createActionAsync([
  actionTypes.COMPANY_GET_STUFF_INFO_PENDING,
  actionTypes.COMPANY_GET_STUFF_INFO_SUCCESS,
  actionTypes.COMPANY_GET_STUFF_INFO_ERROR,
], CompanyService.getCompanyStuffInfo)

export const getCompanyHeadInfo = createActionAsync([
  actionTypes.COMPANY_GET_HEAD_INFO_PENDING,
  actionTypes.COMPANY_GET_HEAD_INFO_SUCCESS,
  actionTypes.COMPANY_GET_HEAD_INFO_ERROR,
], CompanyService.getCompanyHead)

export const getCompanySignerInfo = createActionAsync([
  actionTypes.COMPANY_GET_SIGNER_INFO_PENDING,
  actionTypes.COMPANY_GET_SIGNER_INFO_SUCCESS,
  actionTypes.COMPANY_GET_SIGNER_INFO_ERROR,
], CompanyService.getCompanySigner)

export const getCompanyFoundersInfo = createActionAsync([
  actionTypes.COMPANY_GET_FOUNDERS_INFO_PENDING,
  actionTypes.COMPANY_GET_FOUNDERS_INFO_SUCCESS,
  actionTypes.COMPANY_GET_FOUNDERS_INFO_ERROR,
], CompanyService.getCompanyFounders)

export const getCompanyBeneficiariesInfo = createActionAsync([
  actionTypes.COMPANY_GET_BENEFICIARIES_INFO_PENDING,
  actionTypes.COMPANY_GET_BENEFICIARIES_INFO_SUCCESS,
  actionTypes.COMPANY_GET_BENEFICIARIES_INFO_ERROR,
], CompanyService.getCompanyBeneficiaries)

export const getCompanyContactPersonInfo = createActionAsync([
  actionTypes.COMPANY_GET_CONTACT_PERSON_INFO_PENDING,
  actionTypes.COMPANY_GET_CONTACT_PERSON_INFO_SUCCESS,
  actionTypes.COMPANY_GET_CONTACT_PERSON_INFO_ERROR,
], CompanyService.getCompanyContactPerson)

export const getCompanyDocumentsInfo = createActionAsync([
  actionTypes.COMPANY_GET_DOCUMENTS_INFO_PENDING,
  actionTypes.COMPANY_GET_DOCUMENTS_INFO_SUCCESS,
  actionTypes.COMPANY_GET_DOCUMENTS_INFO_ERROR,
], CompanyService.getCompanyDocuments)

export const getCompanyManagementInfo = createActionAsync([
  actionTypes.COMPANY_GET_MANAGEMENT_INFO_PENDING,
  actionTypes.COMPANY_GET_MANAGEMENT_INFO_SUCCESS,
  actionTypes.COMPANY_GET_MANAGEMENT_INFO_ERROR,
], CompanyService.getCompanyManagement)

export const getCompanyBuhInfo = createActionAsync([
  actionTypes.COMPANY_GET_BUH_INFO_PENDING,
  actionTypes.COMPANY_GET_BUH_INFO_SUCCESS,
  actionTypes.COMPANY_GET_BUH_INFO_ERROR,
], CompanyService.getCompanyBuh)

export const getCompanyLiabilitiesInfo = createActionAsync([
  actionTypes.COMPANY_GET_LIABILITIES_INFO_PENDING,
  actionTypes.COMPANY_GET_LIABILITIES_INFO_SUCCESS,
  actionTypes.COMPANY_GET_LIABILITIES_INFO_ERROR,
], CompanyService.getCompanyLiabilities)

export const getCompanyBgAgentBinding = createActionAsync([
  actionTypes.COMPANY_GET_BG_AGENT_BINDING_PENDING,
  actionTypes.COMPANY_GET_BG_AGENT_BINDING_SUCCESS,
  actionTypes.COMPANY_GET_BG_AGENT_BINDING_ERROR,
], CompanyService.getCompanyBgAgentBinding)

export const updateCompanyBgAgentBinding = createActionAsync([
  actionTypes.COMPANY_UPDATE_BG_AGENT_BINDING_PENDING,
  actionTypes.COMPANY_UPDATE_BG_AGENT_BINDING_SUCCESS,
  actionTypes.COMPANY_UPDATE_BG_AGENT_BINDING_ERROR,
], CompanyService.updateCompanyBgAgentBinding)

export const getCompanyWorkGroups = createActionAsync([
  actionTypes.COMPANY_GET_WORK_GROUPS_PENDING,
  actionTypes.COMPANY_GET_WORK_GROUPS_SUCCESS,
  actionTypes.COMPANY_GET_WORK_GROUPS_ERROR,
], CompanyService.getCompanyWorkGroups)

export const updateCompanyWorkGroups = createActionAsync([
  actionTypes.COMPANY_UPDATE_WORK_GROUPS_PENDING,
  actionTypes.COMPANY_UPDATE_WORK_GROUPS_SUCCESS,
  actionTypes.COMPANY_UPDATE_WORK_GROUPS_ERROR,
], CompanyService.updateCompanyWorkGroups)

export const getCompanyStatisticInfo = createActionAsync([
  actionTypes.COMPANY_GET_STATISTIC_INFO_PENDING,
  actionTypes.COMPANY_GET_STATISTIC_INFO_SUCCESS,
  actionTypes.COMPANY_GET_STATISTIC_INFO_ERROR,
], CompanyService.getCompanyStatistic)

export const excludeOrder = createActionAsync([
  actionTypes.COMPANY_STATISTIC_ORDER_EXCLUDE_PENDING,
  actionTypes.COMPANY_STATISTIC_ORDER_EXCLUDE_SUCCESS,
  actionTypes.COMPANY_STATISTIC_ORDER_EXCLUDE_ERROR,
], CompanyService.getCompanyStatisticExclude)

export const includeOrder = createActionAsync([
  actionTypes.COMPANY_STATISTIC_ORDER_INCLUDE_PENDING,
  actionTypes.COMPANY_STATISTIC_ORDER_INCLUDE_SUCCESS,
  actionTypes.COMPANY_STATISTIC_ORDER_INCLUDE_ERROR,
], CompanyService.getCompanyStatisticInclude)

export const updateLoaded = createActionAsync([
  actionTypes.COMPANY_STATISTIC_UPDATE_LOADED_PENDING,
  actionTypes.COMPANY_STATISTIC_UPDATE_LOADED_SUCCESS,
  actionTypes.COMPANY_STATISTIC_UPDATE_LOADED_ERROR,
], CompanyService.getCompanyStatistic)

export const getCompanyAgentInfo = createActionAsync([
  actionTypes.COMPANY_AGENT_INFO_GET_PENDING,
  actionTypes.COMPANY_AGENT_INFO_GET_SUCCESS,
  actionTypes.COMPANY_AGENT_INFO_GET_ERROR,
], CompanyService.getCompanyAgentInfo)

export const registerCompanyAsAgent = createActionAsync([
  actionTypes.REGISTER_COMPANY_AS_AGENT_PENDING,
  actionTypes.REGISTER_COMPANY_AS_AGENT_SUCCESS,
  actionTypes.REGISTER_COMPANY_AS_AGENT_ERROR,
], CompanyService.registerCompanyAsAgent)

export const addCompanyAgentPerson = createActionAsync(
  actionTypes.COMPANY_AGENT_PERSON_ADD, 
  CompanyService.addPerson
)

export const addCompanyAgentBillingSigner = createActionAsync(
  actionTypes.COMPANY_AGENT_BILLING_SIGNER_ADD, 
  CompanyService.addBillingSigner
)

export const removeCompanyAgentBillingSigner = createActionAsync(
  actionTypes.COMPANY_AGENT_BILLING_SIGNER_REMOVE, 
  CompanyService.removeBillingSigner
)

export const addCompanyAgentBillingContractSchema = createActionAsync(
  actionTypes.COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_ADD, 
  CompanyService.addBillingSchema
)

export const updateCompanyAgentBillingContractSchema = createActionAsync(
  actionTypes.COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_EDIT, 
  CompanyService.updateBillingSchema
)

export const addCompanyAgentBillingContract = createActionAsync(
  actionTypes.COMPANY_AGENT_BILLING_CONTRACT_ADD, 
  CompanyService.addBillingContract
)

export const updateCompanyAgentBillingContract = createActionAsync(
  actionTypes.COMPANY_AGENT_BILLING_CONTRACT_ADD, 
  CompanyService.updateBillingContract
)

export const removeCompanyBillingContract = createActionAsync(
  actionTypes.COMPANY_AGENT_BILLING_CONTRACT_REMOVE, 
  CompanyService.removeBillingContract
)

export const removeBillingContractAndUpdateInfo = ({ companyId }) => async (dispatch, getState) => {
  try {
    dispatch({ 
      type: actionTypes.COMPANY_AGENT_BILLING_CONTRACT_REMOVE_PENDING, 
    })
    const result = await CompanyService.removeBillingContract(companyId)
    if (result.isSuccess) {
      dispatch({ 
        type: actionTypes.COMPANY_AGENT_BILLING_CONTRACT_REMOVE_SUCCESS, 
      })
    }
    await dispatch(getCompanyAgentInfo(companyId))
  } catch(err) {
    console.log(err)
    dispatch({
      type: actionTypes.COMPANY_AGENT_BILLING_CONTRACT_REMOVE_ERROR,
    })
  }
}

export const removeBillingContractSchema = createActionAsync(
  actionTypes.COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_REMOVE, 
  CompanyService.removeBillingSchema
)

export const addBankManager = createActionAsync(
  actionTypes.COMPANY_BANK_MANAGER_ADD, 
  CompanyService.addBankManager
)

export const removeBankManager = createActionAsync(
  actionTypes.COMPANY_BANK_MANAGER_REMOVE, 
  CompanyService.removeBankManager
)

export const companyStartSubprocess = createActionAsync(
  actionTypes.COMPANY_START_SUBPROCESS, 
  CompanyService.companyStartSubprocess
)

export const updateCbsManager = createActionAsync(
  actionTypes.COMPANY_CBS_MANAGER_UPDATE, 
  CompanyService.updateCbsManager
)

export const getBaseSchemaList = createActionAsync(
  actionTypes.COMPANY_AGENT_BILLING_GET_BASE_SCHEMA_LIST, 
  CompanyService.getBaseSchemaList
)

export const addBaseSchema = createActionAsync(
  actionTypes.COMPANY_AGENT_BILLING_ADD_BASE_SCHEMA,
  CompanyService.addBaseSchema
)

export const resetCompanyAgentStatsByStatus = () => (dispatch) =>
  dispatch({ type: actionTypes.COMPANY_RESET_AGENT_STATS_BY_STATUS })