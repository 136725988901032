import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getReportTasksDetails } from '../../../../redux/Reports/Tasks/actions'
import { DetailsTable } from '../../Layout/DetailsTable'
import { TaskDetailsItem } from './TaskDetailsItem'

export const TaskDetails = ({
  details,
  process,
  dateFrom,
  dateTo,
  groups,
  ...rest
}) => {
  const dispatch = useDispatch()
  const { name } = details
  const loading = useSelector(state => state?.Reports?.Tasks?.isFetchingDetails)
  const list = useSelector(state => state?.Reports?.Tasks?.details[name] || [])

  useEffect(() => {
    const { processDefinitionKey, taskDefinitionKey, name } = details
    const payload = {
      name,
      processDefinitionKey,
      taskDefinitionKey,
      process,
      dateFrom,
      dateTo,
      groups: groups.map(({ id }) => id),
    }
    dispatch(getReportTasksDetails(payload))
  }, [dispatch, details, process, dateFrom, dateTo, groups])

  return (
    <DetailsTable
      title={name}
      loading={loading}
      data={{ name, list }}
      listKey='list'
      Item={TaskDetailsItem}
      {...rest}
    />
  )
}