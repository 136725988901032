import { API_SPA_URL, DEFAULT_PAGE_SIZE } from './../config'
import moment from 'moment'
import 'moment/locale/ru'
import { Toast } from '../components/Toast'
moment.locale('ru')

export const isIE = /msie|trident|edge\//.test(navigator.userAgent.toLowerCase())
export const isMozila = /firefox/.test(navigator.userAgent.toLowerCase())
export const isChrome = /chrome/.test(navigator.userAgent.toLowerCase())
export const isSafari = /safari/.test(navigator.userAgent.toLowerCase())
export const isOpera  = /opera/.test(navigator.userAgent.toLowerCase())

export const plural = (number, one, two, five) => {
  let result = ''
  if ((number - number % 10) % 100 !== 10) {
    if (number % 10 === 1) {
      result = one
    } else if (number % 10 >= 2 && number % 10 <= 4) {
      result = two
    } else {
      result = five
    }
  } else {
    result = five
  }
  return result
}

export const onlyDigits = (string = '') => string.replace(/[^\d]/g, '')
export const onlyLetters = (string = '') => string.replace(/[^А-Яа-я]/g, '')
export const onlyDigitsWithDot = (string = '') => string
  .replace(/\s/g, '')
  .replace(/,/g, '.')
  .replace(/[^\d.]|\.(?=.*\.)/g, '')

export const formattedMoney = (string = '') => string.replace(/,/g, '.')

export const formattedNumber = (number) => number
  ? (parseFloat(number).toFixed(2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  : 0

export const formattedNumberRub = (number) => number
  ? `${number}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  : 0

export const formattedNumberToGekto = (number) => number
  ? ((number/100).toFixed(2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  : 0

export const formattedNumberToKilo = (number) => number
  ? ((number/1000).toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  : 0

export const formattedCard = (string) => {
  if (!string) return null
  // 98765432112345678901 --> 98765.432.1.12345678901
  const s1 = string.substring(0, 5)
  const s2 = string.substring(5, 8)
  const s3 = string.substring(8, 9)
  const s4 = string.substring(9, 20)
  return `${s1}.${s2}.${s3}.${s4}`
}

export const formattedDate = (date) => date ? moment(date).format('DD.MM.YYYY') : ''
export const formattedDateTime = (date) => date ? moment(date).format('DD.MM.YYYY, HH:mm:ss') : ''
export const YYYYMMDD = (date) => date ? moment(date).format('YYYY-MM-DD') : ''
export const normilizeDate = (string) => {
  // '27.01.2021' --> '2021-01-27'
  if (!string) {
    return ''
  }
  const array = string.split('.')
  const day = array[0]
  const month = array[1]
  const year = array[2]
  return `${year}-${month}-${day}`
}
export const getMultipleDate = (date) => moment(date).format('llll') // ср, 14 июля 2021 г., 11:04
export const getDateCustomDay = (day) => {
  let currentDateArr = YYYYMMDD(new Date()).split('-')
  currentDateArr[2] = day
  return currentDateArr.join('-')
}

export const getLastMonths = (months = 1) => {
  let stepDateArray = []
  for (let i = 0; i < months; i++) {
    const nextMonth = moment(new Date()).add(-i, 'month')
    stepDateArray = [
      ...stepDateArray,
      {
        id: i + 1,
        name: moment(nextMonth).format('MMMM, YYYY'),
        period: moment(nextMonth).format('YYYY.MM'),
      },
    ]
  }
  return stepDateArray
}

export const round = (value, decimals = 2) => {
  let number = Number(value)
  number = Number(number.toFixed(3))
  return Number(Math.round(number + 'e' + decimals) + 'e-' + decimals)
}

export const getDivider = (array = []) => {
  if (!array.length) {
    return 1
  }

  const M = array.some(item => item && item.toString().length >= 6)
  const K = array.some(item => item && item.toString().length > 3 && item.toString() < 6)

  return M ? 1000000 : K ? 1000 : 1
}

export const getElementHeight = (selector) => {
  const container = document.querySelector(selector)
  if (!container) {
    return
  }
  const { height } = container.getBoundingClientRect()
  return height
}

export const getFilteredRequest = (type, filters) => {
  let query = ''
  const keys = Object.keys(filters)

  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === 'orderCreatedDate_to') {
      query = query + '&orderCreatedDate_from=' + filters[keys[i]].from
      query = query + '&orderCreatedDate_to=' + filters[keys[i]].to
    } else {
      query = query + '&' + keys[i] + '=' + filters[keys[i]]
    }
  }

  fetch(`${API_SPA_URL}/${type}/list?limit=${DEFAULT_PAGE_SIZE}${query}`)
    .then(response => response.json())
    .then(result => console.log(result))
    .catch(e => console.log(e))
}

export const isPressEscape = (e) => e.keyCode === 27

export const sortBy = (a, b, key, sort, time) => {
  let keyA = key ? a[key]?.toLowerCase() : a.toLowerCase()
  let keyB = key ? b[key]?.toLowerCase() : b.toLowerCase()

  if (time) {
    const getTimeStamp = (string) => {
      const array = string.split('.')
      let d = new Date()
      d.setDate(array[0])
      d.setMonth(array[1])
      d.setFullYear(array[2])
      return d.getTime()
    }
    keyA = keyA ? getTimeStamp(keyA) : 0
    keyB = keyB ? getTimeStamp(keyB) : 0
  }

  if (keyA > keyB) {
    return sort === 'asc' ? 1 : -1
  } else if (keyA < keyB) {
    return sort === 'asc' ? -1 : 1
  } else {
    return 0
  }
}

export const cloneDeep = (obj) => JSON.parse(JSON.stringify(obj))

export const formatMoney = (value, defaultValue = '—', suffix = '₽') => {
  const reverseStr = str => str.split('').reverse().join('')
  if (typeof value !== 'number') {
    return defaultValue
  }

  if (value < 0) {
    return `0.00 ${suffix}`
  }

  let [whole, fraction] = value.toString().split('.')
  whole = reverseStr(whole)
  const a = []
  let i = 0
  do {
    a.push(whole.substr(i * 3, 3))
    i++
  } while(((i - 1) * 3 + 3) < whole.length)

  whole = reverseStr(a.join(' '))

  return `${whole}${fraction ? ('.' + fraction) : '.00'} ${suffix}`
}

export const listToArray = (obj) => {
  if (!obj) {
    return []
  }
  let array = []
  for (let key in obj) {
    if (typeof obj[key] === 'object') {
      array.push(obj[key])
    }
  }
  return array
}

export const createSearchString = (obj) => {
  let searchString = ''
  if (obj) {
    for (const key in obj) {
      const value = obj[key]
      searchString = `${searchString}${searchString ? '&' : ''}${key}=${value}`
    }
  }
  return searchString
}

export const getValue = (object, path = '') => path
  .split('.')
  .reduce((result, item) => result === undefined ? result : result?.[item], object)

export const copyValue = (value, successText) => {
  navigator.clipboard.writeText(`${value}`)
    .then(() => {
      Toast({ message: successText, type: 'success' })
    })
}