import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import { Scroll } from '../../containers/Scroll'
import { Icon } from '../index'
import { isPressEscape } from '../../utils'
import { IS_COCONUT_FACTORING } from '../../config'

const useStyles = createUseStyles({
  modal: {
    position: 'fixed',
    zIndex: '10001',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  content: {
    background: '#FAFAFA',
    width: 830,
    maxHeight: 'calc(100vh - 40px)',
    // padding: [20, 0],
    borderRadius: 16,
    position: 'relative',
    '&.fullHeight': {
      height: '100%',
      '& $wrapper': {
        height: '100%',
      },
    },
  },
  wrapper: {
    maxHeight: 'inherit',
    // padding: [0, 30],
  },
  close: {
    background: '#fff',
    width: 50,
    height: 50,
    borderRadius: '50%',
    position: 'absolute',
    left: '100%',
    top: 0,
    transform: 'translateX(25px)',
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      pointerEvents: 'none',
    },
  },
  scroll: {
    maxHeight: 'calc(100vh - 100px)',
  },
})

export const Modal2 = ({
  children,
  className,
  fullHeight,
  withoutScroll,
  onClose,
}) => {
  const classes = useStyles()

  const onEscape = (e) => {
    if (isPressEscape(e)) {
      onClose && onClose()
    }
  }

  const onHoldPosition = (scrollY) => {
    setTimeout(() => {
      window.scrollTo(0, scrollY)
    }, 0)
  }

  useEffect(() => {
    const { scrollY } = window
    document.body.style.overflow = 'hidden'
    onHoldPosition(scrollY)

    return () => {
      document.body.style.overflow = ''
      onHoldPosition(scrollY)
    }
  })

  useEffect(() => {
    document.addEventListener('keyup', onEscape, false)
    return () => {
      document.removeEventListener('keyup', onEscape, false)
    }
  })

  return (
    <div className={classnames(classes.modal, { 'modal2': IS_COCONUT_FACTORING })}>
      <div className={classes.overlay} onClick={onClose} />
      <div className={classnames(classes.content, className, { fullHeight })}>
        {withoutScroll
          ? <div className={classes.wrapper}>{children}</div>
          : <Scroll className={classes.scroll}>
              <div className={classes.wrapper}>{children}</div>
            </Scroll>
        }
        <div className={classes.close} onClick={onClose}>
          <Icon iconName='close' />
        </div>
      </div>
    </div>
  )
}