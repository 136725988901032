import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import { onlyDigits, onlyDigitsWithDot } from '../../../../../../utils'
import { IS_PAPAYA, IS_GUAVA } from '../../../../../../config'

const useStyles = createUseStyles({
  root: {
    padding: [14, 20],
    border: [1, 'solid', '#ddd'],
    borderRadius: 16,
  },
  fieldset: {
    width: '25%',
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      paddingLeft: 20,
    },
  },
  label: {
    color: '#000',
    fontSize: 14,
    lineHeight: 1.7,
    fontWeight: 500,
    display: 'block',
  },
  input: {
    width: '100%',
    background: '#eee',
    padding: 8,
    color: '#504E5B',
    fontSize: 14,
    lineHeight: 1.7,
    fontWeight: 500,
    border: [1, 'solid', '#eee'],
    borderRadius: 16,
    textAlign: 'center',
    outline: 'none',
    transition: 'background 300ms ease',
    '&:focus': {
      background: '#fff',
    },
    '&.error': {
      borderColor: '#F56B6B',
    },
  },
  error: {
    marginTop: 4,
    color: '#F56B6B',
    fontSize: 10,
  },
  actions: {
    marginTop: 13,
  },
  action: {
    color: '#22C7B5',
    fontSize: 14,
    lineHeight: 1.7,
    fontWeight: 500,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: 16,
    },
    '&.cancel': {
      color: '#F56B6B',
    },
  },
})

export const Form = ({
  id,
  index,
  totalAmountStateAux,
  startMaxOrderAmount,
  maxOrderAmount,
  prepaidPercent,
  limitAmount,
  onAdd,
  onAddAux,
  onEdit,
  onEditAux,
  onClose,
}) => {
  const classes = useStyles()

  const FIELDS = [
    { label: 'НМЦК', name: 'startMaxOrderAmount', type: 'number' },
    { label: 'Максимальная сумма БГ', name: 'maxOrderAmount', type: 'number' },
    { label: 'Размер аванса', name: 'prepaidPercent', type: 'percent' },
    { label: 'Доступный лимит', name: 'limitAmount', type: 'number' },
  ]

  const [submitted, setSubmitted] = useState(0)
  const [payload, setPayload] = useState({
    ...(id ? { id } : {}),
    ...(startMaxOrderAmount ? { startMaxOrderAmount } : {}),
    ...(maxOrderAmount ? { maxOrderAmount } : {}),
    ...(prepaidPercent ? { prepaidPercent } : {}),
    ...(limitAmount ? { limitAmount } : {}),
  })

  const getErrors = (payload) => FIELDS.reduce((result, { name }) => {
    const payloadKeys = Object.keys(payload)
    const isEmpty = (IS_PAPAYA || IS_GUAVA) && ['startMaxOrderAmount', 'prepaidPercent'].indexOf(name) > -1
      ? false
      : payloadKeys.indexOf(name) === -1 || !payload[name]
    const lastColumn = 'limitAmount'
    const twoColumns = ['startMaxOrderAmount', 'maxOrderAmount']
    const isNotValidate =
      (twoColumns.indexOf(name) > -1 && payload[name] > payload[lastColumn]) ||
      (name === lastColumn && totalAmountStateAux < 0)

    const error = isEmpty
      ? 'Поле должно быть заполнено!'
      : isNotValidate
        ? 'Суммы лимитов и ограничений не соответствуют!'
        : ''

    if (error) {
      result.push({ [name]: error })
    }
    return result
  }, [])  

  const errors = getErrors(payload)

  const onResetForm = () => {
    setPayload({})
    setSubmitted(0)
  }

  useEffect(() => {
    if (submitted && !errors.length) {
      if (onAdd) {
        onAdd(payload)
      }
      if (onAddAux) {
        onAddAux(payload)
      }
      if (onEdit) {
        onEdit(payload, index)
        onClose()
      }
      onResetForm()
    }
    // eslint-disable-next-line
  }, [submitted])

  useEffect(() => {
    onEditAux && onEditAux(payload, index)
    // eslint-disable-next-line
  }, [payload])

  const onChange = (e, type) => setPayload({
    ...payload,
    [e.target.name]: type === 'number'
      ? Number(onlyDigits(e.target.value))
      : type === 'percent'
        ? onlyDigitsWithDot(e.target.value)
        : e.target.value,
  })

  const onSubmit = () => setSubmitted(submitted + 1)

  // console.log('payload', payload)
  // console.log('errors', errors)
  // console.log('totalAmountStateAux', totalAmountStateAux)
  
  return (
    <div className={classes.root}>
      {FIELDS.map(({ name, label, type }, index) => {
        const error = submitted
          ? errors.find(item => Object.keys(item)[0] === name)
          : null
        return (
          <div className={classes.fieldset} key={index}>
            <label className={classes.label} htmlFor={name}>{label}</label>
            <input
              className={classnames(classes.input, { error })}
              type='text'
              name={name}
              value={payload[name] || ''}
              onChange={(e) => onChange(e, type)}
            />
            {error &&
              <div className={classes.error}>{Object.values(error)[0]}</div>
            }
          </div>
        )
      })}
      <div className={classes.actions}>
        {onAdd
          ? <div className={classes.action} onClick={onSubmit}>Добавить условие</div>
          : <>
              <div className={classes.action} onClick={onSubmit}>Сохранить</div>
              <div className={classnames(classes.action, 'cancel')} onClick={onClose}>Отменить</div>
            </>
        }
      </div>
    </div>
  )
}