import React from 'react'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'
import { Modal } from '../index'

const styles = {
  guarantor: {
    marginBottom: 20,
  },
}

const GuarantorDocuments = ({ 
  classes,
  guarantorType, 
  documents,
  guarantor: {
    id,
    displayName,
    INN,
    KPP,
  }
}) => {
  const isFL = guarantorType === 'ФЛ'
  return (
    <Modal.Documents
      title={`Документы поручителей ${guarantorType}`}
      documents={documents}
    >
      <div className={classes.guarantor}>
        <Modal.Title>
          {isFL
            ? `Поручитель ${guarantorType}`
            : `ИНН / Наименование поручителя ${guarantorType}`
          }
        </Modal.Title>
        <Modal.Value>
          {isFL
            ? <span>{displayName}</span>
            : <Link
                to={`/orders?client-deal=${id}&section=home`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {displayName}
              </Link>
          }
          {INN && KPP &&
            <p>ИНН {INN}, КПП {KPP}</p>
          }
        </Modal.Value>
      </div>
    </Modal.Documents>
  )
}

export default injectSheet(styles)(GuarantorDocuments)