import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ModalAlternative } from '../../layout/Modal/Modal/Alternative/ModalAlternative'
import { Icon } from '../../layout'
import { Reports } from '../Reports'
import { isPressEscape } from '../../utils'
import { FACTORING_VIEWS, IS_PASSIONFRUIT } from '../../config'

const useStyles = createUseStyles({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: 228,
    height: '100vh',
    background: '#fff',
    // paddingTop: 130,
    // paddingBottom: 130,
    transform: 'translateX(-100%)',
    transition: 'transform 300ms ease-in-out',
    zIndex: 245,
    overflowY: 'auto',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'sticky',
      top: 0,
      left: 0,
      right: 0,
      height: 90,
      background: '#fff',
      zIndex: 2,
    },
    '&.active': {
      transform: 'translateX(0)'
    },
    '&.custom': {
      width: 450,
    },
  },
  overlay: {
    background: 'rgba(0, 0, 0, .50)',
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    transform: 'translateX(-200%)',
    opacity: 0,
    transition: 'opacity 300ms ease-in-out',
    zIndex: 245,
    '&.active': {
      transform: 'translateX(0)',
      opacity: 1
    }
  },
  item: {
    padding: [0, 23],
    '&:first-child': {
      paddingTop: 40,
    },
    '&:last-child': {
      paddingBottom: 130,
    },
    '& + &': {
      marginTop: 30
    },
  },
  link: {
    fontFamily: 'Montserrat',
    paddingLeft: 36,
    color: '#151B3D',
    fontSize: 12,
    lineHeight: 2,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      color: '#151B3D'
    },
    '&.disabled': {
      opacity: 0.5,
      cursor: 'default'
    },
    '& svg': {
      fill: '#151B3D',
      width: 24,
      height: 24,
      position: 'absolute',
      left: 0,
      top: 0
    },
    '& img': {
      width: 24,
      height: 24,
      position: 'absolute',
      left: 0,
      top: 0
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'top'
    },
  },
  notLink: {
    fontFamily: 'Montserrat',
    paddingLeft: 36,
    color: '#6D7986',
    fontSize: 12,
    lineHeight: 2,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '& svg': {
      fill: '#151B3D',
      width: 24,
      height: 24,
      position: 'absolute',
      left: 0,
      top: 0
    },
    '& img': {
      width: 24,
      height: 24,
      position: 'absolute',
      left: 0,
      top: 0
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'top'
    },
  },
  sublist: {
    '& > li': {
      marginTop: 10,
      fontFamily: 'Montserrat',
      paddingLeft: 36,
      color: '#6D7986',
      fontSize: 12,
      lineHeight: 2,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      display: 'inline-block',
      verticalAlign: 'top',
      position: 'relative',
    },
    '& ul': {
      marginTop: 10,
      '& > li': {
        marginLeft: 18,
        paddingLeft: 10,
        position: 'relative',
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          transform: 'translateY(-50%)',
          top: '50%',
          left: 0,
          width: 4,
          height: 4,
          background: '#151B3D',
        },
        '& + li': {
          marginTop: 10,
        },
        '& > a': {
          paddingLeft: 0,
        },
      },
    },
  },
})

export const BurgerMenu = ({
  active,
  onActive,
  onClose,
}) => {
  const classes = useStyles()

  const { access, factoring, reports: factoringReports } = useSelector(state => state?.Settings || {})
  const { url } = useSelector(state => state?.Settings?.report?.statistics || {})
  const { REACT_APP_NEW_BILLING_UI } = useSelector(state => state?.Environment?.environment)

  const IS_NEW_BILLING_UI = REACT_APP_NEW_BILLING_UI === 'true'

  const {
    tasks = [],
    views = [],
  } = factoring || {}

  const [openModal, setOpenModal] = useState(false)

  const onOpenModal = () => {
    setOpenModal(true)
    onActive()
  }
  const onCloseModal = () => setOpenModal(false)

  const onOpenReports = () => {
    onOpenModal()
    onClose()
  }

  const onEscape = (e) => isPressEscape(e) && onClose()

  useEffect(() => {
    document.addEventListener('keyup', onEscape, false)
    return () => {
      document.removeEventListener('keyup', onEscape, false)
    }
  })

  return (
    <>
      <div className={classnames(classes.overlay, { active })} onClick={onClose} />
      <div className={classes.wrapper}>
        <ul className={classnames(classes.root, { active, 'custom': tasks?.length > 0 })}>
          <li className={classes.item}>
            <Link className={classes.link} to='/'>
              <Icon iconName='tasks' />
              <span>Задачи</span>
            </Link>
          </li>
          {access?.charts &&
            <li className={classes.item}>
              <Link className={classes.link} to='/charts'>
                <Icon iconName='charts' />
                <span>Аналитика</span>
              </Link>
            </li>
          }
          {access?.reports &&
            <li className={classes.item}>
              <div className={classes.link} onClick={onOpenReports}>
                <Icon iconName='reports' />
                <span>Отчёты</span>
              </div>
            </li>
          }
          {factoringReports &&
            <li className={classes.item}>
              <Link className={classes.link} to='/factoring/reports'>
                <Icon iconName='reports' />
                <span>Отчёты 2</span>
              </Link>
            </li>
          }
          {access?.analytics &&
            <li className={classes.item}>
              {/* <Link className={classes.link} to='/analyze-processing'>
                <Icon iconName='docs' />
                <span>Анализ процессов</span>
              </Link> */}
              <div className={classnames(classes.link, 'disabled')}>
                <Icon iconName='docs' />
                <span>Анализ процессов</span>
              </div>
            </li>
          }
          <li className={classes.item}>
            <Link className={classes.link} to='/orders'>
              <Icon iconName='orders' />
              <span>Заявки</span>
            </Link>
          </li>
          {access?.monitoring &&
            <li className={classes.item}>
              <Link className={classes.link} to='/monitoringNotificationOrders'>
                <Icon iconName='orders' />
                <span>Уведомления по мониторингу</span>
              </Link>
            </li>
          }
          {access?.registry &&
            <li className={classes.item}>
              <Link className={classes.link} to='/payments'>
                <Icon iconName='registry' />
                <span>Реестр</span>
              </Link>
            </li>
          }
          {access && /*access.tariffs &&*/ IS_NEW_BILLING_UI &&
            <li className={classes.item}>
              <Link className={classes.link} to='/tariff-schemes'>
                <Icon iconName='registry' />
                <span>Тарифные схемы</span>
              </Link>
            </li>
          }
          {IS_PASSIONFRUIT &&
            <li className={classes.item}>
              <Link className={classes.link} to='/contracts'>
                <Icon iconName='registry' />
                <span>Договоры</span>
              </Link>
            </li>
          }
          {access?.paymentMatching &&
            <li className={classes.item}>
              <Link
                className={classes.link}
                to='/factoring/payment_matching'
                onClick={onClose}
              >
                <Icon iconName='registry' />
                <span>Квитовка платежей</span>
              </Link>
            </li>
          }
          {access?.monitoring &&
            <li className={classes.item}>
              <div className={classes.notLink}>
                <Icon iconName='registry' />
                <span>Мониторинг</span>
              </div>
              <ul className={classes.sublist}>
                <li>
                  <ul>
                    <li>
                      <Link
                        className={classes.link}
                        to='/factoring/monitoring/issues'
                        onClick={onClose}
                      >
                        <span>Проблемы</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={classes.link}
                        to='/factoring/monitoring/notifications'
                        onClick={onClose}
                      >
                        <span>Уведомления</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          }
          {access?.factoring &&
            <li className={classes.item}>
              <div className={classes.notLink}>
                <Icon iconName='registry' />
                <span>Факторинг</span>
              </div>
              <ul className={classes.sublist}>
                <li>
                  <div>Задачи:</div>
                  <ul>
                    {tasks?.map(({ taskDefinitionKey, taskName }, index) =>
                      <li key={index}>
                        <Link
                          className={classes.link}
                          to={`/factoring/tasks/${taskDefinitionKey}`}
                          onClick={onClose}
                        >
                          <span>{taskName}</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <div>Представления:</div>
                  <ul>
                    {FACTORING_VIEWS.map(({
                      entityType,
                      title,
                      hide,
                    }) => views?.find(item => item?.viewKey === entityType && !hide)
                      ? <li key={entityType}>
                          <Link
                            className={classes.link}
                            to={{
                              pathname: `/factoring/${entityType}`,
                              state: { resetFilters: true },
                            }}
                            onClick={onClose}
                          >
                            <span>{title}</span>
                          </Link>
                        </li>
                      : null
                    )}
                    <li>
                      <Link
                        className={classes.link}
                        to={{
                          pathname: `/factoring/calendar`,
                          state: { resetFilters: true },
                        }}
                        onClick={onClose}
                      >
                        <span>Календарь</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          }
          {openModal &&
            // <Modal width='900px' height='auto' onClose={onCloseModal}>
            //   <Reports url={url} onCancel={onCloseModal} />
            // </Modal>
            <ModalAlternative height='auto' onClose={onCloseModal}>
              <Reports url={url} onCancel={onCloseModal} />
            </ModalAlternative>
          }
        </ul>
      </div>
    </>
  )
}