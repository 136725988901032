import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    background: '#fff',
    paddingTop: 20,
    position: 'sticky',
    borderTop: [1, 'solid', '#eee'],
  },
}

const StickyHead = ({
  classes,
  children,
}) => {
  const header = document.getElementById('task-header')
  const top = header && header.getBoundingClientRect().height
  return (
    <div className={classes.root} style={{ top }}>{children}</div>
  )
}
                
export default injectSheet(styles)(StickyHead)