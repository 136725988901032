import * as actionTypes from './actionTypes'

const initialState = {
  tariffs: [],
  tariff: {},
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BASE_SCHEMA_LIST:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_BASE_SCHEMA_LIST_SUCCESS:
      return {
        ...state,
        tariffs: Object.values(action.data),
        isFetching: false,
      }
    case actionTypes.GET_BASE_SCHEMA_LIST_ERROR:
      return { 
        ...state, 
        isFetching: false, 
      }
    case actionTypes.GET_BASE_SCHEMA:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_BASE_SCHEMA_SUCCESS:
      return {
        ...state,
        tariff: action.data,
        isFetching: false,
      }
    case actionTypes.GET_BASE_SCHEMA_ERROR:
      return { 
        ...state, 
        isFetching: false, 
      }
    case actionTypes.UPDATE_BASE_SCHEMA:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.UPDATE_BASE_SCHEMA_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case actionTypes.UPDATE_BASE_SCHEMA_ERROR:
      return { 
        ...state, 
        isFetching: false, 
      }
    case actionTypes.CLEAR_BASE_SCHEMA:
      return {
        ...state,
        tariff: {},
        isFetching: false,
      }
    default:
      return state
  }
}