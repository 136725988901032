import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

class FooterItem extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    onSelectFilter: PropTypes.func.isRequired
  };

  handleSelectClient = () => {
    const { id, name, onSelectFilter } = this.props;
    onSelectFilter({ clientId: id, clientName: name });
  };

  render() {
    const { count, name } = this.props;
    return (
      <div className={cx("dash_bot-item")} onClick={this.handleSelectClient}>
        <div className="link">
          <span>{count}</span>
          <div className={cx("dash_bot-name")}>{name}</div>
        </div>
      </div>
    );
  }
}

export default FooterItem;
