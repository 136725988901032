import * as actionTypes from './actionTypes'
import { DEFAULT_PAGE_SIZE } from './../../config'

const initialState = {
  orders: [...Array(DEFAULT_PAGE_SIZE).keys()],
  filters: {},
  more: false,
  isFetching: false,
  isFetchingNext: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDERS:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_ORDERS_SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
      }
    case actionTypes.GET_ORDERS_ERROR:
      return { 
        ...state, 
        isFetching: false, 
      }
    case actionTypes.GET_NEXT_ORDERS:
      return { 
        ...state, 
        isFetchingNext: true, 
      }
    case actionTypes.GET_NEXT_ORDERS_SUCCESS:
      return {
        ...state,
        more: action.data.more,
        page: action.data.page,
        orders: [...state.orders, ...action.data.orders],
        isFetchingNext: false,
      }
    case actionTypes.GET_NEXT_ORDERS_ERROR:
      return { 
        ...state, 
        isFetchingNext: false, 
      }
    case actionTypes.SET_ORDERS_FILTER:
      return {
        ...state,
        filters: Object.assign(
          {},
          state.filters,
          action.data.filters,
        ),
      }
    case actionTypes.CLEAR_ORDERS_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
      }
    default:
      return state
  }
}