import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createFactoringAccount } from '../../../../redux/Factoring/actions'
import { Fieldset, Select2, InputText, InputWithDadata, RowGrid, Col12, Modal2 } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Factoring, Search } from '../../../../services/api'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'
import { getFactoringSuretyTypes } from '../../../../redux/Factoring/actions'

const useStyles = createUseStyles({
  addAccountForm: {
    width: 1200,
    padding: [30, 0, 30, 30],
    '& .filter-input.dropdown': {
      '& .dropdown-menu': {
        maxWidth: '100%',
        right: 0,
        '& .dropdown-item': {
          '& .filter__info': {
            maxWidth: '100%',
          },
        },
      },
    },
  },
  wrapper: {
    paddingRight: 30,
  },
})

export const AddAccountForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams()

  const {
    // accountTypes,
    accountEntityTypes,
    suretyTypes,
  } = useSelector(state => state?.Factoring)

  const [accountNumber, setAccountNumber] = useState('')
  const [accountType, setAccountType] = useState('')
  const [accountTypeName, setAccountTypeName] = useState('')
  const [entityType, setEntityType] = useState('')
  const [supplierId, setSupplierId] = useState('')
  const [supplierCompanyName, setSupplierCompanyName] = useState('')
  const [factoringContractId, setFactoringContractId] = useState('')
  const [factoringContractNumber, setFactoringContractNumber] = useState('')
  const [supplyId, setSupplyId] = useState('')
  const [supplyNumber, setSupplyNumber] = useState('')
  const [debtorCompanyName, setDebtorCompanyName] = useState('')
  const [debtorId, setDebtorId] = useState('')
  const [suretyId, setSuretyId] = useState('')
  const [bic, setBic] = useState('')
  const [bankName, setBankName] = useState('')
  const [bankCity, setBankCity] = useState('')
  const [corrAccount, setCorrAccount] = useState('')
  const [submitted, setSubmitted] = useState(0)

  const touched = useMemo(() => submitted > 0, [submitted])

  // const accountTypeOptions = [{ name: '', value: '' }, ...accountTypes]
  const accountEntityTypeOptions = [{ name: '', value: '' }, ...accountEntityTypes]
  const suretyTypeOptions = [
    { name: '', value: '' },
    ...suretyTypes.map(item => ({ value: item.id, name: item.number }))
  ]

  const isSupplierAndCustomer = entityType === 'A'
  const isSupplier = entityType === 'D'
  const isFactroringContractId = entityType === 'B'
  const isFactroringContractAndCustomer = entityType === 'X'
  const isFactroringContractAndSurety = entityType === 'E'
  const isSupplyId = entityType === 'F'

  const entityIdMapper = {
    A: supplierId && debtorId ? `${supplierId}@${debtorId}` : '',
    X: factoringContractId && debtorId ? `${factoringContractId}@${debtorId}` : '',
    E: factoringContractId && suretyId ? `${factoringContractId}@${suretyId}` : '',
    D: supplierId,
    B: factoringContractId,
    F: supplyId,
    C: 'COMMON',
    G: 'COMMON',
  }

  const payload = {
    accountNumber,
    accountType,
    entityType,
    entityId: entityIdMapper[entityType] || '',
    bankCity,
    bankName,
    bic,
    corrAccount,
  }

  const showSuretyId = isFactroringContractAndSurety && factoringContractId
  const showDebtorId = isSupplierAndCustomer || isFactroringContractAndCustomer
  const showSupplierId = isSupplier || isSupplierAndCustomer
  const showFactoringContractNumber = isFactroringContractId || isFactroringContractAndSurety || isFactroringContractAndCustomer

  const errors = {
    ...(accountNumber?.trim() ? {} : { accountNumber: 'Не указан номер счёта' }),
    ...(accountType?.trim() ? {} : { accountType: 'Не указан тип счёта' }),
    ...(entityType?.trim() ? {} : { entityType: 'Не указан тип сущности' }),
    ...(showSupplierId && !supplierId?.trim() ? { supplierId: 'Не указан поставщик' } : {}),
    ...(showFactoringContractNumber && !factoringContractId?.trim() ? { factoringContractId: 'Не указан договор факторинга' } : {}),
    ...(isSupplyId && !supplyId?.trim() ? { supplyId: 'Не указана поставка' } : {}),
    ...(showDebtorId && !debtorId?.trim() ? { debtorId: 'Не указан дебитор' } : {}),
    ...(showSuretyId && !suretyId?.trim() ? { suretyId: 'Не указан договор поручителей' } : {}),
    ...(!bankCity || !bankName || !bic || !corrAccount ? { bank: 'Не указан банк' } : {}),
  }

  const isValid = Object.keys(errors)?.length === 0

  const onChangeSupplierId = (payload) => {
    setSupplierId(payload.id)
    setSupplierCompanyName(payload.displayName)
  }
  const onClearSupplierId = () => {
    setSupplierId('')
    setSupplierCompanyName('')
  }

  const onChangeSupplyId = (payload) => {
    setSupplyId(payload.id)
    setSupplyNumber(payload.number)
  }
  const onClearSupplyId = () => {
    setSupplyId('')
    setSupplyNumber('')
  }

  const onChangeFactoringContractId = (payload) => {
    setFactoringContractId(payload.id)
    setFactoringContractNumber(payload.number)
  }
  const onClearFactoringContractId = () => {
    setFactoringContractId('')
    setFactoringContractNumber('')
  }

  const onChangeDebtorId = (payload) => {
    setDebtorId(payload.id)
    setDebtorCompanyName(payload.displayName)
  }
  const onClearDebtorId = () => {
    setDebtorId('')
    setDebtorCompanyName('')
  }

  const onChangeAccountType = (payload) => {
    setAccountType(payload.value)
    setAccountTypeName(payload.name)
  }
  const onClearAccountType = () => {
    setAccountType('')
    setAccountTypeName('')
  }

  const onChangeBank = (item) => {
    setBankName(item.bankName)
    setBic(item.bic)
    setBankCity(item.bankCity)
    setCorrAccount(item.corrNumber)
  }

  const onSubmit = () => setSubmitted(submitted + 1)

  const onCreateFactoringAccount = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(createFactoringAccount(payload, params?.entityType, onSuccess))
    }
  }

  useEffect(() => {
    if (factoringContractId) {
      dispatch(getFactoringSuretyTypes(factoringContractId))
    }
  }, [factoringContractId, dispatch])

  useEffect(() => {
    if (touched) {
      onCreateFactoringAccount()
    }
  // eslint-disable-next-line
  }, [touched, submitted])

  console.log('payload [CURRENT]:', payload)
  console.log('errors', errors)

  return (
    <Modal2 className={classes.addAccountForm} onClose={onClose}>
      <div className={classes.wrapper}>
        <H3>Добавить счёт</H3>
        <Form>
        <Fieldset>
            <RowGrid>
              <Col12>
                {/* <Select2
                  label='Тип счёта'
                  name='accountType'
                  options={accountTypeOptions}
                  value={accountType}
                  onChange={e => setAccountType(e.target.value)}
                  error={touched && errors.accountType}
                /> */}
                <TextFieldWithAutoComplete
                  classNames={{
                    container: 'filter-input',
                    input: 'filter-input__input',
                  }}
                  name='accountType'
                  placeholder='Тип счёта'
                  label='Тип счёта'
                  value={accountTypeName}
                  defaultValue={accountTypeName}
                  onSelect={onChangeAccountType}
                  onClear={onClearAccountType}
                  payloadKeys={['value', 'name']}
                  request={Factoring.getFactoringRefAccountTypes}
                  requestKey='name'
                  requestSubKeys={['value']}
                  meta={{
                    touched,
                    error: errors.accountType,
                  }}
                  withoutLink
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputWithDadata
                  type='text'
                  service='bank'
                  name='bankName'
                  label='Название банка'
                  placeholder='Введите название банка'
                  value={bankName}
                  onSelect={(item) => onChangeBank(item)}
                  error={touched && errors?.bank}
                />
              </Col12>
            </RowGrid>
          </Fieldset>

          {showSupplierId &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <TextFieldWithAutoComplete
                    classNames={{
                      container: 'filter-input',
                      input: 'filter-input__input',
                    }}
                    name='supplierId'
                    placeholder='Поставщик'
                    label='Поставщик'
                    value={supplierCompanyName}
                    defaultValue={supplierCompanyName}
                    onSelect={onChangeSupplierId}
                    onClear={onClearSupplierId}
                    payloadKeys={['id', 'displayName']}
                    meta={{
                      touched,
                      error: errors.supplierId,
                    }}
                    withoutLink
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }

          {showDebtorId &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <TextFieldWithAutoComplete
                    classNames={{
                      container: 'filter-input',
                      input: 'filter-input__input',
                    }}
                    name='debtorId'
                    placeholder='Дебитор'
                    label='Дебитор'
                    value={debtorCompanyName}
                    defaultValue={debtorCompanyName}
                    onSelect={onChangeDebtorId}
                    onClear={onClearDebtorId}
                    payloadKeys={['id', 'displayName']}
                    meta={{
                      touched,
                      error: errors.debtorId,
                    }}
                    withoutLink
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }

          {showFactoringContractNumber &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <TextFieldWithAutoComplete
                    classNames={{
                      container: 'filter-input',
                      input: 'filter-input__input',
                    }}
                    name='factoringContractId'
                    placeholder='Договор факторинга'
                    label='Договор факторинга'
                    value={factoringContractNumber}
                    defaultValue={factoringContractNumber}
                    onSelect={onChangeFactoringContractId}
                    onClear={onClearFactoringContractId}
                    payloadKeys={['id', 'number']}
                    request={Search.getFactoringContractSuggestions}
                    requestKey='number'
                    requestSubKeys={['displayName', 'supplierInn', 'startDate']}
                    meta={{
                      touched,
                      error: errors.factoringContractId,
                    }}
                    withoutLink
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }

          {isSupplyId &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <TextFieldWithAutoComplete
                    classNames={{
                      container: 'filter-input',
                      input: 'filter-input__input',
                    }}
                    name='supplyId'
                    placeholder='Поставка'
                    label='Поставка'
                    value={supplyNumber}
                    defaultValue={supplyNumber}
                    onSelect={onChangeSupplyId}
                    onClear={onClearSupplyId}
                    payloadKeys={['id', 'supplyContractNumber']}
                    request={Search.getSupplySuggestions}
                    requestKey='number'
                    requestSubKeys={['supplierName', 'supplyDate']}
                    meta={{
                      touched,
                      error: errors.supplyId,
                    }}
                    withoutLink
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }

          {showSuretyId &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Select2
                    label='Договор поручителей'
                    name='suretyId'
                    options={suretyTypeOptions}
                    value={suretyId}
                    onChange={e => setSuretyId(e.target.value)}
                    error={touched && errors.suretyId}
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }

          <Fieldset>
            <RowGrid>
              <Col12>
                <Select2
                  label='Тип сущности'
                  name='entityType'
                  options={accountEntityTypeOptions}
                  value={entityType}
                  onChange={e => setEntityType(e.target.value)}
                  error={touched && errors.entityType}
                />
              </Col12>
            </RowGrid>
          </Fieldset>

          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='accountNumber'
                  label='Номер счёта'
                  value={accountNumber}
                  onChange={e => setAccountNumber(e.target.value)}
                  error={touched && errors?.accountNumber}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        </Form>
        <ButtonWrapper>
          <Button disabled={touched && !isValid} onClick={onSubmit}>Создать</Button>
        </ButtonWrapper>
      </div>
      </Modal2>
  )
}