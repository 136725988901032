import React from 'react'
import './ContentWrapper.style.scss'

export function ContentWrapper({ children, onClose }) {
  return (
    <div className="react-modal-content-wrapper">
      {children}
      <div className="react-modal-content-wrapper-close" onClick={onClose}>
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 6.232L14.187.045l1.768 1.767L9.767 8l6.188 6.188-1.768 1.767L8 
              9.767l-6.188 6.188-1.767-1.768L6.232 8 .045 1.812 1.812.045 8 6.232z"
            fill="#4A4A4A"
          />
        </svg>
      </div>
    </div>
  )
}
