import { createSelector } from 'reselect'
import { formattedNumber } from './../../utils'

const get = require('lodash/get')

export const chart = store => get(store, 'Chart.charts', {})
export const isFetching = store => get(store, 'Chart.isFetching', false)

const createdSelector = createSelector(chart, item => get(item, 'created.report.days', []))
const approvedSelector = createSelector(chart, item => get(item, 'approved.report.days', []))
const issuedSelector = createSelector(chart, item => get(item, 'issued.report.days', []))
export const issueDistributionSelector = createSelector(chart, item => get(item, 'issueDistribution.report', {}))
export const monthlySelector = createSelector(chart, item => get(item, 'monthly.report', []))

const getValue = (name) => (item) => item[name] === null ? undefined : item[name]

// CREATED CHART
export const createdDay = createSelector(createdSelector, (array) => array.map(getValue('day')))
export const createdAmount = createSelector(createdSelector, (array) => array.map(getValue('amount')))
export const createdAmountInc = createSelector(createdSelector, (array) => array.map(getValue('amountInc')))
export const createdCount = createSelector(createdSelector, (array) => array.map(getValue('count')))
export const createdCountLot = createSelector(createdSelector, (array) => array.map(getValue('countLot')))
export const createdCountInc = createSelector(createdSelector, (array) => array.map(getValue('countInc')))

// APPROVED CHART
export const approvedDay = createSelector(approvedSelector, (array) => array.map(getValue('day')))
export const approvedAmount = createSelector(approvedSelector, (array) => array.map(getValue('amount')))
export const approvedAmountInc = createSelector(approvedSelector, (array) => array.map(getValue('amountInc')))
export const approvedCount = createSelector(approvedSelector, (array) => array.map(getValue('count')))
export const approvedCountLot = createSelector(approvedSelector, (array) => array.map(getValue('countLot')))
export const approvedCountInc = createSelector(approvedSelector, (array) => array.map(getValue('countInc')))

// ISSUED CHART
export const issuedDay = createSelector(issuedSelector, (array) => array.map(getValue('day')))
export const issuedAmount = createSelector(issuedSelector, (array) => array.map(getValue('amount')))
export const issuedAmountInc = createSelector(issuedSelector, (array) => array.map(getValue('amountInc')))
export const issuedAmountForecast = createSelector(issuedSelector, (array) => array.map(getValue('amountForecast')))
export const issuedAmountForecastInc = createSelector(issuedSelector, (array) => array.map(getValue('amountForecastInc')))
export const issuedAmountScheduled = createSelector(issuedSelector, (array) => array.map(getValue('amountScheduled')))
export const issuedAmountScheduledInc = createSelector(issuedSelector, (array) => array.map(getValue('amountScheduledInc')))
export const issuedCount = createSelector(issuedSelector, (array) => array.map(getValue('count')))
export const issuedCountInc = createSelector(issuedSelector, (array) => array.map(getValue('countInc')))
export const issuedCountForecast = createSelector(issuedSelector, (array) => array.map(getValue('countForecast')))
export const issuedCountForecastInc = createSelector(issuedSelector, (array) => array.map(getValue('countForecastInc')))
export const issuedCountScheduled = createSelector(issuedSelector, (array) => array.map(getValue('countScheduled')))
export const issuedCountScheduledInc = createSelector(issuedSelector, (array) => array.map(getValue('countScheduledInc')))

export const getMonthlySelector = createSelector(
  monthlySelector,
  (array) => (array || []).reduce((result, item) => {
    if (!result.periods) { result.periods = [] }
    if (!result.created) { result.created = [] }
    if (!result.issued) { result.issued = [] }
    if (!result.lost) { result.lost = [] }
    if (!result.lostRate) { result.lostRate = [] }
    if (!result.issuedRate) { result.issuedRate = [] }
    if (!result.amount) { result.amount = [] }
    if (!result.commissionAmount) { result.commissionAmount = [] }
    if (!result.averageCheck) { result.averageCheck = [] }

    result.periods.push(item.month)
    result.created.push(item.created.count)
    result.issued.push(item.issued.count)
    result.lost.push(item.lost.count)
    result.lostRate.push(`${(item.lost.count / item.created.count * 100).toFixed(2)}%`)
    result.issuedRate.push(`${(item.issued.count / item.created.count * 100).toFixed(2)}%`)
    result.amount.push(`${formattedNumber(item.issued.amount / 1000000)}`)
    result.commissionAmount.push(`${formattedNumber(item.issued.commissionAmount / 1000000)}`)
    result.averageCheck.push(`${formattedNumber(item.issued.amount / item.issued.count / 1000000)}`)

    return result
  }, {})
)