import { setErrorContent } from './Error/actions'

export const createAction = type => (data, meta = {}) => {
  return {
    type, 
    data,
    meta: typeof meta === 'string'
      ? { meta, timestamp: Date.now() }
      : { ...meta, timestamp: Date.now(),
    }, 
  }
}

export const createActionAsync = (actionTypes, fn) => (...args) => async (dispatch, getState) => {
  if (typeof actionTypes === 'string') {
    actionTypes = ['PENDING', 'SUCCESS', 'ERROR'].map(status => `${actionTypes}_${status}`)
  }
  const [PENDING, SUCCESS, ERROR] = actionTypes

  dispatch(createAction(PENDING).call(null, args[0]))

  try {
    const result = await fn.apply(null, args)
    if (result.isSuccess) {
      // if (typeof args[0] === 'string') {
      //   args[0] = {
      //     companyId: args[0],
      //   }
      // }
      dispatch(createAction(SUCCESS).call(null, result, args[0]))
    } else {
      const error = result.message || result.errors || result.error
      dispatch(createAction(ERROR, error, args[0]).call())
      dispatch(setErrorContent(error))
    }
  } catch (err) {
    dispatch(createAction(ERROR, err, args[0]).call())
    dispatch(setErrorContent(err.message))
  }
}

export const hasAccess = accessType => fn => (...args) => async (dispatch, getState) => { 
  const { Settings: { access } } = getState()
  if (access[accessType] !== false) {
    await dispatch(fn.apply(null, args))
  }
}