import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getActiveTask } from '../../../redux/Tasks/selectors'
import { withRouter } from 'react-router-dom'
import Overlay from '../../../components/Overlay'
import { getNextTask } from '../../../services/api/task'
import { setErrorContent } from '../../../redux/Error/actions'
import { getTasks, insertTask, setActiveTask } from '../../../redux/Tasks/actions'
//import { setSettings } from '../../../redux/Settings/actions'
import { isPressEscape } from '../../../utils'

class TaskCamundaDetail extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onCloseDetail: PropTypes.func.isRequired,
  }
  static defaultProps = {
    title: '<i style="font-style: italic; color: #ccc;">Название заявки</i>',
  }

  state = { 
    loading: false,
    wizzardNoNextText: '',
  }

  static getFormScript(id, processDefinitionKey) {
    return `
      try {
        ngapp.init($('#task-detail')[0], '#process_definition_key#', function (CamSDK) {
          var urlPrefix = '',
            camClient = new CamSDK.Client({
              mock: false,
              apiUri: '/camunda/api/engine'
            }),
            taskService = new camClient.resource('task'),
            $container  = $('#task').parent();
            
          function openForm(taskData) {
            $('#task-detail').data('taskData', taskData);
  
            taskService.form(taskData.id, function (err, taskFormInfo) {
        
              var url = urlPrefix + taskFormInfo.key.replace('embedded:app:', taskFormInfo.contextPath + '/');
        
              new CamSDK.Form({
                client: camClient,
                formUrl: url,
                taskId: taskData.id,
                containerElement: $('#camunda'),
                done: function (err, camForm) {
                  if (err) {
                    console.log('all', err);
                    throw err;
                  }
        
                  camForm.on('submit-success', function () {
                    removePreloader();
                    //window.postMessage({type: 'submit-success, taskId: '#task_id#' });
                    //window.location.href = '/tasks/';
                    window['submitSuccess-#task_id#'] && window['submitSuccess-#task_id#']()
                    delete window['submitSuccess-#task_id#']
                  });
        
                  camForm.on('submit-error', function (evt, res) {
                    removePreloader();
                    setErrorNotification(res[0]);
                    setTimeout(function () {
                      clearErrorNotification();
                    }, 3000);
                  });
        
                  $('#camunda_complete').off().click(function (e) {
                    e.preventDefault()
                    setPreloader();
                    camForm.submit(function (err) {
                      console.log(err);
                      if (err) {
                        removePreloader();
                        setErrorNotification(err);
                        setTimeout(function () {
                          clearErrorNotification();
                        }, 3000);                
                        if (window.onError) {
                          if (err.message) {
                            var subString = 'TaskListener:';
                            var hasSubString = new RegExp(subString).test(err.message);
                            var errorMessage = hasSubString
                              ? err.message.split(subString)[1].trim().replace("'", "")
                              : err.message
                            window.onError(errorMessage);
                          } else {
                            window.onError(err);
                          }
                        }
                        throw err;
                      }
                      else {
                        window['submitSuccess-#task_id#'] && window['submitSuccess-#task_id#']();
                        //логируем успешное выполнение завершения задачи
                        // $.post('/api/task/log_task_completion',
                        //     {
                        //         task_id: taskData.id
                        //     },
                        //     function (res) {
                        //         //обработка ошибки логирования
                        //         if (res.status == 'error') {
                        //             //$container.removeOverlay();
                        //             var $scope = angular.element('.start-form-section form').scope();
                        //             if ($scope.$$camForm.$valid) {
                        //                 //uas.flash.error('Ошибка логирования');
                        //                 throw err;
                        //             }
                        //         }
                        //     });
                      }
                    });
                  });
                }
              });
            });
          }
            
          function getTask(taskId) {
            taskService.get(taskId, function (err, res) {
              if (err) {
                if (err.status === 404) {
                  $('#camunda').html(
                    '<div class="error-code error-404"> ' +
                    '<i>404</i> ' +
                    '<h1>Задача не найдена</h1> ' +
                    '<p>Запрашиваемая вами задача не найдена. <a class="js_modal_close" href="close">Закрыть окно</a>.</p> ' +
                    '</div>'
                  );
                  return;
                }
                if (err.status === 401) {
                  $('#camunda').html(
                    '<div class="error-code error-401"> ' +
                    '<i>401</i> ' +
                    '<h1>Ошибка авторизации</h1> ' +
                    '<p>Произошла ошибка при попытке авторизации. <a class="js_modal_close" href="close">Закрыть окно</a>.</p> ' +
                    '</div>'
                  );
                  return;
                }
                $('#camunda').html(
                  '<div class="error-code"> ' +
                  '<i>Assign me</i> ' +
                  '<h1>Assign me</h1> ' +
                  '<p>Мы сожалеем, но что-то пошло не так. <a class="js_modal_close" href="close">Закрыть окно</a>.</p> ' +
                  '</div>'
                );
                return;
              }
              
              openForm(res);
            });
          }
          
          function setErrorNotification(content) {
            var innerHtml =
              '<span class="notification__text">' +
              content +
              '</span>' +
              '<button type="button" class="notification__reload-link">' +
              '    <i class="icon icon-close-s" />' +
              '</button>'
              .replace(/#content#/g, content);
            
            var elem = $(document.createElement('div'))
              .addClass('notification')
              .attr('id', 'error-note')
              .html(innerHtml);
              
            $('#root').append(elem);
            
            $('.notification__reload-link').click(function() {
              clearErrorNotification();
            });
          }
          
          function clearErrorNotification() {
            $('#error-note').remove();
          }
        
          function setPreloader() {
            var innerHtml = 
              '<svg viewBox="-2000 -1000 4000 2000">' +
                '<path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z" />' +
                '<use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px" />' +
              '</svg>';
              
            var elem = $(document.createElement('div'))
              .addClass('preloader preloader--big')
              .attr('id', 'preloader')
              .html(innerHtml);
              
            $('#root').append(elem);
          }
          
          function removePreloader() {
            $('#preloader').remove();
          }
          
          getTask('#task_id#');
        });
      } catch (err) {
        console.log(err);
      }
    `
      .replace(/#process_definition_key#/g, processDefinitionKey)
      .replace(/#task_id#/g, id)
  }

  initNgApp() {
    const { id, activeTask, onCloseDetail } = this.props
    const { processDefinitionKey } = activeTask
    if (!processDefinitionKey) {
      onCloseDetail()
    }

    window[`submitSuccess-${id}`] = this.onFormSubmitSuccess.bind(this)
    window.onError = this.onError.bind(this)

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.innerHTML = TaskCamundaDetail.getFormScript(id, processDefinitionKey)

    const taskDetailBlock = document.querySelector('#task-detail')
    taskDetailBlock.appendChild(script)
  }

  componentDidMount() {
    const { id, activeTask, dispatch } = this.props
    if (id) {
      dispatch(setActiveTask(id))
    }
    
    if (activeTask && activeTask.processDefinitionKey) {
      this.initNgApp()
    }

    document.addEventListener('keyup', this.onEscape, false)
  }

  componentWillUnmount() {
    const { id } = this.props
    document.removeEventListener('keyup', this.onEscape, false)
    delete window[`submitSuccess-${id}`]
    delete window.onError
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeTask } = this.props
    if (
        (
          !(prevProps.activeTask && prevProps.activeTask.processDefinitionKey) && 
          activeTask && 
          activeTask.processDefinitionKey
        ) ||
        prevProps.id !== this.props.id
    ) {
      this.initNgApp()
    }
  }

  onEscape = (e) => {
    if (isPressEscape(e)) {
      const modalCloseElements = document.getElementsByClassName('fz-modal__button-close')
      if (modalCloseElements.length > 0) {
        // modalCloseElements[0].click()
      } else {
        this.props.onCloseDetail()
      }
    }
  }

  onRefreshTasksList = (id) => {
    const { filters, dispatch } = this.props
    dispatch(getTasks(filters, id))
  }

  onRedirectToTasks = () => {
    const { id, history } = this.props
    history.push({
      pathname: '/tasks',
      state: { 
        // BG-545 + BG-802
        fromTask: true,
      }
    })
    this.onRefreshTasksList(id)
    // window.location.href = '/tasks/'
  }

  onCompleteTask = (e) => {
    const { id } = this.props
    if (!e.defaultPrevented) {
      window[`submitSuccess-${id}`] && window[`submitSuccess-${id}`]()
      delete window[`submitSuccess-${id}`]
      this.onRefreshTasksList(id)
    }
  }

  onFormSubmitSuccess() {
    const { activeTask } = this.props
    const { isWizzard } = activeTask || {}
    if (isWizzard) {
      this.waitNextTask()
    } else {
      this.onRedirectToTasks()
    }
  }

  waitNextTask = async (iteration = 0) => {
    const { dispatch, settings, history } = this.props
    this.setState({ loading: true })
    const nextTask = await this.onGetNextTask()
    if (nextTask) {
      dispatch(insertTask(nextTask))
      dispatch(setActiveTask(nextTask.id))
      this.setState({ loading: false })
      // this.initNgApp()
      history.replace(`/tasks/${nextTask.id}`, { title: nextTask.title })
    } else {
      if ((iteration * settings.poolingInterval) < settings.poolingMaxTime && nextTask === undefined) {
        setTimeout(() => {
          this.waitNextTask(iteration + 1) 
        }, settings.poolingInterval * 1000)
      } else {
        this.setState({ 
          loading: false, 
          hasNotNextTask: true, 
        })
      }
    }
  }

  onGetNextTask = async () => {
    const { activeTask = {} } = this.props
    try {
      const { isSuccess, ...resp } = await getNextTask(activeTask.id)
      if (!isSuccess) {
        return null
      }
      if (!resp.nextTask) {
        this.setState({ wizzardNoNextText: resp.wizzardNoNextText })
        if (!resp.wizzardNoNextText) {
          this.onRedirectToTasks()
        }
      }
      return resp.nextTask
    } catch (err) {
      this.onError(err)
      return null
    }
  }

  onError = (error) => this.props.dispatch(setErrorContent(error))

  render() {
    const { title, onCloseDetail, activeTask = {} } = this.props
    const { loading, hasNotNextTask, wizzardNoNextText } = this.state
    
    return (
      <>
        <div className='modal-content__header'>
          <div>
            <div className='modal-content__title modal-content__title--task'>
              <span className='icon icon-ok'></span>
              <span className='task-title' dangerouslySetInnerHTML={{ __html: activeTask.title || title }} />
            </div>
          </div>
        </div>
        <div className='modal-content__body' id='task-detail'>
          {loading && !hasNotNextTask 
            ? <Overlay /> 
            : hasNotNextTask 
              ? <div className='loading-indicator'>{activeTask.wizzardNoNextText || wizzardNoNextText}</div>
              : <div id='camunda'>
                  <div className='loading-indicator'>Подождите, идет загрузка...</div>
                </div>
          }
        </div>
        <div className='modal-content__footer'>
          {hasNotNextTask 
            ? <button className='btn-form btn-form--ok' type='button' id='camunda_complete' onClick={onCloseDetail}>Закрыть</button>
            : <>
                <button className='btn-form btn-form--none' type='button' onClick={onCloseDetail}>Отменить</button>
                <button className='btn-form btn-form--ok' type='button' id='camunda_complete' onClick={this.onCompleteTask}>Завершить</button>
              </>
          }
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ Tasks, Settings }, { title, id }) => {
  return {
    activeTask: getActiveTask(Tasks),
    isFetching: Tasks.isFetching,
    filters: Tasks.filters,
    title: title || Tasks.tasks[id],
    settings: Settings.nextTask || {},
  }
}

export default withRouter(connect(mapStateToProps)(TaskCamundaDetail))