import { THEME_COLOR } from '../../config'

const h1 = {
  paddingBottom: 20,
  fontWeight: 500,
  fontSize: 22,
  lineHeight: 1.2,
  color: '#000',
}

const title = {
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1.5,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
}

const button = {
  fontFamily: 'Montserrat',
  borderRadius: 8,
  border: 'none',
  color: '#fff',
  fontSize: 14,
  lineHeight: 1.8,
  padding: [6, 30],
  cursor: 'pointer',
  outline: 'none',
  textAlign: 'center',
  display: 'inline-block',
  verticalAlign: 'top',
  textDecoration: 'none',
  transition: 'all 300ms ease',
  '&:hover': {
    color: '#fff',
    textDecoration: 'none',
    boxShadow: [0, 2, 4, '#ccc'],
    outline: 'none',
  },
  '&:focus': {
    outline: 'none',
  },
  '&:disabled': {
    opacity: 0.5,
    cursor: 'default',
  },
}

const addButton = {
  ...button,
  background: THEME_COLOR,
  borderColor: THEME_COLOR,
  // '&:active': {
  //   background: '#199083',
  //   borderColor: '#199083',
  // },
  '&.disabled': {
    cursor: 'default',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      background: THEME_COLOR,
      borderColor: THEME_COLOR,
      boxShadow: 'none',
    },
  },
}

const removeButton = {
  ...button,
  background: '#b8b8b8',
  borderColor: '#b8b8b8',
  '&:hover': {
    background: '#F45F5F',
    borderColor: '#F45F5F',
  },
}

const cancelButton = {
  ...button,
  background: '#504E5B',
  borderColor: '#504E5B',
  '&:hover': {
    background: '#6A6873',
    borderColor: '#6A6873',
  },
  '&:active': {
    background: '#504E5B',
    borderColor: '#504E5B',
  },
}

const table = {
  marginTop: 20,
  '& td': {
    verticalAlign: 'top',
    '& > div': {
      lineHeight: '26px',
      paddingTop: 6,
      paddingBottom: 6,
    },
  },
  '& tr': {
    borderTop: [1, 'solid', '#dee2e6'],
  },
}

export default {
  h1,
  title,
  addButton,
  removeButton,
  cancelButton,
  table,
}