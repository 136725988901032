import React, { useEffect, useState, memo, useMemo, Fragment } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFactoringContractsBySupplierInn,
  getFactoringDebtors,
  getFactoringTasks,
  startFactoringProcessTransit,
} from '../../../../redux/Factoring/actions'
import { Modal2, Select2, Fieldset, Label, RowGrid, Col3, Col9, Col12, Error, InputText } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Toast } from '../../../../components/Toast'
import { Button, ButtonWrapper, ButtonAdd, ButtonEdit, ButtonRemove, Form, H3 } from '../../Layout'
import { NonDeliverableTransitModalInner } from './NonDeliverableTransitModalInner'
import { formattedDate, formattedNumber, onlyDigitsWithDot } from '../../../../utils'
import styles from '../../styles'
import Overlay from '../../../../components/Overlay'

const useStyles = createUseStyles({
  nonDeliverableTransitModal: {
    width: 1000,
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
    height: 'inherit',
  },
  ...styles,
  supply: {
    height: 56,
    padding: [20, 0, 20, 10],
    fontSize: 14,
    lineHeight: 16/14,
    border: [1, 'solid', '#0b1f35'],
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& + &': {
      marginTop: 10,
    },
    '& > div': {
      display: 'flex',
    },
  },
  total: {
    fontSize: 14,
    '&:first-child': {
      paddingBottom: 4,
      fontSize: 16,
      fontWeight: 600,
    },
    '& + &': {
      marginTop: 8,
    },
  },
  loader: {
    position: 'absolute',
  },
})

const NEW_FINANCING_INDEX = -1

export const NonDeliverableTransitModal = memo(
  ({
    ordersQueue,
    payment,
    taskFilters,
    requestPayload,
    onClose,
    onResetOrder,
  }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { documents, isFetching } = useSelector(state => state.Factoring)

    const [supplyContracts, setSupplyContracts] = useState([])
    const [factoringContractsBySupplierInn, setFactoringContractsBySupplierInn] = useState([])
    const [debtors, setDebtors] = useState([])
    const [supplierName, setSupplierName] = useState('')
    const [supplierInn, setSupplierInn] = useState('')
    const [factoringContractDate, setFactoringContractDate] = useState('')
    const [factoringContractNumber, setFactoringContractNumber] = useState('')
    const [factoringContractId, setFactoringContractId] = useState('')
    const [financings, setFinancings] = useState([])
    const [financing, setFinancing] = useState({})
    const [index, setIndex] = useState(NEW_FINANCING_INDEX)
    const [openModal, setOpenModal] = useState(false)
    const [submitted, setSubmitted] = useState(0)

    const touched = useMemo(() => submitted > 0, [submitted])

    useEffect(() => {
      console.log('ordersQueue:', ordersQueue)
      if (ordersQueue) {
        // eslint-disable-next-line array-callback-return
        ordersQueue.map((order) => {
          setSupplierName(order?.supplierName)
          setSupplierInn(order?.supplierInn)
          setFactoringContractId(order?.externalEntity?.factoringContractId)
          setFactoringContractDate(order?.externalEntity?.factoringContractDate)
          setFactoringContractNumber(order?.externalEntity?.factoringContractNumber)
          setFactoringContractsBySupplierInn([{
            id: order?.externalEntity?.factoringContractId,
            number: order?.externalEntity?.factoringContractNumber,
          }])
          setFinancings(prev => [...prev, {
            acceptanceDate: order?.externalEntity?.acceptanceDate,
            confirmationDocument: order?.externalEntity?.confirmationDocuments,
            currency: order?.externalEntity?.currency,
            debtorInn: order?.externalEntity?.debtorInn,
            factoringContractDate: order?.externalEntity?.factoringContractDate,
            factoringContractNumber: order?.externalEntity?.factoringContractNumber,
            hasOriginals: order?.externalEntity?.hasOriginals,
            supplierInn: order?.externalEntity?.supplierInn,
            supplyContractDate: order?.externalEntity?.supplyContractDate,
            supplyContractNumber: order?.externalEntity?.supplyContractNumber,
            supplySum: `${order?.externalEntity?.sum}`,
            // matchingAmount: `${order?.externalEntity?.sum}`,
          }])
          setDebtors([
            {
              name: '',
              value: '',
            },
            {
              name: order?.debtorName,
              value: order?.debtorInn,
            },
          ])
          setSupplyContracts([
            {
              name: '',
              value: '',
            },
            {
              inn: order?.debtorInn,
              name: order?.externalEntity?.supplyContractNumber,
              number: order?.externalEntity?.supplyContractNumber,
              date: order?.externalEntity?.supplyContractDate,
              id: order?.externalEntity?.supplyContractId,
              value: order?.externalEntity?.supplyContractId,
            },
          ])
        })
      }
    }, [ordersQueue])

    const {
      id: paymentId,
      unmatchedAmount,
    } = payment || {}
    
    const matchingAmountTotal = financings?.reduce((result, item) => result + Number(item?.matchingAmount), 0)

    const factoringContractOptions = [
      { value: '', name: '' },
      ...factoringContractsBySupplierInn.map(item => ({ value: item.id, name: item.number }))
    ]

    const payload = {
      paymentId,
      supplies: financings.map(item => ({
        ...item,
        supplierInn,
        factoringContractDate,
        factoringContractNumber,
        confirmationDocument: (item?.confirmationDocuments || item?.confirmationDocument || []).reduce((result, item) => {
          if (item.type && item.number && item.date) {
            result.push(item)
          }
          return result
        }, [])
      })),
    }

    const errors = {
      ...(supplierInn?.trim() ? {} : { supplierInn: 'Не указан поставщик' }),
      ...(factoringContractNumber?.trim()
        ? {}
        : { factoringContractNumber: supplierInn?.trim()
            ? 'Не указан номер договора факторинга'
            : 'Не указан номер договора факторинга (необходимо указать поставщика)'
          }),
      ...(factoringContractDate?.trim() ? {} : { factoringContractDate: 'Не указана дата договора факторинга' }),
      ...(financings?.length > 0 ? {} : { financings: 'Не указана поставка' }),
      ...(financings?.some(item => !item?.matchingAmount?.trim()) ? { matchingAmount: 'Не указана сумма зачисления' } : {}),
      ...(financings?.reduce((result, item) => result + parseFloat(item?.supplySum) - parseFloat(item?.matchingAmount), 0) < 0 ? { totalMatchingAmount: 'Сумма распределения превышает сумму поставок' } : {}),
    }

    const isValid = Object.keys(errors)?.length === 0

    const onChangeSupplierInn = ({ INN, displayName }) => {
      setSupplierInn(INN)
      setSupplierName(displayName)
      const entityType = 'factoring_contract'
      const payload = { supplierInn: INN }
      const onSuccess = (data) => setFactoringContractsBySupplierInn(data)
      dispatch(getFactoringContractsBySupplierInn(entityType, payload, onSuccess))
    }
    const onClearSupplierInn = () => {
      setSupplierInn('')
      setSupplierName('')
      setFactoringContractsBySupplierInn([])
      setFactoringContractId('')
      setFactoringContractNumber('')
      setFactoringContractDate('')
    }

    const onChangeFactoringContract = (id, number, startDate) => {
      setFactoringContractId(id)
      setFactoringContractNumber(number)
      setFactoringContractDate(startDate)
      const onSuccess = (data) => {
        setDebtors([
          { name: '', value: '' },
          ...data.map(item => ({
            name: item.displayName,
            value: item.inn,
          })),
        ])
        setSupplyContracts([{ name: '', value: '' }, ...data
          .reduce((result, item) => {
            item.supplyContracts.forEach(supplyContract => result.push({
              ...supplyContract,
              inn: item.inn,
            }))
            return result
          }, [])
          .map(item => ({
            ...item,
            name: item.number,
            value: item.id,
          }))
        ])
      }
      dispatch(getFactoringDebtors(id, onSuccess))
    }

    const onAddFinancing = (payload) => setFinancings([...financings, payload])
    const onEditFinancing = (payload, index) => {
      const updatedFinancings = financings.map((item, idx) => {
        if (index === idx) {
          item = payload
        }
        return item
      })
      setFinancings(updatedFinancings)
    }
    const onRemoveFinancing = (index) => setFinancings(financings.filter((_, idx) => index !== idx))

    const onOpenEditFinancing = (payload, index) => {
      setFinancing({
        ...payload,
        supplierInn,
        factoringContractDate,
        factoringContractNumber,
      })
      setIndex(index)
      setOpenModal(true)
    }

    const onOpenModal = () => {
      setOpenModal(true)
      setIndex(NEW_FINANCING_INDEX)
    }
    const onCloseModal = () => setOpenModal(false)

    const onStartFactoringProcess = () => {
      const onSuccess = () => {
        onResetOrder()
        dispatch(getFactoringTasks(requestPayload, taskFilters))
        onClose()
      }
      if (isValid) {
        if (matchingAmountTotal > unmatchedAmount) {
          Toast({
            type: 'error',
            message: 'Сумма выбранных ордеров больше суммы выбранного платежа',
          })
        } else {
          dispatch(startFactoringProcessTransit(payload, 'factoring-pa-fin-transit', onSuccess))
        }
      }
    }

    useEffect(() => {
      if (touched) {
        onStartFactoringProcess()
      }
    // eslint-disable-next-line
    }, [touched, submitted])

    const total = {
      count: financings?.length || 0,
      supplySum: formattedNumber(financings?.reduce((result, item) => result + parseFloat(item?.supplySum), 0)),
      matchingAmount: formattedNumber(financings?.reduce((result, item) => item?.matchingAmount ? result + parseFloat(item?.matchingAmount) : result + 0, 0))
    }

    console.log('payload [1-st form]:', payload)
    console.log('errors [1-st form', errors)
    return (
      <Modal2
        className={classes.nonDeliverableTransitModal}
        onClose={onClose}
      >
        <div className={classes.wrapper}>
          <H3>Реестр поставок</H3>
          <Form>
            <Fieldset>
              <RowGrid>
                <Col12>
                  <TextFieldWithAutoComplete
                    classNames={{
                      container: 'filter-input',
                      input: 'filter-input__input',
                    }}
                    name='supplierInn'
                    placeholder='Поставщик'
                    label='Поставщик'
                    value={supplierName}
                    defaultValue={supplierName}
                    onSelect={onChangeSupplierInn}
                    onClear={onClearSupplierInn}
                    payloadKeys={['INN', 'displayName']}
                    meta={{
                      touched,
                      error: errors.supplierInn,
                    }}
                    withoutLink
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
            <Fieldset>
              <RowGrid>
                <Col12>
                  <RowGrid>
                    <Col12>
                      <Select2
                        label='Номер договора факторинга'
                        name='factoringContract'
                        options={factoringContractOptions}
                        value={factoringContractId}
                        onChange={(e) => {
                          const id = e.target.value
                          const { number, startDate } = factoringContractsBySupplierInn.find(item => item?.id === id) || {}
                          onChangeFactoringContract(id, number, startDate)
                        }}
                        error={touched && (errors.factoringContractNumber || errors.factoringContractDate)}
                        disabled={!supplierInn}
                      />
                    </Col12>
                  </RowGrid>
                </Col12>
              </RowGrid>
            </Fieldset>
            <Fieldset>
              <RowGrid>
                {financings?.length > 0
                  ? <>
                      <Col9>
                        <Label>Поставки:</Label>
                      </Col9>
                      <Col3>
                        <Label>Сумма зачисления</Label>
                      </Col3>
                    </>
                  : <Col12>Поставки отсутствуют</Col12>
                }
              </RowGrid>
              <RowGrid>
                <Col12>
                  {financings?.map((item, index) => {
                    const { confirmationDocument, debtorName, supplySum, matchingAmount } = item || {}
                    const { number, date, type } = confirmationDocument?.length > 0 ? confirmationDocument[0] : {}
                    const docType = documents.find(({ id }) => id === type)?.name || ''
                    return (
                      <Fragment key={index}>
                        <RowGrid>
                          <Col9>
                            <div className={classes.supply} >
                              <div>
                                {number ? `Поставка: ${number}, ` : null}
                                {debtorName ? `${debtorName}, ` : null}
                                {date ? `Дата: ${formattedDate(date)}, ` : null}
                                {docType ? `Тип документа: ${docType}, ` : null}
                                {supplySum ? `Сумма: ${supplySum}, ` : null}
                                {matchingAmount ? `Зачисление: ${matchingAmount}` : null}
                              </div>
                              <div>
                                <ButtonEdit
                                  className={classes.actionBtn}
                                  title='Редактировать поставку'
                                  onClick={() => onOpenEditFinancing(item, index)}
                                />
                                <ButtonRemove
                                  className={classes.actionBtn}
                                  title='Удалить поставку'
                                  onClick={() => onRemoveFinancing(index)}
                                />
                              </div>
                            </div>
                          </Col9>
                          <Col3>
                            <InputText
                              type='text' 
                              name='matchingAmount'
                              value={matchingAmount}
                              onChange={e => onEditFinancing({
                                ...item,
                                matchingAmount: onlyDigitsWithDot(e?.target?.value),
                              }, index)}
                              error={touched && !matchingAmount?.trim() && errors?.matchingAmount}
                            />
                          </Col3>
                        </RowGrid>
                      </Fragment>
                    )
                  })}
                  {touched && errors.financings &&
                    <Error>{errors.financings}</Error>
                  }
                </Col12>
              </RowGrid>
              <RowGrid>
                <Col12>
                  <ButtonAdd onClick={onOpenModal}>Добавить поставку</ButtonAdd>
                </Col12>
              </RowGrid>
            </Fieldset>
            <Fieldset>
              <RowGrid>
                <Col12>
                  <div className={classes.total}>ИТОГО:</div>
                  <div className={classes.total}>Количество поставок: {total.count}</div>
                  <div className={classes.total}>Сумма поставок: {total.supplySum}</div>
                  <div className={classes.total}>Сумма зачисления: {total.matchingAmount}</div>
                  {touched && errors.totalMatchingAmount &&
                    <Error>{errors.totalMatchingAmount}</Error>
                  }
                </Col12>
              </RowGrid>
            </Fieldset>
          </Form>
          <ButtonWrapper>
            <Button
              disabled={touched && !isValid}
              onClick={() => setSubmitted(submitted + 1)}
            >
              Квитовать
            </Button>
          </ButtonWrapper>
          {openModal &&
            <NonDeliverableTransitModalInner
              index={index}
              financing={financing}
              supplyContracts={supplyContracts}
              debtors={debtors}
              onAddFinancing={onAddFinancing}
              onEditFinancing={onEditFinancing}
              onClose={onCloseModal}
            />
          }
        </div>
        {isFetching &&
          <Overlay className={classes.loader} />
        }
      </Modal2>
    )
  }
)