import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    display: 'inline-block',
    verticalAlign: 'middle',
    '& + &': {
      paddingLeft: 15,
    },
    '& svg': {
      width: 24,
      height: 24,
      display: 'block',
    },
  },
})

export const Item = ({ children, style }) => {
  const classes = useStyles()
  return (
    <div className={classes.root} style={style}>{children}</div>
  )
}