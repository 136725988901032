import React from 'react'
import { createUseStyles } from 'react-jss'
import { Icon } from './../../../../layout'

const useStyles = createUseStyles({
  reset: {
    minWidth: 160,
    height: 16,
    border: 'none',
    cursor: 'pointer',
    transition: '300ms',
    '&:focus': {
      outline: 0,
    },
    '&:hover': {
      opacity: .7,
    },
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 4,
      fill: '#484848',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& span': {
      fontSize: 13,
      lineHeight: 1.2,
      fontWeight: 500,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
})

export const ResetFilters = ({ onClick }) => {
  const classes = useStyles()
  return (
    <div className={classes.reset} onClick={onClick}>
      <Icon iconName='close2' />
      <span>Сбросить фильтры</span>
    </div>
  )
}