import React from 'react'
import injectSheet from 'react-jss'
import { Modal } from '../../index'
import { formattedNumberToGekto } from './../../../utils'

const styles = {
  list: {
    marginTop: -20,
  },
	item: {
    marginTop: 20,
    marginRight: 80,
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '& p': {
      opacity: 0.5,
    },
  },
}

const Parameters = ({ 
  classes, 
  // className, 
  parameters, 
}) => parameters && parameters.length > 0
  ? parameters.map(({ name, attributes }, index) =>
      <Modal.Block iconName='request' title={name} key={index}>
        <Modal.Content>
          <div className={classes.list}>
            {attributes.map(({ value, name, type }, index) =>
              <div className={classes.item} key={index}>
                <Modal.Title>{name}</Modal.Title>
                <Modal.Value>
                  {type === 'money' 
                    ? formattedNumberToGekto(value)
                    : value
                  }
                </Modal.Value>
              </div>
            )}
          </div>
        </Modal.Content>
      </Modal.Block>
    )
  : null

export default injectSheet(styles)(Parameters)