import axios from 'axios'
import { API_SPA_URL, DEFAULT_PAGE_SIZE } from './../../config'

export let promiseGetOrders = {cancel: () => {}}
export const getOrders = (filters = {}, limit = DEFAULT_PAGE_SIZE) => {
  const signal = axios.CancelToken.source()
  const promise = new Promise(async (resolve, reject) => {
    try {
      const params = {
        limit,
        ...filters
      }
      const {
        data: {
          error_code: status,
          ...rest
        }
      } = await axios({
        method: 'GET',
        params,
        url: `${API_SPA_URL}/order/list`,
        cancelToken: signal.token
      })

      if (status === 0) {
        resolve({
          isSuccess: true,
          ...rest
        })
      }

      resolve({
        isSuccess: false,
        needLogout: status === 5,
        message: rest.error
      })
    } catch (err) {
      console.error(err)

      if (!axios.isCancel(err)) {
        reject({
          isSuccess: false,
          message: err.message
        })
      }
    }
  })
  promise.cancel = () => signal.cancel('getOrders is being canceled')
  promiseGetOrders = promise
  return promise
}

export const getNextOrders = async (page, filters = {}, limit = DEFAULT_PAGE_SIZE) => {
  try {
    const params = {
      page,
      limit,
      ...filters
    }
    const { 
      data: {
        error_code: status, 
        ...rest
      } 
    } = await axios({
      method: 'GET',
      params,
      url: `${API_SPA_URL}/order/list`,
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}