import React from 'react'
import { createUseStyles } from 'react-jss'
import { SubTitle, Value } from '../index'
import { formatMoney } from '../../../../../../utils'

const useStyles = createUseStyles({
  root: {
    padding: [17, 50, 14],
    border: [1, 'solid', '#ddd'],
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export const Legend = ({
  COLORS,
  utilizedAmount,
  frozenAmount,
  totalAmount,
}) => {
  const classes = useStyles()
  const items = [
    {
      key: 'used',
      text: 'Использовано',
      color: COLORS.used,
      value: formatMoney(utilizedAmount),
    },
    {
      key: 'frozen',
      text: 'Заморожено',
      color: COLORS.frozen,
      value: formatMoney(frozenAmount),
    },
    {
      key: 'total',
      text: 'Доступно',
      color: COLORS.total,
      value: formatMoney(totalAmount),
    },
  ]
  return (
    <div className={classes.root}>
      {items.map(({ key, text, color, value }) =>
        <div key={key}>
          <SubTitle>{text}</SubTitle>
          <Value>
            <span style={{ background: color }} />
            {value}
          </Value>
        </div>
      )}
    </div>
  )
}