import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import { Body, Fieldset, Header, Item, SubTitle, SwitchSlider } from '../../Layout'

export const PercentAccrual = ({
  date,
  enabled,
  orderNumber,
}) => {
  const [open, setOpen] = useState(false)
  return (
    <Item>
      <Header
        name='Процент на остаток'
        open={open}
        date={date}
        enabled={enabled}
        orderNumber={orderNumber}
        onCollapse={() => setOpen(!open)}
      />
      <Collapse isOpen={open}>
        <Body>
          <SubTitle>Услуга по зачислению процентов на остаток на счете</SubTitle>
          <Fieldset>
            <SwitchSlider
              id='percentAccrual'
              name='percentAccrual'
              label='Услуга по начислению процентов на сумму денежных средств на счете'
              checked={enabled}
              disabled
            />
          </Fieldset>
        </Body>
      </Collapse>
    </Item>
  )
}