import React, { useEffect, useState, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getFactoringMasterOperation, getFactoringSuretyTypes, createFactoringOperation } from '../../../../redux/Factoring/actions'
import { Fieldset, Select2, InputText, InputWithDadata, RowGrid, Col12, Modal2 } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Search } from '../../../../services/api'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'

const useStyles = createUseStyles({
  addOperationForm: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
})

export const AddOperationForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams()

  const {
    masterOperationTypes,
    operationCodeTypes,
    suretyTypes,
    masterOperationId,
  } = useSelector(state => state?.Factoring)

  const [creditAccount, setCreditAccount] = useState('')
  const [creditBic, setCreditBic] = useState('')
  const [creditBankName, setCreditBankName] = useState('')
  const [creditBankCity, setCreditBankCity] = useState('')
  const [creditCorrAccount, setCreditCorrAccount] = useState('')
  const [debitAccount, setDebitAccount] = useState('')
  const [debitBic, setDebitBic] = useState('')
  const [debitBankName, setDebitBankName] = useState('')
  const [debitBankCity, setDebitBankCity] = useState('')
  const [debitCorrAccount, setDebitCorrAccount] = useState('')
  const [purpose, setPurpose] = useState('')
  const [description, setDescription] = useState('')
  const [operationDate, setOperationDate] = useState('')
  const [sum, setSum] = useState('')
  const [code, setCode] = useState('')
  const [masterOperationValue, setMasterOperationValue] = useState('')
  const [supplyId, setSupplyId] = useState('')
  const [supplyNumber, setSupplyNumber] = useState('')
  const [factoringContractId, setFactoringContractId] = useState('')
  const [factoringContractNumber, setFactoringContractNumber] = useState('')
  const [financingId, setFinancingId] = useState('')
  const [financingNumber, setFinancingNumber] = useState('')
  const [suretyId, setSuretyId] = useState('')
  const [submitted, setSubmitted] = useState(0)

  const touched = useMemo(() => submitted > 0, [submitted])

  const masterOperationTypeOptions = [
    { value: '', name: '' },
    ...masterOperationTypes
  ]

  const suretyTypeOptions = [
    { name: '', value: '' },
    ...suretyTypes.map(item => ({ value: item.id, name: item.number }))
  ]

  const operationCodeOptions = [
    { value: '', name: '' },
    ...operationCodeTypes,
  ]

  const masterOperationEntity = useMemo(() => {
    return masterOperationTypeOptions.find(item => item?.value === masterOperationValue)?.entity
  }, [masterOperationTypeOptions, masterOperationValue])

  const isSupply = masterOperationEntity === 'SUPPLY'
  const isFinancing = masterOperationEntity === 'FINANCING'
  const isSuretyRelation = masterOperationEntity === 'SURETY_RELATION'

  const ENTITY_IDS = useMemo(() => {
    return {
      SUPPLY: supplyId,
      FINANCING: financingId,
      SURETY_RELATION: suretyId,
    }
  }, [supplyId, financingId, suretyId])

  const payload = {
    creditAccount,
    creditBankCity,
    creditBankName,
    creditBic,
    creditCorrAccount,
    debitAccount,
    debitBankCity,
    debitBankName,
    debitBic,
    debitCorrAccount,
    description,
    masterOperationId,
    operationDate,
    purpose,
    code,
    sum,
  }

  const errors = {
    ...(!creditAccount?.trim() ? { creditAccount: 'Не указан счёт по кредиту' } : {}),
    ...(!creditBankCity || !creditBankName || !creditBic || !creditCorrAccount ? { creditBank: 'Не указан банк' } : {}),
    ...(!debitAccount?.trim() ? { debitAccount: 'Не указан счёт по дебету' } : {}),
    ...(!debitBankCity || !debitBankName || !debitBic || !debitCorrAccount ? { debitBank: 'Не указан банк' } : {}),
    ...(!purpose?.trim() ? { purpose: 'Не указано назначение платежа' } : {}),
    ...(!description?.trim() ? { description: 'Не указано описание' } : {}),
    ...(!operationDate?.trim() ? { operationDate: 'Не указана дата проводки' } : {}),
    ...(!sum?.trim() ? { sum: 'Не указана сумма' } : {}),
    ...(!code?.trim() ? { code: 'Не указан тип проводки (код)' } : {}),
    ...(!masterOperationValue?.trim() ? { masterOperationValue: 'Не указана мастер-операция' } : {}),
    ...(!masterOperationId?.trim() ? { masterOperationId: 'Не указана мастер-операция' } : {}),
    ...(isSupply && !supplyId?.trim() ? { supplyId: 'Не указана поставка' } : {}),
    ...(isFinancing && !financingId?.trim() ? { financingId: 'Не указан номер финансирования' } : {}),
    ...(isSuretyRelation && !factoringContractId?.trim() ? { factoringContractId: 'Не указан договор факторинга' } : {}),
    ...(isSuretyRelation && !suretyId?.trim() ? { suretyId: 'Не указан договор поручителей' } : {}),
  }

  const isValid = Object.keys(errors)?.length === 0

  const onSubmit = () => setSubmitted(submitted + 1)

  const onCreateFactoringOperation = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(createFactoringOperation(payload, params?.entityType, onSuccess))
    }
  }

  useEffect(() => {
    const entityId = ENTITY_IDS[masterOperationEntity]
    if (entityId && masterOperationValue) {
      dispatch(getFactoringMasterOperation({
        entityId,
        type: masterOperationValue,
      }))
    }
  }, [ENTITY_IDS, masterOperationEntity, masterOperationValue, dispatch])

  useEffect(() => {
    if (factoringContractId) {
      dispatch(getFactoringSuretyTypes(factoringContractId))
    }
  }, [factoringContractId, dispatch])

  useEffect(() => {
    if (touched) {
      onCreateFactoringOperation()
    }
  // eslint-disable-next-line
  }, [touched, submitted])

  const onChangeSupplyId = (payload) => {
    setSupplyId(payload.id)
    setSupplyNumber(payload.number)
  }
  const onClearSupplyId = () => {
    setSupplyId('')
    setSupplyNumber('')
  }

  const onChangeFactoringContractId = (payload) => {
    setFactoringContractId(payload.id)
    setFactoringContractNumber(payload.number)
  }
  const onClearFactoringContractId = () => {
    setFactoringContractId('')
    setFactoringContractNumber('')
  }

  const onChangeFinancingId = (payload) => {
    setFinancingId(payload.id)
    setFinancingNumber(payload.number)
  }
  const onClearFinancingId = () => {
    setFinancingId('')
    setFinancingNumber('')
  }

  console.log('payload [CURRENT]:', payload)
  console.log('errors', errors)

  return (
    <Modal2 className={classes.addOperationForm} onClose={onClose}>
      <div className={classes.wrapper}>
        <H3>Создание проводки</H3>
        <Form>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Select2
                  label='Мастер-операция'
                  name='masterOperationValue'
                  options={masterOperationTypeOptions}
                  value={masterOperationValue}
                  onChange={e => {
                    setMasterOperationValue(e.target.value)
                    setSuretyId(null)
                    setSupplyId(null)
                  }}
                  error={touched && errors.masterOperationValue}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          {isSupply &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <TextFieldWithAutoComplete
                    classNames={{
                      container: 'filter-input',
                      input: 'filter-input__input',
                    }}
                    name='supplyId'
                    placeholder='Поставка'
                    label='Поставка'
                    value={supplyNumber}
                    defaultValue={supplyNumber}
                    onSelect={onChangeSupplyId}
                    onClear={onClearSupplyId}
                    payloadKeys={['id', 'supplyContractNumber']}
                    request={Search.getSupplySuggestions}
                    requestKey='number'
                    requestSubKeys={['supplierName', 'supplyDate']}
                    meta={{
                      touched,
                      error: errors.supplyId,
                    }}
                    withoutLink
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {isFinancing &&
            <Fieldset>
            <RowGrid>
              <Col12>
                <TextFieldWithAutoComplete
                  classNames={{
                    container: 'filter-input',
                    input: 'filter-input__input',
                  }}
                  name='financingId'
                  placeholder='Номер финансирования'
                  label='Номер финансирования'
                  value={financingNumber}
                  defaultValue={financingNumber}
                  onSelect={onChangeFinancingId}
                  onClear={onClearFinancingId}
                  payloadKeys={['id', 'supplyContractNumber']}
                  request={Search.getFactoringFinancingSuggestions}
                  requestKey='number'
                  requestSubKeys={['supplierName', 'supplyDate']}
                  meta={{
                    touched,
                    error: errors.financingId,
                  }}
                  withoutLink
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          }
          {isSuretyRelation &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <>
                    <Fieldset>
                      <RowGrid>
                        <Col12>
                          <TextFieldWithAutoComplete
                            classNames={{
                              container: 'filter-input',
                              input: 'filter-input__input',
                            }}
                            name='factoringContractId'
                            placeholder='Договор факторинга'
                            label='Договор факторинга'
                            value={factoringContractNumber}
                            defaultValue={factoringContractNumber}
                            onSelect={onChangeFactoringContractId}
                            onClear={onClearFactoringContractId}
                            payloadKeys={['id', 'number']}
                            request={Search.getFactoringContractSuggestions}
                            requestKey='number'
                            requestSubKeys={['displayName', 'supplierInn', 'startDate']}
                            meta={{
                              touched,
                              error: errors.factoringContractId,
                            }}
                            withoutLink
                          />
                        </Col12>
                      </RowGrid>
                    </Fieldset>
                    <Fieldset>
                      <RowGrid>
                        <Col12>
                          <Select2
                            label='Договор поручителей'
                            name='suretyId'
                            options={suretyTypeOptions}
                            value={suretyId}
                            onChange={e => setSuretyId(e.target.value)}
                            error={touched && errors.suretyId}
                          />
                        </Col12>
                      </RowGrid>
                    </Fieldset>
                  </>
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          <Fieldset>
            <RowGrid>
              <Col12>
                <TextFieldWithAutoComplete
                  classNames={{
                    container: 'filter-input',
                    input: 'filter-input__input',
                  }}
                  name='creditAccount'
                  placeholder='Счёт по кредиту'
                  label='Счёт по кредиту'
                  value={creditAccount}
                  defaultValue={creditAccount}
                  onSelect={payload => setCreditAccount(payload.number)}
                  onClear={() => setCreditAccount('')}
                  payloadKeys={['id', 'number']}
                  request={Search.getFactoringAccountSuggestions}
                  requestKey='number'
                  requestSubKeys={['entityId', 'accountType', 'entityType']}
                  meta={{
                    touched,
                    error: errors.creditAccount,
                  }}
                  withoutLink
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputWithDadata
                  type='text'
                  service='bank'
                  name='creditBankName'
                  label='Счёт по кредиту - Банк'
                  placeholder='Введите название банка'
                  value={creditBankName}
                  onSelect={item => {
                    setCreditBankName(item.bankName)
                    setCreditBic(item.bic)
                    setCreditBankCity(item.bankCity)
                    setCreditCorrAccount(item.corrNumber)
                  }}
                  error={touched && errors?.creditBank}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <TextFieldWithAutoComplete
                  classNames={{
                    container: 'filter-input',
                    input: 'filter-input__input',
                  }}
                  name='debitAccount'
                  placeholder='Счёт по дебету'
                  label='Счёт по дебету'
                  value={debitAccount}
                  defaultValue={debitAccount}
                  onSelect={payload => setDebitAccount(payload.number)}
                  onClear={() => setDebitAccount('')}
                  payloadKeys={['id', 'number']}
                  request={Search.getFactoringAccountSuggestions}
                  requestKey='number'
                  requestSubKeys={['entityId', 'accountType', 'entityType']}
                  meta={{
                    touched,
                    error: errors.debitAccount,
                  }}
                  withoutLink
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputWithDadata
                  type='text'
                  service='bank'
                  name='debitBankName'
                  label='Счёт по дебету - Банк'
                  placeholder='Введите название банка'
                  value={debitBankName}
                  onSelect={item => {
                    setDebitBankName(item.bankName)
                    setDebitBic(item.bic)
                    setDebitBankCity(item.bankCity)
                    setDebitCorrAccount(item.corrNumber)
                  }}
                  error={touched && errors?.debitBank}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='purpose'
                  label='Назначение платежа'
                  value={purpose}
                  onChange={e => setPurpose(e.target.value)}
                  error={touched && errors?.purpose}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='description'
                  label='Вид проводки'
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  error={touched && errors?.description}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='date' 
                  name='operationDate'
                  label='Дата проводки'
                  value={operationDate}
                  onChange={e => setOperationDate(e.target.value)}
                  error={touched && errors?.operationDate}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='sum'
                  label='Сумма проводки'
                  value={sum}
                  onChange={e => setSum(e.target.value)}
                  error={touched && errors?.sum}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Select2
                  label='Тип проводки (код)'
                  name='code'
                  options={operationCodeOptions}
                  value={code}
                  onChange={e => setCode(e.target.value)}
                  error={touched && errors.code}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        </Form>
        <ButtonWrapper>
          <Button disabled={touched && !isValid} onClick={onSubmit}>Создать</Button>
        </ButtonWrapper>
      </div>
    </Modal2>
  )
}