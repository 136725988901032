import React from 'react'
import injectSheet from 'react-jss'

const styles = {
	root: {
		paddingRight: '113px',
		position: 'relative',
	},
}

const Head = ({ children, classes }) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Head)