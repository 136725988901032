import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { Pagination } from '../../components/Pagination'
import Overlay from '../../components/Overlay'
import { getFactoringShortInfo } from '../../redux/Factoring/actions'
import { getFile } from '../../redux/Files/actions'
import { Icon, SwitchSlider } from '../../layout'
import Header from '../../containers/Header'
import { Burger } from '../../components/Burger'
import { Toast } from '../../components/Toast'
import HeaderFilter from '../../containers/HeaderFilter'
import { Title, Button, ResetFilters } from './Layout'
import { getPaginationSize } from '../../utils/paginationStorage'
import { createSearchString, formattedNumber } from '../../utils'
import { getFilterItem } from './getFilterItem'
import { FactoringModal } from './FactoringViewPage/FactoringModal'
import { SupplyOperationForm } from './Modals'
import { API_URL } from '../../config'
import { getCompanyInfo } from '../../redux/Company/actions'

const useStyles = createUseStyles({
  wrapper: {
    padding: [90, 20, 20, 20],
  },
  wrapperAux: {
    position: 'relative',
  },
  filterItem: {
    flex: 1,
    '& + &': {
      marginLeft: 10,
    },
  },
  xls: {
    width: 24,
    height: 24,
    marginLeft: 12,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
    '& > svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  switcher: {
    marginTop: 10,
  },
})

const DEFAULT_OFFSET = { offset: 0 }
const STATIC_FILTERS = ['limit', 'offset']

export const FactoringWrapper = ({
  id,
  title,
  dataFilters,
  getFilters,
  entityType,
  onRequest,
  requestPayload,
  children,
  withoutPagination,
  autoHeight,
  refetch,
  checkedItems = [],
  additionalInfo = null,
  selected = [],
  isMonitoringIssues = false,
  isMonitoringNotifications = false,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const SIZE = getPaginationSize()

  const [showSupplyOperationForm, setShowSupplyOperationForm] = useState(false)

  const {
    tasks,
    views,
    issueNotifications,
    shortInfo,
    isFetching,
  } = useSelector(state => state?.Factoring || {})

  const {
    isFetching: isFetchingFile,
  } = useSelector(state => state?.Files || {})

  const {
    company
  } = useSelector(state => state?.Company || {})

  const {
    page = 1,
    totalCount,
    totalPages,
  } = isMonitoringIssues
    ? tasks
    : isMonitoringNotifications
      ? issueNotifications
      : views

  const isHideTable = history.location.hash.includes('hideTable')

  const onShowHideTable = (hash) => history.push({
    pathname: history.location.pathname,
    search: createSearchString(filters),
    hash,
  })

  const filtersInitialState = dataFilters.reduce((result, item) => {
    result[item.name] = item.type === 'select'
      ? item.options[0].id
      : ''
    return result
  }, {})

  const parsed = useMemo(() => {
    return queryString.parse(history.location.search)
  }, [history.location])

  const defaultFilters = useMemo(() => {
    return {
      ...filtersInitialState,
      limit: parsed.limit || SIZE,
      offset: parsed.offset || DEFAULT_OFFSET.offset,
    }
  }, [parsed, filtersInitialState, SIZE])

  const parsedFilters = useMemo(() => {
    return {
      ...filtersInitialState,
      ...parsed,
      ...(entityType === 'financing' ? { transits: 'NONE' } : {}),
      limit: parsed.limit || defaultFilters.limit,
      offset: parsed.offset || defaultFilters.offset,
    }
  }, [parsed, filtersInitialState, defaultFilters.limit, defaultFilters.offset, entityType])

  const [filters, setFilters] = useState(parsedFilters)
  const [values, setValues] = useState(parsedFilters)

  useEffect(() => {
    if (history.location?.state?.resetFilters) {
      setFilters(defaultFilters)
      setValues({})
    }
    // eslint-disable-next-line
  }, [entityType, history.location])

  useEffect(() => {
    history.push({
      pathname: history.location.pathname,
      search: createSearchString(filters),
      hash: history.location.hash.split('&')[0],
    })
  }, [filters, history])

  useEffect(() => {
    getFilters && getFilters(filters)
  }, [filters, getFilters])

  useEffect(() => {
    if (shortInfo?.showFieldName && shortInfo?.displayName) {
      setValues({
        ...values,
        [shortInfo.showFieldName]: shortInfo.displayName,
      })
    }
    // eslint-disable-next-line
  }, [shortInfo.showFieldName, shortInfo.displayName])

  const onRequestCallback = useCallback((filters) => {
    return entityType
      ? dispatch(onRequest(requestPayload, filters))
      : {}
    // eslint-disable-next-line
  }, [refetch, entityType, onRequest, dispatch])

  useEffect(() => {
    onRequestCallback(parsedFilters)
    // eslint-disable-next-line
  }, [onRequestCallback])

  useEffect(() => {
    const { showValue, name } = dataFilters.find(({ showKey, showValue }) =>
      showKey && showValue && values[showKey] === showValue
    ) || {}
    if (parsed.entityId) {
      dispatch(getFactoringShortInfo(showValue, parsed.entityId, name))
    }
    // eslint-disable-next-line
  }, [parsed.entityId])

  const onGetItems = (page, size) => {
    const newFilters = {
      ...filters,
      limit: size,
      offset: (page - 1) * size,
    }
    setFilters(newFilters)
    onRequestCallback(newFilters)
  }

  const isCanResetFilters = Object.keys(filters)
    .filter(item => STATIC_FILTERS.indexOf(item) === -1)
    .some(value => !!values[value])

  const onClearAllFilters = () => {
    setFilters(defaultFilters)
    setValues(defaultFilters)
    onRequestCallback(defaultFilters)
  }

  const pageProps = {
    DEFAULT_OFFSET,
    entityType,
    values,
    title,
    filters,
    dataFilters,
    setFilters,
    setValues,
    onRequestCallback,
  }

  useEffect(() => {
    document.body.style.backgroundColor = '#fff'
    return () => {
      document.body.style.backgroundColor = 'inherit'
    }
  }, [])

  const showPagination =
    (!!params?.entityType || isMonitoringIssues || isMonitoringNotifications) &&
    totalCount > 0 &&
    totalPages > 1

  const paginationHeight = showPagination ? 76 : 0

  const totalSum = views?.items?.reduce((result, item) => parseFloat(result + item?.sum), 0)

  const totalText =  ['supply', 'financing'].indexOf(params?.entityType) > -1
    ? `(Сумма: ${formattedNumber(totalSum)})`
    : ''

  const onOpenSupplyOperationForm = () => setShowSupplyOperationForm(true)
  const onCloseSupplyOperationForm = () => setShowSupplyOperationForm(false)

  const onGetReportRateControlFile = () => {
    const data = checkedItems.reduce((result, item) => {
      const financingId = item?.data?.financing?.id
      const factoringContractId = item?.data?.factoringContract?.id
      if (!result[factoringContractId]) {
        result[factoringContractId] = []
      }
      result[factoringContractId].push(financingId)
      return result
    }, {})

    if (Object.keys(data)?.length === 1) {
      const subString = Object.values(data)?.reduce((result, item, index) => {
        result = `${result}${index > 0 ? '&' : ''}financingId=${item}`
        return result
      }, '')

      dispatch(getFile({
        url: `${API_URL}/limit-report/report/rate/control?${subString}`,
        onSuccess: () => {},
        onError: () => {},
      }))
    } else {
      Toast({
        type: 'error',
        message: 'Выбранные поставки относятся к разным Генеральным договорам',
      })
    }
  }

  const onDownloadExcelFile = () => {
    const searchString = createSearchString({
      ...parsed,
      limit: totalCount,
    })
    dispatch(getFile({
      url: `${API_URL}/view/grid/${entityType?.toUpperCase()}/xlsx?${searchString}`,
      onSuccess: () => {},
      onError: () => {},
    }))
  }

  useEffect(() => {
    filters?.objectId && dispatch(getCompanyInfo(filters?.objectId))
  }, [dispatch, filters.objectId])

  useEffect(() => {
    setValues({...values, objectId: company?.company?.displayName})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  return (
    <>
      <Burger />
      <Header burger>
        <HeaderFilter>
          {dataFilters.map((item, index) => {
            const FilterItem = getFilterItem({...pageProps, ...item})
            return FilterItem
              ? <div className={classes.filterItem} key={index}>
                  <FilterItem
                    {...pageProps}
                    {...item}
                  />
                </div>
              : null
          })}
          {isCanResetFilters &&
            <ResetFilters onClick={onClearAllFilters} />
          }
        </HeaderFilter>
      </Header>
      <div className={classes.wrapper}>
        <div className={classes.wrapperAux}>
          <Title>
            <div>
              {title}
              {entityType &&
                <div
                  className={classes.xls}
                  onClick={onDownloadExcelFile}
                  title='Скачать excel-файл'
                >
                  <Icon iconName='xls'/>
                </div>
              }
              {additionalInfo}
            </div>
            {entityType === 'UserTaskCheckRates' &&
              <Button
                onClick={onGetReportRateControlFile}
                disabled={!checkedItems?.length}
              >
                Получить отчет по контролю ставок
              </Button>
            }
            {entityType === 'supply' &&
              <Button
                onClick={onOpenSupplyOperationForm}
                disabled={!selected?.length}
              >
                Операции с поставками
              </Button>
            }
          </Title>
          {id &&
            <SwitchSlider
              className={classes.switcher}
              id='showHideTable'
              name='showHideTable'
              label='Отобразить таблицу'
              checked={!isHideTable}
              onChange={() => onShowHideTable(isHideTable ? 'showTable' : 'hideTable')}
            />
          }
          {isHideTable
            ? null
            : <>
                {withoutPagination
                  ? children
                  : <div style={autoHeight
                      ? {}
                      : {
                          maxHeight: `calc(100vh - ${244 + paginationHeight}px)`,
                          minHeight: 0,
                        }
                    }>
                      {children}
                    </div>
                }
                {showPagination &&
                  <Pagination
                    page={page}
                    pages={totalPages}
                    total={totalCount}
                    onGetItems={onGetItems}
                    text={totalText}
                  />
                }
              </>
          }
        </div>
      </div>
      <FactoringModal
        entityType={entityType}
        parsedFilters={parsedFilters}
        onGetFactoringView={onRequestCallback}
      />
      {showSupplyOperationForm &&
        <SupplyOperationForm
          selected={selected}
          parsedFilters={parsedFilters}
          onGetFactoringView={onRequestCallback}
          onClose={onCloseSupplyOperationForm}
        />
      }
      {(isFetching || isFetchingFile) &&
        <Overlay size='big' />
      }
    </>
  )
}