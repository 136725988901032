import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  buttonFilter: {
    background: '#fff',
    width: '100%',
    height: 40,
    padding: [12, 30],
    fontSize: 14,
    border: [1, 'solid', '#0B1F35'],
    borderRadius: 15,
    color: '#0B1F35',
    fontWeight: 600,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
  },
})

export const ButtonFilter = ({
  children,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <button className={classes.buttonFilter} {...rest}>{children}</button>
  )
}