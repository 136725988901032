// import { combineReducers } from "redux";

import {
  GET_CHART_DATA_PENDING,
  GET_CHART_DATA_FULFILLED,
  GET_CHART_DATA_REJECTED
} from "./actionTypes";

const initialState = {
  isFetching: false,
  isError: false,
  charts: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHART_DATA_PENDING: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GET_CHART_DATA_REJECTED: {
      return {
        ...state,
        isFetching: false,
        isError: true
      };
    }
    case GET_CHART_DATA_FULFILLED: {
      return {
        ...state,
        isFetching: false,
        charts: action.data
      };
    }
    default: {
      return state;
    }
  }
};
