import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import injectSheet from 'react-jss'
import { getCompanyAgentStats, getCompanyAgentStatsByStatus } from '../../../../redux/Company/actions'
import Overlay from '../../../Overlay'
import { UI, InputText, Row } from './../../../../layout'
import StatsModal from './StatsModal'

const styles = {
  root: {
    paddingBottom: 40,
  },
  title: UI.title,
  block: {
    marginTop: 20,
  },
  item: {
    padding: 10,
    color: '#000',
    fontSize: 14,
    fontWeight: 500,
    position: 'relative',
    '& span': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 10,
      display: 'block',
    },
  },
}

const STATUSES = {
  inProgressCount: 'В работе',
  provisionedCount: 'Выданные',
  lostCount: 'Отказанные',
}

const BACKGROUNDS = {
  inProgressCount: 'rgba(255,190,0,.48)',
  provisionedCount: 'rgba(34,199,181,.48)',
  lostCount: 'rgba(250,106,71,.48)',
}

const PAYLOAD_STATUSES = {
  inProgressCount: 'inProgress',
  provisionedCount: 'provisioned',
  lostCount: 'lost',
}

const getFirstDayOfThisMonth = () => {
  const currentDay = new Date().getDate()
  const currentTimestamp = Date.now()
  const passedMs = (currentDay - 1) * 24 * 60 * 60 * 1000
  return new Date(currentTimestamp - passedMs)
}

const Stats = ({
  classes,
  companyId,
}) => {
  const dispatch = useDispatch()

  const { agentStats } = useSelector(state => state.Company) || {}

  const {
    inProgressCount,
    lostCount,
    provisionedCount,
    fetching,
  } = agentStats || {}

  const [dateFrom, setDateFrom] = useState(getFirstDayOfThisMonth())
  const [dateTo, setDateTo] = useState(new Date())
  const [status, setStatus] = useState(null)

  useEffect(() => {
    dispatch(getCompanyAgentStats(companyId, { dateFrom, dateTo }))
  }, [companyId, dateFrom, dateTo, dispatch])

  const onChangeDateFrom = (e) => setDateFrom(new Date(e.target.value))
  const onChangeDateTo = (e) => setDateTo(new Date(e.target.value))

  const onGetCompanyAgentStatsByStatus = (key) => dispatch(
    getCompanyAgentStatsByStatus(companyId, PAYLOAD_STATUSES[key], { dateFrom, dateTo })
  )

  const onOpenStatsModal = (key) => {
    setStatus(key)
    onGetCompanyAgentStatsByStatus(key)
  }

  const onCloseStatsModal = () => setStatus(null)

  const items = [
    { key: 'provisionedCount', count: provisionedCount },
    { key: 'inProgressCount', count: inProgressCount },
    { key: 'lostCount', count: lostCount },
  ]

  if (fetching) {
    return <Overlay />
  }
  
  return (
    <>
      <div className={classes.root}>
        <div className={classes.title}>Статистика по заявкам агента</div>
        <div className={classes.block}>
          <Row>
            <div className='col-6'>
              <InputText
                type='date' 
                name='dateFrom'
                label='Дата подачи заявки с:'
                value={dateFrom}
                maxDate={new Date(dateTo)}
                onChange={onChangeDateFrom}
              />
            </div>
            <div className='col-6'>
              <InputText
                type='date' 
                name='dateTo'
                label='Дата подачи заявки по:'
                value={dateTo}
                minDate={new Date(dateFrom)}
                onChange={onChangeDateTo}
              />
            </div>
          </Row>
          <Row>
            {items.map(({ count, key }) =>
              <div
                key={key}
                className='col-4'
                onClick={() => count && onOpenStatsModal(key)}
                style={{ cursor: count ? 'pointer' : 'default' }}
              >
                <div className={classes.item} style={{ background: BACKGROUNDS[key] }}>
                  {STATUSES[key]} <span>{count}</span>
                </div>
              </div>
            )}
          </Row>
        </div>
      </div>
      {status
        ? <StatsModal
            status={STATUSES[status]}
            background={BACKGROUNDS[status]}
            dateFrom={dateFrom}
            dateTo={dateTo}
            count={status && items.find(({ key }) => key === status).count}
            onClose={onCloseStatsModal}
          /> 
        : null
      }
    </>
  )
}

export default injectSheet(styles)(Stats)