import { RegistryPaymentsList } from './RegistryPaymentsList'
import * as actions from '../../../../redux/RegisterPayments/actions'
import { connect } from 'react-redux'

const mapStateToProps = ({ RegisterPayments }) => {
  const { payments, isFetching } = RegisterPayments

  return {
    payments,
    isFetching,
    isFixed: false
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRegisterPayments: (regId, filters) =>
      dispatch(actions.getRegistersPayments(regId, filters))
  }
}

export const RegistryPaymentsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistryPaymentsList)
