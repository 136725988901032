import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Label } from '../../layout'

// import ClearButton from '../ClearButton'

import LoadingIcon from '../../static/img/loading.svg'

const styles = {
  isMoney: {
    paddingRight: 32,
  },
  error: {
    border: [1, 'solid', 'red'],
  },
}

const TextField = ({
  type = 'text',
  classes,
  isFetching,
  hasReset,
  isMoney,
  name,
  placeholder,
  label,
  onChange,
  onBlur,
  disabled,
  error,
  ...otherProps
}) => {
  // const [focused, setFocused] = useState(false)
  const [typing, setTyping] = useState(false)
  const [value, setValue] = useState(otherProps.value || '')

  useEffect(() => {
    if (!isFetching) {
      setTyping(false)
    }
  }, [isFetching])

  useEffect(() => {
    if (hasReset) {
      setValue('')
    }
  }, [hasReset])

  // const onFocus = () => setFocused(true)
  // const onBlur = () => setFocused(false)
  // const onClear = () => otherProps.onClear(name, '')

  const _onChange = (e) => {
    setTyping(true)
    setValue(e.target.value)
    onChange && onChange(e)
  }

  return (
    <>
      {label &&
        <Label>{label}</Label>
      }
      <div className='filter-input'>
        {isFetching && typing
          ? <img className='input-loader' src={LoadingIcon} alt='' />
          : <i className='icon icon-search'/>
        }
        <input
          type={type}
          className={classnames('filter-input__input', { 
            [classes.isMoney]: isMoney,
            [classes.error]: error, 
          })}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={_onChange}
          // onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          data-placeholder={placeholder}
          required
          aria-required='true'
        />
        {isMoney
          ? <div className='filter-input-rub'>₽</div> 
          : ''
        }
      </div>
    </>
  )
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  // onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  isFetching: PropTypes.bool,
  isMoney: PropTypes.bool.isRequired,
}

TextField.defaultProps = {
  value: '',
}

export default injectSheet(styles)(TextField)