import * as actionTypes from './actionTypes'
import { Orders } from '../../services/api'
import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'


export const getOrders = (filters) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_ORDERS })
    const { isSuccess, ...res } = await Orders.getOrders(filters)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ 
      type: actionTypes.GET_ORDERS_SUCCESS, 
      data: res,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_ORDERS_ERROR })
  }
}

export const getNextOrders = (page, filters) => async dispatch => {
  try {
    dispatch({ type: actionTypes.GET_NEXT_ORDERS })
    const { isSuccess, ...res } = await Orders.getNextOrders(page, filters)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ 
      type: actionTypes.GET_NEXT_ORDERS_SUCCESS, 
      data: res, 
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_NEXT_ORDERS_ERROR })
  }
}

export const setOrdersFilter = (filters) => async (dispatch) => {
  dispatch({ 
    type: actionTypes.SET_ORDERS_FILTER, 
    data: { filters }, 
  })
}

export const clearAllFilters = () => (dispatch) => 
  dispatch({ type: actionTypes.CLEAR_ORDERS_FILTERS })