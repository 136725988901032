import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getFactoringRefRateTypes,
  getFactoringRefRateConfig,
  getFactoringRefBlockTypes,
  getFactoringRefOperationTypes,
  // getFactoringRefAccountTypes,
  getFactoringRefMasterOperationTypes,
  getFactoringRefOfferingKeys,
  getFactoringRefDocuments,
  getFactoringRefIssues,
  getFactoringRefLimitTypes,
  // getFactoringMonIssueNotifications,
  getFactoringRefOperationCodeTypes,
  getFactoringDuplicateSettings,
  getFactoringPriceMatrix,
  getFactoringAccountEntityTypes,
  getFactoringSupplyCommissions,
  getFactoringCounterPartyCommon,
  getFactoringCounterPartyAddress,
  getFactoringCounterPartyState,
  getFactoringCounterPartyInfo,
  getFactoringCounterPartySigner,
  getFactoringCounterPartyContact,
  getFactoringFactoringContractCommon,
  getFactoringFactoringContractMain,
  getFactoringFactoringContractContract,
  getFactoringFactoringContractAccount,
  getFactoringFactoringContractSurety,
  getFactoringFactoringContractTariff,
  getFactoringFactoringContractFeatures,
  getFactoringSupplyContractCommon,
  getFactoringAccountEntities,
  getFactoringConstraintOrders,
  getFactoringSupplyCommonTab,
  getFactoringSupplyDocumentTab,
  getFactoringSupplyFinancingTab,
  getFactoringMonIssueNotificationTab,
  getFactoringSupplyRegistrySupplyTab,
} from '../../redux/Factoring/actions'
import { Factoring, Search } from '../../services/api'
import { FACTORING_ENTITY_TYPE_OPTIONS } from '../../config'

export const FINANCING_TASKS = [
  'UserTaskCheckBlocks',
  'UserTaskCheckLimits',
  'UserTaskModifyLimits',
  'UserTaskCheckRates',
  'UserTaskDebtorVerification',
  'UserTaskOriginalDocs',
  'UserTaskWaitCommission',
  'UserTaskPostponedFinancing',
  'UserTaskApproveFinancing',
  'UserTaskCheckOps',
]

const FINANCING_FILTERS = [
  {
    name: 'supplierInn',
    type: 'autocomplete',
    placeholder: 'Поставщик',
    payloadKey: 'INN',
    payloadKeyUI: 'displayName',
  },
  {
    name: 'debtorInn',
    type: 'autocomplete',
    placeholder: 'Дебитор',
    payloadKey: 'INN',
    payloadKeyUI: 'displayName',
  },
  {
    name: 'factoringContractId',
    type: 'autocomplete',
    placeholder: 'ГД',
    payloadKey: 'id',
    payloadKeyUI: 'number',
    request: Search.getFactoringContractSuggestions,
    requestKey: 'number',
    requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
  },
]

const DEFAULT_TASK_CONFIG = {
  dataFilters: [],
  dataItems: [
    { name: 'Номер', field: 'financing.number' },
    // { name: 'Дата', field: 'financing.financingDate', type: 'date' },
    { name: 'Статус', field: 'financing.status.name', type: 'status' },
    { name: 'Поставщик', field: 'supplier.name' },
    { name: 'ГД', field: 'factoringContract.number' },
    { name: 'Договор поставки', field: 'supplyContract.number' },
    { name: 'Дебитор', field: 'debtor.name' },
    { name: 'Сумма поставки', field: 'supply.sum', type: 'amount', sum: true },
    { name: 'Дата поставки', field: 'supply.supplyDate', type: 'date' },
    { name: 'Сумма финансирования', field: 'financing.sum', type: 'amount', sum: true },
    { name: 'Срок отсрочки', field: 'supply.deferDate', type: 'date' },
    { name: 'Дата приемки', field: 'supply.acceptanceDate', type: 'date' },
    { name: 'Просрочено дней', field: 'supply.overdueDays'},
    { name: 'Дата ожидания', field: 'supply.waitingDate', type: 'date' },
    { name: 'Комментарий', field: '' },
    { name: 'Результат', field: '' },
    { name: 'id', field: 'id' },
    { name: 'processInstanceId', field: 'processInstanceId' },
    { name: 'processDefinitionKey', field: 'processDefinitionKey' },
    { name: 'businessKey', field: 'businessKey' },
    { name: 'taskDefinitionKey', field: 'taskDefinitionKey' },
    { name: 'name', field: 'name' },
    { name: 'startTime', field: 'startTime' },
    { name: 'assignee', field: 'assignee' },
    { name: 'type', field: 'type' },
    { name: 'factoringInfo', field: 'factoringInfo' },
  ]
}

const TRANSFER_TASK_CONFIG = {
  dataFilters: [],
  dataItems: [
    { name: 'Счёт по кредиту', field: 'creditAccount' },
    { name: 'Счёт по кредиту - Банк', field: 'creditBankName' },
    { name: 'Счёт по кредиту - БИК', field: 'creditBic' },
    { name: 'Счёт по кредиту - Корр. счёт', field: 'creditCorrAccount' },
    { name: 'Счёт по кредиту - Город банка', field: 'creditBankCity' },
    { name: 'Сторонний банк', field: 'thirdPartyBank', type: 'boolean' },
    { name: 'Счёт по дебету', field: 'debitAccount' },
    { name: 'Счёт по дебету - Банк', field: 'debitBankName' },
    { name: 'Счёт по дебету - БИК', field: 'debitBic' },
    { name: 'Счёт по дебету - Корр. счёт', field: 'debitCorrAccount' },
    { name: 'Счёт по дебету - Город банка', field: 'debitBankCity' },
    { name: 'Дата трансфера', field: 'transferDate', type: 'date' },
    { name: 'Статус', field: 'status', type: 'status' },
    { name: 'Назначение', field: 'purpose' },
  ],
}

const PAYMENT_MATCHING_CONFIG = {
  dataFilters: [
    { name: 'number', type: 'text', placeholder: 'Номер платежа' },
    { name: 'supplierInn', type: 'autocomplete', placeholder: 'Поставщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
    { name: 'payerInn', type: 'autocomplete', placeholder: 'Плательщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
    { name: 'dateFrom', type: 'date', placeholder: 'Дата С' },
    { name: 'dateTo', type: 'date', placeholder: 'Дата ПО' },
    { name: 'amountFrom', type: 'text', placeholder: 'Сумма ОТ' },
    { name: 'amountTo', type: 'text', placeholder: 'Сумма ДО' },
  ],
  dataItems: [
    { name: '№ Платежа', field: 'data.payment.number' },
    { name: 'Поставщик', field: 'data.payment.supplierName' },
    { name: 'Плательщик', field: 'data.payment.payerName' },
    { name: 'Дата платежа', field: 'data.payment.date', type: 'date' },
    { name: 'Платеж', field: 'data.payment.amount', type: 'amount', sum: true },
    { name: 'Остаток', field: 'data.payment.unmatchedAmount', type: 'amount', sum: true },
    { name: 'Продукт', field: 'data.payment.product' },
    { name: 'Ген. договор', field: 'data.payment.factoringContractNumber' },
    { name: '!', field: 'data.payment.deferDays', type: 'warning' },
  ],
}

export const useFactoring = ({ entityType }) => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const ACTIONS = useMemo(() => {
    return {
      payment_matching: () => dispatch(getFactoringRefDocuments()),
      issues: () => dispatch(getFactoringRefIssues()),
      notifications: () => {
        // dispatch(getFactoringMonIssueNotifications())
        id && dispatch(getFactoringMonIssueNotificationTab(id))
      },
      operation: () => {
        dispatch(getFactoringRefOperationTypes())
        dispatch(getFactoringRefMasterOperationTypes())
        dispatch(getFactoringRefOperationCodeTypes())
      },
      rate: () => {
        dispatch(getFactoringRefRateTypes())
        dispatch(getFactoringRefRateConfig())
      },
      financing: () => dispatch(getFactoringRefDocuments()),
      block: () => dispatch(getFactoringRefBlockTypes()),
      supply: () => {
        id && dispatch(getFactoringSupplyCommonTab(id))
        id && dispatch(getFactoringSupplyDocumentTab(id))
        id && dispatch(getFactoringSupplyFinancingTab(id))
        id && dispatch(getFactoringSupplyCommissions(id))
      },
      supply_contract: () => {
        id && dispatch(getFactoringDuplicateSettings({ entityId: id, entityType }))
        id && dispatch(getFactoringSupplyContractCommon(id))
      },
      factoring_contract: () => {
        id && dispatch(getFactoringPriceMatrix(id))
        id && dispatch(getFactoringFactoringContractCommon(id))
        id && dispatch(getFactoringFactoringContractMain(id))
        id && dispatch(getFactoringFactoringContractContract(id))
        id && dispatch(getFactoringFactoringContractAccount(id))
        id && dispatch(getFactoringFactoringContractSurety(id))
        id && dispatch(getFactoringFactoringContractTariff(id))
        id && dispatch(getFactoringFactoringContractFeatures(id))
      },
      product: () => {
        dispatch(getFactoringRefOfferingKeys())
        id && dispatch(getFactoringPriceMatrix(id))
      },
      account: () => {
        // dispatch(getFactoringRefAccountTypes())
        dispatch(getFactoringAccountEntityTypes())
        id && dispatch(getFactoringAccountEntities(id))
      },
      counterparty: () => {
        id && dispatch(getFactoringCounterPartyCommon(id))
        id && dispatch(getFactoringCounterPartyAddress(id))
        id && dispatch(getFactoringCounterPartyState(id))
        id && dispatch(getFactoringCounterPartyInfo(id))
        id && dispatch(getFactoringCounterPartySigner(id))
        id && dispatch(getFactoringCounterPartyContact(id))
      },
      limit: () => {
        dispatch(getFactoringRefLimitTypes())
        id && dispatch(getFactoringConstraintOrders(id))
      },
      supply_registry: () => {
        id && dispatch(getFactoringSupplyRegistrySupplyTab(id))
      }
    }
  }, [id, entityType, dispatch])

  const {
    issueTypes,
    blockTypes,
    rateTypes,
    limitTypes,
    operationTypes,
    operationCodeTypes,
    masterOperationTypes,
    // accountTypes,
    offeringKeys,
  } = useSelector(state => state?.Factoring)

  useEffect(() => {
    ACTIONS[entityType] && ACTIONS[entityType]()
  }, [ACTIONS, entityType])

  const mapper = (item) => ({
    id: item.value || item.issueType,
    name: item.name,
  })

  // const subjectTypeOptions = [
  //   { id: '', name: 'Тип субъекта' },
  //   { id: 'company', name: 'Компания' },
  //   { id: 'constraint', name: 'Констрейнт' },
  //   { id: 'supply', name: 'Поставка' },
  //   { id: 'factoringContract', name: 'Договор факторинга' },
  //   { id: 'payment', name: 'Платеж' }
  // ]
  const processDefinitionKeyOptions = [
    { id: '', name: 'Тип проблемы' },
    ...issueTypes.reduce((result, item) => {
      if (item?.processDefinitionKey) {
        result.push({
          id: item.processDefinitionKey,
          name: item.name,
        })
      }
      return result
    }, [])
  ]
  const blockTypeOptions = [{ id: '', name: 'Тип блокировки' }, ...blockTypes.map(mapper)]
  const rateTypeOptions = [{ id: '', name: 'Тип ставки' }, ...rateTypes.map(mapper)]
  const operationTypeOptions = [{ id: '', name: 'Статус' }, ...operationTypes.map(mapper)]
  const operationCodeTypesOptions = [{ id: '', name: 'Вид проводки' }, ...operationCodeTypes.map(mapper)]
  const masterOperationTypesOptions = [{ id: '', name: 'Вид операции' }, ...masterOperationTypes.map(mapper)]
  // const accountTypeOptions = [{ id: '', name: 'Тип счёта' }, ...accountTypes.map(mapper)]
  const offeringKeyOptions = [{ id: '', name: 'Продукт' }, ...offeringKeys.map(mapper)]
  const limitTypesOptions = [{ id: '', name: 'Тип лимита' }, ...limitTypes.map(mapper)]
  const entityTypeOptions = FACTORING_ENTITY_TYPE_OPTIONS?.map(item => ({
    id: item?.value,
    name: item?.name || 'Тип продукта',
  }))
  const fullyMatchedOptions = [
    { id: '', name: 'Квитовка' },
    { id: true, name: 'Сквитован полностью' },
    { id: false, name: 'Не сквитован' },
  ]
  const productTypeOptions = [
    { id: '', name: 'Продукт' },
    { id: 'REGRESS', name: 'Регресс' },
    { id: 'WO_REGRESS', name: 'Безрегресс' },
  ]
  const statusOptions = [
    { id: '', name: 'Статус' },
    { id: 'APPROVED', name: 'К авторизации' },
    { id: 'TERMINATED', name: '	Блокирован' },
    { id: 'ACTIVE', name: 'В работе' },
    { id: 'DRAFT', name: 'Черновик' },
  ]

  const factoring = {
    ...(FINANCING_TASKS.reduce((result, item) => ({
      ...result,
      [item]: {
        dataFilters: FINANCING_FILTERS,
        dataItems: DEFAULT_TASK_CONFIG.dataItems,
      }
    }), {})),
    UserTaskFailedAccOperations: {
      dataFilters: [],
      dataItems: [
        { name: 'Счет дебета', field: 'debitAccount' },
        { name: 'Счет кредита', field: 'creditAccount' },
        { name: 'Сумма проводки', field: 'sum', type: 'amount', sum: true },
        { name: 'Вид проводки', field: 'description' },
        { name: 'Назначение', field: 'purpose' },
        { name: 'Дата создания', field: 'createDate', type: 'date' }, 
        { name: 'Дата проводки', field: 'operationDate', type: 'date' },
        { name: 'ID АБС', field: 'absId' }, 
        { name: 'Валюта', field: 'currency' },
        { name: '№ поставки', field: 'supplyNumber' },
        { name: 'Сумма поставки', field: 'supplySum', type: 'amount', sum: true },
        { name: 'Дата поставки', field: 'supplyDate', type: 'date' }, 
        { name: 'Дата регитрации', field: 'supplyRegistration', type: 'date' },
        { name: '№ финансирования', field: 'financingNumber' },
        { name: 'Продукт', field: 'product' },
        { name: 'ГД', field: 'factoringContractNumber' },    
        { name: 'Дата ГД', field: 'factoringContractDate', type: 'date' }, 
        { name: 'Статус', field: 'status', type: 'status' },
      ],
    },
    UserTaskFixTransfer: TRANSFER_TASK_CONFIG,
    UserTaskEditTransfer: TRANSFER_TASK_CONFIG,
    UserTaskPaymentApproveUnknown: PAYMENT_MATCHING_CONFIG,
    UserTaskApprovePayment: {
      dataFilters: [],
      dataItems: [
        { name: '№ платежа', field: 'payment.number' },
        { name: 'Дата платежа', field: 'payment.date', type: 'date' },
        { name: 'Плательщик', field: 'payment.payerName' },
        { name: 'Получатель', field: 'payment.receiverName' },
        { name: '№ Поставки', field: 'supply.number' },
        { name: 'Поставщик', field: 'participants.supplierName' }, 
        { name: 'Дебитор', field: 'participants.debtorName' },
        { name: 'ГД', field: 'factoringContractNumber' }, 
        { name: 'Возврат через поставку от дебитора', field: 'sums.returnOverSupply', type: 'amount', sum: true },
        { name: 'Возврат поставщику от дебитора', field: 'sums.supplyPrepayReturn', type: 'amount', sum: true },
        { name: 'Остаток по поставке', field: 'sums.supplyReturn', type: 'amount', sum: true },
        { name: 'Выплата остатка оплаченная поставщиком', field: 'sums.supplyReturnPrepay', type: 'amount', sum: true },
      ],
    },
    UserTaskVerifyUpdate: {
      dataFilters: [],
      dataItems: [
        { name: 'ID поставки', field: 'supply.id' },
        { name: 'Номер поставки', field: 'supply.number' },
        { name: 'Дата', field: 'supply.supplyDate', type: 'date' },  
        { name: 'Поставщик', field: 'supplier.name' }, 
        { name: 'Дебитор', field: 'debtor.name' },
        { name: 'Договора поставки', field: 'supplyContract.number' }, 
        { name: 'Договор факторинга', field: 'factoringContract.number' },
        { name: 'Дата отсрочки', field: 'data.deferDate', type: 'date' },
        { name: 'Дата ожидания', field: 'data.waitingDate', type: 'date' }, 
        { name: 'Сумма поставки', field: 'data.sum', type: 'amount' },
        { name: 'Новая дата отсрочки', field: 'updateData.deferDate', type: 'date' },
        { name: 'Новая дата ожидания', field: 'updateData.waitingDate', type: 'date' }, 
        { name: 'Новая сумма поставки', field: 'updateData.sum', type: 'amount' },
      ]
    },
    payment_matching: PAYMENT_MATCHING_CONFIG,
    issues: {
      dataFilters: [
        { name: 'processDefinitionKey', type: 'select', options: processDefinitionKeyOptions },
        {
          name: 'objectId',
          type: 'autocomplete',
          placeholder: 'Контрагент',
          payloadKey: 'id',
          payloadKeyUI: 'displayName',
        },
        { name: 'dateFrom', type: 'date', placeholder: 'Дата С' },
        { name: 'dateTo', type: 'date', placeholder: 'Дата ПО' },
        // { name: 'subjectType', type: 'select', options: subjectTypeOptions },
        // {
        //   name: 'subjectId',
        //   type: 'autocomplete',
        //   placeholder: 'Компания',
        //   payloadKey: 'id',
        //   payloadKeyUI: 'displayName',
        //   disabledKey: 'subjectType',
        //   showKey: 'subjectType',
        //   showValue: 'company',
        // },
        // {
        //   name: 'subjectId',
        //   type: 'autocomplete',
        //   placeholder: 'Констрейнт',
        //   payloadKey: 'id',
        //   payloadKeyUI: 'displayName',
        //   disabledKey: 'subjectType',
        //   showKey: 'subjectType',
        //   showValue: 'constraint',
        // },
        // {
        //   name: 'subjectId',
        //   type: 'autocomplete',
        //   placeholder: 'Контракт',
        //   payloadKey: 'id',
        //   payloadKeyUI: 'number',
        //   request: Search.getSupplyContractSuggestions,
        //   requestKey: 'number',
        //   requestSubKeys: ['sellerName', 'startDate'],
        //   showKey: 'subjectType',
        //   showValue: 'supply',
        // },
        // {
        //   name: 'subjectId',
        //   type: 'autocomplete',
        //   placeholder: 'Договор факторинга',
        //   payloadKey: 'number',
        //   payloadKeyUI: 'number',
        //   request: Search.getFactoringContractSuggestions,
        //   requestKey: 'number',
        //   requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
        //   showKey: 'subjectType',
        //   showValue: 'factoringContract',
        // },
        // {
        //   name: 'subjectId',
        //   type: 'text',
        //   placeholder: 'Номер платежа',
        //   showKey: 'subjectType',
        //   showValue: 'payment',
        // },
      ],
      dataItems: [
        { name: 'Название задачи', field: 'name' },
        { name: 'Тип проблемы', field: 'issueTypeName' },
        { name: 'Тип субъекта', field: 'subjectTypeName' },
        { name: 'Субъект', field: 'subjectName' },
        { name: 'Типа объекта', field: 'objectTypeName' },
        { name: 'Объект', field: 'objectName' },
        { name: 'Дата регистрации', field: 'issueDateTime', type: 'date' },
      ],
    },
    notifications: {
      dataFilters: [],
      dataItems: [
        { name: 'Номер уведомления', field: 'notificationNumber' },
        { name: 'Дата уведомления', field: 'notificationDate', type: 'date' },
        { name: 'Дата отправки', field: 'sentDateTime', type: 'date' },
        { name: 'Получатель', field: 'sendTo' },
      ],
    },
    counterparty: {
      dataFilters: [
        { name: 'counterparty', type: 'autocomplete', placeholder: 'Контрагент', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        // { name: 'pinEq', type: 'text', placeholder: 'ПИН EQ' },
      ],
      dataItems: [
        { name: 'Контрагент', field: 'counterparty' },
        { name: 'ИНН', field: 'inn' },
        { name: 'Дата проверки ДЭБ', field: 'debCheckDate', type: 'date' },
        { name: 'Сегмент', field: 'segment' },
        { name: 'Дата мониторинга', field: 'monitoringDate', type: 'date' },
        { name: 'Рейтинг', field: 'rating' },
        { name: 'Рейтинг ЦБ', field: 'ratingCb' },
        { name: 'Дата финположения', field: 'finPositionDate', type: 'date' },
        { name: 'Финположение', field: 'finPosition' },
        { name: 'Кл.подразд', field: 'keyUnit' },
        { name: 'ПИН EQ', field: 'pinEq' },
        { name: 'Дата актуализации', field: 'actualizationDate', type: 'date' },
        { name: 'Категория проблемности', field: 'problemCategory' },
        { name: 'Филиал', field: 'branch' },
        { name: 'Статус', field: 'status', type: 'status' },
        { name: 'ПЦ', field: 'profitCenter' },
        { name: 'Мен.развития', field: 'developmentManager' },
        { name: 'Куратор отр.', field: 'industryCurator' },
      ],
    },
    factoring_contract: {
      dataFilters: [
        {
          name: 'number',
          type: 'autocomplete',
          placeholder: 'ГД',
          payloadKey: 'number',
          payloadKeyUI: 'number',
          request: Search.getFactoringContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
        },
        { name: 'productType', type: 'select', options: productTypeOptions },
        { name: 'supplierInn', type: 'autocomplete', placeholder: 'Поставщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'status', type: 'select', options: statusOptions },
      ],
      dataItems: [
        { name: 'Договор', field: 'number' },
        { name: 'Продукт', field: 'productType' },
        { name: 'Субпродукт', field: 'subProduct' },
        { name: 'Учет прав', field: 'securityRegressType' },
        {
          name: 'Поставщик',
          field: 'displayName',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'supplierId',
          },
        },
        { name: 'Действ С', field: 'startDate', type: 'date' },
        { name: 'Действ ПО', field: 'endDate', type: 'date' },
        { name: 'Статус', field: 'status', type: 'status' },
        { name: 'ПЦ', field: 'profitCenter' },
        { name: 'ПИН АБС', field: 'pinAbs' },
        { name: 'Примечание', field: 'note' },
        { name: 'LM_ID', field: 'lmId' },
        { name: 'Транзит', field: 'transit' },
      ],
    },
    supply_contract: {
      dataFilters: [
        {
          name: 'numberLike',
          type: 'autocomplete',
          placeholder: 'Контракт',
          payloadKey: 'number',
          payloadKeyUI: 'number',
          request: Search.getSupplyContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['number', 'startDate', 'sellerName'],
        },
        { name: 'supplierInn', type: 'autocomplete', placeholder: 'Продавец', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'debtorInn', type: 'autocomplete', placeholder: 'Дебитор', payloadKey: 'INN', payloadKeyUI: 'displayName' },
      ],
      dataItems: [
        {
          name: 'Поставщик',
          field: 'sellerName',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'supplierId',
          },
        },
        {
          name: 'Дебитор',
          field: 'debtorsName',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'debtorId',
          },
        },
        { name: '№ Контракта', field: 'number' },
        { name: 'Нач.дог.', field: 'startDate', type: 'date' },
        { name: 'Окон. дог.', field: 'endDate', type: 'date' },
        { name: '% финансирования', field: 'financingPercentage' },
        { name: 'Дата уведомления', field: 'signNoticeDate', type: 'date' },
        { name: 'Дата обратного уведомления', field: 'signRevNoticeDate', type: 'date' },
        { name: 'Отсрочка', field: 'defer' },
        { name: 'Макс. дней фин.', field: 'maxFinDays' },
        { name: 'Номер ГД', field: 'factoringContractNumber' },
        { name: 'Дата ГД', field: 'factoringContractDate' },
        { name: 'Примечание', field: 'notification' },
        { name: 'Срок до регресса', field: 'daysToRegress' },
        { name: 'Отложенный %', field: 'reservedPercent' },
        { name: 'Закрытый факторинг', field: 'closedFactoring' },
      ]
    },
    financing: {
      dataFilters: [
        {
          name: 'factoringContractNumber',
          type: 'autocomplete',
          placeholder: 'ГД',
          payloadKey: 'number',
          payloadKeyUI: 'number',
          request: Search.getFactoringContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
        },
        {
          name: 'supplyContractNumber',
          type: 'autocomplete',
          placeholder: 'Контракт',
          payloadKey: 'number',
          payloadKeyUI: 'number',
          request: Search.getSupplyContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['sellerName', 'startDate'],
        },
        { name: 'id', type: 'text', placeholder: 'ID запроса на финансирование' },
        { name: 'number', type: 'text', placeholder: '№ финансирования' },
        { name: 'supplyNumber', type: 'text', placeholder: '№ поставки' },
        { name: 'supplyRegistryNumber', type: 'text', placeholder: '№ Реестра' },
        { name: 'supplierInn', type: 'autocomplete', placeholder: 'Поставщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'debtorInn', type: 'autocomplete', placeholder: 'Дебитор', payloadKey: 'INN', payloadKeyUI: 'displayName' },
      ],
      dataItems: [
        { name: 'ID запроса на финансирование', field: 'id' },
        { name: 'Дубль', field: 'supplyId' },
        { name: 'Тип документа', field: 'documentType' },
        { name: 'Дата финансирования', field: 'financingDate', type: 'date' },
        { name: '№ финансирования', field: 'number' },
        {
          name: '№ поставки',
          field: 'supplyNumber',
          type: 'link',
          linkData: {
            entityType: 'supply',
            entityId: 'supplyId',
          },
        },
        { name: '№ Реестра', field: 'supplyRegistryNumber' },
        { name: 'Дата реестра', field: 'supplyRegistryDate', type: 'date' },
        {
          name: 'ГД',
          field: 'factoringContractNumber',
          type: 'link',
          linkData: {
            entityType: 'factoring_contract',
            entityId: 'factoringContractId',
          },
        },
        {
          name: 'Поставщик',
          field: 'supplierName',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'supplierId',
          },
        },
        {
          name: 'Дебитор',
          field: 'debtorName',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'debtorId',
          },
        },
        { name: 'Дата', field: 'supplyDate', type: 'date' },
        { name: 'Приемка', field: 'acceptanceDate', type: 'date' },
        { name: 'Сумма', field: 'sum', type: 'amount' },
        { name: 'Валюта', field: 'currency' },
        { name: 'ЖДФ', field: 'whenToFund' },
        { name: 'Желаемая дата', field: 'paymentDate', type: 'date' },
        { name: 'Транзит', field: 'transit' },
        { name: 'Отсрочка', field: 'deferDate', type: 'date' },
        { name: 'Просрочено дней', field: 'overdueDays' },
        { name: 'Статус', field: 'status', type: 'status' },
        { name: 'Ввод', field: 'createMethod' },
        { name: 'Продукт', field: 'productType' },
        {
          name: 'Контракт',
          field: 'supplyContractNumber',
          type: 'link',
          linkData: {
            entityType: 'supply_contract',
            entityId: 'supplyContractId',
          },
        },
        { name: 'Оригиналы', field: 'hasOriginals' },
        { name: 'Дата ожидания', field: 'waitingDate', type: 'date' },
        { name: 'Регистрация', field: 'registrationDate', type: 'date' },
        { name: 'Дата макс. фин.', field: 'maxFinDate', type: 'date' },
        { name: 'Закрыто', field: 'closed' },
        { name: 'Верификация', field: 'verification' },
        { name: 'Уведомление о просрочке платежа дебитором', field: 'debtorsPaymentDelayNotification' },
        { name: 'Дата погаш фин', field: 'finPaymentDate', type: 'date' },
        { name: 'Примечание', field: 'notification' },
        { name: 'Комментарий', field: 'comment' },
        { name: 'Создал', field: 'createdBy' },
        { name: 'Создание', field: 'creation' },
        { name: 'Последний', field: 'last' },
        { name: 'Изменение', field: 'changedBy' },
        { name: 'Закрытый факторинг', field: 'closedFactoring' },
      ],
    },
    rate: {
      dataFilters: [
        {
          name: 'entityType',
          type: 'select',
          options: entityTypeOptions,
        },
        {
          name: 'entityId',
          type: 'autocomplete',
          placeholder: 'Владелец ставки',
          payloadKey: 'id',
          payloadKeyUI: 'displayName',
          disabledKey: 'entityType',
          showKey: 'entityType',
          showValue: 'COMPANY',
        },
        {
          name: 'entityId',
          type: 'autocomplete',
          placeholder: 'ID договора факторинга',
          payloadKey: 'id',
          payloadKeyUI: 'id',
          disabledKey: 'entityType',
          showKey: 'entityType',
          showValue: 'FACTORING_CONTRACT',
        },
        { name: 'rateType', type: 'select', options: rateTypeOptions },
        { name: 'beginDate', type: 'date', placeholder: 'С даты' },
        { name: 'endDate', type: 'date', placeholder: 'По дату' }, 
      ],
      dataItems: [
        { name: 'Владелец ставки', field: 'displayName' },
        { name: 'Начало действия', field: 'beginDate' , type: 'date' },
        { name: 'Конец действия', field: 'endDate', type: 'date' },
        { name: 'Тип ставки', field: 'type' },
        { name: 'Ставка %', field: 'value', type: 'rate' },
        { name: 'Срок С', field: 'beginPeriod' },
        { name: 'Срок ПО', field: 'endPeriod' },
      ],
    },
    block: {
      dataFilters: [
        {
          name: 'factoringContractId',
          type: 'autocomplete',
          placeholder: 'ГД',
          payloadKey: 'id',
          payloadKeyUI: 'number',
          request: Search.getFactoringContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
        },
        {
          name: 'supplyContractId',
          type: 'autocomplete',
          placeholder: 'Контракт',
          payloadKey: 'id',
          payloadKeyUI: 'number',
          request: Search.getSupplyContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['sellerName', 'startDate'],
        },
        { name: 'supplierInn', type: 'autocomplete', placeholder: 'Поставщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'debtorInn', type: 'autocomplete', placeholder: 'Дебитор', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'type', type: 'select', options: blockTypeOptions },
      ],
      dataItems: [
        {
          name: 'ГД',
          field: 'factoringContractNumber',
          type: 'link',
          linkData: {
            entityType: 'factoring_contract',
            entityId: 'factoringContractId',
          },
        },
        {
          name: 'Договор поставки',
          field: 'supplyContractNumber',
          type: 'link',
          linkData: {
            entityType: 'supply_contract',
            entityId: 'supplyContractId',
          },
        },
        {
          name: 'Поставщик',
          field: 'supplier',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'supplierId',
          },
        },
        {
          name: 'Дебитор',
          field: 'debtor',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'debtorId',
          },
        },
        {
          name: '№ поставки',
          field: 'supplyNumber',
          type: 'link',
          linkData: {
            entityType: 'supply',
            entityId: 'supplyId',
          },
        },
        { name: 'Тип блокировки', field: 'type' },
        { name: 'Дата блокировки С', field: 'beginDate', type: 'date' },
        { name: 'Дата блокировки ПО', field: 'endDate', type: 'date' },
        { name: 'Комментарий', field: 'description' }, 
        { name: 'Пользователь', field: 'userId' }, 
      ],
    },
    limit: {
      dataFilters: [
        { name: 'inn', type: 'autocomplete', placeholder: 'Наименовани компании', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'type', type: 'select', options: limitTypesOptions },
        { name: 'onDate', type: 'date', placeholder: 'Действует ПО' },
        { name: 'supplierInn', type: 'autocomplete', placeholder: 'Допустимый поставщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'debtorInn', type: 'autocomplete', placeholder: 'Допустимый дебитор', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        {
          name: 'supplierHoldings',
          type: 'autocomplete',
          placeholder: 'ГК Поставщика',
          payloadKey: 'id',
          payloadKeyUI: 'name',
          request: Search.getFactoringHoldingSuggestions,
          requestKey: 'name',
          requestSubKeys: [],
        },
        {
          name: 'debtorHoldings',
          type: 'autocomplete',
          placeholder: 'ГК Дебитора',
          payloadKey: 'id',
          payloadKeyUI: 'name',
          request: Search.getFactoringHoldingSuggestions,
          requestKey: 'name',
          requestSubKeys: [],
        },
      ],
      dataItems: [
        { name: '№', field: 'id' },
        { name: 'Вид лимита', field: 'type' },
        {
          name: 'Наименовани компании',
          field: 'displayName',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'companyId',
          },
        },
        { name: 'Владелец лимита', field: 'indicative', type: 'boolean' },
        { name: 'Сумма лимита', field: 'limitAmount', type: 'amount' },
        { name: 'Использованный лимит', field: 'utilizedAmount', type: 'amount' },
        { name: 'Сумма свободного лимита', field: 'freelimitAmount', type: 'amount' },
        {
          name: 'Допустимый поставщик',
          field: 'suppliers',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'id',
          },
        },
        {
          name: 'Допустимый  дебитор',
          field: 'debtors',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'id',
          },
        },
        {
          name: 'ГД',
          field: 'factoringContractNumbers',
          type: 'link',
          linkData: {
            entityType: 'factoring_contract',
            entityId: 'factoringContractId',
          },
        },
        { name: 'Продукт', field: 'products' },
        { name: 'Субпродукт', field: 'subProducts' }, 
        { name: 'Оригиналы', field: 'originals' }, 
        { name: 'Категория качества', field: 'qualityCategory' },
        { name: 'Действует С', field: 'constraintBeginDate', type: 'date' }, 
        { name: 'Действует ПО', field: 'constraintEndDate', type: 'date' },
        { name: 'Дата подтверждения', field: 'confirmationDate', type: 'date' },
        { name: 'ГК Поставщика', field: 'supplierHoldings' },
        { name: 'ГК Дебитора', field: 'debtorHoldings' },
        { name: 'Комментарий', field: 'lastComment' },   
      ],
    },
    supply: {
      dataFilters: [
        {
          name: 'factoringContractId',
          type: 'autocomplete',
          placeholder: 'ГД',
          payloadKey: 'id',
          payloadKeyUI: 'number',
          request: Search.getFactoringContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
        },
        {
          name: 'supplyContractId',
          type: 'autocomplete',
          placeholder: 'Контракт',
          payloadKey: 'id',
          payloadKeyUI: 'number',
          request: Search.getSupplyContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['sellerName', 'startDate'],
        },
        { name: 'supplyNumberLike', type: 'text', placeholder: '№ поставки' },
        { name: 'supplyRegistryNumber', type: 'text', placeholder: '№ Реестра' },
        { name: 'supplierInn', type: 'autocomplete', placeholder: 'Поставщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'debtorInn', type: 'autocomplete', placeholder: 'Дебитор', payloadKey: 'INN', payloadKeyUI: 'displayName' },
      ],
      dataItems: [
        { name: 'ID поставки', field: 'id' },
        { name: 'Тип документа', field: 'documentType' },
        { name: '№ поставки', field: 'supplyNumber' },
        { name: '№ Реестра', field: 'supplyRegistryNumber' },
        { name: 'Дата реестра', field: 'supplyRegistryDate', type: 'date' },
        {
          name: 'ГД',
          field: 'factoringContractNumber',
          type: 'link',
          linkData: {
            entityType: 'factoring_contract',
            entityId: 'factoringContractId',
          },
        },
        {
          name: 'Поставщик',
          field: 'supplierName',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'supplierId',
          },
        },
        {
          name: 'Дебитор',
          field: 'debtorName',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'debtorId',
          },
        },
        { name: 'Дата', field: 'supplyDate', type: 'date' },
        { name: 'Приемка', field: 'acceptanceDate', type: 'date' },
        { name: 'Сумма', field: 'sum', type: 'amount' },
        { name: 'Валюта', field: 'currency' },
        { name: 'Отсрочка', field: 'deferDate', type: 'date' },
        { name: 'Просрочено дней', field: 'overdueDays' },
        { name: 'Статус', field: 'status', type: 'status' },
        { name: 'Ввод', field: 'createMethod' },
        { name: 'Продукт', field: 'productType' },
        {
          name: 'Контракт',
          field: 'supplyContractNumber',
          type: 'link',
          linkData: {
            entityType: 'supply_contract',
            entityId: 'supplyContractId',
          },
        },
        { name: 'Оригиналы', field: 'hasOriginals' },
        { name: 'Дата ожидания', field: 'waitingDate', type: 'date' },
        { name: 'Регистрация', field: 'registrationDate', type: 'date' },
        { name: 'Закрыто', field: 'factDate', type: 'date' },
        { name: 'Дата факт. погашения поставки', field: 'repayedDate', type: 'date' },
        { name: 'Верификация', field: 'verification' },
        { name: 'Уведомление о просрочке платежа дебитором', field: 'debtorsPaymentDelayNotification' },
        { name: 'Дата погаш фин', field: 'finPaymentDate', type: 'date' },
        { name: 'Последний', field: 'last' },
        { name: 'Изменение', field: 'changedBy' },
        { name: 'Закрытый факторинг', field: 'closedFactoring' },
        { name: 'Подтвержденная сумма', field: 'approvedSum', type: 'amount' },
        { name: 'Дата верификации', field: 'verificationDate', type: 'date' },
        { name: 'Долг по финансированию', field: 'asa', type: 'amount' },
        { name: 'Неоплачено Продавцом', field: 'asb', type: 'amount' },
        { name: 'Не оплачено дебитором', field: 'asc', type: 'amount' },
        { name: 'Комиссия за финансирование', field: 'asd', type: 'amount' },
        { name: 'Комиссия за ФУДЗ', field: 'ase', type: 'amount' },
        { name: 'Комиссия за АУДЗ', field: 'asf', type: 'amount' },
        { name: 'Создал', field: 'createdBy' },
        { name: 'Создание', field: 'creation' },
        { name: 'Примечание', field: 'notification' },
        { name: 'Комментарий', field: 'comment' },
        { name: 'Тип поставки', field: 'supplyType' },
      ],
      selectable: true,
    },
    operation: {
      dataFilters: [
        { name: 'masterOperationType', type: 'select', options: masterOperationTypesOptions },
        { name: 'code', type: 'select', options: operationCodeTypesOptions },
        { name: 'status', type: 'select', options: operationTypeOptions },
        { name: 'createDateFrom', type: 'date', placeholder: 'Дата создания ОТ' },
        { name: 'createDateTo', type: 'date', placeholder: 'Дата создания ПО' },
        { name: 'operationDate', type: 'date', placeholder: 'Дата проводки' },
        { name: 'creditAccount', type: 'text', placeholder: 'Счет кредита' },
        { name: 'debitAccount', type: 'text', placeholder: 'Счет дебета' },
        { name: 'sumFrom', type: 'text', placeholder: 'Сумма проводки ОТ' },
        { name: 'sumTo', type: 'text', placeholder: 'Сумма проводки ДО' },
        {
          name: 'supplierId',
          type: 'autocomplete',
          placeholder: 'Поставщик',
          payloadKey: 'INN',
          payloadKeyUI: 'displayName',
        },
        {
          name: 'factoringContractId',
          type: 'autocomplete',
          placeholder: 'ГД',
          payloadKey: 'id',
          payloadKeyUI: 'number',
          request: Search.getFactoringContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
        },
      ],
      dataItems: [
        { name: 'Счет дебета', field: 'debitAccount' },
        { name: 'Счет кредита', field: 'creditAccount' },
        { name: 'Сумма проводки', field: 'sum', type: 'amount' },
        { name: 'Вид проводки', field: 'description' },
        { name: 'Назначение', field: 'purpose' },
        { name: 'Дата создания', field: 'createDate', type: 'date' }, 
        { name: 'Дата проводки', field: 'operationDate', type: 'date' },
        { name: 'ID АБС', field: 'absId' }, 
        { name: 'Валюта', field: 'currency' },
        { name: '№ поставки', field: 'supplyNumber' },
        { name: 'Сумма поставки', field: 'supplySum', type: 'amount' },
        { name: 'Дата поставки', field: 'supplyDate', type: 'date' }, 
        { name: 'Дата регистрации', field: 'supplyRegistration', type: 'date' },
        { name: '№ финансирования', field: 'financingNumber' },
        { name: 'Продукт', field: 'product' },
        { name: 'ГД', field: 'factoringContractNumber' },    
        { name: 'Дата ГД', field: 'factoringContractDate', type: 'date' }, 
        { name: 'Статус', field: 'status', type: 'status' },
        { name: 'Сущность', field: 'masterOperationEntity' },
        { name: 'Тип операции', field: 'masterOperationType' },
        { name: 'masterOperationId', field: 'masterOperationId' },
      ],
    },
    product: {
      dataFilters: [
        { name: 'offeringKey', type: 'select', options: offeringKeyOptions },  
      ],
      dataItems: [
        {
          name: 'Поставщик',
          field: 'supplierDisplayName',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'supplierId',
          },
        },
        {
          name: 'Дебитор',
          field: 'debtorDisplayName',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'debtorId',
          },
        },
        {
          name: 'ГД',
          field: 'factoringContractNumber',
          type: 'link',
          linkData: {
            entityType: 'factoring_contract',
            entityId: 'externalId',
          },
        },
        { name: 'Дата ГД', field: 'factoringContractDate', type: 'date' },
        { name: 'Общие. Вид продукта', field: 'commonProductType' },
        { name: 'Общие. Доп ставка, если счёт в другом банке', field: 'commonOtherBankAdditionalRate', type: 'rate' },
        { name: 'Общие. Момент оплаты комиссии', field: 'commonCommissionPayMoment' },
        { name: 'Общие. Дней до оплаты комиссии после финансирования', field: 'commonCommissionDaysAfterFinancing' },
        { name: 'Общие. День месяца оплаты комиссии', field: 'commonCommissionMonthDay' },
        { name: 'Общие. Дней до оплаты комиссии после погашения', field: 'commonCommissionDaysAfterRepayment' },
        { name: 'За финансирование. Дата окончания', field: 'finRateEndDateType' },
        { name: 'За финансирование. Учёт НДС', field: 'finNdsType' },
        { name: 'За финансирование. Вид ставки', field: 'finRateType' },
        { name: 'За финансирование. Пересчёт ставки в случае изменения Ключевой', field: 'finNeedRecalcRate' },
        { name: 'За финансирование. Дата действия ставки', field: 'finRateValidityDateType' },
        { name: 'За финансирование. Период расчета суммы: Дата начала', field: 'finAmountCalcPeriodStartDateType' },
        { name: 'За финансирование. Период расчета суммы: Дата окончания для расчета базы', field: 'finAmountCalcPeriodEndDateType' },
        { name: 'За финансирование. Определение суммы расчета', field: 'finAmountCalcType' },
        { name: 'За финансирование. Способ округления', field: 'finRoundingType' },
        { name: 'За финансирование. Кол-во знаков при округлении', field: 'finRoundingScale', type: 'scale' },
        { name: 'За документы. Учёт НДС', field: 'docsNdsType' },
        { name: 'За документы. Дата действия ставки', field: 'docsRateValidityDateType' },
        { name: 'ФУДЗ. Вид ставки', field: 'fudzRateType' },
        { name: 'ФУДЗ. Учёт НДС', field: 'fudzNdsType' },
        { name: 'ФУДЗ. Дата действия ставки', field: 'fudzRateValidityDateType' },
        { name: 'ФУДЗ. Период расчета суммы: Дата начала', field: 'fudzAmountCalcPeriodStartDateType' },
        { name: 'ФУДЗ. Период расчета суммы: Дата окончания для расчета базы', field: 'fudzAmountCalcPeriodEndDateType' },
        { name: 'ФУДЗ. Способ округления', field: 'fudzRoundingType' },
        { name: 'ФУДЗ. Кол-во знаков при округлении', field: 'fudzRoundingScale', type: 'scale' },
        { name: 'Пени %', field: 'finFine', type: 'rate' },
      ],
    },
    order: {
      dataFilters: [
        { name: 'principalName', type: 'autocomplete', placeholder: 'Плательшик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'amountFrom', type: 'text', placeholder: 'Ожидаемая сумма ОТ' },
        { name: 'amountTo', type: 'text', placeholder: 'Ожидаемая сумма ДО' },
        { name: 'orderType', type: 'text', placeholder: 'Вид оплаты' },
        { name: 'number', type: 'text', placeholder: 'Номер' }, 
        { name: 'dateFrom', type: 'date', placeholder: 'Дата С' },
        { name: 'dateTo', type: 'date', placeholder: 'Дата ПО' },
      ],
      dataItems: [
        { name: 'Плательшик', field: 'principalName' },
        { name: 'Ожидаемая сума', field: 'amount', type: 'amount' }, 
        { name: 'Оплаченная сумма', field: 'matchedAmount', type: 'amount' },
        { name: 'Оплачен полностью', field: 'fullyMatched', type: 'boolean' },
        { name: 'Вид оплаты', field: 'orderType' },
        { name: 'Номер', field: 'number' },
        { name: 'Дата/время создания', field: 'orderDate', type: 'date' }, 
        { name: 'Ожидаемый остаток', field: 'unmatchedAmount', type: 'amount' }, 
      ],
    },
    account: {
      dataFilters: [
        {
          name: 'numberLike',
          type: 'autocomplete',
          placeholder: 'Номер счета',
          payloadKey: 'number',
          payloadKeyUI: 'number',
          request: Search.getFactoringAccountSuggestions,
          requestKey: 'number',
          requestSubKeys: ['entityId', 'accountType', 'entityType'],
        },
        {
          name: 'accountType',
          // type: 'select',
          // options: accountTypeOptions,
          type: 'autocomplete',
          placeholder: 'Тип счёта',
          payloadKey: 'value',
          payloadKeyUI: 'name',
          request: Factoring.getFactoringRefAccountTypes,
          requestKey: 'name',
          requestSubKeys: ['value'],
        },
        {
          name: 'factoringContractId',
          type: 'autocomplete',
          placeholder: 'ГД',
          payloadKey: 'id',
          payloadKeyUI: 'number',
          request: Search.getFactoringContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
        },
        {
          name: 'supplierId',
          type: 'autocomplete',
          placeholder: 'Поставщик',
          payloadKey: 'id',
          payloadKeyUI: 'displayName',
        },
      ],
      dataItems: [
        { name: 'Номер счета', field: 'number' },
        { name: 'Тип счета', field: 'accountType' },
        { name: 'Баланс', field: 'balance', type: 'amount' },
        { name: 'Подтвержденный баланс', field: 'acceptedBalance', type: 'amount' },
        { name: 'БИК', field: 'bic' },
        { name: 'Кор. счет', field: 'corrAccount' },
        { name: 'Активный', field: 'active', type: 'boolean' }, 
        { name: 'Внешний', field: 'external', type: 'boolean' },
        { name: 'Тип владельца', field: 'entityType' },
        { name: 'Название владельца', field: 'ownerName' },
        { name: 'Поставщик', field: 'supplierName' },
        { name: 'Дебитор', field: 'debtorName' },
        { name: 'ГД', field: 'factoringContractNumber' },
        { name: 'Продукт', field: 'productType' },
        { name: 'Субпродукт', field: 'subProduct' },
        { name: 'Наименование банка', field: 'bankName' },
        { name: 'Город банка', field: 'bankCity' },
        { name: 'Статус', field: 'status', type: 'status' },
      ]
    },
    payment: {
      dataFilters: [
        { name: 'payerInn', type: 'autocomplete', placeholder: 'Плательщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        {
          name: 'numberLike',
          type: 'autocomplete',
          placeholder: 'Номер счета плательщика',
          payloadKey: 'number',
          payloadKeyUI: 'number',
          request: Search.getFactoringAccountSuggestions,
          requestKey: 'number',
          requestSubKeys: ['entityId', 'accountType', 'entityType'],
        },
        { name: 'amountFrom', type: 'text', placeholder: 'Сумма С' },
        { name: 'amountTo', type: 'text', placeholder: 'Сумма ПО' },
        { name: 'dateFrom', type: 'date', placeholder: 'Дата С' },
        { name: 'dateTo', type: 'date', placeholder: 'Дата ПО' },
        { name: 'fullyMatched', type: 'select', options: fullyMatchedOptions },
      ],
      dataItems: [
        { name: 'Номер платежа', field: 'number' },
        { name: 'Сумма', field: 'amount', type: 'amount' },
        { name: 'Дата платежа', field: 'date', type: 'date' },
        { name: 'Назначение платежа', field: 'purpose' },
        { name: 'Сквитованная сумма', field: 'matchedAmount', type: 'amount' },
        { name: 'Не сквитованная сумма', field: 'unmatchedAmount', type: 'amount' },
        { name: 'Сквитован полностью', field: 'fullyMatched', type: 'boolean' },
        { name: 'Наименование плательщика', field: 'payerName' },
        { name: 'ИНН плательщика', field: 'payerInn' },
        { name: 'КПП плательщика', field: 'payerKpp' },
        { name: 'БИК плательщика', field: 'payerBic' },
        { name: 'Номер счета плательщика', field: 'payerAccountNumber' },
        { name: 'Наименование получателя', field: 'receiverName' },
        { name: 'ИНН получателя', field: 'receiverInn' },
        { name: 'КПП получателя', field: 'receiverKpp' },
        { name: 'БИК получателя', field: 'receiverBic' },
        { name: 'Номер счета получателя', field: 'receiverAccountNumber' },
      ],
    },
    reserve_msfo: {
      dataFilters: [],
      dataItems: [
        { name: 'Контрагент', field: 'externalId' },
        { name: 'Корзина', field: 'reserveMsfoBucket' },
        { name: 'Ставка ручн.(%)', field: 'reserveMsfoManualRate' },
        { name: 'Ставка сред.(%)', field: 'reserveMsfoAverageRate' },
        { name: 'Ставка (%)', field: 'reserveMsfoRate' },
        { name: 'EQ ID', field: 'reserveMsfoEqId' },     
      ],
    },
    reserve_rsbu: {
      dataFilters: [],
      dataItems: [
        {
          name: 'Контрагент',
          field: 'displayName',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'externalId',
          },
        },
        { name: 'Категория клиента', field: 'reserveRsbuClientCategory', type: 'rate' },
        { name: 'Процент резервирования', field: 'reserveRsbuReservePercent', type: 'rate' },     
      ],
    },
    notification: {
      dataFilters: [
        { name: 'payerId', type: 'autocomplete', placeholder: 'Плательщик', payloadKey: 'id', payloadKeyUI: 'displayName' },
        { name: 'dateFrom', type: 'date', placeholder: 'Дата С' },
        { name: 'dateTo', type: 'date', placeholder: 'Дата ПО' },
      ],
      dataItems: [
        {
          name: 'Плательщик',
          field: 'displayName',
          type: 'link',
          linkData: {
            entityType: 'counterparty',
            entityId: 'payerId',
          },
        },
        { name: 'Итого. Расчтная сумма', field: 'calcAmount', type: 'amount'  },
        { name: 'Итого. Сумма НДС', field: 'ndsAmount', type: 'amount' }, 
        { name: 'Итого. Сумма комиссии с НДС', field: 'amountWithNds', type: 'amount' },      
        { name: 'Номер уведомления', field: 'notificationNumber' },       
        { name: 'Дата уведомления', field: 'notificationDate' , type: 'date' },        
      ],
    },
    master_operation: {
      dataFilters: [
        { name: 'type', type: 'text', placeholder: 'Тип операции' },
        { name: 'entityType', type: 'text', placeholder: 'Тип сущности' },
      ],
      dataItems: [
        { name: 'Тип операции', field: 'type' }, 
        { name: 'Тип сущности', field: 'entityType' },
        { name: 'Сущность', field: 'entityNumber' },
        { name: 'Способ группировки', field: 'grouping' },
        { name: 'ГД', field: 'factoringContractNumber' },
        { name: 'Договор поставки', field: 'supplyContractNumber' }, 
        { name: 'Дебитор', field: 'debtorName' },
        { name: 'Факт финансирования', field: 'hasFinansing' },
        { name: 'Дата и время операции', field: 'createDateTime', type: 'datetime' },
      ],
    },
    supply_registry: {
      dataFilters: [],
      dataItems: [
        { name: 'ID Реестра', field: 'id' }, 
        { name: '№ реестра', field: 'number' },
        { name: 'Поставщик', field: 'supplierName' },
        { name: 'Дата реестра', field: 'createDate', type: 'date' },
        { name: 'Статус', field: 'status' },
        { name: 'Ввод', field: 'createMethod' }, 
        { name: 'Количество поставок', field: 'supplyCount' },
        { name: 'Сумма поставок', field: 'supplySum', type: 'amount' },
      ],
    },
  }

  return entityType && factoring[entityType]
    ? factoring[entityType]
    : DEFAULT_TASK_CONFIG
}