import axios from 'axios'
import { API_URL } from './../../config'

export const getContracts = async (entityType, filters = {}) => {
  try {
    const options = {
      url: `${API_URL}/contract/${entityType}`,
      method: 'GET',
      params: {
        ...filters,
      },
    }
    const response = await axios(options)
    return response
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getContractType = async (filters = {size: 1000}) => {
  try {
    const options = {
      url: `/es/ref/contractType/_search`,
      method: 'GET',
      params: {
        ...filters,
      },
    }
    const response = await axios(options)
    return response
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getContractStatus = async (filters = {size: 1000}) => {
  try {
    const options = {
      url: `/es/ref/contractStatus/_search`,
      method: 'GET',
      params: {
        ...filters,
      },
    }
    const response = await axios(options)
    return response
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}