import * as actionTypes from './actionTypes'

const initialState = {
  text: '',
  show: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ERROR_SHOW:
      return {
        text: action.message,
        show: true,
      }
    case actionTypes.ERROR_HIDE:
      return { 
        ...initialState 
      }
    default:
      return state
  }
}