import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  subtitle: {
    fontSize: 18,
    lineHeight: 32/18,
    fontWeight: 500,
    color: '#000',
  },
})

export const SubTitle = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.subtitle}>{children}</div>
  )
}