import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import React, { useEffect, useRef, useCallback, useState } from 'react'
import { commonStatusStyles } from '../all3d/styles'
import { useStandardsGraphicOptions } from './graphicOptions'
import { FullScreenIcon } from '../../Layout/FullScreenIcon'
import { DetailsTable } from '../../Layout/DetailsTable'
import { StandardsDetailsItem } from './StandardsDetailsItem'
import { uas } from '../../../../services/utility/charts/uas'

HighchartsExporting(Highcharts)

export const StandardsGraphic = commonStatusStyles(({
  classes,
  data,
  fullWidth,
  changeFullWidth,
  onCancel,
}) => {
    const chartRef = useRef()
    const [details, setDetails] = useState()
    const onCloseDetails = useCallback(() => { 
      setDetails()
    }, [])
    const onClick = () => {
      onCloseDetails()
      onCancel()
    }

    useEffect(() => {
      if (chartRef.current) {
        chartRef.current.chart.width = 1000
      }
    }, [chartRef])

    const onClickBar = useCallback(
      e => {
        if (e.point.name_id === 'time' && chartRef.current) {
          const chart = chartRef.current.chart
          const response = data
          const custom_id = e.point.task.custom_id

          outer_loop: for (let i = 0, l = chart.series.length; i < l; i++) {
            let series_data = chart.series[i].data
            for (let j = 0, l2 = series_data.length; j < l2; j++) {
              // eslint-disable-next-line eqeqeq
              if (series_data[j].task != undefined) {
                if (series_data[j].task.custom_id === custom_id) {
                  var point = series_data[j]
                  break outer_loop
                }
              }
            }
          }

          if (point && point.keys) {
            let h = response

            // eslint-disable-next-line no-redeclare
            for (let i = 0, j = point.keys.length; i < j; i++) {
              h = h[point.keys[i]]

              if (!h) {
                h = null
                break
              }
            }

            const list = (h ? [h] : []).map(item => ({
              ...item,
              time: uas.helpers.timeFormat(
                Math.round(item.time * 60),
                '%Hч:%Mм'
              ),
              timeLimit: uas.helpers.timeFormat(
                Math.round(item.timeLimit * 60),
                '%Hч:%Mм'
              ),
              median: uas.helpers.timeFormat(
                Math.round(item.median * 60),
                '%Hч:%Mм'
              ),
              inTimePrc: Math.round(item.inTimePrc),
            }))

            setDetails({ name: h.name, list })
          }
        }
      },
      [chartRef, data]
    )

    const options = useStandardsGraphicOptions({ data, fullWidth, onClickBar })

    return (
      <>
        <div className={classes.graphicContainer}>
          <FullScreenIcon fullWidth={fullWidth} onClick={changeFullWidth} />
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
            onClickBar={onClickBar}
          />
        </div>
        {details &&
          <DetailsTable
            data={details}
            onClose={onCloseDetails}
            Item={StandardsDetailsItem}
            listKey='list'
            onClick={onClick}
          >
            <StandardsDetailsItem
              data={{
                user: 'Пользователь',
                orderCount: 'Сделок',
                taskCount: 'Задач',
                time: 'Среднее время',
                timeLimit: 'Норматив',
                inTimePrc: 'Выполнено в срок, %',
                median: 'Медиана',
              }}
              isHeader
            />
          </DetailsTable>
        }
      </>
    )
  }
)
