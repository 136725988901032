import * as actionTypes from './actionTypes'

const initialState = {
  items: [],
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DADATA:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_DADATA_SUCCESS:
      return {
        ...state,
        items: action.data,
        isFetching: false,
      }
    case actionTypes.GET_DADATA_ERROR:
      return { 
        ...state,
        items: [],
        isFetching: false, 
      }
    default:
      return state
  }
}