import React, { useState, useEffect, useRef } from 'react'
import injectSheet from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from '../index'
import { ConfirmModal } from '../../../components/ConfirmModal'
import Overlay from '../../../components/Overlay'
import { formattedNumber } from '../../../utils'
import UI from '../../UI'
import { paymentDone } from '../../../redux/Installment/actions'
import InstallmentTooltip from './InstallmentTooltip'

const styles = {
  installmentItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& + &': {
      marginTop: 20,
    },
    '& > div': {
      '& > span': {
        background: '#E4E4E4',
        padding: [11, 25],
        borderRadius: 16,
        fontSize: 18,
        lineHeight: 26/18,
        fontWeight: 500,
        display: 'inline-block',
        verticalAlign: 'top',
        '& + span': {
          marginLeft: 20,
        },
      },
      '& + div': {
        marginLeft: 20,
      },
    },
  },
  button: {
    ...UI.addButton,
    marginLeft: 25,
    padding: [5, 16, 4, 16],
    '& + &': {
      marginTop: 8,
    },
    '& span': {
      color: 'transparent',
    },
  },
  tooltipRef: {
    cursor: 'pointer',
  },
  status: {
    padding: [11, 25],
    fontSize: 16,
    lineHeight: 20/16,
    borderRadius: 16,
    border: [1, 'solid', 'transparent'],
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}

const Installment = ({
  classes,
  title,
  installment,
  onGetCompanyOrder,
}) => {
  const dispatch = useDispatch()
  const tooltipRef = useRef(null)

  const {
    installmentId = '',
    paymentSchedule = []
  } = installment || {}

  const { isFetching } = useSelector(state => state?.Installment || {})

  const [paymentScheduleId, setPaymentScheduleId] = useState(null)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (tooltipRef?.current) {
      setReady(true)
    }
  }, [tooltipRef])

  const onOpenConfirmModal = (id) => setPaymentScheduleId(id)
  const onCloseConfirmModal = () => setPaymentScheduleId(null)

  const onPaymentDone = () => {
    const payload = {
      installmentId,
      paymentScheduleId,
    }
    const onSuccess = () => {
      onGetCompanyOrder()
      onCloseConfirmModal()
    }
    const onError = () => onCloseConfirmModal()
    
    dispatch(paymentDone(payload, onSuccess, onError))
  }

  const selectedPaymentSchedule = paymentSchedule?.find(item => item?.id === paymentScheduleId) || {}

  const renderStatus = ({ isPaid, penny }) => {
    let text = ''
    let styles = {}
    if (isPaid) { 
      text = 'Оплачен'
      styles = { color: '#155724', backgroundColor: '#d4edda', borderColor: '#c3e6cb' }
    }
    if (!isPaid && !penny) { 
      text = 'Не оплачен'
      styles = { color: '#856404', backgroundColor: '#fff3cd', borderColor: '#ffeeba' }
    }
    if (!isPaid && penny) {
      text = 'Просрочен'
      styles = { color: '#721c24', backgroundColor: '#f8d7da', borderColor: '#f5c6cb' }
    }
    return (
      <div className={classes.status} style={styles}>{text}</div>
    )
  }

  if (isFetching) {
    return <Overlay />
  }

  if (!installment || !paymentSchedule) {
    return null
  }
  
  return (
    <>
      <Modal.Block iconName='file' title={title}>
        <Modal.Content>
          {(paymentSchedule || []).map(({ id, dueDate, totalAmount, amount, penny, isPaid }, index) => {
            const tooltipId = `installmentItem-${index}`
            return dueDate && id
              ? <div className={classes.installmentItem} key={index}>
                  <div className={classes.flex}>
                    <span>{new Date(dueDate)?.toLocaleDateString('ru-RU')}</span>
                    <span
                      id={tooltipId}
                      ref={tooltipRef}
                      className={classes.tooltipRef}
                    >
                      {formattedNumber(totalAmount)} ₽
                    </span>
                    {ready &&
                      <InstallmentTooltip
                        id={tooltipId}
                        value={penny
                          ? `Основная сумма - ${amount} ₽, пени - ${penny} ₽`
                          : `Основная сумма - ${amount} ₽`
                        }
                      />
                    }
                  </div>
                  <div className={classes.flex}>
                    {renderStatus({ isPaid, penny })}
                    {!isPaid &&
                      <div
                        className={classes.button}
                        onClick={() => onOpenConfirmModal(id)}
                      >
                        Подтвердить платёж
                      </div>
                    }
                  </div>
                </div>
              : null
            })
          }
        </Modal.Content>
      </Modal.Block>
      {paymentScheduleId &&
        <ConfirmModal
          title={`Вы уверены, что хотите подтвердить платёж на сумму ${formattedNumber(selectedPaymentSchedule?.totalAmount)} ₽?`}
          submitTitle='Да'
          onCancel={onCloseConfirmModal}
          onSubmit={onPaymentDone}
        />
      }
    </>
  )
}

export default injectSheet(styles)(Installment)