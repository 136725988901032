import { useMemo } from 'react'
import moment from 'moment'
import { uas } from '../../../../services/utility/charts/uas'
import { useChartSize } from '../../useChartSize'

export const useCommonStatusGraphicOptions = ({ data, fullWidth }) => {
  const { width, height } = useChartSize({ fullWidth })

  const memoizedOptions = useMemo(
    () => ({
      chart: {
        // renderTo: $chart.get(0),
        height,
        width,
        spacing: width > 550 ? [8, 32, 60, 0] : [8, 40, 60, 40],
        type: 'scatter',
        options3d: {
          enabled: true,
          alpha: 10,
          beta: 30,
          depth: 250,
          viewDistance: 5,
          fitToPlot: false,
          frame: {
            bottom: { size: 1, color: 'rgba(0,0,0,0.02)' },
            back: { size: 1, color: 'rgba(0,0,0,0.04)' },
            side: { size: 1, color: 'rgba(0,0,0,0.06)' },
          },
        },
        events: {
          beforePrint: function () {
            this.update({
              title: {
                y: 12,
              },
            })
          },
          afterPrint: function () {
            //   if (!$container.hasClass("fullscreen")) {
            //     this.update({
            //       title: {
            //         y: -32,
            //       },
            //     });
            //   }
          },
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ['downloadPNG'],
          },
        },
        scale: 4,
        chartOptions: {
          title: {
            y: 12,
          },
          legend: {
            enabled: true,
            floating: false,
          },
        },
      },
      title: {
        text: 'Космический 3D график по сделкам БГ',
        y: -32,
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          var a = [],
            b = []

          if (this.point.ext.principalName) {
            a.push(
              '<tr><th>Клиент</th><td>' +
                this.point.ext.principalName +
                '</td></tr>'
            )
          }

          if (this.point.ext.principalInn) {
            a.push(
              '<tr><th>ИНН</th><td>' +
                this.point.ext.principalInn +
                '</td></tr>'
            )
          }

          if (this.point.ext.orderDate) {
            a.push(
              '<tr><th>Дата сделки</th><td>' +
                this.point.ext.orderDate +
                '</td></tr>'
            )
          }

          a.push(
            '<tr><th>Сумма</th><td>' +
              uas.helpers.numberToCurrency(this.point.y, false) +
              ' ₽</td></tr>'
          )
          a.push('<tr><th>Срок</th><td>' + this.point.z + ' (дней)</td></tr>')

          if (this.point.ext.inProcessTime) {
            a.push(
              '<tr><th>Текущее время исполнения</th><td>' +
                uas.helpers.timeFormat(
                  Math.round(this.point.ext.inProcessTime * 60),
                  '%Hч:%Mм'
                ) +
                '</td></tr>'
            )
          }

          if (this.point.ext.taskNorm) {
            a.push(
              '<tr><th>Норматив</th><td>' +
                uas.helpers.timeFormat(
                  Math.round(this.point.ext.taskNorm * 60),
                  '%Hч:%Mм'
                ) +
                '</td></tr>'
            )
          }

          a = ['<table class="chart-tooltip">' + a.join('') + '</table>']

          a.push('<div class="chart-task">')

          if (this.point.ext.taskName) {
            a.push('<h5>' + this.point.ext.taskName + '</h5>')
          }

          if (this.point.ext.taskGroup) {
            b.push(this.point.ext.taskGroup)
          }
          if (this.point.ext.taskAssignee) {
            b.push(
              '<a class="js_get_name" href="#name">' +
                this.point.ext.taskAssignee +
                '</a>'
            )
          }

          if (b.length) {
            b = [b.join('&nbsp;&bullet; ')]
          }

          if (this.point.ext.taskStartTime) {
            try {
              b.unshift(
                '<span class="date">' +
                  uas.date.strftime(
                    this.point.ext.taskStartTime,
                    '%d.%m.%Y, %H:%M'
                  ) +
                  '</span>'
              )
            } catch (error) {
              b.unshift(
                '<span class="date">' +
                  moment(this.point.ext.taskStartTime).format(
                    'DD.MM.YYYY, hh:mm'
                  ) +
                  '</span>'
              )
            }
          }

          if (b.length) {
            a.push('<p>' + b.join(' ') + '</p>')
          }

          a.push('</div>')

          return a.join(' ')
        },
      },
      plotOptions: {
        scatter: {
          width: 10,
          height: 10,
          depth: 10,
        },
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: 'Сумма',
        },
      },
      xAxis: {
        allowDecimals: false,
        title: {
          text: '',
        },
        labels: {
          enabled: false,
        },
        gridLineWidth: 1,
      },
      zAxis: {
        allowDecimals: false,
        title: {
          text: 'Срок (дней)',
          x: 0,
          y: 20,
        },
        reversed: true,
        showFirstLabel: true,
        labels: {
          enabled: true,
        },
      },
      legend: {
        align: 'left',
        x: 22,
        y: 52,
      },
      series: [
        {
          name: 'выполнено вовремя',
          color: '#287782',
          marker: {
            symbol: 'circle',
          },
          data: data.inTime,
        },
        {
          name: 'выполнено с опозданием',
          color: '#E7391E',
          marker: {
            symbol: 'circle',
          },
          data: data.notInTime,
        },
        {
          name: 'статус неизвестен',
          color: '#81888C',
          marker: {
            symbol: 'circle',
          },
          data: data.unknown,
        },
      ],
    }),
    [data, width, height]
  )

  return memoizedOptions
}
