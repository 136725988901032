import * as actionTypes from './actionTypes'
import { Installment } from '../../services/api'
import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'

export const paymentDone = (payload, onSuccess, onError) => async (dispatch) => {
  const showError = (error) => {
    console.log(error)
    dispatch(setErrorContent(error))
    dispatch({ 
      type: actionTypes.PAYMENT_DONE_ERROR,
      error,
    })
    onError && onError()
  }
  try {
    dispatch({ type: actionTypes.PAYMENT_DONE })
    const { isSuccess, needLogout, message } = await Installment.paymentDone(payload)
    if (isSuccess) {
      dispatch({ type: actionTypes.PAYMENT_DONE_SUCCESS })
     onSuccess && onSuccess()
    } else {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      showError(message)
    }
  } catch (error) {
    showError(error)
  }
}