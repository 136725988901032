import injectSheet from 'react-jss'

export const commonStatusStyles = injectSheet({
  root: {},
  type: {
    margin: [0, 'auto'],
    maxWidth: 320,
    marginBottom: 20,
  },
  typeStandards: {
    margin: [0, 'auto'],
    maxWidth: 320,
    marginTop: 20,
  },
  content: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    maxWidth: 600,
    minHeight: 350,
    margin: [0, 'auto'],
    '&.fullWidth': {
      display: 'none',
    },
  },
  graphicContainer: {
    marginTop: 30,
    position: 'relative',
  },
  hoursContainer: {
    display: 'flex',
  },
  hoursLabel: {
    lineHeight: '38px',
    marginLeft: 4,
  },
  crumbs: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: [0, 32],
    '& > :not(:last-child)': {
      marginRight: 10,
    },
  },
  crumb: {
    color: '#7F8C92',
  },
  activeCrumb: {
    cursor: 'pointer',
    color: '#002863',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  task: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    fontSize: 11,
    lineHeight: 16/11,
    color: '#E7391E',
  },
  analysisTasksTitle: {
    textAlign: 'center',
    marginTop: 30,
  },
  noData: {
    color: '#7F8D92',
    textAlign: 'center',
    padding: [30, 20, 0],
  },
  mb20: {
    marginBottom: 20,
  },
  label: {
    paddingBottom: 4,
    color: '#000',
    fontSize: 14,
    lineHeight: 1.21,
    fontWeight: 500,
  },
})
