import { useWindowSize } from '../../hooks'
import { useMemo } from 'react'

export const useChartSize = ({ fullWidth }) => {
  const { width, height } = useWindowSize()

  const heightFull = height - 320
  const widthFull = width > 900 ? width - 220 : width - 120

  return useMemo(() => ({
    height: !fullWidth ? 400 : heightFull,
    width: !fullWidth ? Math.min(600, widthFull) : widthFull,
  }), [heightFull, widthFull, fullWidth])
}