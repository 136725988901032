import axios from 'axios'
import { API_URL } from '../../config'

export const getDadata = async ({ query, service }) => {
  try {
    const { data: { error_code, ...rest } } = await axios({
      method: 'POST',
      url: `${API_URL}/dadata/suggest/${service}`,
      data: { query },
    })
    // if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: status === 2,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}