export const GET_BASE_SCHEMA_LIST = 'GET_BASE_SCHEMA_LIST'
export const GET_BASE_SCHEMA_LIST_PENDING = 'GET_BASE_SCHEMA_LIST_PENDING'
export const GET_BASE_SCHEMA_LIST_SUCCESS = 'GET_BASE_SCHEMA_LIST_SUCCESS'
export const GET_BASE_SCHEMA_LIST_ERROR = 'GET_BASE_SCHEMA_LIST_ERROR'

export const GET_BASE_SCHEMA = 'GET_BASE_SCHEMA'
export const GET_BASE_SCHEMA_PENDING = 'GET_BASE_SCHEMA_PENDING'
export const GET_BASE_SCHEMA_SUCCESS = 'GET_BASE_SCHEMA_SUCCESS'
export const GET_BASE_SCHEMA_ERROR = 'GET_BASE_SCHEMA_ERROR'

export const CREATE_BASE_SCHEMA = 'CREATE_BASE_SCHEMA'
export const CREATE_BASE_SCHEMA_PENDING = 'CREATE_BASE_SCHEMA_PENDING'
export const CREATE_BASE_SCHEMA_SUCCESS = 'CREATE_BASE_SCHEMA_SUCCESS'
export const CREATE_BASE_SCHEMA_ERROR = 'CREATE_BASE_SCHEMA_ERROR'

export const UPDATE_BASE_SCHEMA = 'UPDATE_BASE_SCHEMA'
export const UPDATE_BASE_SCHEMA_PENDING = 'UPDATE_BASE_SCHEMA_PENDING'
export const UPDATE_BASE_SCHEMA_SUCCESS = 'UPDATE_BASE_SCHEMA_SUCCESS'
export const UPDATE_BASE_SCHEMA_ERROR = 'UPDATE_BASE_SCHEMA_ERROR'

export const CLEAR_BASE_SCHEMA = 'CLEAR_BASE_SCHEMA'