import React, { Fragment, useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { SubTitle, Value, Item } from '../index'
import { Icon } from '../../../../../../layout'
import { Form } from '../index'
import { ModalConfirm } from '../../../../../Modals'
import { formatMoney } from '../../../../../../utils'

const useStyles = createUseStyles({
  root: {
    background: 'linear-gradient(0deg, #f3f3f3, #f3f3f3), #e8fbf2',
    padding: 20,
    borderRadius: 16,
    position: 'relative',
    '&.overLimitAmount': {
      background: '#ccc',
    },
  },
  gap: {
    height: 10,
  },
  actions: {
    position: 'absolute',
    top: '50%',
    right: 30,
    transform: 'translateY(-50%)',
  },
  action: {
    width: 24,
    height: 24,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    '& + &': {
      marginLeft: 16,
    },
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
})

export const Conditions = ({
  calculatedTotalAmountState,
  totalAmountState,
  totalAmountStateAux,
  constraintsState,
  onEdit,
  onEditAux,
  onRemove,
  onRemoveAux,
  readOnly,
}) => {
  const classes = useStyles()

  const [editedIndex, setEditedIndex] = useState(null)
  const [deletedIndex, setDeletedIndex] = useState(null)

  const onCloseEditMode = () => setEditedIndex(null)
  const onCloseDeleteMode = () => setDeletedIndex(null)

  const _onRemove = () => {
    onRemove(deletedIndex)
    onRemoveAux(deletedIndex)
    onCloseDeleteMode()
  }

  return (
    <>
      {constraintsState?.length > 0 && constraintsState.map(({
        id,
        limitAmount,
        maxOrderAmount,
        prepaidPercent,
        startMaxOrderAmount,
        overLimitAmount,
      }, index) => {
          const items = [
            { title: 'НМЦК', value: formatMoney(startMaxOrderAmount) },
            { icon: 'plus' },
            { title: 'Максимальная сумма 1й гарантии', value: formatMoney(maxOrderAmount) },
            { icon: 'plus' },
            { title: 'Размер аванса', value: prepaidPercent ? `${prepaidPercent}%` : '—' },
            { icon: 'arrowRight' },
            { title: 'Доступный лимит для выдачи гарантии в рамках условий', value: formatMoney(limitAmount) }
          ]
          return (
            <Fragment key={index}>
              {index > 0 &&
                <div className={classes.gap} />
              }
              {index === editedIndex
                ? <Form
                    id={id}
                    index={index}
                    calculatedTotalAmountState={calculatedTotalAmountState}
                    totalAmountState={totalAmountState}
                    totalAmountStateAux={totalAmountStateAux}
                    startMaxOrderAmount={startMaxOrderAmount}
                    maxOrderAmount={maxOrderAmount}
                    prepaidPercent={prepaidPercent}
                    limitAmount={limitAmount}
                    onEdit={onEdit}
                    onEditAux={onEditAux}
                    onClose={onCloseEditMode}
                  />
                : <div className={classnames(classes.root, { overLimitAmount })} key={index}>
                    {items.map(({ title, value, icon }, idx) => 
                      <Item key={idx}>
                        {icon
                          ? <Icon iconName={icon} />
                          : <>
                              <SubTitle>{title}</SubTitle>
                              <Value>{value}</Value>
                            </>
                        }
                      </Item>
                    )}
                    {!overLimitAmount && !readOnly &&
                      <div className={classes.actions}>
                        <div
                          className={classes.action}
                          title='Редактировать'
                          onClick={() => setEditedIndex(index)}
                        >
                          <Icon iconName='edit2' />
                        </div>
                        <div
                          className={classes.action}
                          title='Удалить'
                          onClick={() => limitAmount > totalAmountState
                            ? alert('Удаление невозможно: cуммы лимитов и ограничений не соответствуют!')
                            : setDeletedIndex(index)
                          }
                        >
                          <Icon iconName='cancel' />
                        </div>
                      </div>
                    }
                  </div>
              }
            </Fragment>
          )
        })
      }
      {(deletedIndex || deletedIndex === 0)  &&
        <ModalConfirm
          title='Вы уверены, что хотите удалить это условие?'
          submitTitle='Удалить'
          onCancel={onCloseDeleteMode}
          onSubmit={_onRemove}
        />
      }
    </>
  )
}