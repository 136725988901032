import { guid } from '../../services/utility/guid'

export const modifyFiles = files => {
  let fileToUpload = {}
  const uploadDate = new Date()
  for (let i = 0; i < files.length; i++) {
    const id = guid()

    fileToUpload = {
      ...fileToUpload,
      [id]: {
        id,
        file: files[i],
        status: UPLOAD_STATUSES.IN_PROGRESS,
        uploadDate
      }
    }
  }

  return fileToUpload
}

export const UPLOAD_STATUSES = {
  SUCCESS: 2,
  IN_PROGRESS: 1,
  ERROR: 0
}
