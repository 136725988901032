import * as actionTypes from './actionTypes'
import { Payments } from '../../services/api'
import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'

export const getRegistersPayments = (regId, filters) => async dispatch => {
  try {
    dispatch({ type: actionTypes.GET_REGISTER_PAYMENTS })
    const {
      isSuccess,
      payments,
      message,
      needLogout
    } = await Payments.getRegisterPayments(regId, filters)

    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_REGISTER_PAYMENTS_SUCCESS,
      data: payments
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_REGISTER_PAYMENTS_ERROR })
  }
}
