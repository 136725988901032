import React, { useEffect, useState, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { Modal2, InputText, Select2, Fieldset, RowGrid, Col4, Col6, Col12 } from '../../../../layout'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'
import { onlyDigitsWithDot } from '../../../../utils'

const useStyles = createUseStyles({
  nonDeliverableTransitModalInner: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
})

const CONFIRMATION_DOCUMENT_INITIAL_STATE = {
  type: '',
  number: '',
  date: '',
}

export const NonDeliverableTransitModalInner = ({
  supplyContracts,
  debtors,
  supplierInn,
  factoringContractDate,
  factoringContractNumber,
  financing,
  index,
  onAddFinancing,
  onEditFinancing,
  onClose,
}) => {
  const classes = useStyles()

  const { documents } = useSelector(state => state?.Factoring)

  const confirmationDocumentTypeOptions = documents.reduce((result, { id, name }) => {
    result.push({ name, value: id })
    return result
  }, [{ name: '', value: '' }])

  const isEdit = index >=0

  const initialValues = {
    debtorInn: isEdit ? financing.debtorInn : '',
    supplyContractId: isEdit ? supplyContracts?.find(item => item?.number === financing.supplyContractNumber)?.id : '',
    supplyContractDate: isEdit ? financing.supplyContractDate : '',
    supplyContractNumber: isEdit ? financing.supplyContractNumber : '',
    confirmationDocument: isEdit
      ? financing.confirmationDocument
      : [
          { ...CONFIRMATION_DOCUMENT_INITIAL_STATE },
          { ...CONFIRMATION_DOCUMENT_INITIAL_STATE },
        ],
    acceptanceDate: isEdit ? financing.acceptanceDate : '',
    supplySum: isEdit ? financing.supplySum : '',
    matchingAmount: isEdit ? financing.matchingAmount : '',
    number: isEdit ? financing.number : '',
    date: isEdit ? financing.date : '',
    financingDate: isEdit ? financing.financingDate : '',
  }
  
  const [debtorInn, setDebtorInn] = useState(initialValues.debtorInn)
  const [supplyContractId, setSupplyContractId] = useState(initialValues.supplyContractId)
  const [supplyContractDate, setSupplyContractDate] = useState(initialValues.supplyContractDate)
  const [supplyContractNumber, setSupplyContractNumber] = useState(initialValues.supplyContractNumber)
  const [confirmationDocument, setConfirmationDocument] = useState(initialValues.confirmationDocument)
  const [acceptanceDate, setAcceptanceDate] = useState(initialValues.acceptanceDate)
  const [supplySum, setSupplySum] = useState(initialValues.supplySum)
  const [matchingAmount, setMatchingAmount] = useState(initialValues.matchingAmount)
  const [submitted, setSubmitted] = useState(0)

  const touched = useMemo(() => submitted > 0, [submitted])

  const supplyContractsByDebtor = useMemo(() => {
    return supplyContracts.filter(item => item?.inn === debtorInn) || []
  }, [supplyContracts, debtorInn])

  useEffect(() => {
    if (supplyContractsByDebtor?.length === 1) {
      const { id, date, number } = supplyContractsByDebtor[0]
      setSupplyContractId(id)
      setSupplyContractDate(date)
      setSupplyContractNumber(number)
    }
  }, [debtorInn, supplyContractsByDebtor])

  const onChangeSupplyContract = (e) => {
    const id = e.target.value
    const { date, number } = supplyContracts?.find(item => item?.id === id) || {}
    setSupplyContractId(id)
    setSupplyContractDate(date)
    setSupplyContractNumber(number)
  }

  const onChangeConfirmationDocument = (value, name, index) => {
    const updateConfirmationDocument = confirmationDocument.map((item, idx) => {
      if (index === idx) {
        item[name] = value
      }
      return item
    })
    setConfirmationDocument(updateConfirmationDocument)
  }

  const payload = {
    currency: 'RUR',
    hasOriginals: true,
    debtorInn,
    supplyContractNumber,
    supplyContractDate,
    confirmationDocument,
    acceptanceDate,
    factoringContractDate,
    factoringContractNumber,
    supplierInn,
    supplySum,
    matchingAmount,
  }

  const errors = {
    ...(debtorInn?.trim() ? {} : { debtorInn: 'Не указан дебитор' }),
    ...(supplyContractNumber?.trim() ? {} : { supplyContractNumber: 'Не указан номер договора поставки' }),
    ...(supplyContractDate?.trim() ? {} : { supplyContractDate: 'Не указана дата договора поставки' }),
    ...(confirmationDocument[0].type?.trim() ? {} : { confirmationDocumentType: 'Не указан тип основного документа' }),
    ...(confirmationDocument[0].number?.trim() ? {} : { confirmationDocumentNumber: 'Не указан номер основного документа' }),
    ...(confirmationDocument[0].date?.trim() ? {} : { confirmationDocumentDate: 'Не указана дата основного документа' }),
    ...(supplySum?.trim() ? {} : { supplySum: 'Не указана сумма поставки' }),
    ...(matchingAmount?.trim() ? {} : { matchingAmount: 'Не указана сумма зачисления' }),
  }

  const isValid = Object.keys(errors)?.length === 0

  useEffect(() => {
    if (isValid && touched) {
      if (isEdit) {
        onEditFinancing(payload, index)
      } else {
        onAddFinancing(payload)
      }
      onClose()
    }
  // eslint-disable-next-line
  }, [submitted])

  console.log('payload [2-nd form]:', payload)
  console.log('errors [2-nd form]', errors)

  return (
    <Modal2 className={classes.nonDeliverableTransitModalInner} onClose={onClose}>
      <div className={classes.wrapper}>
        <H3>Реестр поставок</H3>
        <Form>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Select2
                  label='Дебитор'
                  name='debtorInn'
                  options={debtors}
                  value={debtorInn}
                  onChange={e => setDebtorInn(e.target.value)}
                  error={touched && errors.debtorInn}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <RowGrid>
                  <Col12>
                    <Select2
                      label='Номер договора поставки'
                      name='factoringContract'
                      options={[
                        { name: '', value: '' },
                        ...supplyContractsByDebtor,
                      ]}
                      value={supplyContractId}
                      onChange={onChangeSupplyContract}
                      error={touched && (errors.supplyContractNumber || errors.supplyContractDate)}
                    />
                  </Col12>
                </RowGrid>
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col4>
                <Select2
                  label='Тип документа'
                  name='type'
                  options={confirmationDocumentTypeOptions}
                  value={confirmationDocument[0].type}
                  onChange={e => onChangeConfirmationDocument(e.target.value, e.target.name, 0)}
                  error={touched && errors.confirmationDocumentType}
                />
              </Col4>
              <Col4>
                <InputText
                  type='text' 
                  name='number'
                  label='Номер документа'
                  value={confirmationDocument[0].number}
                  onChange={e => onChangeConfirmationDocument(e.target.value, e.target.name, 0)}
                  error={touched && errors?.confirmationDocumentNumber}
                />
              </Col4>
              <Col4>
                <InputText
                  type='date' 
                  name='date'
                  label='Дата документа'
                  value={confirmationDocument[0].date}
                  onChange={e => onChangeConfirmationDocument(e.target.value, 'date', 0)}
                  error={touched && errors?.confirmationDocumentDate}
                />
              </Col4>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='date' 
                  name='acceptanceDate'
                  label='Дата приемки'
                  value={acceptanceDate}
                  onChange={e => setAcceptanceDate(e.target.value)}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col6>
                <InputText
                  type='text' 
                  name='supplySum'
                  label='Сумма поставки'
                  value={supplySum}
                  onChange={e => setSupplySum(onlyDigitsWithDot(e.target.value))}
                  error={touched && errors?.supplySum}
                />
              </Col6>
              <Col6>
                <InputText
                  type='text' 
                  name='matchingAmount'
                  label='Сумма зачисления'
                  value={matchingAmount}
                  onChange={e => setMatchingAmount(onlyDigitsWithDot(e.target.value))}
                  error={touched && errors?.matchingAmount}
                />
              </Col6>
            </RowGrid>
          </Fieldset>
        </Form>
        <ButtonWrapper>
          <Button disabled={touched && !isValid} onClick={() => setSubmitted(submitted + 1)}>
            {isEdit
              ? 'Отредактировать поставку'
              : 'Сохранить поставку'
            }
          </Button>
        </ButtonWrapper>
      </div>
    </Modal2>
  )
}