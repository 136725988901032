import * as uploadFileTypes from './actionTypes'
import { modifyFiles, UPLOAD_STATUSES } from './utils'

const INITIAL_STATE = {
  isOpened: false,
  fileProgress: {
    // format will be like below
    // 1: {  --> this interpreted as uploaded file #1
    //   id: guid,
    //   file,
    //   status: UPLOAD_STATUSES,
    // },
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case uploadFileTypes.SET_UPLOAD_FILE:
      return {
        ...state,
        isOpened: true,
        fileProgress: {
          ...state.fileProgress,
          ...modifyFiles(action.data)
        }
      }

    case uploadFileTypes.SUCCESS_UPLOAD_FILE:
      if (!state.isOpened) return state

      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.data]: {
            ...state.fileProgress[action.data],
            status: UPLOAD_STATUSES.SUCCESS
          }
        }
      }

    case uploadFileTypes.FAILURE_UPLOAD_FILE:
      if (!state.isOpened) return state

      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.data]: {
            ...state.fileProgress[action.data],
            status: 0,
            progress: UPLOAD_STATUSES.ERROR
          }
        }
      }

    case uploadFileTypes.RESET_FILES_LIST:
      return INITIAL_STATE

    default:
      return state
  }
}
