import axios from 'axios'
import { API_URL } from '../../config'

export const getChartData = async (period) => {
  //await new Promise(resolve => setTimeout(resolve, 500))
  
  try {
    const [created, approved, issued, issueDistribution, monthly] = await Promise.all([
      axios({
        method: 'GET',
        url: `${API_URL}/report/order/daily?process_definition_key=bg-pa&status=created&period=${period}`,
      }),
      axios({
        method: 'GET',
        url: `${API_URL}/report/order/daily?process_definition_key=bg-pa&status=approved&period=${period}`,
      }),
      axios({
        method: 'GET',
        url: `${API_URL}/report/order/daily?process_definition_key=bg-pa&status=issued&period=${period}`,
      }),
      axios({
        method: 'GET',
        url: `${API_URL}/report/order/issueDistribution?process_definition_key=bg-pa&period=${period}`,
      }),
      axios({
        method: 'GET',
        url: `${API_URL}/report/order/monthly?process_definition_key=bg-pa`,
      }),
    ])

    const charts = {
      created: { ...created.data },
      approved: { ...approved.data },
      issued: { ...issued.data },
      issueDistribution: { ...issueDistribution.data },
      monthly: { ...monthly.data },
    }

    return {
      isSuccess: true,
      ...charts,
    }

    // if (status === 0) {
    //   return {
    //     isSuccess: true,
    //     ...rest
    //   }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: status === 2,
    //   message: rest.error
    // }
  } catch (err) {
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}