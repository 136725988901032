import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCompanyLiabilitiesInfo } from '../../../../redux/Company/actions'
import Overlay from '../../../Overlay'

export class Liabilities extends Component {
  async loadData() {
    const { dispatch, companyId } = this.props
    dispatch(getCompanyLiabilitiesInfo(companyId))
  }

  componentDidMount() {
    if (!this.props.fetching) {
      this.loadData()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.companyId !== prevProps.companyId) {
      this.loadData()
    }
  }

  render() {
    const { liabilities: data = [], fetching } = this.props

    return !fetching
      ? <div className='block-info'>
          <table className='no-margin' style={{tableLayout: 'fixed'}}>
            <thead>
              <tr>
                <td style={{width: '300px'}}>Займодатель</td>
                <td className='text-left' style={{width: '150px'}}>Сумма</td>
              </tr>
            </thead>
            <tbody>
              {(data || []).map((row, index) =>
                <tr key={'row-' + index}>
                  <td>{row.lenderName}</td>
                  <td className='text-left'>{row.totalAmount}</td>
                </tr>
              )}
            </tbody>
          </table> 
        </div> 
      : <Overlay/>
  }
}

const mapStateToProps = ({ Company }) => {
  return {
    ...Company.liabilitiesInfo
  }
}

export default connect(mapStateToProps)(Liabilities)