import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Collapse } from 'reactstrap'
import injectSheet from 'react-jss'
import { getBaseSchemaList } from '../../../../../redux/Tariffs/actions'
import { removeCompanyBillingContract } from '../../../../../redux/Company/actions'
import { ConfirmModal } from './../../../../ConfirmModal'
import { Icon, UI } from '../../../../../layout'
import Schema from './Schema'
import { formattedDate } from '../../../../../utils'

const styles = {
  root: {
    background: '#fff',
    padding: [15, 20],
    border: [1, 'solid', '#ddd'],
    borderRadius: 10,
    position: 'relative',
    transition: 'background 300ms ease',
    '&.open': {
      background: 'rgba(238, 238, 238, 0.7)',
    },
    '& + &': {
      marginTop: 20,
    },
  },
  header: {
    position: 'relative',
  },
  title: {
    fontSize: 16,
    lineHeight: 1.25,
    color: '#000',
    fontWeight: 500,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
  },
  actions: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  edit: {
    width: 18,
    height: 20,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      fill: '#4A4A4A',
      display: 'block',
    },
  },
  empty: {
    marginTop: 20,
    color: '#504E5B',
    fontSize: 15,
    lineHeight: 1.47,
  },
  add: {
    ...UI.addButton,
    width: '100%',
    marginTop: 20,
  },
  remove: {
    ...UI.removeButton,
    marginLeft: 20,
    transform: 'translateY(-9px)',
  },
  balanceAccount: {
    marginTop: 10,
    padding: [0, 20],
    fontWeight: 600,
  },
}

const Contract = ({
  classes,
  companyId,
  contract,
  onEditContract,
  onEditSchema,
  removeCompanyBillingContract,
}) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const {
    number,
    date,
    // bankSigner,
    // agentSigner,
    billingSchemas,
    agentBalance,
  } = contract

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const { REACT_APP_NEW_BILLING } = useSelector(state => state?.Environment?.environment)

  const IS_NEW_BILLING = REACT_APP_NEW_BILLING === 'true'

  const onOpenConfirmModal = () => setShowConfirmModal(true)
  const onCloseConfirmModal = () => setShowConfirmModal(false)

  const onRemoveContract = () => removeCompanyBillingContract({ companyId, number })

  const onAddSchema = (number) => {
    if (IS_NEW_BILLING) {
      dispatch(getBaseSchemaList())
    }
    onEditSchema({ schema: {}, number })
  }

  const hasSchemas = billingSchemas && billingSchemas.length

  return (
    <>
      <div className={classnames(classes.root, { open })}>
        <div className={classes.header}>
          <div className={classes.title} onClick={() => setOpen(!open)}>
            Договор №{number} от {formattedDate(date)}
          </div>
          <div className={classes.actions}>
            <div className={classes.edit} onClick={() => onEditContract(number)}>
              <Icon iconName='edit' />
            </div>
            <div className={classes.remove} onClick={onOpenConfirmModal}>Удалить</div>
          </div>
        </div>

        {/* <div className='billing-contract__info'>
          <p>Подписант со стороны Банка: <strong>{bankSigner ? bankSigner.displayName : ''}</strong></p>
          <p>Подписант со стороны Агента: <strong>{agentSigner ? agentSigner.displayName : ''}</strong></p>
        </div> */}

        <Collapse isOpen={open}>
          {hasSchemas
            ? billingSchemas.map(schema => 
                <Schema 
                  key={schema.schemaId}
                  companyId={companyId}
                  number={number} 
                  schema={schema} 
                  onEditSchema={() => onEditSchema({ schema, number })}
                />
              )
            : <div className={classes.empty}>Не указано ни одной схемы расчёта агентских вознаграждений</div>
          }
          <div className={classnames(classes.add, 'btn-form--ok')} onClick={() => onAddSchema(number)}>
            Добавить схему расчета агентских вознаграждений
          </div>
        </Collapse>

        {showConfirmModal &&
          <ConfirmModal
            title={`Вы действительно хотите удалить договор №${number} от ${formattedDate(date)}?`}
            submitTitle='Удалить'
            onCancel={onCloseConfirmModal}
            onSubmit={onRemoveContract}
          />
        }

      </div>
      <div className={classes.balanceAccount}>Балансовый счет агента: {agentBalance}</div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const dispatchMapToProps = (dispatch, ownProps) => {
	return {
		removeCompanyBillingContract: (contract) => {
			dispatch(removeCompanyBillingContract(contract))
    },
	}
}

Contract.propTypes = {
  contract: PropTypes.object.isRequired,
  onEditContract: PropTypes.func.isRequired,
  onEditSchema: PropTypes.func.isRequired,
  removeCompanyBillingContract: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, dispatchMapToProps)(injectSheet(styles)(Contract))