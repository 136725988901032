import React from 'react'
import { createUseStyles } from 'react-jss'
import { Modal2 } from '../../../../layout'
import { Button, H3 } from '../../Layout'

const useStyles = createUseStyles({
  redirectToWoSupplyTransitModal: {
    width: 520,
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
  title: {
    lineHeight: 1.2,
  },
  btn: {
    width: '100%',
    marginTop: 20,
  },
})

export const RedirectToWoSupplyTransitModal = ({
  onClose,
  onResetOrder,
  onResetAutoDistributePayment,
  onOpenNonDeliverableTransitModal,
}) => {
  const classes = useStyles()
  return (
    <Modal2
      className={classes.redirectToWoSupplyTransitModal}
      onClose={() => {
        onClose()
        onResetOrder()
        onResetAutoDistributePayment()
      }}
    >
      <div className={classes.wrapper}>
        <H3 className={classes.title}>
          Зачисление на поставку без финансирования невозможно, только беспоставочный транзит!
        </H3>
        <Button
          className={classes.btn}
          onClick={() => {
            onClose()
            onOpenNonDeliverableTransitModal()
          }}
        >
          Беспоставочный транзит
        </Button>
      </div>
    </Modal2>
  )
}