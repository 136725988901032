export const LIMIT_TYPES = {
  HOLDING: 'HOLDING',
  COMPANY: 'COMPANY',
  DOCUMENT: 'DOCUMENT',
  PROVISIONS: 'PROVISIONS',
  LEGAL_ENTITY: 'LEGAL_ENTITY',
  SUPPLIER_DEBTOR: 'SUPPLIER_DEBTOR',
  FACTORING_CONTRACT: 'FACTORING_CONTRACT',
  SUPPLIER_MANY_DEBTOR: 'SUPPLIER_MANY_DEBTOR',
  DOCUMENT_LEGAL_ENTITY: 'DOCUMENT_LEGAL_ENTITY',
}

export const ROLES = {
  SUPPLIER: 'SUPPLIER',
  DEBTOR: 'DEBTOR',
}

export const PRODUCTS = {
  REGRESS: 'REGRESS',
  WO_REGRESS: 'WO_REGRESS',
}

export const ORIGINALS = {
  ORIGINALS: 'ORIGINALS',
  COPIES: 'COPIES',
}

export const INDICATIVE_OPTIONS = [
  {
    value: true,
    label: 'Дебитор',
  },
  {
    value: false,
    label: 'Поставщик',
  },
]

export const ROLE_OPTIONS = [
  {
    value: ROLES.SUPPLIER,
    label: 'Поставщик',
  },
  {
    value: ROLES.DEBTOR,
    label: 'Дебитор',
  },
]

export const PRODUCTS_OPTIONS = [
  {
    value: PRODUCTS.REGRESS,
    label: 'Регресс',
  },
  {
    value: PRODUCTS.WO_REGRESS,
    label: 'Безрегресс',
  },
]

export const ORIGINALS_OPTIONS = [
  {
    value: ORIGINALS.ORIGINALS,
    label: 'Оригиналы документов',
  },
  {
    value: ORIGINALS.COPIES,
    label: 'Копии документов',
  },
]

export const DEFAULT_DEBTOR = {
  inn: '',
  ogrn: '',
}

export const DEFAULT_SUPPLIER = {
  inn: '',
  ogrn: '',
}

export const DEFAULT_CONTRACT_PB_ID = {
  id: '',
  number: '',
}