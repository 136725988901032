import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { getCompanyHeadInfo } from '../../../../redux/Company/actions'
// import * as utils from '../utils'
import { Attributes } from '../Helpers/Attributes'
import Overlay from '../../../Overlay'
import { User } from '../Helpers/User'
import { Passport } from '../Helpers/Passport'
import { Attribute } from '../Helpers/Attributes'

export class CompanyHead extends Component {
  async loadData() {
    const { dispatch, companyId } = this.props
    dispatch(getCompanyHeadInfo(companyId))
  }

  componentDidMount() {
    !this.props.fetching && this.loadData()
  }

  componentDidUpdate(prev) {
    const { companyId: oldCompanyId } = prev
    const { companyId } = this.props
    if (oldCompanyId !== companyId){
      this.loadData()
    }
  }

  render() {
    const { head, fetching } = this.props

    const position = _.get(head, 'employee.position')
    const authorizingDoc = _.get(head, 'employee.authorizingDoc')
    const passportData = _.get(head, 'identityDocuments[0]')

    const personAttributes = (head && head.personAttributes) || {}
    const relationAttributes = (head && head.relationAttributes) || {}

    if (fetching) {
      return <Overlay/>
    }

    return ( 
      <div className='block-info'>
        <div className='block_head'>Сведения о руководителе</div>
        <User user={head}/>
        <div className='row'>
          <Attribute col='12' attr={{label: 'Должность', value: position}}/>
          <Attribute col='12' attr={{label: 'Наименование документа о назначении на должность', value: authorizingDoc}}/>
        </div>
        <hr/>
        <div className='block_head'>Паспортные данные</div>
        <Passport passport={passportData}/>
        <hr/>
        <Attributes {...personAttributes}/>
        <hr/>
        <Attributes {...relationAttributes}/>
        <br/>
      </div>
    )
  }
}

const mapStateToProps = ({ Company }) => {
  return {
    ...Company.headInfo
  }
}

export default connect(mapStateToProps)(CompanyHead)