import { toArray, size, debounce } from 'lodash'
import React from 'react'
import { FileCard } from './FileCard'
import { modalWrapperService } from '../../../../../services/modal-wrapper.service'
import './FileList.style.scss'
import { UPLOAD_STATUSES } from '../../../../../redux/RegisterUploads/utils'

const debouncedCloseModal = debounce((fileProgress, callback) => {
  const fileList = toArray(fileProgress)

  if (
    fileList.length &&
    fileList.every(file => file.status === UPLOAD_STATUSES.SUCCESS)
  ) {
    callback()
    modalWrapperService.closeModal()
  }
}, 1000)

export function FilesList({ fileProgress, uploadFile, onAllFilesUploaded }) {
  const uploadedFileAmount = size(fileProgress)

  React.useEffect(() => {
    const fileToUpload = toArray(fileProgress).filter(
      file => file.status === UPLOAD_STATUSES.IN_PROGRESS
    )
    uploadFile(fileToUpload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFileAmount])

  React.useEffect(() => {
    debouncedCloseModal(fileProgress, onAllFilesUploaded)
  }, [fileProgress, onAllFilesUploaded])

  return (
    <div className="file-list">
      {toArray(fileProgress).map(file => (
        <FileCard file={file} key={file.id} />
      ))}
    </div>
  )
}
