import axios from 'axios'
import { API_URL } from '../../../config'

export const getReportAnalysis = async ({ process, dateFrom, dateTo, taskStart, taskEnd }) => {
  try {
    const processString = `orderTypeRefId=${process}`
    const datesString = `&dateFrom=${dateFrom}&dateTo=${dateTo}`
    const tasksString = `&task_from=${taskStart}&task_to=${taskEnd}`
    const paramsString = `${processString}${datesString}${tasksString}`
    const { data: { error_code, result, ...rest } } = await axios({
      method: 'GET',
      url: `${API_URL}/report/allStages?${paramsString}`,
    })
    if (error_code === 0) {
      return {
        isSuccess: true,
        result: result,
      }
    }
    return {
      isSuccess: false,
      needLogout: error_code === 5,
      message: rest.error,
    }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getReportAnalysisTasks = async ({ process }) => {
  try {
    const { data: { error_code, ...rest } } = await axios({
      method: 'GET',
      url: `${API_URL}/report/getTasks?orderTypeRefId=${process}`,
    })
    if (error_code === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: error_code === 5,
      message: rest.error,
    }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}