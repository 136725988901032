import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import ContactPerson from '../CompanyInfo/ContactPerson'
import { Copy } from '../../Copy/Copy'

const styles = {
  root: {
    paddingBottom: 25,
  },
}

const CustomDetailHeader = ({
  classes,
  // section,
  title = '',
  // name = '',
  inn = '—',
  kpp = '—',
  ogrn = '—',
  withoutHr,
  companyId,
}) =>
  <div className={classnames('task-header', {[classes.root]: withoutHr, 'task-header-hr': !withoutHr})} id='task-header'>
    <div className='task-header__title'>
      <Copy successText={"Название скопировано."} valueToCopy={title}>
        {title}
      </Copy>
    </div>
    <div className='task-header__company'>
      <Copy successText={'ИНН скопирован'} valueToCopy={inn} customStyles={{ top: "20px"}}>ИНН <p>{inn}</p></Copy>
      <Copy successText={'КПП скопирован'} valueToCopy={kpp} customStyles={{ top: "20px"}}>КПП <p>{kpp}</p></Copy>
      <Copy successText={'ОГРН скопирован'} valueToCopy={ogrn} customStyles={{ top: "20px"}}>ОГРН <p>{ogrn}</p></Copy>
    </div>
    <ContactPerson companyId={companyId} />
  </div>

CustomDetailHeader.propTypes = {
  section: PropTypes.string.isRequired,
  title: PropTypes.string,
  name: PropTypes.string,
  inn: PropTypes.string,
  kpp: PropTypes.string,
  ogrn: PropTypes.string,
  withoutHr: PropTypes.bool,
}

export default injectSheet(styles)(CustomDetailHeader)