import React, { useState, useEffect } from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import ButtonMassActions from '../ButtonMassActions'
import Dropdown from '../Dropdown'
import { getBankSigners, assignPrintGuarantee } from '../../redux/Task/actions'
import { getSelectedTasks } from '../../redux/Tasks/selectors'
import Overlay from '../Overlay'
import { sortBy } from '../../utils'

const styles = {
  overlay: {
    width: '500px',
    height: 'calc(100vh - 12px)',
    left: '-250px',
  },
}

const INITIAL_USERS = [{ id: '', name: 'Выберите из списка' }]
const EMPTY_LIST_USERS = [{ id: '', name: 'Список кандидатов пуст' }]

const usersMap = (item) => { 
  return { 
    id: item.id, 
    name: item.displayName, 
  }
}

const PrintGuaranteeAssign = ({
  classes,
  isFetchingPrintGuaranteeAsign,
  selectedTasks,
  bankSigners,
  defaultUser,
  getBankSigners,
  assignPrintGuarantee,
  onSuccess,
}) => {
  const [users, setUsers] = useState(INITIAL_USERS)
  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(getBankSigners, [getBankSigners])

  useEffect(() => {
    const hasBankSigners = bankSigners && bankSigners.length
    const sortedUsers = hasBankSigners 
      ? bankSigners.map(usersMap).sort((a, b) => sortBy(a, b, 'name', 'asc'))
      : []

    const newUsers = hasBankSigners
      ? defaultUser && defaultUser.id && defaultUser.name
        ? [...INITIAL_USERS, defaultUser, ...sortedUsers]
        : [...INITIAL_USERS, ...sortedUsers]
      : [...EMPTY_LIST_USERS]
    setUsers(newUsers)
  }, [bankSigners, defaultUser])

  const onDropdownSelectItem = (dropdownName, itemId, itemIndex) => {
    if (!!users[itemIndex].id) {
      setSelectedUser(users[itemIndex])
    } else {
      setSelectedUser(null)
    }
  }

  const onSubmit = async () => {
    const ids = selectedTasks
      .filter(item => item && item.bulkActions && item.bulkActions.includes('printGuaranteeAssign'))
      .map(item => item.id)

    assignPrintGuarantee(ids, selectedUser.id, onSuccess)
  }

  if (isFetchingPrintGuaranteeAsign) {
    return <Overlay size='big' transparent className={classes.overlay} />
  }

  return (
    <form>
      <div className='restore-pass__title'>Сменить подписанта по гарантии на:</div>
      <div className='form-row'>
        <div className='col'>
          <div className='form-group'>
            <Dropdown
              list={users}
              onSelectItem={onDropdownSelectItem}
              placeholder='Выберите сотрудника'
              color
            />
          </div>
        </div>
      </div>
      <div className='form-row'>
        <div className='col'>
          <div className='form-group form-group__buttons-container'>
            <ButtonMassActions
              onClick={onSubmit}
              text='Сменить'
              disabled={!selectedUser}
            />
          </div>
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = ({ Bulk, Tasks, Task, Settings }) => {
  const data = {
    _byIds: Tasks._byIds, 
    selectedTaskIds: Bulk.selectedItems,
  }

  return {
    selectedTasks: getSelectedTasks(data),
    bankSigners: Task.bankSigners && Task.bankSigners.bankSigners,
    defaultUser: {
      id: Settings.user && Settings.user.username, 
      name: Settings.user && Settings.user.fullname, 
    },
    isFetchingPrintGuaranteeAsign: Task.isFetchingPrintGuaranteeAsign,
  }
}

const mapDispatchToProps = (dispatch) => {
 return {
    getBankSigners: () => {
      dispatch(getBankSigners())
    },
    assignPrintGuarantee: (ids, username, callback) => {
      dispatch(assignPrintGuarantee(ids, username, callback))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(PrintGuaranteeAssign))