import { toast } from 'react-toastify'

const TOAST_OPTIONS = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  containerId: 'ToastContainerId',
}

export const Toast = ({
  message,
  type = 'info',
}) => toast[type](message, TOAST_OPTIONS)