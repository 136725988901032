import React, { useState } from 'react'
import {
  useDispatch,
  // useSelector,
} from 'react-redux'
import { Col } from 'reactstrap'
import Dropdown from '../../../../components/Dropdown'
import { Row, InputText } from '../../../../layout'
import { getFile } from '../../../../redux/Files/actions'
import { ReportTab } from '../../Layout/ReportTab'
import { commonStatusStyles } from '../all3d/styles'
import { useProcessSelectOptions } from '../../useProcessSelectOptions'
import { YYYYMMDD } from '../../../../utils'

export const StatisticsUTReport = commonStatusStyles(({
  classes,
  onCancel,
  settings,
}) => {
  const dispatch = useDispatch()

  const now = new Date()
  const currentDate = YYYYMMDD(now)
  const firstDayOfMonth = YYYYMMDD(new Date(now.getFullYear(), now.getMonth(), 1))

  const { processDefault } = useProcessSelectOptions(settings.process)

  // const { orders: orderFilters } = useSelector(state => state?.Settings?.filters)

  const orderTypeOptions = [
    { id: '', name: 'Все заявки' },
    { id: 'credit', name: 'Заявка на кредит' },
    { id: 'creditLimitCalc', name: 'Расчёт лимита' },
    { id: 'ccLimitIncrease', name: 'Увеличение лимита' }
  ]

  // const orderTypeOptions = [
  //   { id: '', name: 'Все заявки' },
  //   ...(orderFilters?.find(f => f?.name === 'orderTypeRefId')?.list || [])
  // ]

  const [dateFrom, setDateFrom] = useState(firstDayOfMonth)
  const [dateTo, setDateTo] = useState(currentDate)
  const [orderType, setOrderType] = useState('')
  const [loading, setLoading] = useState(false)

  const onChangeDateFrom = (e) => setDateFrom(e.target.value)
  const onChangeDateTo = (e) => setDateTo(e.target.value)
  const onChangeOrderType = (e, id) => setOrderType(id)

  const valid = dateFrom && dateTo

  const onDownloadFile = () => {
    if (valid && !loading) {
      setLoading(true)
      const url = `${settings.url}?dateFrom=${dateFrom}&dateTo=${dateTo}&orderType=${orderType}`
      const name = `bg_statsJournal-${processDefault}-${dateFrom}-${dateTo}-${orderType || 'all'}.xlsx`
      const onSuccess = () => setLoading(false)
      const onError = () => setLoading(false)
      dispatch(getFile({ url, name, onSuccess, onError }))
    }
  }

  return (
    <ReportTab
      onOk={onDownloadFile}
      onCancel={onCancel}
      okButtonText='Получить отчёт'
      disabled={!valid}
      loading={loading}
      title={settings.description}
    >
      <Col className={classes.form}>
        <div className={classes.label}>Тип заявки</div>
        <Dropdown
          name='orderType'
          onSelectItem={onChangeOrderType}
          list={orderTypeOptions}
          className={classes.mb20}
        />
        <Row>
          <div className='col-6'>
            <InputText
              type='date'
              name='dateFrom'
              label='Дата с'
              value={dateFrom}
              maxDate={new Date(dateTo)}
              onChange={onChangeDateFrom}
            />
          </div>
          <div className='col-6'>
            <InputText
              type='date'
              name='dateTo'
              label='Дата по'
              value={dateTo}
              minDate={new Date(dateFrom)}
              onChange={onChangeDateTo}
            />
          </div>
        </Row>
      </Col>
    </ReportTab>
  )
})