import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import HeaderFilter from './../../containers/HeaderFilter'
import { getChartData } from '../../redux/Chart/actions'
import { IS_DEV, AXIS_IDS } from './../../config'

import {
  isFetching,
  
  createdDay,
  createdCount,
  createdCountLot,
  createdAmount,
  createdCountInc,
  createdAmountInc,

  issuedDay,
  issuedCountInc,
  issuedCountScheduledInc,
  issuedCountForecastInc,

  approvedDay,
  approvedCount,
  approvedCountLot,
  approvedCountInc,
  approvedAmount,
  approvedAmountInc,

  issuedAmountInc,
  issuedAmountScheduledInc,
  issuedAmountForecastInc,
  issuedCount,
  issuedCountScheduled,
  issuedCountForecast,
  issuedAmount,
  issuedAmountScheduled,
  issuedAmountForecast,
  issueDistributionSelector,
  getMonthlySelector,
} from '../../redux/Chart/selectors'

import { Burger } from '../../components/Burger'
import ChartItem from '../../components/Chart/ChartItem'
import Dropdown from '../../components/Dropdown'
import {
  TableColumn,
  TableRow,
  // TableResult,
} from '../../components/Chart/Table'

import { getLastMonths } from '../../utils'

import {
  applications,
  forecast,
  endorsements,
  forecast2,
  extradition,
  extradition2
} from './mocks/charts'

const styles = {
  root: {
    maxWidth: 1480,
    width: '100%',
    margin: [0, 'auto'],
    padding: [0, 60],
    fontFamily: 'Montserrat',
    '& .filter-input': {
      marginLeft: 29,
    },
  },
  dropdown: {
    margin: 0,
  },
  title: {
    margin: [15, 0, 23, 0],
    color: '#151b3d',
    lineHeight: 1.2,
    fontSize: 26,
    fontWeight: 600,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}

const COLORS = {
  blue: 'rgba(31, 127, 222, 1)',
  rose: 'rgba(233, 138, 144, 1)',
  purple: 'rgba(145, 135, 239, 1)',
  blue25: 'rgba(31, 127, 222, 0.25)',
  rose25: 'rgba(233, 138, 144, 0.25)',
  purple25: 'rgba(145, 135, 239, 0.25)',
}

const Charts = ({
  classes,

  getChartDataAction,

  isFetching,
  createdDay,
  createdCount,
  createdCountLot,
  createdAmount,
  createdCountInc,
  createdAmountInc,

  issuedDay,
  issuedCountInc,
  issuedCountScheduledInc,
  issuedCountForecastInc, // null array, dont remove it
  approvedDay,
  approvedCount,
  approvedCountLot,
  approvedCountInc,
  approvedAmount,
  approvedAmountInc,

  issuedAmountInc,
  issuedAmountScheduledInc,
  issuedAmountForecastInc,

  issuedCount,
  issuedCountScheduled,
  issuedCountForecast,

  issuedAmount,
  issuedAmountScheduled,
  issuedAmountForecast,

  issueDistribution,
  monthly,
}) => {
  const periods = getLastMonths(12)

  const initialState = {
    date: periods[0].period,
    months: periods,
  }

  const [date] = useState(initialState.date)
  const [months] = useState(initialState.months)

  useEffect(() => {
    getChartDataAction(date)
  }, [date, getChartDataAction])

  const onSelectItem = ({ period }) => getChartDataAction(period)

  const { 
    segments = [], 
    title = {}, 
  } = issueDistribution

  const issueDistributionTitlesColumns = title.periods ? title.periods.map(item => item.title) : []
  
  const issueDistributionHead = ['', ...issueDistributionTitlesColumns]

  const issueDistributionBody = segments.map(({ key, periods }, index) => {
    const valuesArray = periods.map(({ value }) => {
      return { 
        content: (value || value === 0) ? `${value}%` : '-',
        color: !value
          ? 'td-red'
          : value < 10
            ? 'td-lightred'
            : 'td-green',
      }
    })
    const currentTitle = title.segments.find(item => item.key === key)
    const titleItem = {
      content: currentTitle ? currentTitle.title : '',
      color: null,
    }
    const values = [titleItem, ...valuesArray]

    // {
    //   id: 0,
    //   values: [
    //     { content: '< 100 тыс.', color: null },
    //     { content: '9%', color: 'td-green' },
    //     { content: '7%', color: 'td-green' },
    //     { content: '8%', color: 'td-lightred' },
    //     { content: '49%', color: 'td-red' },
    //     { content: '6%', color: 'td-green' },
    //     { content: '5%', color: 'td-green' },
    //     { content: '1%', color: 'td-green' }
    //   ]
    // },

    return {
      values,
      id: index,
    }
  })

  return (
    <div className={classes.root}>
      <Burger />
      <HeaderFilter>
        <Dropdown
          className={classes.dropdown}
          list={months}
          handleSelectItem={onSelectItem}
          isChart
        />
      </HeaderFilter>
      <div className={classes.title}>Ежедневный отчет по продажам Банковских гарантий</div>
      <div className={classes.wrapper}>

        <ChartItem
          heading='Заявки'
          // legend={[
          //   { title: 'Заявки (шт.)', background: COLORS.blue },
          //   { title: 'Лоты (шт.)', background: COLORS.purple },
          //   { title: 'Объем (млн. руб.)', background: COLORS.rose },
          // ]}
          styleChartSetting={{
            lines: [
              { title: 'Заявки (шт.)', color: COLORS.blue, isDotted: false },
              { title: 'Лоты (шт.)', color: COLORS.purple, isDotted: false },
              { title: 'Объем (млн. руб.)', color: COLORS.rose, isDotted: false },
            ],
          }}
          charts={['line', 'line', 'bar']}
          content={applications}
          dataX={createdDay}
          dataY={[createdCount, createdCountLot, createdAmount]}
          yAxisIDs={[AXIS_IDS.y1, AXIS_IDS.y1, AXIS_IDS.y2]}
          isFetching={isFetching}
        />

        <ChartItem
          heading='Заявки (нарастающим итогом)'
          // legend={[
          //   { title: 'Заявки (шт.)', background: COLORS.blue },
          //   { title: 'Объем (млн. руб.)', background: COLORS.rose },
          // ]}
          styleChartSetting={{
            lines: [
              { title: 'Заявки (шт.)', color: COLORS.blue, isDotted: true },
              { title: 'Объем (млн. руб.)', color: COLORS.rose, isDotted: false },
            ]
          }}
          charts={['line', 'bar']}
          content={forecast}
          dataX={createdDay}
          dataY={[createdCountInc, createdAmountInc]}
          yAxisIDs={[AXIS_IDS.y1, AXIS_IDS.y2]}
          isFetching={isFetching}
        />

        <ChartItem
          heading='Одобрения'
          // legend={[
          //   { title: 'Заявки (шт.)', background: COLORS.blue },
          //   { title: 'Объем (млн. руб.)', background: COLORS.rose },
          // ]}
          styleChartSetting={{
            lines: [
              { title: 'Заявки (шт.)', color: COLORS.blue, isDotted: true },
              { title: 'Объем (млн. руб.)', color: COLORS.rose, isDotted: false },
            ]
          }}
          charts={['line', 'bar']}
          content={endorsements}
          dataX={approvedDay}
          dataY={[approvedCount, approvedAmount]}
          yAxisIDs={[AXIS_IDS.y1, AXIS_IDS.y2]}
          isFetching={isFetching}
        />

        <ChartItem
          heading='Одобрения (нарастающим итогом)'
          // legend={[
          //   { title: 'Заявки (шт.)', background: COLORS.blue },
          //   { title: 'Объем (млн. руб.)', background: COLORS.rose },
          // ]}
          styleChartSetting={{
            lines: [
              { title: 'Заявки (шт.)', color: COLORS.blue, isDotted: true },
              { title: 'Объем (млн. руб.)', color: COLORS.rose, isDotted: false },
            ]
          }}
          charts={['line', 'bar']}
          content={forecast2}
          dataX={approvedDay}
          dataY={[approvedCountInc, approvedAmountInc]}
          yAxisIDs={[AXIS_IDS.y1, AXIS_IDS.y2]}
          isFetching={isFetching}
        />

        <ChartItem
          heading='Выдачи'
          // legend={[
          //   { title: 'Заявки (факт, шт.)', background: COLORS.blue },
          //   { title: 'Заявки (план, шт.)', background: COLORS.blue25 },
          //   { title: 'Заявки (прогноз, шт.)', background: COLORS.purple },
          //   { title: 'Объем (прогноз, млн. руб.)', background: COLORS.purple25 },
          //   { title: 'Объем (факт, млн. руб.)', background: COLORS.rose },
          //   { title: 'Объем (план, млн. руб.)', background: COLORS.rose25 },
          // ]}
          styleChartSetting={{
            lines: [
              { title: 'Заявки (факт, шт.)', color: COLORS.blue, isDotted: true },
              { title: 'Заявки (план, шт.)', color: COLORS.blue25, isDotted: true },
              { title: 'Заявки (прогноз, шт.)', color: COLORS.purple, isDotted: false },
              { title: 'Объем (прогноз, млн. руб.)', color: COLORS.purple25, isDotted: false },
              { title: 'Объем (факт, млн. руб.)', color: COLORS.rose, isDotted: true },
              { title: 'Объем (план, млн. руб.)', color: COLORS.rose25, isDotted: true },
            ]
          }}
          charts={['line', 'line', 'line', 'bar', 'bar', 'bar']}
          content={extradition}
          dataX={issuedDay}
          dataY={[
            issuedCount,
            issuedCountScheduled,
            issuedCountForecast,
            issuedAmountForecast,
            issuedAmount,
            issuedAmountScheduled,
          ]}
          yAxisIDs={[AXIS_IDS.y1, AXIS_IDS.y1, AXIS_IDS.y1, AXIS_IDS.y2, AXIS_IDS.y2, AXIS_IDS.y2]}
          isFetching={isFetching}
        />

        <ChartItem
          heading='Выдачи (нарастающим итогом)'
          // legend={[
          //   { title: 'Заявки (факт, шт.)', background: COLORS.blue },
          //   { title: 'Заявки (план, шт.)', background: COLORS.blue25 },
          //   { title: 'Заявки (прогноз, шт.)', background: COLORS.purple },
          //   { title: 'Объем (прогноз, млн. руб.)', background: COLORS.purple25 },
          //   { title: 'Объем (факт, млн. руб.)', background: COLORS.rose },
          //   { title: 'Объем (план, млн. руб.)', background: COLORS.rose25 },
          // ]}
          styleChartSetting={{
            lines: [
              { title: 'Заявки (факт, шт.)', color: COLORS.blue, isDotted: true },
              { title: 'Заявки (план, шт.)', color: COLORS.blue25, isDotted: true },
              { title: 'Заявки (прогноз, шт.)', color: COLORS.purple, isDotted: false },
              { title: 'Объем (прогноз, млн. руб.)', color: COLORS.purple25, isDotted: false },
              { title: 'Объем (факт, млн. руб.)', color: COLORS.rose, isDotted: true },
              { title: 'Объем (план, млн. руб.)', color: COLORS.rose25, isDotted: true },
            ]
          }}
          charts={['line', 'line', 'line', 'bar', 'bar', 'bar']}
          content={extradition2}
          dataX={issuedDay}
          dataY={[
            issuedCountInc,
            issuedCountScheduledInc,
            issuedCountForecastInc,
            issuedAmountForecastInc,
            issuedAmountInc,
            issuedAmountScheduledInc,
          ]}
          yAxisIDs={[AXIS_IDS.y1, AXIS_IDS.y1, AXIS_IDS.y1, AXIS_IDS.y2, AXIS_IDS.y2, AXIS_IDS.y2]}
          isFetching={isFetching}
        />

      </div>
      {IS_DEV &&
        <div className='chart-wrap'>
          <TableColumn
            heading='Статистика одобрений заявок и выпуска гарантий по сегментам с начала месяца'
            size='s'
            head={[
              'сегмент гарантий',
              'одобр. план',
              'одобр. факт',
              'принято факт',
              'оплачено факт',
              'объем выдач, млн руб',
              'доля в выдаче план',
              'доля в выдаче факт',
            ]}
            body={[
              {
                id: 0,
                values: [
                  { content: '< 100 тыс.', color: null },
                  { content: '9%', color: 'td-green' },
                  { content: '7%', color: 'td-green' },
                  { content: '8%', color: 'td-lightred' },
                  { content: '49%', color: 'td-red' },
                  { content: '6', color: 'td-green' },
                  { content: '5%', color: null },
                  { content: '1%', color: null },
                ],
              },
              {
                id: 1,
                values: [
                  { content: '< 100 тыс.', color: null },
                  { content: '9%', color: 'td-green' },
                  { content: '7%', color: 'td-green' },
                  { content: '8%', color: 'td-lightred' },
                  { content: '49%', color: 'td-red' },
                  { content: '6', color: 'td-green' },
                  { content: '5%', color: null },
                  { content: '1%', color: null },
                ],
              },
              {
                id: 2,
                values: [
                  { content: '< 100 тыс.', color: null },
                  { content: '9%', color: 'td-green' },
                  { content: '7%', color: 'td-green' },
                  { content: '8%', color: 'td-lightred' },
                  { content: '49%', color: 'td-red' },
                  { content: '6', color: 'td-green' },
                  { content: '5%', color: null },
                  { content: '1%', color: null },
                ],
              }
            ]}
          />
          {/* <TableResult
            heading='Итого по разделу'
            values={['91 млн руб', '100%', '100%']}
          /> */}
        </div>
      }
      <div className='chart-wrap'>
        <TableRow
          heading='Статистика выпуска банковских гарантий помесячно'
          head={monthly.periods}
          body={[
            {
              heading: 'Отправлено в банк',
              color: 'tr-blue',
              values: monthly.created,
            },
            {
              heading: 'Отказано банком',
              color: 'tr-lightred',
              values: monthly.lost,
            },
            {
              heading: 'Выдано банком',
              color: 'tr-lightgreen',
              values: monthly.issued,
            },
          ]}
        />
        <TableRow
          heading='Норма выпуска в %'
          body={[
            {
              heading: 'Уровень отказов',
              color: 'tr-lightred',
              values: monthly.lostRate,
            },
            {
              heading: 'Уровень выдач',
              color: 'tr-lightgreen',
              values: monthly.issuedRate,
            },
          ]}
        />
        <TableRow
          heading='Объемы, млн. руб.'
          body={[
            {
              heading: 'Выдано БГ',
              values: monthly.amount,
            },
            {
              heading: 'Комиссионное вознаграждение',
              values: monthly.commissionAmount,
            },
            {
              heading: 'Средний чек',
              values: monthly.averageCheck,
            },
          ]}
        />
      </div>
      <div className='chart-wrap'>
        <TableColumn
          heading='Распределение выпущенных гарантий по сегментам с начала месяца (шт.)'
          head={issueDistributionHead}
          body={issueDistributionBody}
        />
        {/* <TableResult
          heading='Итого по разделу'
          values={['91 млн руб', '100%', '100%']}
        /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  isFetching: isFetching(state),

  createdDay: createdDay(state),
  createdCount: createdCount(state),
  createdCountLot: createdCountLot(state),
  createdCountInc: createdCountInc(state),
  createdAmount: createdAmount(state),
  createdAmountInc: createdAmountInc(state),

  approvedDay: approvedDay(state),
  approvedCount: approvedCount(state),
  approvedCountLot: approvedCountLot(state),
  approvedCountInc: approvedCountInc(state),
  approvedAmount: approvedAmount(state),
  approvedAmountInc: approvedAmountInc(state),

  issuedDay: issuedDay(state),
  issuedAmount: issuedAmount(state),
  issuedAmountInc: issuedAmountInc(state),
  issuedAmountForecast: issuedAmountForecast(state),
  issuedAmountForecastInc: issuedAmountForecastInc(state),
  issuedAmountScheduled: issuedAmountScheduled(state),
  issuedAmountScheduledInc: issuedAmountScheduledInc(state),
  issuedCount: issuedCount(state),
  issuedCountInc: issuedCountInc(state),
  issuedCountForecast: issuedCountForecast(state),
  issuedCountForecastInc: issuedCountForecastInc(state),
  issuedCountScheduled: issuedCountScheduled(state),
  issuedCountScheduledInc: issuedCountScheduledInc(state),

  issueDistribution: issueDistributionSelector(state),
  monthly: getMonthlySelector(state),
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getChartDataAction: (period) => {
      dispatch(getChartData(period))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(Charts))