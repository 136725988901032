import React, { memo } from 'react'
import { Checkbox } from '../../../layout'
import { Button } from '../Layout'
import { RowValue } from '../RowValue'
import { useStyles } from './styles'

export const Tasks = memo(({
  tasks,
  issues,
  purpose,
  paymentId,
  allChecked,
  dataItems,
  selectedTasks,
  disabledOrders,
  onSelectTask,
  onSelectAllTasks,
  onOpenReturnedModal,
  onOpenUnexplainedModal,
  onOpenNonDeliverableTransitModal,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.h2}>Список платежей/задач</div>
      <div className={classes.tableWrapper}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>
                <div>
                  <Checkbox
                    className={classes.checkbox}
                    checked={allChecked || false}
                    onChange={onSelectAllTasks}
                    title={allChecked ? 'Снять все' : 'Выбрать все'}
                  />
                </div>
              </th>
              {dataItems.map(({ name }, index) => 
                <th key={index}>
                  <div>
                    <span>{name}</span>
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {tasks?.items?.map((item, index) => {
              const checked = !!selectedTasks.find(p => p?.data?.payment?.id === item?.data?.payment?.id)
              const title = checked ? 'Снять выбор' : 'Выбрать'
              return (
                <tr key={index}>
                  <td>
                    <div>
                      <Checkbox
                        id={item?.data?.payment?.id}
                        name={item?.data?.payment?.id}
                        className={classes.checkbox}
                        title={title}
                        checked={checked}
                        onChange={() => onSelectTask(item)} 
                      />
                    </div>
                  </td>
                  {dataItems.map((props, index) =>
                    <RowValue key={index} item={item} {...props} />
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className={classes.info}>
        <div>
          {purpose &&
            <div className={classes.infoItem}>
              <span>Назначение платежа:</span> {purpose}
            </div>
          }
          {issues?.length > 0 &&
            <div className={classes.infoItem}>
              <div>
                <span>Проблемы по платежу:</span>
              </div>
              <ul className={classes.infoList}>
                {issues?.map(({ id, name, date }, index) =>
                  <li key={index}>{date}, {name}, {id}</li>
                )}
              </ul>
            </div>
          }
        </div>
        <div className={classes.buttons}>
          <Button
            disabled={!paymentId}
            onClick={onOpenNonDeliverableTransitModal}
          >
            Беспоставочный транзит
          </Button>
          <Button
            disabled={disabledOrders ? false : !paymentId}
            onClick={onOpenUnexplainedModal}
          >
            Невыясненные
          </Button>
          <Button
            disabled={!paymentId}
            onClick={onOpenReturnedModal}
          >
            Возврат
          </Button>
        </div>
      </div>
    </div>
  )
})