import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    maxWidth: '420px',
    height: '40px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '30px auto 80px',
  },
  icon: {
    width: '24px',
    height: '24px',
    display: 'inline-block',
    marginRight: '16px',
  },
}

const LoadingNext = ({ 
  classes,
  loading, 
}) => {
  if (!loading) {
    return null
  }

  return (
    <div className={classes.root}>
      <img className={classes.icon} src={require('./loading.svg')} alt='' />
      Загрузка данных
    </div>
  )
}

export default injectSheet(styles)(LoadingNext)