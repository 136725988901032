export const applications = {
  data: {
    labels: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31"
    ],
    datasets: [
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#9187EF",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#9187EF",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#9187EF",
        pointHoverBorderColor: "#9187EF",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          6,
          4,
          8,
          5,
          4,
          0,
          0,
          7,
          6,
          8,
          11,
          12,
          0,
          0,
          18,
          3,
          6,
          0,
          12,
          16,
          6,
          7,
          0,
          0,
          10,
          5,
          5,
          10,
          13,
          0,
          0
        ]
      },
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#1f7fde",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#1f7fde",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#1f7fde",
        pointHoverBorderColor: "#1f7fde",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          4,
          3,
          7,
          4,
          3,
          0,
          0,
          5,
          5,
          7,
          11,
          10,
          0,
          0,
          13,
          3,
          12,
          0,
          11,
          15,
          5,
          6,
          0,
          0,
          8,
          4,
          4,
          8,
          11,
          2,
          0
        ]
      }
    ]
  },
  options: {
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            stepSize: 5
          }
        }
      ]
    }
  }
};
export const forecast = {
  data: {
    labels: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31"
    ],
    datasets: [
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#1F7FDE",
        borderCapStyle: "butt",
        borderDash: [5, 5.5],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#1F7FDE",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#1F7FDE",
        pointHoverBorderColor: "#1F7FDE",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          4,
          5,
          8,
          12,
          16,
          20,
          20,
          20,
          24,
          28,
          32,
          35,
          35,
          35,
          39,
          43,
          47,
          51,
          55,
          55,
          55,
          58,
          61,
          64,
          67,
          70,
          70,
          70,
          73,
          76,
          80
        ]
      },
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#2BC3A0",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#2BC3A0",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#2BC3A0",
        pointHoverBorderColor: "#2BC3A0",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [3, 4, 5, 5, 5]
      },
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#EF878D",
        borderCapStyle: "butt",
        borderDash: [5, 5.5],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#EF878D",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#EF878D",
        pointHoverBorderColor: "#EF878D",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          "-",
          "-",
          "-",
          "-",
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ]
      }
    ]
  },
  options: {
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            stepSize: 20
          }
        }
      ]
    }
  }
};
export const endorsements = {
  data: {
    labels: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31"
    ],
    datasets: [
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#9187EF",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#9187EF",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#9187EF",
        pointHoverBorderColor: "#9187EF",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          6,
          4,
          8,
          5,
          4,
          0,
          0,
          7,
          6,
          8,
          11,
          12,
          0,
          0,
          18,
          3,
          6,
          0,
          12,
          16,
          6,
          7,
          0,
          0,
          10,
          5,
          5,
          10,
          13,
          0,
          0
        ]
      },
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#1f7fde",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#1f7fde",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#1f7fde",
        pointHoverBorderColor: "#1f7fde",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          4,
          3,
          7,
          4,
          3,
          0,
          0,
          5,
          5,
          7,
          11,
          10,
          0,
          0,
          13,
          3,
          12,
          0,
          11,
          15,
          5,
          6,
          0,
          0,
          8,
          4,
          4,
          8,
          11,
          2,
          0
        ]
      }
    ]
  },
  options: {
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            stepSize: 5
          }
        }
      ]
    }
  }
};
export const forecast2 = {
  data: {
    labels: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31"
    ],
    datasets: [
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#1F7FDE",
        borderCapStyle: "butt",
        borderDash: [5, 5.5],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#1F7FDE",
        pointBackgroundColor: "#1F7FDE",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#1F7FDE",
        pointHoverBorderColor: "#1F7FDE",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ]
      },
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#2BC3A0",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#2BC3A0",
        pointBackgroundColor: "#2BC3A0",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#2BC3A0",
        pointHoverBorderColor: "#2BC3A0",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [3, 3, 3, 3, 3]
      },
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#EF878D",
        borderCapStyle: "butt",
        borderDash: [5, 5.5],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#EF878D",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#EF878D",
        pointHoverBorderColor: "#EF878D",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          "-",
          "-",
          "-",
          "-",
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ]
      }
    ]
  },
  options: {
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            stepSize: 50,
            min: 0,
            max: 100
          }
        }
      ]
    }
  }
};
export const extradition = {
  data: {
    labels: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31"
    ],
    datasets: [
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#9187EF",
        borderCapStyle: "butt",
        borderDash: [5, 5.5],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#9187EF",
        pointBackgroundColor: "#9187EF",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#9187EF",
        pointHoverBorderColor: "#9187EF",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3,
          3
        ]
      },
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#EF878D",
        borderCapStyle: "butt",
        borderDash: [5, 5.5],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#EF878D",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#EF878D",
        pointHoverBorderColor: "#EF878D",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ]
      },
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#2bc3a0",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#2bc3a0",
        pointBackgroundColor: "#2bc3a0",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#2bc3a0",
        pointHoverBorderColor: "#2bc3a0",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          3,
          1,
          2,
          0,
          0,
          0,
          0,
          0,
          2,
          2,
          1,
          0,
          0,
          0,
          4,
          1,
          1,
          0,
          0,
          0,
          0,
          1,
          1,
          4,
          0,
          0,
          0,
          0,
          2,
          0
        ]
      }
    ]
  },
  options: {
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            stepSize: 1
          }
        }
      ]
    }
  }
};
export const extradition2 = {
  data: {
    labels: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31"
    ],
    datasets: [
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgb(43, 195, 160)",
        borderColor: "#1f7fde",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#1f7fde",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#1f7fde",
        pointHoverBorderColor: "#1f7fde",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          10,
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          170,
          1,
          "-",
          "-",
          "-",
          30,
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          1,
          "-",
          60,
          "-",
          "-",
          "-",
          "-",
          "-",
          1,
          "-"
        ]
      },
      {
        fill: false,
        lineTension: 0.001,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#EF878D",
        borderDash: [5, 5.2],
        borderDashOffset: 0.0,
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointBorderColor: "#EF878D",
        pointBackgroundColor: "#EF878D",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#EF878D",
        pointHoverBorderColor: "#EF878D",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1
        ]
      }
    ]
  },
  options: {
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            stepSize: 50,
            min: 0,
            max: 200
          }
        }
      ]
    }
    // tooltips: {
    //   callbacks: {
    //     label: (tooltipItem, data) => {
    //       console.log(tooltipItem, data)
    //       var label = data.datasets[tooltipItem.datasetIndex].label || "";
    //       console.log(label)
    //     }
    //   }
    // }
  }
};
