import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  item: {
    background: '#fff',
    marginTop: 24,
    padding: 16,
    border: [1, 'solid', '#ddd'],
    borderRadius: 16,
    position: 'relative',
    '& + &': {
      marginTop: 10,
    },
  },
})

export const Item = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.item}>{children}</div>
  )
}