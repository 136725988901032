import * as actionTypes from './actionTypes'
import { Tasks } from '../../services/api'
import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'


export const getTasks = (filters, id) => async dispatch => {
  try {
    dispatch({ type: actionTypes.TASKS_FETCH })
    const { isSuccess, ...res } = await Tasks.getTasks(filters)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ 
      type: actionTypes.TASKS_SUCCESS, 
      data: {
        ...res,
        task: res.task.filter(item => item.id !== id),
      }
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.TASKS_ERROR })
  }
}

export const getNextTasksPage = (page, filters) => async dispatch => {
  try {
    dispatch({ type: actionTypes.NEXT_TASKS_FETCH })
    const { isSuccess, ...res } = await Tasks.getNextPage(page, filters)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ 
      type: actionTypes.NEXT_TASKS_SUCCESS, 
      data: res, 
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.NEXT_TASKS_ERROR })
  }
}

export const insertTask = (task) => dispatch => 
  dispatch({
    type: actionTypes.TASKS_INSERT, 
    data: {
      task,
    }
  })

// export const setTasksFilter = (filters) => dispatch => 
// 	dispatch({ 
// 		type: actionTypes.TASKS_SET_FILTER, 
// 		data: { filters },
// 	})
// }

export const setTasksFilter = (filters) => async (dispatch, getState) => {
  dispatch({ 
    type: actionTypes.TASKS_SET_FILTER, 
    data: { filters } 
  })
  const { Tasks } = getState()
  dispatch(getTasks(Tasks.filters))
}

export const clearAllFilters = () => dispatch => 
  dispatch({ 
    type: actionTypes.TASKS_CLEAR_FILTERS, 
  })

export const setAssigned = (taskId, username) => (dispatch, getState) => {
  const { User } = getState()
  dispatch({ 
    type: actionTypes.TASKS_SET_ASSIGNED_TASK, 
    data: {
      taskId,
      username: username || User.username,
      isAssigned: !username || (username && username === User.username),
    } 
  })
}

export const setActiveTask = (task) => dispatch => 
  dispatch({ 
    type: actionTypes.TASK_SET_ACTIVE, 
    data: task, 
  })

export const clearActiveTask = () => dispatch => 
  dispatch({ 
    type: actionTypes.TASK_SET_ACTIVE, 
    data: {}, 
  })

export const selectTask = (taskId) => dispatch => 
  dispatch({ 
    type: actionTypes.TASK_SELECT, 
    data: taskId, 
  })

export const unselectTask = (taskId) => dispatch => 
  dispatch({ 
    type: actionTypes.TASK_UNSELECT, 
    data: taskId, 
  })

export const unselectAllTasks = () => dispatch => 
  dispatch({ 
    type: actionTypes.TASK_UNSELECT_ALL, 
  })