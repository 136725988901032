import * as types from './actionTypes'
import { Search } from '../../services/api'

import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'

export const searchByString = (query, findAll, request, requestPayload) => async (dispatch) => {
  try {
    const requestMethod = request
      ? request
      : findAll
        ? Search.selectByString
        : Search.findByString
    const { isSuccess, ...res } = await requestMethod(query, requestPayload)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({
      type: types.SEARCH_SUCCESS,
      result: res.list || [],
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: types.SEARCH_ERROR })
  }
}

export const clearSearchResults = () => (dispatch) =>
  dispatch({ type: types.SEARCH_CLEAR })