import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { Col, Row } from 'reactstrap'
import { InputText, Select1 } from '../../../../layout'
import { getReportStandards } from '../../../../redux/Reports/Standards/actions'
import { commonStatusStyles } from '../all3d/styles'
import { ReportTab } from '../../Layout'
import { useProcessSelectOptions } from '../../useProcessSelectOptions'
import { StandardsGraphic } from './StandardsGraphic'
import { YYYYMMDD } from '../../../../utils'

export const StandardsReport = commonStatusStyles(({
  classes,
  onCancel,
  fullWidth,
  changeFullWidth,
  settings,
}) => {
    const dispatch = useDispatch()

    const { processOptions, processDefault } = useProcessSelectOptions(settings.process)

    const now = new Date()
    const currentDate = YYYYMMDD(now)
    const firstDayOfMonth = YYYYMMDD(new Date(now.getFullYear(), now.getMonth(), 1))
    // const firstDayOfMonth = '2018-01-01'

    const [process, setProcess] = useState(processDefault)
    const [dateFrom, setDateFrom] = useState(firstDayOfMonth)
    const [dateTo, setDateTo] = useState(currentDate)
    const [sent, setSent] = useState(false)

    const { isFetching, data } = useSelector(state => state?.Reports?.Standards)

    const onChangeProcess = (e) => setProcess(e.target.value)
    const onChangeDateFrom = (e) => setDateFrom(e.target.value)
    const onChangeDateTo = (e) => setDateTo(e.target.value)

    const onGetReport = () => {
      setSent(true)
      dispatch(getReportStandards({ process, dateFrom, dateTo }))
    }

    return (
      <ReportTab
        onOk={onGetReport}
        onCancel={onCancel}
        okButtonText='Получить график'
        disabled={false}
        loading={isFetching}
        title={settings.description}
      >
        <Col className={classnames(classes.form, { fullWidth })}>
          {processOptions.length > 1 &&
            <div className={classes.type}>
              <Select1
                name='bankSignerId'
                onChange={onChangeProcess}
                defaultValue={process}
                options={processOptions}
              />
            </div>
          }
          <Row className={classes.form}>
            <div className='col-6'>
              <InputText
                type='date'
                name='dateFrom'
                label='Дата с'
                value={dateFrom}
                maxDate={new Date(dateTo)}
                onChange={onChangeDateFrom}
              />
            </div>
            <div className='col-6'>
              <InputText
                type='date'
                name='dateTo'
                label='Дата по'
                value={dateTo}
                minDate={new Date(dateFrom)}
                onChange={onChangeDateTo}
              />
            </div>
          </Row>
        </Col>
        <div className={classes.content}>
          {sent &&
            (isFetching
              ? null
              : (data && Object.keys(data).length > 0
                  ? <StandardsGraphic
                      data={data}
                      fullWidth={fullWidth}
                      changeFullWidth={changeFullWidth}
                      onCancel={onCancel}
                    />
                  : <div className={classes.noData}>Нет данных</div>
                )
            )
          }
        </div>
      </ReportTab>
    )
  }
)