import React from 'react'
import { createUseStyles } from 'react-jss'
import { Title } from './Layout'
import { ClientBank, MtsBusiness, CorpCard, PercentAccrual } from './Products'

const useStyles = createUseStyles({
  dbo: {
    position: 'relative',
  },
})

export const Dbo = ({ companyId }) => {
  const classes = useStyles()
  // const { orders, fetching } = useSelector(state => state?.Company?.orders) || {}

  const clientBank = false
  const mtsBusiness = false
  const corpCard = false
  const percentAccrual = false

  const clientBankProps = {
    provideLogin: false,
    sendToEmail: false,
    useSimpleESign: false,
  }

  const corpCardProps = {
    sendToPhone: false,
    plugCashback: false,
  }

  return (
    <div className={classes.dbo}>
      <Title>Продукты ДБО</Title>
      <ClientBank
        date='12.02.2020'
        orderNumber='4534558-87'
        enabled={clientBank}
        {...clientBankProps}
      />
      <MtsBusiness
        date='12.02.2020'
        orderNumber='4534558-87'
        enabled={mtsBusiness}
      />
      <CorpCard
        date='12.02.2020'
        orderNumber='4534558-87'
        enabled={corpCard}
        {...corpCardProps}
      />
      <PercentAccrual
        date='12.02.2020'
        orderNumber='4534558-87'
        enabled={percentAccrual}
      />
    </div>
  )
}