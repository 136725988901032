import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { getCompanyStaffInfo } from '../../../../redux/Company/actions'
import { Row } from './../../../../layout'
import { formatMoney } from '../../../../utils'

const styles = {
  center: {
    textAlign: 'center',
  },
}

const Staff = ({
  classes,
  companyId,
  staffInfo,
  getCompanyStaffInfo,
}) => {
  useEffect(() => {
    getCompanyStaffInfo(companyId)
  }, [companyId, getCompanyStaffInfo])

  const data = staffInfo.staffInfo || {}
  const averageNumber = (data && data.averageNumber) || '-'
  const staffDebts = (data && data.staffDebts) || '-'

  return (
    <div>
      <Row>
        <div className='col-12 col-md-12'>
          <label>Штат сотрудников, чел.</label>
          <input 
            type='text' 
            className={classes.center} 
            value={averageNumber} 
            disabled 
          />
        </div>
      </Row>
      <Row>
        <div className='col-12 col-md-12'>
          <label>Задолженность по зарплате, тыс. руб</label>
          <input 
            type='text' 
            className={classes.center} 
            value={formatMoney(staffDebts)} 
            disabled 
          />
        </div>
      </Row>
    </div>
  )
}

const mapStateToProps = ({ Company }) => {
  return {
    staffInfo: Company.staffInfo,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getCompanyStaffInfo: (companyId) => {
      dispatch(getCompanyStaffInfo(companyId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(Staff))