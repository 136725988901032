import React from 'react'
import classnames from 'classnames'
import { Payments } from '../../../../services/api'
import PropTypes from 'prop-types'
import { registerType } from '../../types'

function statusCssClass(status) {
  switch (status) {
    case true:
      return 'registers__status registers__status--accepted'
    case false:
      return 'registers__status registers__status--not-accepted'
    case 'ошибка':
      return 'registers__status registers__status--error'
    default:
      return ''
  }
}

function stopPropagation(event) {
  event.stopPropagation()
}

export function RegistryItem({
  register: {
    id,
    registerNumber,
    createdDateTime,
    companyName,
    success,
    errorMessage
  },
  uploadError,
  isUploading,
  onClick
}) {
  const handleItemClick = React.useCallback(() => onClick(id), [id, onClick])
  const formattedDate = new Date(createdDateTime)

  return (
    <div className="registers__item" onClick={handleItemClick}>
      <div className="registers__number">{registerNumber}</div>
      <div className="registers__title">{companyName}</div>
      <div
        className={classnames('registers__data', {
          'registers__data--accepted': success
        })}
      >
        <span className="registers__data--date">
          {`${formattedDate.toLocaleDateString('ru-RU')}, `}
        </span>
        <span className="registers__data--time">
          {formattedDate.toLocaleTimeString().slice(0, 5)}
        </span>
      </div>
      <div className={statusCssClass(success)}>
        {success ? 'Реестр обработан' : errorMessage}
      </div>
      {!isUploading && (
        <div className="registers__downloads" onClick={stopPropagation}>
          <a
            className="registers__download"
            download
            href={Payments.getHrefJsonRegister(id)}
          >
            .json
          </a>
          <a
            className="registers__download"
            href={Payments.getHrefCsvRegister(id)}
            download
          >
            .csv
          </a>
          {uploadError && <span className="registers__btn">Повторить</span>}
        </div>
      )}
      {isUploading && (
        <div className="progress">
          <progress max="100" value="48"></progress>
          <div className="progress__background">
            <div className="progress__bar"></div>
          </div>
        </div>
      )}
      <div className="right-item-container">
        <div className="registers--arrow" />
      </div>
    </div>
  )
}

RegistryItem.propTypes = {
  register: registerType,
  uploadError: PropTypes.bool,
  isUploading: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}
