import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Modal } from '../index'
import { Icon } from '../..'
import { formattedDateTime } from '../../../utils'

const ICON_SIZE = 16
const ICON_GAP = 8

const styles = {
  root: {
    padding: [10, 0],
  },
  list: {
    position: 'relative',
    '&.activeList': {
      marginTop: -20,
    },
  },
  toggle: {
    paddingLeft: 62,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    '&.mt40': {
      marginTop: 40,
    },
  },
  icon: {
    width: 50,
    height: 50,
    top: -9,
    cursor: 'pointer',
  },
  item: {
    background: '#fff',
    width: 230,
    padding: [18, 20],
    marginRight: 20,
    marginTop: 20,
    borderRadius: 16,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  title: {
    height: 34,
    color: '#000',
    fontSize: 15,
    lineHeight: 1.2,
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    '&.isComplete': {
      opacity: 0.5,
    },
  },
  date: {
    marginTop: 15,
    color: '#B8B8B8',
    fontSize: 12,
    lineHeight: 1.2,
    fontWeight: 500,
  },
  user: {
    marginTop: 25,
    color: '#000',
    fontSize: 12,
    lineHeight: 1.25,
    fontWeight: 500,
    '& svg': {
      width: ICON_SIZE,
      height: ICON_SIZE,
      marginRight: ICON_GAP,
      fill: '#9498A9',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& span': {
      maxWidth: `calc(100% - ${ICON_SIZE + ICON_GAP}px)`,
      display: 'inline-block',
      verticalAlign: 'top',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}

const Activities = ({
  classes,
  items,
  title = 'Активности'
}) => {
  const [open, setOpen] = useState(false)

  const makeOpen = () => setOpen(!open)

  const hasItems = items && items.length > 0

  const active = hasItems ? items.filter(item => !item.isComplete) : []
  const completed = hasItems ? items.filter(item => item.isComplete) : []

  const hasActive = active && active.length > 0
  const hasCompleted = completed && completed.length > 0

  const renderItems = (items, custom) => 
    <div className={classnames(classes.list, { 'activeList': custom })}>
      {items.map(({
        assignee,
        assigneeUser,
        title,
        isComplete,
        startTime,
        endTime,
      }, index) =>
        <div className={classes.item} key={`activity-item-${index}`}>
          <div className={classnames(classes.title, { isComplete })}>{title}</div>
          <div className={classes.date}>
            {formattedDateTime(isComplete ? endTime : startTime)}
          </div>
          <div className={classes.user}>
            <Icon iconName='user' />
            {assigneeUser?.email
              ? <a href={`mailto:${assigneeUser?.email}`} target='_blank' rel='noopener noreferrer'>{assignee}</a>
              : <span>{assignee || '-'}</span>
            }
          </div>
        </div>
      )}
    </div>

  return hasItems
    ? <Modal.Block iconName='bar-chart' title={title}>
        <Modal.Content>
          <div className={classes.root}>
              {hasActive &&
                renderItems(active, true)
              }
              {hasCompleted && 
                <>
                  <Modal.H2 
                    className={classnames(classes.toggle, { 'mt40': hasActive })} 
                    icon={{ name: 'arrow-bottom', className: classes.icon }}
                    open={open}
                    onClick={makeOpen}
                  >
                    {open ? 'Скрыть' : 'Показать'} завершенные
                  </Modal.H2>
                  <Collapse isOpen={open}>
                    {renderItems(completed)}
                  </Collapse>
                </>
              }
            </div>
        </Modal.Content>
      </Modal.Block>
    : null
}

export default injectSheet(styles)(Activities)