import React from 'react'
import { Route } from 'react-router-dom'
import { ModalDeal } from './../../components/Modals'
import Orders from '../OrdersPage/Orders'

const MonitoringNotificationOrdersPage = (props) =>
  <>
    <Orders {...props} />
    <Route
      exact
      path='/monitoringNotificationOrders/:orderId'
      component={ModalDeal}
    />
  </>

export default MonitoringNotificationOrdersPage