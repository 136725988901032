import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Logo } from '../../components/Logo'

class UserMenu extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onLogout: PropTypes.func.isRequired
  }

  state = { isOpen: false }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick)
  }

  onToggleMenu = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
      document.removeEventListener('click', this.handleOutsideClick)
    } else {
      this.setState({ isOpen: true })
      document.addEventListener('click', this.handleOutsideClick)
    }
  }

  onLogout = () => this.props.onLogout()

  handleOutsideClick = ({ target }) => {
    if (this.menu && this.menu.contains(target)) return
    this.onToggleMenu()
  }

  render() {
    const { name } = this.props
    const { isOpen } = this.state

    return (
      <div 
        className={classnames('fr-user-menu', { active: isOpen })} 
        ref={node => { this.menu = node }}
      >
        <div className='fr-user-menu__dropdown'>
          <div className='fr-user-menu__list'>
            <div className='fr-user-menu__title'>
              <Logo />
            </div>
            {/* <Link className='fr-user-menu__item' to='?restore-password'>
              <i className='icon icon-settings' />
              Настройки
            </Link> */}
            <span className='fr-user-menu__item' onClick={this.onLogout}>
              <i className='icon icon-exit' />
              Выход
            </span>
          </div>
        </div>
        <div className='fr-user-menu__main' onClick={this.onToggleMenu}>
          <div className='fr-user-menu__name'>
            <span className='icon icon-user' />
            {name}
          </div>
        </div>
      </div>
    )
  }
}

export default UserMenu