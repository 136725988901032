import React, { memo } from 'react'
import { useStyles } from './styles'
import { Icon } from '../../../layout'
import { RowValue } from '../RowValue'
import { formattedNumber } from '../../../utils'
import { ORDERS } from './config'

export const Drafts = memo(({
  tab,
  drafts,
  setIsDraft,
  onRemoveDraft,
  onSelectOrder,
  isPaymentReplications,
}) => {
  const classes = useStyles()

  const totalDraftsMatchedAmount = drafts?.reduce((result, item) => result + item?.matchedAmount, 0)

  return (
    <div
      className={classes.tableWrapper}
      style={{ maxHeight: `${500 + (drafts?.length || 0) * 42}px`}}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th><div>&nbsp;</div></th>
            {ORDERS[tab].map(({ title }, index) => 
              <th key={index}><div>{title}</div></th>
            )}
          </tr>
        </thead>
        <tbody>
          {!isPaymentReplications &&
            <>
              <tr
                // className='sticky'
              >
                <td
                  className='colspan'
                  colSpan={ORDERS[tab].length + 1}
                >
                  <div>Черновики (ИТОГО: {formattedNumber(totalDraftsMatchedAmount)})</div>
                </td>
              </tr>
              {drafts?.length > 0
                ? drafts.map((draft, index) => {
                    const topCss = {
                      // top: 40 * (index + 2),
                      cursor: draft?.order?.id ? 'pointer' : 'default',
                    }
                    return (
                      <tr
                        className='draft'
                        key={index}
                      >
                        <td
                          style={{...topCss, cursor: 'pointer' }}
                          onClick={() => onRemoveDraft(draft)}
                        >
                          <div>
                            <div
                              className={classes.remove}
                              title='Удалить из черновика'
                            >
                              <Icon iconName='close2' />
                            </div>
                          </div>
                        </td>
                        {ORDERS[tab].map((props, index) =>
                          <RowValue
                            key={index}
                            style={topCss}
                            item={props.field === 'matchedAmount'
                              ? draft
                              : draft.order
                            }
                            onClick={() => {
                              if (draft?.order?.id) {
                                onSelectOrder(draft.order)
                                setIsDraft(true)
                              }
                            }}
                            {...props}
                          />
                        )}
                      </tr>
                    )
                  })
                : <tr>
                    <td
                      colSpan={ORDERS[tab].length + 1}
                      style={{ top: 80 }}
                    >
                      <div>Черновики отсутствуют</div>
                    </td>
                  </tr>
              }
            </>
          }
        </tbody>
      </table>
    </div>
  )
})