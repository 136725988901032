import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { removeCompanyAgentBillingSigner } from '../../../../redux/Company/actions'
// import Modal from '../../../../containers/Modal'
import AddBillingSigner from './AddBillingSigner'
import { ConfirmModal } from './../../../ConfirmModal'
import { Modal, UI } from './../../../../layout'
import classnames from 'classnames'

const styles = {
  root: {
    position: 'relative',
  },
  table: UI.table,
  title: UI.title,
  add: UI.addButton,
  remove: UI.removeButton,
}

const BillingSigners = ({
  classes,
  companyId,
  billingSigners,
  removeCompanyAgentBillingSigner,
}) => {
  const [displayName, setDisplayName] = useState('')
  const [relationId, setRelationId] = useState('')
  const [showFormModal, setShowFormModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const onOpenConfirmModal = (id, name) => {
    setShowConfirmModal(true)
    setRelationId(id)
    setDisplayName(name)
  }
  const onCloseConfirmModal = () => setShowConfirmModal(false)

  const onOpenModal = () => setShowFormModal(true)
  const onCloseModal = () => setShowFormModal(false)

  const onRemove = () => removeCompanyAgentBillingSigner({ companyId, relationId })
  
  const hasItems = billingSigners && billingSigners.length

  return (
    <div className={classes.root}>
      <div className={classes.title}>Список подписантов агента</div>
      <table className={classes.table}>
        <thead>
          <tr>
            <td>Должность</td>
            <td>Имя пользователя</td>
            <td>Основания полномочий</td>
            <td>&nbsp;</td>
          </tr>
        </thead>
        <tbody>
          {hasItems ? (
            <Fragment>
              {billingSigners.map(({ signerRelationId, position, displayName, authorizingDoc }) =>
                <tr className={classes.tr} key={signerRelationId}>
                  <td><div>{position}</div></td>
                  <td><div>{displayName}</div></td>
                  <td><div>{authorizingDoc}</div></td>
                  <td>
                    <div 
                      className={classes.remove} 
                      onClick={() => onOpenConfirmModal(signerRelationId, displayName)}
                    >
                      Удалить
                    </div>
                  </td>
                </tr>
              )}
            </Fragment>
          ) : (
            <tr className='no-hover'>
              <td colSpan='4'>Список пуст</td>
            </tr>
          )}
          <tr className='no-hover'>
            <td colSpan='2'>&nbsp;</td>
            <td colSpan='2'>
              <div className={classnames(classes.add, 'btn-form--ok')} onClick={onOpenModal}>
                Добавить подписанта
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      {showFormModal &&
        <Modal height='auto' onClose={onCloseModal}>
          <AddBillingSigner 
            companyId={companyId}
            onCancel={onCloseModal} 
          />
        </Modal>
      }
      {showConfirmModal &&
        <ConfirmModal
          title={`Вы действительно хотите удалить подписанта ${displayName}?`}
          submitTitle='Удалить'
          onCancel={onCloseConfirmModal}
          onSubmit={onRemove}
        />
      }
    </div>
  )
}

const mapStateToProps = ({ Company }) => {
  return {
    billingSigners:
      Company && 
      Company.agentInfo && 
      Company.agentInfo.info && 
      Company.agentInfo.info.billingSigners
  }
}

const dispatchMapToProps = (dispatch, ownProps) => {
  return {
    removeCompanyAgentBillingSigner: (payload) => {
      dispatch(removeCompanyAgentBillingSigner(payload))
    },
  }
}

BillingSigners.propTypes = {
  companyId: PropTypes.string.isRequired,
  // billingSigners: PropTypes.array.isRequired,
  removeCompanyAgentBillingSigner: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, dispatchMapToProps)(injectSheet(styles)(BillingSigners))