import {
  GET_REPORT_FUNNEL_PENDING,
  GET_REPORT_FUNNEL_SUCCESS,
  GET_REPORT_FUNNEL_ERROR,
} from './actionTypes'

const initialState = {
  isFetching: false,
  filters: {},
  data: {},
}

export const FunnelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_FUNNEL_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case GET_REPORT_FUNNEL_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
      }
    case GET_REPORT_FUNNEL_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}