import classnames from 'classnames'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsTreemap from 'highcharts/modules/treemap'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { commonStatusStyles } from '../all3d/styles'
import { DetailsTable } from '../../Layout/DetailsTable'
import { FullScreenIcon } from '../../Layout/FullScreenIcon'
import { FundsDetailsItem } from './FundsDetailsItem'
import { useFundsGraphicOptions } from './graphicOptions'
import { useWindowSize } from '../../../../hooks'

HighchartsExporting(Highcharts)
HighchartsTreemap(Highcharts)

export const FundsGraphic = commonStatusStyles(
  ({ classes, data, fullWidth, changeFullWidth, onCancel }) => {
    const chartRef = useRef()
    const { width } = useWindowSize()
    const [crumbs, setCrumbs] = useState([{ id: 'id', name: 'Все этапы' }])
    const [details, setDetails] = useState()
    const lastCrumb = useMemo(() => crumbs[crumbs.length - 1], [crumbs])

    const onClickArea = useCallback(
      event => {
        const { point } = event
        const { id, name, parent } = point

        if (lastCrumb.id !== id && id.split('_').length < 5) {
          setCrumbs(state => [...state, { id, name }])
        } else {
          // open modal with details
          if (event.point.keys) {
            const response = data

            let h = response

            for (let i = 0, j = event.point.keys.length; i < j; i++) {
              h = h[event.point.keys[i]]

              if (h) {
              } else {
                h = null
                break
              }
            }

            const task = chartRef.current
              ? chartRef.current.chart.get(parent)
              : undefined

            setDetails(
              h
                ? { ...h, name: task && task.name ? task.name : h.name }
                : undefined
            )
          }
        }
      },
      [lastCrumb.id, data, chartRef]
    )

    const options = useFundsGraphicOptions({
      data,
      chart:
        chartRef.current && chartRef.current.chart
          ? chartRef.current.chart
          : undefined,
      onClickArea,
      fullWidth,
    })

    useEffect(() => {
      if (chartRef.current) {
        chartRef.current.chart.width = 1000
      }
    }, [chartRef])

    const onClickCrumb = id => () => {
      if (chartRef.current) {
        const toIndex = crumbs.reduce(
          (acc, { id: iid }, ind) => (iid === id ? ind : acc),
          0
        )

        setCrumbs(state => state.filter((_, ind) => ind <= toIndex))
        chartRef.current.chart.series[0].drillToNode(id !== 'id' ? id : null)
      }
    }

    const onCloseDetails = () => {
      setDetails()
    }

    const onClick = () => {
      setDetails()
      onCancel()
    }

    return (
      <div>
        <div className={classes.graphicContainer}>
          <FullScreenIcon fullWidth={fullWidth} onClick={changeFullWidth} />
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
        </div>
        <div
          className={classes.crumbs}
          style={{ maxWidth: !fullWidth ? 600 : width - 220 }}
        >
          {crumbs.map(({ id, name }) => (
            <div
              className={classnames(classes.crumb, {
                [classes.activeCrumb]: id !== lastCrumb.id,
              })}
              onClick={onClickCrumb(id)}
              key={id}
            >
              {name}
            </div>
          ))}
        </div>
        {details && (
          <DetailsTable
            data={details}
            onClose={onCloseDetails}
            Item={FundsDetailsItem}
            onClick={onClick}
          />
        )}
      </div>
    )
  }
)
