import React, { forwardRef } from 'react'
import ScrollArea from 'react-scrollbar'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
// import 'react-scrollbar/no-css'

const useStyles = createUseStyles({
  scroll: {
    maxHeight: '100%',
    '&.scrollarea': {
      '&:hover': {
        '& .scrollbar-container': {
          '&.vertical': {
            opacity: 1,
          },
        },
      },
      '& .scrollbar-container': {
        opacity: 1,
        '&.vertical': {
          width: 3,
          right: 10,
          '& .scrollbar': {
            background: '#c4c4c4',
            width: 'inherit',
            marginLeft: 0,
            borderRadius: 3,
          },
        },
      },
    },
    // '& .scrollarea .scrollbar-container.vertical .scrollbar': {
    //   width: 'inherit',
    //   borderRadius: 3,
    // },
  },
})

export const Scroll = forwardRef(({ className, children }, ref) => {
  const classes = useStyles()
  return (
    <ScrollArea
      ref={ref}
      speed={0.5}
      className={classnames(classes.scroll, className)}
      contentClassName='content'
      horizontal={false}
    >
      {children}
    </ScrollArea>
  )
})