import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { getScoringColorStyle } from './index'

const styles = {
  root: {
    background: '#E4E4E4',
    color: '#9B9B9B',
    minWidth: 56,
    minHeight: 31,
    padding: [6, 13, 7],
    borderRadius: 12,
    fontSize: 14,
    lineHeight: 1.25,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
  },
}

const Status = ({ 
  value,
  negative,
  className,
  onClick,
  classes,
}) =>
  <div
    className={classnames(classes.root, className)}
    style={getScoringColorStyle(negative)}
    onClick={onClick}
  >
    {value}
  </div>

export default injectSheet(styles)(Status)