import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { InputText, Select1 } from '../../../../layout'
import {
  getReportTasks,
  getReportTasksClearDetails,
  getReportTasksTasks,
} from '../../../../redux/Reports/Tasks/actions'
import { commonStatusStyles } from '../all3d/styles'
import { ReportTab, TaskSelect } from '../../Layout'
import { useProcessSelectOptions } from '../../useProcessSelectOptions'
import { TasksGraphic } from './TasksGraphic'
import { YYYYMMDD } from '../../../../utils'

export const TasksReport = commonStatusStyles(({
  classes,
  onCancel,
  settings,
}) => {
  const dispatch = useDispatch()

  const { processOptions, processDefault } = useProcessSelectOptions(settings.process)

  const { isFetching, data, tasks, isFetchingTasks } = useSelector(state => state?.Reports?.Tasks)

  const now = new Date()
  const currentDate = YYYYMMDD(now)
  const firstDayOfMonth = YYYYMMDD(new Date(now.getFullYear(), now.getMonth(), 1))
  
  const [tasksState, setTasksState] = useState([])
  const [process, setProcess] = useState(processDefault)
  const [dateFrom, setDateFrom] = useState(firstDayOfMonth)
  const [dateTo, setDateTo] = useState(currentDate)
  const [sent, setSent] = useState(false)

  useEffect(() => {
    setTasksState(tasks)
  }, [tasks])

  useEffect(() => {
    dispatch(getReportTasksTasks({ process }))
  }, [dispatch, process])

  const onChangeDateFrom = (e) => setDateFrom(e.target.value)
  const onChangeDateTo = (e) => setDateTo(e.target.value)
  const onChangeProcess = (e) => {
    setSent(false)
    setProcess(e.target.value)
  }
  const onGetReport = () => {
    setSent(true)
    dispatch(getReportTasks({
      process,
      dateFrom,
      dateTo,
      groups: tasksState
        .filter(({ disabled }) => !disabled)
        .map(({ id }) => id)
    }))
    dispatch(getReportTasksClearDetails())
  }

  const onClickTask = (value) => setTasksState(state =>
    state.map(({ id, ...rest }) => id !== value
      ? { id, ...rest }
      : { id, ...rest, disabled: !rest.disabled }
    )
  )

  return (
    <ReportTab
      onOk={onGetReport}
      onCancel={onCancel}
      okButtonText='Получить график'
      disabled={false}
      loading={isFetching}
      title={settings.description}
    >
      <Col className={classes.form}>
        {processOptions.length > 1 &&
          <div className={classes.type}>
            <Select1
              name='bankSignerId'
              onChange={onChangeProcess}
              defaultValue={process}
              options={processOptions}
            />
          </div>
        }
        <Row>
          <div className='col-6'>
            <InputText
              type='date'
              name='dateFrom'
              label='Дата с'
              value={dateFrom}
              maxDate={new Date(dateTo)}
              onChange={onChangeDateFrom}
            />
          </div>
          <div className='col-6'>
            <InputText
              type='date'
              name='dateTo'
              label='Дата по'
              value={dateTo}
              minDate={new Date(dateFrom)}
              onChange={onChangeDateTo}
            />
          </div>
        </Row>
        <Row>
          <TaskSelect
            options={tasksState}
            onClickTask={onClickTask}
            loading={isFetchingTasks}
          />
        </Row>
        <div className={classes.content}>
          {sent &&
            (isFetching
              ? null
              : <TasksGraphic
                  data={data}
                  process={process}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  groups={tasksState}
                  onCancel={onCancel}
                />
            )
          }
        </div>
      </Col>
    </ReportTab>
  )
})