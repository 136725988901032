import React from 'react';

const TableTitle = (props) => {
  const { ...attributes } = props;

  return (
    <div
      {...attributes}
      className="chart__title"
    />
  )
};

export default TableTitle;
