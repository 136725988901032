import axios from 'axios'
import { API_URL, IS_DEV } from '../../config'

export const getForm = async (id) => {
  try {
    const prefix =
      id !== 'a2d08f72-62e9-11e9-a7d4-e205da6478d1' &&
      id !== 'df6f518e-56e3-11e9-add7-e205da6478d1'
        ? 'form'
        : id

    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      url: IS_DEV
        ? `/mocksApi/${prefix}.json`
        : `/mocksApi/${prefix}.json` // todo replace on real api url
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getUserCandidatesByTasks = async (taskIds) => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'POST',
      data: {taskId: taskIds},
      url: `${API_URL}/task/bulk/assigneeChange/getUsers`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const assignTasksToUser = async (taskIds, userId) => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'POST',
      data: {
        taskId: taskIds, 
        assignee: userId,
      },
      url: `${API_URL}/task/bulk/assigneeChange/complete`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const retryResendToComplete = async (taskIds) => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'POST',
      data: {
        taskId: taskIds, 
      },
      url: `${API_URL}/task/bulk/retryResend/complete`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getPrintGuaranteeDocumentUrls = async (taskIds) => {
  try {  
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'POST',
      data: {
        taskId: taskIds,
      },
      url: `${API_URL}/task/bulk/printGuarantee/getUrl`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getBankSigners = async () => {
  try {  
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      url: `${API_URL}/crm/getBankSigners`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const assignPrintGuarantee = async (taskIds, userId) => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'POST',
      data: {
        taskId: taskIds, 
        username: userId,
      },
      url: `${API_URL}/task/bulk/printGuarantee/assign`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const completePrintGuarantee = async (taskIds) => {
  try {  
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'POST',
      data: {
        taskId: taskIds,
      },
      url: `${API_URL}/task/bulk/printGuarantee/complete`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getNextTask = async (taskId) => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      params: { taskId },
      url: `${API_URL}/task/getNext`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getNextProcessTask = async (processInstanceId) => {
  try {  
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      params: { processInstanceId },
      url: `${API_URL}/task/getNext`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}