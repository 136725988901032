import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import keycloak from '../keycloak'

const TOKEN_KEY = 'JWT'

export const setToken = (token) => Cookies.set(TOKEN_KEY, token, 365)
export const getToken = () => Cookies.get(TOKEN_KEY)
export const getTokenParsed = () => {
  const JWT = getToken()
  const token = JWT && JWT !== 'undefined' ? jwtDecode(JWT) : false
  return token
}
export const isValidToken = () => {
  const token = getTokenParsed()
  const isExpired = token ? new Date().getTime() > token.exp * 1000 : false
  const isBadJWT = !token || isExpired
  return !isBadJWT
}

export const onLogin = () => keycloak.login()
export const onLogout = () => {
  keycloak.logout()
  Cookies.remove(TOKEN_KEY)
}