import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  h4: {
    fontSize: 15,
    fontWeight: 600,
  },
})

export const H4 = ({ children, className }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.h4, className)}>{children}</div>
  )
}