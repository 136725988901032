import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d'
import HighchartsExporting from 'highcharts/modules/exporting'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { uas } from '../../../../services/utility/charts/uas'
import { FullScreenIcon } from '../../Layout'
import { useCommonStatusGraphicOptions } from './graphicOptions'
import { commonStatusStyles } from './styles'

highcharts3d(Highcharts)
HighchartsExporting(Highcharts)

const prepareData = response => {
  const data = { inTime: [], notInTime: [], unknown: [] }

  // eslint-disable-next-line no-unused-vars
  let s = 0
  for (let i = 0, l = response.length; i < l; i++) {
    let point = response[i]
    for (var j = 0, l2 = point.tasks.length; j < l2; j++) {
      let h = {
        x: parseInt(point.tasks[j].number),
        // x: s,
        y: uas.helpers.toRub(point.tasks[j].amount),
        z: point.tasks[j].credit,
        ext: {
          number: point.tasks[j].number,
          orderDate: point.tasks[j].orderDate,
          principalName: point.tasks[j].principalName,
          principalInn: point.tasks[j].principalInn,
          inProcessTime: point.tasks[j].inProcessTime,
          taskNorm: point.tasks[j].taskNorm,
          inTime: point.tasks[j].inTime,
          taskName: point.tasks[j].taskName,
          taskStartTime: point.tasks[j].taskStartTime,
          taskGroup: point.tasks[j].taskGroup,
          taskAssignee: point.tasks[j].taskAssignee,
        },
      }

      if (point.tasks[j].isActive === true) {
        s++
        if (point.tasks[j].inTime === true) {
          data.inTime.push(h)
        } else if (point.tasks[j].inTime === false) {
          data.notInTime.push(h)
        } else {
          data.unknown.push(h)
        }
      }
    }
  }

  return data
}

const handlers = []

const unbindAllHandlers = () => {
  handlers.forEach(({ event, handler }) => {
    document.removeEventListener(event, handler)
  })

  handlers.forEach(() => {
    handlers.pop()
  })
  handlers.pop()
}

export const CommonStatusGraphic = commonStatusStyles(
  ({ classes, data: startData, fullWidth, changeFullWidth }) => {
    const chartRef = useRef()
    const containerRef = useRef()

    const data = useMemo(
      () => prepareData(startData && startData.result ? startData.result : []),
      [startData]
    )

    const onDragStart = useCallback(
      eStart => {
        const chart = chartRef.current ? chartRef.current.chart : undefined

        if (!chart) {
          return
        }

        eStart = chart.pointer.normalize(eStart)
        var posX = eStart.chartX,
          posY = eStart.chartY,
          alpha = chart.options.chart.options3d.alpha,
          beta = chart.options.chart.options3d.beta,
          sensitivity = 5 // lower is more sensitive

        function drag(e) {
          // Get e.chartX and e.chartY
          e = chart.pointer.normalize(e)
          chart.update(
            {
              chart: {
                options3d: {
                  alpha: alpha + (e.chartY - posY) / sensitivity,
                  beta: beta + (posX - e.chartX) / sensitivity,
                },
              },
            },
            undefined,
            undefined,
            false
          )
        }

        document.addEventListener('mousemove', drag)
        document.addEventListener('touchmove', drag)
        document.addEventListener('mouseup', unbindAllHandlers)
        document.addEventListener('touchend', unbindAllHandlers)

        handlers.push({ event: 'mousemove', handler: drag })
        handlers.push({ event: 'touchmove', handler: drag })
        handlers.push({ event: 'mouseup', handler: unbindAllHandlers })
        handlers.push({ event: 'mouseup', handler: unbindAllHandlers })
      },
      [chartRef]
    )

    useEffect(() => {
      const container = containerRef.current
      if (container) {
        container.addEventListener('mousedown', onDragStart)
        container.addEventListener('touchstart', onDragStart)
      }

      return () => {
        if (container) {
          container.removeEventListener('mousedown', onDragStart)
          container.removeEventListener('touchstart', onDragStart)
        }
      }
    }, [containerRef, onDragStart])

    const options = useCommonStatusGraphicOptions({ data, fullWidth })

    return (
      <div ref={containerRef} className={classes.graphicContainer}>
        <FullScreenIcon fullWidth={fullWidth} onClick={changeFullWidth} />
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
    )
  }
)
