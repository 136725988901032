import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import {
  AnalysisReport,
  CommonStatusReport,
  FundsReport,
  FunnelReport,
  StandardsReport,
  StatisticsReport,
  StatisticsYMReport,
  StatisticsUTReport,
  TasksReport,
  PonyExpressReport,
} from './Tabs'
import { UI } from '../../layout'
import './charts.scss'

const useStyles = createUseStyles({
  container: {
    minWidth: 840,
    '@media (max-width: 970px)': {
      minWidth: 'auto',
    },
    '&.fullWidth': {
      minWidth: 'calc(100vw - 140px)',
    },
  },
  title: UI.h1,
  content: {
    marginTop: 20,
  },
  actions: {
    marginTop: 30,
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      flex: 0,
    },
  },
  add: {
    minWidth: 200,
    boxSizing: 'border-box',
    position: 'relative',
    '&:hover': {
      opacity: 0.8,
    },
    '&.loading': {
      cursor: 'default',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
    },
    '& > div': {
      borderRadius: 'inherit',
    },
  },
  cancel: {
    ...UI.cancelButton,
    marginRight: 20,
  },
})

export const REPORTS = {
  report: StatisticsReport,
  reportYM: StatisticsYMReport,
  reportUT: StatisticsUTReport,
  all3d: CommonStatusReport,
  common: TasksReport,
  allStages: AnalysisReport,
  normative: StandardsReport,
  funds: FundsReport,
  funnel: FunnelReport,
  ponyExpress: PonyExpressReport,
}

export const Reports = ({ onCancel }) => {
  const classes = useStyles()

  const [activeTab, setActiveTab] = useState('1')
  const [fullWidth, setFullWidth] = useState(false)

  const { process_definition_keys } = useSelector(state => state.Settings)

  const reportsSettings = useMemo(() => (process_definition_keys || [])
    .reduce((result, { reports, ...process }) => {
      const reportsArray = Object.keys(reports || {}).map(key => ({
        key,
        ...reports[key],
        Component: REPORTS[reports[key].type]
          ? REPORTS[reports[key].type]
          : null,
      }))
      const oldReports = result.map(currentItem => {
        const suitItem = reportsArray.filter(item => item.key === currentItem.key)[0]
        return !suitItem
          ? currentItem
          : { ...currentItem, process: [...currentItem.process, process] }
      })
      const newReports = reportsArray
        .filter(currentItem => result.filter(item => item.key === currentItem.key)[0] == null)
        .map(item => ({ ...item, process: [process] }))

      return [...oldReports, ...newReports]
    }, []),
    [process_definition_keys]
  )

  const changeFullWidth = () => setFullWidth(state => !state)
  const onChangeTab = (tab) => setActiveTab(tab)

  return (
    <div className={classnames(classes.container, { fullWidth })}>
      <div className={classes.title}>Отчеты</div>
      <Nav tabs>
        {reportsSettings.map(({ name }, index) =>
          <NavItem key={index}>
            <NavLink
              className={classnames({ active: activeTab === `${index + 1}` })}
              onClick={() => onChangeTab(`${index + 1}`)}
            >
              {name}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        {reportsSettings.map(({ Component, ...settings }, index) =>
          <TabPane tabId={`${index + 1}`} key={index}>
            {activeTab === `${index + 1}` &&
              <Component
                onCancel={onCancel}
                fullWidth={fullWidth}
                changeFullWidth={changeFullWidth}
                settings={settings}
              />
            }
          </TabPane>
        )}
      </TabContent>
    </div>
  )
}