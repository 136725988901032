import {
  GET_CHART_DATA_PENDING,
  GET_CHART_DATA_FULFILLED,
  GET_CHART_DATA_REJECTED,
} from './actionTypes'

import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'

import { Chart } from '../../services/api'

const getChartDataPending = () => ({
  type: GET_CHART_DATA_PENDING,
})

const getChartDataFulfilled = data => ({
  type: GET_CHART_DATA_FULFILLED,
  data,
})

const getChartDataRejected = error => ({
  type: GET_CHART_DATA_REJECTED,
  error,
})

export const getChartData = (period) => async (dispatch) => {
  try {
    dispatch(getChartDataPending())
    const { isSuccess, ...res } = await Chart.getChartData(period)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch(getChartDataFulfilled(res))
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch(getChartDataRejected(err))
  }
}