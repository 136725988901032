import React from 'react'
import { Link } from 'react-router-dom'
import { Modal } from '../index'

const AgentCompany = ({
  name,
  inn,
  kpp,
  companyId,
}) => 
  <Modal.Block iconName='shield' title='Агент'>
    <Modal.Content>
      <Modal.Title>ИНН / Наименование агента</Modal.Title>
      <Modal.Value>
        <Link to={`/orders?client-deal=${companyId}&section=home`} target='_blank' rel='noopener noreferrer'>{name}</Link>
        <p>ИНН {inn}, КПП {kpp}</p>
      </Modal.Value>
    </Modal.Content>
  </Modal.Block>

export default AgentCompany