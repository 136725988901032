import React from 'react'
import { Modal } from '../index'
import { formattedNumberRub } from '../../../utils'

const Commission = ({ commissionAmount }) => commissionAmount
  ? <Modal.Block iconName='shield' title='Сведения по изменению комиссии'>
      <Modal.Content>
        <Modal.Title>Сумма комиссии</Modal.Title>
        <Modal.Value>{formattedNumberRub(commissionAmount)} ₽</Modal.Value>
      </Modal.Content>
    </Modal.Block>
  : null

export default Commission