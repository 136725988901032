import React from 'react'
import { useDropzone } from 'react-dropzone'
import { FilesList } from './FilesList'
import './FileUploader.style.scss'

export function FileUploader({ setUploadFile, resetFiles }) {
  const onDrop = React.useCallback(
    acceptedFiles => setUploadFile(acceptedFiles),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv'
  })

  return (
    <div className="registry-upload-modal">
      <div
        className="registry-upload-modal__wrapper"
        {...getRootProps()}
        tabIndex="-1"
      >
        <div className="registry-upload-modal__title">Загрузите .CSV файл</div>
        <div className="registry-upload-modal__drop" id="drop-area">
          <form className="registry-upload-modal__form">
            <div className="registry-upload-modal__drop-img" />
            <input {...getInputProps()} />
            <label className="registry-upload-modal__btn">
              Загрузить CSV файл
            </label>
            <span className="registry-upload-modal__help">
              или добавьте файл сюда
            </span>
          </form>
        </div>
        {isDragActive && (
          <div className="uploader-drag-active">
            <div className="registry-upload-modal__title">
              Переместите файлы сюда
            </div>
          </div>
        )}
      </div>
      <FilesList />
    </div>
  )
}
