import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  buttonAdd: {
    color: '#0b1f35',
    fontSize: 12,
    lineHeight: 24/12,
    fontWeight: 600,
    cursor: 'pointer',
    '& > div': {
      background: '#0b1f35',
      width: 24,
      height: 24,
      color: '#fff',
      fontSize: 20,
      lineHeight: 24/20,
      textAlign: 'center',
      borderRadius: '50%',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& > span': {
      marginLeft: 8,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
})

export const ButtonAdd = ({
  className,
  children,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.buttonAdd, className)} {...rest}>
      <div>+</div>
      {children && 
        <span>{children}</span>
      }
    </div>
  )
}