import React, { Component } from 'react'
import { connect } from 'react-redux'
import ButtonMassActions from '../ButtonMassActions'
import Dropdown from '../Dropdown'
import { assignTasksToUser, getUserCandidatesByTasks } from '../../services/api/task'
import { setErrorContent } from '../../redux/Error/actions'
import { setAssigned } from '../../redux/Tasks/actions'
import { getSelectedTasks } from '../../redux/Tasks/selectors'
import Overlay from '../Overlay'

const INITIAL_LIST_CANDIDATES = [{ id: undefined, name: 'Выберите из списка' }]
const EMPTY_LIST_CANDIDATES = [{ id: undefined, name: 'Список кандидатов пуст' }]

export class AssigneeChange extends Component {
  state = { 
    userCandidates: INITIAL_LIST_CANDIDATES, 
    loading: false, 
  }

  async componentWillMount() {
    const { selectedTasks } = this.props
    const ids = selectedTasks
      .filter(item => item && item.bulkActions && item.bulkActions.includes('assigneeChange'))
      .map(item => item.id)

    try {
      this.setState({ loading: true })
      const { isSuccess, users = [] } = await getUserCandidatesByTasks(ids)

      if (isSuccess) {
        if (users.length > 0) {
          this.setState({
            userCandidates: [
              ...INITIAL_LIST_CANDIDATES,
              ...users.map(item => {
                return {
                  id: item.id,
                  name: item.fullname,
                }
              })
            ]
          })
        } else {
          this.setState({ userCandidates: EMPTY_LIST_CANDIDATES })
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      this.setState({ loading: false })
    }
  }

  onDropdownSelectItem = (dropdownName, itemId, itemIndex) => {
    const { userCandidates } = this.state
    const selectedItem = userCandidates[itemIndex]
    if (!!selectedItem.id) {
      this.setState({ selectedItem: userCandidates[itemIndex] })
    } else {
      this.setState({ selectedItem: undefined })
    }
  }

  onAssignTasksToUser = async () => {
    const { dispatch, selectedTasks } = this.props
    const ids = selectedTasks
      .filter(item => item && item.bulkActions && item.bulkActions.includes('assigneeChange'))
      .map(item => item.id)
    const { selectedItem } = this.state

    try {
      this.setState({ loading: true })
      const { isSuccess } = await assignTasksToUser(ids, selectedItem.id)
      if (isSuccess) {
        ids.forEach(taskId => dispatch(setAssigned(taskId, selectedItem.id)))
        // if (this.props.onUserSelected) {
        //   this.props.onUserSelected(selectedItem.id)
        // }
      } else {
        dispatch(setErrorContent('Не удалось назначить пользователя'))
      }
    } catch (err) {
      console.log(err)
      dispatch(setErrorContent(err))
    } finally {
      this.setState({ loading: false })
    }
  }

  onSubmit = async () => {
    await this.onAssignTasksToUser()
    if (this.props.onOk) {
      this.props.onOk()
    }
  }

  onClose = async () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    const { userCandidates, selectedItem, loading } = this.state

    if (loading) {
      return <Overlay size='big' transparent />
    }

    return (
      <form>
        <div className='restore-pass__title'>Назначить задачу на:</div>
        <div className='form-row'>
          <div className='col'>
            <div className='form-group'>
              <Dropdown
                list={userCandidates}
                onSelectItem={this.onDropdownSelectItem}
                placeholder='Выберите сотрудника'
                color
              />
            </div>
          </div>
        </div>
        <div className='form-row'>
          <div className='col'>
            <div className='form-group form-group__buttons-container'>
              <ButtonMassActions
                onClick={this.onSubmit}
                text='Назначить'
                disabled={!selectedItem}
              />
            </div>
          </div>
        </div>
        {/* <div className='d-flex form-group justify-content-end'>
          <button
            type='button'
            className='btn btn-white btn--min-width-100'
            onClick={this.onClose}
          >
            Нет
          </button>
          <button
            type='button'
            className='btn btn-white ml-2 btn--min-width-100'
            disabled={!selectedItem}
            onClick={this.onSubmit}
          >
            Да
          </button>
        </div> */}
      </form>
    )
  }
}

const mapStateToProps = ({ Bulk, Tasks }) => {
  const data = {
    _byIds: Tasks._byIds, 
    selectedTaskIds: Bulk.selectedItems,
  }
  return {
    selectedTasks: getSelectedTasks(data),
  }
}

export default connect(mapStateToProps)(AssigneeChange)