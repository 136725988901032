import Highcharts from 'highcharts'
import { useMemo } from 'react'
import { uas } from '../../../../services/utility/charts/uas'
import { useChartSize } from '../../useChartSize'

export const useStandardsGraphicOptions = ({ data, fullWidth, onClickBar }) => {
  const { width, height } = useChartSize({ fullWidth })

  const memoizedOptions = useMemo(() => {
    const response = data

    var series = [],
      x = 0,
      xValues = [],
      //   $container = $(selector),
      //   $chart = $('<div class="chart-area"></div>').appendTo(selector),
      //   isCorrupted = false,
      tasks_tooltip = []

    var i = 0,
      j = 0
    for (var num in response) {
      i++
      var tasks = response[num],
        seriesIdx = 0,
        opacity = 1

      if (num === 42) break

      xValues.push(i)

      for (var id in tasks) {
        var current,
          task = tasks[id]

        task.id = id
        task.custom_id = j
        j++

        // Time
        current = series[seriesIdx]

        if (!current) {
          current = series[seriesIdx] = {
            name: 'Время выполнения',
            type: 'column',
            data: [],
            color: new Highcharts.Color('#287782').setOpacity(opacity).get(),
            showInLegend: seriesIdx < 2,
            // visible: seriesIdx < 2,
            zIndex: 1,
          }
        }

        current.data.push({
          x: x,
          y: task.time,
          task: task,
          keys: [num, id],
          name_id: 'time',
        })

        seriesIdx++

        // Limit
        current = series[seriesIdx]

        if (!current) {
          current = series[seriesIdx] = {
            name: 'Средний норматив выполнения',
            type: 'spline',
            data: [],
            color: new Highcharts.Color('#FABA35').setOpacity(opacity).get(),
            marker: {
              symbol: 'circle',
              lineWidth: 1,
              lineColor: new Highcharts.Color('#FABA35')
                .setOpacity(opacity)
                .get(),
              fillColor: 'white',
            },
            lineWidth: 1,
            showInLegend: seriesIdx < 2,
            // visible: seriesIdx < 2,
            zIndex: 2,
          }
        }

        current.data.push({
          x: x,
          y: task.timeLimit,
          name_id: 'normative',
        })

        seriesIdx++

        opacity -= 0.3

        tasks_tooltip[task.custom_id] = task
      }

      x++
    }

    return {
      lang: {
        downloadPNG: 'Скачать PNG файл',
      },
      chart: {
        width,
        height,
        zoomType: 'x',
        spacing: [8, 32, 16, 32],
        events: {
          beforePrint: function () {
            this.update({
              title: {
                y: 12,
              },
            })
          },
          afterPrint: function () {
            // if (!$container.hasClass('fullscreen')) {
            //   this.update({
            //     title: {
            //       y: -32,
            //     },
            //   })
            // }
          },
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ['downloadPNG'],
          },
        },
        scale: 4,
        chartOptions: {
          title: {
            y: 12,
          },
          legend: {
            enabled: true,
            floating: false,
          },
        },
      },
      title: {
        text: 'Выполнение нормативов',
        y: -32,
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          events: {
            click: onClickBar,
          },
          cursor: 'pointer',
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          var output = [] // ['<span>Номер ' + xValues[this.x] + '</span>'];

          if (this.point.name_id === 'time') {
            var task = tasks_tooltip[this.point.task.custom_id]

            if (task) {
              output.push(
                '<div class="chart-task">' +
                  '<h5>' +
                  task.name +
                  '</h5>' +
                  '<table class="chart-tooltip">' +
                  '<tr>' +
                  '<th>Количество сделок прошедших этап</th>' +
                  '<td>' +
                  task.orderCount +
                  '</td>' +
                  '</tr>' +
                  '<tr>' +
                  '<th>Количество выполненных задач</th>' +
                  '<td>' +
                  task.taskCount +
                  '</td>' +
                  '</tr>' +
                  '<tr>' +
                  '<th>Среднее время выполнения</th>' +
                  '<td>' +
                  uas.helpers.timeFormat(
                    Math.round(task.time * 60),
                    '%Hч:%Mм'
                  ) +
                  '</td>' +
                  '</tr>' +
                  '<tr>' +
                  '<th>Норматив</th>' +
                  '<td>' +
                  uas.helpers.timeFormat(
                    Math.round(task.timeLimit * 60),
                    '%Hч:%Mм'
                  ) +
                  '</td>' +
                  '</tr>' +
                  '<th>Задач выполненных в срок</th>' +
                  '<td>' +
                  Math.round(task.inTimePrc) +
                  ' %</td>' +
                  '</tr>' +
                  '<tr>' +
                  '<th>Медиана</th>' +
                  '<td>' +
                  uas.helpers.timeFormat(
                    Math.round(task.median * 60),
                    '%Hч:%Mм'
                  ) +
                  '</td>' +
                  '</tr>' +
                  '</table>' +
                  '</div>'
              )
            }
          } else {
            // eslint-disable-next-line no-redeclare
            var output = [
              '<span>Номер: ' +
                (this.x + 1) +
                '</span><br><span>Средний норматив выполнения: ' +
                this.y +
                '</span>',
            ]
          }

          output.push('</table>')

          return output.join(' ')
        },
      },
      xAxis: {
        type: 'category',
        maxZoom: 1,
        labels: {
          formatter: function () {
            return xValues[this.value]
          },
        },
      },
      yAxis: {
        title: { text: 'Время' },
        labels: {
          formatter: function () {
            return uas.helpers.timeFormat(this.value * 60, '%Hч:%Mм')
          },
        },
        allowDecimals: false,
      },
      series: series,
    }
  }, [data, width, height, onClickBar])

  return memoizedOptions
}
