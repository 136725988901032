import { useMemo } from 'react'

export const useProcessSelectOptions = (process) => {
  const processOptions = useMemo(
    () => process.map(({ orderTypeRefId, process_name }) => ({
      id: orderTypeRefId,
      description: process_name,
    })),
    [process]
  )

  const processDefault = useMemo(
    () => processOptions[0] ? processOptions[0].id : undefined,
    [processOptions]
  )

  return {
    processOptions,
    processDefault,
  }
}
