import React, { Fragment, Component } from 'react'
import { Burger } from '../../components/Burger'
import ChartItem from '../../components/Chart/ChartItem'
import DatePicker from '../../components/DatePicker'
import HeaderFilter from './../../containers/HeaderFilter'
// import {
//   TableColumn,
//   TableRow,
//   TableResult
// } from '../../components/Chart/Table'

class Charts extends Component {
  state = {
    data: {
      labels: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24'
      ],
      datasets: [
        {
          fill: false,
          lineTension: 0.001,
          backgroundColor: '#9187ef',
          borderColor: '#9187ef',
          borderWidth: '1',
          borderDash: [5, 5.5],
          borderJoinStyle: 'miter',
          pointBorderColor: '#9187ef',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 0.25,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#9187ef',
          pointHoverBorderColor: '#9187ef',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
            20
          ],
          type: 'line'
        },
        {
          fill: false,
          lineTension: 0.001,
          backgroundColor: 'rgb(43, 195, 160)',
          borderColor: '#1f7fde',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#1f7fde',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#1f7fde',
          pointHoverBorderColor: '#1f7fde',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [
            110,
            90,
            120,
            115,
            110,
            140,
            180,
            160,
            120,
            130,
            110,
            120,
            180,
            150,
            90,
            140,
            120,
            130,
            160,
            120,
            160,
            90,
            160,
            140,
            60
          ]
        }
      ]
    },
    options: {
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            barPercentage: 0.5,
            display: true,
            gridLines: {
              display: false,
              drawBorder: false
            }
          }
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
      // tooltips: {
      //   callbacks: {
      //     label: (tooltipItem, data) => {
      //       console.log(tooltipItem, data)
      //       var label = data.datasets[tooltipItem.datasetIndex].label || '
      //       console.log(label)
      //     }
      //   }
      // }
    }
  }
  getCoordinatesData = elems => {
    var activePoints = elems
    if (activePoints[0]) {
      // var chartData = activePoints[0]['_chart'].config.data
      // var idx = activePoints[0]['_index']

      // var label = chartData.labels[idx]
      // var value = chartData.datasets[1].data[idx]
    }
  }
  render() {
    const { data, options } = this.state
    return (
      <Fragment>
        <Burger />
        <div className='container-chart'>
        <HeaderFilter>
          <DatePicker
            name='chartsCreateDate'
            onSelectDate={event => console.log(event)}
            onClear={event => console.log(event)}
          />
        </HeaderFilter>
          <div className='chart-title'>Нормативы</div>
          <div className='charts'>
            <ChartItem
              heading='Выдачи (млн. руб.)'
              legend={[
                { title: 'Норматив по задаче', color: 'chart__marker-green' },
                {
                  title: 'Среднее время исполнения задачи',
                  color: 'chart__marker-purple'
                }
              ]}
              chart='bar'
              data={data}
              options={options}
              getElementAtEvent={(elems, event) =>
                this.getCoordinatesData(elems)
              }
              isFullContainer
              // content={} // Lines
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Charts
