import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyOrders } from '../../../../redux/Company/actions'
import { ModalDeal } from '../../../Modals'
import { Filter, Stats, Table } from './layout'
import { getFilteredItems } from './utils'
import { GET_FILTERS, GET_TYPES, GET_STATUSES } from './config'

const useStyles = createUseStyles({
  root: {
    position: 'relative',
  },
  title: {
    marginBottom: 23,
    fontSize: 26,
    lineHeight: 1.2,
    fontWeight: 600,
    letterSpacing: 1,
  },
  loading: {
    textAlign: 'center',
    paddingTop: 30,
  },
  wrapper: {
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    overflowY: 'auto',
    maxHeight: `calc(100vh - 250px)`,
  },
  filters: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
  },
})

const Deals = ({ companyId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { orders, fetching } = useSelector(state => state?.Company?.orders) || {}
  
  const { list = [] } = orders || {}

  const [showDeal, setShowDeal] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [filters, setFilters] = useState([
    GET_TYPES(list),
    GET_STATUSES(list),
  ])

  const onOpenDeal = (orderId) => {
    setShowDeal(true)
    setOrderId(orderId)
  }

  const onCloseDeal = () => setShowDeal(false)

  useEffect(() => {
    dispatch(getCompanyOrders(companyId))
  }, [companyId, dispatch])

  useEffect(() => {
    if (list?.length > 0) {
      setFilters([
        GET_TYPES(list),
        GET_STATUSES(list)
      ])
    }
  }, [list])

  const onFilter = (value, index) => {
    const filtersByIndex = filters[index]
    const newFiltersByIndex = filtersByIndex.indexOf(value) === -1
      ? [...filtersByIndex, value]
      : filtersByIndex.filter(item => item !== value)
    const newFilters = filters.map((array, idx) => idx === index
      ? newFiltersByIndex
      : array
    )
    setFilters(newFilters)
  }

  const filteredItems = getFilteredItems(list, filters)

  return (
    <>
      {showDeal &&
        <ModalDeal
          orderId={orderId}
          companyId={companyId}
          onClose={onCloseDeal}
        />
      }
      <div className={classes.root}>
        <div className={classes.title}>Сделки</div>
        {fetching
          ? <div className={classes.loading}>Загрузка...</div>
          : <div>
              <div className={classes.filters}>
                {GET_FILTERS(list).map(({ placeholder, options }, index) =>
                  <Filter
                    key={index}
                    placeholder={placeholder}
                    list={list}
                    options={options}
                    filters={filters}
                    index={index}
                    onFilter={onFilter}
                  />
                )}
              </div>
              <div className={classes.wrapper}>
                <Table
                  items={filteredItems}
                  onClick={onOpenDeal}
                />
              </div>
              <Stats
                filtered={filteredItems.length}
                total={list.length}
              />
          </div>
        }
      </div>
    </>
  )
}

export default Deals