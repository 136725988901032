import React, { useState } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { InputText, Select1 } from '../../../../layout'
import { getReportCommonStatus } from '../../../../redux/Reports/CommonStatus/actions'
import { ReportTab } from '../../Layout'
import { useProcessSelectOptions } from '../../useProcessSelectOptions'
import { CommonStatusGraphic } from './CommonStatusGraphic'
import { commonStatusStyles } from './styles'
import { YYYYMMDD } from '../../../../utils'

export const CommonStatusReport = commonStatusStyles(({
  classes,
  onCancel,
  fullWidth,
  changeFullWidth,
  settings,
}) => {
    const dispatch = useDispatch()
    
    const now = new Date()
    const currentDate = YYYYMMDD(now)
    const firstDayOfMonth = YYYYMMDD(new Date(now.getFullYear(), now.getMonth(), 1))
    // const firstDayOfMonth = '2018-01-01'

    const { isFetching, data } = useSelector(state => state?.Reports?.CommonStatus)

    const { processOptions, processDefault } = useProcessSelectOptions(settings.process)

    const [process, setProcess] = useState(processDefault)
    const [dateFrom, setDateFrom] = useState(firstDayOfMonth)
    const [dateTo, setDateTo] = useState(currentDate)
    const [sent, setSent] = useState(false)

    const onChangeProcess = (e) => setProcess(e.target.value)
    const onChangeDateFrom = (e) => setDateFrom(e.target.value)
    const onChangeDateTo = (e) => setDateTo(e.target.value)

    const onGetReport = () => {
      setSent(true)
      dispatch(getReportCommonStatus({ process, dateFrom, dateTo }))
    }

    return (
      <ReportTab
        onOk={onGetReport}
        onCancel={onCancel}
        okButtonText='Получить график'
        disabled={false}
        loading={isFetching}
        title={settings.description}
      >
        <Col className={classnames(classes.form, { fullWidth })}>
          {processOptions.length > 1 &&
            <div className={classes.type}>
              <Select1
                name='process'
                onChange={onChangeProcess}
                defaultValue={process}
                options={processOptions}
              />
            </div>
          }
          <Row>
            <div className='col-6'>
              <InputText
                type='date'
                name='dateFrom'
                label='Дата с'
                value={dateFrom}
                maxDate={new Date(dateTo)}
                onChange={onChangeDateFrom}
              />
            </div>
            <div className='col-6'>
              <InputText
                type='date'
                name='dateTo'
                label='Дата по'
                value={dateTo}
                minDate={new Date(dateFrom)}
                onChange={onChangeDateTo}
              />
            </div>
          </Row>
        </Col>
        <div className={classes.content}>
          {sent &&
            (isFetching
              ? null
              : data?.result?.length > 0
                ? <CommonStatusGraphic
                    data={data}
                    fullWidth={fullWidth}
                    changeFullWidth={changeFullWidth}
                  />
                : <div className={classes.noData}>Нет данных</div>
            )
          }
        </div>
      </ReportTab>
    )
  }
)