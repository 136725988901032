import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col } from 'reactstrap'
import { InputText, Row, Select1 } from '../../../../layout'
import { getFile } from '../../../../redux/Files/actions'
import { ReportTab } from '../../Layout/ReportTab'
import { commonStatusStyles } from '../all3d/styles'
import { useProcessSelectOptions } from '../../useProcessSelectOptions'
import { YYYYMMDD } from '../../../../utils'

const defaultType = 'Статистика'
const typeOptions = [
  { id: defaultType, description: defaultType },
  { id: 'Распоряжение', description: 'Распоряжение' },
  { id: 'Отчет по выдачам гарантий', description: 'Отчет по выдачам гарантий' }
]

export const StatisticsReport = commonStatusStyles(({
  classes,
  url,
  onCancel,
  settings,
}) => {
  const dispatch = useDispatch()

  const now = new Date()
  const currentDate = YYYYMMDD(now)
  const firstDayOfMonth = YYYYMMDD(new Date(now.getFullYear(), now.getMonth(), 1))

  const { processOptions, processDefault } = useProcessSelectOptions(settings.process)

  const {
    REACT_APP_CAN_DOWNLOAD_CONSOLIDATE_ORDER_REPORT,
  } = useSelector(state => state?.Environment?.environment)

  const CAN_DOWNLOAD_CONSOLIDATE_ORDER_REPORT = REACT_APP_CAN_DOWNLOAD_CONSOLIDATE_ORDER_REPORT === 'true'

  const [type, setType] = useState(defaultType)
  const [process, setProcess] = useState(processDefault)
  const [dateFrom, setDateFrom] = useState(firstDayOfMonth)
  const [dateTo, setDateTo] = useState(currentDate)
  const [loading, setLoading] = useState(false)

  const onChangeProcess = (e) => setProcess(e.target.value)
  const onChangeDateFrom = (e) => setDateFrom(e.target.value)
  const onChangeDateTo = (e) => setDateTo(e.target.value)

  const valid = dateFrom && dateTo

  function getDownloadUrl(type, urlSubString) {
    let url = ''
    switch (type) {
      case 'Распоряжение':
        url = `/api/doc-template/form/bgStatsJournal/bg_consolidateOrder?process=${urlSubString}`
        break
      case 'Отчет по выдачам гарантий':
        url = `/api/doc-template/form/bgStatsJournal/bg_issuedDailyReport?process=${urlSubString}`
        break
      default:
        url = `${settings.url}?process=${urlSubString}`
    }
    return url
  }

  const onDownloadFile = () => {
    if (valid && !loading) {
      setLoading(true)
      const urlSubString = `${process}&dateFrom=${dateFrom}&dateTo=${dateTo}`
      const nameSubString = `${process}-${dateFrom}-${dateTo}.xlsx`
      const url = getDownloadUrl(type, urlSubString)
      let startIndex = url.lastIndexOf('bg_')
      if (startIndex === -1) {
        startIndex = url.lastIndexOf('rko_')
      }
      const endIndex = url.lastIndexOf('.xlsx')
      const reportName = url.substring(startIndex, endIndex)
      const name = `${reportName}-${nameSubString}`

      const onSuccess = () => setLoading(false)
      const onError = () => setLoading(false)
      dispatch(getFile({ url, name, onSuccess, onError }))
    }
  }

  const onChangeType = (e) => setType(e.target.value)

  return (
    <ReportTab
      onOk={onDownloadFile}
      onCancel={onCancel}
      okButtonText='Получить отчёт'
      disabled={!valid}
      loading={loading}
      title={settings.description}
    >
      <Col className={classes.form}>
        {CAN_DOWNLOAD_CONSOLIDATE_ORDER_REPORT &&
          <Select1
            name='type'
            onChange={onChangeType}
            defaultValue={defaultType}
            options={typeOptions}
            className={classes.mb20}
          />
        }
        {processOptions.length > 1 &&
          <div className={classes.type}>
            <Select1
              name='bankSignerId'
              onChange={onChangeProcess}
              defaultValue={process}
              options={processOptions}
            />
          </div>
        }
        <Row>
          <div className='col-6'>
            <InputText
              type='date'
              name='dateFrom'
              label='Дата с'
              value={dateFrom}
              maxDate={new Date(dateTo)}
              onChange={onChangeDateFrom}
            />
          </div>
          <div className='col-6'>
            <InputText
              type='date'
              name='dateTo'
              label='Дата по'
              value={dateTo}
              minDate={new Date(dateFrom)}
              onChange={onChangeDateTo}
            />
          </div>
        </Row>
      </Col>
    </ReportTab>
  )
})