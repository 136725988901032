import React, { useState } from 'react'
import injectSheet from 'react-jss'
import { Tooltip } from 'reactstrap'

const styles = {
  tooltip: {
    '& > div': {
      whiteSpace: 'nowrap',
      zIndex: 99999,
    },
    '& .tooltip-inner': {
      maxWidth: 'none',
    },
  },
}

const InstallmentTooltip = ({
  classes,
  id,
  value,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const onMakeTooltip = () => setTooltipOpen(!tooltipOpen)
  return (
    <Tooltip
      placement='top'
      isOpen={tooltipOpen}
      target={id}
      toggle={onMakeTooltip}
      className={classes.tooltip}
      fade={false}
    >
      {value}
    </Tooltip>
  )
}

export default injectSheet(styles)(InstallmentTooltip)