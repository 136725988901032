import React, { useEffect, useState, useMemo, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFactoringTasks,
  getFactoringPaymentOrders,
  // getFactoringMatchingsByTaskId,
  getFactoringMatchingsByPaymentId,
  getFactoringOperDays,
  resetFactoringPaymentOrders,
  resetFactoringMatchingsByPaymentId,
} from '../../../redux/Factoring/actions'
import { MatchingPaymentModal } from './MatchingPaymentModal'
import { RedirectToWoSupplyTransitModal } from './RedirectToWoSupplyTransitModal'
import { UnexplainedModal } from './UnexplainedModal'
import { NonDeliverableTransitModal } from './NonDeliverableTransitModal'
import { ReturnedModal } from './ReturnedModal'
import { FactoringWrapper } from '../FactoringWrapper'
import { useFactoring } from '../useFactoring'
import { ERRORS } from '../../../config'
import { useStyles } from './styles'
import { Orders } from './Orders'
import { Drafts } from './Drafts'
import { TABS, FILTERS, ORDER_TYPES, DEFAULT_ORDER_FILTERS } from './config'
import { Tasks } from './Tasks'
import { OrdersFilters } from './OrdersFilters'
import { OperDays } from './OperDays'
import { Actions } from './Actions'

export const FactoringMatchingPaymentsPage = memo(() => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const entityType = 'payment_matching'

  const {
    dataItems,
    dataFilters,
  } = useFactoring({ entityType })

  const {
    tasks,
    operDays,
    paymentOrders,
    paymentMatchings,
    paymentComissions,
    paymentReplications,
  } = useSelector(state => state?.Factoring || {})

  const [selectedTasks, setSelectedTasks] = useState([])
  const [queue, setQueue] = useState([])
  const [tab, setTab] = useState(TABS[0])
  const [order, setOrder] = useState(null)
  const [ordersQueue, setOrdersQueue] = useState([])
  const [drafts, setDrafts] = useState([...paymentMatchings])
  const [orders, setOrders] = useState([...paymentOrders])
  const [comissions, setComissions] = useState([...paymentComissions])
  const [taskFilters, setTaskFilters] = useState({})
  const [orderFilters, setOrderFilters] = useState({...DEFAULT_ORDER_FILTERS})
  const [values, setValues] = useState({...DEFAULT_ORDER_FILTERS})
  const [showUnexplainedModal, setShowUnexplainedModal] = useState(false)
  const [showReturnedModal, setShowReturnedModal] = useState(false)
  const [showNonDeliverableTransitModal, setShowNonDeliverableTransitModal] = useState(false)
  const [showRedirectToWoSupplyTransitModal, setShowRedirectToWoSupplyTransitModal] = useState(false)
  const [refetch, setRefetch] = useState(0)
  const [isDraft, setIsDraft] = useState(false)
  const [searched, setSearched] = useState(0)
  const [allChecked, setAllChecked] = useState(false)
  const [error, setError] = useState('')
  const [autoDistributePayment, setAutoDistributePayment] = useState(false)
  const [allDirectPayment, setAllDirectPayment] = useState(false)
  const [allWoLeftovers, setAllWoLeftovers] = useState(false)
  const [firstSelectedOrders, setFirstSelectedOrders] = useState({})
  const [firstSelectedOrdersType, setFirstSelectedOrdersType] = useState('')

  const disabledOrders = selectedTasks?.length > 1

  const { payment, issues } = selectedTasks?.length === 1 ? selectedTasks[0]?.data : {}

  const { id: paymentId, purpose } = payment || {}

  const payments = selectedTasks?.reduce((result, item) => [...result, item?.data?.payment], [])

  const getFilters = (filters) => setTaskFilters(filters)

  const isPaymentReplications = tab === 'Репликация квитовок'

  const onSearch = useCallback(() => setSearched(searched + 1), [searched])

  const ORDER_FILTERS = useMemo(() => ({
    'Непогашенные поставки': [
      FILTERS.number,
      FILTERS.supplierInn,
      FILTERS.debtorInn,
      FILTERS.factoringContractId,
      FILTERS.amountFrom,
      FILTERS.amountTo,
      FILTERS.dateFrom,
      FILTERS.dateTo,
      { ...FILTERS.search, onClickButton: onSearch },
    ],
    'Уведомления по комиссии': [
      FILTERS.number,
      { ...FILTERS.supplierInn, placeholder: 'Плательщик' },
      FILTERS.factoringContractId,
      FILTERS.amountFrom,
      FILTERS.amountTo,
      FILTERS.dateFrom,
      FILTERS.dateTo,
      { ...FILTERS.search, onClickButton: onSearch },
    ],
    'Репликация квитовок': []
  }), [onSearch])

  const ACTIONS = useMemo(() => ({
    'Непогашенные поставки': () => {
      dispatch(getFactoringPaymentOrders({
        filters: orderFilters,
        orderType: [ORDER_TYPES['Непогашенные поставки']],
      }))
    },
    'Уведомления по комиссии': () => {
      let commissionNotificationFilters = { ...orderFilters }
      for (const key in commissionNotificationFilters) {
        if (!ORDER_FILTERS['Уведомления по комиссии']?.find(item => item?.name === key)) {
          delete commissionNotificationFilters[key]
        }
      }
      dispatch(getFactoringPaymentOrders({
        filters: commissionNotificationFilters,
        orderType: [ORDER_TYPES['Уведомления по комиссии']],
      }))
    },
    'Репликация квитовок': () => {
      paymentId && dispatch(getFactoringMatchingsByPaymentId(paymentId))
    },
  // eslint-disable-next-line
  }), [ORDER_FILTERS, /*paymentId, orderFilters,*/ dispatch])

  useEffect(() => {
    if (searched > 0) {
      ACTIONS[tab]()
    }
  }, [ACTIONS, tab, searched])

  useEffect(() => {
    if (searched > 0) {
      ACTIONS['Уведомления по комиссии']()
    }
  }, [ACTIONS, searched])

  useEffect(() => {
    if (searched > 0) {
      ACTIONS['Репликация квитовок']()
    }
  }, [ACTIONS, searched])

  useEffect(() => {
    dispatch(getFactoringOperDays())
  }, [dispatch])

  // useEffect(() => {
  //   if (taskId) {
  //    dispatch(getFactoringMatchingsByTaskId(taskId))
  //   }
  // }, [taskId, dispatch])

  useEffect(() => {
    setDrafts([...paymentMatchings])
  }, [paymentMatchings])

  useEffect(() => {
    const newPaymentOrders = [
      ...paymentOrders.filter(payment => drafts.length > 0
        ? !drafts.find(draft => draft.order.id === payment.id)
        : true
      )
    ]
    setOrders(newPaymentOrders)
  }, [paymentOrders, drafts])

  useEffect(() => {
    const newPaymentComissions = [
      ...paymentComissions.filter(comission => drafts.length > 0
        ? !drafts.find(draft => draft.order.id === comission.id)
        : true
      )
    ]
    setComissions(newPaymentComissions)
  }, [paymentComissions, drafts])

  useEffect(() => {
    if (error === ERRORS.REDIRECT_TO_WO_SUPPLY_TRANSIT) {
      onOpenRedirectToWoSupplyTransitModal()
      setError('')
    }
  }, [error])

  useEffect(() => {
    setDrafts([])
    dispatch(resetFactoringPaymentOrders())
    dispatch(resetFactoringMatchingsByPaymentId())
    setAllDirectPayment(false)
    setAllWoLeftovers(false)
  }, [selectedTasks, dispatch])

  const onSelectTask = (task) => {
    const {
      id,
      payerInn,
      debtorInn,
      debtorName,
      supplierInn,
      supplierName,
      factoringContractId,
      factoringContractNumber,
    } = task?.data?.payment
    const canPrefilledSupplierInn = payerInn !== supplierInn
    const canPrefilledDeptorInn = payerInn === debtorInn
    const taskIsset = selectedTasks?.find(item => item?.data?.payment?.id === id)
    const newSelectedTasks = taskIsset
      ? selectedTasks.filter(item => item?.data?.payment?.id !== taskIsset?.data?.payment?.id)
      : [...selectedTasks, task]
    setSelectedTasks(newSelectedTasks)
    if (id === paymentId) {
      setDrafts([])
    }
    if (newSelectedTasks?.length === 1) {
      setOrderFilters({
        ...orderFilters,
        ...(canPrefilledSupplierInn ? { supplierInn: orderFilters?.supplierInn || supplierInn || '' } : {}),
        ...(canPrefilledDeptorInn ? { debtorInn: orderFilters?.debtorInn || debtorInn || '' } : {}),
        factoringContractId: orderFilters?.factoringContractId || factoringContractId || '',
      })
      setValues({
        ...values,
        ...(canPrefilledSupplierInn ? { supplierInn: values?.supplierInn || supplierName || '' } : {}),
        ...(canPrefilledDeptorInn ? { debtorInn: values?.debtorInn || debtorName || '' } : {}),
        factoringContractId: values?.factoringContractId || factoringContractNumber || '',
      })
    } else {
      setOrderFilters({...DEFAULT_ORDER_FILTERS})
      setValues({...DEFAULT_ORDER_FILTERS})
    }
  }

  const onSelectAllTasks = () => {
    const _allChecked = !allChecked
    const newSelectedTasks = tasks?.items?.reduce((result, task) => _allChecked ? [...result, task] : [], [])
    setAllChecked(_allChecked)
    setSelectedTasks(newSelectedTasks)
  }

  const onChangeType = (type) => setTab(type)

  const onSelectOrder = (payload) => setOrder(order?.id && order?.id === payload?.id ? null : payload)

  const onSelectOrdersQueue = (payload) => setOrdersQueue((prev) => {
    const _order = prev?.find((item) => item?.id === payload?.id)
    return _order
      ? prev?.filter((item) => item?.id !== payload?.id)
      : [...prev, payload]
  })

  const onResetOrder = () => {
    setOrder(null)
    setIsDraft(false)
  }

  const onResetAutoDistributePayment = () => {
    setOrdersQueue([])
    setAutoDistributePayment(false)
  }

  const onOpenReturnedModal = () => setShowReturnedModal(true)
  const onCloseReturnedModal = () => setShowReturnedModal(false)

  const onOpenUnexplainedModal = () => setShowUnexplainedModal(true)
  const onCloseUnexplainedModal = () => setShowUnexplainedModal(false)

  const onOpenNonDeliverableTransitModal = () => setShowNonDeliverableTransitModal(true)
  const onCloseNonDeliverableTransitModal = () => setShowNonDeliverableTransitModal(false)

  const onOpenRedirectToWoSupplyTransitModal = () =>  setShowRedirectToWoSupplyTransitModal(true)
  const onCloseRedirectToWoSupplyTransitModal = () => setShowRedirectToWoSupplyTransitModal(false)

  const onAddOrEditDraft = (payload) => {
    const isEdit = payload?.order?.id && drafts.some(item => item?.order?.id === payload?.order?.id)
    setDrafts((prev) => isEdit
      ? prev.map(item => item?.order?.id === payload?.order?.id ? payload : item)
      : [...prev, payload]
    )
    setOrders((prev) => prev?.filter(item => item?.id !== payload?.order?.id))
    setComissions((prev) => prev?.filter(item => item?.id !== payload?.order?.id))
  }

  useEffect(() => {
    if (
      autoDistributePayment &&
      drafts?.length > 0 &&
      ordersQueue?.length > 0 &&
      ordersQueue?.every((order) => drafts?.find((draft) => draft?.order?.id === order?.id))
    ) {
      onResetAutoDistributePayment()
    }
  }, [autoDistributePayment, drafts, ordersQueue, setOrdersQueue])

  const onAutoDistributePayment = () => setAutoDistributePayment(true)

  const onAddDrafts = (array) => setDrafts([...drafts, ...array])

  const onRemoveDraft = (payload) => {
    setDrafts(payload?.order?.externalEntity?.id
      ? drafts.filter(item => item?.order?.externalEntity?.id !== payload?.order?.externalEntity?.id)
      : drafts.filter(item => item?.paymentId !== payload?.paymentId)
    )
    setOrders([payload.order, ...orders])
    setComissions([payload.order, ...comissions])
  }

  const onResetSelect = () => {
    setSelectedTasks([])
    setDrafts([])
  }

  const requestPayload = {
    taskDefinitionKey: 'UserTaskPaymentManualMatching',
    type: 'PAYMENT',
  }

  const matchingModalProps = {
    tab,
    isDraft,
    drafts,
    payment,
    setOrder,
    setError,
    autoDistributePayment,
    onAddOrEditDraft,
    onClose: onResetOrder,
    allDirectPayment,
    setAllDirectPayment
  }

  const selectedNonDeliverableTransit = selectedTasks?.map(item => {
    if (item?.data?.payment?.product === 'Безрегресс') {
      return item?.data?.payment?.factoringContractId
    }
    return null
  }).filter(item => item)

  useEffect(() => {
    setDrafts(drafts.map(item => {
      return {...item, woLeftovers: !!allWoLeftovers}
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWoLeftovers])

  useEffect(() => {
    setFirstSelectedOrders(ordersQueue[0])
  }, [ordersQueue])

  useEffect(() => {
    if(selectedNonDeliverableTransit?.length > 0 && !!firstSelectedOrders) {
      const typeTransit = firstSelectedOrders?.externalEntity?.registrationDate === null ? 'NonDeliverableTransit' : 'DeliverableTransit'
      setFirstSelectedOrdersType(typeTransit)
    } else {
      setFirstSelectedOrdersType('')
    }
  }, [firstSelectedOrders, ordersQueue, selectedNonDeliverableTransit])

  const isDisabledOrdersRow = (item) => {
    if (selectedNonDeliverableTransit?.length > 0 && firstSelectedOrdersType) {
      if(firstSelectedOrdersType === 'NonDeliverableTransit' && item?.externalEntity?.registrationDate !== null) {
        return true
      }
      if (firstSelectedOrdersType === 'DeliverableTransit' && item?.externalEntity?.registrationDate === null) {
        return true
      }
    }
  }

  return (
    <>
      <FactoringWrapper
        title='Квитовка платежей'
        dataFilters={dataFilters}
        entityType={entityType}
        getFilters={getFilters}
        onRequest={getFactoringTasks}
        requestPayload={requestPayload}
        refetch={refetch}
        withoutPagination
        additionalInfo={<OperDays operDays={operDays} />}
      >
        <Tasks
          tasks={tasks}
          issues={issues}
          purpose={purpose}
          paymentId={paymentId}
          allChecked={allChecked}
          dataItems={dataItems}
          selectedTasks={selectedTasks}
          disabledOrders={disabledOrders}
          onSelectTask={onSelectTask}
          onSelectAllTasks={onSelectAllTasks}
          onOpenReturnedModal={onOpenReturnedModal}
          onOpenUnexplainedModal={onOpenUnexplainedModal}
          onOpenNonDeliverableTransitModal={onOpenNonDeliverableTransitModal}
        />
        <div className={classes.container}>
          {ORDER_FILTERS[tab]?.length > 0 &&
            <OrdersFilters
              tab={tab}
              values={values}
              setValues={setValues}
              orderFilters={orderFilters}
              setOrderFilters={setOrderFilters}
              ORDER_FILTERS={ORDER_FILTERS}
            />
          }
          <Orders
            tab={tab}
            order={order}
            searched={searched}
            orders={orders}
            payment={payment}
            drafts={drafts}
            comissions={comissions}
            paymentReplications={paymentReplications}
            disabledOrders={disabledOrders}
            isDisabledOrdersRow={isDisabledOrdersRow}
            onChangeType={onChangeType}
            ordersQueue={ordersQueue}
            onSelectOrdersQueue={onSelectOrdersQueue}
            isPaymentReplications={isPaymentReplications}
            selectedNonDeliverableTransit={selectedNonDeliverableTransit}
            allWoLeftovers={allWoLeftovers}
            setAllWoLeftovers={setAllWoLeftovers}
            allDirectPayment={allDirectPayment}
            setAllDirectPayment={setAllDirectPayment}
            firstSelectedOrdersType={firstSelectedOrdersType}
          />
          {searched > 0 &&
            <Drafts
              tab={tab}
              drafts={drafts}
              setIsDraft={setIsDraft}
              onRemoveDraft={onRemoveDraft}
              onSelectOrder={onSelectOrder}
              isPaymentReplications={isPaymentReplications}
            />
          }
          <Actions
            drafts={drafts}
            queue={queue}
            setQueue={setQueue}
            refetch={refetch}
            setRefetch={setRefetch}
            ordersQueue={ordersQueue}
            selectedTasks={selectedTasks}
            onAutoDistributePayment={onAutoDistributePayment}
            onResetSelect={onResetSelect}
            isPaymentReplications={isPaymentReplications}
            firstSelectedOrdersType={firstSelectedOrdersType}
          />
        </div>
      </FactoringWrapper>
      {paymentId &&
        order &&
        !autoDistributePayment &&
        !showNonDeliverableTransitModal &&
        !showRedirectToWoSupplyTransitModal &&
          <MatchingPaymentModal
            order={order}
            {...matchingModalProps}
          />
      }
      {paymentId &&
        ordersQueue?.length > 0 &&
        autoDistributePayment &&
        !showNonDeliverableTransitModal &&
        !showRedirectToWoSupplyTransitModal
          ? ordersQueue?.map((order) =>
              <MatchingPaymentModal
                key={order?.id}
                order={order}
                {...matchingModalProps}
              />
            )
          : null
      }
      {showRedirectToWoSupplyTransitModal &&
        <RedirectToWoSupplyTransitModal
          onResetOrder={onResetOrder}
          onResetAutoDistributePayment={onResetAutoDistributePayment}
          onClose={onCloseRedirectToWoSupplyTransitModal}
          onOpenNonDeliverableTransitModal={onOpenNonDeliverableTransitModal}
        />
      }
      {showUnexplainedModal &&
        <UnexplainedModal
          payments={payments}
          payment={payment}
          onAddDraft={onAddOrEditDraft}
          onAddDrafts={onAddDrafts}
          onClose={onCloseUnexplainedModal}
        />
      }
      {showReturnedModal &&
        <ReturnedModal
          payment={payment}
          onAddDraft={onAddOrEditDraft}
          onClose={onCloseReturnedModal}
        />
      }
      {showNonDeliverableTransitModal &&
        <NonDeliverableTransitModal
          ordersQueue={ordersQueue}
          payment={payment}
          taskFilters={taskFilters}
          requestPayload={requestPayload}
          onClose={() => {
            onCloseNonDeliverableTransitModal()
            onResetOrder()
            onResetAutoDistributePayment()
          }}
          onResetOrder={onResetOrder}
        />
      }
    </>
  )
})