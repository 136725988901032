import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import TableTitle from './TableTitle'

const TableColumn = ({
  heading, 
  size, 
  head, 
  body,
}) => 
  <div className='table-column'>
    <TableTitle>{heading}</TableTitle>
    <table className={classnames('table-charts', `table-${size}`)}>
      <thead>
        <tr>
          {head.map((item, index) =>
            <td key={index}>{item}</td>
          )}
        </tr>
      </thead>
      <tbody>
        {body.map(row =>
          <tr className='table-charts__row' key={row.id}>
            {row.values.map(({ color, content }, col_index) =>
              <td className={color} key={col_index}>
                {content}
              </td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  </div>

TableColumn.propTypes = {
  heading: PropTypes.string.isRequired,
  size: PropTypes.string,
  head: PropTypes.array.isRequired,
  body: PropTypes.array.isRequired,
  // body: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     id: PropTypes.number.isRequired,
  //     values: PropTypes.shape({
  //       content: PropTypes.string.isRequired,
  //       color: PropTypes.oneOf([PropTypes.string, null]),
  //     }),
  //   }),
  // ),
}

TableColumn.defaultProps = {
  size: 'l', // l или s
}

export default TableColumn