import * as types from './actionTypes'

const initialState = {
  isFetching: false,
  filters: {},
  data: [],
  isFetchingDetails: false,
  details: {},
  tasks: [],
  isFetchingTasks: false,
}

export const TasksReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORT_TASKS_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case types.GET_REPORT_TASKS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
      }
    case types.GET_REPORT_TASKS_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    case types.GET_REPORT_TASKS_DETAILS_PENDING:
      return {
        ...state,
        isFetchingDetails: true,
      }
    case types.GET_REPORT_TASKS_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [action.data.params.name]: action.data.result,
        },
        isFetchingDetails: false,
      }
    case types.GET_REPORT_TASKS_DETAILS_ERROR:
      return {
        ...state,
        isFetchingDetails: false,
      }
    case types.REPORT_TASKS_CLEAR_DETAILS:
      return {
        ...state,
        details: {},
        isFetchingDetails: false,
      }
    case types.GET_REPORT_TASKS_TASKS_PENDING:
      return {
        ...state,
        isFetchingTasks: true,
      }
    case types.GET_REPORT_TASKS_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.data,
        isFetchingTasks: false,
      }
    case types.GET_REPORT_TASKS_TASKS_ERROR:
      return {
        ...state,
        isFetchingTasks: false,
      }
    default:
      return state
  }
}
