export const GET_FACTORING_TASKS = 'GET_FACTORING_TASKS'
export const GET_FACTORING_TASKS_SUCCESS = 'GET_FACTORING_TASKS_SUCCESS'
export const GET_FACTORING_TASKS_ERROR = 'GET_FACTORING_TASKS_ERROR'

export const GET_FACTORING_TASKS_2 = 'GET_FACTORING_TASKS_2'
export const GET_FACTORING_TASKS_SUCCESS_2 = 'GET_FACTORING_TASKS_SUCCESS_2'
export const GET_FACTORING_TASKS_ERROR_2 = 'GET_FACTORING_TASKS_ERROR_2'

export const COMPLETE_FACTORING_TASK = 'COMPLETE_FACTORING_TASK'
export const COMPLETE_FACTORING_TASK_SUCCESS = 'COMPLETE_FACTORING_TASK_SUCCESS'
export const COMPLETE_FACTORING_TASK_ERROR = 'COMPLETE_FACTORING_TASK_ERROR'

export const GET_FACTORING_VIEW = 'GET_FACTORING_VIEW'
export const GET_FACTORING_VIEW_SUCCESS = 'GET_FACTORING_VIEW_SUCCESS'
export const GET_FACTORING_VIEW_ERROR = 'GET_FACTORING_VIEW_ERROR'

export const GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN = 'GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN'
export const GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN_SUCCESS = 'GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN_SUCCESS'
export const GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN_ERROR = 'GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN_ERROR'

export const GET_FACTORING_SHORT_INFO = 'GET_FACTORING_SHORT_INFO'
export const GET_FACTORING_SHORT_INFO_SUCCESS = 'GET_FACTORING_SHORT_INFO_SUCCESS'
export const GET_FACTORING_SHORT_INFO_ERROR = 'GET_FACTORING_SHORT_INFO_ERROR'

export const SEND_FACTORING_MESSAGE = 'SEND_FACTORING_MESSAGE'
export const SEND_FACTORING_MESSAGE_SUCCESS = 'SEND_FACTORING_MESSAGE_SUCCESS'
export const SEND_FACTORING_MESSAGE_ERROR = 'SEND_FACTORING_MESSAGE_ERROR'

export const GET_FACTORING_MESSAGES = 'GET_FACTORING_MESSAGES'
export const GET_FACTORING_MESSAGES_SUCCESS = 'GET_FACTORING_MESSAGES_SUCCESS'
export const GET_FACTORING_MESSAGES_ERROR = 'GET_FACTORING_MESSAGES_ERROR'

export const CREATE_FACTORING_LIMIT = 'CREATE_FACTORING_LIMIT'
export const CREATE_FACTORING_LIMIT_SUCCESS = 'CREATE_FACTORING_LIMIT_SUCCESS'
export const CREATE_FACTORING_LIMIT_ERROR = 'CREATE_FACTORING_LIMIT_ERROR'

export const CREATE_FACTORING_RATE = 'CREATE_FACTORING_RATE'
export const CREATE_FACTORING_RATE_SUCCESS = 'CREATE_FACTORING_RATE_SUCCESS'
export const CREATE_FACTORING_RATE_ERROR = 'CREATE_FACTORING_RATE_ERROR'

export const CREATE_FACTORING_BLOCK = 'CREATE_FACTORING_BLOCK'
export const CREATE_FACTORING_BLOCK_SUCCESS = 'CREATE_FACTORING_BLOCK_SUCCESS'
export const CREATE_FACTORING_BLOCK_ERROR = 'CREATE_FACTORING_BLOCK_ERROR'

export const CREATE_FACTORING_ACCOUNT = 'CREATE_FACTORING_ACCOUNT'
export const CREATE_FACTORING_ACCOUNT_SUCCESS = 'CREATE_FACTORING_ACCOUNT_SUCCESS'
export const CREATE_FACTORING_ACCOUNT_ERROR = 'CREATE_FACTORING_ACCOUNT_ERROR'

export const CREATE_FACTORING_OPERATION = 'CREATE_FACTORING_OPERATION'
export const CREATE_FACTORING_OPERATION_SUCCESS = 'CREATE_FACTORING_OPERATION_SUCCESS'
export const CREATE_FACTORING_OPERATION_ERROR = 'CREATE_FACTORING_OPERATION_ERROR'

export const CREATE_FACTORING_PAYMENT = 'CREATE_FACTORING_PAYMENT'
export const CREATE_FACTORING_PAYMENT_SUCCESS = 'CREATE_FACTORING_PAYMENT_SUCCESS'
export const CREATE_FACTORING_PAYMENT_ERROR = 'CREATE_FACTORING_PAYMENT_ERROR'

export const START_FACTORING_PROCESS = 'START_FACTORING_PROCESS'
export const START_FACTORING_PROCESS_SUCCESS = 'START_FACTORING_PROCESS_SUCCESS'
export const START_FACTORING_PROCESS_ERROR = 'START_FACTORING_PROCESS_ERROR'

export const START_FACTORING_PROCESS_TRANSIT = 'START_FACTORING_PROCESS_TRANSIT'
export const START_FACTORING_PROCESS_TRANSIT_SUCCESS = 'START_FACTORING_PROCESS_TRANSIT_SUCCESS'
export const START_FACTORING_PROCESS_TRANSIT_ERROR = 'START_FACTORING_PROCESS_TRANSIT_ERROR'

export const START_FACTORING_PROCESS_CONTRACT_PREPARATION = 'START_FACTORING_PROCESS_CONTRACT_PREPARATION'
export const START_FACTORING_PROCESS_CONTRACT_PREPARATION_SUCCESS = 'START_FACTORING_PROCESS_CONTRACT_PREPARATION_SUCCESS'
export const START_FACTORING_PROCESS_CONTRACT_PREPARATION_ERROR = 'START_FACTORING_PROCESS_CONTRACT_PREPARATION_ERROR'

export const START_FACTORING_PROCESS_SUPPLY_UPDATE = 'START_FACTORING_PROCESS_SUPPLY_UPDATE'
export const START_FACTORING_PROCESS_SUPPLY_UPDATE_SUCCESS = 'START_FACTORING_PROCESS_SUPPLY_UPDATE_SUCCESS'
export const START_FACTORING_PROCESS_SUPPLY_UPDATE_ERROR = 'START_FACTORING_PROCESS_SUPPLY_UPDATE_ERROR'

export const GET_FACTORING_REF_DOCUMENTS = 'GET_FACTORING_REF_DOCUMENTS'
export const GET_FACTORING_REF_DOCUMENTS_SUCCESS = 'GET_FACTORING_REF_DOCUMENTS_SUCCESS'
export const GET_FACTORING_REF_DOCUMENTS_ERROR = 'GET_FACTORING_REF_DOCUMENTS_ERROR'

export const GET_FACTORING_DEFER_SETTINGS = 'GET_FACTORING_DEFER_SETTINGS'
export const GET_FACTORING_DEFER_SETTINGS_SUCCESS = 'GET_FACTORING_DEFER_SETTINGS_SUCCESS'
export const GET_FACTORING_DEFER_SETTINGS_ERROR = 'GET_FACTORING_DEFER_SETTINGS_ERROR'

export const GET_FACTORING_REF_OPERATION_CODE_TYPES = 'GET_FACTORING_REF_OPERATION_CODE_TYPES'
export const GET_FACTORING_REF_OPERATION_CODE_TYPES_SUCCESS = 'GET_FACTORING_REF_OPERATION_CODE_TYPES_SUCCESS'
export const GET_FACTORING_REF_OPERATION_CODE_TYPES_ERROR = 'GET_FACTORING_REF_OPERATION_CODE_TYPES_ERROR'

export const GET_FACTORING_DEBTORS = 'GET_FACTORING_DEBTORS'
export const GET_FACTORING_DEBTORS_SUCCESS = 'GET_FACTORING_DEBTORS_SUCCESS'
export const GET_FACTORING_DEBTORS_ERROR = 'GET_FACTORING_DEBTORS_ERROR'

export const GET_FACTORING_DUPLICATE_SETTINGS = 'GET_FACTORING_DUPLICATE_SETTINGS'
export const GET_FACTORING_DUPLICATE_SETTINGS_SUCCESS = 'GET_FACTORING_DUPLICATE_SETTINGS_SUCCESS'
export const GET_FACTORING_DUPLICATE_SETTINGS_ERROR = 'GET_FACTORING_DUPLICATE_SETTINGS_ERROR'

export const GET_FACTORING_PRICE_MATRIX = 'GET_FACTORING_PRICE_MATRIX'
export const GET_FACTORING_PRICE_MATRIX_SUCCESS = 'GET_FACTORING_PRICE_MATRIX_SUCCESS'
export const GET_FACTORING_PRICE_MATRIX_ERROR = 'GET_FACTORING_PRICE_MATRIX_ERROR'

export const GET_FACTORING_SUPPLY_COMMISSIONS = 'GET_FACTORING_SUPPLY_COMMISSIONS'
export const GET_FACTORING_SUPPLY_COMMISSIONS_SUCCESS = 'GET_FACTORING_SUPPLY_COMMISSIONS_SUCCESS'
export const GET_FACTORING_SUPPLY_COMMISSIONS_ERROR = 'GET_FACTORING_SUPPLY_COMMISSIONS_ERROR'

export const GET_FACTORING_ACCOUNT_ENTITY_TYPES = 'GET_FACTORING_ACCOUNT_ENTITY_TYPES'
export const GET_FACTORING_ACCOUNT_ENTITY_TYPES_SUCCESS = 'GET_FACTORING_ACCOUNT_ENTITY_TYPES_SUCCESS'
export const GET_FACTORING_ACCOUNT_ENTITY_TYPES_ERROR = 'GET_FACTORING_ACCOUNT_ENTITY_TYPES_ERROR'

export const GET_FACTORING_REF_RATE_TYPES = 'GET_FACTORING_REF_RATE_TYPES'
export const GET_FACTORING_REF_RATE_TYPES_SUCCESS = 'GET_FACTORING_REF_RATE_TYPES_SUCCESS'
export const GET_FACTORING_REF_RATE_TYPES_ERROR = 'GET_FACTORING_REF_RATE_TYPES_ERROR'

export const GET_FACTORING_REF_RATE_CONFIG = 'GET_FACTORING_REF_RATE_CONFIG'
export const GET_FACTORING_REF_RATE_CONFIG_SUCCESS = 'GET_FACTORING_REF_RATE_CONFIG_SUCCESS'
export const GET_FACTORING_REF_RATE_CONFIG_ERROR = 'GET_FACTORING_REF_RATE_CONFIG_ERROR'

export const GET_FACTORING_REF_BLOCK_TYPES = 'GET_FACTORING_REF_BLOCK_TYPES'
export const GET_FACTORING_REF_BLOCK_TYPES_SUCCESS = 'GET_FACTORING_REF_BLOCK_TYPES_SUCCESS'
export const GET_FACTORING_REF_BLOCK_TYPES_ERROR = 'GET_FACTORING_REF_BLOCK_TYPES_ERROR'

export const GET_FACTORING_REF_OPERATION_TYPES = 'GET_FACTORING_REF_OPERATION_TYPES'
export const GET_FACTORING_REF_OPERATION_TYPES_SUCCESS = 'GET_FACTORING_REF_OPERATION_TYPES_SUCCESS'
export const GET_FACTORING_REF_OPERATION_TYPES_ERROR = 'GET_FACTORING_REF_OPERATION_TYPES_ERROR'

export const GET_FACTORING_REF_ACCOUNT_TYPES = 'GET_FACTORING_REF_ACCOUNT_TYPES'
export const GET_FACTORING_REF_ACCOUNT_TYPES_SUCCESS = 'GET_FACTORING_REF_ACCOUNT_TYPES_SUCCESS'
export const GET_FACTORING_REF_ACCOUNT_TYPES_ERROR = 'GET_FACTORING_REF_ACCOUNT_TYPES_ERROR'

export const GET_FACTORING_REF_OFFERING_KEYS = 'GET_FACTORING_REF_OFFERING_KEYS'
export const GET_FACTORING_REF_OFFERING_KEYS_SUCCESS = 'GET_FACTORING_REF_OFFERING_KEYS_SUCCESS'
export const GET_FACTORING_REF_OFFERING_KEYS_ERROR = 'GET_FACTORING_REF_OFFERING_KEYS_ERROR'

export const GET_FACTORING_REF_ISSUES = 'GET_FACTORING_REF_ISSUES'
export const GET_FACTORING_REF_ISSUES_SUCCESS = 'GET_FACTORING_REF_ISSUES_SUCCESS'
export const GET_FACTORING_REF_ISSUES_ERROR = 'GET_FACTORING_REF_ISSUES_ERROR'

export const GET_FACTORING_REF_LIMIT_TYPES = 'GET_FACTORING_REF_LIMIT_TYPES'
export const GET_FACTORING_REF_LIMIT_TYPES_SUCCESS = 'GET_FACTORING_REF_LIMIT_TYPES_SUCCESS'
export const GET_FACTORING_REF_LIMIT_TYPES_ERROR = 'GET_FACTORING_REF_LIMIT_TYPES_ERROR'

export const GET_FACTORING_MON_ISSUE_NOTIFICATIONS = 'GET_FACTORING_MON_ISSUE_NOTIFICATIONS'
export const GET_FACTORING_MON_ISSUE_NOTIFICATIONS_SUCCESS = 'GET_FACTORING_MON_ISSUE_NOTIFICATIONS_SUCCESS'
export const GET_FACTORING_MON_ISSUE_NOTIFICATIONS_ERROR = 'GET_FACTORING_MON_ISSUE_NOTIFICATIONS_ERROR'

export const GET_FACTORING_REF_MATCHING_REASONS = 'GET_FACTORING_REF_MATCHING_REASONS'
export const GET_FACTORING_REF_MATCHING_REASONS_SUCCESS = 'GET_FACTORING_REF_MATCHING_REASONS_SUCCESS'
export const GET_FACTORING_REF_MATCHING_REASONS_ERROR = 'GET_FACTORING_REF_MATCHING_REASONS_ERROR'

export const GET_FACTORING_PAYMENT_ORDERS = 'GET_FACTORING_PAYMENT_ORDERS'
export const GET_FACTORING_PAYMENT_ORDERS_SUCCESS = 'GET_FACTORING_PAYMENT_ORDERS_SUCCESS'
export const GET_FACTORING_PAYMENT_ORDERS_ERROR = 'GET_FACTORING_PAYMENT_ORDERS_ERROR'

export const RESET_FACTORING_PAYMENT_ORDERS = 'RESET_FACTORING_PAYMENT_ORDERS'

export const GET_FACTORING_MATCHINGS_BY_TASK_ID = 'GET_FACTORING_MATCHINGS_BY_TASK_ID'
export const GET_FACTORING_MATCHINGS_BY_TASK_ID_SUCCESS = 'GET_FACTORING_MATCHINGS_BY_TASK_ID_SUCCESS'
export const GET_FACTORING_MATCHINGS_BY_TASK_ID_ERROR = 'GET_FACTORING_MATCHINGS_BY_TASK_ID_ERROR'

export const GET_FACTORING_MATCHINGS_BY_PAYMENT_ID = 'GET_FACTORING_MATCHINGS_BY_PAYMENT_ID'
export const GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_SUCCESS = 'GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_SUCCESS'
export const GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_ERROR = 'GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_ERROR'

export const RESET_FACTORING_MATCHINGS_BY_PAYMENT_ID_SUCCESS = 'RESET_FACTORING_MATCHINGS_BY_PAYMENT_ID_SUCCESS'

export const GET_FACTORING_DISTRIBUTE_PAYMENT = 'GET_FACTORING_DISTRIBUTE_PAYMENT'
export const GET_FACTORING_DISTRIBUTE_PAYMENT_SUCCESS = 'GET_FACTORING_DISTRIBUTE_PAYMENT_SUCCESS'
export const GET_FACTORING_DISTRIBUTE_PAYMENT_ERROR = 'GET_FACTORING_DISTRIBUTE_PAYMENT_ERROR'

export const RESET_FACTORING_DISTRIBUTE_PAYMENT = 'RESET_FACTORING_DISTRIBUTE_PAYMENT'

export const GET_FACTORING_SURETY_TYPES = 'GET_FACTORING_SURETY_TYPES'
export const GET_FACTORING_SURETY_TYPES_SUCCESS = 'GET_FACTORING_SURETY_TYPES_SUCCESS'
export const GET_FACTORING_SURETY_TYPES_ERROR = 'GET_FACTORING_SURETY_TYPES_ERROR'

export const DELETE_FACTORING_OPERATION = 'DELETE_FACTORING_OPERATION'
export const DELETE_FACTORING_OPERATION_SUCCESS = 'DELETE_FACTORING_OPERATION_SUCCESS'
export const DELETE_FACTORING_OPERATION_ERROR = 'DELETE_FACTORING_OPERATION_ERROR'

export const GET_FACTORING_REF_MASTER_OPERATION_TYPES = 'GET_FACTORING_REF_MASTER_OPERATION_TYPES'
export const GET_FACTORING_REF_MASTER_OPERATION_TYPES_SUCCESS = 'GET_FACTORING_REF_MASTER_OPERATION_TYPES_SUCCESS'
export const GET_FACTORING_REF_MASTER_OPERATION_TYPES_ERROR = 'GET_FACTORING_REF_MASTER_OPERATION_TYPES_ERROR'

export const GET_FACTORING_MASTER_OPERATION = 'GET_FACTORING_MASTER_OPERATION'
export const GET_FACTORING_MASTER_OPERATION_SUCCESS = 'GET_FACTORING_MASTER_OPERATION_SUCCESS'
export const GET_FACTORING_MASTER_OPERATION_ERROR = 'GET_FACTORING_MASTER_OPERATION_ERROR'

export const GET_FACTORING_COUNTERPARTY_COMMON = 'GET_FACTORING_COUNTERPARTY_COMMON'
export const GET_FACTORING_COUNTERPARTY_COMMON_SUCCESS = 'GET_FACTORING_COUNTERPARTY_COMMON_SUCCESS'
export const GET_FACTORING_COUNTERPARTY_COMMON_ERROR = 'GET_FACTORING_COUNTERPARTY_COMMON_ERROR'

export const GET_FACTORING_COUNTERPARTY_ADDRESS = 'GET_FACTORING_COUNTERPARTY_ADDRESS'
export const GET_FACTORING_COUNTERPARTY_ADDRESS_SUCCESS = 'GET_FACTORING_COUNTERPARTY_ADDRESS_SUCCESS'
export const GET_FACTORING_COUNTERPARTY_ADDRESS_ERROR = 'GET_FACTORING_COUNTERPARTY_ADDRESS_ERROR'

export const GET_FACTORING_COUNTERPARTY_STATE = 'GET_FACTORING_COUNTERPARTY_STATE'
export const GET_FACTORING_COUNTERPARTY_STATE_SUCCESS = 'GET_FACTORING_COUNTERPARTY_STATE_SUCCESS'
export const GET_FACTORING_COUNTERPARTY_STATE_ERROR = 'GET_FACTORING_COUNTERPARTY_STATE_ERROR'

export const GET_FACTORING_COUNTERPARTY_INFO = 'GET_FACTORING_COUNTERPARTY_INFO'
export const GET_FACTORING_COUNTERPARTY_INFO_SUCCESS = 'GET_FACTORING_COUNTERPARTY_INFO_SUCCESS'
export const GET_FACTORING_COUNTERPARTY_INFO_ERROR = 'GET_FACTORING_COUNTERPARTY_INFO_ERROR'

export const GET_FACTORING_COUNTERPARTY_SIGNER = 'GET_FACTORING_COUNTERPARTY_SIGNER'
export const GET_FACTORING_COUNTERPARTY_SIGNER_SUCCESS = 'GET_FACTORING_COUNTERPARTY_SIGNER_SUCCESS'
export const GET_FACTORING_COUNTERPARTY_SIGNER_ERROR = 'GET_FACTORING_COUNTERPARTY_SIGNER_ERROR'

export const GET_FACTORING_COUNTERPARTY_CONTACT = 'GET_FACTORING_COUNTERPARTY_CONTACT'
export const GET_FACTORING_COUNTERPARTY_CONTACT_SUCCESS = 'GET_FACTORING_COUNTERPARTY_CONTACT_SUCCESS'
export const GET_FACTORING_COUNTERPARTY_CONTACT_ERROR = 'GET_FACTORING_COUNTERPARTY_CONTACT_ERROR'

export const GET_FACTORING_FACTORING_CONTRACT_COMMON = 'GET_FACTORING_FACTORING_CONTRACT_COMMON'
export const GET_FACTORING_FACTORING_CONTRACT_COMMON_SUCCESS = 'GET_FACTORING_FACTORING_CONTRACT_COMMON_SUCCESS'
export const GET_FACTORING_FACTORING_CONTRACT_COMMON_ERROR = 'GET_FACTORING_FACTORING_CONTRACT_COMMON_ERROR'

export const GET_FACTORING_FACTORING_CONTRACT_MAIN = 'GET_FACTORING_FACTORING_CONTRACT_MAIN'
export const GET_FACTORING_FACTORING_CONTRACT_MAIN_SUCCESS = 'GET_FACTORING_FACTORING_CONTRACT_MAIN_SUCCESS'
export const GET_FACTORING_FACTORING_CONTRACT_MAIN_ERROR = 'GET_FACTORING_FACTORING_CONTRACT_MAIN_ERROR'

export const GET_FACTORING_FACTORING_CONTRACT_CONTRACT = 'GET_FACTORING_FACTORING_CONTRACT_CONTRACT'
export const GET_FACTORING_FACTORING_CONTRACT_CONTRACT_SUCCESS = 'GET_FACTORING_FACTORING_CONTRACT_CONTRACT_SUCCESS'
export const GET_FACTORING_FACTORING_CONTRACT_CONTRACT_ERROR = 'GET_FACTORING_FACTORING_CONTRACT_CONTRACT_ERROR'

export const GET_FACTORING_FACTORING_CONTRACT_ACCOUNT = 'GET_FACTORING_FACTORING_CONTRACT_ACCOUNT'
export const GET_FACTORING_FACTORING_CONTRACT_ACCOUNT_SUCCESS = 'GET_FACTORING_FACTORING_CONTRACT_ACCOUNT_SUCCESS'
export const GET_FACTORING_FACTORING_CONTRACT_ACCOUNT_ERROR = 'GET_FACTORING_FACTORING_CONTRACT_ACCOUNT_ERROR'

export const GET_FACTORING_FACTORING_CONTRACT_SURETY = 'GET_FACTORING_FACTORING_CONTRACT_SURETY'
export const GET_FACTORING_FACTORING_CONTRACT_SURETY_SUCCESS = 'GET_FACTORING_FACTORING_CONTRACT_SURETY_SUCCESS'
export const GET_FACTORING_FACTORING_CONTRACT_SURETY_ERROR = 'GET_FACTORING_FACTORING_CONTRACT_SURETY_ERROR'

export const GET_FACTORING_FACTORING_CONTRACT_TARIFF = 'GET_FACTORING_FACTORING_CONTRACT_TARIFF'
export const GET_FACTORING_FACTORING_CONTRACT_TARIFF_SUCCESS = 'GET_FACTORING_FACTORING_CONTRACT_TARIFF_SUCCESS'
export const GET_FACTORING_FACTORING_CONTRACT_TARIFF_ERROR = 'GET_FACTORING_FACTORING_CONTRACT_TARIFF_ERROR'

export const GET_FACTORING_FACTORING_CONTRACT_FEATURES = 'GET_FACTORING_FACTORING_CONTRACT_FEATURES'
export const GET_FACTORING_FACTORING_CONTRACT_FEATURES_SUCCESS = 'GET_FACTORING_FACTORING_CONTRACT_FEATURES_SUCCESS'
export const GET_FACTORING_FACTORING_CONTRACT_FEATURES_ERROR = 'GET_FACTORING_FACTORING_CONTRACT_FEATURES_ERROR'

export const GET_FACTORING_SUPLY_CONTRACT_COMMON = 'GET_FACTORING_SUPLY_CONTRACT_COMMON'
export const GET_FACTORING_SUPLY_CONTRACT_COMMON_SUCCESS = 'GET_FACTORING_SUPLY_CONTRACT_COMMON_SUCCESS'
export const GET_FACTORING_SUPLY_CONTRACT_COMMON_ERROR = 'GET_FACTORING_SUPLY_CONTRACT_COMMON_ERROR'

export const GET_FACTORING_SUPPLY_CONTRACT_COMMON = 'GET_FACTORING_SUPPLY_CONTRACT_COMMON'
export const GET_FACTORING_SUPPLY_CONTRACT_COMMON_SUCCESS = 'GET_FACTORING_SUPPLY_CONTRACT_COMMON_SUCCESS'
export const GET_FACTORING_SUPPLY_CONTRACT_COMMON_ERROR = 'GET_FACTORING_SUPPLY_CONTRACT_COMMON_ERROR'

export const GET_FACTORING_ACCOUNT_ENTITIES = 'GET_FACTORING_ACCOUNT_ENTITIES'
export const GET_FACTORING_ACCOUNT_ENTITIES_SUCCESS = 'GET_FACTORING_ACCOUNT_ENTITIES_SUCCESS'
export const GET_FACTORING_ACCOUNT_ENTITIES_ERROR = 'GET_FACTORING_ACCOUNT_ENTITIES_ERROR'

export const GET_FACTORING_CONSTRAINT_ORDERS = 'GET_FACTORING_CONSTRAINT_ORDERS'
export const GET_FACTORING_CONSTRAINT_ORDERS_SUCCESS = 'GET_FACTORING_CONSTRAINT_ORDERS_SUCCESS'
export const GET_FACTORING_CONSTRAINT_ORDERS_ERROR = 'GET_FACTORING_CONSTRAINT_ORDERS_ERROR'

export const GET_FACTORING_SUPPLY_COMMON_TAB = 'GET_FACTORING_SUPPLY_COMMON_TAB'
export const GET_FACTORING_SUPPLY_COMMON_TAB_SUCCESS = 'GET_FACTORING_SUPPLY_COMMON_TAB_SUCCESS'
export const GET_FACTORING_SUPPLY_COMMON_TAB_ERROR = 'GET_FACTORING_SUPPLY_COMMON_TAB_ERROR'

export const GET_FACTORING_SUPPLY_DOCUMENT_TAB = 'GET_FACTORING_SUPPLY_DOCUMENT_TAB'
export const GET_FACTORING_SUPPLY_DOCUMENT_TAB_SUCCESS = 'GET_FACTORING_SUPPLY_DOCUMENT_TAB_SUCCESS'
export const GET_FACTORING_SUPPLY_DOCUMENT_TAB_ERROR = 'GET_FACTORING_SUPPLY_DOCUMENT_TAB_ERROR'

export const GET_FACTORING_SUPPLY_FINANCING_TAB = 'GET_FACTORING_SUPPLY_FINANCING_TAB'
export const GET_FACTORING_SUPPLY_FINANCING_TAB_SUCCESS = 'GET_FACTORING_SUPPLY_FINANCING_TAB_SUCCESS'
export const GET_FACTORING_SUPPLY_FINANCING_TAB_ERROR = 'GET_FACTORING_SUPPLY_FINANCING_TAB_ERROR'

export const GET_FACTORING_MON_ISSUE_NOTIFICATION_TAB = 'GET_FACTORING_MON_ISSUE_NOTIFICATION_TAB'
export const GET_FACTORING_MON_ISSUE_NOTIFICATION_TAB_SUCCESS = 'GET_FACTORING_MON_ISSUE_NOTIFICATION_TAB_SUCCESS'
export const GET_FACTORING_MON_ISSUE_NOTIFICATION_TAB_ERROR = 'GET_FACTORING_MON_ISSUE_NOTIFICATION_TAB_ERROR'

export const GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB = 'GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB'
export const GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_SUCCESS = 'GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_SUCCESS'
export const GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_ERROR = 'GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_ERROR'

export const GET_FACTORING_HOLIDAYS_BY_PERIOD = 'GET_FACTORING_HOLIDAYS_BY_PERIOD'
export const GET_FACTORING_HOLIDAYS_BY_PERIOD_SUCCESS = 'GET_FACTORING_HOLIDAYS_BY_PERIOD_SUCCESS'
export const GET_FACTORING_HOLIDAYS_BY_PERIOD_ERROR = 'GET_FACTORING_HOLIDAYS_BY_PERIOD_ERROR'

export const SET_FACTORING_HOLIDAY_DAY = 'SET_FACTORING_HOLIDAY_DAY'
export const SET_FACTORING_HOLIDAY_DAY_SUCCESS = 'SET_FACTORING_HOLIDAY_DAY_SUCCESS'
export const SET_FACTORING_HOLIDAY_DAY_ERROR = 'SET_FACTORING_HOLIDAY_DAY_ERROR'

export const DELETE_FACTORING_HOLIDAY_DAY = 'DELETE_FACTORING_HOLIDAY_DAY'
export const DELETE_FACTORING_HOLIDAY_DAY_SUCCESS = 'DELETE_FACTORING_HOLIDAY_DAY_SUCCESS'
export const DELETE_FACTORING_HOLIDAY_DAY_ERROR = 'DELETE_FACTORING_HOLIDAY_DAY_ERROR'

export const ENABLE_FACTORING_HOLIDAY_DAY = 'ENABLE_FACTORING_HOLIDAY_DAY'
export const ENABLE_FACTORING_HOLIDAY_DAY_SUCCESS = 'ENABLE_FACTORING_HOLIDAY_DAY_SUCCESS'
export const ENABLE_FACTORING_HOLIDAY_DAY_ERROR = 'ENABLE_FACTORING_HOLIDAY_DAY_ERROR'

export const DISABLE_FACTORING_HOLIDAY_DAY = 'DISABLE_FACTORING_HOLIDAY_DAY'
export const DISABLE_FACTORING_HOLIDAY_DAY_SUCCESS = 'DISABLE_FACTORING_HOLIDAY_DAY_SUCCESS'
export const DISABLE_FACTORING_HOLIDAY_DAY_ERROR = 'DISABLE_FACTORING_HOLIDAY_DAY_ERROR'

export const GET_FACTORING_OPER_DAYS = 'GET_FACTORING_OPER_DAYS'
export const GET_FACTORING_OPER_DAYS_SUCCESS = 'GET_FACTORING_OPER_DAYS_SUCCESS'
export const GET_FACTORING_OPER_DAYS_ERROR = 'GET_FACTORING_OPER_DAYS_ERROR'

export const GET_FACTORING_ACCOUNT_NUMBER = 'GET_FACTORING_ACCOUNT_NUMBER'
export const GET_FACTORING_ACCOUNT_NUMBER_SUCCESS = 'GET_FACTORING_ACCOUNT_NUMBER_SUCCESS'
export const GET_FACTORING_ACCOUNT_NUMBER_ERROR = 'GET_FACTORING_ACCOUNT_NUMBER_ERROR'

export const GET_FACTORING_SUPPLY_CONTRACT = 'GET_FACTORING_SUPPLY_CONTRACT'
export const GET_FACTORING_SUPPLY_CONTRACT_SUCCESS = 'GET_FACTORING_SUPPLY_CONTRACT_SUCCESS'
export const GET_FACTORING_SUPPLY_CONTRACT_ERROR = 'GET_FACTORING_SUPPLY_CONTRACT_ERROR'

export const GET_FACTORING_ACCOUNT_COMPANY = 'GET_FACTORING_ACCOUNT_COMPANY'
export const GET_FACTORING_ACCOUNT_COMPANY_SUCCESS = 'GET_FACTORING_ACCOUNT_COMPANY_SUCCESS'
export const GET_FACTORING_ACCOUNT_COMPANY_ERROR = 'GET_FACTORING_ACCOUNT_COMPANY_ERROR'

export const RESET_FACTORING_RECEIVER = 'RESET_FACTORING_RECEIVER'