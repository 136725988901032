import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Form } from 'redux-form'

import CustomDetailLoader from '../../../../components/CustomDetail/Loader'
import CustomDetailHeader from '../../../../components/CustomDetail/Header'
import ProposalForm from '../../../../components/CustomDetail/Form/Proposal'

const CustomAddTask = ({
  isFetching, 
  onCloseForm,
}) =>
  <div className='row no-gutters flex-nowrap task-form'>
    <Form className='task-form__wrap block'>
      {isFetching 
        ? <CustomDetailLoader />
        : <>
            <CustomDetailHeader
              section='Заявка'
              title='Заявка на выпуск банковской гарантии'
              name='Имя компании'
              inn='123456789'
              kpp='123456789'
              ogrn='123456789'
            />
            <div className='block_item-out'>
              <ProposalForm />
            </div>
            <div className='task-form__footer'>
              <button type='button' className='btn-form btn-form--none' onClick={onCloseForm}>Отказать</button>
              <button type='submit' className='btn-form btn-form--ok'>Согласовано</button>
            </div>
          </>
        }
    </Form>
  </div>

const mapStateToProps = () => {
  return {
    isFetching: false
  }
}

const formSettings = {
  form: 'addTask',
  enableReinitialize: true,
}

export default connect(mapStateToProps)(reduxForm(formSettings)(CustomAddTask))