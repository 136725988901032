import React, { useState } from 'react'
import injectSheet from 'react-jss'
import { Icon } from './../../index'
import { formattedDateTime } from './../../../utils'

const styles = {
	root: {
    width: '210px',
    padding: '7px 15px',
    position: 'absolute',
    top: '-3px',
    right: '70px',
    '& svg': {
      fill: '#9B9B9B',
      width: '24px',
      height: '24px',
      marginRight: '10px',
      display: 'inline-block',
      pointerEvents: 'none',
    },
  },
  select: {
    background: 'transparent',
    width: '100%',
    padding: '7px 0',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    cursor: 'pointer',
    outline: 'none',
    opacity: 0,
  },
  fake: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    border: '1px solid #eee',
    borderRadius: '16px',
    padding: '7px 0 7px 50px',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000',
    fontWeight: 600,
    display: 'inline-block',
    textAlign: 'left',
    pointerEvents: 'none',
  },
}

const Datepicker = ({ 
  items,
  index, 
  onChange,
  classes, 
}) => {
  const [value, setValue] = useState(items[0].id)

  const currentObject = items.find(item => item.id === value)
  const currentValue = currentObject ? formattedDateTime(currentObject.createdDateTime) : ''

  return (
    <div className={classes.root}>
      <Icon iconName='datepicker' />
      <select className={classes.select} onChange={(e) => {
        onChange(e.target.value, index)
        setValue(e.target.value)
      }}>
        {items.map(({ id, createdDateTime }) =>
          <option key={id} value={id}>
            {formattedDateTime(createdDateTime)}
          </option>
        )}
      </select>
      <div className={classes.fake}>{currentValue}</div>
    </div>
  )
}

export default injectSheet(styles)(Datepicker)