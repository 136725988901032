import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  switcher: {
    position: 'relative',
    '& input': {
      display: 'none',
      '&:checked': {
        '&:disabled': {
          '& + label': {
            cursor: 'default',
          },
        },
        '& + label': {
          '&:before': {
            background: '#4BD763',
          },
          '&:after': {
            left: 24,
          },
        },
      },
      '&:disabled': {
        '& + label': {
          cursor: 'default',
        },
      },
    },
    '& + &': {
      marginTop: 10,
    },
  },
  label: {
    minHeight: 24,
    marginBottom: [0, '!important'],
    padding: [3, 0, 3, 56],
    fontSize: [14, '!important'],
    lineHeight: [18/14, '!important'],
    color: ' #000',
    fontWeight: 500,
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    cursor: 'pointer',
    '&:before': {
      content: '""',
      display: 'block',
      background: '#eee',
      width: 46,
      height: 24,
      borderRadius: 40,
      position: 'absolute',
      left: 0,
      top: 0,
      transition: 'background 300ms ease',
    },
    '&:after': {
      content: '""',
      display: 'block',
      background: '#fff',
      width: 20,
      height: 20,
      borderRadius: '50%',
      position: 'absolute',
      left: 2,
      top: 2,
      transition: 'background 300ms ease, left 300ms ease',
    },
  },
})

export const SwitchSlider = ({
  className,
  id,
  label,
  type = 'checkbox',
  ...rest
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.switcher, className)}>
      <input id={id} type={type} {...rest} />
      <label className={classes.label} htmlFor={id}>
        {label}
      </label>
    </div>
  )
}
