export default [
  {
    type: 'group',
    label: 'БУХГАЛТЕРСКИЙ БАЛАНС (ТЫС. РУБ.)'
  },
  {
    type: 'group',
    label: 'I. ВНЕОБОРОТНЫЕ АКТИВЫ (ТЫС. РУБ.)',
    rows: [
      { code: 1110, label: 'Нематериальные активы' },
      { code: 1120, label: 'Результаты исслед. и разработок' },
      { code: 1130, label: 'Нематериальные поисковые активы' },
      { code: 1140, label: 'Материальные поисковые активы' },
      { code: 1150, label: 'Основные средства' },
      { code: 1160, label: 'Доходные вложения в материальные ценности' },
      { code: 1170, label: 'Долгосрочные финансовые вложения' },
      { code: 1180, label: 'Отложенные налоговые активы' },
      { code: 1190, label: 'Прочие внеоборотные активы' },
      { code: 1100, label: 'ИТОГО по разделу I', place: 'footer' },
    ],
  },
  {
    type: 'group',
    label: 'II. ОБОРОТНЫЕ АКТИВЫ (ТЫС. РУБ.)',
    rows: [
      { code: 1210, label: 'Запасы' },
      { code: 1220, label: 'НДС по приобретенным ценностям' },
      { code: 1230, label: 'Дебиторская задолженность' },
      { code: 1240, label: 'Краткосрочные фин. Вложения' },
      { code: 1250, label: 'Денежные средства' },
      { code: 1260, label: 'Прочие оборотные активы' },
      { code: 1200, label: 'ИТОГО по разделу II',  place: 'footer' },
      { code: 1600, label: 'БАЛАНС',  place: 'footer'}
    ],
  },
  {
    type: 'group',
    label: 'ПАССИВ (ТЫС. РУБ.)'
  },
  {
    type: 'group',
    label: 'III. КАПИТАЛ И РЕЗЕРВЫ (ТЫС. РУБ.)',
    rows: [
      { code: 1310, label: 'Уставный капитал' },
      { code: 1320, label: 'Собственные акции, выкупленные у акционеров' },
      { code: 1340, label: 'Переоценка внеоборотных активов' },
      { code: 1350, label: 'Добавочный капитал' },
      { code: 1360, label: 'Резервный капитал' },
      { code: 1370, label: 'Нераспределенная прибыль' },
      { code: 1300, label: 'ИТОГО по разделу III',  place: 'footer'}
    ],
  },
  {
    type: 'group',
    label: 'IV. ДОЛГОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА (ТЫС. РУБ.)',
    rows: [
      { code: 1410, label: 'Заемные средства' },
      { code: 1420, label: 'Отложенные налоговые обязательства' },
      { code: 1430, label: 'Оценочные обязательства долгосроч.' },
      { code: 1450, label: 'Прочие долгосрочные обязательства' },
      { code: 1400, label: 'ИТОГО по разделу IV',  place: 'footer'}
    ],
  },
  {
    type: 'group',
    label: 'V. КРАТКОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА (ТЫС. РУБ.)',
    rows: [
      { code: 1510, label: 'Заемные средства' },
      { code: 1520, label: 'Кредиторская задолженность' },
      { code: 1530, label: 'Доходы будущих периодов' },
      { code: 1540, label: 'Оценочные обязательства' },
      { code: 1550, label: 'Прочие краткосрочные обязательства' },
      { code: 1500, label: 'ИТОГО по разделу V', place: 'footer' },
      { code: 1700, label: 'БАЛАНС', place: 'footer'}
    ],
  },
  {
    type: 'group',
    label: 'ОТЧЕТ О ФИНАНСОВЫХ РЕЗУЛЬТАТАХ (ТЫС. РУБ.)',
    rows: [
      { code: 2110, label: 'Выручка от реализации (нетто, без НДС, акцизов)' },
      { code: 2120, label: 'Себестоимость' },
      { code: 2100, label: 'Валовая прибыль' },
      { code: 2210, label: 'Коммерческие расходы' },
      { code: 2220, label: 'Управленческие расходы' },
      { code: 2200, label: 'Прибыль (убыток) от продаж' },
      { code: 2310, label: 'Доходы от участия в других организациях' },
      { code: 2320, label: 'Проценты к получению' },
      { code: 2330, label: 'Проценты к уплате' },
      { code: 2340, label: 'Прочие доходы (операционные)' },
      { code: 2350, label: 'Прочие расходы (операционные)' },
      { code: 2300, label: 'Прибыль (убыток) до налогообложения' },
      { code: 2410, label: 'Текущий налог на прибыль' },
      { code: 2430, label: 'Отложенные налоговые обязательства' },
      { code: 2450, label: 'Отложенные налоговые активы' },
      { code: 2460, label: 'Прочее' },
      { code: 2400, label: 'Чистая прибыль (убыток) отчетного периода' },
      { code: 5640, label: 'Значение амортизационных отчислений на последнюю отчетную годовую дату' },
    ],
  },
]