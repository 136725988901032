import * as actionTypes from './actionTypes'
import { combineReducers } from 'redux'
import reduceReducers from 'reduce-reducers'
import _ from 'lodash'

const INITIAL_STATE = {
  fetching: false,
  company: null,
}

const INITIAL_STATE_BY_TYPE = {
  COMPANY_GET_ORDER: {
    fetching: false,
    order: {
      bankguarantee: {
        finalAmount: 0,
        calculatedAmount: 0,
      },
      bankguaranteeLimit: {
        finalAmount: 0,
        calculatedAmount: 0,
      },
      principal: {},
      scoring: {
        scoringItems: [],
        scoringTypes: [],
      },
    },
  }
}

const createGetReducer = type => (state = INITIAL_STATE_BY_TYPE[type] || {}, action = {}) => {
  switch (action.type) {
    case `${type}_PENDING`:
      return {
        ...state,
        fetching: true,
      }
    case `${type}_SUCCESS`:
      return {
        ...state,
        ...action.data,
        fetching: false,
      }
    case `${type}_ERROR`:
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}

const companyInfoReducer = createGetReducer('COMPANY_GET_INFO')
const limitsReducer = createGetReducer('COMPANY_GET_LIMITS')
const ordersReducer = createGetReducer('COMPANY_GET_ORDERS')
const orderReducer = createGetReducer('COMPANY_GET_ORDER')
const clientLogins = createGetReducer('COMPANY_GET_CLIENT_LOGINS')
const agentLogins = createGetReducer('COMPANY_GET_AGENT_LOGINS')
const address = createGetReducer('COMPANY_GET_ADDRESS')
const bankAccounts = createGetReducer('COMPANY_GET_BANK_ACCOUNTS')
const staffInfo = createGetReducer(actionTypes.COMPANY_GET_STUFF_INFO)
const headInfo = createGetReducer(actionTypes.COMPANY_GET_HEAD_INFO)
const signerInfo = createGetReducer(actionTypes.COMPANY_GET_SIGNER_INFO)
const foundersInfo = createGetReducer(actionTypes.COMPANY_GET_FOUNDERS_INFO)
const beneficiariesInfo = createGetReducer(actionTypes.COMPANY_GET_BENEFICIARIES_INFO)
const contactPersonInfo = createGetReducer(actionTypes.COMPANY_GET_CONTACT_PERSON_INFO)
const documentsInfo = createGetReducer(actionTypes.COMPANY_GET_DOCUMENTS_INFO)
const managementInfo = createGetReducer(actionTypes.COMPANY_GET_MANAGEMENT_INFO)
const buhInfo = createGetReducer(actionTypes.COMPANY_GET_BUH_INFO)
const liabilitiesInfo = createGetReducer(actionTypes.COMPANY_GET_LIABILITIES_INFO)
const bgAgentBinding = createGetReducer(actionTypes.COMPANY_GET_BG_AGENT_BINDING)
const workGroupsGet = createGetReducer(actionTypes.COMPANY_GET_WORK_GROUPS)
const agentInfoGet = createGetReducer(actionTypes.COMPANY_AGENT_INFO_GET)
const startSubProcess = createGetReducer(actionTypes.COMPANY_START_SUBPROCESS)
const baseSchemaList = createGetReducer(actionTypes.COMPANY_AGENT_BILLING_GET_BASE_SCHEMA_LIST)
const agentStats = createGetReducer(actionTypes.COMPANY_GET_AGENT_STATS)
const agentStatsByStatusGet = createGetReducer(actionTypes.COMPANY_GET_AGENT_STATS_BY_STATUS)

const agentInfoBillingSignersReducer = (state = [], action, meta) => {
  switch (action.type) {
    case actionTypes.COMPANY_AGENT_BILLING_SIGNER_REMOVE_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          billingSigners: state.info.billingSigners
            .filter(item => item.relationSignerId !== action.meta.relationId)
        },
      }
    default:
      return state
  }
}

const agentInfoCbsManagerUpdateReducer = (state = [], action, meta) => {
  switch (action.type) {
    case actionTypes.COMPANY_CBS_MANAGER_UPDATE_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          agentCbsId: action.meta.agentCbsId,
        }
      }
    default: 
      return state
  }
}

const workGroupsUpdateReducer = (state = [], action, meta) => {
  switch (action.type) {
    case actionTypes.COMPANY_UPDATE_WORK_GROUPS_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actionTypes.COMPANY_UPDATE_WORK_GROUPS_SUCCESS:
    case actionTypes.COMPANY_UPDATE_WORK_GROUPS_ERROR:
      return {
        ...state,
        fetching: false,
      }
    default: 
      return state
  }
}

const agentStatsByStatusReset = (state = [], action) => {
  switch (action.type) {
    case actionTypes.COMPANY_RESET_AGENT_STATS_BY_STATUS:
      return {}
    default:
      return state
  }
}

const agentInfo = reduceReducers(
  agentInfoGet,
  agentInfoBillingSignersReducer,
  agentInfoCbsManagerUpdateReducer,
)

const workGroups = reduceReducers(workGroupsGet, workGroupsUpdateReducer)
const agentStatsByStatus = reduceReducers(agentStatsByStatusGet, agentStatsByStatusReset)

const updateTable = (state, action, value) => {
  const { orderId } = action.meta
  const itemIndex = state.findIndex(item => item.orderId === orderId)

  if (itemIndex > -1) {
    return state.map((item, index) => {
      if (itemIndex === index) {
        return {
          ...item,
          excluded: value
        }
      }
      return item
    })
  }
  return state
}

const statisticReducerCreator = type => (state = {}, action = {}) => {
  switch (action.type) {
    case `${type}_PENDING`:
      return {
        fetching: true,
      }
    case `${type}_SUCCESS`:
      return {
        ...state,
        ...action.data,
        fetching: false,
      }
    case `${type}_ERROR`:
      return {
        fetching: false,
      }
    case actionTypes.COMPANY_STATISTIC_ORDER_INCLUDE_SUCCESS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          issued: updateTable(state.statistics.issued, action, false),
          inprogress: updateTable(state.statistics.inprogress, action, false),
          load: updateTable(state.statistics.load, action, false),
          lost: updateTable(state.statistics.lost, action, false),
          other: updateTable(state.statistics.other, action, false),
        },
      }
    case actionTypes.COMPANY_STATISTIC_ORDER_EXCLUDE_SUCCESS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          issued: updateTable(state.statistics.issued, action, true),
          inprogress: updateTable(state.statistics.inprogress, action, true),
          load: updateTable(state.statistics.load, action, true),
          lost: updateTable(state.statistics.lost, action, true),
          other: updateTable(state.statistics.other, action, true),
        },
      }
    case actionTypes.COMPANY_STATISTIC_UPDATE_LOADED_SUCCESS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          load: _.get(action.data, 'statistics.load')
        },
        monthlyLoad: action.data.monthlyLoad,
      }
    default:
      return state
  }
}

const statisticInfo = statisticReducerCreator(actionTypes.COMPANY_GET_STATISTIC_INFO)

const registerCompanyAsAgentReducerCreator = () => (state = {}, action = {}) => {
  switch(action.type) {
    case actionTypes.REGISTER_COMPANY_AS_AGENT_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actionTypes.REGISTER_COMPANY_AS_AGENT_SUCCESS: 
      return {
        ...state,
        company: {
          ...state.company,
          billingId: action.data.billingId,
        },
        fetching: false,
      }
    case actionTypes.REGISTER_COMPANY_AS_AGENT_ERROR:
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}

const registerCompanyAsAgentReducer = registerCompanyAsAgentReducerCreator()

export const companyReducer = combineReducers({
  company: reduceReducers(companyInfoReducer, registerCompanyAsAgentReducer),
  limits: limitsReducer,
  orders: ordersReducer,
  order: orderReducer,
  clientLogins,
  agentInfo,
  agentLogins,
  address,
  bankAccounts,
  staffInfo,
  headInfo,
  signerInfo,
  foundersInfo,
  beneficiariesInfo,
  contactPersonInfo,
  documentsInfo,
  managementInfo,
  buhInfo,
  liabilitiesInfo,
  bgAgentBinding,
  workGroups,
  statisticInfo,
  startSubProcess,
  baseSchemaList,
  agentStats,
  agentStatsByStatus,
}, {})

export const CompanyReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case actionTypes.COMPANY_GET_INFO_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actionTypes.COMPANY_GET_INFO_SUCCESS: 
      return {
        ...state,
        fetching: false,
        company: action.data.company,
      }
    case actionTypes.COMPANY_GET_INFO_ERROR:
      return {
        ...state,
        fetching: false,
      }
    case actionTypes.COMPANY_GET_LIMITS_PENDING:
      return {
        ...state, 
        fetching: true,
      }
    case actionTypes.COMPANY_GET_LIMITS_SUCCESS:
      return {
        ...state, 
        fetching: false,
        limits: action.data.limits,
      }
    case actionTypes.COMPANY_GET_LIMITS_ERROR:
      return {
        ...state, 
        fetching: false,
      }
    case actionTypes.COMPANY_GET_ORDERS_PENDING:
      return {
        ...state, 
        fetching: true,
      }
    case actionTypes.COMPANY_GET_ORDERS_SUCCESS:
      return {
        ...state, 
        fetching: false,
        orders: action.data.orders,
      }
    case actionTypes.COMPANY_GET_ORDERS_ERROR:
      return {
        ...state, 
        fetching: false,
      }
    case actionTypes.COMPANY_GET_ORDER_PENDING:
      return {
        ...state, 
        fetching: true,
      }
    case actionTypes.COMPANY_GET_ORDER_SUCCESS:
      return {
        ...state, 
        fetching: false,
        order: action.data.order,
      }
    case actionTypes.COMPANY_GET_ORDER_ERROR:
      return {
        ...state, 
        fetching: false,
      }
    case actionTypes.COMPANY_GET_CLIENT_LOGINS_PENDING:
      return {
        ...state, 
        fetching: true,
      }
    case actionTypes.COMPANY_GET_CLIENT_LOGINS_SUCCESS:
      return {
        ...state, 
        fetching: false,
        clientLogins: action.data.clientLogins,
      }
    case actionTypes.COMPANY_GET_CLIENT_LOGINS_ERROR:
      return {
        ...state, 
        fetching: false,
      }
    case actionTypes.COMPANY_GET_AGENT_LOGINS_PENDING:
      return {
        ...state, 
        fetching: true,
      }
    case actionTypes.COMPANY_GET_AGENT_LOGINS_SUCCESS:
      const { agentLogins } = action.data
      return {
        ...state, 
        fetching: false,
        agentLogins,
      }
    case actionTypes.COMPANY_GET_AGENT_LOGINS_ERROR:
      return {
        ...state, 
        fetching: false,
      }
    case actionTypes.COMPANY_ADD_AGENT_LOGIN_PENDING:
      return {
        ...state, 
        fetching: true,
      }
    case actionTypes.COMPANY_ADD_AGENT_LOGIN_ERROR:
    case actionTypes.COMPANY_ADD_AGENT_LOGIN_SUCCESS:
      return {
        ...state, 
        fetching: false,
      }
    case actionTypes.COMPANY_ADD_CLIENT_LOGIN_PENDING:
      return {
        ...state, 
        fetching: true,
      }
    case actionTypes.COMPANY_ADD_CLIENT_LOGIN_ERROR:
    case actionTypes.COMPANY_ADD_CLIENT_LOGIN_SUCCESS:
      return {
        ...state, 
        fetching: false,
      }
    case actionTypes.COMPANY_REMOVE_AGENT_LOGIN_PENDING:
      return {
        ...state, 
        fetching: true,
      }
    case actionTypes.COMPANY_REMOVE_AGENT_LOGIN_ERROR:
    case actionTypes.COMPANY_REMOVE_AGENT_LOGIN_SUCCESS:
      return {
        ...state, 
        fetching: false,
      }
    case actionTypes.COMPANY_REMOVE_CLIENT_LOGIN_PENDING:
      return {
        ...state, 
        fetching: true,
      }
    case actionTypes.COMPANY_REMOVE_CLIENT_LOGIN_ERROR:
    case actionTypes.COMPANY_REMOVE_CLIENT_LOGIN_SUCCESS:
      return {
        ...state, 
        fetching: false,
      }
    case actionTypes.COMPANY_GET_ADDRESS_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actionTypes.COMPANY_GET_ADDRESS_SUCCESS:
      return {
        ...state,
        address: action.data.address,
        fetching: false,
      }
    case actionTypes.COMPANY_GET_ADDRESS_ERROR:
      return {
        ...state,
        fetching: false,
      }
    case actionTypes.COMPANY_GET_BANK_ACCOUNTS_PENDING:
      return {
        ...state,
        bankAccountsFetching: true,
      }
    case actionTypes.COMPANY_GET_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        bankAccountsFetching: false,
        bankAccounts: action.data.bankAccounts,
      }
    case actionTypes.COMPANY_GET_BANK_ACCOUNTS_ERROR:
      return {
        ...state,
        bankAccountsFetching: false,
      }
    default:
      return state
  }
}

export default CompanyReducer