import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import _ from 'lodash'

import { Burger } from '../../components/Burger'
import Modal from '../../containers/Modal'
import Dashboard from '../../containers/Dashboard'
import Filters from '../../components/Filters'
import TasksGroupActions from '../../components/GroupActions/Tasks'
import TasksList from '../../components/List/Tasks'
import EmptyTasksList from '../../components/Empty/TasksList'
import TaskCamundaDetail from '../../containers/Detail/TaskCamunda'
import TaskCustomDetail from '../../containers/Detail/TaskCustom'
import { ClientAllTasks } from '../../components/CustomDetail/Form'

import {
  getTasks,
  getNextTasksPage,
  setTasksFilter,
  clearAllFilters,
  selectTask,
  unselectTask,
  unselectAllTasks
} from '../../redux/Tasks/actions'
import { retryResendToComplete, clearTaskInfo } from '../../redux/Task/actions'
import { clearDashboard, getDashboard } from '../../redux/Dashboard/actions'
import { authenticationUser } from '../../redux/User/actions'
import { selectTasks } from '../../redux/Bulk/actions'
// import * as User from '../../services/api/user'
import ClaimTask from '../../components/ClaimTask'
import AssigneeChange from '../../components/AssigneeChange'
import {
  ASSIGNEE__CHANGE,
  PRINT__GUARANTEE,
  PRINT__GUARANTEE__ASSIGN,
  RETRY__RESEND,
} from '../../constants/bulkOperations'
// import * as TaskService from '../../services/api/task'
import PrintGuarantee from '../../components/PrintGuarantee'
import PrintGuaranteeAssign from '../../components/PrintGuaranteeAssign'
import { getSelectedTasks } from '../../redux/Tasks/selectors'
import { ConfirmModal } from '../../components/ConfirmModal'

import { plural, getElementHeight, getFilteredRequest } from './../../utils'

import Header from './../../containers/Header'

const isEmpty = require('lodash/isEmpty')

class Tasks extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    isFetchingNext: PropTypes.bool.isRequired,
    list: PropTypes.array,
    filters: PropTypes.array,
    nextPage: PropTypes.number.isRequired,
    hasMorePage: PropTypes.bool.isRequired,
    taskTypes: PropTypes.array,
    // dispatch: PropTypes.func.isRequired,
    task: PropTypes.array,
  }

  static defaultProps = {
    task: []
  }

  state = {
    isFixed: false,
    showConfirmModal: false,
    selectedList: {},
  }

  constructor(props) {
    super(props)
    this.onGetTasksList = _.debounce(this.onGetTasksList.bind(this), 0)
    this.onChangeFilter = _.debounce(this.onChangeFilter, 500)
  }

  componentDidMount() {
    this.onGetTasksList()
    window.addEventListener('scroll', this.onLoadNextPage)
    window.addEventListener('resize', this.onLoadNextPage)
  }

  componentDidUpdate(prevProps) {
    const { location: { state = {} } = {}, hasMorePage, isFetchingTask } = this.props
    const { location: { state: oldState = {} } = {} } = prevProps

    if (state.setFilter && !_.isEqual(state.data, oldState.data)) {
      this.props.clearAllFilters()
      this.props.setTasksFilter(state.data)
    }

    const tasksHeight = this.getHeightTasksContainer()
    const bodyHeight = this.getHeightBody()
    if (hasMorePage && tasksHeight < bodyHeight) {
      this.onLoadNextPage()
    }

    if (!isFetchingTask && prevProps.isFetchingTask) {
      this.onCloseConfirmModal()
      this.onGetTasksList()
    }
  }

  componentWillUnmount() {
    this.props.clearAllFilters()
    this.props.clearPresetFilters();
    window.removeEventListener('scroll', this.onLoadNextPage)
    window.removeEventListener('resize', this.onLoadNextPage)
  }

  onGetTasksList = () => this.props.getTasks(this.props.tasksFilters)

  onClearAllFilters = () => {
    const filters = {}
    this.props.clearAllFilters()
    this.onChangeFilter(filters)
  }

  getHeightBody = () => getElementHeight('body')
  getHeightTasksContainer = () => getElementHeight('.block-list.block-list--tasks')

  onLoadNextPage = () => {
    const {
      task,
      tasksFilters,
      isFetchingNext,
      nextPage,
      hasMorePage,
      getNextTasksPage,
    } = this.props
    const { isFixed } = this.state

    if (!isFixed && window.pageYOffset > 0) {
      this.setState({ isFixed: true })
    } else if (isFixed && window.pageYOffset === 0) {
      this.setState({ isFixed: false })
    }

    const tasksHeight = this.getHeightTasksContainer()
    if (hasMorePage && !isFetchingNext && task.length > 0 && window.pageYOffset + window.innerHeight + 10 >= tasksHeight) {
      getNextTasksPage(nextPage, tasksFilters)
    }
  }

  onChangeFilter = (filters) => this.props.setTasksFilter(filters)

  onOpenDetail = (taskId, taskName) => {
    const { authenticationUser, history } = this.props

      authenticationUser(true, {
        success: () => history.push(`/tasks/${taskId}?section=home`, { title: taskName }),
        error: err => console.log(err),
      })
  }

  onOpenClaimTask = (taskId, taskName) => {
    const { authenticationUser, history } = this.props

    authenticationUser(true, {
      success: () => history.push(`/tasks?claim=${taskId}`, { title: taskName }),
      error: err => console.log(err),
    })
  }

  onSelectTask = (id, remove) => {
    const { selectTask, unselectTask } = this.props
    const { selectedList } = this.state

    if (selectedList[id]) {
      delete selectedList[id]
      unselectTask(id)
    } else {
      selectedList[id] = true
      selectTask(id)
    }
    this.setState({
      selectedList: { ...selectedList },
      lastSelected: id,
    })
  }

  onClearSelectedList = () => {
    const { selectedTasks, unselectTask } = this.props
    for (let i = 0; i < selectedTasks.length; i++) {
      unselectTask(selectedTasks[i].id)
    }
    this.setState({ selectedList: {} })
  }

  onActionClick = action => {
    const { selectedList } = this.state
    const { history, selectTasks } = this.props
    switch (action) {
      case ASSIGNEE__CHANGE:
        selectTasks(selectedList)
        history.push('/tasks?assigneeChange')
        // this.onClearSelectedList()
        break
      case PRINT__GUARANTEE:
        selectTasks(selectedList)
        history.push('/tasks?print-guarantee')
        // this.onClearSelectedList()
        break
      case PRINT__GUARANTEE__ASSIGN:
        selectTasks(selectedList)
        history.push('/tasks?print-guarantee-assign')
        // this.onClearSelectedList()
        break
      case RETRY__RESEND:
        selectTasks(selectedList)
        this.onOpenConfirmModal()
        break
      default:
    }
  }

  onCloseCustomDetail = () => {
    this.props.clearTaskInfo()
    this.props.history.push('/tasks')
  }

  onClickWithShift = (e, currentTaskId) => {
    const { selectedList, lastSelected } = this.state
    const { task } = this.props
    if (e.ctrlKey || e.metaKey) {
      if (isEmpty(selectedList)) {
        return
      } else {
        const lastSelectedTaskIdFiltered = task.findIndex(item => item.id === lastSelected)
        const currentTaskIdFiltered = task.findIndex(item => item.id === currentTaskId)

        if (lastSelectedTaskIdFiltered < currentTaskIdFiltered) {
          const slicedTasks = task.slice(lastSelectedTaskIdFiltered, currentTaskIdFiltered)
          this.onFillSelectedBySlicedTasks(slicedTasks)
        } else {
          const slicedTasks = task.slice(currentTaskIdFiltered + 1, lastSelectedTaskIdFiltered)
          this.onFillSelectedBySlicedTasks(slicedTasks)
        }
      }
    }
  }

  onFillSelectedBySlicedTasks = (array) => {
    const { selectTask } = this.props
    const { selectedList } = this.state
    for (let i = 0; i < array.length; i++) {
      const { id } = array[i]
      selectTask(id)
      selectedList[id] = true
    }

    this.setState({ selectedList: { ...selectedList } })
  }

  onSelectAllTasks = () => this.onFillSelectedBySlicedTasks(this.props.task)

  renderTasksList() {
    const { isFetching, isFetchingNext, task, tasksFilters } = this.props
    const { selectedList } = this.state

    if (!task.length && !isFetching) {
      return <EmptyTasksList />
    }

    return (
      <div className='block-list block-list--tasks'>
        <div className='board'>
          <div className='container-fluid'>
            <TasksList
              selectedTasks={selectedList}
              task={task}
              isLoading={isFetching}
              isLoadingNext={isFetchingNext}
              tasksFilters={tasksFilters}
              onOpenDetail={this.onOpenDetail}
              onOpenClaimTask={this.onOpenClaimTask}
              onSelectTask={this.onSelectTask}
              onClickWithShift={this.onClickWithShift}
            />
          </div>
        </div>
      </div>
    )
  }

  getFilterBody = () => getFilteredRequest('task', this.props.tasksFilters)

  renderButtons = () => {
    const {
      settings: { formType },
      processDefinitionKeys,
    } = this.props
    return (
      <>
        {processDefinitionKeys && processDefinitionKeys.length > 0
          ? <div className='btn-options'>
              <Link
                to={`?add-task${formType === 'custom' ? '=bg-pa-agent' : ''}`}
                className='btn-options__link'
              />
            </div>
          : null
        }
        <div className='btn-magic' style={!processDefinitionKeys ? {bottom: '20px'} : {}}>
          <Link to='?dashboard' className='btn-magic__link' />
        </div>
      </>
    )
  }

  onBulkAssigneeChange = async userId => {
    const { getTasks, tasksFilters } = this.props
    getTasks(tasksFilters)
  }

  onUnselectAllTasks = async () => {
    const { unselectAllTasks, history } = this.props
    history.push('/tasks')
    this.onClearSelectedList()
    unselectAllTasks()
  }

  onCloseAssigneeChangeModal = () => {
    this.onUnselectAllTasks()
  }

  onSubmitPrintModal = () => {
    this.onUnselectAllTasks()
    this.onGetTasksList()
  }

  onClosePrintModal = () => {
    this.onUnselectAllTasks()
  }

  onOkButtonAsssegneeChange = async () => {
    const { history } = this.props
    history.goBack()
  }

  onPrintGuaranteeAssignSuccess = () => this.props.history.push('/tasks?print-guarantee')

  onOpenConfirmModal = () => this.setState({ showConfirmModal: true })
  onCloseConfirmModal = () => {
    this.setState({ showConfirmModal: false })
    this.onClearSelectedList()
  }

  onGetRetryResendTasks = () => {
    const { selectedTasks } = this.props
    return selectedTasks.filter(item => item && item.bulkActions && item.bulkActions.includes('retryResend'))
  }

  onRetryResendToComplete = () => {
    const { retryResendToComplete } = this.props
    const retryResendTasks = this.onGetRetryResendTasks()
    const ids = retryResendTasks.map(item => item.id)
    retryResendToComplete(ids)
  }

  renderModalNode() {
    const {
      location: { state: routeState = {}, search },
      history,
      match,
      settings,
      presetFilters
    } = this.props
    const { selectedList } = this.state

    switch (true) {
      case search.search(/\?claim/) !== -1: {
        const clientInfo = search.match(/claim=[_A-Za-z0-9-]+/g)
        const clientId = clientInfo[0].split('=')[1]
        const { history } = this.props
        return (
          <Modal
            topPosition
            modalClass='modal-custom--empty'
            preventOutsideClick
            onCloseModal={history.goBack}
          >
            <ClaimTask
              clientId={clientId}
              onCloseClaimTask={history.push}
              history={history}
              state={routeState}
            />
          </Modal>
        )
      }
      case search === '?assigneeChange': {
        return (
          <Modal
            topPosition
            modalClass='modal-custom--empty'
            preventOutsideClick
            onCloseModal={this.onCloseAssigneeChangeModal}
          >
            <AssigneeChange
              selectedItems={selectedList}
              onClose={this.onCloseAssigneeChangeModal}
              onUserSelected={this.onBulkAssigneeChange}
              onOk={this.onOkButtonAsssegneeChange}
            />
          </Modal>
        )
      }
      case search === '?print-guarantee': {
        return (
          <Modal
            topPosition
            modalClass='modal-custom__fullscreen'
            preventOutsideClick
            onCloseModal={this.onClosePrintModal}
          >
            <PrintGuarantee
              onCancel={this.onClosePrintModal}
              onSuccess={this.onSubmitPrintModal}
            />
          </Modal>
        )
      }
      case search === '?print-guarantee-assign': {
        return (
          <Modal
            topPosition
            modalClass='modal-custom--empty'
            preventOutsideClick
            onCloseModal={this.onCloseAssigneeChangeModal}
          >
            <PrintGuaranteeAssign
              onSuccess={this.onUnselectAllTasks}
            />
          </Modal>
        )
      }
      case search === '?dashboard': {
        return (
          <Modal
            topPosition
            modalClass='modal-custom--dashboard '
            preventOutsideClick
            onCloseModal={history.goBack}
          >
            <Dashboard
              onSelectTasks={this.onChangeFilter}
              onCloseDashboard={history.push}
              {...presetFilters}
            />
          </Modal>
        )
      }
      case search.search(/\?client-deal/) !== -1: {
        const clientInfo = search.match(/client-deal=[_A-Za-z0-9-]+/g)
        const clientId = clientInfo[0].split('=')[1]
        return (
          <Modal
            topPosition
            modalClass='modal-custom--custom-detail'
            preventOutsideClick
            onCloseModal={() => {history.push('/tasks')}}
            fullWidth={true}
          >
            <ClientAllTasks id={clientId} />
          </Modal>
        )
      }
      case typeof match.params.id !== 'undefined': {
        const { title } = routeState

        if (settings.formType === 'custom') {
          return (
            <Modal
              topPosition
              modalClass='modal-custom--custom-detail'
              preventOutsideClick
              onCloseModal={this.onCloseCustomDetail}
            >
              <TaskCustomDetail
                id={match.params.id}
                locationQuery={search}
                title={title}
                onCloseDetail={this.onCloseCustomDetail}
              />
            </Modal>
          )
        } else {
          return (
            <Modal
              topPosition
              modalClass='modal-custom--wide-width'
              preventOutsideClick
              onCloseModal={() => {history.push('/tasks')}}
            >
              <TaskCamundaDetail
                id={match.params.id}
                title={title}
                onCloseDetail={() => {history.push('/tasks')}}
              />
            </Modal>
          )
        }
      }
      default: {
        return null
      }
    }
  }

  render() {
    const {
      isFetching,
      list,
      filters,
      taskTypes,
      selectedTasks,
      tasksFilters,
      isFetchingTask,
      ismanager,
    } = this.props

    const {
      showConfirmModal,
      selectedList,
    } = this.state

    const contentNode = this.renderModalNode()

    const selectedTasksCount = Object.keys(selectedList).length

    const retryResendTasks = this.onGetRetryResendTasks()
    const retryResendTasksCount = retryResendTasks.length

    return (
      <>
        <Burger />
        <Header burger>
          <div className='container-fluid'>
            <Filters
              isFetching={isFetching}
              isDisable={!list.length && !Object.keys(filters).length}
              filters={filters}
              taskTypes={taskTypes}
              isHidden={Boolean(selectedTasksCount)}
              onChangeFilter={this.onChangeFilter}
              onFilterStart={this.getFilterBody}
              selectedFilters={tasksFilters}
              onClearAllFilters={this.onClearAllFilters}
              onSelectAllTasks={this.onSelectAllTasks}
            />
            <TasksGroupActions
              taskCount={selectedTasksCount}
              isActive={Boolean(selectedTasksCount)}
              onClearAll={this.onClearSelectedList}
              onActionClick={this.onActionClick}
              selectedTasks={selectedTasks}
              ismanager={ismanager}
            />
          </div>
        </Header>
        {this.renderTasksList()}
        {this.renderButtons()}
        <CSSTransition
          timeout={100}
          in={Boolean(contentNode)}
          classNames='fade'
        >
          <div>{contentNode}</div>
        </CSSTransition>

        {showConfirmModal &&
          <ConfirmModal
            title={`
              Вы уверены, что хотите повторить попытку интеграции для 
              ${retryResendTasksCount} 
              ${plural(retryResendTasksCount, 'задачи', 'задач', 'задач')}?`
            }
            submitTitle='Да'
            cancelTitle='Нет'
            isSending={isFetchingTask}
            onCancel={this.onCloseConfirmModal}
            onSubmit={this.onRetryResendToComplete}
          />
        }

      </>
    )
  }
}

const mapStateToProps = ({ Tasks, Task, User, Dashboard }) => {
  const { _byIds, selectedTaskIds } = Tasks

  return {
    isFetching: Tasks.isFetching,
    isFetchingNext: Tasks.isFetchingNext,
    isFetchingTask: Task.isFetching,
    list: Tasks.order,
    task: Tasks.task,
    presetFilters: {
      total: Dashboard.total,
      blocks: Dashboard.blocks,
      footer: Dashboard.footer
    },
    tasksFilters: Tasks.filters,
    filters: User.filters.tasks,
    nextPage: Tasks.page + 1,
    hasMorePage: Tasks.more,
    taskTypes: Tasks.task_types,
    settings: User.settings,
    selectedTasks: getSelectedTasks({ _byIds, selectedTaskIds }),
    processDefinitionKeys: User.processDefinitionKeys,
    ismanager: User.ismanager,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getDashboard: () => {
      dispatch(getDashboard())
    },
    getTasks: (filters) => {
      dispatch(getTasks(filters))
    },
    getNextTasksPage: (nextPage, tasksFilters) => {
      dispatch(getNextTasksPage(nextPage, tasksFilters))
    },
    selectTask: (id) => {
      dispatch(selectTask(id))
    },
    unselectTask: (id) => {
      dispatch(unselectTask(id))
    },
    setTasksFilter: (filters) => {
      dispatch(setTasksFilter(filters))
    },
    unselectAllTasks: () => {
      dispatch(unselectAllTasks())
    },
    clearAllFilters: () => {
      dispatch(clearAllFilters())
    },
    clearTaskInfo: () => {
      dispatch(clearTaskInfo())
    },
    authenticationUser: (needNotification, { success, error }) => {
      dispatch(authenticationUser(needNotification))
        .then(success)
        .catch(error)
    },
    selectTasks: (selectedList) => {
      dispatch(selectTasks(selectedList))
    },
    retryResendToComplete: (ids) => {
      dispatch(retryResendToComplete(ids))
    },
    clearPresetFilters: () => {
      dispatch(clearDashboard())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks)