import React from 'react'
import injectSheet from 'react-jss'
import { Modal } from './../../index'
import { formattedNumber } from '../../../utils'

const styles = {
	root: {
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
  item: {
    background: '#E4E4E4',
    marginRight: 14,
    padding: [16, 20, 17],
    borderRadius: 12,
    display: 'inline-block',
    '&:last-child': {
      marginRight: 0,
    },
  },
  label: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.25,
    color: '#97969c',
  },
}

const Limits = ({ 
  calculatedAmount,
  finalAmount,
  classes, 
}) => {
  const calculated = !calculatedAmount ? 'НЕ РАССЧИТАН' : `${formattedNumber(calculatedAmount)} ₽`
  const final = !finalAmount ? 'НЕ РАССЧИТАН' : `${formattedNumber(finalAmount)} ₽`
  
  return (
    // <Modal.Block iconName='info' title='Информация по лимитам'>
    //   <Modal.Content>
        <div className={classes.root}>
          <div className={classes.item}>
            <Modal.Value>{calculated}</Modal.Value>
            <div className={classes.label}>Автоматически рассчитанный лимит</div>
          </div>
          <div className={classes.item}>
            <Modal.Value>{final}</Modal.Value>
            <div className={classes.label}>Итоговый лимит</div>
          </div>
        </div>
    //   </Modal.Content>
    // </Modal.Block>
  )
}

export default injectSheet(styles)(Limits)