export function guid() {
  function s4() {
    var hexSize = 16
    var chunkSize = 0x10000
    return Math.floor((1 + Math.random()) * chunkSize)
      .toString(hexSize)
      .substring(1)
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  )
}
