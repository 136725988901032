import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Icon } from '../index'

const useStyles = createUseStyles({
  checkbox: {
    display: 'block',
    position: 'relative',
    '& + &': {
      marginTop: 6,
    },
    '&.error': {
      '& [type=checkbox]': {
        '& + label': {
          '&:before': {
            borderColor: 'red',
          },
        },
      },
      '& + .error': {
        '& [type=checkbox]': {
          '& + label': {
            borderLeftColor: 'transparent',
          },
        },
      },
    },
    '& input[type=checkbox]': {
      display: 'none',
      '&:checked': {
        '& + label': {
          '&:before': {
            background: '#0b1f35',
            borderColor: '#0b1f35',
          },
          '& svg': {
            fill: '#fff',
            opacity: 1,
          },
        },
      },
      '&:disabled': {
        '& + label': {
          cursor: 'default',
          '&:before': {
            background: '#eee',
          },
        },
      },
      '& + label': {
        margin: 0,
        fontSize: 14,
        lineHeight: 20/14,
        paddingLeft: 26,
        cursor: 'pointer',
        display: 'inline-block',
        verticalAlign: 'top',
        '&:before': {
          content: '""',
          background: '#fff',
          width: 20,
          height: 20,
          border: [1, 'solid', 'rgba(80, 78, 91, 0.4)'],
          borderRadius: 3,
          textAlign: 'center',
          display: 'block',
          position: 'absolute',
          left: 0,
          top: 0,
        },
        '& svg': {
          fill: '#fff',
          width: 12,
          height: 10,
          position: 'absolute',
          left: 4,
          top: 5,
          display: 'block',
          opacity: 0,
        },
      },
    },
  },
})

export const Checkbox = ({
  id = -1,
  className,
  label,
  error,
  title,
  checked = false,
  ...otherProps
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.checkbox, className, { error })}>
      <input id={id} type='checkbox' checked={checked} {...otherProps} />
      <label htmlFor={id} title={title}>
        <Icon iconName='check-for-checkbox' />
        {label}
      </label>
    </div>
  )
}