import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
	root: {
		background: '#f3f3f3',
		marginTop: '10px',
		borderRadius: '16px',
		padding: '20px 70px 20px 74px',
  },
}

const Content = ({ 
	children,
	className,
	classes,
}) => 
	<div className={classnames(classes.root, className)}>
		{children}
	</div>

export default injectSheet(styles)(Content)