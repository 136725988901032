import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 1,
    top: 12,
    right: 64,
    cursor: 'pointer',
    userSelect: 'none',
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
      '& > div': {
        textDecoration: 'underline',
      },
    },
  },
  image: {
    width: 16,
    marginLeft: 8,
  },
  link: {
    color: '#000',
    fontSize: 12,
  },
})

export const FullScreenIcon = ({
  className,
  onClick,
  fullWidth,
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.container, className)} onClick={onClick}>
      <div className={classes.link}>{fullWidth ? 'уменьшить' : 'увеличить'}</div>
      <img
        className={classes.image}
        alt='fullscreen'
        src={require(fullWidth ? './fullscreen-down.svg' : './fullscreen-up.svg')}
      />
    </div>
  )
}