import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Col } from 'reactstrap'
import { InputText, Row } from '../../../../layout'
import { getFile } from '../../../../redux/Files/actions'
import { ReportTab } from '../../Layout/ReportTab'
import { commonStatusStyles } from '../all3d/styles'
import { YYYYMMDD } from '../../../../utils'

export const PonyExpressReport = commonStatusStyles(({
  classes,
  onCancel,
  settings,
}) => {
  const dispatch = useDispatch()

  const now = new Date()
  const firstDayOfMonth = YYYYMMDD(new Date(now.getFullYear(), now.getMonth(), 1))

  const [dateTo, setDateTo] = useState(firstDayOfMonth)
  const [loading, setLoading] = useState(false)
  const onChangeDateTo = (e) => setDateTo(e.target.value)

  const valid = dateTo

  const onDownloadFile = () => {
    if (valid && !loading) {
      setLoading(true)
      const url = `/api/doc-template/form/PonyExpress/bg_ponyExpressDeliveryForm.xlsx?startTime=${dateTo}`
      const name = `ponyExpress-${dateTo}.xlsx`

      const onSuccess = () => setLoading(false)
      const onError = () => setLoading(false)
      dispatch(getFile({ url, name, onSuccess, onError }))
    }
  }

  return (
    <ReportTab
      onOk={onDownloadFile}
      onCancel={onCancel}
      okButtonText='Получить отчёт'
      disabled={!valid}
      loading={loading}
      title={settings.description}
    >
      <Col className={classes.form}>
        <Row>
          <div className='col-6'>
            <InputText
              type='date'
              name='dateTo'
              label='Дата создания задач'
              value={dateTo}
              maxDate={new Date(dateTo)}
              onChange={onChangeDateTo}
            />
          </div>
        </Row>
      </Col>
    </ReportTab>
  )
})