import React, { useEffect, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyInfo, getCompanyLimits, deleteCompanyLimits } from '../../../../redux/Company/actions'
import CustomDetailMenu from '../../Menu'
import CustomDetailHeader from '../../Header'
import Overlay from '../../../Overlay'
import StartSubProcess from '../../../StartSubProcess'
import { UI } from '../../../../layout'

// import LimitsInfo from '../../CompanyInfo/Limits'
import { Limits } from '../../CompanyInfo/Limits'
import CompanyInfo from '../../CompanyInfo/Info'
import Deals from '../../CompanyInfo/Deals'
import { Dbo } from '../../CompanyInfo/Dbo'
import ClientInfo from '../../CompanyInfo/ClientInfo'
import AgentInfo from '../../CompanyInfo/AgentInfo'
// import Address from '../../CompanyInfo/Address'
// import BankAccounts from '../../CompanyInfo/BankAccounts'
// import Staff from '../../CompanyInfo/Staff'
import CompanyHead from '../../CompanyInfo/CompanyHead'
import Signer from '../../CompanyInfo/Signer'
import Founders from '../../CompanyInfo/Founders'
import Beneficiaries from '../../CompanyInfo/Beneficiaries'
// import ContactPerson from '../../CompanyInfo/ContactPerson'
import { Documents } from '../../CompanyInfo/Documents'
// import Management from '../../CompanyInfo/Management'
import Buh from '../../CompanyInfo/Buh'
import Liabilities from '../../CompanyInfo/Liabilities'
import BgAgentBinding from '../../CompanyInfo/BgAgentBinding'
// import Statistic from '../../CompanyInfo/Statistic'

const useStyles = createUseStyles({
  actions: {
    marginTop: 16,
    marginRight: 16,
    display: 'inline-block',
    verticalAlign: 'top',
    '& > div': {
      ...UI.addButton,
      width: 200,
      minHeight: 35,
      padding: [5, 0, 4, 0],
      display: 'inline-block',
      verticalAlign: 'top',
      '& span': {
        color: 'transparent',
      },
    },
  },
  resetLimit: {
    ...UI.addButton,
    background: '#ed1b24',
    width: 200,
    minHeight: 35,
    marginTop: 16,
    padding: [5, 0, 4, 0],
    display: 'inline-block',
    verticalAlign: 'top',
  },
})

const getSection = (search) => {
  const params = new URLSearchParams(search)
  return params.get('section')
}

const ClientAllTasksForm = ({
  id,
  location,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { company, isSuccess, fetching } = useSelector(state => state?.Company?.company)
  const { limits } = useSelector(state => state?.Company?.limits)
  const { access = {} } = useSelector(state => state?.Settings)
  const {
    REACT_APP_SHOW_DBO_PRODUCTS,
    REACT_APP_BG_AGENT_BINDING,
    REACT_APP_CAN_DELETE_COMPANY_LIMITS,
  } = useSelector(state => state?.Environment?.environment)

  const SHOW_DBO_PRODUCTS = REACT_APP_SHOW_DBO_PRODUCTS === 'true'
  const IS_BG_AGENT_BINDING = REACT_APP_BG_AGENT_BINDING === 'true'
  const CAN_DELETE_COMPANY_LIMITS = REACT_APP_CAN_DELETE_COMPANY_LIMITS === 'true'

  const sections = [{
    items: [
      { slug: 'limits', name: 'Лимиты' },
      { slug: 'deals', name: 'Cписок сделок', readonly: true },
      SHOW_DBO_PRODUCTS ? { slug: 'dbo', name: 'Продукты ДБО', readonly: true } : {},
      { slug: 'buh', name: 'Бух отчетность', readonly: true },
      // { slug: 'addresses', name: 'Адреса' },
      // { slug: 'invoices', name: 'Счета' },
      // { slug: 'management-structure', name: 'Структура органов управления', readonly: true },
      { slug: 'head', name: 'Руководитель' },
      { slug: 'guarantee-signer', name: 'Подписант по гарантии' },
      { slug: 'founders', name: 'Учредители' },
      { slug: 'beneficiaries', name: 'Бенефициарные владельцы' },
      // { slug: 'current-obligations', name: 'Действующие обязательства' },
      // { slug: 'staff-information', name: 'Сведения о персонале' },
      // { slug: 'contact-person', name: 'Контактное лицо принципала' },
      { slug: 'documents', name: 'Документы компании' },
      // { slug: 'bank-signer', name: 'Подписант по договору с банком' },
      // { slug: 'company-info', name: 'Информация о компании' },
      { slug: 'agentInfo', name: 'Параметры агента' },
      { slug: 'clientLogins', name: 'Параметры клиента' },
      // { slug: 'address', name: 'Адреса' },
      // { slug: 'bankAccounts', name: 'Счета' },
      { slug: 'liabilities', name: 'Действующие обязательства' },
      IS_BG_AGENT_BINDING ? { slug: 'bgAgentBinding', name: 'Закрепление' } : {},
      // { slug: 'statistics', name: 'Статистика', readonly: true }
    ]
  }]

  const {
    billingId,
    principalType = 'Компания',
    displayName = '—',
    INN = '—',
    KPP = '—',
    OGRN = '—',
  } = company || {}

  const currentSection = getSection(location.search)

  useEffect(() => {
    dispatch(getCompanyInfo(id))
  }, [id, dispatch])

  const onGetCompanyLimits = useCallback(() => {
    dispatch(getCompanyLimits(id))
  }, [id, dispatch])

  useEffect(() => {
    if (currentSection ==='limits') {
      onGetCompanyLimits()
    }
  }, [currentSection, onGetCompanyLimits])

  const onDeleteLimits = () => dispatch(deleteCompanyLimits(id))

  const renderSection = () => {
    if (!isSuccess) {
      return null
    }

    switch (currentSection) {
      // case 'limits': return <LimitsInfo companyId={id} />
      case 'limits': return (
        <>
          <Limits onGetCompanyLimits={onGetCompanyLimits} readOnly />
          <StartSubProcess
            className={classes.actions}
            variable={{ name: 'companyId', value: id }}
            companyId={id}
            onlyChangeLimit
          />
          {limits && CAN_DELETE_COMPANY_LIMITS &&
            <div className={classes.resetLimit} onClick={onDeleteLimits}>Обнуление лимита</div>
          }
        </>
      )
      case 'deals': return <Deals companyId={id} {...company} />
      case 'dbo': return <Dbo companyId={id} />
      case 'clientLogins': return <ClientInfo companyId={id} />
      case 'agentInfo': return <AgentInfo companyId={id} billingId={billingId} />
      // case 'address': return <Address companyId={id} />
      // case 'bankAccounts': return <BankAccounts companyId={id} />
      // case 'staff-information': return <Staff companyId={id} />
      case 'head': return <CompanyHead companyId={id} />
      case 'guarantee-signer': return <Signer companyId={id} />
      case 'founders': return <Founders companyId={id} />
      case 'beneficiaries': return <Beneficiaries companyId={id} />
      // case 'contact-person': return <ContactPerson companyId={id} />
      case 'documents': return <Documents companyId={id} {...company} />
      // case 'management-structure': return <Management companyId={id} />
      case 'buh': return <Buh companyId={id} />
      case 'liabilities': return <Liabilities companyId={id} />
      case 'bgAgentBinding': return <BgAgentBinding companyId={id} />
      // case 'statistics': return <Statistic companyId={id} />
      default: return <CompanyInfo companyId={id} />
    }
  }

  const isAvailableSection = (sectionName) => access[sectionName] !== false

  const availableSections = sections
    .map(section => ({
      ...section,
      items: section.items.filter(({ slug }) => isAvailableSection(slug))
    }))
    .filter(x => x.items.length > 0)

  return (
    <div className='task-form'>
      <div className='container p-0'>
        <div className='row no-gutters flex-nowrap'>
          <CustomDetailMenu title='Компания' sections={availableSections} companyId={id} />
          <form className='task-form__wrap' style={{position: 'relative'}}>
            {fetching 
              ? <Overlay size='big'/>
              : <div className='block block--readonly'>
                  <div className='container'>
                    <CustomDetailHeader
                      companyId={id}
                      section={principalType}
                      title={displayName}
                      inn={INN}
                      kpp={KPP}
                      ogrn={OGRN}
                      withoutHr={currentSection === 'buh'}
                    />
                    {renderSection()}
                  </div>
                </div>
            }
          </form>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ClientAllTasksForm)