import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import { BurgerMenu } from '../BurgerMenu'

const useStyles = createUseStyles({
  root: {
    position: 'fixed',
    left: 23,
    top: 34,
    width: 18,
    height: 15,
    zIndex: 250,
    cursor: 'pointer',
    '& span': {
      background: '#151B3D',
      height: 2,
      width: 18,
      borderRadius: 5,
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      margin: 'auto',
      '&:nth-child(1)': {
        transform: 'translateY(-6px) rotateZ(0)',
      },
      '&:nth-child(3)': {
        transform: 'translateY(6px) rotateZ(0)',
      },
    },
    '&:after': {
      content: '"Закрыть"',
      position: 'absolute',
      left: '100%',
      marginLeft: 19,
      fontWeight: 'bold',
      color: '#151B3D',
      fontSize: 12,
      lineHeight: 15/12,
      textTransform: 'uppercase',
      opacity: 0,
      transform: 'translateY(-50px)',
      transition: '400ms',
    },
    '&.active': {
      '& span': {
        '&:nth-child(1)': {
          animation: 'burgerActive_1 ease 400ms both',
        },
        '&:nth-child(2)': {
          animation: 'burgerActive_2 ease 400ms both',
        },
        '&:nth-child(3)': {
          animation: 'burgerActive_3 ease 400ms both',
        },
      },
      '&:after': {
        transform: 'translateY(0)',
        opacity: 1,
      },
    },
    '&.noactive': {
      '&:nth-child(1)': {
        animation: 'burgerNoactive_1 ease 400ms both',
      },
      '&:nth-child(2)': {
        animation: 'burgerNoactive_2 ease 400ms both',
      },
      '&:nth-child(3)': {
        animation: 'burgerNoactive_3 ease 400ms both',
      },
    },
  },
})

export const Burger = () => {
  const classes = useStyles()

  const [active, setActive] = useState(false)

  const onOpen = () => setActive(true)
  const onClose = () => setActive(false)

  const onClick = () => active
    ? onClose()
    : onOpen()

  return (
    <>
      <div className={classnames(classes.root, { active })} onClick={onClick}>
        <span />
        <span />
        <span />
      </div>
      <BurgerMenu
        active={active}
        onActive={onOpen}
        onClose={onClose}
      />
    </>
  )
}