import React from 'react'
import { debounce } from 'lodash'
import { DEBONCE_TIMER } from './utils'
import { DateFilter, CompanyNameFilter } from './components'
import { Icon } from './../../../../layout'

import './RegistersPageFilter.style.scss'

const defaultState = {
  createdDateTime: '',
  companyName: ''
}

export function RegistersPageFilter({ onFiltersChange, config }) {
  const [filters, setFilters] = React.useState(defaultState)

  const handleFiltersChange = React.useCallback(
    debounce(filters => {
      const transformed = Object.values(filters)
        .filter(filterValue => Boolean(filterValue))
        .join(',')

      onFiltersChange(transformed)
    }, DEBONCE_TIMER),
    []
  )

  const handleCompanyNameFilterChange = React.useCallback(
    filterQuery => {
      setFilters(prevState => {
        const newState = { ...prevState, companyName: filterQuery }

        handleFiltersChange(newState)

        return newState
      })
    },
    [handleFiltersChange]
  )

  const handleDateFilterChange = React.useCallback(
    filterQuery => {
      setFilters(prevState => {
        const newState = { ...prevState, createdDateTime: filterQuery }

        handleFiltersChange(newState)

        return newState
      })
    },
    [handleFiltersChange]
  )

  const handleResetFilters = React.useCallback(() => {
    handleFiltersChange(defaultState)
    setFilters(defaultState)
  }, [handleFiltersChange])

  return (
    <div className="registers-filters-wrapper">
      <CompanyNameFilter
        onChangeFilter={handleCompanyNameFilterChange}
        resetFilter={!Boolean(filters.companyName)}
        name="companyName"
      />
      <div style={{ width: '30px' }} />
      <DateFilter
        onChangeFilter={handleDateFilterChange}
        resetFilter={!Boolean(filters.createdDateTime)}
        name="createdDateTime"
      />
      <div style={{ width: '30px' }} />
      {(filters.createdDateTime || filters.companyName) && (
        <div className="registers-filters-reset" onClick={handleResetFilters}>
          <Icon iconName="close2" />
          <span>Сбросить фильтры</span>
        </div>
      )}
    </div>
  )
}
