import { GET_TYPES, GET_STATUSES } from '../config'
import { sortBy } from '../../../../../utils'

const getKey = (value, array) => array.indexOf(value) > -1 ? value : ''

export const getFilteredItems = (items, filters) => {
  const types = GET_TYPES(items)
  const statuses = GET_STATUSES(items)
  return items
    .filter(({ fields }) => fields.find(({ id, value }) => {
      const keyType = getKey(value, types)
      return id === 'orderTypeName' && keyType && filters[0].indexOf(keyType) > -1
    }))
    .filter(({ fields }) => fields.find(({ id, value }) => {
      const keyStatus = getKey(value, statuses)
      return id === 'orderStatus' && keyStatus && filters[1].indexOf(keyStatus) > -1
    }))
    .sort((a, b) => sortBy(
      a.fields.find(({ id }) => id === 'provisionedDate'), 
      b.fields.find(({ id }) => id === 'provisionedDate'),
      'value',
      'desc',
      true
    ))
}