import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { getCompanyBankAccounts } from '../../../../redux/Company/actions'
import { Attribute } from '../Helpers/Attributes'

const BankAccount = (props) => {
  const noInfo = 'Нет информации'
  const bankName = _.get(props, 'bankName') || noInfo
  const number = _.get(props, 'number') || noInfo
  const corrNumber = _.get(props, 'corrNumber') || noInfo
  const BIK = _.get(props, 'BIK') || noInfo
  const cardFile = _.isEqual(_.get(props, 'cardFile'), true) ? 'Да' : 'Нет'
  const useInDocuments = _.isEqual(_.get(props, 'useInDocuments'), true) ? 'Да' : 'Нет'
  const index = _.get(props, 'index') || 0

  return (
    <div className='block-info'>
      <div className='block_head'>Счет №{index}</div>
        <div className='row'>
        <Attribute col='12' attr={{ label: 'Наименование банка', value: bankName }} />
        <Attribute col='4' attr={{ label: 'БИК', value: BIK, disabled: true }} />
        <Attribute col='4' attr={{ label: 'Коррсчет', value: corrNumber, disabled: true }} />
        <Attribute col='4' attr={{ label: 'Номер счета', value: number, disabled: true }} />
        <Attribute col='4' attr={{ label: 'Использовать в документах', value: useInDocuments, disabled: true }} />
        <Attribute col='4' attr={{ label: 'Картотека № 2', value: cardFile, disabled: true }} />
      </div>
    </div>
  )
}

export class BankAccounts extends Component {
  async loadData() {
    const { dispatch, companyId } = this.props
    dispatch(getCompanyBankAccounts(companyId))
  }

  componentDidMount() {
    if (!this.props.fetching) {
      this.loadData()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.loadData()
    }
  }

  render() {
    const { bankAccounts = [] } = this.props

    return bankAccounts.length === 0 
      ? <h4>нет информации о счетах</h4> 
      : bankAccounts.map((account, index) =>
          <BankAccount {...account} index={index + 1} key={index} />
        )
  }
}

const mapStateToProps = ({ Company }) => {
  return {
    ...Company.bankAccounts
  }
}

export default connect(mapStateToProps)(BankAccounts)