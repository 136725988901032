const normalizeColData = (col) => []
  .concat(col.form1 || [])
  .concat(col.form2 || [])
  .reduce((result, item) => {
    result[item.code] = item.endValue
    return result
  }, {})

export const buhDataToTableDataCols = (data) => data && data.length > 0
  ? data.map((col, index) => ({
      id: `${col}-${index}`,
      label: `${col.quarter} кв ${col.year}`,
      data: normalizeColData(col),
    }))
  : null