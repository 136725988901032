import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { THEME_COLOR } from '../../../config'

const styles = {
  root: {
    color: '#000',
    fontSize: 18,
    lineHeight: 1.8,
    fontWeight: 500,
    '& p': {
      color: '#898989',
      fontSize: 14,
      lineHeight: 1.4,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    '& a': {
      color: THEME_COLOR,
    },
  },
}

const Value = ({
  children,
  className,
  classes,
  onClick,
}) => <div className={classnames(classes.root, className)} onClick={onClick}>{children}</div>

export default injectSheet(styles)(Value)