import React from 'react';
import { Attribute } from '../Attributes'
import _ from 'lodash';
import { formattedDate } from '../../../../../utils'

export const User = ({ user = {} }) =>
	<div className="row">
		<Attribute col="4" attr={{label: 'Фамилия', value: user && user.lastName}}/>
		<Attribute col="4" attr={{label: 'Имя', value: user && user.firstName}}/>
		<Attribute col="4" attr={{label: 'Отчество', value: user && user.secondName}}/>
		<Attribute col="4" attr={{label: 'Дата рождения', value: user && formattedDate(user.birthDate)}}/>
		<Attribute col="8" attr={{label: 'Место рождения', value: user && user.birthPlace}}/>

		<Attribute col="4" attr={{label: 'Гражданство', value: _.get(user, 'citizenship.name')}}/>
		<Attribute col="4" attr={{label: 'Другое гражданство', value: _.get(user, 'otherCitizenship.name')}}/>
		<Attribute col="4" attr={{label: 'ИНН', value: user && user.INN}}/>

		<Attribute col="12" attr={{label: 'Адрес местонахождения', value: _.get(user, 'actualAddress.result')}}/>
		<Attribute col="12" attr={{label: 'Адрес регистрации', value: _.get(user, 'registrationAddress.result')}}/>

		<Attribute col="6" attr={{label: 'Телефон', value: _.get(user, 'contacts[0].phone')}}/>
		<Attribute col="6" attr={{label: 'E-mail', value: _.get(user, 'contacts[0].email')}}/>
	</div>