import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  row: {
    margin: [0, -10],
    '& + &': {
      marginTop: 10,
    },
  },
})

export const Row = ({ children, className }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.row, className)}>
      {children}
    </div>
  )
}
