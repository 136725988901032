import * as actionTypes from './actionTypes'

const initialState = {
  registers: [],
  isFetching: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REGISTERS:
      return {
        ...state,
        isFetching: true
      }
    case actionTypes.GET_REGISTERS_SUCCESS:
      return {
        ...state,
        registers: action.data,
        isFetching: false
      }
    case actionTypes.GET_REGISTERS_ERROR:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}
