import { FileUploader } from './FileUploader'
import * as actions from '../../../../redux/RegisterUploads/actions'
import { connect } from 'react-redux'

function mapDispatchToProps(dispatch) {
  return {
    setUploadFile: files => dispatch(actions.setUploadFile(files))
  }
}

export const FileUploaderContainer = connect(
  null,
  mapDispatchToProps
)(FileUploader)
