import React, { useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Collapse } from 'reactstrap'
import H2 from './../H2'
import { Icon } from '../..'
import { useSelector } from 'react-redux'

const useStyles = createUseStyles({
  root: {
    marginTop: 23,
    position: 'relative',
    '& + &': {
      marginTop: 28,
    },
  },
  wrapper: {
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
  },
  icon: {
    width: 16,
    height: 16,
    marginLeft: 8,
    display: 'inline-block',
    verticalAlign: 'middle',
    transition: 'transform 300ms ease-in-out',
    transform: 'rotate(180deg)',
    position: 'absolute',
    top: 8,
    right: 0,
    '&.open': {
      transform: 'rotate(0deg)',
    },
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  title: {
    display: 'flex',
    marginLeft: 30,
    marginRight: 30,
    cursor: 'pointer',
  },
  linkRight: {
    marginLeft: 'auto',
    marginRight: 40
  },
  linkSignDocs: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: 4,
  },
})

export const Block = ({ 
  children,
  iconName,
  title,
  emailToken,
  orderId
}) => {
  const classes = useStyles()

  const { REACT_APP_AGENT_CABINET_URL } = useSelector(state => state?.Environment?.environment)

  const [open, setOpen] = useState(true)

  const makeOpen = () => setOpen(!open)

  return (
    <div className={classes.root}>
      <H2 className={classes.title} icon={{ name: iconName }} onClick={makeOpen}>
        <div className={classes.wrapper}>{title}</div>
        <div className={classnames(classes.icon, { open })}>
          <Icon iconName='arrow-collapse' />
        </div>
        {emailToken &&
          <a
            className={classes.linkSignDocs}
            href={`${REACT_APP_AGENT_CABINET_URL}/sign-docs/?token=${emailToken}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            (Ссылка на подписание документов)
          </a>
        }
        {orderId &&
          <a
            className={classes.linkRight}
            href={`/download/zip/bgOrder/${orderId}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            Скачать документы по заявке
          </a>
        }
      </H2>
      <Collapse isOpen={open}>{children}</Collapse>
    </div>
  )
}