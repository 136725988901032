import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { claimTask } from '../../services/api/user'
import { setErrorContent } from '../../redux/Error/actions'
import { setAssigned } from '../../redux/Tasks/actions'

const ClaimTask = ({
  history,
  clientId,
  onCloseClaimTask,
  setAssigned,
  setErrorContent,
}) => {
  const claimTaskById = async (taskId) => {
    try {
      const { isSuccess } = await claimTask(taskId)
      if (isSuccess) {
        setAssigned(taskId)
        history.push(`/tasks/${taskId}`)
        return false
      } else {
        history.push(`/tasks`)
        setErrorContent('Не удалось назначить задачу.')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <form>
      <div className='restore-pass__title'>Назначить задачу на себя?</div>
      <div className='form-group'>
        <button type='button' className='btn btn-block btn-white' onClick={onCloseClaimTask}>Нет</button>
        <button type='button' className='btn btn-block btn-white' onClick={() => claimTaskById(clientId)}>Да</button>
      </div>
    </form>
  )
}

ClaimTask.propTypes = {
  clientId: PropTypes.string.isRequired,
  onCloseClaimTask: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired,
}

const mapStateToProps = (store) => {
  return {
    
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAssigned: (taskId) => {
      dispatch(setAssigned(taskId))
    },
    setErrorContent: (error) => {
      dispatch(setErrorContent(error))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimTask)