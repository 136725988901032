import * as actionTypes from './actionTypes'

const initialState = {
  isFetching: false,
  isFetchingPrintGuaranteeAsign: false,
  bankSgners: {
    isFetching: false,
    error: false,
    bankSigners: [],
  },
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.TASK_FETCH:
      return {
        ...state,
        isFetching: true
      }
    case actionTypes.TASK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.data,
      }
    case actionTypes.TASK_ERROR:
      return {
        ...state,
        isFetching: false
      }
    case actionTypes.TASK_CLEAR:
      return {
        ...initialState
      }
    case actionTypes.RETRY_RESEND_TO_COMPLETE:
      return {
        ...state,
        isFetching: true
      }
    case actionTypes.RETRY_RESEND_TO_COMPLETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case actionTypes.RETRY_RESEND_TO_COMPLETE_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    case actionTypes.GET_BANK_SIGNERS:
      return {
        ...state,
        bankSigners: {
          ...state.bankSigners,
          error: false,
          isFetching: true,
        },
      }
    case actionTypes.GET_BANK_SIGNERS_SUCCESS:
      return {
        ...state,
        bankSigners: {
          ...state.bankSigners,
          bankSigners: action.data.bankSigners,
          error: false,
          isFetching: false,
        },
      }
    case actionTypes.GET_BANK_SIGNERS_ERROR:
      return {
        ...state,
        bankSigners: {
          ...state.bankSigners,
          error: action.error,
          isFetching: false,
        },
      }
    case actionTypes.PRINT_GUARANTEE_ASSIGN:
      return {
        ...state,
        error: false,
        isFetchingPrintGuaranteeAsign: true,
      }
    case actionTypes.PRINT_GUARANTEE_ASSIGN_SUCCESS:
      return {
        ...state,
        error: false,
        isFetchingPrintGuaranteeAsign: false,
      }
    case actionTypes.PRINT_GUARANTEE_ASSIGN_ERROR:
      return {
        ...state,
        isFetchingPrintGuaranteeAsign: false,
        error: action.error,
      }
    default:
      return state
  }
}