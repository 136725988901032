import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  col1: {
    width: '8.33%',
    padding: [0, 10],
    display: 'inline-block',
    verticalAlign: 'top',
  },
})

export const Col1 = ({ children, className }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.col1, className)}>
      {children}
    </div>
  )
}