import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  container: {
    backgroundColor: '#ffffff',
    width: 900,
    boxSizing: 'border-box',
    '@media (max-width: 970px)': {
      width: 'auto',
    },
  },
  header: {
    margin: 0,
    fontSize: 16,
    lineHeight: 1.2,
    padding: [20, 68, 20, 32],
    borderBottom: [1, 'solid', '#E7EBED'],
    borderRadius: [4, 4, 0, 0],
    background: '#F7F7F7',
  },
  tableWrapper: {
    maxHeight: 'calc(100vh - 128px)',
    overflowY: 'auto',
  },
  table: {
    tableLayout: 'fixed',
    emptyCells: 'show',
    fontSize: '1em',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    lineHeight: 1.33,
    margin: 0, // TODO hack for fix shit in _charts.scss
    width: '100%', // TODO hack for fix shit in _charts.scss
    '& > tbody > tr > td': {
      boxSizing: 'border-box',
      padding: [12, 8],
    },
    '& > tbody > tr > td:first-child': {
      paddingLeft: 32,
    },
    '& > tbody > tr > td:last-child': {
      paddingRight: 32,
    },
    maxHeight: 'calc(100vh - 32px)',
  },
  tdRight: {
    textAlign: 'right',
  },
  tdLeft: {
    textAlign: 'left',
  },
  tableText: {
    fontSize: 12,
  },
  tableTextMinor: {
    color: '#7F8D92',
  },
  tableTextMajor: {
    color: '#996411',
    display: 'flex',
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 100,
    '& > .preloader': {
      position: 'relative',
    },
  },
  user: {
    color: '#333',
    '&::before': {
      content: '\\2022',
      margin: [0, 6, 0, 3],
      color: '#777',
    },
  },
  row: {
    '&.isHeader': {
      '& > td': {
        borderBottom: [1, 'solid', '#E6EAED'],
        color: '#9B9B9B'
      },
    },
  },
})