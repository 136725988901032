export const TASK_FETCH = 'TASK_FETCH'
export const TASK_SUCCESS = 'TASK_SUCCESS'
export const TASK_ERROR = 'TASK_ERROR'
export const TASK_CLEAR = 'TASK_CLEAR'

export const TASK_GET_FILTER = 'TASK_GET_FILTER'

export const RETRY_RESEND_TO_COMPLETE = 'RETRY_RESEND_TO_COMPLETE'
export const RETRY_RESEND_TO_COMPLETE_SUCCESS = 'RETRY_RESEND_TO_COMPLETE_SUCCESS'
export const RETRY_RESEND_TO_COMPLETE_ERROR = 'RETRY_RESEND_TO_COMPLETE_ERROR'

export const GET_BANK_SIGNERS = 'GET_BANK_SIGNERS'
export const GET_BANK_SIGNERS_SUCCESS = 'GET_BANK_SIGNERS_SUCCESS'
export const GET_BANK_SIGNERS_ERROR = 'GET_BANK_SIGNERS_ERROR'

export const PRINT_GUARANTEE_ASSIGN = 'PRINT_GUARANTEE_ASSIGN'
export const PRINT_GUARANTEE_ASSIGN_SUCCESS = 'PRINT_GUARANTEE_ASSIGN_SUCCESS'
export const PRINT_GUARANTEE_ASSIGN_ERROR = 'PRINT_GUARANTEE_ASSIGN_ERROR'