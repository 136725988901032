import PropTypes from 'prop-types'

export const registerType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  registerNumber: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  createdDateTime: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
})

export const registerPaymentType = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  middleName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  paymentSum: PropTypes.number.isRequired,
  receiptLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(null)
  ]),
  statusCode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(null)
  ]),
  id: PropTypes.number.isRequired
})
