import React from 'react'
import { registerPaymentType } from '../../../types'
import { PAYMENT_STATUS_CODES } from '../../../constants'
import classNames from 'classnames'
import '../RegistryPayments.style.scss'

export function RegistryPaymentsListItem({
  payment: {
    id,
    firstName,
    middleName,
    lastName,
    paymentSum,
    statusCode,
    receiptLink
  }
}) {
  return (
    <div className="payment__item">
      <div className="payment__number">{id}</div>
      <div className="payment__full-name">
        {`${firstName} ${middleName} ${lastName}`}
      </div>
      <div className="payment__sum">{paymentSum}</div>
      <div className="payment__status">
        <div
          className={classNames('payment__token', 'payment__token--success')}
        >
          {statusCode ? PAYMENT_STATUS_CODES[statusCode] : '—'}
        </div>
      </div>
      <div className="payment__cash-voucher">{receiptLink || '—'}</div>
    </div>
  )
}

RegistryPaymentsListItem.propTypes = {
  payment: registerPaymentType
}
