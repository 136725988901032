import React from 'react'
import { InputText } from '../../../../layout'
import { formattedMoney, onlyDigitsWithDot } from '../../../../utils'

export const Input = ({
  value,
  name,
  onChangePayload,
  ...rest
}) => {
  const isDate = name.toLowerCase().includes('date')
  const type = isDate ? 'date' : 'text'

  const onChangeDate = (value) => onChangePayload({ [name]: value })

  const onChangeText = (value) => onChangePayload({
    [name]: name?.toLowerCase()?.includes('sum')
      ? onlyDigitsWithDot(formattedMoney(value.trim()))
      : value.trim(),
  })

  return (
    <InputText 
      type={type}
      name={name}
      value={value}
      onChange={isDate
        ? (e) => onChangeDate(e.target.value)
        : (e) => onChangeText(e.target.value)
      }
      {...rest}
    />
  )
}