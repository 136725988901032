import { Search } from '../../../services/api'
import { YYYYMMDD } from '../../../utils'

export const ORDERS = {
  'Непогашенные поставки': [
    { title: '№ Поставки / Уведомления', field: 'number' },
    { title: 'Поставщик', field: 'supplierName' },
    { title: 'Дебитор', field: 'debtorName' },
    { title: 'ГД', field: 'externalEntity.factoringContractNumber' },
    { title: 'Дата документа', field: 'externalEntity.supplyDate', type: 'date' },
    { title: 'Регистрация', field: 'externalEntity.registrationDate', type: 'date' },
    { title: 'Сумма', field: 'amount', type: 'amount' },
    { title: 'Сумма квитовки', field: 'matchedAmount', type: 'amount' },
    { title: 'Валюта', field: 'externalEntity.currency' },
    { title: 'Не оплачено', field: 'unmatchedAmount', type: 'amount' },
    { title: 'Контракт', field: 'externalEntity.supplyContractNumber' },
    { title: 'Ввод', field: '' },
    { title: 'Отсрочка', field: 'externalEntity.deferDate', type: 'date' },
    { title: 'Тип', field: 'orderTypeRu' },
    { title: 'БТ', field: 'externalEntity.registrationDate', type: 'warning' },
  ],
  'Уведомления по комиссии': [
    { title: '№ Поставки / Уведомления', field: 'number' },
    { title: 'Дата уведомления', field: 'externalEntity.notificationDate', type: 'date' },
    { title: 'Плательщик', field: 'supplierName' },
    { title: 'ГД', field: 'factoringContractNumber' },
    { title: 'Сумма', field: 'amount', type: 'amount' },
    { title: 'Сумма квитовки', field: 'matchedAmount', type: 'amount' },
    { title: 'Не оплачено', field: 'unmatchedAmount', type: 'amount' },
    { title: 'Тип', field: 'orderTypeRu' },
  ],
  'Репликация квитовок': [
    { title: '№ Поставки / Уведомления', field: 'order.number' },
    { title: 'Поставщик', field: '' },
    { title: 'Дебитор', field: 'order.principalName' },
    { title: 'ГД', field: 'factoringContractNumber' },
    { title: 'Дата квитовки', field: 'order.orderDate', type: 'date' },
    { title: 'Сумма платежа', field: 'matchedAmount', type: 'amount' },
    { title: 'Тип', field: 'order.orderTypeRu' },
  ]
}

export const TABS = [
  'Непогашенные поставки',
  'Уведомления по комиссии',
  'Репликация квитовок',
]

export const ORDER_TYPES = {
  'Непогашенные поставки': 'supply',
  'Уведомления по комиссии': 'commissionNotification',
}

export const DEFAULT_ORDER_FILTERS = {
  dateFrom: YYYYMMDD(new Date().setMonth(new Date().getMonth() - 1))
}

export const FILTERS = {
  number: { name: 'number', type: 'text', placeholder: '№ Поставки' },
  amountFrom: { name: 'amountFrom', type: 'text', placeholder: 'Сумма ОТ' },
  amountTo: { name: 'amountTo', type: 'text', placeholder: 'Сумма ДО' },
  dateFrom: { name: 'dateFrom', type: 'date', placeholder: 'Дата С' },
  dateTo: { name: 'dateTo', type: 'date', placeholder: 'Дата ПО' },
  search: { name: 'search', type: 'button', buttonText: 'Поиск' },
  inn: {
    name: 'inn',
    type: 'autocomplete',
    placeholder: 'Плательщик',
    payloadKey: 'INN',
    payloadKeyUI: 'displayName',
  },
  supplierInn: {
    name: 'supplierInn',
    type: 'autocomplete',
    placeholder: 'Поставщик',
    payloadKey: 'INN',
    payloadKeyUI: 'displayName',
  },
  debtorInn: {
    name: 'debtorInn',
    type: 'autocomplete',
    placeholder: 'Дебитор',
    payloadKey: 'INN',
    payloadKeyUI: 'displayName',
  },
  factoringContractId: {
    name: 'factoringContractId',
    type: 'autocomplete',
    placeholder: 'ГД',
    payloadKey: 'id',
    payloadKeyUI: 'number',
    request: Search.getFactoringContractSuggestions,
    requestKey: 'number',
    requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
  },
}