import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import { DetailsTable } from '../../Layout/DetailsTable'
import { AnalysisDetailsItem } from './AnalysisDetailsItem'
import { useAnalysisGraphicOptions } from './graphicOptions'
import { commonStatusStyles } from '../all3d/styles'

HighchartsExporting(Highcharts)

export const AnalysisGraphic = commonStatusStyles(
  ({ classes, data, onCancel }) => {
    const chartRef = useRef()
    const [details, setDetails] = useState()

    const onClickSeries = useCallback(
      event => {
        if (event.point.data.type && event.point.data.key) {
          const data1 = data[event.point.data.type][event.point.data.key]
          const type = event.point.data.type

          let title = ''
          if (type === 'amounts') {
            title = 'Сумма'
          } else if (type === 'federalLaw') {
            title = `Закон`
          } else if (type === 'all') {
            title = 'Все сделки'
          } else if (type === 'orderProductsType') {
            title = 'Продукт'
          } else if (type === 'orderBailsType') {
            title = 'Обеспечение'
          }

          if (data1) {
            setDetails({ ...data1, name: `${title} ${data1.name}` })
          }
        }
      },
      [data]
    )

    const options = useAnalysisGraphicOptions({ data, onClickSeries })

    const onCloseDetails = () => {
      setDetails()
    }

    const onClick = () => {
      onCloseDetails()
      onCancel()
    }

    useEffect(() => {
      if (chartRef.current) {
        chartRef.current.chart.width = 1000
      }
    }, [chartRef])

    const dataIsEmpty = useMemo(
      () =>
        options.reduce(
          (acc, item) => (item.series.length > 0 ? false : acc),
          true
        ),
      [options]
    )

    return (
      <div>
        {!dataIsEmpty ? (
          options.map((option, index) =>
            option.series.length > 0 ? (
              <HighchartsReact
                highcharts={Highcharts}
                options={option}
                ref={chartRef}
                key={index}
              />
            ) : null
          )
        ) : (
          <div className={classes.noData}>Нет данных</div>
        )}
        {details && (
          <DetailsTable
            data={details}
            onClose={onCloseDetails}
            Item={AnalysisDetailsItem}
            listKey="orders"
            onClick={onClick}
          />
        )}
      </div>
    )
  }
)
