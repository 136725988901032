import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { addBankManager, removeBankManager } from '../../../../redux/Company/actions'
import { ConfirmModal } from './../../../ConfirmModal'
import { UI } from './../../../../layout'
import classnames from 'classnames'

const styles = {
  root: {
    paddingBottom: 40,
    position: 'relative',
  },
  actions: {
    marginTop: 20,
  },
  input: {
    marginTop: 20,
  },
  title: UI.title,
  add: {
    ...UI.addButton,
    marginRight: 20,
  },
  remove: UI.removeButton,
  cancel: {
    ...UI.cancelButton,
    marginRight: 20,
  },
}

const BankManager = ({
  classes,
  companyId,
  agentManagerLogin,
  addBankManager,
  removeBankManager,
}) => {
  const [edit, setEdit] = useState(false)
  const [username, setUsername] = useState(agentManagerLogin)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const onOpenConfirmModal = () => setShowConfirmModal(true)
  const onCloseConfirmModal = () => setShowConfirmModal(false)

  const onEdit = () => setEdit(true)
  const onChange = (e) => setUsername(e.target.value)

  const onCancel = () => {
    setEdit(false)
    setUsername(agentManagerLogin)
  }

  const onSave = () => {
    if (username) {
      addBankManager({ username, companyId })
      setEdit(false)
    }
  }

  const onRemove = () => removeBankManager({ companyId })

  useEffect(() => {
    setUsername(agentManagerLogin)
  }, [agentManagerLogin])
    
  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.title}>Куратор Агента со стороны банка</div>
        <input
          type='text'
          name='username'
          className={classes.input}
          value={username}
          onChange={onChange}
          autoFocus={edit}
          disabled={!edit}
        />
        <div className={classes.actions}>
          {edit 
            ? <Fragment>
                <div className={classes.cancel} onClick={onCancel}>Отменить</div>
                <button 
                  type='button' 
                  className={classnames(classes.add, 'btn-form--ok')} 
                  onClick={onSave} 
                  disabled={!username}
                >
                  Сохранить
                </button>
              </Fragment>
            : <Fragment>
                <div className={classnames(classes.add, 'btn-form--ok')} onClick={onEdit}>{username ? 'Сменить' : 'Добавить'} куратора</div>
                {agentManagerLogin &&
                  <div className={classes.remove} onClick={onOpenConfirmModal}>Удалить куратора</div>
                }
              </Fragment>
          }
        </div>
      </div>
      {showConfirmModal &&
        <ConfirmModal
          title={`Вы действительно хотите удалить куратора ${username}?`}
          submitTitle='Удалить'
          onCancel={onCloseConfirmModal}
          onSubmit={onRemove}
        />
      }
      <hr/>
    </Fragment>
  )
}

const mapStateToProps = ({ Company }) => {
  return {
    agentManagerLogin: (
      Company && 
      Company.agentInfo && 
      Company.agentInfo.info && 
      Company.agentInfo.info.agentManagerLogin
    ) || '',
  }
}

const dispatchMapToProps = (dispatch, ownProps) => {
	return {
    addBankManager: (payload) => {
      dispatch(addBankManager(payload))
    },
    removeBankManager: (payload) => {
      dispatch(removeBankManager(payload))
    },
	}
}

export default connect(mapStateToProps, dispatchMapToProps)(injectSheet(styles)(BankManager))