import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getCompanyAddress } from '../../../../redux/Company/actions'

export class Address extends Component {
  async loadData() {
    const { dispatch, companyId } = this.props
    dispatch(getCompanyAddress(companyId))
  }

  componentDidMount() {
    if (!this.props.fetching) {
      this.loadData()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.companyId !== prevProps.companyId) {
      this.loadData()
    }
  }

  render() {
    const { address = {} } = this.props

    const noAddress = 'адрес не установлен'

    const legalAddress = _.get(address, 'legalAddress.result') || noAddress

    const actualAddress = _.get(address, 'actualAddress.result')
      ? _.get(address, 'actualAddress.result')
      : _.get(address, 'actualAddress.equalsLegalAddress')
        ? 'Фактический адрес совпадает с юридическим'
        : noAddress

    const postalAddress = _.get(address, 'postalAddress.result')
      ? _.get(address, 'postalAddress.result')
      : _.get(address, 'postalAddress.equalsLegalAddress')
        ? 'Почтовый адрес совпадает с юридическим'
        : noAddress

    return (
      <div>
        <div className="block_item row align-items-stretch">
          <div className="col-12 col-md-12">
            <label>Юридический Адрес</label>
            <input
              type="text"
              className="center"
              disabled
              value={legalAddress}
            />
          </div>
        </div>
        <br/>
        <div className="block_item row align-items-stretch">
          <div className="col-12 col-md-12">
            <label>Фактический Адрес</label>
            <input
              type="text"
              className="center"
              disabled
              value={actualAddress}
            />
          </div>
        </div>
        <br/>
        <div className="block_item row align-items-stretch">
          <div className="col-12 col-md-12">
            <label>Почтовый Адрес</label>
            <input
              type="text"
              className="center"
              disabled
              value={postalAddress}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ( { Company }) => {
  return {
    ...Company.address
  }
}

export default connect(mapStateToProps)(Address)