import React from 'react'
import './IconLoading.style.scss'

export function IconLoading() {
  return (
    <span className="icon-loading">
      <span className="icon-loading__cont"></span>
    </span>
  )
}
