import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    marginBottom: 4,
    fontSize: 14,
    lineHeight: 18/14,
    color: '#000',
    fontWeight: 500,
    textAlign: 'left',
    display: 'block',
  },
})

export const Label = ({
  className,
  htmlFor,
  children
}) => {
  const classes = useStyles()
  return (
    <label htmlFor={htmlFor} className={classnames(classes.root, className)}>
      {children}
    </label>
  )
}