import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { setErrorContent } from '../../../../../redux/Error/actions'
import { getBankSignersBilling } from '../../../../../services/api/company'
import {
  addCompanyAgentBillingContract,
  updateCompanyAgentBillingContract,
} from '../../../../../redux/Company/actions'
import Overlay from '../../../../Overlay'
import Dropdown from '../../../../Dropdown'
import { Label, InputText, Row, UI } from './../../../../../layout'
import classnames from 'classnames'

const styles = {
  title: UI.h1,
  dropdown: {
    marginLeft: 0,
    marginRight: 0,
  },
  actions: {
    marginTop: '30px',
    textAlign: 'right',
  },
  add: {
    ...UI.addButton,
    marginLeft: '20px',
  },
  cancel: UI.cancelButton,
}

const DEFAULT_NO_SIGNER = {
  id: '', 
  name: 'Выберите подписанта',
}

const ContractForm = ({
  classes,
  companyId,
  data,
  billingSigners,
  onAdd,
  onUpdate,
  onError,
  onCancel,
}) => {
  const mapToDropdown = ({ id, displayName }) => ({ id, name: displayName })

  const getSignerRelationId = (agentSignerId) => {
    const findItem = billingSigners.find(item => item.id === agentSignerId)
    return findItem ? findItem.signerRelationId : ''
  }

  const [isFetching, setIsFetching] = useState(false)
  const [bankSigners, setBankSigners] = useState([DEFAULT_NO_SIGNER])
  const [agentSigners] = useState([DEFAULT_NO_SIGNER, ...billingSigners.map(mapToDropdown)])

  const [number, setNumber] = useState(data.number || '')
  const [date, setDate] = useState(data.date || '')
  const [bankSignerId, setBankSignerId] = useState(data.bankSigner ? data.bankSigner.id : '')
  const [agentSignerId, setAgentSignerId] = useState(data.agentSigner ? data.agentSigner.id : '')
  const [signerRelationId, setSignerRelationId] = useState(getSignerRelationId(data.agentSigner ? data.agentSigner.id : ''))

  // const valid = number && date && bankSignerId && signerRelationId

  useEffect(() => {
    const loadBankSignersBilling = async () => {
      setIsFetching(true)
      try {
        const result = await getBankSignersBilling()
        if (result.isSuccess) {
          setBankSigners([
            DEFAULT_NO_SIGNER, 
            ...result.bankSignersBilling.map(mapToDropdown), 
          ])
        } else {
          throw new Error(result.error || 'Ошибка загрузки списка подписантов со стороны банка')
        }
      } catch (err) {
        console.log(err)
        onError(err.message)
      } finally {
        setIsFetching(false)
      }
    }
    
    loadBankSignersBilling()
  }, [companyId, onError])

  const onChangeNumber = (e) => setNumber(e.target.value)
  const onChangeDate = (e) => setDate(e.target.value)
  const onChangeBankSigner = (name, id, index) => setBankSignerId(id)
  const onChangeAgentSigner = (name, id, index) => {
    const signerRelationId = getSignerRelationId(id)
    setAgentSignerId(id)
    setSignerRelationId(signerRelationId)
  }

  const onSubmit = () => {
    const payload = { 
      number, 
      date, 
      bankSignerId, 
      signerRelationId, 
      companyId, 
    }
    if (data.number) {
      onUpdate(payload)
    } else {
      onAdd(payload)
    }
  }
  
  const selectedAgentSignerIndex = agentSigners.findIndex(item => item.id === agentSignerId)
  const selectedBankSignerIndex = bankSigners.findIndex(item => item.id === bankSignerId)

  const title = data.number 
    ? `Изменить контракт №${data.number}` 
    : `Создать новый контракт`

  if (isFetching) {
    return <Overlay/>
  }

  return (
    <Fragment>
      <div className={classes.title}>{title}</div>
      <Row>
        <div className='col-6'>
          <InputText
            type='text'
            name='number'
            label='Номер договора'
            value={number}
            onChange={onChangeNumber}
            disabled={!!data.number}
          />
        </div>
        <div className='col-6'>
          <InputText
            type='date'
            name='date'
            label='Дата' 
            value={date}
            onChange={onChangeDate}
            placeholder='2019-12-25'
          />
        </div>
      </Row>
      <Row>
        <div className='col-6'>
          <Label htmlFor='bankSignerId'>Подписант со стороны Банка</Label>
          <Dropdown 
            name='bankSignerId'
            className={classes.dropdown} 
            hideDefaultItem={false} 
            defaultActive={selectedBankSignerIndex === -1 ? 0 : selectedBankSignerIndex}
            onSelectItem={onChangeBankSigner}
            list={bankSigners}
          /> 
        </div>   
        <div className='col-6'>
          <Label htmlFor='agentSignerId'>Подписант со стороны Агента</Label>
          <Dropdown 
            name='agentSignerId' 
            className={classes.dropdown}
            hideDefaultItem={false} 
            defaultActive={selectedAgentSignerIndex === -1 ? 0 : selectedAgentSignerIndex}
            onSelectItem={onChangeAgentSigner}
            list={agentSigners}
          />
        </div>
      </Row>
      <div className={classes.actions}>
        <div className={classes.cancel} onClick={onCancel}>Отменить</div>
        <button 
          type='button' 
          className={classnames(classes.add, 'btn-form--ok')}
          onClick={onSubmit} 
          // disabled={!valid}
        >
          {data.number ? 'Сохранить' : 'Создать'}
        </button>
      </div>
    </Fragment>
  )
}

const mapStateToProps = ({ Company }) => {
  return {
    billingSigners: (
      Company &&
      Company.agentInfo &&
      Company.agentInfo.info &&
      Company.agentInfo.info.billingSigners
    ) || [],
  }
}

const dispatchMapToProps = (dispatch, ownProps) => {
	return {
    onAdd: (payload) => {
      dispatch(addCompanyAgentBillingContract(payload))
    },
    onUpdate: (payload) => {
      dispatch(updateCompanyAgentBillingContract(payload))
    },
    onError: (message) => {
      dispatch(setErrorContent(message))
    },
	}
}

ContractForm.propTypes = {
  companyId: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, dispatchMapToProps)(injectSheet(styles)(ContractForm))