import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Line, Bar } from 'react-chartjs-2'
import { Bar as Multi } from '@reactchartjs/react-chart.js'
import 'chartjs-plugin-datalabels'
import Overlay from '../../Overlay'
import { round, getDivider, getDateCustomDay, getMultipleDate } from './../../../utils'
import { AXIS_IDS } from '../../../config'

const isEmpty = require('lodash/isEmpty')

const styles = {
  root: {
    width: 'calc(50% - 10px)',
    minWidth: 600,
    '&.isFullContainer': {
      width: '100%',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1.8,
  },
  wrapper: {
    background: '#fff',
    marginBottom: 20,
    padding: [20, 24],
  },
  chart: {
    marginTop: 20,
  },
  legend: {
    maxWidth: 500,
    margin: [20, 'auto', 0],
    textAlign: 'center',
    '& > li': {
      margin: [0, 10],
      paddingLeft: 16,
      fontSize: 10,
      fontWeight: 500,
      display: 'inline-block',
      verticalAlign: 'top',
      position: 'relative',
      textAlign: 'left',
      '& > i': {
        width: 8,
        height: 8,
        position: 'absolute',
        left: 0,
        top: 0,
        borderRadius: '50%',
      },
    },
  },
}

const OPTIONS = {
  scales: {
    xAxes: [{
      display: true,
      gridLines: {
        display: true,
        drawBorder: true,
      },
    }],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: AXIS_IDS.y1,
        gridLines: {
          display: true,
          drawBorder: true,
        },
        ticks: {
          beginAtZero: true,
        },
      },
      {
        type: 'linear',
        display: true,
        position: 'right',
        id: AXIS_IDS.y2,
        gridLines: {
          display: true,
          drawBorder: true,
          drawOnArea: false,
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  legend: {
    display: true,
    position: 'bottom',
    align: 'center',
    labels: {
      boxWidth: 40,
      padding: 10,
    },
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  plugins: {
    datalabels: {
      display: false,
      clip: false,
      color: '#000',
      anchor: 'center',
      align: 'top',
      formatter: (value, context) => {
        const prev = context.dataset.data[context.dataIndex - 1]
        const next = context.dataset.data[context.dataIndex + 1]
        if (Number(value) && Number(value) > 1 && (
          // (value > prev && value >= next) ||
          // (value >= prev && value > next) ||
          // (value < prev && value <= next) ||
          // (value <= prev && value < next)
          (value > prev && value > next) ||
          (value < prev && value < next)
        )) {
          return value
        }
  
        return null
      },
    },
  },
  tooltips: {
    enabled: true,
    callbacks: {
      title: function (tooltipItems, data) {
        const currentDate = getDateCustomDay(tooltipItems[0].xLabel)
        return getMultipleDate(currentDate).split(' ').slice(0, 3).join(' ')
      },
    },
  },
}

const formattedValues = (array = []) => {
  if (!array.length) { return [] }
  const d = getDivider(array)
  const result = array.map(item => round(item / d))
  return result
}

const ChartItem = ({
  classes,
  heading,
  legend,
  charts,
  isFullContainer,
  isFetching,
  // imglink,
  // content,
  dataX,
  dataY,
  yAxisIDs,
  styleChartSetting,
}) => {
  const isMulti = charts.indexOf('line') > -1 && charts.indexOf('bar') > -1

  const buildChartByData = () => {
    const { lines } = styleChartSetting
    const data = {
      labels: dataX,
      datasets: dataY.map((data, index) => isMulti
        ? {
            type: charts[index],
            label: lines[index].title,
            data: formattedValues(data),
            fill: false,
            backgroundColor: lines[index].color,
            borderColor: lines[index].color,
            yAxisID: yAxisIDs[index],
          }
        : {
            label: lines[index].title,
            fill: false,
            // lineTension: 0.001,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: lines[index].color,
            borderDash: lines[index].isDotted && [5, 5.5],
            borderWidth: 2,
            pointBorderColor: lines[index].color,
            pointBackgroundColor: lines[index].color,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: lines[index].color,
            pointHoverBorderColor: lines[index].color,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: formattedValues(data),
          }
      )
    }

    if (!isEmpty(dataY[2]) && !isMulti) {
      return {
        ...data,
        datasets: [
          ...data.datasets,
          {
            label: lines[2].title,
            fill: false,
            lineTension: 0.001,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: lines[2].color,
            borderDash: lines[2].isDotted && [5, 5.5],
            borderWidth: 2,
            pointBorderColor: lines[2].color,
            pointBackgroundColor: lines[2].color,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: lines[2].color,
            pointHoverBorderColor: lines[2].color,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: formattedValues(dataY[2]),
          }
        ]
      }
    }

    return data
  }

  const renderChart = (charts) => {
    if (charts?.length > 1) {
      return <Multi data={buildChartByData} options={OPTIONS} />
    }
    if (charts?.length === 1) {
      switch (charts[0]) {
        case 'line': return <Line data={buildChartByData} options={OPTIONS} />
        case 'bar': return <Bar data={buildChartByData} options={OPTIONS} />
        default: break
      }
    }
  }

  return (
    <div className={classnames(classes.root, { isFullContainer })}>
      <div className={classes.wrapper}>
        <div className={classes.title}>{heading}</div>
        <div className={classes.chart}>
          {isFetching
            ? <Overlay /> 
            : renderChart(charts)
          }
        </div>
        {legend?.length > 0 &&
          <ul className={classes.legend}>
            {legend.map(({ background, title }, index) =>
              <li key={index}>
                <i style={{ background }} />
                <span>{title}</span>
              </li>
            )}
          </ul>
        }
      </div>
    </div>
  )
}

export default injectSheet(styles)(ChartItem)