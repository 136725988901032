import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  alert: {
    padding: [0, 10],
    textAlign: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    fontSize: 14,
    lineHeight: 18/14,
    '&.success': {
      background: '#d4edda',
      borderColor: '#c3e6cb',
      color: '#155724',
    },
    '&.danger': {
      background: '#f8d7da',
      borderColor: '#f5c6cb',
      color: '#721c24',
    },
  },
})

export const Alert = ({
  type,
  children,
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.alert, { [type]: type })}>{children}</div>
  )
}