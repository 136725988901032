import React from 'react'
import { createUseStyles } from 'react-jss'
import { THEME_COLOR } from '../../../../config'

const useStyles = createUseStyles({
  plus: {
    background: THEME_COLOR,
    width: 50,
    height: 50,
    color: '#fff',
    textAlign: 'center',
    fontSize: 40,
    lineHeight: 50/40,
    borderRadius: 24,
    cursor: 'pointer',
    position: 'fixed',
    bottom: 20,
    right: 20,
    boxShadow: [0, 4, 8, 'rgba(0, 0, 0, 0.25)'],
    border: 'none',
    zIndex: 2,
    '&:disabled': {
      cursor: 'default',
      opacity: 0.5,
    },
  },
})

export const ButtonPlus = (props) => {
  const classes = useStyles()
  return (
    <button type='button' className={classes.plus} {...props}>+</button>
  )
}