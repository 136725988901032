import * as types from './actionTypes'
import { Dashboard } from '../../services/api'
import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'

export const getDashboard = () => async (dispatch) => {
  try {
    dispatch({ type: types.DASHBOARD_GET_FETCH })
    const { isSuccess, ...response } = await Dashboard.getList()
    if (!isSuccess) {
      if (response.needLogout) {
        dispatch(logoutProcess(response.message))
        return
      }
      throw new Error(response.message)
    }
    dispatch({
      type: types.DASHBOARD_GET_SUCCESS,
      data: response,
    })
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent(error.message))
    dispatch({ type: types.DASHBOARD_GET_ERROR })
  }
}

export const clearDashboard = () => (dispatch) => dispatch({ type: types.DASHBOARD_CLEAR })