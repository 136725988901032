export const STYLES = {
  orderStatus: {
    'Отказано': { color: '#E07899', borderColor: '#E07899' },
    'Одобрено': { color: '#22C7B5', borderColor: '#22C7B5' },
    'В работе': { color: '#EC9454', borderColor: '#EC9454' },
  },
  orderTypeName: {
    'Открытие счетов': { background: '#F0FAFF', color: '#7ACAFF' },
    'Банковская гарантия': { background: '#FDF4ED', color: '#EC9454' },
    'Заявка на лимит БГ': { background: '#F0FAFF', color: '#7ACAFF' },
    'Заявка на изменение документов по БГ': { background: '#E1FFFC', color: '#22C7B5' },
  },
}

export const GET_TYPES = (orders) => orders.reduce((result, order) => {
  const { fields = [] } = order || {}
  const type = fields.find(field => field?.id === 'orderTypeName')
  const { value } = type || {}
  if (value && result.indexOf(value) === -1) {
    result.push(value)
  }
  return result
}, [])

export const GET_STATUSES = (orders) => orders.reduce((result, order) => {
  const { fields = [] } = order || {}
  const status = fields.find(field => field?.id === 'orderStatus')
  const { value } = status || {}
  if (value && result.indexOf(value) === -1) {
    result.push(value)
  }
  return result
}, [])

export const GET_FILTERS = (orders) => [
  {
    placeholder: 'Выберите тип',
    options: GET_TYPES(orders).map(item => ({ key: item, value: item }))
  },
  {
    placeholder: 'Выберите статус',
    options: GET_STATUSES(orders).map(item => ({ key: item, value: item }))
  }
]