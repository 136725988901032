import React, { memo } from 'react'
import { getFilterItem } from '../getFilterItem'
import { useStyles } from './styles'

export const OrdersFilters = memo(({
  tab,
  values,
  setValues,
  orderFilters,
  setOrderFilters,
  ORDER_FILTERS,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.filters}>
      {ORDER_FILTERS[tab]?.map((item, index) => {
        const pageProps = {
          values,
          filters: orderFilters,
          setFilters: setOrderFilters,
          setValues,
          onRequestCallback: () => console.log('onRequestCallback')
        }
        const FilterItem = getFilterItem({ ...pageProps, ...item })
        return FilterItem
          ? <div className={classes.filterItem} key={index}>
              <FilterItem {...pageProps} {...item} />
            </div>
          : null
      })}
    </div>
  )
})