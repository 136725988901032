import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Modal } from './../../index'

const styles = {
	root: {
    paddingBottom: 20,
    borderBottom: [1, 'solid', '#DDDDDD'],
    position: 'relative',
  },
	borderRoot: {
    paddingBottom: 20,
    borderBottom: [1, 'solid', '#DDDDDD'],
    position: 'relative',
  },
  item: {
    minHeight: 50,
    paddingRight: 230,
    position: 'relative',
    '& + &': {
      marginTop: 20,
    },
  },
  right: {
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'right',
  },
  lots: {
    padding: [30, 0],
    position: 'relative',
  },
  info: {
    background: '#E4E4E4',
    // width: 'calc(100% + 144px)',
    // marginLeft: '-74px',
    // marginTop: '35px',
    // padding: '0 75px',
    position: 'relative',
  },
  infoItem: {
    background: '#ECECEC',
    width: '20%',
    padding: [25, 0],
    textAlign: 'center',
    display: 'inline-block',
    position: 'relative',
    '& + &': {
      width: 'calc(20% - 1px)',
      marginLeft: 1,
    },
  },
}

const Info = ({ 
  content: { 
    info = [], 
    data = [],
    lots = [],
    documents = [],
    eis = ''
  },
  iconName = 'file',
  title,
  classes, 
}) =>
  <Modal.Block iconName={iconName} title={title}>
    <Modal.Content>
      <div className={lots?.length > 0 || data?.length > 0 ? classes.borderRoot : classes.root}>
        {info.map(({ items }, index) =>
          <div className={classes.item} key={`info-${index}`}>
            {items.map(({ title, value, right }, subIndex) => 
              <div 
                className={classnames({ [classes.right]: right })} 
                key={`info-${index}-${subIndex}`}
              >
                <Modal.Title>{title}</Modal.Title>
                <Modal.Value>{value}</Modal.Value>
              </div>
            )}
          </div>
        )}
      </div>
      {lots?.length > 0 &&
      <div className={classes.lots}>
        {(lots || []).map((lot, index) =>
          <Modal.Lot {...lot} documents={documents} key={`lot-${index}`} eis={eis} />
        )}
      </div>
      }
      {data?.length > 0 &&
      <ul className={classes.info}>
        {data.map(({ title, value }, index) =>
          <li className={classes.infoItem} key={`data-${index}`}>
            <Modal.Title>{title}</Modal.Title>
            <Modal.Value>{value}</Modal.Value>
          </li>
        )}
      </ul>
      }
    </Modal.Content>
  </Modal.Block>

export default injectSheet(styles)(Info)