import React, { Fragment, useState, useEffect } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
// import TextField from '../../TextField'
import { addCompanyAgentPerson } from '../../../../redux/Company/actions'
import TextFieldWithSuggest from '../../../TextFieldWithSuggest'
import { searchAddress, searchIdentityDocumentType } from '../../../../services/api/company'
import { Row, InputText, UI, Select1 } from './../../../../layout'

const styles = {
  title: UI.h1,
  actions: {
    marginTop: 30,
    textAlign: 'right',
  },
  add: UI.addButton,
  cancel: {
    ...UI.cancelButton,
    marginRight: 20,
  },
}

const AddPerson = ({ 
  classes,
  companyId,
  onCancel,
  addCompanyAgentPerson, 
}) => {
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [secondName, setSecondName] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [INN, setINN] = useState('')
  const [identityDocumentTypeRefId, setIdentityDocumentTypeRefId] = useState('')
  const [series, setSeries] = useState('')
  const [number, setNumber] = useState('')
  const [issuedDate, setIssuedDate] = useState('')
  const [issuingAuthorityCode, setIssuingAuthorityCode] = useState('')
  const [issuingAuthority, setIssuingAuthority] = useState('')
  const [registrationAddress, setRegistrationAddress] = useState('')
  const [actualAddress, setActualAddress] = useState('')

  const [identityDocumentType, setIdentityDocumentType] = useState([])
  const [errors] = useState({})

  const onGDocumentType = async () => {
    try {
      const { identityDocumentType } = await searchIdentityDocumentType()
      setIdentityDocumentType(identityDocumentType)
    } catch (err) {
      console.log(err)
    }
  }

  const onSearch = async (value) => {
    try {
      const { suggest } = await searchAddress(value)
      return suggest
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    onGDocumentType()
  }, [])

  const getValue = (data) => data.value

  const onSubmit = () => addCompanyAgentPerson({ 
    companyId,
    lastName,
    firstName,
    secondName,
    birthDate,
    INN,
    identityDocumentTypeRefId,
    series,
    number,
    issuedDate,
    issuingAuthorityCode,
    issuingAuthority,
    registrationAddress,
    actualAddress,
  })

  // const valid =
  //   firstName && 
  //   lastName && 
  //   birthDate && 
  //   number && 
  //   series && 
  //   identityDocumentTypeRefId && 
  //   registrationAddress
    
  return (
    <Fragment>
      <div className={classes.title}>Новый подписант</div>
      <Row>
        <div className='col-4'>
          <InputText
            type='text'
            name='lastName'
            label='Фамилия *'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={errors.lastName}
          />
        </div>
        <div className='col-4'>
          <InputText
            type='text' 
            name='firstName'
            label='Имя *'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={errors.firstName}
          />
        </div>
        <div className='col-4'>
          <InputText
            type='text' 
            name='secondName'
            label='Отчество'
            value={secondName}
            onChange={(e) => setSecondName(e.target.value)}
            error={errors.secondName}
          />
        </div>
      </Row>
      <Row>
        <div className='col-6'>
          <InputText
            type='date' 
            name='birthDate'
            label='Дата рождения *'
            value={birthDate}
            onChange={e => setBirthDate(e.target.value)}
            error={errors.birthDate}
          />
        </div>
        <div className='col-6'>
          <InputText
            type='text'
            name='INN'
            label='ИНН'
            value={INN}
            onChange={(e) => setINN(e.target.value)}
            error={errors.INN}
          />
        </div>
      </Row>
      <Row>
        <div className='col-6'>
          <Select1
            name='identityDocumentTypeRefId'
            label='Тип документа *'
            options={identityDocumentType}
            defaultValue={identityDocumentTypeRefId}
            onChange={(e) => setIdentityDocumentTypeRefId(e.target.value)}
            error={errors.identityDocumentType}
          />
        </div>
        <div className='col-3'>
          <InputText
            type='text' 
            name='series'
            label='Серия *'
            value={series}
            onChange={(e) => setSeries(e.target.value)}
            error={errors.series}
          />
        </div>
        <div className='col-3'>
          <InputText
            type='text' 
            name='number'
            label='Номер *'
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            error={errors.number}
          />
        </div>
      </Row>
      <Row>
        <div className='col-4'>
          <InputText
            type='date' 
            name='issuedDate'
            label='Дата выдачи'
            value={issuedDate}
            onChange={e => setIssuedDate(e.target.value)}
            error={errors.issuedDate}
          />
        </div>
        <div className='col-4'>
          <InputText
            type='text' 
            name='issuingAuthorityCode'
            label='Код подразделения'
            value={issuingAuthorityCode}
            onChange={(e) => setIssuingAuthorityCode(e.target.value)}
            error={errors.issuingAuthorityCode}
          />
        </div>
        <div className='col-4'>
          <InputText 
            type='text'
            name='issuingAuthority'
            label='Кем выдан'
            value={issuingAuthority}
            onChange={(e) => setIssuingAuthority(e.target.value)}
            error={errors.issuingAuthority}
          />
        </div>
      </Row>
      <Row>
        <div className='col-12'>
          <TextFieldWithSuggest 
            name='registrationAddress'
            label='Адрес регистрации *' 
            search={onSearch}
            input={{
              value: registrationAddress,
              onChange: (item) => setRegistrationAddress(item),
            }}
            onSelect={() => {}}
            onClear={() => {}}
            getValue={getValue}
            error={errors.registrationAddress}
          />
        </div>
      </Row>
      <Row>
        <div className='col-12'>
          <TextFieldWithSuggest 
            name='actualAddress'
            label='Адрес места жительства' 
            search={onSearch}
            input={{
              value: actualAddress,
              onChange: (item) => setActualAddress(item),
            }}
            onSelect={() => {}}
            onClear={() => {}}
            getValue={getValue}
            error={errors.actualAddress}
          />
        </div>
      </Row>
      <div className={classes.actions}>
        <div className={classes.cancel} onClick={onCancel}>Отменить</div>
        <button 
          type='button'
          className={classnames(classes.add, 'btn-form--ok')}
          onClick={onSubmit}
          // disabled={!valid}
        >
          Создать
        </button>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (store) => {
  return {}
}

const dispatchMapToProps = (dispatch, ownProps) => {
  return {
    addCompanyAgentPerson: (payload) => {
      dispatch(addCompanyAgentPerson(payload))
    },
  }
}

AddPerson.propTypes = {
  companyId: PropTypes.string.isRequired,
  addCompanyAgentPerson: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, dispatchMapToProps)(injectSheet(styles)(AddPerson))