export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR'

export const GET_NEXT_ORDERS = 'GET_NEXT_ORDERS'
export const GET_NEXT_ORDERS_SUCCESS = 'GET_NEXT_ORDERS_SUCCESS'
export const GET_NEXT_ORDERS_ERROR = 'GET_NEXT_ORDERS_ERROR'

export const SET_ORDERS_FILTER = 'SET_ORDERS_FILTER'

export const CLEAR_ORDERS_FILTERS = 'CLEAR_ORDERS_FILTERS'