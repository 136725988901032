import React from 'react'
import { RegistryItem } from './RegistryItem'
import { RegistersPageModalService } from '../../registers-page-modal.service'

import './RegistersList.style.scss'

export function RegistersList({ registers, isFetching }) {
  return (
    <div className="registers__list">
      {!isFetching && registers.length === 0 && <div>Ничего не найдено</div>}
      {registers.map(register => (
        <RegistryItem
          key={register.id}
          register={register}
          onClick={RegistersPageModalService.openModalWithPayments}
        />
      ))}
    </div>
  )
}
