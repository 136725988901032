import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
  root: {
    paddingLeft: '70px',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '26px',
    color: '#000',
    position: 'relative',
  },
}

const H1 = ({ 
  children,
  className,
  classes,
}) => 
  <div className={classnames(classes.root, className)}>
    {children}
  </div>

export default injectSheet(styles)(H1)