import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  radio: {
    '& + &': {
      marginTop: 6,
    },
    display: 'block',
    '&.error': {
      '[type=radio]': {
        '& + label': {
          borderColor: '#f00',
        },
      },
      '& + .error': {
        '&[type=radio]': {
          '& + label': {
            borderLeftColor: 'transparent',
          },
        },
      },
    },
    '& input[type=radio]': {
      display: 'none',
      '&:checked': {
        '& + label': {
          '&:before': {
            borderColor: '#0b1f35',
          },
          '&:after': {
            opacity: 1,
          },
        },
      },
      '&:disabled': {
        '& + label': {
          background: '#eee',
          padding: [8, 8, 8, 40],
          borderRadius: 8,
          cursor: 'default',
          '&:before': {
            left: 8,
          },
          '&:after': {
            left: 12,
          },
        },
      },
    },
    '& > label': {
      height: '100%',
      paddingLeft: 32,
      fontSize: 14,
      lineHeight: 20/14,
      textAlign: 'right',
      color: '#0B1F35',
      position: 'relative',
      cursor: 'pointer',
      display: 'inline-block',
      verticalAlign: 'top',
      '&:before': {
        content: '""',
        width: 20,
        height: 20,
        border: [1, 'solid', 'rgba(80, 78, 91, 0.4)'],
        borderRadius: '50%',
        display: 'block',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        boxSizing: 'border-box',
        transition: 'border 300ms ease',
      },
      '&:after': {
        content: '""',
        background: '#0b1f35',
        width: 12,
        height: 12,
        borderRadius: '50%',
        display: 'block',
        position: 'absolute',
        left: 4,
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: 0,
        transition: 'opacity 300ms ease',
      },
    },
  },
})

export const Radio = ({
  id,
  className,
  label,
  error,
  disabled,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.radio, className, { error })}>
      <input
        id={id}
        type='radio'
        disabled={disabled}
        {...rest}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}