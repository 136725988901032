export default {
  actionBtn: {
    background: 'transparent',
    width: 54,
    height: 54,
    transition: 'background 300ms ease',
    '&:hover': {
      background: 'rgba(11, 31, 53, 0.1)',
    },
  },
}