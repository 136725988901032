import React from 'react'
import { Route } from 'react-router-dom'
import TariffSchemes from './TariffSchemes'
import TariffModal from './TariffModal'

const TariffSchemesPage = (props) =>
  <>
    <TariffSchemes {...props} />
    <Route exact path='/tariff-schemes/:tariffSchemaId' component={TariffModal} />
  </>

export default TariffSchemesPage