import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    minWidth: 110,
    maxWidth: 220,
    color: '#000',
    fontSize: 10,
    lineHeight: 1.8,
    fontWeight: 500,
    letterSpacing: 1,
    textTransform: 'uppercase',
    opacity: 0.5,
  },
})

export const SubTitle = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>{children}</div>
  )
}