import React from 'react'
import { withRouter } from 'react-router-dom'
import injectSheet from 'react-jss'
import { Icon } from './../../Icons'

const styles = {
  close: {
    width: '50px',
    height: '50px',
    position: 'fixed',
    top: '6px',
    left: '50%',
    transform: 'translateX(595px)',
    cursor: 'pointer',
    zIndex: 5000,
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
}

const Close = ({
  classes,
  history,
  style,
  onClose,
}) => {
  const { pathname } = history.location

  const _onClose = onClose 
    ? onClose 
    : /orders/.test(pathname)
      ? () => history.push('/orders')
      : /tasks\/order/.test(pathname)
        ? () => history.push('/tasks')
        : () => history.goBack()
  
  return (
    <div
      className={classes.close}
      onClick={_onClose}
      style={style}
    >
      <Icon iconName='close' />
    </div>
  )
}

export default withRouter(injectSheet(styles)(Close))