import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Tab, Tabs as TabsReact, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

const useStyles = createUseStyles({
  tabs: {
    background: '#F3F4F5',
    padding: 20,
  },
  tabsHeader: {
    background: '#fff',
    padding: [0, 20]
  },
  tabName: {
    padding: [16, 16, 14, 16],
    borderBottom: [2, 'solid', '#fff'],
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    '&.react-tabs__tab--selected': {
      color: '#0B1F35',
      fontWeight: 600,
      borderColor: '#0B1F35',
    },
  },
  tabContent: {
    '&.react-tabs__tab-panel--selected': {
      background: '#fff',
      marginTop: 2,
      padding: 20,
    },
  },
})

export const Tabs = ({
  className,
  items = [],
}) => {
  const classes = useStyles()
  return items.some(item => item?.show)
    ? <TabsReact className={classnames(classes.tabs, className)}>
        <TabList className={classes.tabsHeader}>
          {items.map(({ name, show }, i) => show
            ? <Tab className={classes.tabName} key={`TabList-${i}`}>{name}</Tab>
            : null
          )}
        </TabList>
        {items.map(({ content, show }, i) => show
          ? <TabPanel className={classes.tabContent} key={`TabPanel-${i}`}>{content}</TabPanel>
          : null
        )}
      </TabsReact>
    : null
}