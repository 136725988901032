import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    marginTop: 9,
    paddingRight: 113,
    '& span': {
      marginLeft: 4,
      marginRight: 4,
      color: '#000',
      fontSize: 14,
      lineHeight: 1.4,
      fontWeight: 500,
      display: 'inline-block',
    },
  },
}

const Urls = ({ children, classes }) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Urls)