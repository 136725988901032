import React from 'react'
import { Link } from 'react-router-dom'
import injectSheet from 'react-jss'
import Skeleton from '../../components/Card/Skeleton'

const styles = {
  root: {
    width: 264,
    minHeight: 180,
    marginTop: 20,
    marginRight: 20,
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
  item: {
    background: '#fff',
    width: 'inherit',
    minHeight: 'inherit',
    borderRadius: 16,
    textAlign: 'left',
    display: 'block',
    position: 'relative',
    '&:hover': {
      textDecoration: 'none',
    },
    '& > a': {
      color: '#000',
      padding: [60, 20],
      position: 'relative',
      display: 'block',
      '&:hover': {
        textDecoration: 'none',
      },
      '& > span': {
        display: 'block',
      },
    },
  },
  title: {
    color: '#000',
    fontSize: 15,
    lineHeight: 1.33,
    fontWeight: 'bold',
    display: 'block',
    position: 'absolute',
    top: 15,
    left: 20,
    right: 20,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  period: {
    marginTop: 20,
    color: '#B8B8B8',
    fontSize: 13,
    lineHeight: 1.7,
    fontWeight: 500,
    letterSpacing: '0.04em',
    position: 'relative',
  },
  name: {
    color: '#000',
    fontSize: 14,
    lineHeight: 1.7,
    fontWeight: 500,
    display: 'block',
    position: 'absolute',
    left: 20,
    right: 20,
    bottom: 15,
  },
  type: {
    position: 'absolute',
    left: 20,
    bottom: 20,
    fontSize: 14,
  },
}

const TariffSchema = ({
  classes,
  id,
  name,
  productType,
  validFrom,
  validTo,
  marketingCampaign,
  isFetching,
}) =>
  <div className={classes.root}>
    {isFetching 
      ? <Skeleton />
      : <div className={classes.item}>
          <Link to={`/tariff-schemes/${id}`}>
            <span className={classes.title}>{productType} №{id}</span>
            <span>{name}</span>
            <span className={classes.period}>
              {new Date(validFrom).toLocaleDateString('ru-RU')}
              {' '} - {' '}
              {new Date(validTo).toLocaleDateString('ru-RU')}
            </span>
            <span className={classes.type}>{marketingCampaign ? 'Акционная' : 'Базовая'}</span>
          </Link>
        </div>
    }
  </div>

export default injectSheet(styles)(TariffSchema)