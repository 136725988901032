import React, { useState, useEffect, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru'
//registerLocale('ru', ru)
import {
  getFactoringHolidaysByPeriod,
  setFactoringHolidayDate,
  deleteFactoringHolidayDate,
  enableFactoringHolidayDate,
  disableFactoringHolidayDate,
  getFactoringOperDays,
} from '../../../redux/Factoring/actions'
import Overlay from '../../../components/Overlay'
import { FactoringWrapper } from '../FactoringWrapper'
import { Fieldset, Modal2 } from '../../../layout'
import { Button, Table, Title } from '../Layout'
import { YYYYMMDD, formattedDate } from '../../../utils'

const COLORS = {
  BANK_HOLIDAY: 'green',
  // HALF_HOLIDAY: 'yellow',
  HOLIDAY: 'red',
  MANUAL_HOLIDAY: 'blue',
}

const HOLIDAYS = {
  BANK_HOLIDAY: 'Банковский выходной',
  // HALF_HOLIDAY: 'yellow',
  HOLIDAY: 'Государственный выходной',
  MANUAL_HOLIDAY: 'Пользовательский выходной',
}

const useStyles = createUseStyles({
  calendar: {
    marginTop: 50,
    position: 'relative',
    '& .react-datepicker': {
      border: 'none',
    },
    '& .react-datepicker__month-container': {
      width: '16%',
      height: 258,
      margin: '0.4%',
      border: [1, 'solid', '#aeaeae'],
      fontFamily: 'Montserrat',
      float: 'none',
      display: 'inline-block',
      verticalAlign: 'top',
      '&:nth-child(6n - 2)': {
        marginLeft: 0,
      },
      '&:nth-child(6n - 3)': {
        marginRight: 0,
      },
    },
    '& .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll': {
      position: 'absolute',
      left: 0,
      top: -45,
    },
    '& .react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll': {
      margin: 0,
    },
    '& .react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view': {
      padding: [8, 40, 8, 16],
      border: [1, 'solid', '#aeaeae'],
      borderRadius: 0,
      visibility: ['visible', '!important'],
    },
    '& .react-datepicker__navigation': {
      display: 'none',
    },
    '& .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow': {
      marginLeft: 0,
      float: 'none',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 12,
      borderWidth: 6,
    },
    '& .react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown': {
      width: '100%',
      left: 0,
      top: '100%',
      borderRadius: 0,
      marginTop: 1,
    },
    '& .react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option': {
      padding: [4, 16],
      textAlign: 'left',
      '&:first-child, &:last-child': {
        '&:hover': {
          background: 'transparent',
        },
      },
    },
    '& .react-datepicker__year-option--selected_year': {
      background: '#0B1F35',
      color: '#fff',
    },
    '& .react-datepicker__year-option--selected': {
      display: 'none',
    },
    '& .react-datepicker__day--outside-month': {
      visibility: 'hidden',
      pointerEvents: 'none',
    },
    '& .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header': {
      color: '#0B1F35',
      fontWeight: 600,
    },
    '& .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name': {
      margin: 2,
      color: '#0B1F35',
      '&:hover': {
        borderRadius: 0,
      },
    },
    '& .react-datepicker__day--selected': {
      background: 'transparent',
      border: 'none',
      outline: 'none',
      '&:hover': {
        background: '#f0f0f0',
      },
    },
    '& .react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today': {
      background: '#0B1F35',
      color: '#fff',
    },
    '& .react-datepicker__day-name': {
      '&:nth-child(6), &:nth-child(7)': {
        color: COLORS.HOLIDAY,
        fontWeight: 600,
      },
    },
    '& .react-datepicker__day--highlighted-bankHoliday': {
      color: COLORS.BANK_HOLIDAY,
      fontWeight: 600,
    },
    // '& .react-datepicker__day--highlighted-halfHoliday': {
    //   color: COLORS.HALF_HOLIDAY,
    //   fontWeight: 600,
    // },
    '& .react-datepicker__day--highlighted-holiday': {
      color: COLORS.HOLIDAY,
      fontWeight: 600,
    },
    '& .react-datepicker__day--highlighted-manualHoliday': {
      color: COLORS.MANUAL_HOLIDAY,
      fontWeight: 600,
    },
  },
  calendarModal: {
    padding: 30,
  },
  calendarModalItem: {
    marginTop: 15,
    '& > span': {
      '&:first-child': {
        width: 260,
        display: 'inline-block',
        verticalAlign: 'top',
      },
      '&:last-child': {
        fontWeight: 600,
      },
    },
  },
  buttons: {
    marginTop: 30,
  },
  operDays: {
    marginTop: 40,
    paddingBottom: 80,
  },
  table: {
    marginTop: 20,
  },
  legend: {
    padding: [8, 16],
    border: [1, 'solid', '#aeaeae'],
    position: 'absolute',
    top: -40,
    right: 0,
    whiteSpace: 'nowrap',
  },
  legendItem: {
    padding: [2, 0],
    fontSize: 12,
    lineHeight: 12/12,
    fontWeight: 500,
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.5)',
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: 40,
    },
    '& > span': {
      width: 12,
      height: 12,
      marginRight: 10,
      borderRadius: '50%',
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
})

const NOW = new Date()
const getFirstDayOfYear = (date) => new Date(new Date(date).getFullYear(), 0, 1)

export const FactoringCalendarPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    holidaysByPeriod,
    operDays,
    isFetching,
  } = useSelector(state => state?.Factoring || {})

  const [date, setDate] = useState(getFirstDayOfYear(NOW))
  const [showModal, setShowModal] = useState(false)

  const _date = YYYYMMDD(date)

  const selectedDate = holidaysByPeriod?.find(item => item?.date === _date)

  const {
    bankHoliday,
    holiday,
    manualHoliday,
  } = selectedDate || {}

  const onClose = () => setShowModal(false)

  const onGetFactoringHolidaysByPeriod = useCallback(() => {
    const _date = new Date(date)
    const startDate = YYYYMMDD(getFirstDayOfYear(_date))
    const endDate = YYYYMMDD(_date.setFullYear(_date.getFullYear() + 1))
    dispatch(getFactoringHolidaysByPeriod(startDate, endDate))
  }, [date, dispatch])

  const onSuccess = () => {
    onGetFactoringHolidaysByPeriod()
    onClose()
  }

  const onError = () => onClose()

  const onSetFactoringHolidayDate = () => dispatch(setFactoringHolidayDate(_date, onSuccess, onError))
  const onDeleteFactoringHolidayDate = () => dispatch(deleteFactoringHolidayDate(_date, onSuccess, onError))
  const onEnableFactoringHolidayDate = () => dispatch(enableFactoringHolidayDate(_date, onSuccess, onError))
  const onDisableFactoringHolidayDate = () => dispatch(disableFactoringHolidayDate(_date, onSuccess, onError))

  useEffect(() => {
    onGetFactoringHolidaysByPeriod()
  }, [onGetFactoringHolidaysByPeriod])

  useEffect(() => {
    dispatch(getFactoringOperDays())
  }, [dispatch])

  const bankHolidays = holidaysByPeriod.reduce((result, item) => item?.bankHoliday ? [...result, new Date(item?.date)] : result, [])
  const halfHolidays = holidaysByPeriod.reduce((result, item) => item?.halfHoliday ? [...result, new Date(item?.date)] : result, [])
  const holidays = holidaysByPeriod.reduce((result, item) => item?.holiday ? [...result, new Date(item?.date)] : result, [])
  const manualHolidays = holidaysByPeriod.reduce((result, item) => item?.manualHoliday ? [...result, new Date(item?.date)] : result, [])

  const highlightWithRanges = [
    { 'react-datepicker__day--highlighted-bankHoliday':  bankHolidays },
    { 'react-datepicker__day--highlighted-halfHoliday': halfHolidays },
    { 'react-datepicker__day--highlighted-holiday': holidays },
    { 'react-datepicker__day--highlighted-manualHoliday': manualHolidays },
  ]

  const isHoliday = holiday || bankHoliday || manualHoliday

  return (
    <>
      <FactoringWrapper
        title='Календарь'
        dataFilters={[]}
        entityType={''}
        onRequest={()=>{}}
        requestPayload={{}}
        autoHeight
      >
        <div className={classes.calendar}>
          <div className={classes.legend}>
            {Object.keys(COLORS).map((item, index) =>
              <div className={classes.legendItem} key={index}>
                <span style={{background: COLORS[item]}} />
                {HOLIDAYS[item]}
              </div>
            )}
          </div>
          <DatePicker
            selected={date}
            onChange={date => {
              setDate(date)
              setShowModal(true)
            }}
            onYearChange={date => setDate(date)}
            monthsShown={12}
            highlightDates={highlightWithRanges}
            shouldCloseOnSelect={false}
            locale={ru}
            inline
            showYearDropdown
            //dropdownMode='select'
            yearDropdownItemNumber={2}
          />
        </div>
        <div className={classes.operDays}>
          <Title>Операционные дни</Title>
          <Table
            className={classes.table}
            dataItems={[
              { name: 'Дата', field: 'date', type: 'date' },
              { name: 'Тип', field: 'type' },
            ]}
            items={operDays}
          />
        </div>
      </FactoringWrapper>
      {showModal &&
        <Modal2 className={classes.calendarModal} onClose={onClose}>
          <div className={classes.calendarModalItem}>
            Дата: <span>{formattedDate(date)}</span>
          </div>
          <div className={classes.calendarModalItem}>
            <span>Государственный выходной:</span>
            <span>{holiday ? 'Да' : 'Нет'}</span>
          </div>
          <div className={classes.calendarModalItem}>
            <span>Банковский выходной:</span>
            <span>{bankHoliday ? 'Да' : 'Нет'}</span>
          </div>
          <div className={classes.calendarModalItem}>
            <span>Пользовательский выходной:</span>
            <span>{manualHoliday ? 'Да' : 'Нет'}</span>
          </div>
          <div className={classes.calendarModalItem}>
            <span>Итоговое значение:</span>
            <span>{isHoliday ? 'Выходной' : 'Рабочий'}</span>
          </div>
          <div className={classes.buttons}>
            <Fieldset>
              <Button onClick={onSetFactoringHolidayDate} disabled={manualHoliday}>Установить пользовательский выходной</Button>
              <Button onClick={onDeleteFactoringHolidayDate} disabled={!manualHoliday}>Убрать пользовательский выходной</Button>
            </Fieldset>
            <Fieldset>  
              <Button onClick={onEnableFactoringHolidayDate} disabled={isHoliday}>Разрешить правила выходного дня</Button>
              <Button onClick={onDisableFactoringHolidayDate} disabled={!isHoliday}>Отменить правила выходного дня</Button>
            </Fieldset>
          </div>
        </Modal2>
      }
      {isFetching &&
        <Overlay size='big' />
      }
    </>
  )
}