import React from "react";
// import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from 'react-router-dom';

import FooterItem from "../FooterItem";


const MAX_VISIBLE_COUNT = 5;
class DashboardFooter extends React.Component {

  state = {isOpen: false}

  renderItems = (items, name) => {
    const { onSelectFilter } = this.props;

    const visibleItemsCount = 6;
    const visibleItems = [];
    const hiddenItems = [];

    if (items.length > visibleItemsCount) {
      for (let i = 0; i < visibleItemsCount; i++) {
        visibleItems.push(items[i]);
      }

      for (let i = visibleItemsCount; i < items.length; i++) {
        hiddenItems.push(items[i]);
      }
      return (
        <div style={{ width: "100%" }}>
          <div className="d-flex flex-wrap">
            {visibleItems.map((item, item_index) => (
              <FooterItem
                key={item_index}
                id={item.id}
                count={item.count}
                name={item.title}
                onSelectFilter={onSelectFilter}
              />
            ))}
            <div className={cx("dash__drop-main--bot", `footer-${name}`)}>
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            className={cx(
              "dash__block",
              "dash__drop-list",
              "dash__drop-footer",
              `footer-${name}`
            )}
            style={{ display: "none" }}
          >
            {hiddenItems.map((item, item_index) => (
              <div
                key={item_index}
                onClick={onSelectFilter}
                className="drop-footer__item"
              >
                <span>{item.title}</span>
                <span className="dash__drop-footer count">{item.count}</span>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-wrap">
          {items.map((item, item_index) => (
            <FooterItem
              key={item_index}
              id={item.id}
              count={item.count}
              name={item.title}
              onSelectFilter={onSelectFilter}
            />
          ))}
        </div>
      );
    }
  };

  handleToggleVisibility = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }



  render() {
    const { title, name, items = [] } = this.props;
    const visible = items.slice(0, MAX_VISIBLE_COUNT);
    const invisible = items.slice(MAX_VISIBLE_COUNT);
    const hasInvisible = invisible.length > 0;
    const { isOpen } = this.state;
    
    return (<div className="dash_bot">
    <div className="dash_bot-top">
      {title}
    </div>
    <br/>
    <div className="d-flex flex-wrap">
      {visible.map(x => <div key={x.id} className="dash_bot-item">
        <Link to={{
              pathname: '/tasks',
              state: {
                setFilter: true,
                data: {
                  [name]: x.id
                }
              }
            }}>
          <span>{x.count}</span>
          <div>
            {x.title}
          </div>
        </Link>

      </div>)}
      {
        hasInvisible ? <React.Fragment>
        <div className="dash_bot-item dash_bot-item-btn">
          <div className="dash__drop-main dash__drop-main--bot" onClick={this.handleToggleVisibility}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={cx('dash__drop-list--bot', { 'open': isOpen })}>
          {invisible.map(x => <Link  key={x.id} to={{
              pathname: '/tasks',
              state: {
                setFilter: true,
                data: {
                  [name]: x.id
                }
              }
            }}>
            <span>{x.title}</span>
            <span>{x.count}</span>
          </Link>)}
        </div>
        </React.Fragment> : null
      }
      

    </div>
  </div>)

    // return (<div className="dash_bot">
    //   { [].map((row, rowIndex) => {
    //     return <React.Fragment key={rowIndex}>
    //       <div className="dash_bot-top">
    //         {row.title}
    //       </div>
    //       <br/>
    //       <div className="d-flex flex-wrap">
    //         <div className="dash_bot-item">
    //           801
    //           <div>
    //             ООО «Траксмакс»
    //           </div>
    //         </div>
    //         <div className="dash_bot-item">
    //           755
    //           <div>
    //             ООО «Адамант»
    //           </div>
    //         </div>
    //         <div className="dash_bot-item">
    //           801
    //           <div>
    //             ООО «Траксмакс»
    //           </div>
    //         </div>
    //         <div className="dash_bot-item">
    //           755
    //           <div>
    //             ООО «Адамант»
    //           </div>
    //         </div>
    //         <div className="dash_bot-item">
    //           801
    //           <div>
    //             ООО «Траксмакс»
    //           </div>
    //         </div>
    //         <div className="dash_bot-item">
    //           755
    //           <div>
    //             ООО «Адамант»
    //           </div>
    //         </div>

    //       </div>
    //     </React.Fragment>
    //   })}
    //   </div>)
      //   return (
      // <div className={cx("dash_bot")}>
      //     {[].map((element, element_index) => (
      //       <div key={element_index}>
      //         <div className={cx("dash_bot-top")}>{element.title}</div>
      //         <br />
      //         {this.renderItems(element.items, element.name)}
      //       </div>
      //     ))}
      //   </div>
      //   );
      }
    }
    
// DashboardFooter.propTypes = {
//           footer: PropTypes.array,
//         onSelectFilter: PropTypes.func.isRequired
//       };
      
// DashboardFooter.defaultProps = {
//           footer: []
//       };
      
      export default DashboardFooter;
