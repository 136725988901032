import {
  getReportAnalysis as _getReportAnalysis,
  getReportAnalysisTasks as _getReportAnalysisTasks,
} from '../../../services/api/reports/analysis'
import { setErrorContent } from '../../Error/actions'
import { logoutProcess } from '../../User/actions'
import * as actionTypes from './actionTypes'

export const getReportAnalysis = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.GET_REPORT_ANALYSIS_PENDING,
      data: {},
    })
    const { isSuccess, ...res } = await _getReportAnalysis(payload)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({
      type: actionTypes.GET_REPORT_ANALYSIS_SUCCESS,
      data: res.result,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_REPORT_ANALYSIS_ERROR })
  }
}

export const getReportAnalysisTasks = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.GET_REPORT_ANALYSIS_TASKS_PENDING,
      data: {},
    })
    const { isSuccess, ...res } = await _getReportAnalysisTasks(payload)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({
      type: actionTypes.GET_REPORT_ANALYSIS_TASKS_SUCCESS,
      data: res.result,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_REPORT_ANALYSIS_TASKS_ERROR })
  }
}