import React, { useEffect, useState } from 'react'
import { Input } from './Input'

const FIELD_PATHS = {
  financingSum: 'data.financing.sum',
  approvedSum: 'data.supply.sum',
  acceptanceDate: 'data.supply.acceptanceDate',
}

const getPropValue = (object, path = '') => path.split('.')
  .reduce((result, item) => result === undefined ? result : result[item], object)

const getDefaultValues = ({ task, items }) => items
  .map(item => Object.keys(item)[0])
  .reduce((result, item) => {
    const path = FIELD_PATHS[item]
    const value = getPropValue(task, path)
    result[item] = value
    return result
  }, {})

export const Item = ({
  task,
  items,
  index,
  submitted,
  queue,
  showNotification,
  setQueue,
  setValidated,
  setValuesNoGreater,
  setGlobalSubmitted,
  onClose,
}) => {
  const defaultValues = getDefaultValues({ task, items })

  const [payload, setPayload] = useState(defaultValues)

  const { number } = task?.data?.financing || {}

  const onChangePayload = (data) => setPayload({
    ...payload,
    ...data,
  })

  const newQueue = queue.map(item => {
    if (item.id === task.id) {
      item.data = payload
    }
    return item
  })

  const hasError = newQueue
    .reduce((result, item) => {
      const values = item?.data
      if (values) {
        for (const key in values) {
          const value = values[key]
          const configItem = items?.find(item => Object.keys(item)[0] === key)
          const { required } = configItem?.[key] || {}
          if (required) {
            result.push(value)
          }
        }
      }
      return result
    }, [])
    .some(item => !item)

  // console.log('newQueue:', newQueue)
  // console.log('hasError:', hasError)

  useEffect(() => {
    setValidated(!hasError)
  }, [hasError, setValidated])

  useEffect(() => {
    if (submitted && !hasError && !showNotification) {
      setGlobalSubmitted(true)
      setQueue(newQueue)
      onClose()
    }
  // eslint-disable-next-line
  }, [submitted])

  return (
    <tr>
      <td>
        <div>{number}</div>
      </td>
      {items.map((item, idx) => {
        const name = Object.keys(item)[0]
        const {
          edit,
          required,
          // bulk,
        } = item[name]
        const path = FIELD_PATHS[name]
        const defaultValue = getPropValue(task, path)
        const value = !!payload[name] || payload[name] === ''
          ? payload[name]
          : defaultValue
        const hasError = required && !value
        return (
          <td key={idx}>
            <div>
              <Input
                name={name}
                value={value}
                onChangePayload={(data) => {
                  onChangePayload(data)
                  if (name === 'approvedSum') {
                    setValuesNoGreater((prev) => {
                      const updatedValuesNoGreater = [...prev]
                      updatedValuesNoGreater[index] = defaultValue >= Number(data[name])
                      return updatedValuesNoGreater
                    })
                  }
                }}
                disabled={!edit}
                error={hasError && !!submitted && 'Поле обязательное для заполнения'}
              />
            </div>
          </td>
        )
      })}
    </tr>
  )
}