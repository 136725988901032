import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    width: 300,
  },
}

const Td1 = ({
  classes,
  children,
}) => <td className={classes.root}>{children}</td>
                
export default injectSheet(styles)(Td1)