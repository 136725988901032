import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  col9: {
    width: '75%',
    padding: [0, 10],
    display: 'inline-block',
    verticalAlign: 'top',
  },
})

export const Col9 = ({ children, className }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.col9, className)}>
      {children}
    </div>
  )
}