import axios from 'axios'

export const getEnvironment = async () => {
  try {
    const response = await axios({
      url: `/env.json`,
      method: 'GET',
    })
    return response
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}