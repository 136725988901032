import * as uploadFileTypes from './actionTypes'
import { Payments } from '../../services/api'

export const setUploadFile = data => async dispatch => {
  try {
    dispatch({
      type: uploadFileTypes.SET_UPLOAD_FILE,
      data
    })
  } catch (e) {
    console.log(e)
  }
}

export const successUploadFile = id => ({
  type: uploadFileTypes.SUCCESS_UPLOAD_FILE,
  data: id
})

export const failureUploadFile = id => ({
  type: uploadFileTypes.FAILURE_UPLOAD_FILE,
  data: id
})

export const uploadFile = files => dispatch => {
  if (files.length) {
    files.forEach(async file => {
      const formPayload = new FormData()
      formPayload.append('file', file.file)
      try {
        await Payments.uploadFile(formPayload)
        dispatch(successUploadFile(file.id))
      } catch (error) {
        dispatch(failureUploadFile(file.id))
      }
    })
  }
}

export const resetFileList = () => ({
  type: uploadFileTypes.RESET_FILES_LIST,
  data: null
})
