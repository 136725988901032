import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import TextField from '../TextField'
import DatePicker from '../DatePicker'
import Dropdown from '../Dropdown'
import TextFieldWithAutoComplete from '../TextFieldWithAutoComplete'
import { Icon } from './../../layout'
import HeaderFilter from './../../containers/HeaderFilter'

const styles = {
  reset: {
    minWidth: 160,
    height: 16,
    border: 'none',
    cursor: 'pointer',
    transition: '300ms',
    '&:focus': {
      outline: 0,
    },
    '&:hover': {
      opacity: .7,
    },
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 4,
      fill: '#484848',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& span': {
      fontSize: 13,
      lineHeight: 1.2,
      fontWeight: 500,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  selectAll: {
    background: '#504e5b',
    height: 40,
    margin: [0, 10],
    padding: [0, 20],
    color: '#fff',
    fontSize: 12,
    lineHeight: 40/12,
    fontWeight: 500,
    textAlign: 'center',
    transition: '300ms',
    borderRadius: 16,
    border: 'none',
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
}

class Filters extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    isDisable: PropTypes.bool,
    isHidden: PropTypes.bool,
    filters: PropTypes.array,
    taskTypes: PropTypes.array,
    onChangeFilter: PropTypes.func.isRequired,
    onFilterStart: PropTypes.func.isRequired,
  }
  static defaultProps = {
    isDisable: false,
    isHidden: false,
  }

  state = {
    checked: false,
    hasReset: false,
  }

  componentDidUpdate(prevProps) {
    const keys = Object.keys(this.props.selectedFilters) || []
    const prevKeys = Object.keys(prevProps.selectedFilters) || []
    const hasReset = prevKeys.length > 0 && keys.length === 0
    if (this.state.hasReset !== hasReset) {
      this.setState({ hasReset }, 
        () => {
          if (hasReset) {
            this.setState({ checked: false })
          }
        }
      )
    }
  }

  getTaskTypes(taskTypes, activeTaskType = '') {
    const { filters, onChangeFilter } = this.props
    let active = 0
    const list = taskTypes.reduce((result, taskType) => {
      if (taskType.group_name) {
        const preparedGroup = taskType.list.reduce((groupResult, item, index) => {
          if (activeTaskType === item.id) {
            active = result.length + index + 1
          }
          return groupResult.concat([{ type: 'group-item', id: item.id, name: item.name }])
        }, [])
        return result.concat([
          { type: 'group-name', id: null, name: taskType.group_name },
          ...preparedGroup,
        ])
      }
      if (activeTaskType === taskType.id) {
        active = result.length
      }
      return result.concat([{ type: 'item', id: taskType.id, name: taskType.name }])
    }, [{ type: 'item', id: 'all', name: 'Все заявки' }])

    if (list.length === 2) {
      active = 1
      if (filters.taskType !== list[1].id) {
        onChangeFilter({ taskType: list[1].id })
      }
    }

    return { active, list }
  }

  onClearField = (name, value) => {
    if (name && value) {
      this.props.onChangeFilter(Object.assign({}, { [name]: value }))
    }
  }

  onSelectDropdown = (name, id) => this.props.onChangeFilter({ [name]: id === 'all' ? '' : id })

  onCheckboxSelect = ({ target: { name, value } }) => value === 'all'
    ? this.onClearField(name, '')
    : this.props.onChangeFilter({ [name]: value })

  onTypeText = ({ target: { name, value } }) => this.props.onChangeFilter({ [name]: value })

  onSelectDate = ({ target: { name, value } }) => {
    const keys = name.split('/')
    const values = value.split('/')
    this.props.onChangeFilter({
      [keys[0]]: values[0],
      [keys[1]]: values[1],
    })
  }

  onEndMovingSlider = (name, value) => {
    this.props.onChangeFilter({
      [name]: {
        from: value[0],
        to: value[1],
      },
    })
  }

  onSearchSelect = (payload, name) => this.props.onChangeFilter({ [name]: payload.id })

  onBooleanChange = ({ target: { name, checked: value } }) => {
    this.setState({ checked: value })
    this.props.onChangeFilter({[name]: value})
  }

  renderFilters = (filters) => {
    const { isFetching } = this.props
    const { checked, hasReset } = this.state
    const content_DOM = []

    filters.forEach(({ type, name, title, nameFrom, nameTo, list }, index) => {
      switch (type) {
        case 'string':
          content_DOM.push(
            <TextField
              key={index}
              name={name}
              placeholder={title}
              isFetching={isFetching}
              onChange={this.onTypeText}
              onClear={this.onClearField}
              hasReset={hasReset}
              isMoney={false}
            />
          )
          break
        case 'datepicker':
          content_DOM.push(
            <DatePicker
              key={index}
              name={`${nameFrom}/${nameTo}`}
              isFetching={isFetching}
              onSelectDate={this.onSelectDate}
              onClear={this.onClearField}
              hasReset={hasReset}
            />
          )
          break
        case 'money':
          content_DOM.push(
            <TextField
              key={index}
              name={name}
              placeholder={title}
              isFetching={isFetching}
              onChange={this.onTypeText}
              onClear={this.onClearField}
              hasReset={hasReset}
              isMoney={true}
            />
          )
          break
        case 'boolean':
          content_DOM.push(
            <div key={index} className='board-item__checkbox board-item__checkbox--filter'>
              <label className='checkbox'>
                <input 
                  type='checkbox' 
                  name={name}
                  checked={checked}
                  onChange={this.onBooleanChange}
                />
                <span className='checkbox__text'>{title}</span>
              </label>
            </div>
          )
          break
        case 'autocomplete':
          content_DOM.push(
            <TextFieldWithAutoComplete
              key={index}
              classNames={{
                container: 'filter-input',
                input: 'filter-input__input',
              }}
              name={name}
              placeholder={name === 'principalCompanyId' ? 'Компания' : title}
              defaultValue=''
              value=''
              onSelect={this.onSearchSelect}
              onClear={this.onClearField}
              payloadKeys={['id', 'displayName']}
              isFetching={isFetching}
              hasReset={hasReset}
            />
          )
          break
        case 'list':
          content_DOM.push(
            <Dropdown
              key={index}
              name={name}
              toggleClassName='filter-dropdown'
              defaultActive={0}
              list={[
                {
                  id: undefined, 
                  name: title,
                }, 
                ...list
              ]}
              onSelectItem={this.onSelectDropdown}
              hasReset={hasReset}
            />
          )
          break
        default:
          content_DOM.push(<div>Invalid filter type</div>)
          break
      }
    })
    return content_DOM
  }

  getFilterData = () => this.props.onFilterStart()

  isCanResetFilter = () => {
    const { selectedFilters } = this.props
    let values = []
    for (var key in selectedFilters) {
      if (key !== 'limit') {
        values.push(selectedFilters[key])
      }
    }
    return values.some(value => typeof value === 'object'
      ? Object.values(value).length > 0
      : !!value
    )
  }

  render() {
    const { 
      classes,
      isDisable, 
      isHidden, 
      filters, 
      className,
      history,
      onClearAllFilters,
      onSelectAllTasks,
    } = this.props
    
    return (
      <HeaderFilter
        className={className}
        hidden={isHidden}
        disabled={isDisable}
      >
        {this.renderFilters(filters)}
        {history?.location?.pathname?.includes('tasks') && filters?.length > 0 &&
          <div
            className={classes.selectAll}
            onClick={onSelectAllTasks}
          >
            Выбрать все
          </div>
        }
        {this.isCanResetFilter()
          ? <div
              className={classes.reset}
              onClick={onClearAllFilters}
            >
              <Icon iconName='close2' />
              <span>Сбросить фильтры</span>
            </div>
          : null
        }
      </HeaderFilter>
    )
  }
}

export default withRouter(injectSheet(styles)(Filters))