import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  '@keyframes bounce': {
    '0%': {
      transform: 'scale(0)',
    },
    '50%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(0)',
    },
  },
  root: {
    width: '26px',
    height: '26px',
    margin: [0, 'auto'],
    position: 'relative',
  },
  bounce: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0.4,
    animation: '$bounce 2.0s infinite ease-in-out',
  },
  bounce1: {
    background: '#000',
  },
  bounce2: {
    background: '#FFF',
    animationDelay: '-1.0s',
  },
})

export const Spinner = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classnames(classes.bounce, classes.bounce1)} />
      <div className={classnames(classes.bounce, classes.bounce2)} />
    </div>
  )
}