import * as types from './actionTypes'

const initialState = {
  isFetching: false,
  filters: {},
  data: [],
}

export const CommonStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORT_COMMON_STATUS_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case types.GET_REPORT_COMMON_STATUS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
      }
    case types.GET_REPORT_COMMON_STATUS_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}
