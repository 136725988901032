import axios from 'axios'
import { API_SPA_URL, DEFAULT_PAGE_SIZE } from './../../config'
// import { prepareFiltersToQuery } from '../utility'

let cancelGetTaskList

export const getTasks = async (filters = {}, limit = DEFAULT_PAGE_SIZE) => {
  try {
    //dirty fix:
    if (filters.orderCreatedDate_to) {
      const { to, from } = filters.orderCreatedDate_to
      if (to && from) {
        filters.orderCreatedDate_to = to
        filters.orderCreatedDate_from = from
      }
    }
    filters.limit = limit
    
    if (cancelGetTaskList) {
      cancelGetTaskList.cancel()
    }

    cancelGetTaskList = axios.CancelToken.source()

    const {
      data: {
        error_code: status, 
        ...rest
      }, 
      //...other
    } = await axios({
      method: 'GET',
      params: filters,
      url: `${API_SPA_URL}/task/list`,
      cancelToken: cancelGetTaskList.token
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error,
    }
  } catch (err) {
    console.error(err)
    if (axios.isCancel(err)) {
      console.log('CANCELED GET TASK LIST')
    }
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getNextPage = async (page, filters = {}, limit = DEFAULT_PAGE_SIZE) => {
  try {
    // const query = prepareFiltersToQuery(filters)
    const params = {
      page,
      limit,
      ...filters
    }
    const { 
      data: {
        error_code: status, 
        ...rest
      } 
    } = await axios({
      method: 'GET',
      params,
      url: `${API_SPA_URL}/task/list`,
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}