import React, { useState, useCallback, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { getFactoringView, getFactoringMonIssueNotifications, getFactoringMessages } from '../../../redux/Factoring/actions'
import { Toast } from '../../../components/Toast'
import { Table } from '../Layout'
import { FactoringWrapper } from '../FactoringWrapper'
import { Card } from './Card'
import { useFactoring } from '../useFactoring'
import { createSearchString } from '../../../utils'
import { FACTORING_VIEWS } from '../../../config'

const useStyles = createUseStyles({
  content: {
    // minHeight: 300,
    maxHeight: 'inherit',
    position: 'relative'
  },
  container: {
    maxHeight: 'inherit',
    marginTop: 20,
    // padding: 20,
    // border: [1, 'solid', '#B6BCC3'],
    borderRadius: 5,
  },
})

export const FactoringViewPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { id, entityType: entityTypeFromParams } = useParams()

  const isMonitoringNotifications = history.location.pathname.toLowerCase().includes('monitoring')

  const entityType = isMonitoringNotifications
    ? 'notifications'
    : entityTypeFromParams

  const {
    dataItems,
    dataFilters,
    selectable,
  } = useFactoring({ entityType })

  const { views, issueNotifications } = useSelector(state => state?.Factoring || {})

  const [filters, setFilters] = useState({})
  const [refetch, setRefetch] = useState(0)
  const [selected, setSelected] = useState([])

  const items = isMonitoringNotifications
    ? issueNotifications?.items || []
    : views?.items || []

  const onGetFactoringMessages = useCallback(() => {
    return id
      ? dispatch(getFactoringMessages(entityType, id))
      : {}
  }, [entityType, id, dispatch])

  useEffect(() => {
    onGetFactoringMessages()
  }, [onGetFactoringMessages])

  const onOpen = ({ id, number }) => {
    const ID = id || number
    const subString = `${entityType}/${ID}`
    return ID
      ? history.push({
          pathname: isMonitoringNotifications
            ? `/factoring/monitoring/${subString}`
            : `/factoring/${subString}`,
          search: createSearchString(filters),
        })
      // : alert('Не определено значение поля id / number')
      : {}
  }

  const onClose = () => history.push({
    pathname: isMonitoringNotifications
      ? `/factoring/monitoring/${entityType}`
      : `/factoring/${entityType}`,
    search: createSearchString(filters),
  })

  const { title, entityName } = FACTORING_VIEWS.find(item => item?.entityType === entityType) || {}
  const { displayName } = items.find(item => item?.id?.toString() === id?.toString()) || {}

  const getFilters = (filters) => setFilters(filters)

  const onSuccess = () => {
    setRefetch(refetch + 1)
    Toast({ message: `Проводка успешно удалена`, type: 'success' })
  }

  const onSelect = (selected) => setSelected(selected)

  return (
    <>
      <FactoringWrapper
        id={id}
        title={isMonitoringNotifications
          ? 'Уведомления'
          : title
        }
        dataFilters={dataFilters}
        entityType={entityType}
        getFilters={getFilters}
        onRequest={isMonitoringNotifications
          ? getFactoringMonIssueNotifications
          : getFactoringView
        }
        requestPayload={entityType}
        refetch={refetch}
        selected={selected}
        isMonitoringNotifications={isMonitoringNotifications}
      >
        <div className={classes.content}>
          <div className={classes.container}>
            <Table
              id={id}
              entityType={entityType}
              dataItems={dataItems}
              items={items}
              onOpen={onOpen}
              onSuccess={onSuccess}
              {...selectable ? { onSelect } : {}}
            />
          </div>
        </div>
      </FactoringWrapper>
      {id &&
        <Card
          entityType={entityType}
          entityName={entityName}
          displayName={displayName}
          onGetFactoringMessages={onGetFactoringMessages}
          onClose={onClose}
        />
      }
    </>
  )
}