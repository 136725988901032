import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { getFactoringContractsBySupplierInn, getFactoringDebtors, startFactoringProcess } from '../../../../redux/Factoring/actions'
import { Modal2, InputText, Select2, Fieldset, Label, RowGrid, Col6, Col12, Error } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Button, ButtonWrapper, ButtonAdd, ButtonEdit, ButtonRemove, Form, H3 } from '../../Layout'
import { AddSupplyRegisterFormInner } from '../AddSupplyRegisterFormInner'
import { formattedDate } from '../../../../utils'
import styles from '../../styles'

const useStyles = createUseStyles({
  addSupplyRegisterForm: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
  },
  ...styles,
  supply: {
    height: 56,
    padding: [20, 0, 20, 10],
    fontSize: 16,
    lineHeight: 16/16,
    border: [1, 'solid', '#0b1f35'],
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& + &': {
      marginTop: 10,
    },
    '& > div': {
      display: 'flex',
    },
  },
})

const NEW_FINANCING_INDEX = -1

export const AddSupplyRegisterForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { documents } = useSelector(state => state.Factoring)

  const [supplyContracts, setSupplyContracts] = useState([])
  const [factoringContractsBySupplierInn, setFactoringContractsBySupplierInn] = useState([])
  const [debtors, setDebtors] = useState([])
  const [supplierName, setSupplierName] = useState('')
  const [supplierInn, setSupplierInn] = useState('')
  const [factoringContractDate, setFactoringContractDate] = useState('')
  const [factoringContractNumber, setFactoringContractNumber] = useState('')
  const [factoringContractId, setFactoringContractId] = useState('')
  const [number, setNumber] = useState('')
  const [registryDate, setRegistryDate] = useState('')
  const [financings, setFinancings] = useState([])
  const [financing, setFinancing] = useState({})
  const [index, setIndex] = useState(NEW_FINANCING_INDEX)
  const [openModal, setOpenModal] = useState(false)
  const [submitted, setSubmitted] = useState(0)

  const touched = useMemo(() => submitted > 0, [submitted])

  const factoringContractOptions = [
    { value: '', name: '' },
    ...factoringContractsBySupplierInn.map(item => ({ value: item.id, name: item.number }))
  ]

  const payload = {
    createMethod: 'FILE',
    number,
    registryDate,
    financings: financings.map(item => ({
      ...item,
      supplierInn,
      factoringContractDate,
      factoringContractNumber,
      confirmationDocument: (item?.confirmationDocument || []).reduce((result, item) => {
        if (item.type && item.number && item.date) {
          result.push(item)
        }
        return result
      }, [])
    })),
  }

  const errors = {
    ...(supplierInn?.trim() ? {} : { supplierInn: 'Не указан поставщик' }),
    ...(factoringContractNumber?.trim()
      ? {}
      : { factoringContractNumber: supplierInn?.trim()
          ? 'Не указан номер договора факторинга'
          : 'Не указан номер договора факторинга (необходимо указать поставщика)'
        }),
    ...(factoringContractDate?.trim() ? {} : { factoringContractDate: 'Не указана дата договора факторинга' }),
    ...(number.trim() ? {} : { number: 'Не указан номер реестра' }),
    ...(registryDate.trim() ? {} : { registryDate: 'Не указана дата поставки' }),
    ...(financings.length > 0 ? {} : { financings: 'Не указана поставка' }),
  }

  const isValid = Object.keys(errors)?.length === 0

  const onChangeSupplierInn = ({ INN, displayName }) => {
    setSupplierInn(INN)
    setSupplierName(displayName)
    const entityType = 'factoring_contract'
    const payload = { supplierInn: INN }
    const onSuccess = (data) => setFactoringContractsBySupplierInn(data)
    dispatch(getFactoringContractsBySupplierInn(entityType, payload, onSuccess))
  }
  const onClearSupplierInn = () => {
    setSupplierInn('')
    setSupplierName('')
    setFactoringContractsBySupplierInn([])
    setFactoringContractId('')
    setFactoringContractNumber('')
    setFactoringContractDate('')
  }

  const onChangeFactoringContract = (id, number, startDate) => {
    setFactoringContractId(id)
    setFactoringContractNumber(number)
    setFactoringContractDate(startDate)
    const onSuccess = (data) => {
      setDebtors([
        { name: '', value: '' },
        ...data.map(item => ({
          name: item.displayName,
          value: item.inn,
        })),
      ])
      setSupplyContracts([{ name: '', value: '' }, ...data
        .reduce((result, item) => {
          item.supplyContracts.forEach(supplyContract => result.push({
            ...supplyContract,
            inn: item.inn,
          }))
          return result
        }, [])
        .map(item => ({
          ...item,
          name: item.number,
          value: item.id,
        }))
      ])
    }
    dispatch(getFactoringDebtors(id, onSuccess))
  }

  const onChangeNumber = (e) => setNumber(e.target.value)
  const onChangeRegistryDate = (e) => setRegistryDate(e.target.value)

  const onAddFinancing = (payload) => setFinancings([...financings, payload])
  const onEditFinancing = (payload, index) => {
    const updatedFinancings = financings.map((item, idx) => {
      if (index === idx) {
        item = payload
      }
      return item
    })
    setFinancings(updatedFinancings)
  }
  const onRemoveFinancing = (index) => setFinancings(financings.filter((_, idx) => index !== idx))

  const onOpenEditFinancing = (payload, index) => {
    setFinancing({
      ...payload,
      supplierInn,
      factoringContractDate,
      factoringContractNumber,
    })
    setIndex(index)
    setOpenModal(true)
  }

  const onOpenModal = () => {
    setOpenModal(true)
    setIndex(NEW_FINANCING_INDEX)
  }
  const onCloseModal = () => setOpenModal(false)

  const onSubmit = () => setSubmitted(submitted + 1)

  const onStartFactoringProcess = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(startFactoringProcess(payload, 'factoring-pa-fin', onSuccess))
    }
  }

  useEffect(() => {
    if (touched) {
      onStartFactoringProcess()
    }
  // eslint-disable-next-line
  }, [touched, submitted])

  console.log('payload [1-st form]:', payload)
  console.log('errors [1-st form', errors)

  return (
    <Modal2
      className={classes.addSupplyRegisterForm}
      onClose={onClose}
      fullHeight
      withoutScroll
    >
      <div className={classes.wrapper}>
        <H3>Реестр поставок</H3>
        <Form>
          <Fieldset>
            <RowGrid>
              <Col12>
                <TextFieldWithAutoComplete
                  classNames={{
                    container: 'filter-input',
                    input: 'filter-input__input',
                  }}
                  name='supplierInn'
                  placeholder='Поставщик'
                  label='Поставщик'
                  value={supplierName}
                  defaultValue={supplierName}
                  onSelect={onChangeSupplierInn}
                  onClear={onClearSupplierInn}
                  payloadKeys={['INN', 'displayName']}
                  meta={{
                    touched,
                    error: errors.supplierInn,
                  }}
                  withoutLink
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <RowGrid>
                  <Col12>
                    <Select2
                      label='Номер договора факторинга'
                      name='factoringContract'
                      options={factoringContractOptions}
                      value={factoringContractId}
                      onChange={(e) => {
                        const id = e.target.value
                        const { number, startDate } = factoringContractsBySupplierInn.find(item => item?.id === id) || {}
                        onChangeFactoringContract(id, number, startDate)
                      }}
                      error={touched && (errors.factoringContractNumber || errors.factoringContractDate)}
                      disabled={!supplierInn}
                    />
                  </Col12>
                </RowGrid>
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col6>
                <InputText
                  type='text' 
                  name='number'
                  label='Номер реестра'
                  value={number}
                  onChange={onChangeNumber}
                  error={touched && errors?.number}
                />
              </Col6>
              <Col6>
                <InputText
                  type='date' 
                  name='registryDate'
                  label='Дата реестра'
                  value={registryDate}
                  onChange={onChangeRegistryDate}
                  error={touched && errors?.registryDate}
                />
              </Col6>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Label>Поставки:</Label>
              </Col12>
            </RowGrid>
            <RowGrid>
              <Col12>
                {financings?.map((item, index) => {
                  const { confirmationDocument, debtorName, supplySum } = item
                  const { number, date, type } = confirmationDocument[0]
                  return (
                    <div className={classes.supply} key={index}>
                      <div>
                        {number ? `${number}, ` : null}
                        {debtorName ? `${debtorName}, ` : null}
                        {supplySum ? `${supplySum}, ` : null}
                        {date ? `${formattedDate(date)}, ` : null}
                        {documents.find(({ id }) => id === type)?.name || ''}
                      </div>
                      <div>
                        <ButtonEdit
                          className={classes.actionBtn}
                          title='Редактировать поставку'
                          onClick={() => onOpenEditFinancing(item, index)}
                        />
                        <ButtonRemove
                          className={classes.actionBtn}
                          title='Удалить поставку'
                          onClick={() => onRemoveFinancing(index)}
                        />
                      </div>
                    </div>
                  )
                })}
                {touched && errors.financings &&
                  <Error>{errors.financings}</Error>
                }
              </Col12>
            </RowGrid>
            <RowGrid>
              <Col12>
                <ButtonAdd onClick={onOpenModal}>Добавить поставку</ButtonAdd>
              </Col12>
            </RowGrid>
          </Fieldset>
        </Form>
        <div style={{ flexGrow: 1 }} />
        <ButtonWrapper>
          <Button disabled={touched && !isValid} onClick={onSubmit}>Отправить реестр</Button>
        </ButtonWrapper>
        {openModal &&
          <AddSupplyRegisterFormInner
            index={index}
            financing={financing}
            supplyContracts={supplyContracts}
            debtors={debtors}
            onAddFinancing={onAddFinancing}
            onEditFinancing={onEditFinancing}
            onClose={onCloseModal}
          />
        }
      </div>
    </Modal2>
  )
}