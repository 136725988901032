import React from 'react'
import injectSheet from 'react-jss'
import { UI } from './../../../../layout'
import { formattedNumberToKilo } from './../../../../utils'
import { Table, Td1, Td2, Td3 } from './Layout'

const styles = {
  title: UI.title,
}

const GroupTable = ({ 
  classes,
  group, 
  cols,
}) => {
  const hasRows = group && group.rows && group.rows.length > 0
  const hasCols = cols && cols.length > 0

  return (
    <div className='form-group'>
      {group && 
        <div className={classes.title}>{group.label}</div>
      }
      <Table>
        <tbody>
          {hasRows && group.rows.map(({ code, label }, index) => 
            <tr key={`row-${index}`}>
              <Td1>{label}</Td1>
              <Td2>Код {code}</Td2>
              {hasCols && cols.map(({ id, data }, index) => 
                <Td3 key={`row-${index}-col-${id}`}>
                  {data[code] ? `${formattedNumberToKilo(data[code])}`: '-'}
                </Td3>
              )}
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default injectSheet(styles)(GroupTable)