import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  block: {
    '& + &': {
      marginTop: 24,
    },
  },
})

export const Block = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.block}>{children}</div>
  )
}