import React, {Fragment, Component } from 'react'
import { Field, FieldArray } from 'redux-form'

import TextField from '../../TextField'
import CheckboxField from '../../CheckboxField'

class AccountInfo extends Component {
  billRefs = []

  scrollToItem = (index, event) => {
    event.preventDefault()

    this.billRefs[index].scrollIntoView({
      behavior: 'smooth'
    })
  }

  renderAccount = (name, index) =>
    <Fragment key={index}>
      {index > 0 && <hr /> }
      <div
        ref={(node) => { this.billRefs[index] = node }}
        className='block_head col-12'
      >
        Счет №{index + 1}
      </div>
      <div className='col-12'>
        <Field
          name={`${name}.name`}
          label='Наименование банка'
          component={TextField}
        />
      </div>
      <div className='col-6 col-md-4'>
        <Field
          name={`${name}.bik`}
          label='БИК'
          component={TextField}
          disabled
        />
      </div>
      <div className='col-6 col-md-4'>
        <Field
          name={`${name}.bill`}
          label='Коррсчет'
          component={TextField}
          disabled
        />
      </div>
      <div className='col-6 col-md-4'>
        <Field
          name={`${name}.number`}
          label='Номер счета'
          component={TextField}
          disabled
        />
      </div>
      <div className='col-12 justify-content-center'>
        <Field
          name={`${name}.useInDocs`}
          id={`${name}.useInDocs`}
          type='checkbox'
          label='Использовать в документах'
          component={CheckboxField}
        />
      </div>
      <div className='col-12 justify-content-center'>
        <Field
          name={`${name}.cardTwo`}
          id={`${name}.cardTwo`}
          type='checkbox'
          label='Картотека №2'
          component={CheckboxField}
        />
      </div>
    </Fragment>

  renderAccountsList = ({ fields }) => fields.map((field, index) => this.renderAccount(field, index))

  render() {
    // const { taskInfo } = this.props
    // const { accounts = [] } = taskInfo

    return (
      <div className='block_item row align-items-stretch'>
        {/* <div className='block_head col-12'>Счета</div>
        <div className='col-12'>
          <div className='accounts'>
            {accounts.map((account, index) =>
              <div key={index} className='d-flex align-items-center account'>
                <div className='account__name'>{account.name}</div>
                <button
                  type='button'
                  className='account__link'
                  onClick={this.scrollToItem.bind(this, index)}
                >
                  {account.bill}
                </button>
              </div>
            )}
          </div>
        </div> */}
        <FieldArray name='accounts' component={this.renderAccountsList} />
      </div>
    )
  }
}

export default AccountInfo