export const STATUS_CODES = {
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  HAVE_PERMISSION: 'HAVE_PERMISSION',
  WITHOUT_PERMISSION: 'WITHOUT_PERMISSION',
  NOT_NPD: 'NOT_NPD',
  PAYMENT_GOOD: 'PAYMENT_GOOD',
  TRANSACTION_GOOD: 'TRANSACTION_GOOD',
  PAYMENT_BAD: 'PAYMENT_BAD',
  TRANSACTION_BAD: 'TRANSACTION_BAD',
  INCOME_GOOD: 'INCOME_GOOD',
  INCOME_BAD: 'INCOME_BAD',
  TAX_GOOD: 'TAX_GOOD',
  TAX_BAD: 'TAX_BAD',
  IN_PROCESS: 'IN_PROCESS',
  PROCESSED: 'PROCESSED'
}

export const PAYMENT_STATUS_CODES = {
  [STATUS_CODES.VALIDATION_ERROR]: 'Ошибка валидации полей',
  [STATUS_CODES.HAVE_PERMISSION]: 'Право на регистрацию дохода присутствует',
  [STATUS_CODES.WITHOUT_PERMISSION]: 'Невозможно сформировать чек',
  [STATUS_CODES.NOT_NPD]: 'Клиент не плательщик НПД',
  [STATUS_CODES.PAYMENT_GOOD]: 'Платёж проведён',
  [STATUS_CODES.TRANSACTION_GOOD]: 'Операция выполнена',
  [STATUS_CODES.PAYMENT_BAD]: 'Платёж не проведён',
  [STATUS_CODES.TRANSACTION_BAD]: 'Операция не выполнена',
  [STATUS_CODES.INCOME_GOOD]: 'Доход учтён',
  [STATUS_CODES.INCOME_BAD]: 'Доход не учтён',
  [STATUS_CODES.TAX_GOOD]: 'Налог уплачен',
  [STATUS_CODES.TAX_BAD]: 'Налог не уплачен',
  [STATUS_CODES.IN_PROCESS]: 'В обработке',
  [STATUS_CODES.PROCESSED]: 'Обработан'
}
