import React, { useState } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { updateCompanyWorkGroups } from '../../../../redux/Company/actions'
import { Row, InputText, UI } from '../../../../layout'
import Overlay from '../../../Overlay'
import { cloneDeep } from '../../../../utils'

const styles = {
  title: UI.h1,
  actions: {
    marginTop: 30,
    textAlign: 'right',
  },
  add: UI.addButton,
  cancel: {
    ...UI.cancelButton,
    marginRight: 20,
  },
}

const ChangeLoginModal = ({
  classes,
  companyId,
  group,
  groups,
  onCancel,
}) => {
  const dispatch = useDispatch()

  const { fetching } = useSelector(state => state.Company.workGroups)

  const [value, setValue] = useState('')

  const payload = [...groups].map(item => {
    item = cloneDeep(item)
    if (item.userRoleName === group.userRoleName) {
      item.userId = value
    }
    return item
  })

  const onChange = (e) => setValue(e.target.value)
  const onSubmit = () => value && value !== group.userId
    ? dispatch(updateCompanyWorkGroups(companyId, payload, onCancel))
    : alert(value === group.userId
      ? 'Новый логин должен отличаться от текущего'
      : 'Введите новый логин'
    )

  if (fetching) {
    return <Overlay/>
  }

  return (
    <>
      <div className={classes.title}>Изменение логина пользователя</div>
      <Row>
        <div className='col-12'>
          <InputText
            type='text'
            value={group.userRoleName}
            label='Роль:'
            disabled
          />
        </div>
      </Row>
      <Row>
        <div className='col-12'>
          <InputText
            type='text'
            value={group.userId}
            label='Текущий логин:'
            disabled
          />
        </div>
      </Row>
      
      <Row>
        <div className='col-12'>
          <InputText
            type='text'
            value={value}
            label='Новый логин:'
            onChange={onChange}
            autoFocus
          />
        </div>
      </Row>
      <div className={classes.actions}>
        <div className={classes.cancel} onClick={onCancel}>Отменить</div>
        <button 
          type='button'
          className={classnames(classes.add, 'btn-form--ok')} 
          onClick={onSubmit}
          disabled={fetching}
        >
          Изменить
        </button>
      </div>
    </>
  )
}

export default injectSheet(styles)(ChangeLoginModal)