import axios from 'axios'
import { API_URL, API_SPA_URL } from '../../config'

export const login = async (authData) => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'POST',
      url: `${API_URL}/login`,
      data: authData,
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const auth = async () => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      url: `${API_SPA_URL}/session`,
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    if (status === 401) {
      return {
        isSuccess: false,
        message: 'Пользователя с данной сессией не найдено!',
      }
    }
    return {
      isSuccess: false,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const logout = async () => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      url: `${API_SPA_URL}/session`,
    })
    if (status === 0) {
      return { 
        isSuccess: true, 
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const recoverPassword = async (username) => {
  try {
    const { data: { error_code: status, ...rest }} = await axios({
      method: 'POST',
      url: `${API_SPA_URL}/recover`,
      data: { username }
    })
    if (status === 0) {
      return { 
        isSuccess: true, 
      }
    }
    return {
      isSuccess: false,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const claimTask = async (taskId) => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      url: `${API_SPA_URL}/task/claim?taskId=${taskId}`
    })
    if (status === 0) {
      return {
        isSuccess: true,
      }
    }
    return {
      isSuccess: false,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}


export const closeSession = async () => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      params: {"_": Date.now()},
      method: 'GET',
      url: `${API_URL}/logout`
    })
    if (status === 0) {
      return {
        isSuccess: true,
      }
    }
    return {
      isSuccess: false,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}