import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import { getContracts, getContractStatus, getContractType } from '../../redux/Contracts/actions'
import { Toast } from '../../components/Toast'
import { Table } from '../FactoringPages/Layout'
import { FactoringWrapper } from '../FactoringPages/FactoringWrapper'
import { Card } from '../FactoringPages/FactoringViewPage/Card'
import { createSearchString } from '../../utils'

const useStyles = createUseStyles({
  content: {
    // minHeight: 300,
    maxHeight: 'inherit',
    position: 'relative'
  },
  container: {
    maxHeight: 'inherit',
    marginTop: 20,
    // padding: 20,
    // border: [1, 'solid', '#B6BCC3'],
    borderRadius: 5,
  },
})

const DATA_ITEMS = [
  { name: 'Договор', field: 'contractNumber' },
  { name: 'Доп. соглашение', field: 'isSupplementaryAgreement', type: 'boolean' },
  { name: 'Продукт', field: 'contractType' },
  { name: 'Клиент', field: 'clientCompanyInn' },
  { name: 'Действ С', field: 'contractDate', type: 'date' },
  { name: 'Действ ПО', field: 'contractEndDate', type: 'date' },
  { name: 'Статус', field: 'contractStatus', type: 'status' },
]

const DATA_FILTERS = []

const entityType = 'contract'

const CONTRACTS_PATHNAME = `${entityType}s`

const ContractsPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const dispatch = useDispatch()

  const { contracts, contractType, contractStatus } = useSelector(state => state?.Contracts || {})

  const [filters, setFilters] = useState({})
  const [refetch, setRefetch] = useState(0)
  const [contractsData, setContractsData] = useState([])

  useEffect(() => {
      dispatch(getContractType())
      dispatch(getContractStatus())
  }, [])// eslint-disable-line

  useEffect(() => {
    if (contracts && contracts.length) {
      const statusReference = contractStatus.hits?.hits?.reduce((acc, status) => {
        acc[status._id] = status._source?.name
        return acc
      }, {}) || {};
      const typeReference = contractType.hits?.hits?.reduce((acc, status) => {
        acc[status._id] = status._source?.name
        return acc
      }, {}) || {};

      const updatedContracts = contracts.map(contract => {
        return {
          ...contract,
          contractStatus: statusReference[contract.contractStatus] ?? contract.contractStatus,
          contractType: typeReference[contract.contractType] ?? contract.contractType,
        }
      })

      setContractsData(updatedContracts)
    }
  }, [contracts, contractType, contractStatus])


  const onOpen = ({ id }) => id
    ? history.push({
        pathname: `/${CONTRACTS_PATHNAME}/${id}`,
        search: createSearchString(filters),
      })
    // : alert('Не определено значение поля id / number')
    : {}

  const onClose = () => history.push({
    pathname: `/${CONTRACTS_PATHNAME}`,
    search: createSearchString(filters),
  })

  const { displayName } = contracts?.find(item => item?.id === id) || {}

  const getFilters = (filters) => setFilters(filters)

  const onSuccess = () => {
    setRefetch(refetch + 1)
    Toast({ message: `Проводка успешно удалена`, type: 'success' })
  }

  return (
    <>
      <FactoringWrapper
        id={id}
        title='Договоры'
        dataFilters={DATA_FILTERS}
        entityType={entityType}
        getFilters={getFilters}
        onRequest={getContracts}
        requestPayload={entityType}
        refetch={refetch}
      >
        <div className={classes.content}>
          <div className={classes.container}>
            <Table
              id={id}
              entityType={entityType}
              dataItems={DATA_ITEMS}
              items={contractsData}
              onOpen={onOpen}
              onSuccess={onSuccess}
            />
          </div>
        </div>
      </FactoringWrapper>
      {id &&
        <Card
          entityType={entityType}
          entityName='Договора'
          displayName={displayName}
          onClose={onClose}
        />
      }
      <div className='btn-options'>
        <Link
          className='btn-options__link'
          to='?add-contract'
        />
      </div>
    </>
  )
}

export default ContractsPage