import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { addCompanyClientLogin } from '../../../../redux/Company/actions'
import { Row, InputText, UI } from '../../../../layout'
import classnames from 'classnames'

const styles = {
  title: UI.h1,
  actions: {
    marginTop: '30px',
    textAlign: 'right',
  },
  add: UI.addButton,
  cancel: {
    ...UI.cancelButton,
    marginRight: '20px',
  },
}

const AddAgent = ({
  classes,
  companyId,
  addCompanyClientLogin,
  onCancel,
}) => {
  const [username, setUsername] = useState('')

  const onChange = (e) => setUsername(e.target.value)
  const onSubmit = (username) => addCompanyClientLogin({ companyId, username })

  return (
    <Fragment>
      <div className={classes.title}>Добавить нового пользователя</div>
      <Row>
        <div className='col-12'>
          <InputText
            type='text'
            value={username}
            onChange={onChange}
            autoFocus
          />
        </div>
      </Row>
      <div className={classes.actions}>
        <div className={classes.cancel} onClick={onCancel}>Отменить</div>
        <button 
          type='button'
          className={classnames(classes.add, 'btn-form--ok')} 
          onClick={() => onSubmit(username)}
          //disabled={!username}
        >
          Добавить
        </button>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (store) => {
  return {}
}

const dispatchMapToProps = (dispatch, ownProps) => {
  return {
    addCompanyClientLogin: (payload) => {
      dispatch(addCompanyClientLogin(payload))
    },
  }
}

AddAgent.propTypes = {
  companyId: PropTypes.string.isRequired,
  addCompanyClientLogin: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, dispatchMapToProps)(injectSheet(styles)(AddAgent))