import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    paddingBottom: 10,
    color: '#504e5b',
    fontSize: 18,
    lineHeight: 1.8,
    fontWeight: 500,
  },
})

export const Title = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>{children}</div>
  )
}