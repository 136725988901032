import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Col } from 'reactstrap'
import { Row } from '../../../../layout'
import Dropdown from '../../../../components/Dropdown'
import { getFile } from '../../../../redux/Files/actions'
import { ReportTab } from '../../Layout/ReportTab'
import { commonStatusStyles } from '../all3d/styles'
import { useProcessSelectOptions } from '../../useProcessSelectOptions'


const now = new Date()
const currentMonth = now.getMonth() + 1
const currentYear = now.getFullYear()

const months = [
  { id: '1', name: 'Январь' },
  { id: '2', name: 'Февраль' },
  { id: '3', name: 'Март' },
  { id: '4', name: 'Апрель' },
  { id: '5', name: 'Май' },
  { id: '6', name: 'Июнь' },
  { id: '7', name: 'Июль' },
  { id: '8', name: 'Август' },
  { id: '9', name: 'Сентябрь' },
  { id: '10', name: 'Октябрь' },
  { id: '11', name: 'Ноябрь' },
  { id: '12', name: 'Декабрь' },
]

const years = [
  { id: currentYear, name: currentYear },
  { id: currentYear - 1, name: currentYear - 1 },
  { id: currentYear - 2, name: currentYear - 2 },
  { id: currentYear - 3, name: currentYear - 3 },
  { id: currentYear - 4, name: currentYear - 4 },
  { id: currentYear - 5, name: currentYear - 5 },
  { id: currentYear - 6, name: currentYear - 6 },
  { id: currentYear - 7, name: currentYear - 7 },
  { id: currentYear - 8, name: currentYear - 8 },
  { id: currentYear - 9, name: currentYear - 9 },
  { id: currentYear - 10, name: currentYear - 10 },
]

export const StatisticsYMReport = commonStatusStyles(({
  classes,
  url,
  onCancel,
  settings,
}) => {
  const dispatch = useDispatch()

  const { processDefault } = useProcessSelectOptions(settings.process)

  const [year, setYear] = useState(currentYear)
  const [month, setMonth] = useState(currentMonth)
  const [loading, setLoading] = useState(false)

  const onChangeMonth = (e, id) => setMonth(id)
  const onChangeYear = (e, id) => setYear(id)

  const valid = year && month

  const onDownloadFile = () => {
    if (valid && !loading) {
      setLoading(true)
      const url = `${settings.url}?year=${year}&month=${month}`
      const name = `bg_statsJournal-${processDefault}-${year}-${month}.xlsx`
      const onSuccess = () => setLoading(false)
      const onError = () => setLoading(false)
      dispatch(getFile({ url, name, onSuccess, onError }))
    }
  }

  return (
    <ReportTab
      onOk={onDownloadFile}
      onCancel={onCancel}
      okButtonText='Получить отчёт'
      disabled={!valid}
      loading={loading}
      title={settings.description}
    >
      <Col className={classes.form}>
        <Row>
          <div className='col-6'>
            <div className={classes.label}>Год</div>
            <Dropdown name='year' list={years} onSelectItem={onChangeYear} />
          </div>
          <div className='col-6'>
            <div className={classes.label}>Месяц</div>
            <Dropdown name='month' list={months} onSelectItem={onChangeMonth} />
          </div>
        </Row>
      </Col>
    </ReportTab>
  )
})