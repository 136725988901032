import axios from 'axios'
import { API_URL } from '../../config'

export const getRegisters = async filters => {
  try {
    const params = { query: filters }
    const { data: registers } = await axios({
      method: 'GET',
      params,
      url: `${API_URL}/registry-payment/registry/search`
    })
    return {
      isSuccess: true,
      registers
    }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const getRegisterPayments = async (regId, params) => {
  try {
    const {
      data: { payments }
    } = await axios({
      method: 'GET',
      params,
      url: `${API_URL}/registry-payment/registry/payment/${regId}`
    })
    return {
      isSuccess: true,
      payments
    }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const uploadFile = formData => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }

  return axios.post(
    `${API_URL}/registry-payment/registry/uploadFile`,
    formData,
    config
  )
}

export const getHrefJsonRegister = regId => {
  return `${API_URL}/registry-payment/registry/payment/${regId}`
}

export const getHrefCsvRegister = regId =>
  `${API_URL}/registry-payment/registry/payment/${regId}/csv`
