import React from 'react'
import injectSheet from 'react-jss'

const styles = {
	root: {
		color: '#898989',
		fontSize: '10px',
		lineHeight: '18px',
		fontWeight: 500,
		letterSpacing: '1px',
		textTransform: 'uppercase',
	},
}

const Title = ({ 
  children,
	classes,
}) => <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Title)