import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import { Row, Col4, Col6, Col12 } from '../../../../../../layout'
import { Block, Body, Fieldset, InputText, Item } from '../../Layout'
import { Header } from '../../Layout'

export const MtsBusiness = ({
  date,
  enabled,
  orderNumber,
}) => {
  const [open, setOpen] = useState(false)
  return (
    <Item>
      <Header
        name='МТС-Бизнес'
        open={open}
        date={date}
        enabled={enabled}
        orderNumber={orderNumber}
        onCollapse={() => setOpen(!open)}
      />
      <Collapse isOpen={open}>
        <Body>
          <Block>
            <Fieldset>
              <Row>
                <Col4>
                  <InputText
                    label='Фамилия'
                    value={''}
                    disabled
                  />
                </Col4>
                <Col4>
                  <InputText
                    label='Имя'
                    value={''}
                    disabled
                  />
                </Col4>
                <Col4>
                  <InputText
                    label='Отчество'
                    value={''}
                    disabled
                  />
                </Col4>
              </Row>
            </Fieldset>
            <Fieldset>
              <Row>
                <Col6>
                  <InputText
                    label='Серия'
                    value={''}
                    disabled
                  />
                </Col6>
                <Col6>
                  <InputText
                    label='Номер'
                    value={''}
                    disabled
                  />
                </Col6>
              </Row>
            </Fieldset>
            <Fieldset>
              <Row>
                <Col6>
                  <InputText
                    label='Дата выдачи'
                    value={''}
                    disabled
                  />
                </Col6>
                <Col6>
                  <InputText
                    label='Код подразделения'
                    value={''}
                    disabled
                  />
                </Col6>
              </Row>
            </Fieldset>
            <Fieldset>
              <Row>
                <Col12>
                  <InputText
                    label='Кем выдан'
                    value={''}
                    disabled
                  />
                </Col12>
              </Row>
            </Fieldset>
            <Fieldset>
              <Row>
                <Col12>
                  <InputText
                    label='Телефон'
                    value={''}
                    disabled
                  />
                </Col12>
              </Row>
            </Fieldset>
          </Block>
        </Body>
      </Collapse>
    </Item>
  )
}