import React from 'react'
import PropTypes from 'prop-types'
import TaskCard from '../../../components/Card/Task'
import LoadingNext from '../../../components/LoadingNext'

const TasksList = ({
  isLoading,
  isLoadingNext,
  task,
  selectedTasks,
  onOpenClaimTask,
  onOpenDetail,
  onSelectTask,
  onClickWithShift,
}) =>
  <>
    <div className='board-row'>
      {task.map(({
        id,
        title,
        items,
        statuses,
        isAssigned,
        isHightLightedError,
        bulkActions,
        orderId,
      }, index) =>
        <div key={index} className='board-col'>
          <TaskCard
            orderId={orderId}
            bulkActions={bulkActions}
            selected={!!selectedTasks[id]}
            taskId={id}
            taskName={title}
            items={items}
            statuses={statuses}
            isAssigned={isAssigned}
            isHightLightedError={isHightLightedError}
            isFetching={isLoading}
            selectedTasks={selectedTasks}
            onOpenClaimTask={onOpenClaimTask}
            onOpenDetail={onOpenDetail}
            onSelectTask={onSelectTask}
            onClickWithShift={onClickWithShift}
          />
        </div>
      )}
    </div>
    <LoadingNext loading={isLoadingNext} />
  </>

TasksList.propTypes = {
  list: PropTypes.array,
  // selectedTasks: PropTypes.arrayOf(PropTypes.string),
  selectedTasks: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isLoadingNext: PropTypes.bool.isRequired,
  onOpenClaimTask: PropTypes.func.isRequired,
  onOpenDetail: PropTypes.func.isRequired,
  onSelectTask: PropTypes.func.isRequired,
  task: PropTypes.array.isRequired,
  tasksFilters: PropTypes.object.isRequired,
}

export default TasksList