import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  buttonWrapper: {
    marginTop: 20,
    textAlign: 'right',
  },
})

export const ButtonWrapper = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.buttonWrapper}>{children}</div>
  )
}