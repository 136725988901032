import { getReportStandards as _getReportStandards } from '../../../services/api/reports/standards'
import { setErrorContent } from '../../Error/actions'
import { logoutProcess } from '../../User/actions'
import * as actionTypes from './actionTypes'

export const getReportStandards = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.GET_REPORT_STANDARDS_PENDING,
      data: {},
    })
    const { isSuccess, ...res } = await _getReportStandards(payload)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({
      type: actionTypes.GET_REPORT_STANDARDS_SUCCESS,
      data: res.result.tasks,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_REPORT_STANDARDS_ERROR })
  }
}