import * as actionTypes from './actionTypes'
import { Factoring } from '../../services/api'
import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'
import { formattedDate } from '../../utils'

const action = ({
  types,
  request,
  additionalData,
  errorMessage,
  forSuggestions = false,
  dataToSuccess = false,
  onSuccess,
  onError,
}) => async (dispatch) => {
  const _onError = (message, status) => {
    !forSuggestions && dispatch({
      type: types.error,
      ...additionalData,
    })
    dispatch(setErrorContent(errorMessage || message))
    onError && onError(message, status)
  }
  try {
    !forSuggestions && dispatch({ type: types.fetching })
    const { isSuccess, needLogout, message, status, data } = await request()
    if (isSuccess) {
      !forSuggestions && dataToSuccess && dispatch({ type: types.success })
      !forSuggestions && !dataToSuccess && dispatch({
        type: types.success,
        data,
        ...additionalData,
      })
      onSuccess && onSuccess(dataToSuccess ? data : data.data)
    } else {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      _onError(message, status)
    }
  } catch (error) {
    _onError(error?.message, error?.status)
  }
}

export const getFactoringTasks = (payload, filters) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_TASKS,
    success: actionTypes.GET_FACTORING_TASKS_SUCCESS,
    error: actionTypes.GET_FACTORING_TASKS_ERROR,
  },
  request: () => Factoring.getFactoringTasks(payload, filters),
  errorMessage: '',
})

export const getFactoringTasks2 = (payload, filters) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_TASKS_2,
    success: actionTypes.GET_FACTORING_TASKS_SUCCESS_2,
    error: actionTypes.GET_FACTORING_TASKS_ERROR_2,
  },
  request: () => Factoring.getFactoringTasks2(payload, filters),
  additionalData: {
    filters,
  },
  errorMessage: '',
})

export const completeFactoringTask = (id, type, payload, onSuccess, onError) => action({
  types: {
    fetching: actionTypes.COMPLETE_FACTORING_TASK,
    success: actionTypes.COMPLETE_FACTORING_TASK_SUCCESS,
    error: actionTypes.COMPLETE_FACTORING_TASK_ERROR,
  },
  request: () => Factoring.completeFactoringTask(id, type, payload),
  errorMessage: '',
  onSuccess,
  onError,
})

export const getFactoringView = (entityType, filters, onSuccess, forSuggestions) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_VIEW,
    success: actionTypes.GET_FACTORING_VIEW_SUCCESS,
    error: actionTypes.GET_FACTORING_VIEW_ERROR,
  },
  request: () => Factoring.getFactoringView(entityType, filters),
  additionalData: {
    entityType,
    filters,
  },
  errorMessage: '',
  onSuccess,
  forSuggestions,
})

export const getFactoringContractsBySupplierInn = (entityType, filters, onSuccess) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN,
    success: actionTypes.GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN_SUCCESS,
    error: actionTypes.GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN_ERROR,
  },
  request: () => Factoring.getFactoringView(entityType, filters),
  errorMessage: '',
  onSuccess,
})

export const getFactoringShortInfo = (entityType, companyId, showFieldName) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_SHORT_INFO,
    success: actionTypes.GET_FACTORING_SHORT_INFO_SUCCESS,
    error: actionTypes.GET_FACTORING_SHORT_INFO_ERROR,
  },
  request: () => Factoring.getFactoringShortInfo(entityType, companyId),
  additionalData: {
    showFieldName,
  },
  errorMessage: '',
})

export const getFactoringMessages = (entityType, entityId) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_MESSAGES,
    success: actionTypes.GET_FACTORING_MESSAGES_SUCCESS,
    error: actionTypes.GET_FACTORING_MESSAGES_ERROR,
  },
  request: () => Factoring.getFactoringMessages(entityType, entityId),
  errorMessage: '',
})

export const sendFactoringMessage = (payload, onSuccess) => action({
  types: {
    fetching: actionTypes.SEND_FACTORING_MESSAGE,
    success: actionTypes.SEND_FACTORING_MESSAGE_SUCCESS,
    error: actionTypes.SEND_FACTORING_MESSAGE_ERROR,
  },
  request: () => Factoring.sendFactoringMessage(payload),
  errorMessage: '',
  onSuccess,
})

export const startFactoringProcess = (payload, processDefinitionKey, onSuccess) => action({
  types: {
    fetching: actionTypes.START_FACTORING_PROCESS,
    success: actionTypes.START_FACTORING_PROCESS_SUCCESS,
    error: actionTypes.START_FACTORING_PROCESS_ERROR,
  },
  request: () => Factoring.startFactoringProcess(payload, processDefinitionKey),
  errorMessage: 'Не удалось отправить реестр',
  onSuccess,
})

export const startFactoringProcessTransit = (payload, processDefinitionKey, onSuccess) => action({
  types: {
    fetching: actionTypes.START_FACTORING_PROCESS_TRANSIT,
    success: actionTypes.START_FACTORING_PROCESS_TRANSIT_SUCCESS,
    error: actionTypes.START_FACTORING_PROCESS_TRANSIT_ERROR,
  },
  request: () => Factoring.startFactoringProcessTransit(payload, processDefinitionKey),
  errorMessage: 'Не удалось отправить реестр',
  onSuccess,
})

export const startFactoringProcessContractPreparation = (payload, processDefinitionKey, onSuccess) => action({
  types: {
    fetching: actionTypes.START_FACTORING_PROCESS_CONTRACT_PREPARATION,
    success: actionTypes.START_FACTORING_PROCESS_CONTRACT_PREPARATION_SUCCESS,
    error: actionTypes.START_FACTORING_PROCESS_CONTRACT_PREPARATION_ERROR,
  },
  request: () => Factoring.startFactoringProcessContractPreparation(payload, processDefinitionKey),
  errorMessage: 'Не удалось запустить процесс подготовки договора факторинга',
  onSuccess,
})

export const startFactoringProcessSupplyUpdate = (payload, processDefinitionKey, onSuccess) => action({
  types: {
    fetching: actionTypes.START_FACTORING_PROCESS_SUPPLY_UPDATE,
    success: actionTypes.START_FACTORING_PROCESS_SUPPLY_UPDATE_SUCCESS,
    error: actionTypes.START_FACTORING_PROCESS_SUPPLY_UPDATE_ERROR,
  },
  request: () => Factoring.startFactoringProcessSupplyUpdate(payload, processDefinitionKey),
  errorMessage: 'Не удалось запустить процесс изменения поставки',
  onSuccess,
})

export const getFactoringDebtors = (factoringContractId, onSuccess) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_DEBTORS,
    success: actionTypes.GET_FACTORING_DEBTORS_SUCCESS,
    error: actionTypes.GET_FACTORING_DEBTORS_ERROR,
  },
  request: () => Factoring.getFactoringDebtors(factoringContractId),
  errorMessage: 'Не удалось получить дебиторов',
  onSuccess,
  dataToSuccess: true,
})

export const getFactoringDuplicateSettings = (payload) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_DUPLICATE_SETTINGS,
    success: actionTypes.GET_FACTORING_DUPLICATE_SETTINGS_SUCCESS,
    error: actionTypes.GET_FACTORING_DUPLICATE_SETTINGS_ERROR,
  },
  request: () => Factoring.getFactoringDuplicateSettings(payload),
  errorMessage: 'Не удалось получить настроек дедупликации',
})

export const getFactoringPriceMatrix = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_PRICE_MATRIX,
    success: actionTypes.GET_FACTORING_PRICE_MATRIX_SUCCESS,
    error: actionTypes.GET_FACTORING_PRICE_MATRIX_ERROR,
  },
  request: () => Factoring.getFactoringPriceMatrix(id),
  errorMessage: 'Не удалось получить прайс-матрицы',
})

export const getFactoringSupplyCommissions = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_SUPPLY_COMMISSIONS,
    success: actionTypes.GET_FACTORING_SUPPLY_COMMISSIONS_SUCCESS,
    error: actionTypes.GET_FACTORING_SUPPLY_COMMISSIONS_ERROR,
  },
  request: () => Factoring.getFactoringSupplyCommissions(id),
  errorMessage: 'Не удалось получить расчет комиссий',
})

export const getFactoringSuretyTypes = (factoringContractId) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_SURETY_TYPES,
    success: actionTypes.GET_FACTORING_SURETY_TYPES_SUCCESS,
    error: actionTypes.GET_FACTORING_SURETY_TYPES_ERROR,
  },
  request: () => Factoring.getFactoringSuretyTypes(factoringContractId),
  errorMessage: 'Не удалось получить справочник договоров поручителей',
})

export const createFactoringLimit = (payload, entityType, onSuccess) => action({
  types: {
    fetching: actionTypes.CREATE_FACTORING_LIMIT,
    success: actionTypes.CREATE_FACTORING_LIMIT_SUCCESS,
    error: actionTypes.CREATE_FACTORING_LIMIT_ERROR,
  },
  request: () => Factoring.createFactoringEntity(payload, entityType),
  errorMessage: 'Не удалось создать лимит',
  onSuccess,
})

export const createFactoringRate = (payload, entityType, onSuccess) => action({
  types: {
    fetching: actionTypes.CREATE_FACTORING_RATE,
    success: actionTypes.CREATE_FACTORING_RATE_SUCCESS,
    error: actionTypes.CREATE_FACTORING_RATE_ERROR,
  },
  request: () => Factoring.createFactoringEntity(payload, entityType),
  errorMessage: 'Не удалось создать ставку',
  onSuccess,
})

export const createFactoringBlock = (payload, entityType, onSuccess) => action({
  types: {
    fetching: actionTypes.CREATE_FACTORING_BLOCK,
    success: actionTypes.CREATE_FACTORING_BLOCK_SUCCESS,
    error: actionTypes.CREATE_FACTORING_BLOCK_ERROR,
  },
  request: () => Factoring.createFactoringEntity(payload, entityType),
  errorMessage: 'Не удалось создать блокировку',
  onSuccess,
})

export const createFactoringAccount = (payload, entityType, onSuccess) => action({
  types: {
    fetching: actionTypes.CREATE_FACTORING_ACCOUNT,
    success: actionTypes.CREATE_FACTORING_ACCOUNT_SUCCESS,
    error: actionTypes.CREATE_FACTORING_ACCOUNT_ERROR,
  },
  request: () => Factoring.createFactoringEntity(payload, entityType),
  errorMessage: 'Не удалось создать счёт',
  onSuccess,
})

export const createFactoringPayment = ({
  payload,
  entityType,
  ignoreDuplicates,
  onSuccess,
  onError,
}) => action({
  types: {
    fetching: actionTypes.CREATE_FACTORING_PAYMENT,
    success: actionTypes.CREATE_FACTORING_PAYMENT_SUCCESS,
    error: actionTypes.CREATE_FACTORING_PAYMENT_ERROR,
  },
  request: () => Factoring.createFactoringEntity(payload, entityType, ignoreDuplicates),
  errorMessage: 'Не удалось создать платёж',
  onSuccess,
  onError,
})

export const createFactoringOperation = (payload, entityType, onSuccess) => action({
  types: {
    fetching: actionTypes.CREATE_FACTORING_OPERATION,
    success: actionTypes.CREATE_FACTORING_OPERATION_SUCCESS,
    error: actionTypes.CREATE_FACTORING_OPERATION_ERROR,
  },
  request: () => Factoring.createFactoringEntity(payload, entityType),
  errorMessage: 'Не удалось создать проводку',
  onSuccess,
})

export const getFactoringPaymentOrders = (payload) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_PAYMENT_ORDERS,
    success: actionTypes.GET_FACTORING_PAYMENT_ORDERS_SUCCESS,
    error: actionTypes.GET_FACTORING_PAYMENT_ORDERS_ERROR,
  },
  request: () => Factoring.getFactoringPaymentOrders(payload),
  additionalData: {
    isPaymentOrders: payload.orderType.length === 1 && payload.orderType[0] === 'supply',
  },
  errorMessage: '',
})

export const resetFactoringPaymentOrders = () => async (dispatch) => 
  dispatch({ type: actionTypes.RESET_FACTORING_PAYMENT_ORDERS })

export const getFactoringMatchingsByTaskId = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_MATCHINGS_BY_TASK_ID,
    success: actionTypes.GET_FACTORING_MATCHINGS_BY_TASK_ID_SUCCESS,
    error: actionTypes.GET_FACTORING_MATCHINGS_BY_TASK_ID_ERROR,
  },
  request: () => Factoring.getFactoringMatchingsByTaskId(id),
  errorMessage: '',
})

export const getFactoringMatchingsByPaymentId = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_MATCHINGS_BY_PAYMENT_ID,
    success: actionTypes.GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_SUCCESS,
    error: actionTypes.GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_ERROR,
  },
  request: () => Factoring.getFactoringMatchingsByPaymentId(id),
  errorMessage: '',
})

export const resetFactoringMatchingsByPaymentId = () => async (dispatch) => 
  dispatch({ type: actionTypes.RESET_FACTORING_MATCHINGS_BY_PAYMENT_ID_SUCCESS })

export const getFactoringDistributePayment = ({ payload, onSuccess, onError }) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_DISTRIBUTE_PAYMENT,
    success: actionTypes.GET_FACTORING_DISTRIBUTE_PAYMENT_SUCCESS,
    error: actionTypes.GET_FACTORING_DISTRIBUTE_PAYMENT_ERROR,
  },
  request: () => Factoring.getFactoringDistributePayment(payload),
  errorMessage: '',
  onSuccess,
  onError,
})

export const resetFactoringDistributePayment = () => async (dispatch) => 
  dispatch({ type: actionTypes.RESET_FACTORING_DISTRIBUTE_PAYMENT })

export const deleteFactoringOperation = (id, onSuccess, onError) => action({
  types: {
    fetching: actionTypes.DELETE_FACTORING_OPERATION,
    success: actionTypes.DELETE_FACTORING_OPERATION_SUCCESS,
    error: actionTypes.DELETE_FACTORING_OPERATION_ERROR,
  },
  request: () => Factoring.deleteFactoringOperation(id),
  errorMessage: '',
  onSuccess,
  onError,
})

export const getFactoringMasterOperation = (payload) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_MASTER_OPERATION,
    success: actionTypes.GET_FACTORING_MASTER_OPERATION_SUCCESS,
    error: actionTypes.GET_FACTORING_MASTER_OPERATION_ERROR,
  },
  request: () => Factoring.getFactoringMasterOperation(payload),
  errorMessage: 'Не удалось получить ИД мастер-операции',
})

export const getFactoringCounterPartyCommon = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_COUNTERPARTY_COMMON,
    success: actionTypes.GET_FACTORING_COUNTERPARTY_COMMON_SUCCESS,
    error: actionTypes.GET_FACTORING_COUNTERPARTY_COMMON_ERROR,
  },
  request: () => Factoring.getFactoringCounterPartyCommon(id),
  errorMessage: 'Не удалось получить данные по вкладке ОБЩЕЕ',
})

export const getFactoringCounterPartyAddress = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_COUNTERPARTY_ADDRESS,
    success: actionTypes.GET_FACTORING_COUNTERPARTY_ADDRESS_SUCCESS,
    error: actionTypes.GET_FACTORING_COUNTERPARTY_ADDRESS_ERROR,
  },
  request: () => Factoring.getFactoringCounterPartyAddress(id),
  errorMessage: 'Не удалось получить данные по вкладке АДРЕС',
})

export const getFactoringCounterPartyState = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_COUNTERPARTY_STATE,
    success: actionTypes.GET_FACTORING_COUNTERPARTY_STATE_SUCCESS,
    error: actionTypes.GET_FACTORING_COUNTERPARTY_STATE_ERROR,
  },
  request: () => Factoring.getFactoringCounterPartyState(id),
  errorMessage: 'Не удалось получить данные по вкладке СОСТОЯНИЕ',
})

export const getFactoringCounterPartyInfo = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_COUNTERPARTY_INFO,
    success: actionTypes.GET_FACTORING_COUNTERPARTY_INFO_SUCCESS,
    error: actionTypes.GET_FACTORING_COUNTERPARTY_INFO_ERROR,
  },
  request: () => Factoring.getFactoringCounterPartyInfo(id),
  errorMessage: 'Не удалось получить данные по вкладке ИНФО',
})

export const getFactoringCounterPartySigner = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_COUNTERPARTY_SIGNER,
    success: actionTypes.GET_FACTORING_COUNTERPARTY_SIGNER_SUCCESS,
    error: actionTypes.GET_FACTORING_COUNTERPARTY_SIGNER_ERROR,
  },
  request: () => Factoring.getFactoringCounterPartySigner(id),
  errorMessage: 'Не удалось получить данные по вкладке ПОДПИСАНТЫ',
})

export const getFactoringCounterPartyContact = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_COUNTERPARTY_CONTACT,
    success: actionTypes.GET_FACTORING_COUNTERPARTY_CONTACT_SUCCESS,
    error: actionTypes.GET_FACTORING_COUNTERPARTY_CONTACT_ERROR,
  },
  request: () => Factoring.getFactoringCounterPartyContact(id),
  errorMessage: 'Не удалось получить данные по вкладке ЗАКРЕПЛЕНИЕ ПОСТАВЩИКОВ',
})

export const getFactoringFactoringContractCommon = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_FACTORING_CONTRACT_COMMON,
    success: actionTypes.GET_FACTORING_FACTORING_CONTRACT_COMMON_SUCCESS,
    error: actionTypes.GET_FACTORING_FACTORING_CONTRACT_COMMON_ERROR,
  },
  request: () => Factoring.getFactoringFactoringContractCommon(id),
  errorMessage: 'Не удалось получить данные по вкладке ОБЩЕЕ',
})

export const getFactoringFactoringContractMain = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_FACTORING_CONTRACT_MAIN,
    success: actionTypes.GET_FACTORING_FACTORING_CONTRACT_MAIN_SUCCESS,
    error: actionTypes.GET_FACTORING_FACTORING_CONTRACT_MAIN_ERROR,
  },
  request: () => Factoring.getFactoringFactoringContractMain(id),
  errorMessage: 'Не удалось получить данные по вкладке ОСНОВНОЕ',
})

export const getFactoringFactoringContractContract = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_FACTORING_CONTRACT_CONTRACT,
    success: actionTypes.GET_FACTORING_FACTORING_CONTRACT_CONTRACT_SUCCESS,
    error: actionTypes.GET_FACTORING_FACTORING_CONTRACT_CONTRACT_ERROR,
  },
  request: () => Factoring.getFactoringFactoringContractContract(id),
  errorMessage: 'Не удалось получить данные по вкладке КОНТРАГЕНТЫ',
})

export const getFactoringFactoringContractAccount = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_FACTORING_CONTRACT_ACCOUNT,
    success: actionTypes.GET_FACTORING_FACTORING_CONTRACT_ACCOUNT_SUCCESS,
    error: actionTypes.GET_FACTORING_FACTORING_CONTRACT_ACCOUNT_ERROR,
  },
  request: () => Factoring.getFactoringFactoringContractAccount(id),
  errorMessage: 'Не удалось получить данные по вкладке РЕКВИЗИТЫ',
})

export const getFactoringFactoringContractSurety = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_FACTORING_CONTRACT_SURETY,
    success: actionTypes.GET_FACTORING_FACTORING_CONTRACT_SURETY_SUCCESS,
    error: actionTypes.GET_FACTORING_FACTORING_CONTRACT_SURETY_ERROR,
  },
  request: () => Factoring.getFactoringFactoringContractSurety(id),
  errorMessage: 'Не удалось получить данные по вкладке ДОГОВОР ПОРУЧИТЕЛЬСТВА',
})

export const getFactoringFactoringContractTariff = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_FACTORING_CONTRACT_TARIFF,
    success: actionTypes.GET_FACTORING_FACTORING_CONTRACT_TARIFF_SUCCESS,
    error: actionTypes.GET_FACTORING_FACTORING_CONTRACT_TARIFF_ERROR,
  },
  request: () => Factoring.getFactoringFactoringContractTariff(id),
  errorMessage: 'Не удалось получить данные по вкладке ТАРИФЫ',
})

export const getFactoringFactoringContractFeatures = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_FACTORING_CONTRACT_FEATURES,
    success: actionTypes.GET_FACTORING_FACTORING_CONTRACT_FEATURES_SUCCESS,
    error: actionTypes.GET_FACTORING_FACTORING_CONTRACT_FEATURES_ERROR,
  },
  request: () => Factoring.getFactoringFactoringContractFeatures(id),
  errorMessage: 'Не удалось получить данные по вкладке ОСОБЕННОСТИ',
})

export const getFactoringSupplyContractCommon = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_SUPPLY_CONTRACT_COMMON,
    success: actionTypes.GET_FACTORING_SUPPLY_CONTRACT_COMMON_SUCCESS,
    error: actionTypes.GET_FACTORING_SUPPLY_CONTRACT_COMMON_ERROR,
  },
  request: () => Factoring.getFactoringSupplyContractCommon(id),
  errorMessage: 'Не удалось получить данные по вкладке ОБЩЕЕ',
})

export const getFactoringAccountEntities = (number) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_ACCOUNT_ENTITIES,
    success: actionTypes.GET_FACTORING_ACCOUNT_ENTITIES_SUCCESS,
    error: actionTypes.GET_FACTORING_ACCOUNT_ENTITIES_ERROR,
  },
  request: () => Factoring.getFactoringAccountEntities(number),
  errorMessage: 'Не удалось получить данные по вкладке СУЩНОСТИ СЧЁТА',
})

export const getFactoringConstraintOrders = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_CONSTRAINT_ORDERS,
    success: actionTypes.GET_FACTORING_CONSTRAINT_ORDERS_SUCCESS,
    error: actionTypes.GET_FACTORING_CONSTRAINT_ORDERS_ERROR,
  },
  request: () => Factoring.getFactoringConstraintOrders(id),
  errorMessage: 'Не удалось получить данные по вкладке ОРДЕРА',
})

export const getFactoringSupplyCommonTab = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_SUPPLY_COMMON_TAB,
    success: actionTypes.GET_FACTORING_SUPPLY_COMMON_TAB_SUCCESS,
    error: actionTypes.GET_FACTORING_SUPPLY_COMMON_TAB_ERROR,
  },
  request: () => Factoring.getFactoringSupplyCommonTab(id),
  errorMessage: 'Не удалось получить данные по вкладке ОБЩЕЕ',
})

export const getFactoringSupplyDocumentTab = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_SUPPLY_DOCUMENT_TAB,
    success: actionTypes.GET_FACTORING_SUPPLY_DOCUMENT_TAB_SUCCESS,
    error: actionTypes.GET_FACTORING_SUPPLY_DOCUMENT_TAB_ERROR,
  },
  request: () => Factoring.getFactoringSupplyDocumentTab(id),
  errorMessage: 'Не удалось получить данные по вкладке ДОКУМЕНТЫ',
})

export const getFactoringSupplyFinancingTab = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_SUPPLY_FINANCING_TAB,
    success: actionTypes.GET_FACTORING_SUPPLY_FINANCING_TAB_SUCCESS,
    error: actionTypes.GET_FACTORING_SUPPLY_FINANCING_TAB_ERROR,
  },
  request: () => Factoring.getFactoringSupplyFinancingTab(id),
  errorMessage: 'Не удалось получить данные по вкладке ФИНАНСИРОВАНИЯ',
})

export const getFactoringMonIssueNotificationTab = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_MON_ISSUE_NOTIFICATION_TAB,
    success: actionTypes.GET_FACTORING_MON_ISSUE_NOTIFICATION_TAB_SUCCESS,
    error: actionTypes.GET_FACTORING_MON_ISSUE_NOTIFICATION_TAB_ERROR,
  },
  request: () => Factoring.getFactoringMonIssueNotificationTab(id),
  errorMessage: `Не удалось получить данные по вкладке СОБЫТИЯ`,
})

export const getFactoringSupplyRegistrySupplyTab = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB,
    success: actionTypes.GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_SUCCESS,
    error: actionTypes.GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_ERROR,
  },
  request: () => Factoring.getFactoringSupplyRegistrySupplyTab(id),
  errorMessage: `Не удалось получить данные по вкладке ПОСТАВКИ`,
})

export const getFactoringAccountEntityTypes = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_ACCOUNT_ENTITY_TYPES,
    success: actionTypes.GET_FACTORING_ACCOUNT_ENTITY_TYPES_SUCCESS,
    error: actionTypes.GET_FACTORING_ACCOUNT_ENTITY_TYPES_ERROR,
  },
  request: Factoring.getFactoringAccountEntityTypes,
  errorMessage: 'Не удалось получить справочник типов сущностей счетов',
})

export const getFactoringRefDocuments = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_REF_DOCUMENTS,
    success: actionTypes.GET_FACTORING_REF_DOCUMENTS_SUCCESS,
    error: actionTypes.GET_FACTORING_REF_DOCUMENTS_ERROR,
  },
  request: Factoring.getFactoringRefDocuments,
  errorMessage: 'Не удалось получить документы',
})

export const getFactoringDeferSettings = (payload) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_DEFER_SETTINGS,
    success: actionTypes.GET_FACTORING_DEFER_SETTINGS_SUCCESS,
    error: actionTypes.GET_FACTORING_DEFER_SETTINGS_ERROR,
  },
  request: () => Factoring.getFactoringDeferSettings(payload),
  errorMessage: 'Не удалось получить настройки определения дней отсрочки',
})

export const getFactoringRefIssues = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_REF_ISSUES,
    success: actionTypes.GET_FACTORING_REF_ISSUES_SUCCESS,
    error: actionTypes.GET_FACTORING_REF_ISSUES_ERROR,
  },
  request: Factoring.getFactoringRefIssues,
  errorMessage: 'Не удалось получить справочник проблем',
})

export const getFactoringRefLimitTypes = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_REF_LIMIT_TYPES,
    success: actionTypes.GET_FACTORING_REF_LIMIT_TYPES_SUCCESS,
    error: actionTypes.GET_FACTORING_REF_LIMIT_TYPES_ERROR,
  },
  request: Factoring.getFactoringRefLimitTypes,
  errorMessage: 'Не удалось получить справочник типов сублимитов',
})

export const getFactoringMonIssueNotifications = (entityType, filters) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_MON_ISSUE_NOTIFICATIONS,
    success: actionTypes.GET_FACTORING_MON_ISSUE_NOTIFICATIONS_SUCCESS,
    error: actionTypes.GET_FACTORING_MON_ISSUE_NOTIFICATIONS_ERROR,
  },
  request: () => Factoring.getFactoringMonIssueNotifications(filters),
  additionalData: {
    filters,
  },
  errorMessage: 'Не удалось получить список уведомлений мониторинга',
})

export const getFactoringRefOperationCodeTypes = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_REF_OPERATION_CODE_TYPES,
    success: actionTypes.GET_FACTORING_REF_OPERATION_CODE_TYPES_SUCCESS,
    error: actionTypes.GET_FACTORING_REF_OPERATION_CODE_TYPES_ERROR,
  },
  request: Factoring.getFactoringRefOperationCodeTypes,
  errorMessage: 'Не удалось получить справочник кодов проводок',
})

export const getFactoringRefRateTypes = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_REF_RATE_TYPES,
    success: actionTypes.GET_FACTORING_REF_RATE_TYPES_SUCCESS,
    error: actionTypes.GET_FACTORING_REF_RATE_TYPES_ERROR,
  },
  request: Factoring.getFactoringRefRateTypes,
  errorMessage: 'Не удалось получить справочник типа поставок',
})

export const getFactoringRefRateConfig = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_REF_RATE_CONFIG,
    success: actionTypes.GET_FACTORING_REF_RATE_CONFIG_SUCCESS,
    error: actionTypes.GET_FACTORING_REF_RATE_CONFIG_ERROR,
  },
  request: Factoring.getFactoringRefRateConfig,
  errorMessage: 'Не удалось получить зависимость между типами ставки и типами продукта',
})

export const getFactoringRefBlockTypes = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_REF_BLOCK_TYPES,
    success: actionTypes.GET_FACTORING_REF_BLOCK_TYPES_SUCCESS,
    error: actionTypes.GET_FACTORING_REF_BLOCK_TYPES_ERROR,
  },
  request: Factoring.getFactoringRefBlockTypes,
  errorMessage: 'Не удалось получить справочник типов операций для блокировки',
})

export const getFactoringRefOperationTypes = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_REF_OPERATION_TYPES,
    success: actionTypes.GET_FACTORING_REF_OPERATION_TYPES_SUCCESS,
    error: actionTypes.GET_FACTORING_REF_OPERATION_TYPES_ERROR,
  },
  request: Factoring.getFactoringRefOperationTypes,
  errorMessage: 'Не удалось получить справочник статусов продовок',
})

export const getFactoringRefAccountTypes = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_REF_ACCOUNT_TYPES,
    success: actionTypes.GET_FACTORING_REF_ACCOUNT_TYPES_SUCCESS,
    error: actionTypes.GET_FACTORING_REF_ACCOUNT_TYPES_ERROR,
  },
  request: Factoring.getFactoringRefAccountTypes,
  errorMessage: 'Не удалось получить справочник типов счетов',
})

export const getFactoringRefOfferingKeys = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_REF_OFFERING_KEYS,
    success: actionTypes.GET_FACTORING_REF_OFFERING_KEYS_SUCCESS,
    error: actionTypes.GET_FACTORING_REF_OFFERING_KEYS_ERROR,
  },
  request: Factoring.getFactoringRefOfferingKeys,
  errorMessage: 'Не удалось получить справочник ключей офферинга',
})

export const getFactoringRefMatchingReasons = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_REF_MATCHING_REASONS,
    success: actionTypes.GET_FACTORING_REF_MATCHING_REASONS_SUCCESS,
    error: actionTypes.GET_FACTORING_REF_MATCHING_REASONS_ERROR,
  },
  request: Factoring.getFactoringRefMatchingReasons,
  errorMessage: 'Не удалось получить справочник невыясненных платежей',
})

export const getFactoringRefMasterOperationTypes = () => action({
  types: {
    fetching: actionTypes.GET_FACTORING_REF_MASTER_OPERATION_TYPES,
    success: actionTypes.GET_FACTORING_REF_MASTER_OPERATION_TYPES_SUCCESS,
    error: actionTypes.GET_FACTORING_REF_MASTER_OPERATION_TYPES_ERROR,
  },
  request: Factoring.getFactoringRefMasterOperationTypes,
  errorMessage: 'Не удалось получить справочник типов мастер операций',
})

export const getFactoringHolidaysByPeriod = (startDate, endDate) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_HOLIDAYS_BY_PERIOD,
    success: actionTypes.GET_FACTORING_HOLIDAYS_BY_PERIOD_SUCCESS,
    error: actionTypes.GET_FACTORING_HOLIDAYS_BY_PERIOD_ERROR,
  },
  request: () => Factoring.getFactoringHolidaysByPeriod(startDate, endDate),
  errorMessage: 'Не удалось получить список выходных/коротких дней',
})

export const setFactoringHolidayDate = (date, onSuccess, onError) => action({
  types: {
    fetching: actionTypes.SET_FACTORING_HOLIDAY_DAY,
    success: actionTypes.SET_FACTORING_HOLIDAY_DAY_SUCCESS,
    error: actionTypes.SET_FACTORING_HOLIDAY_DAY_ERROR,
  },
  request: () => Factoring.setFactoringHolidayDate(date),
  errorMessage: 'Не удалось установить пользовательский выходной',
  onSuccess,
  onError,
})

export const deleteFactoringHolidayDate = (date, onSuccess, onError) => action({
  types: {
    fetching: actionTypes.DELETE_FACTORING_HOLIDAY_DAY,
    success: actionTypes.DELETE_FACTORING_HOLIDAY_DAY_SUCCESS,
    error: actionTypes.DELETE_FACTORING_HOLIDAY_DAY_ERROR,
  },
  request: () => Factoring.deleteFactoringHolidayDate(date),
  errorMessage: 'Не удалось убрать пользовательский выходной',
  onSuccess,
  onError,
})

export const enableFactoringHolidayDate = (date, onSuccess, onError) => action({
  types: {
    fetching: actionTypes.ENABLE_FACTORING_HOLIDAY_DAY,
    success: actionTypes.ENABLE_FACTORING_HOLIDAY_DAY_SUCCESS,
    error: actionTypes.ENABLE_FACTORING_HOLIDAY_DAY_ERROR,
  },
  request: () => Factoring.enableFactoringHolidayDate(date),
  errorMessage: `Не удалось разрешить правила выходного дня на этот день (${formattedDate(date)})`,
  onSuccess,
  onError,
})

export const disableFactoringHolidayDate = (date, onSuccess, onError) => action({
  types: {
    fetching: actionTypes.DISABLE_FACTORING_HOLIDAY_DAY,
    success: actionTypes.DISABLE_FACTORING_HOLIDAY_DAY_SUCCESS,
    error: actionTypes.DISABLE_FACTORING_HOLIDAY_DAY_ERROR,
  },
  request: () => Factoring.disableFactoringHolidayDate(date),
  errorMessage: `Не удалось отменить любые правила выходного дня на этот день (${formattedDate(date)})`,
  onSuccess,
  onError,
})

export const getFactoringOperDays = (showClosed) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_OPER_DAYS,
    success: actionTypes.GET_FACTORING_OPER_DAYS_SUCCESS,
    error: actionTypes.GET_FACTORING_OPER_DAYS_ERROR,
  },
  request: () => Factoring.getFactoringOperDays(showClosed),
  errorMessage: 'Не удалось получить опердни',
})

export const getFactoringAccountNumber = (receiverAccountNumber) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_ACCOUNT_NUMBER,
    success: actionTypes.GET_FACTORING_ACCOUNT_NUMBER_SUCCESS,
    error: actionTypes.GET_FACTORING_ACCOUNT_NUMBER_ERROR,
  },
  request: () => Factoring.getFactoringAccountNumber(receiverAccountNumber),
  errorMessage: 'Не удалось получить счёт получателя',
})

export const getFactoringSupplyContract = (id) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_SUPPLY_CONTRACT,
    success: actionTypes.GET_FACTORING_SUPPLY_CONTRACT_SUCCESS,
    error: actionTypes.GET_FACTORING_SUPPLY_CONTRACT_ERROR,
  },
  request: () => Factoring.getFactoringSupplyContract(id),
  errorMessage: 'Не удалось получить договор факторинга',
})

export const getFactoringCompany = (supplierInn) => action({
  types: {
    fetching: actionTypes.GET_FACTORING_ACCOUNT_COMPANY,
    success: actionTypes.GET_FACTORING_ACCOUNT_COMPANY_SUCCESS,
    error: actionTypes.GET_FACTORING_ACCOUNT_COMPANY_ERROR,
  },
  request: () => Factoring.getFactoringCompany(supplierInn),
  errorMessage: 'Не удалось получить компанию',
})

export const resetFactoringReceiver = () => async (dispatch) =>
  dispatch({ type: actionTypes.RESET_FACTORING_RECEIVER })