import React, { useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Modal, Icon, Checkbox, Tabs, Fieldset, Row, Col2, Col4, Col6, Col8, Col12, InputText } from './../../../layout'
import { AddMessageForm } from '../Modals'
import { Button, H3, H4 } from '../Layout'
import { formattedNumberRub, formattedDate, YYYYMMDD } from '../../../utils'
import styles from '../styles'

const useStyles = createUseStyles({
  ...styles,
  card: {
    margin: [0, 20],
    border: [1, 'solid', '#B6BCC3'],
  },
  header: {
    background: '#eee',
    minHeight: 36,
    padding: [10, 15],
    color: '#888',
    fontSize: 12,
    lineHeight: 16/12,
    fontWeight: 600,
    textTransform: 'uppercase',
    borderBottom: [1, 'solid', '#B6BCC3'],
    position: 'relative',
  },
  body: {
    maxHeight: 'calc(100% - 37px)',
    overflow: 'auto',
    position: 'relative',
  },
  close: {
    width: 16,
    height: 16,
    cursor: 'pointer',
    position: 'absolute',
    top: 10,
    right: 15,
    transition: 'opacity 300ms ease',
    '&:hover': {
      opacity: 0.7,
    },
    '& > svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      fill: '#484848',
    },
  },
  content: {
    minHeight: 338,
    '& + &': {
      marginTop: 40,
    },
  },
  duplicateSettings: {
    marginTop: 20,
  },
  message: {
    background: '#eee',
    marginTop: 15,
    padding: 15,
    border: [1, 'solid', '#B6BCC3'],
    fontSize: 16,
    lineHeight: 20/16,
  },
  author: {
    marginTop: 15,
    color: '#000',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'right',
  },
  empty: {
    marginTop: 15,
    fontSize: 14,
  },
  tableWrapper: {
    width: '100%',
    overflow: 'auto',
    margin: [10, 0, 30, 0],
    '& table': {
      margin: 0,
      border: [2, 'solid', '#0b1f35'],
    },
    '& th': {
      background: '#eee',
      padding: [6, 12],
      color: '#888',
      fontSize: 12,
      lineHeight: 18/12,
      fontWeight: 600,
      border: [1, 'solid', '#0b1f35'],
      textAlign: 'center',
      '&:first-child': {
        background: '#ccc',
        width: 200,
      },
    },
    '& tbody': {
      '& td': {
        padding: [6, 12],
        border: [1, 'solid', '#0b1f35'],
        textAlign: 'center',
        '&:first-of-type:not(:only-of-type)': {
          // background: '#eee',
          // width: 200,
          // color: '#888',
          // fontSize: 12,
          // lineHeight: 18/12,
          // fontWeight: 600,
          whiteSpace: 'nowrap',
        },
        '&:first-child': {
          padding: [6, 12],
        },
        '&:only-child': {
          background: '#fff',
        },
      },
    },
  },
  commission: {
    marginTop: 20,
  },
  commissionItem: {
    '& + &': {
      marginTop: 4,
    },
  },
  pre: {
    marginTop: 10,
  },
  footer: {
    height: 80,
  },
  wrapper: {
    marginTop: 20,
  },
  th: {
    textAlign: 'left !important',
    whiteSpace: 'nowrap',
  },
  td: {
    background: '#fff !important',
    textAlign: 'left !important',
    whiteSpace: 'nowrap',
  },
  h4: {
    paddingBottom: 10,
  },
})

const TITLES = [
  'За финансирование:',
  'За документы:',
  'За ФУДЗ:',
]

export const Card = ({
  className,
  entityType,
  entityName,
  displayName,
  onClose,
  onGetFactoringMessages,
}) => {
  const classes = useStyles()
  const { id } = useParams()

  const [openModal, setOpenModal] = useState('')

  const {
    messages,
    priceMatrix,
    duplicateSettings,
    supplyCommissions,
    supplyContractCommon,
    counterPartyCommon,
    counterPartyAddress,
    counterPartyState,
    counterPartyInfo,
    counterPartySigner,
    counterPartyContact,
    factoringContractCommon,
    factoringContractMain,
    factoringContractContract,
    factoringContractAccount,
    factoringContractSurety,
    factoringContractTariff,
    accountEntities,
    constraintOrders,
    supplyCommonTab,
    supplyDocumentTab,
    supplyFinancingTab,
    monIssueNotificationTab,
    supplyRegistrySupplyTab,
  } = useSelector(state => state?.Factoring || {})

  const { contracts } = useSelector(state => state?.Contracts || {})

  const currentContract = contracts?.find(item => item?.id === id) || {}

  const onOpenModal = () => setOpenModal(true)
  const onCloseModal = () => setOpenModal(false)

  const isFactoringContract = entityType === 'factoring_contract' 
  const isSupplyContract = entityType === 'supply_contract'
  const isLimit = entityType === 'limit'
  const isProduct = entityType === 'product'
  const isSupply = entityType === 'supply'
  const isAccount = entityType === 'account'
  const isCounterParty = entityType === 'counterparty'
  const isIssueNotifications = entityType === 'notifications'
  const isSupplyRegistry = entityType === 'supply_registry'

  const isContract = entityType === 'contract'

  const TABS = [
    {
      name: `События`,
      show: isIssueNotifications,
      content: <div className={classes.content}>
        <H3>События</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>Код события</th>
                  <th className={classes.th}>Тип проблемы</th>
                  <th className={classes.th}>Тип субъекта</th>
                  <th className={classes.th}>Субъект</th>
                  <th className={classes.th}>Тип объекта</th>
                  <th className={classes.th}>Объект</th>
                  <th className={classes.th}>Дата создания</th>
                </tr>
              </thead>
              <tbody>
                {monIssueNotificationTab.map((item, index) => 
                  <tr key={index}>
                    <td className={classes.td}>{item?.code}</td>
                    <td className={classes.td}>{item?.monIssue?.issueType}</td>
                    <td className={classes.td}>{item?.monIssue?.subjectTypeName}</td>
                    <td className={classes.td}>{item?.monIssue?.subjectName}</td>
                    <td className={classes.td}>{item?.monIssue?.objectTypeName}</td>
                    <td className={classes.td}>{item?.monIssue?.objectName}</td>
                    <td className={classes.td}>{formattedDate(item?.monIssue?.createdDateTime)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Общее',
      show: isCounterParty,
      content: <div className={classes.content}>
        <H3>Общее</H3>
        <div className={classes.wrapper}>
          <Fieldset>
            <Row>
              <Col12>
                <InputText
                  type='text'
                  label='Наименование'
                  value={counterPartyCommon?.fullName || ''}
                  disabled
                />
              </Col12>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='ИНН'
                  value={counterPartyCommon?.inn || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='КПП'
                  value={counterPartyCommon?.kpp || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='ОГРН'
                  value={counterPartyCommon?.ogrn || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='ПИН EQ'
                  value={counterPartyCommon?.pinEq || ''}
                  disabled
                />
              </Col4>
              <Col8>
                <InputText
                  type='text'
                  label='Организационная форма по ЦБ'
                  value={counterPartyCommon?.opf || ''}
                  disabled
                />
              </Col8>
            </Row>
          </Fieldset>
        </div>
      </div>,
    },
    {
      name: 'Адреса',
      show: isCounterParty,
      content: <div className={classes.content}>
        <H3>Адреса</H3>
        <div className={classes.wrapper}>
          <Fieldset>
            <Row>
              <Col12>
                <InputText
                  type='text'
                  label='Юридический адрес'
                  value={counterPartyAddress?.legalAddress || ''}
                  disabled
                />
              </Col12>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col12>
                <InputText
                  type='text'
                  label='Фактический адрес'
                  value={counterPartyAddress?.actualAddress || ''}
                  disabled
                />
              </Col12>
            </Row>
          </Fieldset>
        </div>
      </div>,
    },
    {
      name: 'Состояние',
      show: isCounterParty,
      content: <div className={classes.content}>
        <H3>Состояние</H3>
        <div className={classes.wrapper}>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Дата мониторинга'
                  value={counterPartyState?.monitoringDate || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Дата финположения'
                  value={counterPartyState?.financialPostitionDate || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Дата актуализации'
                  value={counterPartyState?.complianceCheckDate || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Срок ДЭБ'
                  value={counterPartyState?.sbCheck || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Рейтинг'
                  value={counterPartyState?.rating || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Рейтинг ЦБ'
                  value={counterPartyState?.ratingCb || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Финположение'
                  value={counterPartyState?.financialPostition || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Категория проблемности'
                  value={counterPartyState?.problemCategory || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Статус'
                  value={counterPartyState?.status || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
        </div>
      </div>,
    },
    {
      name: 'Инфо',
      show: isCounterParty,
      content: <div className={classes.content}>
        <H3>Инфо</H3>
        <div className={classes.wrapper}>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Менеджер развитие'
                  value={counterPartyInfo?.manager || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Развитие ЦО'
                  value={counterPartyInfo?.co || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='ПЦ'
                  value={counterPartyInfo?.profitCenter || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Филиал'
                  value={counterPartyInfo?.branchOffice || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Город'
                  value={counterPartyInfo?.city || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Кл.подразд'
                  value={counterPartyInfo?.coreUnit || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Куратор отр.'
                  value={counterPartyInfo?.industryCurator || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Сегмент'
                  value={counterPartyInfo?.segment || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
        </div>
      </div>,
    },
    {
      name: 'Подписанты',
      show: isCounterParty,
      content: <div className={classes.content}>
        <H3>Подписанты</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>Подписант</th>
                  <th className={classes.th}>На основании чего действует</th>
                  <th className={classes.th}>Должность</th>
                  <th className={classes.th}>Действует С</th>
                  <th className={classes.th}>Действует ПО</th>
                </tr>
              </thead>
              <tbody>
                {counterPartySigner.map((item, index) => 
                  <tr key={index}>
                    <td className={classes.td}>{item?.displayName}</td>
                    <td className={classes.td}>{item?.authorizingDoc}</td>
                    <td className={classes.td}>{item?.position}</td>
                    <td className={classes.td}>{formattedDate(item?.authorizationStartDate)}</td>
                    <td className={classes.td}>{formattedDate(item?.authorizationExpirationDate)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Закрепление поставщиков',
      show: isCounterParty,
      content: <div className={classes.content}>
        <H3>Закрепление поставщиков</H3>
        <div className={classes.wrapper}>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Поставщик'
                  value={''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Контракт'
                  value={''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Контактное лицо'
                  value={counterPartyContact?.contactFio}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Email'
                  value={counterPartyContact?.contactPhone}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Телефон'
                  value={counterPartyContact?.contactEmail}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
        </div>
      </div>,
    },
    {
      name: 'Общее',
      show: isFactoringContract,
      content: <div className={classes.content}>
        <H3>Общее</H3>
        <div className={classes.wrapper}>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='№ Договора'
                  value={factoringContractCommon?.number}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='date'
                  label='Действует С'
                  value={factoringContractCommon?.startDate}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='date'
                  label='Действует ПО'
                  value={factoringContractCommon?.endDate}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Поставщик'
                  value={factoringContractCommon?.supplierInn}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Продукт'
                  value={factoringContractCommon?.productType}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Статус'
                  value={factoringContractCommon?.status}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='ПЦ'
                  value={factoringContractCommon?.profitCenter}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='ПИН EQ'
                  value={factoringContractCommon?.pinEq}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='LM ID'
                  value={factoringContractCommon?.lmId}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
        </div>
      </div>,
    },
    {
      name: 'Основное',
      show: isFactoringContract,
      content: <div className={classes.content}>
        <H3>Основное</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>Дебитор</th>
                  <th className={classes.th}>Макс дни фин (Дней календ)</th>
                  <th className={classes.th}>Макс дни фин (Дней банк)</th>
                  <th className={classes.th}>Срок действия ЮД</th>
                  <th className={classes.th}>Беспоставочный Транзит</th>
                  <th className={classes.th}>Беспоставочный Транзит (Примечание)</th>
                  <th className={classes.th}>Уменьшение периода ожидания до макс срока</th>
                </tr>
              </thead>
              <tbody>
                {factoringContractMain.map((item, index) => 
                  <tr key={index}>
                    <td className={classes.td}>{item?.debtor}</td>
                    <td className={classes.td}>{item?.maxFinPeriod}</td>
                    <td className={classes.td}>{item?.maxFinPeriodBank}</td>
                    <td className={classes.td}>{formattedDate(item?.udSigningDate)}</td>
                    <td className={classes.td}>{item?.transit}</td>
                    <td className={classes.td}>{item?.transitNote}</td>
                    <td className={classes.td}>{item?.isSubstractWaitingPeriod}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Контракты',
      show: isFactoringContract,
      content: <div className={classes.content}>
        <H3>Контракты</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>Номер договора</th>
                  <th className={classes.th}>Поставщик</th>
                  <th className={classes.th}>Дебитор</th>
                  <th className={classes.th}>Начало договора</th>
                  <th className={classes.th}>Окончание договора</th>
                  <th className={classes.th}>Статус</th>
                </tr>
              </thead>
              <tbody>
                {factoringContractContract.map((item, index) => 
                  <tr key={index}>
                    <td className={classes.td}>{item?.number}</td>
                    <td className={classes.td}>{item?.supplierName}</td>
                    <td className={classes.td}>
                      {(item?.debtors || []).map(({ displayName }, idx) =>
                        <div key={idx}>{displayName}</div>
                      )}
                    </td>
                    <td className={classes.td}>{formattedDate(item?.beginDate)}</td>
                    <td className={classes.td}>{formattedDate(item?.endDate)}</td>
                    <td className={classes.td}>{item?.status}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Контрагенты',
      show: isFactoringContract,
      content: <div className={classes.content}>
        <H3>Контрагенты</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>Дебитор</th>
                  <th className={classes.th}>ИНН</th>
                  <th className={classes.th}>ОГРН</th>
                  <th className={classes.th}>Реестр</th>
                  <th className={classes.th}>Дата уведомления</th>
                  <th className={classes.th}>Дата обр уведомления</th>
                  <th className={classes.th}>Процент финансирования</th>
                  <th className={classes.th}>Дни финансирования (кал)</th>
                  <th className={classes.th}>Дни финансирования (банк)</th>
                  <th className={classes.th}>Период ожидания (кал)</th>
                  <th className={classes.th}>Период ожидания (банк)</th>
                  <th className={classes.th}>Период фондирования</th>
                  <th className={classes.th}>Необходимы оригиналы</th>
                  <th className={classes.th}>Дней на предоставление оригиналов (кал)</th>
                  <th className={classes.th}>Дней на предоставление оригиналов (банк)</th>
                </tr>
              </thead>
              <tbody>
                {factoringContractContract.map((item, index) => (item?.debtors || []).map(d =>
                  <tr key={index}>
                    <td className={classes.td}>{d?.displayName}</td>
                    <td className={classes.td}>{d?.inn}</td>
                    <td className={classes.td}>{d?.ogrn}</td>
                    <td className={classes.td}>{d?.sendsCounterRegistry}</td>
                    <td className={classes.td}>{formattedDate(d?.signNoticeDate)}</td>
                    <td className={classes.td}>{formattedDate(d?.signRevNoticeDate)}</td>
                    <td className={classes.td}>{d?.financingPercentage}</td>
                    <td className={classes.td}>{d?.maxFinPeriod}</td>
                    <td className={classes.td}>{d?.maxFinPeriodBank}</td>
                    <td className={classes.td}>{d?.waitingPeriod}</td>
                    <td className={classes.td}>{d?.waitingPeriodBank}</td>
                    <td className={classes.td}>{d?.fundingPeriod}</td>
                    <td className={classes.td}>{d?.needOriginals}</td>
                    <td className={classes.td}>{d?.originalsDeadLine}</td>
                    <td className={classes.td}>{d?.originalsDeadLineBank}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Реквизиты',
      show: isFactoringContract,
      content: <div className={classes.content}>
        <H3>Реквизиты</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>Счет</th>
                  <th className={classes.th}>Владелец счета</th>
                  <th className={classes.th}>Тип</th>
                  <th className={classes.th}>Группа</th>
                  <th className={classes.th}>Валюта</th>
                  <th className={classes.th}>Дебитор</th>
                  <th className={classes.th}>БИК</th>
                  <th className={classes.th}>Корр.счет</th>
                  <th className={classes.th}>Банк</th>
                  <th className={classes.th}>Статус</th>
                  <th className={classes.th}>Действует С</th>
                  <th className={classes.th}>Действует ПО</th>
                </tr>
              </thead>
              <tbody>
                {factoringContractAccount.map((item, index) =>
                  <tr key={index}>
                    <td className={classes.td}>{item?.number}</td>
                    <td className={classes.td}>{item?.accountOwner}</td>
                    <td className={classes.td}>{item?.accountType}</td>
                    <td className={classes.td}>{item?.group}</td>
                    <td className={classes.td}>{item?.currency}</td>
                    <td className={classes.td}>{item?.displayName}</td>
                    <td className={classes.td}>{item?.bic}</td>
                    <td className={classes.td}>{item?.corrAccount}</td>
                    <td className={classes.td}>{item?.bankName}</td>
                    <td className={classes.td}>{item?.status}</td>
                    <td className={classes.td}>{formattedDate(item?.beginDate)}</td>
                    <td className={classes.td}>{formattedDate(item?.endDate)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Договор поручительства',
      show: isFactoringContract,
      content: <div className={classes.content}>
        <H3>Договор поручительства</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>Поручитель</th>
                  <th className={classes.th}>Договор поручительства</th>
                  <th className={classes.th}>Действует С</th>
                  <th className={classes.th}>Действует ПО</th>
                  <th className={classes.th}>Сумма</th>
                  <th className={classes.th}>Валюта</th>
                  <th className={classes.th}>Счет внебаланс</th>
                  <th className={classes.th}>р. Счет</th>
                  <th className={classes.th}>БИК</th>
                  <th className={classes.th}>Корр.счет</th>
                  <th className={classes.th}>Банк</th>
                  <th className={classes.th}>Примечание</th>
                </tr>
              </thead>
              <tbody>
                {factoringContractSurety.map((item, index) =>
                  <tr key={index}>
                    <td className={classes.td}>{item?.displayName}</td>
                    <td className={classes.td}>{item?.number}</td>
                    <td className={classes.td}>{formattedDate(item?.beginDate)}</td>
                    <td className={classes.td}>{formattedDate(item?.endDate)}</td>
                    <td className={classes.td}>{item?.amount}</td>
                    <td className={classes.td}>{item?.currency}</td>
                    <td className={classes.td}>{item?.accountNumberUnbalance}</td>
                    <td className={classes.td}>{item?.accountNumber}</td>
                    <td className={classes.td}>{item?.bic}</td>
                    <td className={classes.td}>{item?.corrAccount}</td>
                    <td className={classes.td}>{item?.bankName}</td>
                    <td className={classes.td}>{item?.note}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Тарифы',
      show: isFactoringContract,
      content: <div className={classes.content}>
        <H3>Тарифы</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>Ставка (Пени)</th>
                  <th className={classes.th}>Тип ставки (Пени)</th>
                  <th className={classes.th}>Очередность удержания (Пени)</th>
                  <th className={classes.th}>Дебитор (Отсрочка фондирования)</th>
                  <th className={classes.th}>Отстрочка (Отсрочка фондирования)</th>
                  <th className={classes.th}>Надбавка за перевод в др Банк</th>
                </tr>
              </thead>
              <tbody>
                {factoringContractTariff.map((item, index) =>
                  <tr key={index}>
                    <td className={classes.td}>{item?.rate}</td>
                    <td className={classes.td}>{item?.rateType}</td>
                    <td className={classes.td}>{item?.orderDeduction}</td>
                    <td className={classes.td}>{item?.debtor}</td>
                    <td className={classes.td}>{item?.fundingPeriod}</td>
                    <td className={classes.td}>{item?.otherBankAdditionalRate}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Особенности',
      show: isFactoringContract,
      content: <div className={classes.content}>
        <H3>Особенности</H3>
        <div className={classes.wrapper}>
          <Fieldset>
            <Row>
              <Col6>
                <InputText
                  type='text'
                  label='Особенности финансирования'
                  value={factoringContractTariff?.financingFeature || ''}
                  disabled
                />
              </Col6>
              <Col6>
                <InputText
                  type='text'
                  label='Особенности квитовки'
                  value={factoringContractTariff?.matchingFeature || ''}
                  disabled
                />
              </Col6>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col6>
                <InputText
                  type='text'
                  label='Настройки назначения финансирования'
                  value={factoringContractTariff?.financingSettings || ''}
                  disabled
                />
              </Col6>
              <Col6>
                <InputText
                  type='text'
                  label='Настройки назначения остатков'
                  value={factoringContractTariff?.financingLeftovers || ''}
                  disabled
                />
              </Col6>
            </Row>
          </Fieldset>
        </div>
      </div>,
    },
    {
      name: 'Общее',
      show: isSupplyContract,
      content: <div className={classes.content}>
        <H3>Общее</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>Поставщик</th>
                  <th className={classes.th}>Номер договора</th>
                  <th className={classes.th}>Начало договора</th>
                  <th className={classes.th}>Окончание договора</th>
                  <th className={classes.th}>Дебитор</th>
                  <th className={classes.th}>Срок до регресса (дней календарных)</th>
                  <th className={classes.th}>Срок до регресса (дней банковских)</th>
                  <th className={classes.th}>Процент финансирования</th>
                  <th className={classes.th}>Запрос оригиналов (дней календарных)</th>
                  <th className={classes.th}>Запрос оригиналов (дней банковских)</th>
                  <th className={classes.th}>Дата уведомления</th>
                  <th className={classes.th}>Дата обратного уведомления</th>
                </tr>
              </thead>
              <tbody>
                {supplyContractCommon?.debtors?.map((item, index) =>
                  <tr key={index}>
                    <td className={classes.td}>{item?.supplierName}</td>
                    <td className={classes.td}>{item?.number}</td>
                    <td className={classes.td}>{formattedDate(item?.startDate)}</td>
                    <td className={classes.td}>{formattedDate(item?.endDate)}</td>
                    <td className={classes.td}>{item?.displayName}</td>
                    <td className={classes.td}>{item?.waitingPeriod}</td>
                    <td className={classes.td}>{item?.waitingPeriodBank}</td>
                    <td className={classes.td}>{item?.financingPercentage}</td>
                    <td className={classes.td}>{item?.originalsDeadLine}</td>
                    <td className={classes.td}>{item?.originalsDeadLineBank}</td>
                    <td className={classes.td}>{formattedDate(item?.signNoticeDate)}</td>
                    <td className={classes.td}>{formattedDate(item?.signRevNoticeDate)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Тарифный план',
      show: isFactoringContract || isProduct,
      content: <div className={classes.content}>
        <H3>Тарифный план</H3>
        <div className={classes.wrapper}>
          {priceMatrix.map(({ columns, rows, values }, index) =>
            <div className={classes.tableWrapper} key={index}>
              <H4 className={classes.h4}>{TITLES[index]}</H4>
              <table>
                {columns?.length > 0 &&
                  <thead>
                    <tr>
                      <th></th>
                      {columns.map((column, columnIndex) => 
                        <th key={columnIndex}>{column}</th>
                      )}
                    </tr>
                  </thead>
                }
                <tbody>
                  {rows?.length > 0
                    ? rows.map((row, rowIndex) => 
                        <tr key={rowIndex}>
                          <td>{row}</td>
                          {values[rowIndex]?.length > 0 && values[rowIndex].map((value, valueIndex) =>
                            <td key={valueIndex}>{value}</td>
                          )}
                        </tr>
                      )
                    : <tr><td>{values[0]}</td></tr>
                  }
                </tbody>
              </table>
            </div>
          )}
        </div>  
      </div>,
    },
    {
      name: 'Основное',
      show: isSupply,
      content: <div className={classes.content}>
        <H3>Основное</H3>
        <div className={classes.wrapper}>
          <Fieldset>
            <Row>
              <Col2>
                <InputText
                  type='text'
                  label='Id поставки'
                  value={supplyCommonTab?.id || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='date'
                  label='Дата приемки'
                  value={supplyCommonTab?.acceptanceDate || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='Ввод'
                  value={supplyCommonTab?.createMethod || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='Поставщик'
                  value={supplyCommonTab?.supplierName || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='Дебитор'
                  value={supplyCommonTab?.debtorName || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='№ Реестра'
                  value={supplyCommonTab?.supplyRegistryNumber || ''}
                  disabled
                />
              </Col2>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col2>
                <InputText
                  type='date'
                  label='Дата реестра'
                  value={supplyCommonTab?.supplyRegistryDate || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='date'
                  label='Период ожидания'
                  value={supplyCommonTab?.waitingDate || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='Оригиналы'
                  value={supplyCommonTab?.hasOriginals || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='Сумма поставки'
                  value={formattedNumberRub(supplyCommonTab?.sum) || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='Валюта'
                  value={supplyCommonTab?.currency || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='Договор факторинга'
                  value={supplyCommonTab?.factoringContractNumber || ''}
                  disabled
                />
              </Col2>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col2>
                <InputText
                  type='date'
                  label='Отсрочка'
                  value={supplyCommonTab?.deferDate || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='Прос.дн.'
                  value={supplyCommonTab?.overdueDays || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='Примечание'
                  value={supplyCommonTab?.notification || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='Долг по финансированию'
                  value={formattedNumberRub(supplyCommonTab?.asa) || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='Договор поставки'
                  value={supplyCommonTab?.supplyContractNumber || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='date'
                  label='Дата закрытия'
                  value={supplyCommonTab?.closed || ''}
                  disabled
                />
              </Col2>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col2>
                <InputText
                  type='text'
                  label='Увед о просрочке'
                  value={supplyCommonTab?.debtorsPaymentDelayNotification || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='text'
                  label='Не оплачено'
                  value={formattedNumberRub(supplyCommonTab?.asc) || ''}
                  disabled
                />
              </Col2>
              <Col2>
                <InputText
                  type='date'
                  label='Дата верификации'
                  value={supplyCommonTab?.verificationDate || ''}
                  disabled
                />
              </Col2>
              <Col6>
                <InputText
                  type='text'
                  label='Статус'
                  value={supplyCommonTab?.status || ''}
                  disabled
                />
              </Col6>
            </Row>
          </Fieldset>
        </div>
      </div>,
    },
    {
      name: 'Документы',
      show: isSupply,
      content: <div className={classes.content}>
        <H3>Документы</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>ТИП ДОКУМЕНТА</th>
                  <th className={classes.th}>НОМЕР ДОКУМЕНТА</th>
                  <th className={classes.th}>ДАТА ДОКУМЕНТА</th>
                </tr>
              </thead>
              <tbody>
                {supplyDocumentTab.map((item, index) => 
                  <tr key={index}>
                    <td className={classes.td}>{item?.type}</td>
                    <td className={classes.td}>{item?.number}</td>
                    <td className={classes.td}>{formattedDate(item?.date)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Финансирования',
      show: isSupply,
      content: <div className={classes.content}>
        <H3>Финансирования</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>ID ЗАПРОСА НА ФИНАНСИРОВАНИЕ</th>
                  <th className={classes.th}>ДАТА ФИНАНСИРОВАНИЯ</th>
                  <th className={classes.th}>№ ФИНАНСИРОВАНИЯ</th>
                  <th className={classes.th}>СУММА</th>
                  <th className={classes.th}>ВАЛЮТА</th>
                  <th className={classes.th}>ЖДФ</th>
                  <th className={classes.th}>ЖЕЛАЕМАЯ ДАТА</th>
                  <th className={classes.th}>ТРАНЗИТ</th>
                  <th className={classes.th}>СТАТУС</th>
                </tr>
              </thead>
              <tbody>
                {supplyFinancingTab.map((item, index) =>
                  <tr key={index}>
                    <td className={classes.td}>{item?.id}</td>
                    <td className={classes.td}>{formattedDate(item?.financingDate)}</td>
                    <td className={classes.td}>{item?.number}</td>
                    <td className={classes.td}>{formattedNumberRub(item?.sum)}</td>
                    <td className={classes.td}>{item?.currency}</td>
                    <td className={classes.td}>{item?.whenToFund}</td>
                    <td className={classes.td}>{formattedDate(item?.paymentDate)}</td>
                    <td className={classes.td}>{item?.transit}</td>
                    <td className={classes.td}>{item?.status}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Расчет комиссии',
      show: isSupply,
      content: <div className={classes.content}>
        <H3>Расчет комиссии</H3>
        {supplyCommissions.map(({ commissionType, calcAmountSteps }, index) =>
          <div className={classes.commission} key={index}>
            <H4>{commissionType}</H4>
            <div className={classes.pre}>
              {calcAmountSteps.map((item, idx) =>
                <div className={classes.commissionItem} key={idx}>{item}</div>
              )}
            </div>
          </div>
        )}
      </div>,
    },
    {
      name: 'Дедупликация поставок',
      show: isSupplyContract,
      content: <div className={classes.content}>
        <H3>Дедупликация поставок</H3>
        <div className={classes.duplicateSettings}>
          {duplicateSettings.map(({ name, value }, index) => 
            <Checkbox label={name} checked={value} key={index} disabled />
          )}
        </div>
      </div>,
    },
    {
      name: 'Сущность счёта',
      show: isAccount,
      content: <div className={classes.content}>
        <H3>Сущность счёта</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>Тип счёта</th>
                  <th className={classes.th}>Тип владельца</th>
                  <th className={classes.th}>Сущность владельца</th>
                  <th className={classes.th}>Название владельца</th>
                </tr>
              </thead>
              <tbody>
                {accountEntities.map((item, index) =>
                  <tr key={index}>
                    <td className={classes.td}>{item?.accountType}</td>
                    <td className={classes.td}>{item?.entityType}</td>
                    <td className={classes.td}>{item?.ownerType}</td>
                    <td className={classes.td}>{item?.ownerName}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Ордера',
      show: isLimit,
      content: <div className={classes.content}>
        <H3>Ордера</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>ID Сущности</th>
                  <th className={classes.th}>Статус</th>
                  <th className={classes.th}>Сумма</th>
                  <th className={classes.th}>Погашенная сумма</th>
                  <th className={classes.th}>Продукт</th>
                  <th className={classes.th}>Субпродукт</th>
                  <th className={classes.th}>ГД</th>
                  <th className={classes.th}>Оригиналы</th>
                  <th className={classes.th}>Категория</th>
                  <th className={classes.th}>ГК Поставщика</th>
                  <th className={classes.th}>ГК Дебитора</th>
                </tr>
              </thead>
              <tbody>
                {constraintOrders.map((item, index) => 
                  <tr key={index}>
                    <td className={classes.td}>{item?.externalId}</td>
                    <td className={classes.td}>{item?.status}</td>
                    <td className={classes.td}>{formattedNumberRub(item?.amount)}</td>
                    <td className={classes.td}>{formattedNumberRub(item?.returnedSum)}</td>
                    <td className={classes.td}>{item?.product}</td>
                    <td className={classes.td}>{item?.subProduct}</td>
                    <td className={classes.td}>{item?.factoringContractNumber}</td>
                    <td className={classes.td}>{item?.originals}</td>
                    <td className={classes.td}>{item?.qualityCategory}</td>
                    <td className={classes.td}>{item?.supplierHoldings}</td>
                    <td className={classes.td}>{item?.debtorHoldings}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Поставки',
      show: isSupplyRegistry,
      content: <div className={classes.content}>
        <H3>Поставки</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>ID поставки</th>
                  <th className={classes.th}>Тип документа</th>
                  <th className={classes.th}>№ поставки</th>
                  <th className={classes.th}>№ Реестра</th>
                  <th className={classes.th}>Дата реестра</th>
                  <th className={classes.th}>ГД</th>
                  <th className={classes.th}>Поставщик</th>
                  <th className={classes.th}>Дебитор</th>
                  <th className={classes.th}>Дата</th>
                  <th className={classes.th}>Приёмка</th>
                  <th className={classes.th}>Сумма</th>
                  <th className={classes.th}>Валюта</th>
                  <th className={classes.th}>Отсрочка</th>
                  <th className={classes.th}>Просрочено дней</th>
                  <th className={classes.th}>Статус</th>
                  <th className={classes.th}>Ввод</th>
                  <th className={classes.th}>Продукт</th>
                  <th className={classes.th}>Контракт</th>
                  <th className={classes.th}>Оригиналы</th>
                  <th className={classes.th}>Дата ожидания</th>
                  <th className={classes.th}>Регистрация</th>
                  <th className={classes.th}>Закрыто</th>
                  <th className={classes.th}>Верификация</th>
                  <th className={classes.th}>Уведомление о просрочке платежа дебитором</th>
                  <th className={classes.th}>Дата погаш фин</th>
                  <th className={classes.th}>Последний</th>
                  <th className={classes.th}>Изменение</th>
                  <th className={classes.th}>Закрытый факторинг</th>
                  <th className={classes.th}>Подтвержденная сумма</th>
                  <th className={classes.th}>Дата верификации</th>
                  <th className={classes.th}>Долг по финансированию</th>
                  <th className={classes.th}>Не оплачено продавцом</th>
                  <th className={classes.th}>Не оплачено дебитором</th>
                  <th className={classes.th}>Комиссия за финансирование</th>
                  <th className={classes.th}>Комиссия за ФУДЗ</th>
                  <th className={classes.th}>Создал</th>
                  <th className={classes.th}>Создание</th>
                  <th className={classes.th}>Примечание</th>
                  <th className={classes.th}>Комментарий</th>
                  <th className={classes.th}>Транзит</th>
                </tr>
              </thead>
              <tbody>
                {supplyRegistrySupplyTab.map((item, index) => 
                  <tr key={index}>
                    <td className={classes.td}>{item?.id}</td>
                    <td className={classes.td}>{item?.documentType}</td>
                    <td className={classes.td}>{item?.supplyNumber}</td>
                    <td className={classes.td}>{item?.supplyRegistryNumber}</td>
                    <td className={classes.td}>{formattedDate(item?.supplyRegistryDate)}</td>
                    <td className={classes.td}>{item?.factoringContractNumber}</td>
                    <td className={classes.td}>{item?.supplierName}</td>
                    <td className={classes.td}>{item?.debtorName}</td>
                    <td className={classes.td}>{formattedDate(item?.supplyDate)}</td>
                    <td className={classes.td}>{formattedDate(item?.acceptanceDate)}</td>
                    <td className={classes.td}>{formattedNumberRub(item?.sum)}</td>
                    <td className={classes.td}>{item?.currency}</td>
                    <td className={classes.td}>{formattedDate(item?.deferDate)}</td>
                    <td className={classes.td}>{item?.overdueDays}</td>
                    <td className={classes.td}>{item?.status}</td>
                    <td className={classes.td}>{item?.createMethod}</td>
                    <td className={classes.td}>{item?.productType}</td>
                    <td className={classes.td}>{item?.supplyContractNumber}</td>
                    <td className={classes.td}>{item?.hasOriginals}</td>
                    <td className={classes.td}>{formattedDate(item?.waitingDate)}</td>
                    <td className={classes.td}>{formattedDate(item?.registrationDate)}</td>
                    <td className={classes.td}>{item?.closed}</td>
                    <td className={classes.td}>{item?.verification}</td>
                    <td className={classes.td}>{item?.debtorsPaymentDelayNotification}</td>
                    <td className={classes.td}>{formattedDate(item?.finPaymentDate)}</td>
                    <td className={classes.td}>{item?.last}</td>
                    <td className={classes.td}>{item?.changedBy}</td>
                    <td className={classes.td}>{item?.closedFactoring}</td>
                    <td className={classes.td}>{formattedNumberRub(item?.approvedSum)}</td>
                    <td className={classes.td}>{formattedDate(item?.verificationDate)}</td>
                    <td className={classes.td}>{formattedNumberRub(item?.asa)}</td>
                    <td className={classes.td}>{formattedNumberRub(item?.asb)}</td>
                    <td className={classes.td}>{formattedNumberRub(item?.asc)}</td>
                    <td className={classes.td}>{formattedNumberRub(item?.asd)}</td>
                    <td className={classes.td}>{formattedNumberRub(item?.ase)}</td>
                    <td className={classes.td}>{item?.createdBy}</td>
                    <td className={classes.td}>{item?.creation}</td>
                    <td className={classes.td}>{item?.notification}</td>
                    <td className={classes.td}>{item?.comment}</td>
                    <td className={classes.td}>{item?.transit}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    {
      name: 'Информация по договору',
      show: isContract,
      content: <div className={classes.content}>
        <H3>Информация по договору</H3>
        <div className={classes.wrapper}>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Подписант со стороны Банка'
                  value={currentContract?.bankSigner|| ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Клиент (ИНН)'
                  value={currentContract?.clientCompanyInn || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Подписант со стороны клиента'
                  value={currentContract?.clientSigner || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Тип бенефициара'
                  value={currentContract?.contractBeneficiaryType || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='date'
                  label='Дата договора / доп. соглашения'
                  value={currentContract?.contractDate || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='date'
                  label='Дата договора / доп. соглашения'
                  value={currentContract?.contractEndDate || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Лимит выдачи по договору'
                  value={currentContract?.contractLimitAmount || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Номер договора / доп. соглашения'
                  value={currentContract?.contractNumber || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Форма подписания договора (бумажный носитель / УКЭП)'
                  value={currentContract?.contractSignType || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Статус'
                  value={currentContract?.contractStatus || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Вид договора'
                  value={currentContract?.contractType || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='date'
                  label='Дата создания'
                  value={YYYYMMDD(currentContract?.createdDateTime) || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Наличие предельной ставки'
                  value={currentContract?.hasMaxRate ? 'Да' : 'Нет'}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Внутренний идентификатор'
                  value={currentContract?.id || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Необходимость индивидуальной формы'
                  value={currentContract?.isCustomContractForm ? 'Да' : 'Нет'}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='text'
                  label='Доп. соглашение'
                  value={currentContract?.isSupplementaryAgreement ? 'Да' : 'Нет'}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Основной договор (для доп. соглашений)'
                  value={currentContract?.mainContractId || ''}
                  disabled
                />
              </Col4>
              <Col4>
                <InputText
                  type='text'
                  label='Предельная ставка (% или значение)'
                  value={currentContract?.maxRate || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
          <Fieldset>
            <Row>
              <Col4>
                <InputText
                  type='date'
                  label='Дата расторжения'
                  value={currentContract?.terminationDate || ''}
                  disabled
                />
              </Col4>
            </Row>
          </Fieldset>
        </div>
      </div>,
    },
    {
      name: 'Комментарии',
      show: !isContract,
      content: <div className={classes.content}>
        <H3>
          <div>Комментарии</div>
          <Button onClick={onOpenModal}>Добавить комментарий</Button>
        </H3>
        {messages?.length > 0
          ? messages.map(({ authorFullName, dateTime, message }, index) =>
              <div className={classes.message} key={index}>
                {message}
                <div className={classes.author}>
                  {authorFullName}, {formattedDate(dateTime)} в {new Date(dateTime).toLocaleTimeString()}
                </div>
              </div>
            )
          : <div className={classes.empty}>Комментарии отсутствуют</div>
        }
      </div>
    },
  ]

  return (
    <>
      <div className={classnames(classes.card, className)}>
        <div className={classes.header}>
          {entityName && `Владелец ${entityName}:`} {displayName}
          <div className={classes.close} onClick={onClose}>
            <Icon iconName='close2' />
          </div>
        </div>
        <div className={classes.body}>
          <Tabs items={TABS} />
        </div>
        {openModal &&
          <Modal width='600px' height='340px' onClose={onCloseModal}>
            <AddMessageForm
              entityType={entityType}
              onGetFactoringMessages={onGetFactoringMessages}
              onClose={onCloseModal}
            />
          </Modal>
        }
      </div>
      <div className={classes.footer} />
    </>
  )
}