import React, { useEffect, useState, memo, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { InputText, Modal2 } from '../../../../layout'
import { formattedNumber, onlyDigitsWithDot } from '../../../../utils'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'

const useStyles = createUseStyles({
  returnedModal: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
  item: {
    fontSize: 14,
    '& + &': {
      marginTop: 8,
    },
    '& > span': {
      width: 160,
      fontWeight: 600,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
})

export const ReturnedModal = memo(
  ({
    payment,
    onAddDraft,
    onClose,
  }) => {
    const classes = useStyles()

    const [matchedAmount, setMatchedAmount] = useState(payment?.unmatchedAmount)
    const [submitted, setSubmitted] = useState(0)

    const touched = useMemo(() => submitted > 0, [submitted])

    const payload = {
      details: [],
      matchedAmount,
      order: {
        amount: 1000000000000000,
        externalId: 'return',
        number: '-',
        orderDate: new Date().toISOString(),
        orderType: 'return',
        orderTypeRu: 'Возврат',
      },
      paymentId: payment?.id,
    }

    const errors = {
      ...(String(matchedAmount)?.trim() ? {} : { matchedAmount: 'Не указана сумма к возврату' }),
      ...(matchedAmount <= payment?.unmatchedAmount ? {} : { matchedAmount: 'Сумма к возврату не должна превышать сумму остатка' }),
    }

    const isValid = Object.keys(errors)?.length === 0

    const onSubmit = () => setSubmitted(submitted + 1)
    
    useEffect(() => {
      if (touched && isValid) {
        onAddDraft(payload)
        onClose()
      }
    // eslint-disable-next-line
    }, [submitted])

    return (
      <Modal2 className={classes.returnedModal} onClose={onClose}>
        <div className={classes.wrapper}>
          <H3>Возврат платежа</H3>
          <Form>
            <div className={classes.item}>
              <span>№ Платежа:</span> {payment?.number}
            </div>
            <div className={classes.item}>
              <span>Дата платежа:</span> {new Date(payment?.date)?.toLocaleDateString('ru-RU')}
            </div>
            <div className={classes.item}>
              <span>Плательщик:</span> {payment?.payerName}
            </div>
            <div className={classes.item}>
              <span>Сумма:</span> {formattedNumber(payment?.amount)}
            </div>
            <div className={classes.item}>
              <span>Остаток:</span> {formattedNumber(payment?.unmatchedAmount)}
            </div>
            <div className={classes.item}>
              <InputText
                type='text' 
                name='sum'
                label='Сумма к возврату'
                value={matchedAmount}
                onChange={e => setMatchedAmount(e.target.value
                  ? onlyDigitsWithDot(e.target.value)
                  : e.target.value
                )}
                error={touched && errors?.matchedAmount}
              />
            </div>
          </Form>
          <ButtonWrapper>
            <Button disabled={touched && !isValid} onClick={onSubmit}>Создать</Button>
          </ButtonWrapper>
        </div>
      </Modal2>
    )
  }
)