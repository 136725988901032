import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { useScrolled } from '../../hooks'

const styles = {
  root: {
    background: '#f8f8f8',
    paddingBottom: 20,
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 10,
    '&.scrolled': {
      boxShadow: [0, 4, 12, 'rgba(0,0,0,0.15)'],
    },
    '&.burger': {
      paddingLeft: 60,
    },
  },
}

const Header = ({
  classes,
  burger,
  children,
}) => {
  const scrolled = useScrolled()
  return (
    <div className={classnames(classes.root, { scrolled, burger })}>
      {children}
    </div>
  )
}

export default injectSheet(styles)(Header)