import * as actionTypes from './actionTypes'
import { Contracts } from '../../services/api'
import { setErrorContent } from '../Error/actions'

export const getContracts = (entityType, filters, onSuccess, forSuggestions) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_CONTRACTS })
    const { data } = await Contracts.getContracts(entityType, filters)
    dispatch({ 
      type: actionTypes.GET_CONTRACTS_SUCCESS, 
      data,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_CONTRACTS_ERROR })
  }
}

export const getContractType = (filters, onSuccess, forSuggestions) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_CONTRACT_TYPE })
    const { data } = await Contracts.getContractType(filters)
    dispatch({
      type: actionTypes.GET_CONTRACT_TYPE_SUCCESS,
      data,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_CONTRACT_TYPE_ERROR })
  }
}

export const getContractStatus = (filters, onSuccess, forSuggestions) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_CONTRACT_STATUS })
    const { data } = await Contracts.getContractStatus(filters)
    dispatch({
      type: actionTypes.GET_CONTRACT_STATUS_SUCCESS,
      data,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_CONTRACT_STATUS_ERROR })
  }
}