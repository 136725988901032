import React, { Component } from 'react'
import Highlighter from 'react-highlight-words'
import { Link } from 'react-router-dom'

class ListItem extends Component {

  handleClick = () => this.props.onClick(this.props.data, this.props.text)

  render() {
    const {
      id,
      text,
      searchQuery,
      withoutLink,
      textToHighlight,
    } = this.props

    return (
      <div className='dropdown-item'>
        <div className='filter__info' onClick={this.handleClick}>
          <Highlighter
            className='filter__title'
            highlightClassName='autocomplete__identity'
            searchWords={[searchQuery]}
            autoEscape={true}
            textToHighlight={text}
          />
          {textToHighlight &&
            <Highlighter
              className='filter__inn'
              highlightClassName='autocomplete__identity'
              searchWords={[searchQuery]}
              autoEscape={true}
              textToHighlight={textToHighlight}
            />
          }
        </div>
        {id && !withoutLink &&
          <Link className='filter__link' to={`?client-deal=${id}&section=home`}>Карточка</Link>
        }
      </div>
    )
  }
}

export default ListItem