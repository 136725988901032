import * as types from './actionTypes'

const initialState = {
  isFetching: false,
  filters: {},
  data: {},
}

export const FundsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORT_FUNDS_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case types.GET_REPORT_FUNDS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
      }
    case types.GET_REPORT_FUNDS_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}
