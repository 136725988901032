import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { updateCbsManager } from '../../../../redux/Company/actions'
import { UI } from '../../../../layout'
import classnames from 'classnames'

const styles = {
  root: {
    paddingBottom: 40,
    position: 'relative',
  },
  actions: {
    marginTop: 20,
  },
  input: {
    marginTop: 20,
  },
  title: UI.title,
  add: {
    ...UI.addButton,
    marginRight: 20,
  },
  remove: UI.removeButton,
  cancel: {
    ...UI.cancelButton,
    marginRight: 20,
  },
}

const CbsManager = ({
  classes,
  companyId,
  agentCbsId,
  updateCbsManager,
}) => {
  const [edit, setEdit] = useState(false)
  const [username, setUsername] = useState(agentCbsId)

  const onEdit = () => setEdit(true)
  const onChange = (e) => setUsername(e.target.value)

  const onCancel = () => {
    setEdit(false)
    setUsername(agentCbsId)
  }

  const onSave = () => {
    if (username) {
      updateCbsManager({ 
        companyId, 
        agentCbsId: username, 
      })
      setEdit(false)
    }
  }

  useEffect(() => {
    setUsername(agentCbsId)
  }, [agentCbsId])

  if (agentCbsId || agentCbsId === null) {    
    return (
      <Fragment>
        <div className={classes.root}>
          <div className={classes.title}>ID Агента в АБС</div>
          <input
            type='text'
            name='username'
            className={classes.input}
            value={username || ''}
            onChange={onChange}
            autoFocus={edit}
            disabled={!edit}
          />
          <div className={classes.actions}>
            {edit 
              ? <Fragment>
                  <div className={classes.cancel} onClick={onCancel}>Отменить</div>
                  <button 
                    type='button' 
                    className={classnames(classes.add, 'btn-form--ok')} 
                    onClick={onSave} 
                    disabled={!username}
                  >
                    Сохранить
                  </button>
                </Fragment>
              : <div className={classnames(classes.add, 'btn-form--ok')} onClick={onEdit}>
                  {username === null ? 'Установить' : 'Поменять'} агента в АБС
                </div>
            }
          </div>
        </div>
        <hr/>
      </Fragment>
    )
  } else {
    return null
  }
}

const mapStateToProps = ({ Company }) => {
  return {
    agentCbsId:
      Company && 
      Company.agentInfo && 
      Company.agentInfo.info && 
      Company.agentInfo.info.agentCbsId,
  }
}

const dispatchMapToProps = (dispatch, ownProps) => {
	return {
    updateCbsManager: (payload) => {
      dispatch(updateCbsManager(payload))
    },
	}
}

export default connect(mapStateToProps, dispatchMapToProps)(injectSheet(styles)(CbsManager))