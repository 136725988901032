import React from 'react'

const CheckboxField = ({ input, type, label, disabled, defaultValue }) => {
  const { checked, ...inputProps } = input
  return (
    <label className='checkbox'>
      <input
        type={type}
        disabled={disabled}
        checked={checked || defaultValue}
        {...inputProps}
      />
      <span className='checkbox__text' />
      {label}
    </label>
  )
}

export default CheckboxField