import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useIdleTimer } from 'react-idle-timer'
import { Modal2 } from '../../layout'
import { plural } from '../../utils'
import { THEME_COLOR } from '../../config'

const useStyles = createUseStyles({
  idleTimerModal: {
    width: 700,
    padding: 30,
  },
  text: {
    '& + &': {
      marginTop: 16,
    },
  },
  button: {
    background: THEME_COLOR,
    marginTop: 32,
    padding: [10, 20],
    borderRadius: 16,
    color: '#fff',
    border: 'none',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
})

const msToSec = (ms) => Math.ceil(ms / 1000)

const DEFAULT_PROPS = {
  title: 'Мы заметили, что Вы долгое время бездействовали.',
  subtitle: 'Автоматическое завершение сессии произойдёт через',
  submitText: 'Я всё ещё здесь',
  timeout: 1000 * 60 * 60, // 60 min
  // promptTimeout: 1000 * 60 * 6, //  6 min (timeout * 0.1)
}

export const IdleTimer = ({
  title = DEFAULT_PROPS.title,
  subtitle = DEFAULT_PROPS.subtitle,
  submitText = DEFAULT_PROPS.submitText,
  timeout = DEFAULT_PROPS.timeout,
  // promptTimeout = DEFAULT_PROPS.promptTimeout,
  onLogout,
}) => {
  const classes = useStyles()

  const _timeout = timeout * 0.9
  const promptTimeout = timeout * 0.1

  const [open, setOpen] = useState(false)
  const [remaining, setRemaining] = useState(0)

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 30 minutes. Here you can open your prompt. 
    // All events are disabled while the prompt is active. 
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setOpen(true)
    setRemaining(msToSec(promptTimeout))
  }

  const onIdle = () => {
    // onIdle will be called after the promptTimeout is reached.
    // In this case 30 seconds. Here you can close your prompt and 
    // perform what ever idle action you want such as log out your user.
    // Events will be rebound as long as `stopOnMount` is not set.
    setOpen(false)
    setRemaining(0)
    console.log('LOGOUT!')
    onLogout && onLogout()
  }

  const onActive = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()` 
    // is true. Here you will also want to close your modal and perform
    // any active actions. 
    setOpen(false)
    setRemaining(0)
  }

  const {
    getRemainingTime,
    isPrompted,
    activate,
  } = useIdleTimer({
    timeout: _timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
  })

  const onStillHere = () => {
    setOpen(false)
    activate()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(msToSec(getRemainingTime()))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted])

  return open
    ? <Modal2
        className={classes.idleTimerModal}
        onClose={onStillHere}
        withoutScroll
      >
        <div className={classes.text}>{title}</div>
        <div className={classes.text}>
          {subtitle}
          {' '}
          {remaining} {plural(remaining, 'секунду', 'секунды', 'секунд')}...
        </div>
        <div
          className={classes.button}
          onClick={onStillHere}
        >
          {submitText}
        </div>
      </Modal2>
    : null
}