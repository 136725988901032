import { RegistersPage } from './RegistersPage'
import { connect } from 'react-redux'

import * as actions from '../../redux/Registers/actions'

const mapStateToProps = ({ Registers }) => {
  const { registers, isFetching } = Registers

  return {
    registers,
    isFetching,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRegisters: (filters) => dispatch(actions.getRegisters(filters))
  }
}

export const RegistersPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistersPage)
