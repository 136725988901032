import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import { Row, Col4, Col6, Col12 } from '../../../../../../layout'
import { Block, Body, Fieldset, Header, InputText, Item, SubTitle, SwitchSlider } from '../../Layout'

export const ClientBank = ({
  date,
  enabled,
  orderNumber,
  provideLogin,
  sendToEmail,
  useSimpleESign,
}) => {
  const [open, setOpen] = useState(false)
  const showEmail = enabled && sendToEmail
  const showSimpleESign = enabled && useSimpleESign
  return (
    <Item>
      <Header
        name='Клиент-банк'
        open={open}
        date={date}
        enabled={enabled}
        orderNumber={orderNumber}
        onCollapse={() => setOpen(!open)}
      />
      <Collapse isOpen={open}>
        <Body>
          <Block>
            <SubTitle>Логин</SubTitle>
            <Fieldset>
              <SwitchSlider
                id='provideLogin'
                name='provideLogin'
                label='Выдать при личном обращении'
                checked={provideLogin}
                disabled
              />
              <SwitchSlider
                id='sendToEmail'
                name='sendToEmail'
                label='Направить по адресу электронной почты'
                checked={sendToEmail}
                disabled
              />
            </Fieldset>
            {showEmail &&
              <Fieldset>
                <InputText
                  label='E-mail'
                  value={''}
                  disabled
                />
              </Fieldset>
            }
          </Block>
          <Block>
            <SubTitle>Пароль направить по номеру телефона</SubTitle>
            <Fieldset>
              <InputText
                label='Телефон'
                value={''}
                disabled
              />
            </Fieldset>
            <Fieldset>
              <SwitchSlider
                id='showSimpleESign'
                name='showSimpleESign'
                label='Клиент намерен использовать простую электронную подпись (ПЭП) для подписания электронных документов'
                checked={showSimpleESign}
                disabled
              />
            </Fieldset>
          </Block>
          {showSimpleESign &&
            <Block>
              <SubTitle>Предоставить полномочия ПЭП следующему лицу</SubTitle>
              <Fieldset>
                <Row>
                  <Col4>
                    <InputText
                      label='Фамилия'
                      value={''}
                      disabled
                    />
                  </Col4>
                  <Col4>
                    <InputText
                      label='Имя'
                      value={''}
                      disabled
                    />
                  </Col4>
                  <Col4>
                    <InputText
                      label='Отчество'
                      value={''}
                      disabled
                    />
                  </Col4>
                </Row>
              </Fieldset>
              <Fieldset>
                <Row>
                  <Col6>
                    <InputText
                      label='Серия'
                      value={''}
                      disabled
                    />
                  </Col6>
                  <Col6>
                    <InputText
                      label='Номер'
                      value={''}
                      disabled
                    />
                  </Col6>
                </Row>
              </Fieldset>
              <Fieldset>
                <Row>
                  <Col6>
                    <InputText
                      label='Дата выдачи'
                      value={''}
                      disabled
                    />
                  </Col6>
                  <Col6>
                    <InputText
                      label='Код подразделения'
                      value={''}
                      disabled
                    />
                  </Col6>
                </Row>
              </Fieldset>
              <Fieldset>
                <Row>
                  <Col12>
                    <InputText
                      label='Кем выдан'
                      value={''}
                      disabled
                    />
                  </Col12>
                </Row>
              </Fieldset>
              <Fieldset>
                <Row>
                  <Col12>
                    <InputText
                      label='Телефон'
                      value={''}
                      disabled
                    />
                  </Col12>
                </Row>
              </Fieldset>
            </Block>
          }
        </Body>
      </Collapse>
    </Item>
  )
}