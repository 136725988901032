import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { UI, Row, InputText } from '../../../layout'

const styles = {
  remove: {
    ...UI.removeButton,
    width: '100%',
    marginTop: 22,
    '&.custom': {
      marginTop: 40,
    },
  },
  customLabel: {
    minHeight: 36,
  },
}

const SchemaFormRatesItem = ({
  classes,
  values,
  group,
  name,
  label,
  label2,
  onChange,
  onRemove,
  valueRegexp,
  disabled,
  errorFromAmount,
  errorRate,
}) => {
  const [value, setValue] = useState(values[name])
  const [rate, setRate] = useState(values.rate)

  useEffect(() => {
    setValue(values[name])
    setRate(values.rate)
  }, [values, name])

  const onChangeValue = (e) => {
    if (!disabled) {
      const targetValue = e.target.value
      const newValue = valueRegexp
        ? targetValue.replace(valueRegexp,'')
        : targetValue

      onChange({ 
        [name]: newValue, 
        rate, 
      })
      setValue(newValue)
    }
  }

  const onChangeRate = (e) => {
    if (!disabled) {
      onChange({ 
        [name]: value, 
        rate: e.target.value, 
      })
      setRate(e.target.value)
    }
  }

  const valueName = `${group}-${name}`
  const rateName = `${group}-rate`

  const custom = group === 'totalDebtRatesByAmount'
  
  return (
    <Row>
      <div className='col-4'>
        <InputText
          labelClassName={classnames({ [classes.customLabel]: custom } )} 
          type='text'
          name={valueName}
          label={label}
          value={value}
          onChange={onChangeValue}
          disabled={disabled}
          error={errorFromAmount}
        />
      </div>
      <div className='col-4'>
        <InputText
          labelClassName={classnames({ [classes.customLabel]: custom } )}
          type='text'
          name={rateName}
          label={label2}
          value={rate}
          onChange={onChangeRate}
          disabled={disabled}
          error={errorRate}
        />
      </div>
      <div className='col-4'>
        <div
          className={classnames(classes.remove, { custom })}
          onClick={onRemove}
        >
          Удалить
        </div>
      </div>
    </Row>
  )
}


SchemaFormRatesItem.propTypes = {
  values: PropTypes.object.isRequired,
  group: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  valueRegexp: PropTypes.any,
}

export default injectSheet(styles)(SchemaFormRatesItem)