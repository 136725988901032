import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  col6: {
    width: '50%',
    padding: [0, 10],
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
})

export const Col6 = ({ className, children }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.col6, className)}>
      {children}
    </div>
  )
}