import React, { useEffect } from 'react'
import injectSheet from 'react-jss'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getBaseSchema } from '../../../redux/Tariffs/actions'
import SchemaForm from './SchemaForm'
import { Modal } from '../../../layout'

const styles = {
  modal: {
    height: '100%',
  },
}

const ModalSchemaForm = ({
  classes,
  onClose,
  ...rest
}) => {
  const dispatch = useDispatch()
  const { tariffSchemaId } = useParams()

  useEffect(() => {
    if (tariffSchemaId) {
      dispatch(getBaseSchema(tariffSchemaId))
    }
  }, [tariffSchemaId, dispatch])

  return (
    <Modal className={classes.modal} width='1000px' onClose={onClose}>
      <SchemaForm onClose={onClose} {...rest}/>
    </Modal>
  )
}

export default injectSheet(styles)(ModalSchemaForm)