import React, { Fragment, useEffect, useState, useCallback, useMemo } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { getFactoringTasks, getFactoringTasks2, completeFactoringTask } from '../../../redux/Factoring/actions'
import { Toast } from '../../../components/Toast'
import { Checkbox, Icon, Row, Col4, Col6, Col8, Col12, InputText, Fieldset, Modal, Tabs } from '../../../layout'
import { formattedNumber, plural, getValue } from '../../../utils'
import { H3 } from '../Layout'
import { THEME_COLOR } from '../../../config'
import { useFactoring, FINANCING_TASKS } from '../useFactoring'
import { FactoringWrapper } from '../FactoringWrapper'
import Scorings from '../../../layout/Modal/Scorings'
import { ModifyModal } from './ModifyModal'
import { RowValue } from '../RowValue'

const useStyles = createUseStyles({
  container: {
    marginTop: 20,
    border: [1, 'solid', '#B6BCC3'],
  },
  containerWrapper: {
    maxHeight: 'calc(100vh - 380px)',
    overflow: 'auto',
  },
  table: {
    minHeight: 'inherit',
    marginBottom: 0,
    '& thead': {
      '& th': {
        height: 40,
        textAlign: 'left',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        '&:first-child': {
          width: 40,
        },
        '& + th': {
          borderLeft: [1, 'solid', '#B6BCC3'],
        },
        '& > div': {
          background: '#eee',
          padding: 10,
          fontSize: 12,
          lineHeight: 20/12,
          color: '#888',
          fontWeight: 600,
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 1,
            background: '#B6BCC3',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& + tr': {
          borderTop: [1, 'solid', '#B6BCC3'],
        },
      },
      '& td': {
        padding: 0,
        whiteSpace: 'nowrap',
        textAlign: 'left',
        verticalAlign: 'middle',
        '&:first-child': {
          background: '#eee',
          padding: 0,
        },
        '& + td': {
          borderLeft: [1, 'solid', '#B6BCC3'],
        },
        '&.colspan': {
          background: '#F3F4F5',
          padding: [10, 10, 10, 50],
          color: '#0B1F35',
          fontSize: 14,
          lineHeight: 20/14,
          fontWeight: 600,
        },
        '& > div': {
          minHeight: 40,
          padding: 10,
          fontSize: 14,
          lineHeight: 20/14,
          color: '#0B1F35',
          whiteSpace: 'nowrap',
          '&.verified': {
            background: '#FEF2E6',
            padding: 6,
            color: '#F57C00',
            fontSize: 10,
            lineHeight: 13/10,
            fontWeight: 600,
            textTransform: 'uppercase',
            borderRadius: 999,
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '&.rejected': {
            background: '#FDEAE9',
            padding: 6,
            color: '#FF5C5C',
            fontSize: 10,
            lineHeight: 13/10,
            fontWeight: 600,
            textTransform: 'uppercase',
            borderRadius: 999,
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '& > span': {
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '& > svg': {
            width: 12,
            height: 12,
            marginLeft: 5,
            display: 'inline-block',
            verticalAlign: 'top',
          },
        },
      },
    },
    '& tfoot': {
      '& td': {
        height: 40,
        padding: 0,
        textAlign: 'left',
        position: 'sticky',
        bottom: 0,
        zIndex: 2,
        '&:first-child': {
          width: 40,
        },
        '& + td': {
          borderLeft: [1, 'solid', '#B6BCC3'],
        },
        '& > div': {
          background: '#eee',
          padding: 10,
          fontSize: 14,
          lineHeight: 20/14,
          color: '#0B1F35',
          fontWeight: 600,
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          position: 'relative',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: 1,
            background: '#B6BCC3',
          },
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 1,
            background: '#B6BCC3',
          },
        },
      },
    },
  },
  info: {
    background: '#F3F4F5',
    minHeight: 68,
    padding: 10,
    color: '#0B1F35',
    fontSize: 16,
    lineHeight: 20/16,
    borderTop: [1, 'solid', '#B6BCC3'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      fontWeight: 600,
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& > div': {
      '&:first-child': {
        '& > div': {
          '& + div': {
            marginTop: 4,
          },
        },
      },
    },
  },
  checkbox: {
    width: 20,
    height: 20,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  decision: {
    background: THEME_COLOR,
    height: 48,
    padding: [13, 30],
    color: '#F3F4F5',
    fontSize: 14,
    lineHeight: 20/14,
    fontWeight: 600,
    border: [1, 'solid', THEME_COLOR],
    borderRadius: 5,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    // '&.reject': {
    //   background: 'none',
    //   borderColor: '#6d7986',
    //   color: '#0b1f35',
    // },
    '& + &': {
      marginLeft: 20,
    },
    '& > span': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& > svg': {
      width: 20,
      height: 20,
      marginLeft: 15,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  name: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  block: {
    background: '#F3F4F5',
    padding: 20,
  },
  blockTitle: {
    fontSize: 20,
  },
  modifyModal: {
    width: 1000,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  tabs: {
    marginTop: 20,
  },
  content: {
    minHeight: 338,
    '& + &': {
      marginTop: 40,
    },
  },
  tableWrapper: {
    width: '100%',
    overflow: 'auto',
    margin: [10, 0, 30, 0],
    '& table': {
      margin: 0,
      border: [2, 'solid', '#0b1f35'],
    },
    '& th': {
      background: '#eee',
      padding: [6, 12],
      color: '#888',
      fontSize: 12,
      lineHeight: 18/12,
      fontWeight: 600,
      border: [1, 'solid', '#0b1f35'],
      textAlign: 'center',
      '&:first-child': {
        background: '#ccc',
        width: 200,
      },
    },
    '& td': {
      padding: [6, 12],
      border: [1, 'solid', '#0b1f35'],
      textAlign: 'center',
      '&:first-of-type:not(:only-of-type)': {
        // background: '#eee',
        // width: 200,
        // color: '#888',
        // fontSize: 12,
        // lineHeight: 18/12,
        // fontWeight: 600,
        whiteSpace: 'nowrap',
      },
      '&:only-child': {
        background: '#fff',
      },
    }
  },
  wrapper: {
    marginTop: 20,
  },
  th: {
    textAlign: 'left !important',
    whiteSpace: 'nowrap',
  },
  td: {
    background: '#fff !important',
    textAlign: 'left !important',
    whiteSpace: 'nowrap',
  },
  footer: {
    height: 80,
  },
})

const MODIFY_CODES = [
  'MODIFY',
  'ACCEPT_WITH_CHANGES',
]

const isModifyCode = (code) => MODIFY_CODES.indexOf(code) > -1

export const FactoringPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { taskDefinitionKey: taskDefinitionKeyFromParams } = useParams()
  const { location } = useHistory()

  const isMonitoringIssues = location.pathname.toLowerCase().includes('monitoring')
  const isTransfer = location.pathname.toLowerCase().includes('transfer')

  const taskDefinitionKey = isMonitoringIssues ? 'issues' : taskDefinitionKeyFromParams

  const isFinancing = FINANCING_TASKS.indexOf(taskDefinitionKey) > -1

  const _getFactoringTasks = useMemo(() => {
    return isMonitoringIssues
      ? getFactoringTasks2
      : getFactoringTasks
  }, [isMonitoringIssues])

  const [items, setItems] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [queue, setQueue] = useState([])
  const [code, setCode] = useState('')
  const [textCode, setTexCode] = useState('')
  const [monitoringTaskDefinitionKeySelected, setMonitoringTaskDefinitionKeySelected] = useState('')
  const [openModifyModal, setOpenModifyModal] = useState(false)
  const [config, setConfig] = useState({})
  const [globalSubmitted, setGlobalSubmitted] = useState(false)

  const {
    dataFilters = [],
    dataItems = [],
  } = useFactoring({ entityType: taskDefinitionKey }) || {}

  const { tasks } = useSelector(state => state?.Factoring || {})
  const { factoring, monitoring } = useSelector(state => state?.Settings || {})

  const onShowModifyModal = () => setOpenModifyModal(true)
  const onCloseModifyModal = () => setOpenModifyModal(false)

  const { taskName } = factoring?.tasks?.find(item => item?.taskDefinitionKey === taskDefinitionKey) || {}

  const { decisions = [] } = isMonitoringIssues
    ? monitoring?.tasks?.find(item => item.taskDefinitionKey === monitoringTaskDefinitionKeySelected) || {}
    : factoring?.tasks?.find(item => item.taskDefinitionKey === taskDefinitionKey) || {}

  const firstSumIndex = dataItems?.findIndex(item => item?.sum)

  const colSpan = firstSumIndex > -1  ? firstSumIndex : dataItems?.length

  const titles = dataItems.map(item => item?.name)

  const _tasks = (tasks?.items || [])?.map(item => ({
    ...item,
    ...(item?.data ? { ...item.data } : {}),
    id: item.id,
  }))

  const onResetValues = useCallback(() => {
    setAllChecked(false)
    setCode('')
    onCloseModifyModal()
    setConfig({})
    setTexCode('')
    setGlobalSubmitted(false)
  }, [])

  const onCompleteFactoringTask = useCallback(() => {
    if (queue.length > 0) {
      const { id, type, checked, data = {} } = queue[0]
      for (var key in data) {
        if (key.toLowerCase().includes('sum')) {
          data[key] = Number(data[key])
        }
      }
      const payload = {
        ...data,
        decisionValue: code,
      }
      const onCallback = () => {
        const newQueue = [...queue]
        newQueue.shift()
        setQueue(newQueue)
        if (newQueue.length === 0) {
          if (isModifyCode(code)) {
            onCloseModifyModal()
          }
          onResetValues()
          dispatch(_getFactoringTasks({ taskDefinitionKey, type: '' }))
        }
      }
      const onSuccess = () => {
        Toast({ type: 'success', message: 'Завершение задачи прошло успешно' })
        onCallback()
      }
      const onError = (error) => {
        Toast({ type: 'error', message: error || 'Завершение задачи не удалось' })
        onCallback()
      }
      if (
        (checked && !isModifyCode(code)) ||
        (checked && isModifyCode(code) && Object.keys(data).length > 0 && globalSubmitted)
      ) {
        dispatch(completeFactoringTask(id, type, payload, onSuccess, onError))
      }
    }
  }, [queue, code, globalSubmitted, taskDefinitionKey, _getFactoringTasks, onResetValues, dispatch])

  useEffect(onCompleteFactoringTask, [onCompleteFactoringTask])

  useEffect(() => {
    setItems((tasks?.items || [])?.map(({ id, type, data }) => ({ id, type, data, checked: false })))
  }, [tasks])

  const onSelectAll = () => {
    const _allChecked = !allChecked
    setAllChecked(_allChecked)
    setItems(items.map(item => {
      if ((_allChecked && !item.checked) || (!_allChecked && item.checked)) {
        item = {
          ...item,
          checked: !item.checked
        }
      }
      return item
    }))
  }

  const isChecked = (id) => {
    const { checked } = items.find(item => item.id === id && item.checked) || {}
    return checked
  }

  const onSelect = (e, taskDefinitionKey) => {
    const { id } = e.target
    const newItems = items.map(item => {
      if (item?.id === id) {
        item = {
          ...item,
          ...(e.target.checked ? { taskDefinitionKey } : {}),
          checked: !item.checked,
        }
      }
      return item
    })
    setItems(newItems)
    setMonitoringTaskDefinitionKeySelected(
      newItems.find(({ taskDefinitionKey }) => taskDefinitionKey) ? taskDefinitionKey : ''
    )
  }

  const getTotal = (array) => `${array.length} ${plural(array.length, 'запись', 'записи', 'записей')}`

  const total = getTotal(_tasks)
  
  const getTotalSumByField = (field) => _tasks.reduce((result, item) => {
    const sum = getValue(item, field)
    return parseFloat(result + sum)
  }, 0)

  const checkedItems = items.filter(item => item?.checked)

  const totalSelected = getTotal(checkedItems)

  const totalSelectedSum = checkedItems.reduce((result, item) => {
    const sum = item?.data?.financing?.sum || item?.data?.sum || item?.data?.payment?.unmatchedAmount
    return parseFloat(result + sum)
  }, 0)

  const completeTasks = items.filter(item => item.checked)
  const showDecisions = items.some(item => item.checked)

  const onModify = ({ fields, text, code }) => {
    onComplete(code)
    setConfig(fields)
    setTexCode(text)
    onShowModifyModal()
  }

  const onComplete = (code) => {
    setQueue(completeTasks)
    setCode(code)
  }

  // const getStatus = (flag) => flag
  //   ? <div className='verified'>
  //       <span>Верифицировано</span>
  //       <Icon iconName='verified' />
  //     </div>
  //   : <div className='rejected'>
  //       <span>Отклонено</span>
  //       <Icon iconName='rejected' />
  //     </div>


  const selectedItems = _tasks.filter(({ id }) => checkedItems?.find(item => item?.id === id))
  const showSelectedItem = selectedItems?.length === 1
  const { data } = selectedItems[0] || {}

  const TABS = [
    {
      name: `Поставка ${data?.supply?.number}`,
      show: !isTransfer && !isMonitoringIssues,
      content: <div className={classes.content}>
        <Fieldset>
          <Row>
            <Col6>
              <Fieldset>
                <Row>
                  <Col6>
                    <InputText
                      type='text'
                      label='Продукт'
                      value={data?.factoringContract?.productType || ''}
                      disabled
                    />
                  </Col6>
                  <Col6>
                    <InputText
                      type='text'
                      label='Номер'
                      value={data?.supply?.number || ''}
                      disabled
                    />
                  </Col6>
                </Row>
              </Fieldset>
              <Fieldset>
                <Row>
                  <Col6>
                    <InputText
                      type='text'
                      label='Отсрочка'
                      value={data?.supply?.deferDate || ''}
                      disabled
                    />
                  </Col6>
                  <Col6>
                    <InputText
                      type='date'
                      label='Дата регистрации'
                      value={data?.supply?.registrationDate || ''}
                      disabled
                    />
                  </Col6>
                </Row>
              </Fieldset>
            </Col6>
            <Col6>
              <Fieldset>
                <Row>
                  <Col6>
                    <InputText
                      type='text'
                      label='Сумма поставки'
                      value={data?.supply?.sum || ''}
                      disabled
                    />
                  </Col6>
                  <Col6>
                    <InputText
                      type='date'
                      label='Дата'
                      value={data?.supply?.supplyDate || ''}
                      disabled
                    />
                  </Col6>
                </Row>
              </Fieldset>
              <Fieldset>
                <Row>
                  <Col12>
                    <InputText
                      type='text'
                      label='Сумма финансирования'
                      value={data?.financing?.sum || ''}
                      disabled
                    />
                  </Col12>
                </Row>
              </Fieldset>
            </Col6>
          </Row>
        </Fieldset>
        <Fieldset>
          <Row>
            <Col6>
              <div className={classes.block}>
                <Fieldset>
                  <div className={classes.blockTitle}>Поставщик</div>
                </Fieldset>
                <Fieldset>
                  <Row>
                    <Col8>
                      <InputText
                        type='text'
                        label='Поставщик'
                        value={data?.supplier?.name || ''}
                        disabled
                      />
                    </Col8>
                    <Col4>
                      <InputText
                        type='text'
                        label='ИНН поставщика'
                        value={data?.supplier?.inn || ''}
                        disabled
                      />
                    </Col4>
                  </Row>
                </Fieldset>
              </div>
            </Col6>
            <Col6>
              <div className={classes.block}>
                <Fieldset>
                  <div className={classes.blockTitle}>Дебитор</div>
                </Fieldset>
                <Fieldset>
                  <Row>
                    <Col8>
                      <InputText
                        type='text'
                        label='Дебитор'
                        value={data?.debtor?.name || ''}
                        disabled
                      />
                    </Col8>
                    <Col4>
                      <InputText
                        type='text'
                        label='ИНН дебитора'
                        value={data?.debtor?.inn || ''}
                        disabled
                      />
                    </Col4>
                  </Row>
                </Fieldset>
              </div>
            </Col6>
          </Row>
        </Fieldset>
        <Fieldset>
          <Row>
            <Col6>
              <div className={classes.block}>
                <Fieldset>
                  <div className={classes.blockTitle}>Договор факторинга</div>
                </Fieldset>
                <Fieldset>
                  <Row>
                    <Col8>
                      <InputText
                        type='text'
                        label='Договор факторинга'
                        value={data?.factoringContract?.number || ''}
                        disabled
                      />
                    </Col8>
                    <Col4>
                      <InputText
                        type='date'
                        label='Дата договора факторинга'
                        value={data?.factoringContract?.date || ''}
                        disabled
                      />
                    </Col4>
                  </Row>
                </Fieldset>
              </div>
            </Col6>
            <Col6>
              <div className={classes.block}>
                <Fieldset>
                  <div className={classes.blockTitle}>Договор поставки</div>
                </Fieldset>
                <Fieldset>
                  <Row>
                    <Col8>
                      <InputText
                        type='text'
                        label='Договор поставки'
                        value={data?.supplyContract?.number || ''}
                        disabled
                      />
                    </Col8>
                    <Col4>
                      <InputText
                        type='date'
                        label='Дата договора поставки'
                        value={data?.supplyContract?.date || ''}
                        disabled
                      />
                    </Col4>
                  </Row>
                </Fieldset>
              </div>
            </Col6>
          </Row>
        </Fieldset>
      </div>,
    },
    {
      name: 'Документы',
      show: isFinancing,
      content: <div className={classes.content}>
        <H3>Документы</H3>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.th} style={{background: '#eee'}}>Тип документа</th>
                  <th className={classes.th}>Номер документа</th>
                  <th className={classes.th}>Дата документа</th>
                </tr>
              </thead>
              <tbody>
                {data?.supply?.confirmationDocuments?.map((item, index) => 
                  <tr key={index}>
                    <td className={classes.td}>{item?.type}</td>
                    <td className={classes.td}>{item?.number}</td>
                    <td className={classes.td}>
                      {item?.date
                        ? new Date(item.date).toLocaleDateString('ru-RU')
                        : '-'
                      }
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>,
    },
    ...(data?.scorings?.length > 0
        ? data?.scorings?.reduce((result, item) => {
            if (item?.scoringType) {
              result.push({
                name: item?.scoringName || item?.scoringType,
                show: true,
                content: <div className={classes.content}>
                  <Scorings data={item?.data} isFactoring />
                </div>,
              })
            }
            return result
          }, [])
        : []
      ),
  ]

  return (
    <>
      <FactoringWrapper
        title={isMonitoringIssues
          ? 'Проблемы'
          : taskName
        }
        dataFilters={dataFilters}
        entityType={taskDefinitionKey}
        onRequest={_getFactoringTasks}
        requestPayload={{
          taskDefinitionKey: isMonitoringIssues ? '' : taskDefinitionKey,
          type: FINANCING_TASKS.indexOf(taskDefinitionKey) > -1
            ? 'FINANCING'
            : isMonitoringIssues
              ? 'monitoring'
              : '',
        }}
        autoHeight
        checkedItems={checkedItems}
        isMonitoringIssues={isMonitoringIssues}
      >
        <div className={classes.container}>
          <div className={classes.containerWrapper}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>
                    <div>
                      <Checkbox
                        className={classes.checkbox}
                        checked={allChecked || false}
                        onChange={onSelectAll}
                        title={allChecked ? 'Снять все' : 'Выбрать все'}
                      />
                    </div>
                  </th>
                  {titles.map((item, index) => 
                    <th key={index}><div>{item}</div></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {dataItems?.length > 0 && _tasks?.length > 0 && _tasks.map((task, index) =>
                  <tr key={index}>
                    <td>
                      <div>
                        <Checkbox
                          id={task?.id}
                          className={classes.checkbox}
                          checked={isChecked(task?.id)}
                          onChange={(e) => onSelect(e, task?.taskDefinitionKey)}
                          title={isChecked(task?.id) ? 'Снять выбор' : 'Выбрать'}
                        />
                      </div>
                    </td>
                    {dataItems.map((props, index) =>
                      <RowValue
                        key={index}
                        item={task}
                        {...props}
                      />
                    )}
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={colSpan + 1}>
                    <div>Итого: {total}</div>
                  </td>
                  {dataItems?.slice(colSpan)?.map((item, index) =>
                    <td key={index}>
                      <div>
                        {item?.sum
                          ? `${formattedNumber(getTotalSumByField(item?.field))} ₽`
                          : '-'
                        }
                      </div>
                    </td>
                  )}
                </tr>
              </tfoot>
            </table>
          </div>
          <div className={classes.info}>
            <div>
              <>
                <div><span>Итого выбрано:</span> {totalSelected}</div>
                {!isMonitoringIssues &&
                  <div><span>На сумму:</span> {formattedNumber(totalSelectedSum)} ₽</div>
                }
              </>
            </div>
            {showDecisions && decisions?.length > 0 &&
              <div>
                {decisions.map(({ code, text, fields }, index) => {
                  const reject = code === 'REJECT'
                  const accept = code === 'ACCEPT'
                  return (
                    <Fragment key={index}>
                      <div
                        className={classnames(classes.decision, { reject })}
                        onClick={() => isModifyCode(code)
                          ? onModify({ fields, text, code })
                          : onComplete(code)
                        }
                      >
                        <span>{text}</span>
                        {accept && <Icon iconName='accept' />}
                        {reject && <Icon iconName='reject' />}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            }
          </div>
        </div>
        {showSelectedItem &&
          <Tabs className={classes.tabs} items={TABS} />
        }
        {openModifyModal &&
          <Modal className={classes.modifyModal} onClose={onCloseModifyModal}>
            <ModifyModal
              classes={classes}
              textCode={textCode}
              completeTasks={completeTasks}
              config={config}
              queue={queue}
              setQueue={setQueue}
              setGlobalSubmitted={setGlobalSubmitted}
              onClose={onCloseModifyModal}
            />
          </Modal>
        }
      </FactoringWrapper>
      <div className={classes.footer} />
    </>
  )
}