import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Label, Error } from '../../layout'

const useStyles = createUseStyles({
  textarea: {
    background: 'rgba(11, 31, 53, 0.05)',
    padding: 12,
    width: '100%',
    height: 156,
    lineHeight: 1.33,
    border: 'none',
    borderBottom: [1, 'solid', '#B6BCC3'],
    // borderRadius: 5,
    resize: 'none',
    transition: 'background 300ms, border 300ms ease',
    '&:focus': {
      background: 'rgba(11, 31, 53, 0.1)',
      borderWidth: 2,
      borderColor: '#000',
      boxShadow: 'none',
      outline: 'none',
    },
    '&.error': {
      borderColor: 'red',
    },
  },
})

export const Textarea = ({
  name,
  label,
  labelClassName,
  className,
  error,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <>
      {label && 
        <Label className={classnames(classes.label, labelClassName)} htmlFor={name}>
          {label}
        </Label>
      }
      <textarea className={classnames(classes.textarea, className, { error })} name={name} {...rest} />
      {error && 
        <Error>{error}</Error>
      }
    </>
  )
}