import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'

import { getCompanyFoundersInfo } from '../../../../redux/Company/actions'
import { Attributes } from '../Helpers/Attributes'
import Overlay from '../../../Overlay'
import { User } from '../Helpers/User'
import { Passport } from '../Helpers/Passport'
import { Attribute } from '../Helpers/Attributes'

const styles = {
  item: {
    paddingBottom: '16px',
    position: 'relative',
    '& + &': {
      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
      '& .block_head': {
        marginTop: '16px',
      },
    },
  },
}

export class Founders extends Component {
  async loadData() {
    const { dispatch, companyId } = this.props
    dispatch(getCompanyFoundersInfo(companyId))
  }

  componentDidMount() {
    if (!this.props.fetching) { 
      this.loadData()
    }
  }

  componentDidUpdate(prev) {
    const { companyId: oldCompanyId } = prev
    const { companyId } = this.props
    if (oldCompanyId !== companyId) {
      this.loadData()
    }
  }

  render() {
    const { 
      founders: {
        persons = [], 
        companies= []
      } = {}, 
      fetching,
      classes,
    } = this.props

    const personsIsset = persons && persons.length
    const companiesIsset = companies && companies.length
    let foundersCounter = 0

    if (fetching) {
      return <Overlay/>
    }

    return (
      <div className='block-info'>
        
        {personsIsset ?
          <Fragment>
            {persons.map((data, index) => {
              foundersCounter++
              const share = data && data.share
              const identityDocuments = data && data.identityDocuments
              const percent = (share && share.percent) || 0
              // const sum = (share && share.sum) || 0
              const passport = identityDocuments && identityDocuments.length && identityDocuments[0]

              return (
                <div className={classes.item} key={index}>
                  <div className='block_head'>
                    Учредитель {index + 1} - {' '}
                    <span className='text-info'>{percent}%</span>
                    {/* <span className='text-info'>{', '}</span> */}
                    {/* <span className='text-info'>{sum} руб.</span> */}
                  </div>
                  <User user={data} />
                  <div className='row'>
                    <Attribute col='12' attr={{ label: 'Доля, %', value: percent }}/>
                    {/* <Attribute col='6' attr={{ label: 'Сумма', value: sum }}/> */}
                  </div>
                  <hr/>
                  <div className='block_head'>Паспортные данные</div>
                  <Passport passport={passport}/>
                  <hr/>
                  <Attributes {...data.personAttributes}/>
                </div>
              )
            })}
          </Fragment>
          : null
        }

      {companiesIsset ?
        <Fragment>
          {companies.map((data, index) => {
            const share = data && data.share
            const percent = (share && share.percent) || 0
            // const sum = (share && share.sum) || 0
            const companyName = (data && data.displayName) || ''
            const inn = (data && data.INN) || ''
            const ogrn = (data && data.OGRN) || ''

            return (
              <div className={classes.item} key={index + foundersCounter}>
                <div className='block_head'>
                  Учредитель {index + foundersCounter + 1} - {' '}
                  <span className='text-info'>{percent}%</span>
                  {/* <span className='text-info'>{', '}</span> */}
                  {/* <span className='text-info'>{sum} руб.</span> */}
                </div>
                <div className='row'>
                  <Attribute col='12' attr={{ label: ' Компания', value: companyName }}/>
                  <Attribute col='6' attr={{ label: 'ИНН', value: inn }}/>
                  <Attribute col='6' attr={{ label: 'ОГРН', value: ogrn }}/>
                  <Attribute col='12' attr={{ label: 'Доля, %', value: percent}}/>
                  {/* <Attribute col='6' attr={{ label: 'Сумма', value: sum }}/> */}
                </div>
              </div>
            )
          })}
        </Fragment>
        : null
      }	

    </div>
  )}
}

const mapStateToProps = ({ Company }) => {
  return {
    ...Company.foundersInfo,
  }
}

export default injectSheet(styles)(connect(mapStateToProps)(Founders))