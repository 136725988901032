export const magicFilter = { limit: 42 }

export const DEBONCE_TIMER = 500

export const voidFunction = () => {}

export function getFiltersWithoutMagicFilter(mergedFilters) {
  const magicKey = Object.keys(magicFilter)
  const { [magicKey[0]]: key, ...filtersForRequest } = mergedFilters

  return filtersForRequest
}

export const Conditions = {
  EQUAL: ':',
  LARGER: '>',
  SMALLER: '<',
  NOT_EQUAL: '!',
  LIKE: '~'
}

export function transformFilterQuery(condition, value, name) {
  if (!value) return ''

  return `${name}${condition}${value}`
}
