import * as actionTypes from './actionTypes'
import { Task } from '../../services/api'
import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'


export const getTaskForm = (formId) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.TASK_FETCH })
    const { isSuccess, ...res } = await Task.getForm(formId)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ type: actionTypes.TASK_SUCCESS, data: res })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.TASK_ERROR })
  }
}

export const clearTaskInfo = () => (dispatch) => 
  dispatch({ type: actionTypes.TASK_CLEAR })

export const retryResendToComplete = (ids) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.RETRY_RESEND_TO_COMPLETE })
    const { isSuccess, ...res } = await Task.retryResendToComplete(ids)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ 
      type: actionTypes.RETRY_RESEND_TO_COMPLETE_SUCCESS,
    })
  }
  catch(err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.RETRY_RESEND_TO_COMPLETE_ERROR })
  }
}

export const getBankSigners = () => async (dispatch) => {
  const showError = (error) => {
    console.log(error)
    dispatch(setErrorContent(error))
    dispatch({ 
      type: actionTypes.GET_BANK_SIGNERS_ERROR,
      error,
    })
  }
  try {
    dispatch({ type: actionTypes.GET_BANK_SIGNERS })
    const { isSuccess, needLogout, message, ...res } = await Task.getBankSigners()
    if (isSuccess) {
      dispatch({ 
        type: actionTypes.GET_BANK_SIGNERS_SUCCESS,
        data: res,
      })
    } else {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      showError(message)
    }    
  }
  catch(error) {
    showError(error)
  }
}

export const assignPrintGuarantee = (ids, username, callback) => async (dispatch) => {
  const showError = (error) => {
    console.log(error)
    dispatch(setErrorContent(error))
    dispatch({ 
      type: actionTypes.PRINT_GUARANTEE_ASSIGN_ERROR,
      error,
    })
  }
  try {
    dispatch({ type: actionTypes.PRINT_GUARANTEE_ASSIGN })
    const { isSuccess, needLogout, message } = await Task.assignPrintGuarantee(ids, username)
    if (isSuccess) {
      dispatch({ type: actionTypes.PRINT_GUARANTEE_ASSIGN_SUCCESS })
      if (callback) {
        callback()
      }
    } else {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      showError(message)
    }
  } catch (error) {
    showError(error)
  }
}