import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import { ConfirmModal } from '../../../../components/ConfirmModal'
import { Icon, Checkbox } from '../../../../layout'
import { deleteFactoringOperation } from '../../../../redux/Factoring/actions'
import { RowValue } from '../../RowValue'

const useStyles = createUseStyles({
  tableWrapper: {
    maxHeight: 'inherit',
    borderLeft: [1, 'solid', '#B6BCC3'],
    borderRight: [1, 'solid', '#B6BCC3'],
    borderBottom: [1, 'solid', '#B6BCC3'],
    overflow: 'auto',
  },
  table: {
    marginBottom: 0,
    '& thead': {
      '& th': {
        background: '#eee',
        height: 40,
        textAlign: 'left',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        verticalAlign: 'middle',
        '& + th': {
          borderLeft: [1, 'solid', '#B6BCC3'],
          '& > div': {
            // borderLeft: [1, 'solid', '#B6BCC3'],
          },
        },
        '& > div': {
          padding: 10,
          // borderTop: [1, 'solid', '#B6BCC3'],
          fontSize: 12,
          lineHeight: 20/12,
          color: '#888',
          fontWeight: 600,
          textTransform: 'uppercase',
          // whiteSpace: 'nowrap',
          // position: 'relative',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: 1,
            background: '#B6BCC3',
          },
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 1,
            background: '#B6BCC3',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        background: 'transparent',
        '&.canBeSelected': {
          cursor: 'pointer',
          '&:hover': {
            background: 'rgba(0, 0, 0, 0.03)',
          },
        },
        '&.selectedRow': {
          background: '#0b1f35',
          cursor: 'pointer',
          '& > td': {
            '& > div': {
              color: '#fff',
              '& $delete': {
                fill: '#fff',
              },
              '& > a': {
                color: '#fff',
              },
            },
          },
          '& $checkbox': {
            '& label': {
              '&:before': {
                background: '#fff',
                borderColor: '#fff',
              },
              '& svg': {
                fill: '#0B1F35',
              },
            },
          },
        },
        '& + tr': {
          borderTop: [1, 'solid', '#B6BCC3'],
        },
      },
      '& td': {
        padding: 0,
        whiteSpace: 'nowrap',
        textAlign: 'left',
        verticalAlign: 'top',
        '&:first-child': {
          paddingLeft: 0,
        },
        '& + td': {
          borderLeft: [1, 'solid', '#B6BCC3'],
          '& > div': {
            // borderLeft: [1, 'solid', '#B6BCC3'],
          },
        },
        '&.colspan': {
          background: '#F3F4F5',
          padding: [10, 10, 10, 50],
          color: '#0B1F35',
          fontSize: 14,
          lineHeight: 20/14,
          fontWeight: 600,
        },
        '& > div': {
          minHeight: 40,
          padding: 10,
          fontSize: 14,
          lineHeight: 20/14,
          color: '#0B1F35',
          whiteSpace: 'nowrap',
          '&.status': {
            background: '#D5F3E2',
            padding: [6, 22],
            borderRadius: 999,
            color: '#A9B563',
            fontWeight: 600,
          },
          '&.verified': {
            background: '#FEF2E6',
            padding: 6,
            color: '#F57C00',
            fontSize: 10,
            lineHeight: 13/10,
            fontWeight: 600,
            textTransform: 'uppercase',
            borderRadius: 999,
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '&.rejected': {
            background: '#FDEAE9',
            padding: 6,
            color: '#FF5C5C',
            fontSize: 10,
            lineHeight: 13/10,
            fontWeight: 600,
            textTransform: 'uppercase',
            borderRadius: 999,
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '&.ellipsis': {
            // maxWidth: 70,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '&.bold': {
            fontWeight: 600,
          },
          '& > a': {
            textDecoration: 'underline',
          },
          '& > span': {
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '& > svg': {
            width: 12,
            height: 12,
            marginLeft: 5,
            display: 'inline-block',
            verticalAlign: 'top',
          },
        },
      },
    },
  },
  delete: {
    width: '16px !important',
    height: '16px !important',
    margin: '2px !important',
  },
  checkbox: {
    width: 20,
    height: 20,
    display: 'inline-block',
    verticalAlign: 'top',
  },
})

export const Table = ({
  id,
  className,
  entityType,
  dataItems,
  items,
  onOpen,
  onSelect,
  ...rest
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [deletedItemId, setDeletedItemId] = useState(null)
  const [allChecked, setAllChecked] = useState(false)
  const [selected, setSelected] = useState([])

  const isOperation = entityType === 'operation'

  const onDeleteFactoringOperation = () => {
    const onSuccess = () => {
      rest.onSuccess()
      setDeletedItemId(null)
    }
    dispatch(deleteFactoringOperation(deletedItemId, onSuccess))
  }

  const isChecked = (id) => selected?.find(item => item === id)

  const onSelectCheckbox = (e) => {
    const { id, checked } = e?.target || {}
    const newSelected = checked
      ? [...selected, id]
      : selected?.filter(item => item !== id)
    setSelected(newSelected)
  }

  const onSelectAll = () => {
    const _allChecked = !allChecked
    const newSelected = _allChecked
      ? items?.map(item => item?.id)
      : []
    setAllChecked(_allChecked)
    setSelected(newSelected)
  }

  useEffect(() => {
    onSelect && onSelect(selected)
  }, [selected, onSelect])

  return (
    <>
      <div className={classnames(classes.tableWrapper, className)}>
        <table className={classes.table}>
          <thead>
            <tr>
              {isOperation && 
                <th><div>&nbsp;</div></th>
              }
              {onSelect &&
                <th>
                  <div>
                    <Checkbox
                      className={classes.checkbox}
                      checked={allChecked || false}
                      onChange={onSelectAll}
                      title={allChecked ? 'Снять все' : 'Выбрать все'}
                    />
                  </div>
                </th>
              }
              {dataItems.map(({ name }, index) => 
                <th key={`th-${index}`}>
                  <div>{name}</div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              const selectedRow =
                (id && item?.id?.toString() === id?.toString()) ||
                (id && item?.number?.toString() === id?.toString())
              const canBeSelected = !!onOpen && !selectedRow
              return (
                <tr
                  key={`tr-${index}`}
                  className={classnames({ canBeSelected, selectedRow })}
                >
                  {isOperation &&
                    <td>
                      <div>
                        {item?.canBeDeleted &&
                          <Icon
                            className={classes.delete}
                            iconName='close2'
                            title='Удалить проводку'
                            onClick={() => setDeletedItemId(item.id)}
                          />
                        }
                      </div>
                    </td>
                  }
                  {onSelect &&
                    <td>
                      <div>
                        <Checkbox
                          id={item?.id}
                          className={classes.checkbox}
                          checked={isChecked(item?.id)}
                          onChange={onSelectCheckbox}
                          title={isChecked(item?.id) ? 'Снять выбор' : 'Выбрать'}
                        />
                      </div>
                    </td>
                  }
                  {dataItems.map((props, index) =>
                    <RowValue
                      key={index}
                      item={item}
                      onClick={() => onOpen && onOpen({
                        id: item.id,
                        number: item.number,
                      })}
                      {...props}
                    />
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {deletedItemId &&
        <ConfirmModal
          title={`Вы действительно хотите удалить проводку?`}
          submitTitle='Удалить'
          onCancel={() => setDeletedItemId(null)}
          onSubmit={onDeleteFactoringOperation}
        />
      }
    </>
  )
}