import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { sendFactoringMessage } from '../../../../redux/Factoring/actions'
import { Textarea } from '../../../../layout'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'

export const AddMessageForm = ({
  entityType,
  onClose,
  onGetFactoringMessages,
}) => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const [message, setMessage] = useState('')

  const onChangeMessage = (e) => setMessage(e.target.value)

  const onSendMessage = () => {
    const payload = [
      {
        entityId: id,
        entityType: entityType.toUpperCase(),
        message,
        visibleTo: [],
      }
    ]
    const onSuccess = () => {
      onGetFactoringMessages()
      onClose()
    }
    dispatch(sendFactoringMessage(payload, onSuccess))
  }

  return (
    <>
      <H3>Добавить комментарий:</H3>
      <Form>
        <Textarea
          value={message}
          onChange={onChangeMessage}
          autoFocus
        />
      </Form>
      <ButtonWrapper>
        <Button onClick={onSendMessage}>Отправить</Button>
      </ButtonWrapper>
    </>
  )
}