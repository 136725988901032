import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { logoutUser } from '../../redux/User/actions'
import { Icon } from '../../layout'
import { THEME_COLOR } from '../../config'

const styles = {
  root: {
    // background: '#FF9A8D',
    background: THEME_COLOR,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  logo: {
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    left: '70px',
    top: '70px',
    '@media (max-width: 768px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      left: 'auto',
      top: 'auto',
      marginTop: '30px',
    },
  },
  error: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  message: {
    width: '700px',
    marginTop: '60px',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    '@media (max-width: 768px)': {
      width: '280px',
      marginTop: '30px',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  logout: {
    marginTop: '20px',
    color: '#fff',
    borderBottom: '1px solid #fff',
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
  },
  icon: {
    width: '150px',
    height: '150px',
    '@media (max-width: 768px)': {
      width: '100px',
      height: '100px',
    },
  },
}

const ErrorPage = ({ 
  classes,
  authType,
  logoutUser, 
}) => {
  const onLogout = () => logoutUser(authType)
  
  return (
    <div className={classes.root}>
      <div className={classnames(classes.logo, 'verify-logo')} />
      <div className={classes.error}>
        <Icon iconName='sad-face' className={classes.icon} />
        <div className={classes.message}>
          Авторизация не удалась. Обратитесь к администратору.
          <br/>
          <div className={classes.logout} onClick={onLogout}>Выход</div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ User }) => {
  return {
    authType: User.settings.authType,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logoutUser: (authType) => {
      dispatch(logoutUser(authType))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(ErrorPage))