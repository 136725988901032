import axios from 'axios'
import { API_URL } from '../../../config'

export const getReportTasks = async ({ process, dateFrom, dateTo, groups }) => {
  try {
    const groupsString = groups.reduce((result, item) => `${result}&groups[]=${item}`, '')
    const paramsString = `orderTypeRefId=${process}&dateFrom=${dateFrom}&dateTo=${dateTo}${groupsString}`
    const { data: { error_code, result, error } } = await axios({
      method: 'GET',
      url: `${API_URL}/report/common?${paramsString}`,
    })
    if (error_code === 0) {
      return {
        isSuccess: true,
        result,
      }
    }
    return {
      isSuccess: false,
      needLogout: error_code === 5,
      message: error,
    }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getReportTasksDetails = async ({
  process,
  dateFrom,
  dateTo,
  processDefinitionKey,
  taskDefinitionKey,
  groups,
}) => {
  try {
    const groupsString = groups.reduce((result, item) => `${result}&groups[]=${item}`, '')
    const paramsString = `orderTypeRefId=${process}&dateFrom=${dateFrom}&dateTo=${dateTo}&processDefinitionKey=${processDefinitionKey}&taskDefinitionKey=${taskDefinitionKey}${groupsString}`
    const { data: { error_code, result, error } } = await axios({
      method: 'GET',
      url: `${API_URL}/report/commonTasks?${paramsString}`,
    })
    if (error_code === 0) {
      return {
        isSuccess: true,
        result,
      }
    }
    return {
      isSuccess: false,
      needLogout: error_code === 5,
      message: error,
    }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getReportTasksTasks = async ({ process }) => {
  try {
    const { data: { error_code, result, error } } = await axios({
      method: 'GET',
      url: `${API_URL}/report/getGroups?orderTypeRefId=${process}`,
    })
    if (error_code === 0) {
      return {
        isSuccess: true,
        result: result.map(id => ({ id })),
      }
    }
    return {
      isSuccess: false,
      needLogout: error_code === 5,
      message: error,
    }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}