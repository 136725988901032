import React from 'react'
import { Conditions, transformFilterQuery } from '../../utils'

import './CompanyNameFilter.style.scss'

const condition = Conditions.LIKE

export function CompanyNameFilter({ onChangeFilter, resetFilter, name }) {
  const [value, setValue] = React.useState('')

  const handleChangeValue = React.useCallback(
    event => {
      onChangeFilter &&
        onChangeFilter(
          transformFilterQuery(condition, event.target.value, name)
        )
      setValue(event.target.value)
    },
    [onChangeFilter, name]
  )

  React.useEffect(() => {
    if (resetFilter) {
      setValue('')
    }
  }, [resetFilter])

  return (
    <input
      type="text"
      className="company-name-filter-input"
      value={value}
      placeholder="Введите название"
      autoComplete="off"
      onChange={handleChangeValue}
    />
  )
}
