import { combineReducers } from 'redux'
import { AnalysisReducer } from './Analysis/reducer'
import { CommonStatusReducer } from './CommonStatus/reducer'
import { FundsReducer } from './Funds/reducer'
import { StandardsReducer } from './Standards/reducer'
import { TasksReportReducer } from './Tasks/reducer'
import { FunnelReducer } from './Funnel/reducer'

export default combineReducers({
  CommonStatus: CommonStatusReducer,
  Standards: StandardsReducer,
  Analysis: AnalysisReducer,
  Funds: FundsReducer,
  Tasks: TasksReportReducer,
  Funnel: FunnelReducer,
})