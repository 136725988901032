import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { ReportContent } from './ReportContent'
import { FactoringWrapper } from '../FactoringWrapper'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

const useStyles = createUseStyles({
  reports: {
    padding: [20, 0],
  },
  tabs: {
    marginTop: 20,
  },
})

export const FactoringReportsPages = () => {
  const classes = useStyles()
  const { reports } = useSelector(state => state.Settings)
  const ITEMS = reports?.reports || []
  return (
    <FactoringWrapper
      title='Отчёты'
      dataFilters={[]}
      entityType={''}
      onRequest={()=>{}}
      requestPayload={{}}
      autoHeight
    >
      <div className={classes.reports}>
        <Tabs className={classes.tabs}>
          <TabList>
            {ITEMS.map(({ name }, index) =>
              <Tab key={index}>{name}</Tab>
            )}
          </TabList>
          {ITEMS.map(({ description, url, filters }, index) =>
            <TabPanel key={index}>
              <ReportContent
                description={description}
                url={url}
                filters={filters}
              />
            </TabPanel>
          )}
        </Tabs>
      </div>
    </FactoringWrapper>
  )
}