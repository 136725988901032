import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getCompanyManagementInfo } from '../../../../redux/Company/actions'
// import * as utils from '../utils'
import { Attributes, Attribute } from '../Helpers/Attributes'
import Overlay from '../../../Overlay'
import { User } from '../Helpers/User'
import { Passport } from '../Helpers/Passport'

export class Management extends Component {
  async loadData() {
    const { dispatch, companyId } = this.props
    dispatch(getCompanyManagementInfo(companyId))
  }

  componentDidMount() {
    if (!this.props.fetching) {
      this.loadData()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.loadData()
    }
  }

  render() {
    const { management:  data = {}, fetching } = this.props

    return fetching
      ? <Overlay/>
      : <div className='block-info'>
          <div className='row'>
            <Attribute
              col='12'
              attr={{
                label: 'Высший орган управления',
                value: _.get(data, 'supremeGoverningBody'),
              }}
            />
            <Attribute
              col='12'
              attr={{
                label: 'Единоличный исполнительный орган',
                value: _.get(data, 'soleExecutiveBody'),
              }}
            />
          </div>
          {!!data.managementCompany
            ? <>
                <div className='block_head'>Сведения об управляющей компании</div>
                <div className='row'>
                  <Attribute
                    col='6'
                    attr={{
                      label: 'Компания',
                      value: _.get(data, 'managementCompany.displayName'),
                    }}
                  />
                  <Attribute
                    col='6'
                    attr={{
                      label: ' ИНН',
                      value: _.get(data, 'managementCompany.INN'),
                    }}
                  />
                </div>
                <br/>
              </>
            : null
          }
          {!!data.hasSupervisoryBoard
            ? <>
                <div className='block_head'>Наблюдательный совет</div>
                <div className='row'>
                  <Attribute
                    col='12'
                    attr={{
                      label: 'Наименование наблюдательного совета',
                      value: _.get(data, 'supervisoryBoardName'),
                    }}
                  />
                </div>
                <br/>
                <div className='block_head'>Члены наблюдательного совета</div>
                {(data.supervisoryBoardMember || []).map((member, index) =>
                  <Fragment key={index}>
                    <div className='block_head'>№ {index + 1}</div>
                    <User user={member}/>
                    <Passport passport={_.get(member, 'identityDocuments[0]')}/>
                    <br/>
                    <Attributes {..._.get(member, 'personAttributes')}/>
                    <br/>
                    <Attributes {..._.get(member, 'relationAttributes')}/>
                  </Fragment>
                )}
                <br/>
              </>
            : null
          }
          {!!data.hasCollegialExecutiveBody
            ? <>
                <div className='block_head'>Коллегиальный исполнительный орган</div>
                <div className='row'>
                  <Attribute
                    col='12'
                    attr={{
                      label: 'Наименование коллегиального исполнительного органа',
                      value: _.get(data, 'collegialExecutiveBodyName'),
                    }}
                  />
                </div>
                <br/>
                <div className='block_head'>Члены коллегиального исполнительного органа</div>
                {(data.collegialExecutiveBodyMember || []).map((member, index) =>
                  <Fragment key={index}>
                    <div className='block_head'>№ {index + 1}</div>
                    <User user={member}/>
                    <Passport passport={_.get(member, 'identityDocuments[0]')}/>
                    <br/>
                    <Attributes {..._.get(member, 'personAttributes')}/>
                    <br/>
                    <Attributes {..._.get(member, 'relationAttributes')}/>
                  </Fragment> 
                )}
                <br/>
              </>
            : null
          }
          <Attributes {...data}/>
        </div>
  }
}

const mapStateToProps = ( { Company }) => {
  return {
    ...Company.managementInfo
  }
}

export default connect(mapStateToProps)(Management)