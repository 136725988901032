import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { getCompanyOrder } from '../../../redux/Company/actions'
import { Modal, UI, Icon } from '../../../layout'
import Overlay from '../../Overlay'
import StartSubProcess from '../../StartSubProcess'
import { plural, formattedNumber, formattedDate } from '../../../utils'
import { IS_LIME } from '../../../config'
import { Copy } from '../../Copy/Copy'

const styles = {
  modalDeal: {
    width: '100%',
    height: '100%',
  },
  wrapper: {
    paddingBottom: 30,
  },
  subprocesses: {
    position: 'absolute',
    top: -7,
    right: 0,
    '& > div': {
      display: 'inline-block',
      verticalAlign: 'top',
      '& + div': {
        marginLeft: 10,
      },
    },
  },
  status: {
    height: 23,
    margin: [1.5, 0, 1.5, 40],
    padding: [5, 6],
    color: '#1F7FDE',
    fontSize: 13,
    lineHeight: 1,
    fontWeight: 600,
    letterSpacing: '0.01em',
    border: [1, 'solid', '#1F7FDE'],
    borderRadius: 4,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  retry: {
    ...UI.addButton,
    minHeight: 35,
    padding: [5, 10, 4, 10],
    '& > svg': {
      width: 24,
      height: 24,
      fill: '#fff',
      display: 'block',
    },
  },
}

const ModalDeal = ({
  classes,
  orderId,
  order = {},
  fetching,
  dispatch,
  onClose,
  companyId,
  process_definition_keys,
}) => {

  const [filesHistory, setFilesHistory] = useState([])

  const onGetCompanyOrder = useCallback(() => {
    dispatch(getCompanyOrder(orderId))
  }, [dispatch, orderId])

  const onGetDocapiHistory = useCallback(async () => {
    const response = await fetch(`/docapi/history/${orderId}/guaranteeLot`)
    if (response?.ok) {
      const data = await response.json()
      setFilesHistory(data)
    }
  }, [orderId])

  useEffect(onGetCompanyOrder, [onGetCompanyOrder])
  useEffect(() => {
    IS_LIME && onGetDocapiHistory()
  }, [onGetDocapiHistory])

  const {
    title,
    orderNumber,
    orderTypeRefId,
    bankguarantee = {},
    bankguaranteeReissue = {},
    bankguaranteeLimit = {},
    principal,
    agentCompany,
    documents = [],
    principalDocuments = [],
    scoring,
    comments = [],
    tasks = [],
    parameters = [],
    lostReason,
    emailToken,
    orderStatusCategory = {},
    originalOrder,
    commissionAmount,
    installment,
    guarantorCompanyDocuments = [],
    guarantorPersonDocuments = [],
    rkoTariff = {},
    rkoFilial = {},
    productType = {},
    businessSegment = {},
    initiatorChannel,
    partnerDouble,
    isIncident,
    eis,
    uaeInfo = {},
  } = order
  const INN = (principal && principal.INN) || '-'
  const KPP = (principal && principal.KPP) || '-'
  const displayName = (principal && principal.displayName) || '-'

  const agentINN = (agentCompany && agentCompany.INN) || '-'
  const agentKPP = (agentCompany && agentCompany.KPP) || '-'
  const agentDisplayName = (agentCompany && agentCompany.displayName) || '-'
  const agentCompanyId = (agentCompany && agentCompany.id) || ''

  const isGuarantee = orderTypeRefId === 'bankguarantee'
  const isReissue = orderTypeRefId === 'bankguaranteeReissue'
  const isLimits = orderTypeRefId === 'bankguaranteeLimit'
  const isRko = orderTypeRefId === 'rko'
  const isRkoUae = orderTypeRefId === 'rko-uae'
  const lostReasonParams = lostReason
    ? {
        name: 'Причина отказа',
        attributes: [
          {
            name: 'Причина отказа',
            type: 'string', value: lostReason, },
        ],
      }
    : null

  // const isAgentRegistration = orderTypeRefId === 'agentRegistration'
  // const isAgentBilling = orderTypeRefId === 'agentBilling'

  const {
    durationDays,
    endDate,
    federalLaw,
    amount = 0,
    bankCommissionAmount = 0,
    defaultBankCommissionAmount = null,
    maxAmount = 0,
    lot = [],
    purchaseMarketPlace,
    purchaseNumber,
    purchaseLink,
    purchaseSubject,
    purchaseTenderType,
    // requiredExecutionDate,
    startDate,
    type,
    calculatedAmount,
    finalAmount,
  } = isLimits
    ? bankguaranteeLimit
    : isReissue
      ? bankguaranteeReissue
      : isGuarantee
        ? bankguarantee
        : {}

  const contentRko = {
    info: [
      {
        items: [
          { title: 'Сегмент бизнеса', value: businessSegment.name || '—' },
          { title: 'Тариф', value: rkoTariff.name || '—' },
          { title: 'Продукт', value: productType.name || '—' },
          { title: 'Город', value: rkoFilial.city || '—' },
          { title: 'Офис', value: rkoFilial.code ? <span>{rkoFilial.code}<br/>{rkoFilial.address}</span> : '—' },
          { title: 'Канал', value: initiatorChannel || '—' },
          { title: 'Партнерский дубль', value: partnerDouble ? <span><font color="red">{partnerDouble}</font></span> : '—' }
        ]
      }
    ]
  }

  const getPurchaseLink = (purchaseLink, purchaseNumber) => purchaseLink
    ? purchaseLink
    : purchaseNumber.length === 11
      ? `https://zakupki.gov.ru/223/purchase/public/purchase/info/common-info.html?regNumber=${purchaseNumber}`
      : purchaseNumber.length === 19
        ? `https://zakupki.gov.ru/epz/order/notice/ea44/view/common-info.html?regNumber=${purchaseNumber}`
        : '#'

  const content = {
    info: [
      {
        items: [
          { title: 'Вид банковской гарантии', value: type || '-', },
        ],
      },
      {
        items: [
          { title: 'Реестровый № торгов', value: purchaseNumber
            ?
                <Copy successText={'Реестровый № торгов скопирован'} valueToCopy={purchaseNumber}>
                  <a
                    href={getPurchaseLink(purchaseLink, purchaseNumber)}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {purchaseNumber}
                  </a>
                </Copy>
            : '-'
          },
          {
            title: (
              parameters &&
              parameters[0] &&
              parameters[0].attributes &&
              parameters[0].attributes[0] &&
              parameters[0].attributes[0].name
            ) || 'Тип аукциона',
            value: (
              parameters &&
              parameters[0] &&
              parameters[0].attributes &&
              parameters[0].attributes[0] &&
              parameters[0].attributes[0].value
            ) || '-',
            right: true,
          },
        ],
      },
      {
        items: [
          { title: 'Площадка', value: purchaseMarketPlace || '-', },
          { title: 'Закон', value: federalLaw || '-', right: true, },
        ],
      },
      {
        items: [
          {
            title: 'Предмет конракта / предмет закупки',
            value: purchaseSubject || '-',
          },
        ],
      },
      {
        items: [
          {
            title: 'Тип закупки',
            value: purchaseTenderType || '-',
          },
          {
            title: 'Предложенная цена',
            value: finalAmount ? `${formattedNumber(finalAmount)} ₽` : '-',
            right: true,
          },
        ],
      },
      {
        items: [
          {
            title: defaultBankCommissionAmount ? 'Рассчитанная комиссия' : '',
            value: defaultBankCommissionAmount ? `${formattedNumber(defaultBankCommissionAmount)} ₽` : '',
          },
          {
            title: 'Стоимость гарантии',
            value: bankCommissionAmount ? `${formattedNumber(bankCommissionAmount)} ₽` : '-',
            right: true,
          },
        ],
      },
    ],
    data: [
      {
        title: 'Дата начала',
        value: startDate ? formattedDate(startDate) : '-',
      },
      {
        title: 'Дата окончания',
        value: endDate ? formattedDate(endDate) : '-',
      },
      {
        title: 'Срок бг',
        value: durationDays ? `${durationDays} ${plural(durationDays, 'день', 'дня', 'дней')}` : '-',
      },
      {
        title: 'Итого НМЦ',
        value: maxAmount ? `${formattedNumber(maxAmount)} ₽` : '-',
      },
      {
        title: 'Итоговая гарантия',
        value: amount ? `${formattedNumber(amount)} ₽` : '-',
      },
    ],
    lots: lot,
    eis,
    documents: documents.filter(item => item.lot),
  }

  const onRetry = async () => {
    const response = await fetch(`/pa/bg-pa/api/incident/retry/${orderId}`)
    if (response?.ok && response?.status === 200) {
      onGetCompanyOrder()
    }
  }

  if (isRkoUae) {
    const oaeLostReasonParams = lostReason ? {
      name: 'Причина отказа / Rejection reason',
      attributes: [
        {
          name: 'Причина отказа / Rejection reason',
          type: 'string',
          value: lostReason
        }
      ]
    }: null

    const uaeClientInfo = {
      info: [
        {
          items: [
            { title: 'Наименование клиента / Customer\'s name', value: uaeInfo.clientName || '—' },
            { title: 'Регистрационный номер / Registration number', value: uaeInfo.regNumber || '—' }
          ]
        }
      ]
    }

    const uaeApplicationInfo = {
      info: [
        {
          items: [
            { title: 'Сегмент бизнеса / Business segment', value: uaeInfo.businessSegment || '—' },
            { title: 'Дата решения Клиентского комитета / Date of committee decision', value: uaeInfo.dateOfCommitteeDecision || '—' },
            { title: 'Продукт / Product', value: uaeInfo.productType || '—' },
            { title: 'Валюта / Currency', value: uaeInfo.currency || '—' },
            { title: 'Канал / Channel', value: uaeInfo.channel || '—' },
            { title: 'Менеджер привлечения / Attraction manager', value: uaeInfo.attractionManager || '—' },
            { title: 'Наименование партнера / Partner name', value: uaeInfo.partnerName || '—' }
          ]
        }
      ]
    }

    const uaeContactInfo = {
      info: [
        {
          items: [
            { title: 'Контактное лицо / Contact person', value: uaeInfo.contactPerson || '—' },
            { title: 'Язык / Language', value: uaeInfo.language || '—' },
            { title: 'Телефон / Mobile', value: uaeInfo.phone || '—' },
            { title: 'Эл. почта / Email', value: uaeInfo.email || '—' }
          ]
        }
      ]
    }

    return (
       <Modal
         className={classes.modalDeal}
         width='84vw'
         onClose={onClose}
       >
         {fetching
           ? <Overlay />
           : <div className={classes.wrapper}>
               <Modal.H1>
                 {orderNumber}
               </Modal.H1>
               <Modal.Info
                 content={uaeClientInfo}
                 title='Клиент / Client'
                 iconName='shield'
               />
               <Modal.Info
                 content={uaeApplicationInfo}
                 title='Параметры заявки / Application parameters'
                 iconName='shield'
               />
               <Modal.Info
                 content={uaeContactInfo}
                 title='Контактная информация / Contact Information'
                 iconName='shield'
               />
               <Modal.Activities
                 items={tasks}
                 title='Активности / Activities'
               />
               {oaeLostReasonParams &&
                 <Modal.Parameters parameters={[oaeLostReasonParams]} />
               }
               <Modal.Comments
                 items={comments}
                 title='Комментарии / Comments'
               />
               <Modal.Scorings
                 data={scoring}
                 title='Скоринги / Scorings'
               />
               <Modal.Documents
                 title='Документы / Documents'
                 documents={documents}
                 orderId={orderId}
               />
           </div>
         }
       </Modal>
     )
  }

  return (
    <Modal
      className={classes.modalDeal}
      width='84vw'
      onClose={onClose}
    >
      {fetching
        ? <Overlay />
        : <div className={classes.wrapper}>
            <Modal.H1>
              <Copy valueToCopy={orderNumber} successText={'Номер заявки скопирован'}>
                {title}
              </Copy>
              {orderStatusCategory?.orderStatus?.name &&
                <span className={classes.status}>
                  {orderStatusCategory.orderStatus.name}
                </span>
              }
              <div className={classes.subprocesses}>
                {!!isIncident?.exceptionMessage &&
                  <div className={classes.retry} onClick={onRetry}>
                    <Icon iconName='retry' />
                  </div>
                }
                <StartSubProcess
                  variable={{
                    name: 'orderId',
                    value: orderId,
                  }}
                  orderTypeRefId={orderTypeRefId}
                  process_definition_keys={process_definition_keys}
                />
              </div>
            </Modal.H1>
            {originalOrder &&
              <Modal.OriginalBg
                orderId={originalOrder.orderId}
                orderType={originalOrder.orderType || null}
                orderNumber={originalOrder.orderNumber}
                orderCommissionAmount={originalOrder.orderCommissionAmount}
              />
            }
            {commissionAmount &&
              <Modal.Commission
                commissionAmount={commissionAmount}
              />
            }
            <Modal.Principal
              name={displayName}
              inn={INN}
              kpp={KPP}
              companyId={companyId || principal.id}
              orderTypeRefId={orderTypeRefId}
            >
              {isLimits &&
              <Modal.Limits
                calculatedAmount={calculatedAmount}
                finalAmount={finalAmount}
              />
            }
            </Modal.Principal>
            {(isGuarantee || isReissue) &&
              <Modal.Info
                content={content}
                title='Сведения о закупке / контракте'
              />
            }
            {isRko &&
              <Modal.Info
                content={contentRko}
                title='Параметры заявки'
              />
            }
            {/* {parameters && parameters[0] &&
              <Modal.Parameters parameters={[parameters[0]]} />
            } */}
            <Modal.Activities items={tasks} />
            <Modal.Comments items={comments} />
            {agentCompanyId &&
              <Modal.AgentCompany
                name={agentDisplayName}
                inn={agentINN}
                kpp={agentKPP}
                companyId={agentCompanyId}
              />
            }
            {parameters && parameters[1] &&
              <Modal.Parameters parameters={[parameters[1]]} />
            }
            {lostReasonParams &&
              <Modal.Parameters parameters={[lostReasonParams]} />
            }
            <Modal.Scorings data={scoring} />
            <Modal.Installment
              title='Информация о рассрочке'
              installment={installment}
              onGetCompanyOrder={onGetCompanyOrder}
            />
            <Modal.Documents
              title='Документы по сделке'
              documents={documents.filter(item => !item.lot)}
              emailToken={emailToken}
              orderId={orderId}
            />
            <Modal.Documents
              title='История макетов Гарантии'
              documents={filesHistory}
            />
            <Modal.Documents
              title='Документы принципала'
              documents={principalDocuments}
            />
            {guarantorCompanyDocuments?.length > 0 && guarantorCompanyDocuments.map((item, index) =>
              <Modal.GuarantorDocuments
                key={index}
                guarantorType='ЮЛ'
                {...item}
              />
            )}
            {guarantorPersonDocuments?.length > 0 && guarantorPersonDocuments.map((item, index) =>
              <Modal.GuarantorDocuments
                key={index}
                guarantorType='ФЛ'
                {...item}
              />
            )}
        </div>
      }
    </Modal>
  )
}

const mapStateToProps = ({ Company }, ownProps) => {
  const { order, fetching } = Company.order
  const orderId = ownProps.orderId || ownProps.match.params.orderId

  return {
    orderId,
    order,
    fetching,
  }
}

export default connect(mapStateToProps)(injectSheet(styles)(ModalDeal))