import React, { useState, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import { createPortal } from 'react-dom'
import Close from './Close'
import { isPressEscape } from '../../../utils'

const useStyles = createUseStyles({
  modalWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 5000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:before': {
      content: '""',
      display: 'block',
      background: '#1e1e1e',
      opacity: 0.9,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 4000,
    },
  },
  wrapper: {
    minWidth: 1100,
    width: '84vw',
    position: 'fixed',
    top: 6,
    bottom: 6,
    borderRadius: 16,
    overflowY: 'auto',
    zIndex: 5000,
  },
  modal: {
    background: '#fff',
    maxHeight: '100%',
    maxWidth: ' 100%',
    height: 'auto',
    padding: 30,
    overflowY: 'auto',
    borderRadius: 16,
    // position: 'absolute',
    // left: '50%',
    // top: '50%',
    // transform: 'translate(-50%, -50%)',
    '&.fixWidth': {
      width: '100%',
    },
    '&.fixHeight': {
      overflowY: 'visible',
    },
  },
})

export const Modal = ({ 
  children,
  className,
  width,
  height,
  onClose,
}) => {
  const classes = useStyles()

  const [position, setPosition] = useState({})

  const modalNode = document.createElement('div')
  const root = document.getElementById('root')
  modalNode.classList.add(classes.modalWrapper)

  useEffect(() => {
    document.body.insertBefore(modalNode, root)

    return () => document.body.removeChild(modalNode)
  })

  const CSS = { 
    width,
    height,
    ...(height ? { top: 'auto', bottom: 'auto' } : {}),
    ...(width ? { minWidth: width } : {}),
  }

  const modalRef = useRef(null)

  useEffect(() => {
    const { top, left, width } = modalRef.current.getBoundingClientRect()
    setPosition({ 
      top: `${top}px`, 
      left: `${left + width + 15}px`,
      transform: 'none', 
    })
  }, [modalRef])

  const onEscape = (e) => {
    if (isPressEscape(e)) {
      onClose && onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', onEscape, false)
    return () => {
      document.removeEventListener('keyup', onEscape, false)
    }
  })
  
  return createPortal(
    <>
      <div className={classes.wrapper} style={CSS}>
        <div ref={modalRef} className={classnames(classes.modal, className, { 
          'fixWidth': width,
          'fixHeight': height, 
        })}>
          {children}
        </div>
      </div>
      <Close onClose={onClose} style={position} />
    </>,
    modalNode
  )
}