import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  title: {
    fontSize: 26,
    lineHeight: 32/26,
    fontWeight: 600,
    color: '#504E5B',
    leterSpacing: '1px',
  },
})

export const Title = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.title}>{children}</div>
  )
}