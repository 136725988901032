import React from 'react'
import { Link } from 'react-router-dom'
import { Modal } from '../index'
import { formattedNumberRub } from '../../../utils'

const OriginalBg = ({
  orderId,
  orderType,
  orderNumber,
  orderCommissionAmount,
}) =>
  <Modal.Block iconName='shield' title='Исходная заявка'>
    <Modal.Content>
      <Modal.Title>Номер исходной заявки</Modal.Title>
      <Modal.Value>
        <Link
          to={`/orders/${orderId}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {orderType
            ? `${orderType} №`
            : null
          }
          {orderNumber}
        </Link>
      </Modal.Value>
      {orderCommissionAmount && 
        <>
          <br/>
          <Modal.Title>Комиссия исходной заявки</Modal.Title>
          <Modal.Value>{formattedNumberRub(orderCommissionAmount)} ₽</Modal.Value>
        </>
      }
    </Modal.Content>
  </Modal.Block>

export default OriginalBg