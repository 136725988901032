import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { completeFactoringTask } from '../../../redux/Factoring/actions'
import { Toast } from '../../../components/Toast'
import { Button } from '../Layout'
import { useStyles } from './styles'
import { CommissionSetCounter } from './CommissionSetCounter'

export const Actions = memo(({
  drafts,
  queue,
  setQueue,
  refetch,
  setRefetch,
  selectedTasks,
  ordersQueue,
  onResetSelect,
  onAutoDistributePayment,
  isPaymentReplications,
  firstSelectedOrdersType
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const totalDraftsMatchedAmount = drafts?.reduce((result, item) => result + item?.matchedAmount, 0)
  const totalOrdersUnmatchedAmount = ordersQueue?.reduce((result, item) => result + item?.unmatchedAmount, 0)
  const totalSelectedTasksUnmatchedAmount = selectedTasks?.reduce((result, item) => result + item?.data?.payment.unmatchedAmount, 0)

  const showDistributePaymentButton = ordersQueue?.length > 0

  const onSubmit = () => {
    const draftsTotalMatchedAmount = drafts.reduce((result, item) => result + parseFloat(item.matchedAmount), 0)
    const onCallback = () => {
      const newQueue = [...queue]
      newQueue.shift()
      setQueue(newQueue)
      if (newQueue.length === 0) {
        onResetSelect()
      }
    }
    const onSuccess = () => {
      Toast({
        type: 'success',
        message: `Завершение задачи платежей прошло успешно`,
      })
      onCallback()
      setRefetch(refetch + 1)
    }
    const onError = (error) => {
      Toast({
        type: 'error',
        message: error || `Завершение задачи платежей не удалось`,
      })
      onCallback()
    }
    drafts.forEach(draft => {
      delete draft.woLeftovers
      if (!draft?.matchingReason) {
        draft.matchingReason = 'COMMON'
      }
    })
    if (selectedTasks?.length === 1) {
      if (draftsTotalMatchedAmount > totalSelectedTasksUnmatchedAmount) {
        Toast({
          type: 'error',
          message: 'Сумма квитовки превышает остаток по платежу',
        })
      } else {
        dispatch(completeFactoringTask(selectedTasks[0]?.id, 'PAYMENT', drafts, onSuccess, onError))
      }
    } else {
      selectedTasks.forEach((task, index) =>
        dispatch(completeFactoringTask(task?.id, 'PAYMENT', [drafts[index]], onSuccess, onError))
      )
    }
  }

  return (
    <div className={classes.info}>
      <div className={classes.infoItem}>&nbsp;</div>
      <div className={classes.infoItem}>
        <div style={{display: 'flex'}}>
          <CommissionSetCounter
            totalDraftsMatchedAmount={totalDraftsMatchedAmount}
            totalOrdersUnmatchedAmount={totalOrdersUnmatchedAmount}
            totalSelectedTasksUnmatchedAmount={totalSelectedTasksUnmatchedAmount}
          />
          <Button
            onClick={() => onAutoDistributePayment()}
            disabled={firstSelectedOrdersType === 'NonDeliverableTransit' ? true : !showDistributePaymentButton}
          >
            Связать
          </Button>
          {!isPaymentReplications &&
            <Button
              onClick={firstSelectedOrdersType === 'NonDeliverableTransit' ? () => onAutoDistributePayment() : onSubmit}
              disabled={firstSelectedOrdersType === 'NonDeliverableTransit' ? false : !drafts?.length}
            >
              Квитовать
            </Button>
          }
        </div>
      </div>
    </div>
  )
})