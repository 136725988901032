class ModalWrapperService {
  setNewModalState
  component

  setComponentUpdater(cb) {
    this.setNewModalState = cb
  }

  openModal(component, animateWrapper) {
    this.component = component
    this.setNewModalState({ component, isOpened: true, animateWrapper })
  }

  closeModal() {
    this.setNewModalState({
      component: this.component,
      isOpened: false,
      animateWrapper: true
    })
  }
}

export const modalWrapperService = new ModalWrapperService()
