import React, { useState } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { UI } from '../../layout'
import Modal from '../../containers/Modal'
import CamundaAddTask from '../../containers/Form/AddTask/Camunda'
import Overlay from '../Overlay'
import { companyStartSubprocess } from '../../redux/Company/actions'
import { ModalChangeLimits } from '../Modals'

const styles = {
  overlay: {
    borderRadius: 'inherit',
  },
  button: {
    ...UI.addButton,
    minHeight: 35,
    padding: [5, 10, 4, 10],
    display: 'block',
    '& + &': {
      marginTop: 8,
    },
    '& span': {
      color: 'transparent',
    },
  },
}

const StartSubProcess = ({
  classes,
  className,
  history,
  variable,
  orderTypeRefId,
  companyId,
  onlyChangeLimit,
}) => {
  const dispatch = useDispatch()

  const { name, value } = variable || {}

  const { company, process_definition_keys } = useSelector(state => state?.Settings)
  const { startSubProcess } = useSelector(state => state?.Company)
  const { subprocess } = company || {}
  const { id, fetching } = startSubProcess || {}

  const [processDefinitionKey, setProcessDefinitionKey] = useState('')
  const [isWizzard, setIsWizzard] = useState('')
  const [title, setTitle] = useState('')
  const [modalChangeLimitKey, setModalChangeLimitKey] = useState(null)

  const onOpenModalChangeLimit = (key) => setModalChangeLimitKey(key)
  const onCloseModalChangeLimit = () => setModalChangeLimitKey(null)

  const onCompanyStartSubprocess = ({
    payload,
    process_definition_key,
    onSuccess,
    ...rest
  }) => dispatch(companyStartSubprocess(payload, process_definition_key, onSuccess, rest))

  const onStartSubprocess = ({
    isWizzard,
    process_name,
    process_definition_key,
  }) => {
    if (!fetching) {
      const payload = {
        variables: {
          [name]: {
            value,
            type: 'String',
          }
        }
      }
      setProcessDefinitionKey(process_definition_key)
      setIsWizzard(isWizzard)
      setTitle(process_name)
      onCompanyStartSubprocess({ payload, process_definition_key })
    }
  }

  let subProcessList = []
  switch (name) {
    case 'companyId':
      subProcessList = subprocess
      break
    case 'orderId':
      const result = (process_definition_keys || []).find(item => 
        item.orderTypeRefId === orderTypeRefId && item.subprocess
      )
      if (result?.subprocess) {
        subProcessList = result.subprocess
      }
      break
    default: 
  }

  if (onlyChangeLimit) {
    subProcessList = (subProcessList || []).filter(({ process_definition_key }) =>
      process_definition_key === 'bg-pa-limit-change'
    )
  }

  return subProcessList?.length > 0
    ? <div className={className}>
        {subProcessList.map(({
          isWizzard,
          process_name,
          process_definition_key,
        }, index) =>
          <div 
            key={index} 
            className={classnames(classes.button, { 'disabled': fetching })} 
            onClick={() => process_definition_key === 'bg-pa-limit-change'
              ? onOpenModalChangeLimit(process_definition_key)
              : onStartSubprocess({ process_definition_key, isWizzard, process_name })}
          >
            {fetching 
              ? <>
                  <span>{process_name}</span>
                  <Overlay size='small' className={classes.overlay} />
                </>
              : process_name
            }
          </div>
        )}
        {id && processDefinitionKey && isWizzard &&
          <Modal
            topPosition
            modalClass='modal-custom--wide-width'
            preventOutsideClick
            onCloseModal={() => history.go(-2)}
          >
            <CamundaAddTask
              processInstanceId={id}
              isWizzard={isWizzard}
              title={title}
              activeDefinitionKey={processDefinitionKey}
              onCloseModal={() => history.go(-2)}
            />
          </Modal>
        }
        {modalChangeLimitKey &&
          <ModalChangeLimits
            companyId={companyId}
            process_definition_key={modalChangeLimitKey}
            onCompanyStartSubprocess={onCompanyStartSubprocess}
            onClose={onCloseModalChangeLimit}
          />
        }
      </div>
    : null
}

export default injectSheet(styles)(StartSubProcess)