export const asFieldModal = (
  label, 
  name, 
  value, 
  disabled = false,
) => {
  return {
    input: {
      name, 
      value,
    },
    label,
    disabled,
  }
}