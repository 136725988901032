import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: 60,
    borderRadius: 30,
    position: 'relative',
  },
  bar: {
    height: '100%',
    position: 'absolute',
    borderRadius: 'inherit',
    transition: 'width 1000ms ease',
    zIndex: 2,
    '&.total': {
      width: '100%',
    },
  },
})

export const Bar = ({
  COLORS,
  utilizedAmount,
  frozenAmount,
  calculatedTotalAmountState,
}) => {
  const classes = useStyles()

  const used = utilizedAmount && calculatedTotalAmountState
    ? (utilizedAmount / calculatedTotalAmountState) * 100
    : 0

  const frozen = frozenAmount && calculatedTotalAmountState
    ? (frozenAmount / calculatedTotalAmountState) * 100
    : 0

  return (
    <div className={classes.root}>
      <div
        className={classnames(classes.bar, 'total')}
        style={{ backgroundColor: COLORS.total }}
      />
      <div
        className={classes.bar}
        style={{
          backgroundColor: COLORS.frozen,
          width: `${used + frozen}%`,
        }}
      />
      <div
        className={classes.bar}
        style={{
          backgroundColor: COLORS.used,
          width: `${used}%`,
        }}
      />
    </div>
  )
}