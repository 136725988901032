import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyBuhInfo } from '../../../../redux/Company/actions'
import Overlay from '../../../Overlay'
import GroupTable from './GroupTable'
import groups from './groups'
import { StickyHead, Table, Td1, Td2, Td3 } from './Layout'
import { buhDataToTableDataCols } from './utils'

const Buh = ({ companyId }) => {
  const dispatch = useDispatch()
  
  const {
    buh = [],
    fetching,
  } = useSelector(state => state.Company.buhInfo)

  useEffect(() => {
    dispatch(getCompanyBuhInfo(companyId))
  }, [companyId, dispatch])

  const cols = buhDataToTableDataCols(buh)

  return fetching
    ? <Overlay/>
    : <>
        <StickyHead>
          <Table style={{marginTop: 0}}>
            <thead>
              <tr>
                <Td1 />
                <Td2>Код</Td2>
                {cols && cols.length > 0 && cols.map(({ id, label }) => 
                  <Td3 key={id}>{label}</Td3>
                )}
              </tr>
            </thead>
          </Table>
        </StickyHead>
        <div className='block-info'>
          {groups.map((group, index) => 
            <GroupTable key={`group-${index}`} group={group} cols={cols} />
          )}
        </div>
    </>
}

export default Buh