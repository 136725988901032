import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'
import { withKeycloak } from 'react-keycloak'
import { history } from './history'

import LayoutBase from './containers/Layout'
import SnackBar from './containers/SnackBar'
import LoginBase from './pages/Login'
import TasksPage from './pages/TasksPage'
import NotFound from './pages/NotFound'
import Charts from './pages/Charts'
import AnalyzeProcessing from './pages/AnylazieProcessing'
import OrdersPage from './pages/OrdersPage'
import MonitoringNotificationOrdersPage from './pages/MonitoringNotificationOrdersPage'
import { RegistersPage } from './pages/RegistersPage'
import TariffSchemesPage from './pages/TariffSchemesPage'
import ContractsPage from './pages/ContractsPage'
import {
  FactoringMatchingPaymentsPage,
  FactoringPage,
  FactoringViewPage,
  FactoringReportsPages,
  FactoringCalendarPage,
} from './pages/FactoringPages'
import { ErrorBoundary } from './components/ErrorBoundary'
import { getEnvironment } from './redux/Environment/actions'

export default ({ isUseKeycloak }) => {
  const dispatch = useDispatch()

  const Login = isUseKeycloak ? withKeycloak(LoginBase) : LoginBase
  const Layout = isUseKeycloak ? withKeycloak(LayoutBase) : LayoutBase

  useEffect(() => {
    dispatch(getEnvironment())
  }, [dispatch])

  return (
    <Router history={history}>
      <ErrorBoundary>
        <SnackBar />
          <Switch>
            <Route exact path='/' component={Login} />
            <Layout exact path='/tasks/:id' component={TasksPage} />
            <Layout path='/tasks' component={TasksPage} />
            <Layout path='/charts' component={Charts} />
            <Layout path='/analyze-processing' component={AnalyzeProcessing} />
            <Layout path='/orders' component={OrdersPage} />
            <Layout path='/monitoringNotificationOrders' component={MonitoringNotificationOrdersPage} />
            <Layout path='/payments' component={RegistersPage} />
            <Layout path='/tariff-schemes' component={TariffSchemesPage} />
            <Layout exact path='/contracts' component={ContractsPage} />
            <Layout exact path='/contracts/:id' component={ContractsPage} />
            <Layout exact path='/factoring/calendar' component={FactoringCalendarPage} />
            <Layout exact path='/factoring/reports' component={FactoringReportsPages} />
            <Layout exact path='/factoring/payment_matching' component={FactoringMatchingPaymentsPage} />
            <Layout exact path='/factoring/monitoring/issues' component={FactoringPage} />
            <Layout exact path='/factoring/monitoring/notifications' component={FactoringViewPage} />
            <Layout exact path='/factoring/monitoring/notifications/:id' component={FactoringViewPage} />
            <Layout exact path='/factoring/tasks/:taskDefinitionKey' component={FactoringPage} />
            <Layout exact path='/factoring/:entityType' component={FactoringViewPage} />
            <Layout exact path='/factoring/:entityType/:id' component={FactoringViewPage} />
            <Layout component={NotFound} />
          </Switch>
      </ErrorBoundary>
    </Router>
  )
}
