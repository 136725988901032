import * as actionTypes from './actionTypes'

const initialState = {
  tasks: {},
  views: {},
  shortInfo: {},
  messages: [],
  documents: [],
  deferSettings: {},
  rateTypes: [],
  rateConfig: [],
  blockTypes: [],
  operationTypes: [],
  accountTypes: [],
  offeringKeys: [],
  issueTypes: [],
  limitTypes: [],
  issueNotifications: {},
  duplicateSettings: [],
  priceMatrix: [],
  supplyCommissions: [],
  accountEntityTypes: [],
  masterOperationTypes: [],
  operationCodeTypes: [],
  suretyTypes: [],
  supplyContractsByFactoringContractId: [],
  supplyContractCommon: [],
  debtorsByFactoringContractId: [],
  paymentOrders: [],
  paymentComissions: [],
  paymentMatchings: [],
  paymentReplications: [],
  distributePayment: [],
  masterOperationId: '',
  counterPartyCommon: {},
  counterPartyAddress: {},
  counterPartyState: {},
  counterPartyInfo: {},
  counterPartySigner: [],
  counterPartyContact: {},
  factoringContractCommon: {},
  factoringContractMain: [],
  factoringContractContract: [],
  factoringContractAccount: [],
  factoringContractSurety: [],
  factoringContractTariff: [],
  factoringContractFeatures: [],
  accountEntities: [],
  constraintOrders: [],
  supplyCommonTab: {},
  supplyDocumentTab: [],
  supplyFinancingTab: [],
  monIssueNotificationTab: [],
  supplyRegistrySupplyTab: [],
  matchingReasons: [],
  holidaysByPeriod: [],
  operDays: [],
  accountNumber: [],
  supplyContract: [],
  accountCompany: [],
  isFetching: false,
  isFetchingCount: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FACTORING_TASKS:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_FACTORING_TASKS_SUCCESS:
      return {
        ...state,
        tasks: {
          items: action.data
        },
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_TASKS_ERROR:
      return { 
        ...state,
        tasks: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_TASKS_2:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_FACTORING_TASKS_SUCCESS_2: {
      const { entityType, filters: { offset, limit } } = action
      const { data, count } = action.data
      return {
        ...state,
        tasks: {
          items: data,
          entityType,
          totalCount: count,
          totalPages: Math.ceil(count / limit),
          size: limit,
          page: Number(offset) ? (offset / limit + 1) : 1,
        },
        isFetching: false,
      }
    }
    case actionTypes.GET_FACTORING_TASKS_ERROR_2: {
      return { 
        ...state,
        tasks: {},
        isFetching: false, 
      }
    }
    case actionTypes.GET_FACTORING_VIEW:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_FACTORING_VIEW_SUCCESS: {
      const { entityType, filters: { offset, limit } } = action
      const { data, count } = action.data
      return {
        ...state,
        views: {
          items: data,
          entityType,
          totalCount: count,
          totalPages: Math.ceil(count / limit),
          size: limit,
          page: Number(offset) ? (offset / limit + 1) : 1,
        },
        isFetching: false,
      }
    }
    case actionTypes.GET_FACTORING_VIEW_ERROR:
      return { 
        ...state,
        views: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_SHORT_INFO:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_FACTORING_SHORT_INFO_SUCCESS:
      return {
        ...state,
        shortInfo: {
          ...action.data,
          showFieldName: action.showFieldName,
        },
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_SHORT_INFO_ERROR:
      return { 
        ...state, 
        shortInfo: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_MESSAGES:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_FACTORING_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_MESSAGES_ERROR:
      return { 
        ...state,
        messages: [],
        isFetching: false, 
      }
    case actionTypes.SEND_FACTORING_MESSAGE:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.SEND_FACTORING_MESSAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case actionTypes.SEND_FACTORING_MESSAGE_ERROR:
      return { 
        ...state, 
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_DUPLICATE_SETTINGS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_DUPLICATE_SETTINGS_SUCCESS:
      return {
        ...state,
        duplicateSettings: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_DUPLICATE_SETTINGS_ERROR:
      return { 
        ...state,
        duplicateSettings: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_PRICE_MATRIX:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_PRICE_MATRIX_SUCCESS:
      return {
        ...state,
        priceMatrix: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_PRICE_MATRIX_ERROR:
      return { 
        ...state,
        priceMatrix: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_SUPPLY_COMMISSIONS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_SUPPLY_COMMISSIONS_SUCCESS:
      return {
        ...state,
        supplyCommissions: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_SUPPLY_COMMISSIONS_ERROR:
      return { 
        ...state,
        supplyCommissions: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_ACCOUNT_ENTITY_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_ACCOUNT_ENTITY_TYPES_SUCCESS:
      return {
        ...state,
        accountEntityTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_ACCOUNT_ENTITY_TYPES_ERROR:
      return { 
        ...state,
        accountEntityTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_SURETY_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_SURETY_TYPES_SUCCESS:
      return {
        ...state,
        suretyTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_SURETY_TYPES_ERROR:
      return { 
        ...state,
        suretyTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_RATE_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_RATE_TYPES_SUCCESS:
      return {
        ...state,
        rateTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_RATE_TYPES_ERROR:
      return { 
        ...state,
        rateTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_RATE_CONFIG:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_RATE_CONFIG_SUCCESS:
      return {
        ...state,
        rateConfig: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_RATE_CONFIG_ERROR:
      return { 
        ...state,
        rateConfig: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_BLOCK_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_BLOCK_TYPES_SUCCESS:
      return {
        ...state,
        blockTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_BLOCK_TYPES_ERROR:
      return { 
        ...state,
        blockTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_OPERATION_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_OPERATION_TYPES_SUCCESS:
      return {
        ...state,
        operationTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_OPERATION_TYPES_ERROR:
      return { 
        ...state,
        operationTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_ACCOUNT_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_ACCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        accountTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_ACCOUNT_TYPES_ERROR:
      return { 
        ...state,
        accountTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_OFFERING_KEYS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_OFFERING_KEYS_SUCCESS:
      return {
        ...state,
        offeringKeys: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_OFFERING_KEYS_ERROR:
      return { 
        ...state,
        offeringKeys: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_ISSUES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_ISSUES_SUCCESS:
      return {
        ...state,
        issueTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_ISSUES_ERROR:
      return { 
        ...state,
        issueTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_LIMIT_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_LIMIT_TYPES_SUCCESS:
      return {
        ...state,
        limitTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_LIMIT_TYPES_ERROR:
      return { 
        ...state,
        limitTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_MON_ISSUE_NOTIFICATIONS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_MON_ISSUE_NOTIFICATIONS_SUCCESS: {
      const { entityType, filters: { offset, limit } } = action
      const { data, count } = action.data
      return {
        ...state,
        issueNotifications: {
          items: data,
          entityType,
          totalCount: count,
          totalPages: Math.ceil(count / limit),
          size: limit,
          page: Number(offset) ? (offset / limit + 1) : 1,
        },
        isFetching: false,
      }
    }
    case actionTypes.GET_FACTORING_MON_ISSUE_NOTIFICATIONS_ERROR:
      return { 
        ...state,
        issueNotifications: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_MASTER_OPERATION_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_MASTER_OPERATION_TYPES_SUCCESS:
      return {
        ...state,
        masterOperationTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_MASTER_OPERATION_TYPES_ERROR:
      return { 
        ...state,
        masterOperationTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_DOCUMENTS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_DOCUMENTS_ERROR:
      return { 
        ...state,
        documents: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_DEFER_SETTINGS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_DEFER_SETTINGS_SUCCESS:
      return {
        ...state,
        deferSettings: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_DEFER_SETTINGS_ERROR:
      return { 
        ...state,
        deferSettings: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_OPERATION_CODE_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_OPERATION_CODE_TYPES_SUCCESS:
      return {
        ...state,
        operationCodeTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_OPERATION_CODE_TYPES_ERROR:
      return { 
        ...state,
        operationCodeTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_MATCHING_REASONS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_MATCHING_REASONS_SUCCESS:
      return {
        ...state,
        matchingReasons: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_MATCHING_REASONS_ERROR:
      return { 
        ...state,
        matchingReasons: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_PAYMENT_ORDERS:
      return { 
        ...state,
        isFetchingCount: state?.isFetchingCount + 1,
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_PAYMENT_ORDERS_SUCCESS:
      return {
        ...state,
        paymentOrders: action.isPaymentOrders ? action.data : state.paymentOrders,
        paymentComissions: action.isPaymentOrders ? state.paymentComissions : action.data,
        isFetchingCount: state?.isFetchingCount - 1,
        isFetching: state?.isFetchingCount - 1 === 0 ? false : true,
      }
    case actionTypes.GET_FACTORING_PAYMENT_ORDERS_ERROR:
      return { 
        ...state,
        paymentOrders: action.isPaymentOrders ? [] : state.paymentOrders,
        paymentComissions: action.isPaymentOrders ? state.paymentComissions : [],
        isFetchingCount: state?.isFetchingCount - 1,
        isFetching: state?.isFetchingCount - 1 === 0 ? false : true,
      }
    case actionTypes.RESET_FACTORING_PAYMENT_ORDERS:
      return {
        ...state,
        paymentOrders: [],
        paymentComissions: [],
        isFetchingCount: 0,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_MATCHINGS_BY_TASK_ID:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_MATCHINGS_BY_TASK_ID_SUCCESS:
      return {
        ...state,
        paymentMatchings: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_MATCHINGS_BY_TASK_ID_ERROR:
      return { 
        ...state,
        paymentMatchings: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_MATCHINGS_BY_PAYMENT_ID:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_SUCCESS:
      return {
        ...state,
        paymentReplications: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_ERROR:
      return { 
        ...state,
        paymentReplications: [],
        isFetching: false, 
      }
    case actionTypes.RESET_FACTORING_MATCHINGS_BY_PAYMENT_ID_SUCCESS:
      return {
        ...state,
        paymentReplications: [],
        isFetching: false,
      }
    case actionTypes.COMPLETE_FACTORING_TASK:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.COMPLETE_FACTORING_TASK_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case actionTypes.COMPLETE_FACTORING_TASK_ERROR:
      return { 
        ...state,
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_DISTRIBUTE_PAYMENT:
      return { 
        ...state, 
        isFetching: true,
        isFetchingCount: state?.isFetchingCount + 1,
      }
    case actionTypes.GET_FACTORING_DISTRIBUTE_PAYMENT_SUCCESS:
      return {
        ...state,
        distributePayment: action.data?.rows,
        isFetchingCount: state?.isFetchingCount - 1,
        isFetching: state?.isFetchingCount - 1 === 0 ? false : true,
      }
    case actionTypes.GET_FACTORING_DISTRIBUTE_PAYMENT_ERROR:
      return { 
        ...state,
        distributePayment: [],
        isFetchingCount: state?.isFetchingCount - 1,
        isFetching: state?.isFetchingCount - 1 === 0 ? false : true, 
      }
    case actionTypes.RESET_FACTORING_DISTRIBUTE_PAYMENT:
      return { 
        ...state,
        distributePayment: [],
        isFetching: state?.isFetchingCount === 0 ? false : true, 
      }
    case actionTypes.GET_FACTORING_MASTER_OPERATION:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_MASTER_OPERATION_SUCCESS:
      return {
        ...state,
        masterOperationId: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_MASTER_OPERATION_ERROR:
      return { 
        ...state,
        masterOperationId: '',
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_COMMON:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_COMMON_SUCCESS:
      return {
        ...state,
        counterPartyCommon: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_COMMON_ERROR:
      return { 
        ...state,
        counterPartyCommon: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_ADDRESS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_ADDRESS_SUCCESS:
      return {
        ...state,
        counterPartyAddress: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_ADDRESS_ERROR:
      return { 
        ...state,
        counterPartyAddress: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_STATE:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_STATE_SUCCESS:
      return {
        ...state,
        counterPartyState: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_STATE_ERROR:
      return { 
        ...state,
        counterPartyState: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_INFO:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_INFO_SUCCESS:
      return {
        ...state,
        counterPartyInfo: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_INFO_ERROR:
      return { 
        ...state,
        counterPartyInfo: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_SIGNER:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_SIGNER_SUCCESS:
      return {
        ...state,
        counterPartySigner: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_SIGNER_ERROR:
      return { 
        ...state,
        counterPartySigner: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_CONTACT:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_CONTACT_SUCCESS:
      return {
        ...state,
        counterPartyContact: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_COUNTERPARTY_CONTACT_ERROR:
      return { 
        ...state,
        counterPartyContact: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_COMMON:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_COMMON_SUCCESS:
      return {
        ...state,
        factoringContractCommon: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_COMMON_ERROR:
      return { 
        ...state,
        factoringContractCommon: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_MAIN:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_MAIN_SUCCESS:
      return {
        ...state,
        factoringContractMain: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_MAIN_ERROR:
      return { 
        ...state,
        factoringContractMain: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_CONTRACT:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_CONTRACT_SUCCESS:
      return {
        ...state,
        factoringContractContract: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_CONTRACT_ERROR:
      return { 
        ...state,
        factoringContractContract: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_ACCOUNT:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_ACCOUNT_SUCCESS:
      return {
        ...state,
        factoringContractAccount: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_ACCOUNT_ERROR:
      return { 
        ...state,
        factoringContractAccount: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_SURETY:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_SURETY_SUCCESS:
      return {
        ...state,
        factoringContractSurety: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_SURETY_ERROR:
      return { 
        ...state,
        factoringContractSurety: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_TARIFF:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_TARIFF_SUCCESS:
      return {
        ...state,
        factoringContractTariff: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_TARIFF_ERROR:
      return { 
        ...state,
        factoringContractTariff: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_FEATURES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_FEATURES_SUCCESS:
      return {
        ...state,
        factoringContractFeatures: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_FACTORING_CONTRACT_FEATURES_ERROR:
      return { 
        ...state,
        factoringContractFeatures: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_SUPPLY_CONTRACT_COMMON:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_SUPPLY_CONTRACT_COMMON_SUCCESS:
      return {
        ...state,
        supplyContractCommon: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_SUPPLY_CONTRACT_COMMON_ERROR:
      return { 
        ...state,
        supplyContractCommon: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_ACCOUNT_ENTITIES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_ACCOUNT_ENTITIES_SUCCESS:
      return {
        ...state,
        accountEntities: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_ACCOUNT_ENTITIES_ERROR:
      return { 
        ...state,
        accountEntities: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_CONSTRAINT_ORDERS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_CONSTRAINT_ORDERS_SUCCESS:
      return {
        ...state,
        constraintOrders: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_CONSTRAINT_ORDERS_ERROR:
      return { 
        ...state,
        constraintOrders: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_SUPPLY_COMMON_TAB:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_SUPPLY_COMMON_TAB_SUCCESS:
      return {
        ...state,
        supplyCommonTab: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_SUPPLY_COMMON_TAB_ERROR:
      return { 
        ...state,
        supplyCommonTab: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_SUPPLY_DOCUMENT_TAB:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_SUPPLY_DOCUMENT_TAB_SUCCESS:
      return {
        ...state,
        supplyDocumentTab: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_SUPPLY_DOCUMENT_TAB_ERROR:
      return { 
        ...state,
        supplyDocumentTab: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_SUPPLY_FINANCING_TAB:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_SUPPLY_FINANCING_TAB_SUCCESS:
      return {
        ...state,
        supplyFinancingTab: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_SUPPLY_FINANCING_TAB_ERROR:
      return { 
        ...state,
        supplyFinancingTab: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_MON_ISSUE_NOTIFICATION_TAB:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_MON_ISSUE_NOTIFICATION_TAB_SUCCESS:
      return {
        ...state,
        monIssueNotificationTab: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_MON_ISSUE_NOTIFICATION_TAB_ERROR:
      return { 
        ...state,
        monIssueNotificationTab: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_SUCCESS:
      return {
        ...state,
        supplyRegistrySupplyTab: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_ERROR:
      return { 
        ...state,
        supplyRegistrySupplyTab: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_HOLIDAYS_BY_PERIOD:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_HOLIDAYS_BY_PERIOD_SUCCESS:
      return {
        ...state,
        holidaysByPeriod: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_HOLIDAYS_BY_PERIOD_ERROR:
      return { 
        ...state,
        holidaysByPeriod: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_OPER_DAYS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_OPER_DAYS_SUCCESS:
      return {
        ...state,
        operDays: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_OPER_DAYS_ERROR:
      return { 
        ...state,
        operDays: [],
        isFetching: false, 
      }
    case actionTypes.START_FACTORING_PROCESS_TRANSIT:
      return { 
        ...state,
        isFetching: true, 
      }
    case actionTypes.START_FACTORING_PROCESS_TRANSIT_SUCCESS:
      return { 
        ...state,
        isFetching: false, 
      }
    case actionTypes.START_FACTORING_PROCESS_TRANSIT_ERROR:
      return { 
        ...state,
        isFetching: false, 
      }
      case actionTypes.GET_FACTORING_ACCOUNT_NUMBER:
        return { 
          ...state, 
          isFetching: true,
        }
      case actionTypes.GET_FACTORING_ACCOUNT_NUMBER_SUCCESS:
        return {
          ...state,
          accountNumber: action.data,
          isFetching: false,
        }
      case actionTypes.GET_FACTORING_ACCOUNT_NUMBER_ERROR:
        return { 
          ...state,
          accountNumber: [],
          isFetching: false, 
        }
      case actionTypes.GET_FACTORING_SUPPLY_CONTRACT:
        return { 
          ...state, 
          isFetching: true,
        }
      case actionTypes.GET_FACTORING_SUPPLY_CONTRACT_SUCCESS:
        return {
          ...state,
          supplyContract: action.data,
          isFetching: false,
        }
      case actionTypes.GET_FACTORING_SUPPLY_CONTRACT_ERROR:
        return { 
          ...state,
          supplyContract: [],
          isFetching: false, 
        }
      case actionTypes.GET_FACTORING_ACCOUNT_COMPANY:
        return { 
          ...state, 
          isFetching: true,
        }
      case actionTypes.GET_FACTORING_ACCOUNT_COMPANY_SUCCESS:
        return {
          ...state,
          accountCompany: action.data,
          isFetching: false,
        }
      case actionTypes.GET_FACTORING_ACCOUNT_COMPANY_ERROR:
        return { 
          ...state,
          accountCompany: [],
          isFetching: false, 
        }
      case actionTypes.RESET_FACTORING_RECEIVER:
        return { 
          ...state,
          accountCompany: [],
          supplyContract: [],
          accountNumber: [],
          isFetchingCount: 0,
          isFetching: false, 
        }
      default:
      return state
  }
}