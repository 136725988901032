import React, { useEffect, useState, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import {
  getFactoringContractsBySupplierInn,
  getFactoringDebtors,
  startFactoringProcessContractPreparation,
} from '../../../../redux/Factoring/actions'
import { Modal2, Fieldset, RowGrid, Select2, Col1, Col11, Col12, Label, InputWithDadata, InputText } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Button, ButtonAdd, ButtonRemove, ButtonWrapper, Form, H3 } from '../../Layout'
import styles from '../../styles'

const useStyles = createUseStyles({
  addFactoringContractForm: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
  },
  ...styles,
  actions: {
    marginTop: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
  },
  actionBtn: {
    ...styles.actionBtn,
    width: 56,
    height: 56,
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const PROCESS_TYPE_OPTIONS = [
  { name: 'Добавить новый договор факторинга', value: 'NEW_FACTORING' },
  { name: 'Внести изменения в действующий договор факторинга', value: 'UPDATE_FACTORING' },
  { name: 'Добавить дебитора/договор поставки', value: 'NEW_SUPPLY_CONTRACT_DEBTOR' },
  { name: 'Изменить параметры дебитора в договоре поставки', value: 'UPDATE_DEBTOR' },
  { name: 'Изменить параметры договора поставки', value: 'UPDATE_SUPPLY_CONTRACT' },
]

const DEFAULT_SUPPLIER = { id: '', INN: '', OGRN: '', displayName: '' }
const DEFAULT_DEBTOR = { id: '', INN: '', OGRN: '' }
const DEFAULT_FACTORING_CONTRACT = { id: '', date: '', number: '' }
const DEFAULT_SUPPLY_CONTRACT = { id: '', date: '', number: '' }

export const AddFactoringContractForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [processType, setProcessType] = useState(PROCESS_TYPE_OPTIONS[0].value)
  const [supplier, setSupplier] = useState(DEFAULT_SUPPLIER)
  const [debtors, setDebtors] = useState([DEFAULT_DEBTOR])
  const [factoringContract, setFactoringContract] = useState(DEFAULT_FACTORING_CONTRACT)
  const [supplyContract, setSupplyContract] = useState(DEFAULT_SUPPLY_CONTRACT)
  const [supplyContracts, setSupplyContracts] = useState([DEFAULT_SUPPLY_CONTRACT])
  const [factoringContractsBySupplierInn, setFactoringContractsBySupplierInn] = useState([])
  const [submitted, setSubmitted] = useState(0)

  const touched = useMemo(() => submitted > 0, [submitted])

  const isNewFactoring = processType === 'NEW_FACTORING'
  const isUpdateFactoring = processType === 'UPDATE_FACTORING'
  const isNewSupplyContractDebtor = processType === 'NEW_SUPPLY_CONTRACT_DEBTOR'
  const isUpdateDebtor = processType === 'UPDATE_DEBTOR'
  const isUpdateSupplyContract = processType === 'UPDATE_SUPPLY_CONTRACT'

  const isSupplierFromDadata = isNewFactoring

  const showDebtorsByDadata = isNewFactoring || isNewSupplyContractDebtor
  const showDebtorsBySelect = isUpdateDebtor
  const showDebtors = showDebtorsByDadata || showDebtorsBySelect
  const showFactoringContract = isUpdateFactoring || isNewSupplyContractDebtor || isUpdateDebtor || isUpdateSupplyContract
  const showSupplyContract = isUpdateDebtor || isUpdateSupplyContract

  const factoringContractOptions = useMemo(() => {
    return [
      { value: '', name: '' },
      ...factoringContractsBySupplierInn.map(item => ({
        value: item.id,
        name: item.number,
      }))
    ]
  }, [factoringContractsBySupplierInn])

  const supplyContractOptions = useMemo(() => {
    return supplyContracts?.filter(item => debtors?.length > 0 && item?.id === debtors[0]?.id) || []
  }, [supplyContracts, debtors])

  useEffect(() => {
    if (supplyContractOptions?.length === 1) {
      const { supplyContractId, date, number } = supplyContractOptions[0]
      setSupplyContract({ id: supplyContractId || '', date, number })
    }
  }, [supplyContractOptions])

  const payload = {
    processType,
    debtors: debtors?.map(debtor => ({
      inn: debtor?.INN || null,
      ogrn: debtor?.OGRN || null,
    })),
    factoringContractId: factoringContract?.id || null,
    factoringContractDate: factoringContract?.date ||  null,
    factoringContractNumber: factoringContract?.number || null,
    supplierInn: supplier?.INN,
    supplierOgrn: supplier?.OGRN,
    supplyContractId: supplyContract?.id || null,
    supplyContractDate: supplyContract?.date || null,
    supplyContractNumber: supplyContract?.number || null,
  }

  const errors = {
    ...(!(supplier?.INN?.trim() || supplier?.OGRN?.trim()) ? { supplier: 'Не указан поставщик' } : {}),
    ...(showFactoringContract && !factoringContract?.id?.trim() ? { factoringContract: 'Не указан договор факторинга' } : {}),
    ...(showSupplyContract && !supplyContract?.id?.trim() ? { supplyContract: 'Не указан договор поставки' } : {}),
    ...(showDebtors && debtors?.reduce((result, item, index) => {
      return !(item?.INN?.trim() || item?.OGRN?.trim())
        ? { ...result, [`debtor${index}`]: 'Не указан дебитор' }
        : { ...result }
      }, {})
    ),
    ...(isNewFactoring && !factoringContract?.number?.trim()? { factoringContractNumber: 'Не указан номер договора факторинга' } : {}),
    ...(isNewFactoring && !factoringContract?.date?.trim()? { factoringContractDate: 'Не указана дата договора факторинга' } : {}),
    ...(isNewFactoring && !supplyContract?.number?.trim()? { supplyContractNumber: 'Не указан номер договора поставки' } : {}),
    ...(isNewFactoring && !supplyContract?.date?.trim()? { supplyContractDate: 'Не указана дата договора поставки' } : {}),
  }

  const isValid = Object.keys(errors)?.length === 0


  const onReset = () => {
    onClearSupplier()
    setDebtors([DEFAULT_DEBTOR])
    setFactoringContract(DEFAULT_FACTORING_CONTRACT)
    setSupplyContract(DEFAULT_SUPPLY_CONTRACT)
    setSupplyContracts([DEFAULT_SUPPLY_CONTRACT])
    setFactoringContractsBySupplierInn([])
  }

  const onChangeProcessType = (e) => {
    setProcessType(e.target.value)
    onReset()
  }

  const onChangeSupplier = ({ id, displayName, INN, OGRN }) => {
    setSupplier({ id, displayName, INN, OGRN })
    const entityType = 'factoring_contract'
    const payload = { supplierInn: INN }
    const onSuccess = (data) => setFactoringContractsBySupplierInn(data)
    dispatch(getFactoringContractsBySupplierInn(entityType, payload, onSuccess))
  }

  const onChangeSupplierByDadata = ({ inn, ogrn, fullName }) => {
    setSupplier({
      id: '',
      displayName: fullName,
      INN: inn,
      OGRN: ogrn,
    })
    const entityType = 'factoring_contract'
    const payload = { supplierInn: inn }
    const onSuccess = (data) => setFactoringContractsBySupplierInn(data)
    dispatch(getFactoringContractsBySupplierInn(entityType, payload, onSuccess))
  }
  
  const onClearSupplier = () => setSupplier(DEFAULT_SUPPLIER)

  const onChangeDebtorByDadata = ({ inn, ogrn, fullName }, index) => {
    const newDebtors = debtors?.map((item, idx) => index === idx
      ? { INN: inn, OGRN: ogrn, displayName: fullName }
      : item
    )
    setDebtors(newDebtors)
  }

  const onChangeDebtorBySelect = (e) => {
    const id = e?.target?.value
    const { INN, OGRN, displayName } = debtors.find(item => item?.id === id) || {}
    setDebtors([{ id, INN, OGRN, displayName }])
  }

  const onRemoveDebtor = (index) => setDebtors(
    debtors.filter((_, idx) => index !== idx)
  )

  const onAddDebitor = () => setDebtors([...debtors, DEFAULT_DEBTOR])

  const onChangeFactoringContract = (id, number, date) => {
    setFactoringContract({ id, number, date })
    const onSuccess = (data) => {
      setDebtors(data.map(item => ({
        id: item?.id,
        INN: item?.inn,
        OGRN: item?.ogrn,
        displayName: item?.displayName,
      })))
      setSupplyContracts(data
        .reduce((result, item) => {
          item.supplyContracts.forEach(supplyContract => result.push({
            ...supplyContract,
            supplyContractId: supplyContract?.id,
            id: item.id,
          }))
          return result
        }, [])
        .map(item => ({
          ...item,
          name: item.number,
          value: item.supplyContractId,
        }))
      )
    }
    if (showDebtorsBySelect || isUpdateSupplyContract) {
      dispatch(getFactoringDebtors(id, onSuccess))
    }
  }

  const onChangeSupplyContract = (e) => {
    const id = e.target.value
    const item = supplyContractOptions?.find(item => item?.supplyContractId === id)
    const { date, number } = item || {}
    setSupplyContract({ id, date, number })
  }

  const onSubmit = () => setSubmitted(submitted + 1)

  const onStartFactoringProcess = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(startFactoringProcessContractPreparation(payload, 'factoring-pa-contract-preparation', onSuccess))
    }
  }

  useEffect(() => {
    if (touched) {
      onStartFactoringProcess()
    }
  // eslint-disable-next-line
  }, [touched, submitted])

  const debtorOptions = debtors?.map(item => ({
    value: item?.id,
    name: item?.displayName,
  }))


  console.log('payload:', payload)
  console.log('errors:', errors)

  return (
    <Modal2
      className={classes.addFactoringContractForm}
      onClose={onClose}
    >
      <div className={classes.wrapper}>
        <H3>MVP0 'Экспресс'</H3>
        <Form>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Select2
                  label='Добавить/изменить'
                  name='processType'
                  options={PROCESS_TYPE_OPTIONS}
                  value={processType}
                  onChange={onChangeProcessType}
                  error={touched && errors.processType}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                {isSupplierFromDadata
                  ? <InputWithDadata
                      type='text'
                      service='party'
                      name='supplier'
                      label='Поставщик'
                      placeholder='Поставщик'
                      value={supplier?.displayName}
                      onSelect={onChangeSupplierByDadata}
                      error={touched ? errors.supplier : ''}
                    />
                  : <TextFieldWithAutoComplete
                      classNames={{
                        container: 'filter-input',
                        input: 'filter-input__input',
                      }}
                      name='supplier'
                      placeholder='Поставщик'
                      label='Поставщик'
                      value={supplier?.displayName}
                      defaultValue={supplier?.displayName}
                      onSelect={onChangeSupplier}
                      onClear={onClearSupplier}
                      payloadKeys={['id', 'INN', 'OGRN', 'displayName']}
                      meta={{
                        touched,
                        error: errors.supplier,
                      }}
                      withoutLink
                    />
                }
              </Col12>
            </RowGrid>
          </Fieldset>
          {showFactoringContract &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Select2
                    label='Договор факторинга'
                    name='factoringContract'
                    options={factoringContractOptions}
                    value={factoringContract?.id}
                    onChange={(e) => {
                      const id = e.target.value
                      const { number, startDate } = factoringContractsBySupplierInn?.find(item => item?.id === id) || {}
                      onChangeFactoringContract(id, number, startDate)
                    }}
                    error={touched && errors.factoringContract}
                    disabled={!supplier?.id}
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {showDebtorsByDadata &&
            <>
              {debtors?.map((debtor, index) => {
                const canRemoveDebtor = debtors?.length > 1
                const Column = canRemoveDebtor ? Col11 : Col12
                return (
                  <Fieldset key={index}>
                    <RowGrid className={classes.centered}>
                      <Column>
                        <InputWithDadata
                          type='text'
                          service='party'
                          name='debtor'
                          label='Дебитор'
                          placeholder='Дебитор'
                          value={debtor?.displayName}
                          onSelect={(data) => data?.inn && onChangeDebtorByDadata(data, index)}
                          error={touched ? errors[`debtor${index}`] : ''}
                        />
                      </Column>
                      {canRemoveDebtor &&
                        <Col1>
                          <Label>&nbsp;</Label>
                          <div className={classes.actions}>
                            <ButtonRemove
                              className={classes.actionBtn}
                              title='Удалить дебитора'
                              onClick={() => onRemoveDebtor(index)}
                            />
                          </div>
                        </Col1>
                      }
                    </RowGrid>
                  </Fieldset>
                )
              })}
              <Fieldset>
                <RowGrid>
                  <Col12>
                    <ButtonAdd onClick={onAddDebitor}>Добавить дебитора</ButtonAdd>
                  </Col12>
                </RowGrid>
              </Fieldset>
            </>
          }
          {showDebtorsBySelect &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Select2
                    label='Дебитор'
                    name='debtor'
                    options={[{ name: '', value: '' }, ...debtorOptions]}
                    value={debtors[0]?.id}
                    onChange={onChangeDebtorBySelect}
                    error={touched ? errors[`debtor0`] : ''}
                    disabled={!factoringContract?.id}
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }    
          {showSupplyContract &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Select2
                    label='Договор поставки'
                    name='supplyContract'
                    options={[{ name: '', value: '' }, ...supplyContractOptions]}
                    value={supplyContract?.id }
                    onChange={onChangeSupplyContract}
                    error={touched && errors.supplyContract}
                    disabled={!debtors[0]?.id}
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {isNewFactoring &&
            <>
              <Fieldset>
                <RowGrid>
                  <Col12>
                    <InputText
                      type='text'
                      name='factoringContractNumber'
                      label='Номер договора факторинга'
                      value={factoringContract?.number}
                      onChange={(e) => setFactoringContract({
                        ...factoringContract,
                        number: e?.target?.value,
                      })}
                      error={touched ? errors.factoringContractNumber : ''}
                    />
                  </Col12>
                </RowGrid>
              </Fieldset>
              <Fieldset>
                <RowGrid>
                  <Col12>
                    <InputText
                      type='date'
                      name='factoringContractDate'
                      label='Дата договора факторинга'
                      value={factoringContract?.date}
                      onChange={(e) => setFactoringContract({
                        ...factoringContract,
                        date: e?.target?.value,
                      })}
                      error={touched ? errors.factoringContractDate : ''}
                    />
                  </Col12>
                </RowGrid>
              </Fieldset>
              <Fieldset>
                <RowGrid>
                  <Col12>
                    <InputText
                      type='text'
                      name='supplyContractNumber'
                      label='Номер договора поставки'
                      value={supplyContract?.number}
                      onChange={(e) => setSupplyContract({
                        ...supplyContract,
                        number: e?.target?.value,
                      })}
                      error={touched ? errors.supplyContractNumber : ''}
                    />
                  </Col12>
                </RowGrid>
              </Fieldset>
              <Fieldset>
                <RowGrid>
                  <Col12>
                    <InputText
                      type='date'
                      name='supplyContractDate'
                      label='Дата договора поставки'
                      value={supplyContract?.date}
                      onChange={(e) => setSupplyContract({
                        ...supplyContract,
                        date: e?.target?.value,
                      })}
                      error={touched ? errors.supplyContractDate : ''}
                    />
                  </Col12>
                </RowGrid>
              </Fieldset>
            </>
          }
        </Form>
        <div style={{ flexGrow: 1 }} />
        <ButtonWrapper>
          <Button
            disabled={touched && !isValid}
            onClick={onSubmit}
          >
            Создать
          </Button>
        </ButtonWrapper>
      </div>
    </Modal2>
  )
}