import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { getCompanySignerInfo } from '../../../../redux/Company/actions'
// import * as utils from '../utils'
import { Attributes } from '../Helpers/Attributes'
import Overlay from '../../../Overlay'
import { User } from '../Helpers/User'
import { Passport } from '../Helpers/Passport'
import { Attribute } from '../Helpers/Attributes'

export class Signer extends Component {
  async loadData() {
    const { dispatch, companyId } = this.props
    dispatch(getCompanySignerInfo(companyId))
  }

  componentDidMount() {
    if (!this.props.fetching) {
      this.loadData()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.loadData()
    }
  }

  render() {
    const {
      signer: data = {},
      fetching,
    } = this.props
    
    const position = _.get(data, 'employee.position')
    const authorizingDoc = _.get(data, 'employee.authorizingDoc')
    const passportData = _.get(data, 'identityDocuments[0]')

    return fetching
      ? <Overlay/>
      : <div className='block-info'>
          <div className='block_head'>Сведения о подписанте</div>
          <User user={data}/>
            <div className='row'>
              <Attribute col='12' attr={{label: 'Должность', value: position}}/>
              <Attribute col='12' attr={{label: 'Наименование документа о назначении на должность', value: authorizingDoc}}/>
            </div>
            <hr/>
            <div className='block_head'>Паспортные данные</div>
            <Passport passport={passportData}/>
            {data?.personAttributes ?
              <>
                <hr/>
                <Attributes {...data.personAttributes}/>
              </>
              : null
            }
            {data?.relationAttributes ?
              <>
                <hr/>
                <Attributes {...data.relationAttributes}/>
              </>
              : null
            }
            <br/>
        </div>
  }
}

const mapStateToProps = ( { Company }) => {
    return {
      ...Company.signerInfo
    }
}

export default connect(mapStateToProps)(Signer)