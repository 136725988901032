export const GET_REPORT_TASKS_PENDING = 'GET_REPORT_TASKS_PENDING'
export const GET_REPORT_TASKS_SUCCESS = 'GET_REPORT_TASKS_SUCCESS'
export const GET_REPORT_TASKS_ERROR = 'GET_REPORT_TASKS_ERROR'

export const GET_REPORT_TASKS_DETAILS_PENDING =
  'GET_REPORT_TASKS_DETAILS_PENDING'
export const GET_REPORT_TASKS_DETAILS_SUCCESS =
  'GET_REPORT_TASKS_DETAILS_SUCCESS'
export const GET_REPORT_TASKS_DETAILS_ERROR = 'GET_REPORT_TASKS_DETAILS_ERROR'
export const REPORT_TASKS_CLEAR_DETAILS = 'REPORT_TASKS_CLEAR_DETAILS'

export const GET_REPORT_TASKS_TASKS_PENDING = 'GET_REPORT_TASKS_TASKS_PENDING'
export const GET_REPORT_TASKS_TASKS_SUCCESS = 'GET_REPORT_TASKS_TASKS_SUCCESS'
export const GET_REPORT_TASKS_TASKS_ERROR = 'GET_REPORT_TASKS_TASKS_ERROR'
