export const COMPANY_GET_INFO_PENDING = 'COMPANY_GET_INFO_PENDING'
export const COMPANY_GET_INFO_SUCCESS = 'COMPANY_GET_INFO_SUCCESS'
export const COMPANY_GET_INFO_ERROR = 'COMPANY_GET_INFO_ERROR'

export const COMPANY_GET_LIMITS_PENDING = 'COMPANY_GET_LIMITS_PENDING'
export const COMPANY_GET_LIMITS_SUCCESS = 'COMPANY_GET_LIMITS_SUCCESS'
export const COMPANY_GET_LIMITS_ERROR = 'COMPANY_GET_LIMITS_ERROR'

export const COMPANY_DELETE_LIMITS_PENDING = 'COMPANY_DELETE_LIMITS_PENDING'
export const COMPANY_DELETE_LIMITS_SUCCESS = 'COMPANY_DELETE_LIMITS_SUCCESS'
export const COMPANY_DELETE_LIMITS_ERROR = 'COMPANY_DELETE_LIMITS_ERROR'

export const COMPANY_GET_ORDERS_PENDING = 'COMPANY_GET_ORDERS_PENDING'
export const COMPANY_GET_ORDERS_SUCCESS = 'COMPANY_GET_ORDERS_SUCCESS'
export const COMPANY_GET_ORDERS_ERROR = 'COMPANY_GET_ORDERS_ERROR'

export const COMPANY_GET_ORDER_PENDING = 'COMPANY_GET_ORDER_PENDING'
export const COMPANY_GET_ORDER_SUCCESS = 'COMPANY_GET_ORDER_SUCCESS'
export const COMPANY_GET_ORDER_ERROR = 'COMPANY_GET_ORDER_ERROR'

export const COMPANY_GET_CLIENT_LOGINS_PENDING = 'COMPANY_GET_CLIENT_LOGINS_PENDING'
export const COMPANY_GET_CLIENT_LOGINS_SUCCESS = 'COMPANY_GET_CLIENT_LOGINS_SUCCESS'
export const COMPANY_GET_CLIENT_LOGINS_ERROR = 'COMPANY_GET_CLIENT_LOGINS_ERROR'

export const COMPANY_GET_AGENT_LOGINS_PENDING = 'COMPANY_GET_AGENT_LOGINS_PENDING'
export const COMPANY_GET_AGENT_LOGINS_SUCCESS = 'COMPANY_GET_AGENT_LOGINS_SUCCESS'
export const COMPANY_GET_AGENT_LOGINS_ERROR = 'COMPANY_GET_AGENT_LOGINS_ERROR'

export const COMPANY_ADD_AGENT_LOGIN_PENDING = 'COMPANY_ADD_AGENT_LOGIN_PENDING'
export const COMPANY_ADD_AGENT_LOGIN_SUCCESS = 'COMPANY_ADD_AGENT_LOGIN_SUCCESS'
export const COMPANY_ADD_AGENT_LOGIN_ERROR = 'COMPANY_ADD_AGENT_LOGIN_ERROR'

export const COMPANY_ADD_CLIENT_LOGIN_PENDING = 'COMPANY_ADD_CLIENT_LOGIN_PENDING'
export const COMPANY_ADD_CLIENT_LOGIN_SUCCESS = 'COMPANY_ADD_CLIENT_LOGIN_SUCCESS'
export const COMPANY_ADD_CLIENT_LOGIN_ERROR = 'COMPANY_ADD_CLIENT_LOGIN_ERROR'

export const COMPANY_REMOVE_AGENT_LOGIN_PENDING = 'COMPANY_REMOVE_AGENT_LOGIN_PENDING'
export const COMPANY_REMOVE_AGENT_LOGIN_SUCCESS = 'COMPANY_REMOVE_AGENT_LOGIN_SUCCESS'
export const COMPANY_REMOVE_AGENT_LOGIN_ERROR = 'COMPANY_REMOVE_AGENT_LOGIN_ERROR'

export const COMPANY_REMOVE_CLIENT_LOGIN_PENDING = 'COMPANY_REMOVE_CLIENT_LOGIN_PENDING'
export const COMPANY_REMOVE_CLIENT_LOGIN_SUCCESS = 'COMPANY_REMOVE_CLIENT_LOGIN_SUCCESS'
export const COMPANY_REMOVE_CLIENT_LOGIN_ERROR = 'COMPANY_REMOVE_CLIENT_LOGIN_ERROR'

export const COMPANY_GET_ADDRESS_PENDING = 'COMPANY_GET_ADDRESS_PENDING'
export const COMPANY_GET_ADDRESS_SUCCESS = 'COMPANY_GET_ADDRESS_SUCCESS'
export const COMPANY_GET_ADDRESS_ERROR = 'COMPANY_GET_ADDRESS_ERROR'

export const COMPANY_GET_BANK_ACCOUNTS_PENDING = 'COMPANY_GET_BANK_ACCOUNTS_PENDING'
export const COMPANY_GET_BANK_ACCOUNTS_SUCCESS = 'COMPANY_GET_BANK_ACCOUNTS_SUCCESS'
export const COMPANY_GET_BANK_ACCOUNTS_ERROR = 'COMPANY_GET_BANK_ACCOUNTS_ERROR'

export const COMPANY_GET_STUFF_INFO = 'COMPANY_GET_STUFF_INFO'
export const COMPANY_GET_STUFF_INFO_PENDING = 'COMPANY_GET_STUFF_INFO_PENDING'
export const COMPANY_GET_STUFF_INFO_SUCCESS = 'COMPANY_GET_STUFF_INFO_SUCCESS'
export const COMPANY_GET_STUFF_INFO_ERROR = 'COMPANY_GET_STUFF_INFO_ERROR'

export const COMPANY_GET_HEAD_INFO = 'COMPANY_GET_HEAD_INFO'
export const COMPANY_GET_HEAD_INFO_PENDING = 'COMPANY_GET_HEAD_INFO_PENDING'
export const COMPANY_GET_HEAD_INFO_SUCCESS = 'COMPANY_GET_HEAD_INFO_SUCCESS'
export const COMPANY_GET_HEAD_INFO_ERROR = 'COMPANY_GET_HEAD_INFO_ERROR'

export const COMPANY_GET_SIGNER_INFO = 'COMPANY_GET_SIGNER_INFO'
export const COMPANY_GET_SIGNER_INFO_PENDING = 'COMPANY_GET_SIGNER_INFO_PENDING'
export const COMPANY_GET_SIGNER_INFO_SUCCESS = 'COMPANY_GET_SIGNER_INFO_SUCCESS'
export const COMPANY_GET_SIGNER_INFO_ERROR = 'COMPANY_GET_SIGNER_INFO_ERROR'

export const COMPANY_GET_FOUNDERS_INFO = 'COMPANY_GET_FOUNDERS_INFO'
export const COMPANY_GET_FOUNDERS_INFO_PENDING = 'COMPANY_GET_FOUNDERS_INFO_PENDING'
export const COMPANY_GET_FOUNDERS_INFO_SUCCESS = 'COMPANY_GET_FOUNDERS_INFO_SUCCESS'
export const COMPANY_GET_FOUNDERS_INFO_ERROR = 'COMPANY_GET_FOUNDERS_INFO_ERROR'

export const COMPANY_GET_BENEFICIARIES_INFO = 'COMPANY_GET_BENEFICIARIES_INFO'
export const COMPANY_GET_BENEFICIARIES_INFO_PENDING = 'COMPANY_GET_BENEFICIARIES_INFO_PENDING'
export const COMPANY_GET_BENEFICIARIES_INFO_SUCCESS = 'COMPANY_GET_BENEFICIARIES_INFO_SUCCESS'
export const COMPANY_GET_BENEFICIARIES_INFO_ERROR = 'COMPANY_GET_BENEFICIARIES_INFO_ERROR'

export const COMPANY_GET_CONTACT_PERSON_INFO = 'COMPANY_GET_CONTACT_PERSON_INFO'
export const COMPANY_GET_CONTACT_PERSON_INFO_PENDING = 'COMPANY_GET_CONTACT_PERSON_INFO_PENDING'
export const COMPANY_GET_CONTACT_PERSON_INFO_SUCCESS = 'COMPANY_GET_CONTACT_PERSON_INFO_SUCCESS'
export const COMPANY_GET_CONTACT_PERSON_INFO_ERROR = 'COMPANY_GET_CONTACT_PERSON_INFO_ERROR'

export const COMPANY_GET_DOCUMENTS_INFO = 'COMPANY_GET_DOCUMENTS_INFO'
export const COMPANY_GET_DOCUMENTS_INFO_PENDING = 'COMPANY_GET_DOCUMENTS_INFO_PENDING'
export const COMPANY_GET_DOCUMENTS_INFO_SUCCESS = 'COMPANY_GET_DOCUMENTS_INFO_SUCCESS'
export const COMPANY_GET_DOCUMENTS_INFO_ERROR = 'COMPANY_GET_DOCUMENTS_INFO_ERROR'

export const COMPANY_GET_MANAGEMENT_INFO = 'COMPANY_GET_MANAGEMENT_INFO'
export const COMPANY_GET_MANAGEMENT_INFO_PENDING = 'COMPANY_GET_MANAGEMENT_INFO_PENDING'
export const COMPANY_GET_MANAGEMENT_INFO_SUCCESS = 'COMPANY_GET_MANAGEMENT_INFO_SUCCESS'
export const COMPANY_GET_MANAGEMENT_INFO_ERROR = 'COMPANY_GET_MANAGEMENT_INFO_ERROR'

export const COMPANY_GET_BUH_INFO = 'COMPANY_GET_BUH_INFO'
export const COMPANY_GET_BUH_INFO_PENDING = 'COMPANY_GET_BUH_INFO_PENDING'
export const COMPANY_GET_BUH_INFO_SUCCESS = 'COMPANY_GET_BUH_INFO_SUCCESS'
export const COMPANY_GET_BUH_INFO_ERROR = 'COMPANY_GET_BUH_INFO_ERROR'

export const COMPANY_GET_LIABILITIES_INFO = 'COMPANY_GET_LIABILITIES_INFO'
export const COMPANY_GET_LIABILITIES_INFO_PENDING = 'COMPANY_GET_LIABILITIES_INFO_PENDING'
export const COMPANY_GET_LIABILITIES_INFO_SUCCESS = 'COMPANY_GET_LIABILITIES_INFO_SUCCESS'
export const COMPANY_GET_LIABILITIES_INFO_ERROR = 'COMPANY_GET_LIABILITIES_INFO_ERROR'

export const COMPANY_GET_STATISTIC_INFO = 'COMPANY_GET_STATISTIC_INFO'
export const COMPANY_GET_STATISTIC_INFO_PENDING = 'COMPANY_GET_STATISTIC_INFO_PENDING'
export const COMPANY_GET_STATISTIC_INFO_SUCCESS = 'COMPANY_GET_STATISTIC_INFO_SUCCESS'
export const COMPANY_GET_STATISTIC_INFO_ERROR = 'COMPANY_GET_STATISTIC_INFO_ERROR'

export const COMPANY_STATISTIC_ORDER_INCLUDE = 'COMPANY_STATISTIC_ORDER_INCLUDE'
export const COMPANY_STATISTIC_ORDER_INCLUDE_PENDING = 'COMPANY_STATISTIC_ORDER_INCLUDE_PENDING'
export const COMPANY_STATISTIC_ORDER_INCLUDE_SUCCESS = 'COMPANY_STATISTIC_ORDER_INCLUDE_SUCCESS'
export const COMPANY_STATISTIC_ORDER_INCLUDE_ERROR = 'COMPANY_STATISTIC_ORDER_INCLUDE_ERROR'

export const COMPANY_STATISTIC_ORDER_EXCLUDE = 'COMPANY_STATISTIC_ORDER_EXCLUDE'
export const COMPANY_STATISTIC_ORDER_EXCLUDE_PENDING = 'COMPANY_STATISTIC_ORDER_EXCLUDE_PENDING'
export const COMPANY_STATISTIC_ORDER_EXCLUDE_SUCCESS = 'COMPANY_STATISTIC_ORDER_EXCLUDE_SUCCESS'
export const COMPANY_STATISTIC_ORDER_EXCLUDE_ERROR = 'COMPANY_STATISTIC_ORDER_EXCLUDE_ERROR'

export const COMPANY_STATISTIC_UPDATE_LOADED = 'COMPANY_STATISTIC_UPDATE_LOADED'
export const COMPANY_STATISTIC_UPDATE_LOADED_PENDING = 'COMPANY_STATISTIC_UPDATE_LOADED_PENDING'
export const COMPANY_STATISTIC_UPDATE_LOADED_SUCCESS = 'COMPANY_STATISTIC_UPDATE_LOADED_SUCCESS'
export const COMPANY_STATISTIC_UPDATE_LOADED_ERROR = 'COMPANY_STATISTIC_UPDATE_LOADED_ERROR'

export const COMPANY_AGENT_INFO_GET = 'COMPANY_AGENT_INFO_GET'
export const COMPANY_AGENT_INFO_GET_PENDING = 'COMPANY_AGENT_INFO_GET_PENDING'
export const COMPANY_AGENT_INFO_GET_SUCCESS = 'COMPANY_AGENT_INFO_GET_SUCCESS'
export const COMPANY_AGENT_INFO_GET_ERROR = 'COMPANY_AGENT_INFO_GET_ERROR'

export const REGISTER_COMPANY_AS_AGENT = 'REGISTER_COMPANY_AS_AGENT'
export const REGISTER_COMPANY_AS_AGENT_PENDING = 'REGISTER_COMPANY_AS_AGENT_PENDING'
export const REGISTER_COMPANY_AS_AGENT_SUCCESS = 'REGISTER_COMPANY_AS_AGENT_SUCCESS'
export const REGISTER_COMPANY_AS_AGENT_ERROR = 'REGISTER_COMPANY_AS_AGENT_ERROR'

export const COMPANY_AGENT_BILLING_SIGNER_ADD = 'COMPANY_AGENT_BILLING_SIGNER_ADD'
export const COMPANY_AGENT_BILLING_SIGNER_ADD_PENDING = 'COMPANY_AGENT_BILLING_SIGNER_ADD_PENDING'
export const COMPANY_AGENT_BILLING_SIGNER_ADD_SUCCESS = 'COMPANY_AGENT_BILLING_SIGNER_ADD_SUCCESS'
export const COMPANY_AGENT_BILLING_SIGNER_ADD_ERROR = 'COMPANY_AGENT_BILLING_SIGNER_ADD_ERROR'

export const COMPANY_AGENT_BILLING_SIGNER_REMOVE = 'COMPANY_AGENT_BILLING_SIGNER_REMOVE'
export const COMPANY_AGENT_BILLING_SIGNER_REMOVE_PENDING = 'COMPANY_AGENT_BILLING_SIGNER_REMOVE_PENDING'
export const COMPANY_AGENT_BILLING_SIGNER_REMOVE_SUCCESS = 'COMPANY_AGENT_BILLING_SIGNER_REMOVE_SUCCESS'
export const COMPANY_AGENT_BILLING_SIGNER_REMOVE_ERROR = 'COMPANY_AGENT_BILLING_SIGNER_REMOVE_ERROR'

export const COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_ADD = 'COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_ADD'
export const COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_ADD_PENDING = 'COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_ADD_PENDING'
export const COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_ADD_SUCCESS = 'COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_ADD_SUCCESS'
export const COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_ADD_ERROR = 'COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_REMOVE_ERROR'

export const COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_EDIT = 'COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_EDIT'
export const COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_EDIT_PENDING = 'COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_EDIT_PENDING'
export const COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_EDIT_SUCCESS = 'COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_EDIT_SUCCESS'
export const COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_EDIT_ERROR = 'COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_REMOVE_ERROR'

export const COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_REMOVE = 'COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_REMOVE'
export const COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_REMOVE_PENDING = 'COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_REMOVE_PENDING'
export const COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_REMOVE_SUCCESS = 'COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_REMOVE_SUCCESS'
export const COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_REMOVE_ERROR = 'COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_REMOVE_ERROR'

export const COMPANY_AGENT_BILLING_CONTRACT_ADD = 'COMPANY_AGENT_BILLING_CONTRACT_ADD'
export const COMPANY_AGENT_BILLING_CONTRACT_ADD_PENDING = 'COMPANY_AGENT_BILLING_CONTRACT_ADD_PENDING'
export const COMPANY_AGENT_BILLING_CONTRACT_ADD_SUCCESS = 'COMPANY_AGENT_BILLING_CONTRACT_ADD_SUCCESS'
export const COMPANY_AGENT_BILLING_CONTRACT_ADD_ERROR = 'COMPANY_AGENT_BILLING_CONTRACT_REMOVE_ERROR'

export const COMPANY_AGENT_BILLING_CONTRACT_EDIT = 'COMPANY_AGENT_BILLING_CONTRACT_EDIT'
export const COMPANY_AGENT_BILLING_CONTRACT_EDIT_PENDING = 'COMPANY_AGENT_BILLING_CONTRACT_EDIT_PENDING'
export const COMPANY_AGENT_BILLING_CONTRACT_EDIT_SUCCESS = 'COMPANY_AGENT_BILLING_CONTRACT_EDIT_SUCCESS'
export const COMPANY_AGENT_BILLING_CONTRACT_EDIT_ERROR = 'COMPANY_AGENT_BILLING_CONTRACT_REMOVE_ERROR'

export const COMPANY_AGENT_BILLING_CONTRACT_REMOVE = 'COMPANY_AGENT_BILLING_CONTRACT_REMOVE'
export const COMPANY_AGENT_BILLING_CONTRACT_REMOVE_PENDING = 'COMPANY_AGENT_BILLING_CONTRACT_REMOVE_PENDING'
export const COMPANY_AGENT_BILLING_CONTRACT_REMOVE_SUCCESS = 'COMPANY_AGENT_BILLING_CONTRACT_REMOVE_SUCCESS'
export const COMPANY_AGENT_BILLING_CONTRACT_REMOVE_ERROR = 'COMPANY_AGENT_BILLING_CONTRACT_REMOVE_ERROR'

export const COMPANY_BANK_MANAGER_ADD = 'COMPANY_BANK_MANAGER_ADD'
export const COMPANY_BANK_MANAGER_ADD_PENDING = 'COMPANY_BANK_MANAGER_ADD_PENDING'
export const COMPANY_BANK_MANAGER_ADD_SUCCESS = 'COMPANY_BANK_MANAGER_ADD_SUCCESS'
export const COMPANY_BANK_MANAGER_ADD_ERROR = 'COMPANY_BANK_MANAGER_ADD_ERROR'

export const COMPANY_BANK_MANAGER_REMOVE = 'COMPANY_BANK_MANAGER_REMOVE'
export const COMPANY_BANK_MANAGER_REMOVE_PENDING = 'COMPANY_BANK_MANAGER_REMOVE_PENDING'
export const COMPANY_BANK_MANAGER_REMOVE_SUCCESS = 'COMPANY_BANK_MANAGER_REMOVE_SUCCESS'
export const COMPANY_BANK_MANAGER_REMOVE_ERROR = 'COMPANY_BANK_MANAGER_REMOVE_ERROR'

export const COMPANY_AGENT_PERSON_ADD = 'COMPANY_AGENT_PERSON_ADD'
export const COMPANY_AGENT_PERSON_ADD_PENDING = 'COMPANY_AGENT_PERSON_ADD_PENDING'
export const COMPANY_AGENT_PERSON_ADD_SUCCESS = 'COMPANY_AGENT_PERSON_ADD_SUCCESS'
export const COMPANY_AGENT_PERSON_ADD_ERROR = 'COMPANY_AGENT_PERSON_ADD_ERROR'

export const COMPANY_START_SUBPROCESS = 'COMPANY_START_SUBPROCESS'
export const COMPANY_START_SUBPROCESS_PENDING = 'COMPANY_START_SUBPROCESS_PENDING'
export const COMPANY_START_SUBPROCESS_SUCCESS = 'COMPANY_START_SUBPROCESS_SUCCESS'
export const COMPANY_START_SUBPROCESS_ERROR = 'COMPANY_START_SUBPROCESS_ERROR'

export const COMPANY_CBS_MANAGER_UPDATE = 'COMPANY_CBS_MANAGER_UPDATE'
export const COMPANY_CBS_MANAGER_UPDATE_PENDING = 'COMPANY_CBS_MANAGER_UPDATE_PENDING'
export const COMPANY_CBS_MANAGER_UPDATE_SUCCESS = 'COMPANY_CBS_MANAGER_UPDATE_SUCCESS'
export const COMPANY_CBS_MANAGER_UPDATE_ERROR = 'COMPANY_CBS_MANAGER_UPDATE_ERROR'

export const COMPANY_AGENT_BILLING_GET_BASE_SCHEMA_LIST = 'COMPANY_AGENT_BILLING_GET_BASE_SCHEMA_LIST'
export const COMPANY_AGENT_BILLING_GET_BASE_SCHEMA_LIST_PENDING = 'COMPANY_AGENT_BILLING_GET_BASE_SCHEMA_LIST_PENDING'
export const COMPANY_AGENT_BILLING_GET_BASE_SCHEMA_LIST_SUCCESS = 'COMPANY_AGENT_BILLING_GET_BASE_SCHEMA_LIST_SUCCESS'
export const COMPANY_AGENT_BILLING_GET_BASE_SCHEMA_LIST_ERROR = 'COMPANY_AGENT_BILLING_GET_BASE_SCHEMA_LIST_ERROR'

export const COMPANY_AGENT_BILLING_ADD_BASE_SCHEMA = 'COMPANY_AGENT_BILLING_ADD_BASE_SCHEMA'
export const COMPANY_AGENT_BILLING_ADD_BASE_SCHEMA_PENDING = 'COMPANY_AGENT_BILLING_ADD_BASE_SCHEMA_PENDING'
export const COMPANY_AGENT_BILLING_ADD_BASE_SCHEMA_SUCCESS = 'COMPANY_AGENT_BILLING_ADD_BASE_SCHEMA_SUCCESS'
export const COMPANY_AGENT_BILLING_ADD_BASE_SCHEMA_ERROR = 'COMPANY_AGENT_BILLING_ADD_BASE_SCHEMA_ERROR'

export const COMPANY_GET_BG_AGENT_BINDING = 'COMPANY_GET_BG_AGENT_BINDING'
export const COMPANY_GET_BG_AGENT_BINDING_PENDING = 'COMPANY_GET_BG_AGENT_BINDING_PENDING'
export const COMPANY_GET_BG_AGENT_BINDING_SUCCESS = 'COMPANY_GET_BG_AGENT_BINDING_SUCCESS'
export const COMPANY_GET_BG_AGENT_BINDING_ERROR = 'COMPANY_GET_BG_AGENT_BINDING_ERROR'

export const COMPANY_UPDATE_BG_AGENT_BINDING = 'COMPANY_UPDATE_BG_AGENT_BINDING'
export const COMPANY_UPDATE_BG_AGENT_BINDING_PENDING = 'COMPANY_UPDATE_BG_AGENT_BINDING_PENDING'
export const COMPANY_UPDATE_BG_AGENT_BINDING_SUCCESS = 'COMPANY_UPDATE_BG_AGENT_BINDING_SUCCESS'
export const COMPANY_UPDATE_BG_AGENT_BINDING_ERROR = 'COMPANY_UPDATE_BG_AGENT_BINDING_ERROR'

export const COMPANY_GET_WORK_GROUPS = 'COMPANY_GET_WORK_GROUPS'
export const COMPANY_GET_WORK_GROUPS_PENDING = 'COMPANY_GET_WORK_GROUPS_PENDING'
export const COMPANY_GET_WORK_GROUPS_SUCCESS = 'COMPANY_GET_WORK_GROUPS_SUCCESS'
export const COMPANY_GET_WORK_GROUPS_ERROR = 'COMPANY_GET_WORK_GROUPS_ERROR'

export const COMPANY_UPDATE_WORK_GROUPS = 'COMPANY_UPDATE_WORK_GROUPS'
export const COMPANY_UPDATE_WORK_GROUPS_PENDING = 'COMPANY_UPDATE_WORK_GROUPS_PENDING'
export const COMPANY_UPDATE_WORK_GROUPS_SUCCESS = 'COMPANY_UPDATE_WORK_GROUPS_SUCCESS'
export const COMPANY_UPDATE_WORK_GROUPS_ERROR = 'COMPANY_UPDATE_WORK_GROUPS_ERROR'

export const COMPANY_GET_AGENT_STATS = 'COMPANY_GET_AGENT_STATS'
export const COMPANY_GET_AGENT_STATS_PENDING = 'COMPANY_GET_AGENT_STATS_PENDING'
export const COMPANY_GET_AGENT_STATS_SUCCESS = 'COMPANY_GET_AGENT_STATS_SUCCESS'
export const COMPANY_GET_AGENT_STATS_ERROR = 'COMPANY_GET_AGENT_STATS_ERROR'

export const COMPANY_GET_AGENT_STATS_BY_STATUS = 'COMPANY_GET_AGENT_STATS_BY_STATUS'
export const COMPANY_GET_AGENT_STATS_BY_STATUS_PENDING = 'COMPANY_GET_AGENT_STATS_BY_STATUS_PENDING'
export const COMPANY_GET_AGENT_STATS_BY_STATUS_SUCCESS = 'COMPANY_GET_AGENT_STATS_BY_STATUS_SUCCESS'
export const COMPANY_GET_AGENT_STATS_BY_STATUS_ERROR = 'COMPANY_GET_AGENT_STATS_BY_STATUS_ERROR'

export const COMPANY_RESET_AGENT_STATS_BY_STATUS = 'COMPANY_RESET_AGENT_STATS_BY_STATUS'