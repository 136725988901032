import React, { Fragment } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Modal } from './../../index'
import Head from './Head'
import Code from './Code'
import Urls from './Urls'
import Url from './Url'
import Status from './Status'
import { getScoringColorStyle } from './index'

const styles = {
  root: {
    position: 'relative',
    '& > &': {
      marginTop: 17,
      paddingLeft: 32,
      '&:before': {
        content: '"• • • • • •"',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        color: '#9F9F9F',
        fontSize: 7,
        lineHeight: 4.5,
      },
    },
    '& > & > &': {
      paddingLeft: 64,
      '&:before': {
        content: '"• • • • • • • • • • • •"',
      },
    },
    '& > & > & > &': {
      paddingLeft: 80,
      '&:before': {
        content: '"• • • • • • • • • • • • • • • • • •"',
      },
    },
  },
  inline: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  pointer: {
    cursor: 'pointer',
  },
}

// doesn't work in Mozilla
// const PATTERN = /(?<=\{).*?(?=})/gi 

// eslint-disable-next-line
const PATTERN = /[^{\}]+(?=})/gi

const Scoring = ({ 
  classes,
  name,
  code,
  childKey,
  value,
  negative,
  formula,
  state,
  open,
  onOpen,
  onOpenAll,
  scoringChilds,
 }) => {
  const urls = formula ? formula.match(PATTERN) : []
  const parts = formula ? formula.split(PATTERN) : []

  const hasUrls = urls && urls.length
  const hasScoringChilds = scoringChilds && scoringChilds.length > 0

  return open
    ? <div className={classes.root}>
        <Head>
          <Modal.Value className={classes.root}>
            {name}
            <Code>{childKey || code}</Code>
          </Modal.Value>
          <Status
            className={classnames({ [classes.pointer]: hasUrls })} 
            value={value}
            negative={negative}
            onClick={() => hasUrls ? onOpenAll(urls) : {} } 
          />
        </Head>
        {hasUrls 
          ? <Urls>
              {parts.map((part, i) => {
                const key = urls[i]
                const find = hasScoringChilds && scoringChilds.find(({ id }) => id === key)
                const negative = find && find.negative
                return (
                  <Fragment key={i}>
                    {part}
                    <Url
                      active={state[`${code}--${key}`]}
                      onClick={() => onOpen(key)}
                      style={getScoringColorStyle(negative)}
                    >
                      {key}
                    </Url>
                  </Fragment>
                )
              })}
            </Urls>
          : null
        }
        {hasScoringChilds && scoringChilds.map((child, i) => child
          ? <Scoring 
              key={i}
              name={child.name}
              code={code}
              childKey={child.key}
              value={child.value}
              negative={child.negative}
              formula={child.formula}
              onOpen={onOpen}
              onOpenAll={onOpenAll}
              open={state[`${code}--${child.key}`]}
              state={state}
              scoringChilds={child.scoringChilds}
              classes={classes}
            />
          : null
        )}
      </div>
    : null
}

export default injectSheet(styles)(Scoring)