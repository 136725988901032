import React, { useMemo, useState, useEffect } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { InputText, Select1 } from '../../../../layout'
import { getReportAnalysis, getReportAnalysisTasks } from '../../../../redux/Reports/Analysis/actions'
import { commonStatusStyles } from '../all3d/styles'
import { ReportTab } from '../../Layout'
import { useProcessSelectOptions } from '../../useProcessSelectOptions'
import { AnalysisGraphic } from './AnalysisGraphic'
import { YYYYMMDD } from '../../../../utils'

export const AnalysisReport = commonStatusStyles(({
  classes,
  onCancel,
  settings,
}) => {
  const dispatch = useDispatch()
  const { isFetching, data, tasks } = useSelector(state => state?.Reports?.Analysis)

  const now = new Date()
  const currentDate = YYYYMMDD(now)
  const firstDayOfMonth = YYYYMMDD(new Date(now.getFullYear(), now.getMonth(), 1))
  // const firstDayOfMonth = '2018-01-01'

  const { processOptions, processDefault } = useProcessSelectOptions(settings.process)

  const [process, setProcess] = useState(processDefault)
  const [dateFrom, setDateFrom] = useState(firstDayOfMonth)
  const [dateTo, setDateTo] = useState(currentDate)
  const [taskStart, setTaskStart] = useState()
  const [taskEnd, setTaskEnd] = useState()
  const [touched, setTouched] = useState(false)
  const [sent, setSent] = useState(false)

  const onChangeProcess = (e) => {
    setProcess(e.target.value)
    setTaskStart()
    setTaskStart()
  }
  const onChangeDateFrom = (e) => setDateFrom(e.target.value)
  const onChangeDateTo = (e) => setDateTo(e.target.value)
  const onChangeTaskStart = (e) => setTaskStart(e.target.value)
  const onChangeTaskEnd = (e) => setTaskEnd(e.target.value)

  const onGetReport = () => {
    setTouched(true)
    if (taskStart && taskEnd) {
      setSent(true)
      dispatch(getReportAnalysis({ process, dateFrom, dateTo, taskStart, taskEnd }))
    }
  }

  useEffect(() => {
    dispatch(getReportAnalysisTasks({ process }))
  }, [dispatch, process])

  const tasksList = useMemo(
    () => tasks.map(({ taskDefinitionKey, name }) => ({ id: taskDefinitionKey, description: name })),
    [tasks]
  )

  const taskListStart = useMemo(() => {
    if (!taskEnd) {
      return tasksList
    } else {
      const selectedIndex = tasksList.reduce((result, { id }, index) => taskEnd === id ? index : result, -1)
      return tasksList.map((item, index) => ({ ...item, disabled: index >= selectedIndex }))
    }
  }, [tasksList, taskEnd])

  const taskListEnd = useMemo(() => {
    if (!taskStart) {
      return tasksList
    } else {
      const selectedIndex = tasksList.reduce((result, { id }, index) => (taskStart === id ? index : result), -1)
      return tasksList.map((item, index) => ({ ...item, disabled: index <= selectedIndex }))
    }
  }, [tasksList, taskStart])

  return (
    <ReportTab
      onOk={onGetReport}
      onCancel={onCancel}
      okButtonText='Получить отчёт'
      disabled={false}
      loading={isFetching}
      title={settings.description}
    >
      <Col className={classes.form}>
        {processOptions.length > 1 &&
          <div className={classes.type}>
            <Select1
              name='process'
              onChange={onChangeProcess}
              defaultValue={process}
              options={processOptions}
            />
          </div>
        }
        <Row>
          <div className='col-6'>
            <InputText
              type='date'
              name='dateFrom'
              label='Дата с'
              value={dateFrom}
              maxDate={new Date(dateTo)}
              onChange={onChangeDateFrom}
            />
          </div>
          <div className='col-6'>
            <InputText
              type='date'
              name='dateTo'
              label='Дата по'
              value={dateTo}
              minDate={new Date(dateFrom)}
              onChange={onChangeDateTo}
            />
          </div>
        </Row>
        <div className={classes.analysisTasksTitle}>Выберите стартовую и финальную задачу этапа</div>
        <Row style={{ marginTop: 10 }}>
          <div className={classnames('col-6', classes.task)}>
            <Select1
              name='taskStart'
              label='Стартовая задача'
              onChange={onChangeTaskStart}
              defaultValue={taskStart}
              options={taskListStart}
            />
            {touched && !taskStart &&
              <div className={classes.error}>Обязательное поле</div>
            }
          </div>
          <div className={classnames('col-6', classes.task)}>
            <Select1
              name='taskEnd'
              label='Конечная задача'
              onChange={onChangeTaskEnd}
              options={taskListEnd}
              defaultValue={taskEnd}
            />
            {touched && !taskEnd &&
              <div className={classes.error}>Обязательное поле</div>
            }
          </div>
        </Row>
        <div className={classes.content}>
          {sent &&
            (isFetching
              ? null
              : <AnalysisGraphic data={data} onCancel={onCancel} />
            )
          }
        </div>
      </Col>
    </ReportTab>
  )
})