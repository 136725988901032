import { PAYMENT_STATUS_CODES } from '../../constants'
import { isEmptyValue } from '../utils'

const fields = {
  CLIENT_NAME: 'clientName',
  PAYMENT_STATUS: 'paymentStatus'
}

export const filters = [
  { name: fields.CLIENT_NAME, type: 'string', title: 'ФИО' },
  {
    name: fields.PAYMENT_STATUS,
    type: 'list',
    title: 'Статус',
    list: Object.entries(PAYMENT_STATUS_CODES).map(([id, name]) => ({
      id,
      name
    }))
  }
]

export function mergeFilters(oldFilters, newFilters) {
  const resultFilters = {}
  const dirtyMegred = { ...oldFilters, ...newFilters }

  Object.keys(dirtyMegred).forEach(filterKey => {
    if (!isEmptyValue(dirtyMegred[filterKey])) {
      resultFilters[filterKey] = dirtyMegred[filterKey]
    }
  })

  return resultFilters
}
