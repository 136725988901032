import React, { Component } from 'react'
import {connect} from 'react-redux'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import _ from 'lodash'
import ClearButton from '../ClearButton'
import ListItem from './blocks/ListItem'
import { searchByString, clearSearchResults } from '../../redux/Search/actions'
import LoadingIcon from '../../static/img/loading.svg'
import { Label, Error } from '../../layout'
import { Scroll } from '../../containers/Scroll'

const styles = {
  textFieldWithAutoComplete: {
    '& .error': {
      borderColor: 'red',
    },
  },
  input: {
    '&:disabled': {
      background: 'rgba(11, 31, 53, 0.2) !important',
    },
  },
  scroll: {
    maxHeight: 340,
    '&.scrollarea .scrollbar-container.vertical': {
      right: 5,
    },
  },
}

class TextFieldWithAutoComplete extends Component {
  static defaultProps = {
    placeholder: '',
    defaultValue: '',
    value: '',
    classNames: {
      container: '',
      input: '',
      error: ''
    },
    meta: {
      touched: false,
      error: ''
    },
    findAll: false,
  }

  state = {
    focused: false,
    typing: false,
    value: this.props.value,
    showResult: false,
  }

  constructor(props) {
    super(props)
    this.onLoadSuggestions = _.debounce(this.onLoadSuggestions.bind(this), 100)
  }

  componentDidUpdate(prevProps, prevState) {
    const { defaultValue } = this.props
    const { value } = this.state

    if (!prevProps.defaultValue && defaultValue && defaultValue !== value) {
      this.setState({ value: defaultValue })
    }

    if (prevProps.isFetching && !this.props.isFetching) {
      if (prevState.typing && !prevState.focused) {
        this.setState({ typing: false })
      }
    }

    if (this.props.hasReset !== prevProps.hasReset) {
      this.onClearField()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onOutsideClick)
  }

  onClearField = (clearProps = true) => {
    this.setState({ value: '' })
    if (clearProps) {
      this.props.onClear && this.props.onClear(this.props.name, '')
    }
    this.props.dispatch(clearSearchResults())
  }

  onFocus = () => {
    this.setState({ focused: true })
    this.onClearField(false)
    document.addEventListener('click', this.onOutsideClick)
  }

  onBlur = () => {
    this.setState({ value: this.props.value || this.state.value })
  }

  onOutsideClick = ({ target }) => {
    if (this.textField?.contains(target)) {
      return
    }
    this.setState({ focused: false })
    if (this.props.value && !this.state.value) {
      this.onClearField()
    } else {
      this.onToggleResults()
    }
  }

  onToggleResults = () => {
    // const {dispatch} = this.props
    // dispatch(clearSearchResults())
    this.setState({showResult: false})
  }

  onChange = (e) => {
    const { value } = e?.target || {}

    const showResult = !!value.length
    const { findAll, dispatch, request, requestPayload } = this.props
    this.setState({ value, showResult })

    if (showResult) {
      this.onLoadSuggestions(value, findAll, request, requestPayload)
    } else {
      dispatch(clearSearchResults())
    }
  }

  onLoadSuggestions = (value, findAll, request, requestPayload) => this.props.dispatch(searchByString(value, findAll, request, requestPayload))

  onSelectItem = (payload, text) => {
    this.setState({
      value: text,
      showResult: false,
      typing: true,
    })
    this.props.onSelect(payload, this.props.name)
  }

  renderSuggestions() {
    const {
      list,
      classes,
      withoutLink,
      payloadKeys = ['id'],
      requestKey = 'displayName',
      requestSubKeys = ['INN'],
    } = this.props

    const { showResult, value } = this.state

    if (!(list?.length && showResult)) {
      return null
    }

    return (
      <div className='dropdown-menu show'>
        <Scroll className={classes.scroll}>
          {list.map(item =>
            <ListItem
              key={typeof item.id === 'string' ? item.id : item.key}
              data={
                payloadKeys.reduce((result, i) => {
                  result[i] = item[i]
                  return result
                }, {})
              }
              id={item.id}
              text={item[requestKey]}
              textToHighlight={
                requestSubKeys
                  .map(key => typeof key === 'object'
                    ? key?.key?.toLowerCase().includes('date')
                      ? `${key?.name} ${new Date(item[key?.key]).toLocaleDateString('ru-RU')}`
                      : `${key?.name} ${item[key?.key]}`
                    : key.toLowerCase().includes('inn')
                      ? `ИНН ${item[key]}`
                      : key.toLowerCase().includes('date')
                        ? new Date(item[key]).toLocaleDateString('ru-RU')
                        : item[key]
                  )
                  .join(', ')
              }
              searchQuery={value}
              onClick={this.onSelectItem}
              withoutLink={withoutLink}
            />
          )}
        </Scroll>  
      </div>
    )
  }

  render() {
    const {
      classes,
      isFetching,
      name,
      classNames,
      placeholder,
      meta: {
        touched,
        error,
      },
      style,
      disabled,
      label,
    } = this.props

    const {
      value,
      typing,
    } = this.state

    const isError = touched && error

    return (
      <div className={classes.textFieldWithAutoComplete}>
        {label &&
          <Label htmlFor={name}>{label}</Label>
        }
        <div
          className={classnames('dropdown', classNames.container)}
          ref={node => this.textField = node}
        >
          <div style={{ position: 'relative', height: '100%' }}>
            {isFetching && typing
              ? <img className='input-loader' src={LoadingIcon} alt='' />
              : <i className='icon icon-search'/>
            }
            <input
              type='text'
              autoComplete='off'
              className={classnames(classNames.input, classes.input, { 'error': isError })}
              placeholder={placeholder}
              name={name}
              value={value}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              style={style}
              disabled={disabled}
            />
            <ClearButton
              onClear={this.onClearField}
              isHidden={!value.length}
            />
            {this.renderSuggestions()}
          </div>
          {isError &&
            <Error>{error}</Error>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProp = ({ Search }) => {
  return {
    list: Search.list,
  }
}

export default connect(mapStateToProp)(injectSheet(styles)(TextFieldWithAutoComplete))