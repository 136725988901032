import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import Overlay from '../../../Overlay'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: [20, 0],
    flex: 1,
    minHeight: 82,
    '& > .preloader': {
      position: 'relative',
    },
  },
  item: {
    boxSizing: 'borderBox',
    width: '50%',
    padding: [5, 10],
  },
  itemContent: {
    position: 'relative',
    padding: [0, 8, 0, 16],
    height: 32,
    border: [1, 'solid', '#e8e0d1'],
    borderRadius: 4,
    background: '#F9F7F3',
    color: '#333',
    fontSize: 14,
    lineHeight: 30/14,
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: '200ms all',
    '&.disabled': {
      color: '#c6b38d',
    },
  },
  icon: {
    transition: '200ms all',
    '&.disabled': {
      opacity: 0,
    },
  },
  emptyContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 100,
    '& > .preloader': {
      position: 'relative',
    },
  },
})

export const TaskSelect = ({
  options,
  onClickTask,
  loading,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {loading
        ? <Overlay />
        : options.map(({ id, disabled }) =>
            <div className={classes.item} key={id}>
              <div
                className={classnames(classes.itemContent, { disabled })}
                onClick={() => onClickTask(id)}
              >
                <span>{id}</span>
                <img
                  className={classnames(classes.icon, { disabled })}
                  src={require('./down-black.svg')}
                  alt='check-icon'
                />
              </div>
            </div>
          )
      }
    </div>
  )
}