import { getReportFunds as _getReportFunds} from '../../../services/api/reports/funds'
import { setErrorContent } from '../../Error/actions'
import { logoutProcess } from '../../User/actions'
import {
  GET_REPORT_FUNDS_PENDING,
  GET_REPORT_FUNDS_SUCCESS,
  GET_REPORT_FUNDS_ERROR,
} from './actionTypes'

export const getReportFunds = (filters) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REPORT_FUNDS_PENDING,
      data: {},
    })
    const { isSuccess, ...res } = await _getReportFunds(filters)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({
      type: GET_REPORT_FUNDS_SUCCESS,
      data: res.result,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: GET_REPORT_FUNDS_ERROR })
  }
}