import React from 'react'
import { UPLOAD_STATUSES } from '../../../../../../redux/RegisterUploads/utils'
import './FileCard.style.scss'
import { IconDone, IconError, IconLoading } from './components'

export function FileCard(props) {
  const { file, status, uploadDate } = props.file

  return (
    <div className="file-card">
      <div className="file-card__details">
        <div className="file-card__name">{file.name}</div>
        <div className="file-card__data">
          <span className="file-card__data--date">
            {`${uploadDate.toLocaleDateString('ru-RU')}, `}
          </span>
          <span className="file-card__data--time">
            {uploadDate.toLocaleTimeString().slice(0, 5)}
          </span>
        </div>
      </div>
      {status === UPLOAD_STATUSES.IN_PROGRESS && <IconLoading />}
      {status === UPLOAD_STATUSES.SUCCESS && <IconDone />}
      {status === UPLOAD_STATUSES.ERROR && <IconError />}
    </div>
  )
}
