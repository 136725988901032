let build = ''

if (process.env.REACT_APP_BUILD_ID === undefined) { build = require('./default').default }
if (process.env.REACT_APP_BUILD_ID === 'pear') { build = require('./pear').default }
if (process.env.REACT_APP_BUILD_ID === 'pear-leasing') { build = require('./pear-leasing').default }
if (process.env.REACT_APP_BUILD_ID === 'lime') { build = require('./lime').default }
if (process.env.REACT_APP_BUILD_ID === 'papaya') { build = require('./papaya').default }
if (process.env.REACT_APP_BUILD_ID === 'apricot') { build = require('./apricot').default }
if (process.env.REACT_APP_BUILD_ID === 'coconut') { build = require('./coconut').default }
if (process.env.REACT_APP_BUILD_ID === 'coconut-factoring') { build = require('./coconut-factoring').default }
if (process.env.REACT_APP_BUILD_ID === 'rambutan') { build = require('./rambutan').default }
if (process.env.REACT_APP_BUILD_ID === 'pomelo') { build = require('./pomelo').default }
if (process.env.REACT_APP_BUILD_ID === 'longan') { build = require('./longan').default }
if (process.env.REACT_APP_BUILD_ID === 'mangosteen') { build = require('./mangosteen').default }
if (process.env.REACT_APP_BUILD_ID === 'guava') { build = require('./guava').default }
if (process.env.REACT_APP_BUILD_ID === 'onion') { build = require('./default').default }
if (process.env.REACT_APP_BUILD_ID === 'passionfruit') { build = require('./passionfruit').default }

export default build
