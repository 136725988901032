import * as types from './actionTypes'

const initialState = {
  isFetching: false,
  filters: {},
  data: [],
  tasks: [],
  isFetchingTasks: false,
}

export const AnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORT_ANALYSIS_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case types.GET_REPORT_ANALYSIS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
      }
    case types.GET_REPORT_ANALYSIS_ERROR:
      return {
        ...state,
        isFetching: false,
      }

    case types.GET_REPORT_ANALYSIS_TASKS_PENDING:
      return {
        ...state,
        isFetchingTasks: true,
      }
    case types.GET_REPORT_ANALYSIS_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.data,
        isFetchingTasks: false,
      }
    case types.GET_REPORT_ANALYSIS_TASKS_ERROR:
      return {
        ...state,
        isFetchingTasks: false,
      }

    default:
      return state
  }
}
