import React from 'react'
import Icons from './img.svg'

export const Icon = ({
  className,
  iconName,
  style,
  onClick,
}) => 
  <svg className={className} style={style} onClick={onClick}>
    <use xlinkHref={`${Icons}#${iconName}`} />
  </svg>