import React, { useState } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Modal, Icon } from './../../index'
import { THEME_COLOR } from '../../../config'

const styles = {
	root: {
    background: '#f4f4f4',
    marginTop: 10,
    padding: 30,
    borderRadius: 16,
    fontFamily: 'Montserrat',
		position: 'relative',
  },
  list: {
    paddingTop: 30,
    paddingBottom: 30,
    position: 'relative',
  },
  item: {
    position: 'relative',
    '& + &': {
      marginTop: 15,
    },
    '&.isMy': {
      textAlign: 'right',
    },
  },
  message: {
    background: '#fff',
    width: 580,
    padding: [15, 20],
    borderRadius: 12,
    display: 'inline-block',
    verticalAlign: 'top',
    '.isMy &': {
      // background: '#EEF5FE',
      background: '#C9EAE7',
    },
  },
  author: {
    marginBottom: 6,
    '& svg': {
      fill: '#9498A9',
      width: 24,
      height: 24,
      marginRight: 8,
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& span': {
      fontSize: 14,
      lineHeight: 1.8,
      color: '#000',
      fontWeight: 600,
      fontStyle: 'normal',
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  text: {
    fontSize: 14,
    lineHeight: 1.6,
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 500,
    whiteSpace: 'pre-wrap',
  },
  button: {
    background: THEME_COLOR,
    marginTop: 15,
    padding: [6, 20],
    fontSize: 14,
    lineHeight: 1.4,
    color: '#fff',
    borderRadius: 8,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  date: {
    marginTop: 15,
    textAlign: 'right',
    fontSize: 10,
    lineHeight: 1.8,
    color: '#b8b8b8',
    letterSpacing: 1,
    textTransform: 'uppercase',
    '.isMy &': {
      color: '#4a4a4a',
    },
  },
  fieldset: {
    display: 'none',
    position: 'relative',
  },
  input: {
    background: '#fff',
    width: '100%',
    height: 40,
    padding: [11, 48, 11, 16],
    border: [1, 'solid', '#ddd'],
    borderRadius: 16,
    outline: 'none',
    fontSize: 14,
    lineHeight: 1.4,
    color: '#000',
    fontWeight: 500,
    fontFamily: 'Montserrat',
  },
  submit: {
    position: 'absolute',
    right: 12,
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    '& svg': {
      fill: '#22C7B5',
      width: 20,
      height: 22,
      display: 'block',
      pointerEvents: 'none',
    },
  },
}

const Comments = ({
  items,
  classes,
  title = 'Комментарии'
}) => {
  const [value, setValue] = useState('')

  const onChange = (e) => setValue(e.target.value)

  const isItemsIsset = items && items.length

  if (!isItemsIsset) {
    return null
  }

  return (
    <Modal.Block iconName='comments' title={title}>
      <div className={classes.root}>
        <div className={classes.list}> 
          {items.map(({ message, authorName, isMy, postedDateTime, taskName }, index) => 
            <div className={classnames(classes.item, { isMy })} key={`comment-${index}`}>
              <div className={classes.message}>
                <div className={classes.author}>
                  <Icon iconName='user' />
                  <span>{authorName}</span>
                </div>
                <div className={classes.text}>{message}</div>
                <div className={classes.button}>{taskName}</div>
                <div className={classes.date}>{postedDateTime}</div>
              </div>
            </div>
          )}
        </div>
        <div className={classes.fieldset}>
          <input 
            type='text'
            className={classes.input}
            onChange={onChange}
            value={value}
            placeholder='Текст комментария'
          />
          <div className={classes.submit}>
            <Icon iconName='letter' />
          </div>
        </div>
      </div>
    </Modal.Block>
  )
}

export default injectSheet(styles)(Comments)