import * as actionTypes from './actionTypes'

const initialState = {
  payments: [],
  isFetching: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REGISTER_PAYMENTS:
      return {
        ...state,
        isFetching: true
      }
    case actionTypes.GET_REGISTER_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.data,
        isFetching: false
      }
    case actionTypes.GET_REGISTER_PAYMENTS_ERROR:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}
