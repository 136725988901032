import cx from "classnames";
import { copyValue } from "../../utils";
import React, { useState } from "react";

export const Copy = ({ valueToCopy, successText, customStyles = {}, children }) => {
  const [isCopyVisible, setIsCopyVisible] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsCopyVisible(true)}
      onMouseLeave={() => setIsCopyVisible(false)}
      style={{ display: "inline-block", paddingRight: "40px" }}
      className={cx("copy-wrapper")}
    >
      {children}
      <span
        className={isCopyVisible && cx("icon-copy")}
        style={{
          cursor: "pointer",
          position: "absolute",
          marginLeft: "10px",
          ...customStyles
        }}
        onClick={() => copyValue(valueToCopy, successText)}
      />
    </div>
  );
};
