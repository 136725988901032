import axios from 'axios'
import { API_URL } from '../../config'

export const getList = async () => {
  try {
    const { 
      data: { 
        error_code: status, 
        ...rest 
      } 
    } = await axios({
      method: 'GET',
      url: `${API_URL}/dashboard`,
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}