import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import Overlay from '../../Overlay'

const styles = {
	root: {
		border: '2px solid #fff',
    padding: '17px 20px 5px',
    position: 'relative',
    background: '#fff',
    minHeight: '240px',
    borderRadius: '15px',
	},
	item: {
		'& + &': {
			marginTop: '10px',
		},
	},
	bar: {
		display: 'block',
		height: '24px',
		backgroundColor: '#eee',
		borderRadius: '8px',
		background: 'linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2))',
		animation: 'card-loading 2s ease infinite',
		backgroundSize: '600% 600%',
	},
	big: {
		height: '48px',
	},
	w100: {
		width: '100%',
	},
	w80: {
		width: '80%',
	},
}

const Skeleton = ({ 
	classes, 
	showLoader, 
}) =>
	<div className={classes.root}>
		<div className={classes.item}>
			<div className={classnames(classes.bar, classes.w100)} />
		</div>
		<div className={classes.item}>
			<div className={classnames(classes.bar, classes.w80)} />
		</div>
		<div className={classes.item}>
			<div className={classnames(classes.bar, classes.big, classes.w100)} />
		</div>
		<div className={classes.item}>
			<div className={classnames(classes.bar, classes.w80)} />
		</div>
		<div className={classes.item}>
			<div className={classnames(classes.bar, classes.w80)} />
		</div>
		{showLoader && <Overlay />}
	</div>

Skeleton.propTypes = { showLoader: PropTypes.bool }
Skeleton.defaultProps = { showLoader: false }

export default injectSheet(styles)(Skeleton)