import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Label } from '../index'

const useStyles = createUseStyles({
  root: {
    position: 'relative',
  },
  helper: {
    position: 'relative',
  },
  select: {
    background: '#fff',
    width: '100%',
    height: 38,
    padding: [9, 16],
    border: [1, 'solid', '#ddd'],
    borderRadius: 16,
    fontSize: 14,
    outline: 'none',
    '&:hover, &:focus': {
      outline: 'none',
    },
    '&:disabled': {
      opacity: 0.5,
    },
    '&.error': {
      borderColor: 'red',
    },
  },
  option: {
    '&:disabled': {
      opacity: 0.5,
    },
  },
})

export const Select1 = ({
  className,
  getRef,
  options,
  name,
  label,
  defaultValue,
  disabled,
  error,
  value,
  onChange
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <div className={classes.helper}>
        <select
          ref={getRef}
          name={name}
          className={classnames(classes.select, className, { error })}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={onChange}
          value={value}
        >
          <option style={{ display: 'none' }}></option>
          <option value='' disabled>Выберите значение</option>
          {options.map(({ id, description, ...rest }) =>
            <option value={id} key={id} {...rest}>{description}</option>
          )}
        </select>
      </div>
    </div>
  )
}