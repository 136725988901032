import axios from 'axios'
import { API_URL } from '../../config'

export const getBaseSchemaList = async () => {
  const options = {
    method: 'GET',
    params: {},
    url: `${API_URL}/bg-agent-billing/base-schema?onlyNotMarketingCampaign=false&onlyValidOnCurrentDate=false`,
  }
  try {
    const { data: { error_code, ...rest } } = await axios(options)
    // if (error_code === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getBaseSchema = async (id) => {
  const options = {
    method: 'GET',
    params: {},
    url: `${API_URL}/bg-agent-billing/base-schema/${id}`,
  }
  try {
    const { data: { error_code, ...rest } } = await axios(options)
    // if (error_code === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const createBaseSchema = async (payload) => {
  const options = {
    method: 'POST',
    data: payload,
    url: `${API_URL}/bg-agent-billing/base-schema`,
  }
  try {
    const { data: { error_code, ...rest } } = await axios(options)
    // if (error_code === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const updateBaseSchema = async (id, payload) => {
  const options = {
    method: 'PUT',
    data: payload,
    url: `${API_URL}/bg-agent-billing/base-schema/${id}`,
  }
  try {
    const { data: { error_code, ...rest } } = await axios(options)
    // if (error_code === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}