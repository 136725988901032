import React from 'react'
import { createUseStyles } from 'react-jss'
import { InputText as InputTextMain } from '../../../../../../layout'

const useStyles = createUseStyles({
  inputText: {
    textAlign: 'center',
  },
})

export const InputText = (props) => {
  const classes = useStyles()
  return (
    <InputTextMain className={classes.inputText} {...props} />
  )
}