import React from 'react'
import PropTypes from 'prop-types'
import { RegistryPaymentsListItem, RegistryPaymentsLoader } from './components'
import { registerPaymentType } from '../../types'
import './RegistryPayments.style.scss'
import { RegistersPageFilter } from '../RegistersPageFilter'
import { paymentConfig } from '../../filter-utils'

export function RegistryPaymentsList({
  regId,
  payments,
  getRegisterPayments,
  isFetching
}) {
  React.useEffect(() => {
    getRegisterPayments(regId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGetPayments = React.useCallback(
    filters => getRegisterPayments(regId, filters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return (
    <div className="payments-wrapper">
      <div className="payment-filters-wrapper">
        <RegistersPageFilter
          onFiltersChange={handleGetPayments}
          config={paymentConfig}
        />
      </div>
      <div className="payment">
        <div className="payment__head-item">
          <div className="payment__number">№</div>
          <div className="payment__full-name">ФИО</div>
          <div className="payment__sum">Сумма к зачислению</div>
          <div className="payment__status">Статус</div>
          <div className="payment__cash-voucher">Чек</div>
        </div>
        {payments.map(payment => (
          <RegistryPaymentsListItem key={payment.id} payment={payment} />
        ))}
      </div>
      {isFetching && (
        <div className="payment__loading-wrapper">
          <RegistryPaymentsLoader />
        </div>
      )}
    </div>
  )
}

RegistryPaymentsList.propTypes = {
  regId: PropTypes.string.isRequired,
  payments: PropTypes.arrayOf(registerPaymentType),
  getRegisterPayments: PropTypes.func.isRequired,
  isFetching: PropTypes.bool
}
