import * as actionTypes from './actionTypes'

const INITIAL_STATE = {
  selectedItems: {}
}

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case actionTypes.BULK_SELECT_TASKS: {
      return {
        ...state, 
        selectedItems: action.data,
      }
    }
    default: {
      return state
    }
  }
}