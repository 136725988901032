import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    background: '#fff',
    margin: '15px 10px',
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '7px',
    fontWeight: 'bold',
    border: '#fff 1px solid',
    cursor: 'pointer',
    '&[disabled]': {
      background: '#777',
      borderColor: '#777',
      cursor: 'default',
    },
  },
}

const ButtonMassActions = ({
  text, 
  onClick, 
  disabled, 
  classes,
}) =>
  <button 
    type='button'
    className={classes.root} 
    onClick={onClick} 
    disabled={disabled}
  >
    {text}
  </button>

export default injectSheet(styles)(ButtonMassActions)