import React, { useState, useEffect, useRef, useMemo } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import {
  getOrders,
  getNextOrders,
  setOrdersFilter,
  clearAllFilters,
} from '../../redux/Orders/actions'
import { Burger } from '../../components/Burger'
import Filters from '../../components/Filters'
import LoadingNext from '../../components/LoadingNext'
import { ClientAllTasks } from '../../components/CustomDetail/Form'
import Order from './Order'
import { getFilteredRequest } from '../../utils'
import Header from '../../containers/Header'
import Modal from '../../containers/Modal'
import { promiseGetOrders } from '../../services/api/orders'
import { IS_COCONUT_FACTORING } from '../../config'

const styles = {
  root: {
    paddingTop: 82,
    paddingBottom: 82,
    textAlign: 'center',
    position: 'relative',
  },
}

const Orders = ({
  classes,
  location: { 
    search,
    pathname,
  },
  history,
}) => {
  const dispatch = useDispatch()

  const isMonitoringNotificationOrders = pathname === '/monitoringNotificationOrders'

  const entityUrl = isMonitoringNotificationOrders
    ? '/monitoringNotificationOrders'
    : '/orders'

  const {
    orders,
    page,
    more,
    isFetching,
    isFetchingNext,
    filters: ordersFilters,
  } = useSelector(state => state.Orders)

  const {
    settings,
    processDefinitionKeys,
    filters: {
      orders: filters,  
    }
  } = useSelector(state => state.User)

  const orderFilters = useMemo(() => {
    return {
      ...ordersFilters,
      ...(IS_COCONUT_FACTORING
        ? {  
            orderTypeRefId: isMonitoringNotificationOrders
              ? 'monIssueNotification'
              : !ordersFilters?.orderTypeRefId ?
              'factoring,factoringFin,factoringFileImport,factoringContractClosure,factoringContractMigration,factoringContractPreparation,factoringSupplyUpdate,factoringCounterRegistry,factoringPaymentDetails,factoringPaymentImport,commissionNotification,factoringKafkaImport,transferExport,factoringOperDay'
              : ordersFilters?.orderTypeRefId
          }
        : {}
      ),
    }
  }, [ordersFilters, isMonitoringNotificationOrders])

  const nextPage = page + 1

  const [fixed, setFixed] = useState(false)
  const ordersHTMLElement = useRef(null)

  useEffect(() => {
    if (!isFetching) {
      dispatch(getOrders(orderFilters))
    } else {
      promiseGetOrders.cancel()
      dispatch(getOrders(orderFilters))
    }
    // eslint-disable-next-line
  }, [dispatch, orderFilters])

  useEffect(() => {
    window.addEventListener('scroll', onLoadNextPage)
    window.addEventListener('resize', onLoadNextPage)
    
    return (() => {
      window.removeEventListener('scroll', onLoadNextPage)
      window.removeEventListener('resize', onLoadNextPage) 
    })
  })

  const onChangeFilter = _.debounce((filters) => dispatch(setOrdersFilter(filters)), 500)

  const onLoadNextPage = () => {
    if (!fixed && window.pageYOffset > 0) {
      setFixed(true)
    } else if (fixed && window.pageYOffset === 0) {
      setFixed(false)
    }

    if (ordersHTMLElement) {
      const ordersHeight = ordersHTMLElement.current.getBoundingClientRect().height
      if (
        more &&
        !isFetchingNext &&
        orders.length > 0 &&
        window.pageYOffset + window.innerHeight + 200 >= ordersHeight
      ) {
        dispatch(getNextOrders(nextPage, orderFilters))
      }
    }
  }

  const onClearAllFilters = () => dispatch(clearAllFilters())

  const getFilterBody = () => getFilteredRequest('order', orderFilters)

  const hasProcessDefinitionKeys =
    processDefinitionKeys &&
    processDefinitionKeys.length > 0 

  const renderModalNode = () => {
    switch (true) {
      case search.search(/\?client-deal/) !== -1:
        const clientInfo = search.match(/client-deal=[_A-Za-z0-9-]+/g)
        const clientId = clientInfo[0].split('=')[1]
        return (
          <Modal
            topPosition
            modalClass='modal-custom--custom-detail'
            preventOutsideClick
            onCloseModal={() => history.push(entityUrl)}
          >
            <ClientAllTasks id={clientId} />
          </Modal>
        )
      default:
        return null
    }
  }

  const contentNode = renderModalNode()

  return (
    <>
      <Burger />
      <Header burger>
        <div className='container-fluid'>
          <Filters
            isFetching={isFetching}
            isDisable={!orders.length && !Object.keys(filters).length}
            filters={filters}
            // taskTypes={taskTypes}
            // isHidden={Boolean(selectedTasksCount)}
            onChangeFilter={onChangeFilter}
            onFilterStart={getFilterBody}
            selectedFilters={orderFilters}
            onClearAllFilters={onClearAllFilters}
            className='orders'
          />
        </div>
      </Header>
      <div className={classnames(classes.root, 'orders')} ref={ordersHTMLElement}>
        {orders.map(({ id, title, items }, index) => 
          <Order 
            key={index} 
            id={id}
            title={title} 
            items={items}
            isFetching={isFetching}
            entityUrl={entityUrl}
          />
        )}
      </div>
      <LoadingNext loading={isFetchingNext} />
      {hasProcessDefinitionKeys 
        ? <div className='btn-options'>
            <Link
              className='btn-options__link'
              to={`?add-task${settings.formType === 'custom' ? '=bg-pa-agent' : ''}`}
            />
          </div> 
        : null
      }
      <CSSTransition
        timeout={100}
        in={Boolean(contentNode)}
        classNames='fade'
      >
        <div>{contentNode}</div>
      </CSSTransition>
    </>
  )
}

export default injectSheet(styles)(Orders)