import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Tooltip } from 'reactstrap'
import injectSheet from 'react-jss'
import { setActiveTask } from '../../../redux/Tasks/actions'
import { Icon } from './../../../layout'
import Skeleton from './../Skeleton'
import { THEME_COLOR } from './../../../config'

const styles = {
  root: {
    background: '#fff',
    minHeight: 240,
    padding: [17, 0, 8],
    border: [2, 'solid', '#eaeaea'],
    borderRadius: 15,
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      paddingBottom: 15,
      position: 'absolute',
      top: 0,
      right: 10,
      left: 10,
      borderRadius: 15,
      boxShadow: [0, 4, 12, 'rgba(0, 0, 0,.08)'],
      zIndex: 5,
      '& .board-item__checkbox': {
        opacity: 1,
      },
      '& .board-item__status': {
        display: 'flex',
      },
      '& .board-item__title': {
        height: 'auto',
      },
      '& .board-item__name': {
        height: 'auto',
      },
      '& .board-item__user': {
        backgroundColor: '#EAECF5',
        marginBottom: 15,
      },
      '& .board-item__footer': {
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    '&.active': {
      borderColor: THEME_COLOR,
      zIndex: 4,
      '&:hover': {
        zIndex: 5,
      },
      '& .board-item__checkbox': {
        opacity: 1,
      },
    },
    '&.error': {
      // border: [1, 'solid', '#FF3F34'],
      // boxShadow: [0, 4, 4, '#FF3F34'],
      '&:hover': {
        // border: [1, 'solid', '#FF3F34'],
        // boxShadow: [0, 4, 4, '#FF3F34'],
      },
      '& .board-item__title': {
        color: '#FF3F34',
      },
    },
    '& .board-item__checkbox': {
      position: 'absolute',
      top: 0,
      right: 0,
      transition: '.3s',
      opacity: 0,
    },
    '& .board-item__status': {
      marginTop: 12,
      marginBottom: 12,
      paddingLeft: 20,
      paddingRight: 20,
      display: 'none',
      flexWrap: 'wrap',
      '& span': {
        fontSize: 10,
        fontWeight: 'normal',
        lineHeight: 2.3,
        letterSpacing: 1,
        height: 23,
        marginRight: 5,
        marginBottom: 5,
        padding: [0, 12],
        textTransform: 'uppercase',
        color: '#fff',
        borderRadius: 15,
        background: '#c5c7dc',
        '&.info': {
          background: '#c5c7dc',
          margin: 0,
          color: '#fff',
          '& + .error': {
            marginTop: 10,
          },
        },
        '&.warning': {
          background: THEME_COLOR,
          color: '#fff',
        },
        '&.error': {
          background: '#FF3F34',
          color: '#fff',
        }
      },
    },
    '& .board-item__name': {
      marginBottom: 5,
      minHeight: 14,
      paddingLeft: 20,
      paddingRight: 20,
      color: '#000',
      fontSize: 13,
      height: 14,
      lineHeight: 1.15,
      fontWeight: 500,
      overflow: 'hidden',
    },
    '& .board-item__title': {
      minHeight:  36,
      height: 36,
      marginBottom: 8,
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 15,
      fontWeight: 700,
      lineHeight: 1.25,
      color: '#151b3d',
      overflow: 'hidden',
    },
    '& .board-item__user': {
      padding: [12, 20],
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 1.25,
      color: '#000',
      wordBreak: 'break-all',
      '& svg': {
        width: 16,
        height: 16,
        marginRight: 6,
        display: 'inline-block',
        verticalAlign: 'top',
        fill: '#9498A9',
      },
      '& span': {
        '& + svg': {
          width: 14,
          height: 14,
          marginLeft: 4,
          marginRight: 4,
          marginTop: 1,
          fill: '#000',
        },
      },
    },
    '& .board-item__footer': {
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.67,
      color: '#9b9b9b',
      '& > div': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  amount: {
    height: 17,
    marginBottom: 12,
    paddingLeft: 20,
    paddingRight: 20,
    color: '#000',
    fontSize: 15,
    lineHeight: 1.15,
    fontWeight: 500,
    overflow: 'hidden',
  },
  icon: {
    backgroundColor: '#ddd',
    background: 'url(/static/media/user.png) no-repeat center / 20px 20px',
    marginRight: 14,
    width: 20,
    height: 20,
    minWidth: 20,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  minor: {
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1,
    marginBottom: 9,
    color: '#9b9b9b',
    minHeight: 14,
  },
  statuses: {
    height: 28,
    marginTop: 6,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
  },
  status: {
    fontSize: 12,
    lineHeight: 1.67,
    color: '#000',
    fontWeight: 500,
    display: 'inline-block',
    verticalAlign: 'top',
    '&.lotPages': {
      background: '#F7F4F4',
      borderRadius: 14,
      padding: [4, 10],
    },
    '& + &': {
      marginLeft: 10,
    },
  },
  rework: {
    width: 24,
    height: 24,
    marginTop: 2,
    display: 'block',
    fill: '#C4C4C4',
  },
  multiLot: {
    width: 20,
    height: 20,
    marginTop: 4,
    display: 'block',
    fill: '#C4C4C4',
  },
  lotPages: {
    width: 20,
    height: 20,
    marginRight: 7,
    display: 'inline-block',
    verticalAlign: 'middle',
    fill: '#22C7B5',
  },
  new: {
    width: 20,
    height: 20,
    marginTop: 4,
    display: 'block',
    fill: '#1F7FDE',
  },
  special: {
    width: 20,
    height: 20,
    marginTop: 4,
    display: 'block',
    fill: THEME_COLOR,
  },
  closedAuction: {
    width: 24,
    height: 24,
    marginTop: 2,
    display: 'block',
  },
  electronicGuarantee: {
    width: 24,
    height: 24,
    marginTop: 2,
    display: 'block',
  },
  nonStandardGuarantee: {
    width: 24,
    height: 24,
    marginTop: 2,
    marginLeft: -4,
    display: 'block',
  },
  cssChar: {
    background: '#C5C7DC',
    width: 30,
    height: 26,
    borderRadius: 16,
    textAlign: 'center',
    color: '#fff',
    fontSize: 10,
    lineHeight: 26/10,
    fontWeight: 600,
  },
}

const STATUSES = {
  rework: 'Доработка',
  multiLot: 'Многолот',
  lotPages: 'Количество страниц в макете гарантии',
  new: 'Новая заявка',
  special: 'Приоритетная заявка',
  nonStandardGuarantee: 'Нестандартный макет гарантии',
  commercialGuarantee: 'Коммерческая гарантия',
  closedAuction: 'Закрытые торги',
  electronicGuarantee: 'Электронная БГ',
  hasRequiredDocs: 'Приложены все обязательные документы',
  noRequiredDocs: 'Нет одного или всех обязательных документов',
  bankGuaranteeType_returnAdvance: 'Обеспечение на возврат аванса',
  bankGuaranteeType_participation: 'Обеспечение заявки на участие в торгах',
  bankGuaranteeType_execution: 'Обеспечение исполнения обязательств по контракту',
  bankGuaranteeType_period: 'Банковская гарантия на обеспечение гарантийных обязательств',
}

const CHARS = {
  bankGuaranteeType_returnAdvance: 'А',
  bankGuaranteeType_participation: 'У',
  bankGuaranteeType_execution: 'И',
  bankGuaranteeType_period: 'Г',
  commercialGuarantee: 'К',
}

const StatusItem = ({
  id,
  type,
  count,
  value,
  className,
  iconClassName,
  isCssIcon,
  cssChar,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const onMakeTooltip = () => setTooltipOpen(!tooltipOpen)

  return (
    <>
      <div id={id} className={className}>
        {isCssIcon
          ? <div className={iconClassName}>{cssChar}</div>
          : <Icon className={iconClassName} iconName={type} />
        }
        {count && <span>{count}</span>}
      </div>
      <Tooltip
        placement='bottom'
        isOpen={tooltipOpen}
        target={id}
        toggle={onMakeTooltip}
        fade={false}
      >
        {value}
      </Tooltip>
    </>
  )
}

class TaskCard extends Component {
  static propTypes = {
    taskId: PropTypes.string,
    taskName: PropTypes.string,
    items: PropTypes.array.isRequired,
    selected: PropTypes.bool,
    selectedTasks: PropTypes.object.isRequired,
    // isAssigned: PropTypes.bool.isRequired,
    onOpenClaimTask: PropTypes.func.isRequired,
    onOpenDetail: PropTypes.func.isRequired,
    // isHightLightedError: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    taskId: null,
    taskName: null,
    selected: false,
    items: [],
  }

  onShowTaskDetail = ({ target }) => {
    const { onOpenDetail, isAssigned, onOpenClaimTask, setActiveTask } = this.props
    const { taskId, taskName } = target.dataset

    setActiveTask()

    if (isAssigned === false) {
      onOpenClaimTask(taskId, taskName)
      return false
    } else {
      onOpenDetail(taskId, taskName)
    }
  }

  onSelectTask = () => {
    const { taskId, selected, onSelectTask } = this.props
    onSelectTask(taskId, selected)
  }

  onPassToParent = (e) => {
    const { taskId, onClickWithShift } = this.props
    onClickWithShift(e, taskId)
  }

  renderCardContent = ({ items, orderId, classes }) => {
    let content_DOM = []

    const footerItems = items.filter(({ type }) => type === 'footer')
    const isFirstFooterItem = (titleLeft) => footerItems && footerItems[0] && footerItems[0].titleLeft === titleLeft

    items.forEach(({
      type,
      title,
      previousAssignee,
      tags,
      titleLeft,
      titleRight,
      userProfile,
    }, index) => {
      switch (type) {
        case 'money': 
          content_DOM.push(
            <div key={index} className={classes.amount}>
              {title} руб.
            </div>
          )
          break
        case 'assignee':
          content_DOM.push(
            <div className='board-item__user' key={index}>
              <Icon iconName='user' />
              {previousAssignee &&
                <>
                  <span>{previousAssignee}</span>
                  <Icon iconName='arrow-right' />
                </>
              }
              {userProfile?.email
                ? <a href={`mailto:${userProfile?.email}`} target='_blank' rel='noopener noreferrer'>{title}</a>
                : <span>{title}</span>
              }
            </div>
          )
          break
        case 'string':
          content_DOM.push(
            <div key={index} className='board-item__name'>
              {title}
            </div>
          )
          break
        case 'minor':
          content_DOM.push(
            <div key={index} className={classes.minor}>
              {title}
            </div>
          )
          break
        case 'tag':
          content_DOM.push(
            <div key={index} className='board-item__status'>
              {tags.map(({ type, id, title }) =>
                <span className={type} key={id}>{title}</span>
              )}
            </div>
          )
          break
        case 'footer':
          content_DOM.push(
            <div key={index} className='board-item__footer'>
              <div>
                {orderId && isFirstFooterItem(titleLeft)
                  ? <Link to={`/tasks/order/${orderId}`}>{titleLeft}</Link>
                  : titleLeft
                }
              </div>
              <div>{titleRight}</div>
            </div>
          )
          break
        default:
          content_DOM.push(
            <div>Invalid item type</div>
          )
      }
    })
    return content_DOM
  }

  renderStatuses = (statuses, classes) => {
    const { taskId } = this.props 

    return (
      <div>
        {statuses.map(({ type, count }, index) => {
          const isCssIcon = type.includes('bankGuaranteeType_') || type === 'commercialGuarantee'
          return (
            <StatusItem
              key={index}
              id={`${type}-${taskId}`}
              type={type}
              count={count}
              value={STATUSES[type]}
              className={classnames(classes.status, {'lotPages': type === 'lotPages'})}
              iconClassName={isCssIcon
                ? classes.cssChar
                : classes[type]
              }
              isCssIcon={isCssIcon}
              cssChar={CHARS[type]}
            />
          )}
        )}
      </div>
    )
  }
  
  render() {
    const {
      classes,
      taskId,
      taskName,
      items,
      statuses = [],
      selected,
      isHightLightedError,
      isFetching,
      // bulkActions,
      orderId,
    } = this.props

    const statusesLeft = statuses.filter(item => item.align !== 'right')
    const statusesRight = statuses.filter(item => item.align === 'right')

    if (isFetching) {
      return <Skeleton />
    }

    return (
      <div className={classnames(classes.root, {'active': selected, 'error': isHightLightedError})}>
        {taskId
          ? <>
              {/* {bulkActions?.length > 0 && */}
                <div className='board-item__checkbox'>
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      checked={selected}
                      onChange={this.onSelectTask}
                      onClick={this.onPassToParent}
                    />
                    <span className='checkbox__text' />
                  </label>
                </div>
              {/* } */}
              <div
                className='board-item__title'
                data-task-id={taskId}
                data-task-name={taskName}
                onClick={this.onShowTaskDetail}
              >
                {taskName}
              </div>
              {this.renderCardContent({ items, orderId, classes })}
              {statuses && statuses.length
                ? <div className={classes.statuses}>
                    {this.renderStatuses(statusesLeft, classes)}
                    {this.renderStatuses(statusesRight, classes)}
                  </div>
                : null
              }
            </>
          : null
        }
      </div>
    )
  }
}

const mapStateToProps = ({ Tasks }) => {
  return {
    task: Tasks.task,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { taskId } = ownProps

  return {
    setActiveTask: () => {
      dispatch(setActiveTask(taskId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(TaskCard))