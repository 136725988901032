import * as actionTypes from './actionTypes'

const initialState = {
  environment: {
    REACT_APP_NEW_BILLING: process.env.REACT_APP_NEW_BILLING,
    REACT_APP_NEW_BILLING_UI: process.env.REACT_APP_NEW_BILLING_UI,
    REACT_APP_BG_AGENT_BINDING: process.env.REACT_APP_BG_AGENT_BINDING,
    REACT_APP_AGENT_STATS: process.env.REACT_APP_AGENT_STATS,
    REACT_APP_CAN_DELETE_COMPANY_LIMITS: process.env.REACT_APP_CAN_DELETE_COMPANY_LIMITS,
    REACT_APP_CAN_DOWNLOAD_ARCHIVAL_DOCUMENTS: process.env.REACT_APP_CAN_DOWNLOAD_ARCHIVAL_DOCUMENTS,
    REACT_APP_CAN_DOWNLOAD_CONSOLIDATE_ORDER_REPORT: process.env.REACT_APP_CAN_DOWNLOAD_CONSOLIDATE_ORDER_REPORT,
    REACT_APP_SHOW_DBO_PRODUCTS: process.env.REACT_APP_SHOW_DBO_PRODUCTS,
    REACT_APP_PROGRESS_BAR_EXECUTED_COLOR: process.env.REACT_APP_PROGRESS_BAR_EXECUTED_COLOR,
    REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR: process.env.REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    REACT_APP_PROGRESS_BAR_LOST_COLOR: process.env.REACT_APP_PROGRESS_BAR_LOST_COLOR,
    REACT_APP_PROGRESS_BAR_EXPIRED_COLOR: process.env.REACT_APP_PROGRESS_BAR_EXPIRED_COLOR,
    REACT_APP_AGENT_CABINET_URL: process.env.REACT_APP_AGENT_CABINET_URL || '',
    REACT_APP_LIMIT_AGGREGATE_FUNC: process.env.REACT_APP_LIMIT_AGGREGATE_FUNC || 'max',
  },
  isError: false,
  isFetching: false,
  isLoaded: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ENVIRONMENT:
      return {
        ...state,
        isError: false,
        isFetching: true,
      }
    case actionTypes.GET_ENVIRONMENT_SUCCESS:
      return {
        ...state,
        environment: {
          ...state.environment,
          ...action.data,
        },
        isError: false,
        isFetching: false,
        isLoaded: true,
      }
    case actionTypes.GET_ENVIRONMENT_ERROR:
      return {
        ...state,
        isError: true,
        isFetching: false,
      }
    default:
      return state
  }
}