import React, { useEffect } from 'react'
import injectSheet from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { resetCompanyAgentStatsByStatus } from '../../../../redux/Company/actions'
import Overlay from '../../../Overlay'
import { UI, Modal } from './../../../../layout'
import { formatMoney, plural } from '../../../../utils'

const styles = {
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  title: UI.h1,
  row: {
    transition: 'background 300ms ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      background: '#eaecf5',
    },
  },
  table: {
    margin: [20, 0],
    padding: [14, 0],
    color: '#4A4A4A',
    borderTop: [1, 'solid', '#DDDDDD'],
    '& thead': {
      background: '#eee',
      color: '#000',
    },
    '& td': {
      padding: [14, 0],
    },
  },
  cell: {
    maxWidth: '100px',
    margin: [0, 'auto'],
    wordBreak: 'break-all',
  },
}

const StatsModal = ({
  classes,
  status,
  background,
  dateFrom,
  dateTo,
  count,
  onClose,
}) => {
  const dispatch = useDispatch()

  const { agentStatsByStatus } = useSelector(state => state.Company && state.Company) || {}
  const { fetching } = agentStatsByStatus || {}

  useEffect(() => {
    return () => dispatch(resetCompanyAgentStatsByStatus())
  }, [dispatch])

  const items = Object.values(agentStatsByStatus).reduce((result, item) => {
    if (item.companyId) {
      result.push(item)
    }
    return result
  }, [])

  const totalAmount = items.reduce((result, { amount }) => result + amount, 0)

  return (
    <Modal className={classes.root} width='90vw' onClose={onClose}>
      {fetching
        ? <Overlay />
        : <>
          <div className={classes.title}>
            Статистика по заявкам агента в статусе <span style={{background}}>"{status}"</span> в период
            {' '}
            с {new Date(dateFrom).toLocaleDateString('ru-RU')}
            {' '}
            по {new Date(dateTo).toLocaleDateString('ru-RU')}
          </div>
          <div>Всего {count} {plural(count, 'заявка', 'заявки', 'заявок')} на общую сумму {formatMoney(totalAmount)}</div>
          <table className={classes.table}>
            <thead>
              <tr>
                <td>№</td>
                <td>ИНН принципала</td>
                <td>Название принципала</td>
                <td>Номер заявки</td>
                <td>Дата заявки</td>
                <td>Сумма заявки</td>
              </tr>
            </thead>
            <tbody>
              {items.map(({
                displayName,
                inn,
                orderNumber,
                createdDateTime,
                amount,
                // companyId,
                // ogrn,
                // orderId,
              }, index) =>
                <tr key={index} className={classes.row}>
                  <td>{index + 1}</td>
                  <td>{inn}</td>
                  <td>{displayName}</td>
                  <td><div className={classes.cell}>{orderNumber}</div></td>
                  <td>{new Date(createdDateTime).toLocaleDateString('ru-RU')}</td>
                  <td>{formatMoney(amount)}</td>
                </tr>
                )}
            </tbody>
          </table>
        </>
      }
    </Modal>
  )
}

export default injectSheet(styles)(StatsModal)