import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import _noop from 'lodash/noop'
import classNames from './groupActionTasks.module.scss'
// import SelectWithAutoComplete from '../../SelectWithAutoComplete'
import {
  ASSIGNEE__CHANGE,
  PRINT__GUARANTEE,
  PRINT__GUARANTEE__ASSIGN,
  RETRY__RESEND,
} from '../../../constants/bulkOperations'
import { Icon } from './../../../layout'

const styles = {
  result: {
    padding: [12, 20],
    display: 'inline-block',
    verticalAlign: 'top',
  },
  title: {
    fontSize: 12,
    lineHeight: 1.25,
    fontWeight: 700,
    letterSpacing: 0.5,
    color: 'rgba(21, 27, 61, 0.68)',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  actions: {
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
}

const TasksGroupActions = ({
  classes,
  isActive,
  taskCount,
  onClearAll,
  onActionClick,
  selectedTasks,
  ismanager,
}) => {
  const isAssigneeChange = selectedTasks.some(x => x && x.bulkActions && x.bulkActions.includes('assigneeChange'))
  const isPrintGuarantee = selectedTasks.some(x => x && x.bulkActions && x.bulkActions.includes('printGuarantee'))
  const isPrintGuaranteeAssign = selectedTasks.some(x => x && x.bulkActions && x.bulkActions.includes('printGuaranteeAssign'))
  const isRetryResend = selectedTasks.some(x => x && x.bulkActions && x.bulkActions.includes('retryResend'))

  return (
    <div className={classnames(classNames.root, { isActive })}>
      <div className={classes.result}>
        <div className={classNames.close} onClick={onClearAll}>
          <Icon iconName='close2' />
        </div>
        <div className={classes.title}>Выбрано: {taskCount}</div>
      </div>
      <div className={classes.actions}>
        {isAssigneeChange && ismanager
          ? <div
              className={classNames.button}
              onClick={onActionClick.bind(this, ASSIGNEE__CHANGE)}
            >
              Назначить задачу
            </div>
          : null
        }
        {isRetryResend
          ? <div
              className={classNames.button}
              onClick={onActionClick.bind(this, RETRY__RESEND)}
            >
              Повторить попытку Интеграции
            </div>
          : null
        }
        {/*
          <div
            className={classNames.button}
            onClick={onActionClick.bind(this, 'no')}
          >
            Распечатать накладные
          </div>
        */}
        {isPrintGuarantee
          ? <div
              className={classNames.button}
              onClick={onActionClick.bind(this, PRINT__GUARANTEE)}
            >
              Скачать и распечатать
            </div>
          : null
        }
        {isPrintGuaranteeAssign
          ? <div
              className={classNames.button}
              onClick={onActionClick.bind(this, PRINT__GUARANTEE__ASSIGN)}
            >
              Сменить подписанта
            </div>
          : null
        }
      </div>
      {/* <SelectWithAutoComplete/> */}
    </div>
  )
}

TasksGroupActions.propTypes = {
  isActive: PropTypes.bool,
  taskCount: PropTypes.number.isRequired,
  onActionClick: PropTypes.func.isRequired,
  onClearAll: PropTypes.func,
}

TasksGroupActions.defaultProps = {
  isActive: false,
  onClearAll: _noop,
}

export default injectSheet(styles)(TasksGroupActions)