import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCompanyContactPersonInfo } from '../../../../redux/Company/actions'
// import { Attributes } from '../Helpers/Attributes'
import Overlay from '../../../Overlay'

export class ContactPerson extends Component {
  async loadData() {
    const { dispatch, companyId } = this.props
    dispatch(getCompanyContactPersonInfo(companyId))
  }

  componentDidMount() {
    if (!this.props.fetching) {
      this.loadData()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.loadData()
    }
  }

  render() {
    const { contactPerson:  data = {}, fetching } = this.props
    const { items } = data
    const hasSomeValue =
      items &&
      items.length > 0 &&
      items.some(({ value }) => !!value)

    return fetching
      ? <Overlay/>
      : hasSomeValue
        ? <>
            <br/>
            {items.map(({ value }, index) => {
              const lastItem = index === items.length -1
              return (
                <span key={index}>
                  {value}
                  {lastItem ? '' : ', '}
                </span>
              )
            })}
          </>
        : null
        // <div className='block-info'>
        //   <Attributes {...data}/>
        // </div> 
  }
}

const mapStateToProps = ({ Company }) => {
  return {
    ...Company.contactPersonInfo
  }
}

export default connect(mapStateToProps)(ContactPerson)