import * as TYPES from './actionsTypes';

const INITIAL_STATE = {

}

export default function (state = INITIAL_STATE, action){
    switch ( action.type ){
        case TYPES.SETTINGS_SET: {
            return {
                ...state, 
                ...action.data
            }
        }
        default: {
            return state;
        }
    }
}