import axios from 'axios'
import { API_URL } from '../../config'
import _ from 'lodash'
import store from '../../redux/configureStore'
import { getCompanyLimits as getCompanyLimitsAction } from '../../redux/Company/actions'

const API_URL_CRM = `${API_URL}/crm`
const API_URL_REF = `${API_URL}/ref`
const API_URL_COMPANY = `${API_URL}/company`
const API_URL_BG_AGENT_BILLING = `${API_URL}/bg-agent-billing`
const API_URL_BG_AGENT_BILLING_BASE_SCHEMA = `${API_URL_BG_AGENT_BILLING}/base-schema`
const API_URL_BG_AGENT_BILLING_BASE_SCHEMA_AGENT_LINK = `${API_URL_BG_AGENT_BILLING}/base-schema-agent-link`
const API_URL_BG_AGENT_BINDING = `${API_URL_COMPANY}/bgAgentBinding`
const API_URL_COMPANY_WORKGROUP = `${API_URL_COMPANY}/workGroup`
const API_URL_COMPANY_AGENT = `${API_URL_COMPANY}/agent`
const API_URL_COMPANY_AGENT_STATS = `${API_URL_COMPANY_AGENT}/stats`
const API_URL_COMPANY_CLIENT = `${API_URL_COMPANY}/client`

const isCamunda = (url) => /camunda/.test(url)
const isBgAgentBinding = (url) => new RegExp(`${API_URL_BG_AGENT_BINDING}/`).test(url)
const isWorkGroup = (url) => new RegExp(`${API_URL_COMPANY_WORKGROUP}/`).test(url)
const isAgentStats = (url) => new RegExp(`${API_URL_COMPANY_AGENT_STATS}/`).test(url)
const isBgAgentBillingBaseSchema = (url) => url === API_URL_BG_AGENT_BILLING_BASE_SCHEMA
const isBgAgentBillingBaseSchemaAgentLink = (url) => url === API_URL_BG_AGENT_BILLING_BASE_SCHEMA_AGENT_LINK

const apiGet = async (url, params, onSuccess, onError) => {
  const options = { method: 'GET', params, url }
  try {
    const { data } = await axios(options)
    const { error_code, error, errors, ...rest } = data
    if (
      error_code === 0 ||
      isBgAgentBillingBaseSchema(url) ||
      isBgAgentBinding(url) ||
      isWorkGroup(url) ||
      isAgentStats(url)
    ) {

      // for PEAR-1775
      // if (params.relationId && url === `${API_URL_COMPANY_AGENT}/getInfo`) {
      //   return {
      //     isSuccess: true,
      //     ...rest,
      //     info: {
      //       ...rest.info,
      //       billingSigners: rest.info.billingSigners.filter(item => params.relationId !== item.signerRelationId)
      //     }
      //   }
      // }
      onSuccess && onSuccess()
      return {
        isSuccess: true,
        ...rest,
      }
    }
    onError && onError()
    return {
      error,
      errors,
      isSuccess: false,
    }
  } catch (err) {
    console.log(err)
    onError && onError()
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

const apiPost = async (url, data, params, onSuccess, rest) => {
  const options = { method: 'POST', url, data, params }
  try {
    const { data } = await axios(options)
    const { error_code, error, errors, ...rest } = data
    if (
      error_code === 0 ||
      (isCamunda(url) && rest.id) ||
      isBgAgentBillingBaseSchemaAgentLink(url) ||
      isBgAgentBinding(url) ||
      isWorkGroup(url)
    ) {
      onSuccess && onSuccess()
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      error,
      errors,
      isSuccess: false,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  } finally {
    rest?.finallyCallback && rest.finallyCallback()
  }
}

export const getCompanyOrder = async (orderId) => {
  const options = {
    method: 'GET',
    data: { orderId },
    url: `${API_URL}/order/get?orderId=${orderId}`,
  }
  try {  
    const { data } = await axios(options)
    const { error_code, ...rest } = data
    if (error_code === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: error_code === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

const getCompanyMethodUrl = (method) => `${API_URL_COMPANY}/${method}`
const createApiGet = (url) => async (params = {}) => await apiGet(url, params)
const createApiPost = (url) => async (
  data = {},
  params = {},
  onSuccess,
  rest,
) => await apiPost(url, data, params, onSuccess, rest)

const createApiGetByCompanyId = (url) => async (
  companyId,
  relationId,
) => await createApiGet(url)({ companyId, relationId })


// CREATE API GET BY COMPANY ID
export const getBillingSigners = createApiGetByCompanyId(`${API_URL_COMPANY_AGENT}/getBillingSigners`)
export const getCompanyInfo = createApiGetByCompanyId(getCompanyMethodUrl('get'))
export const getCompanyLimits = createApiGetByCompanyId(getCompanyMethodUrl('getLimits'))
export const deleteCompanyLimits = async (companyId, relationId) => {
  const fn = createApiGetByCompanyId(getCompanyMethodUrl('deleteLimits'))
  const result = await fn(companyId, relationId)

  if (result.isSuccess) {
    store.dispatch(getCompanyLimitsAction(companyId, relationId))
  }
  return result
}
export const getCompanyOrders = createApiGetByCompanyId(getCompanyMethodUrl('getOrders'))
export const getCompanyClientLogins = createApiGetByCompanyId(getCompanyMethodUrl('client/getLogins'))
export const getCompanyAgentLogins = createApiGetByCompanyId(getCompanyMethodUrl('agent/getInfo'))
export const getCompanyAgentInfo = createApiGetByCompanyId(getCompanyMethodUrl('agent/getInfo'))
export const registerCompanyAsAgent = createApiGetByCompanyId(getCompanyMethodUrl('agent/register'))
export const getCompanyAddress = createApiGetByCompanyId(getCompanyMethodUrl('getAddress'))
export const getCompanyBankAccounts = createApiGetByCompanyId(getCompanyMethodUrl('getBankAccounts'))
export const getCompanyBuh = createApiGetByCompanyId(getCompanyMethodUrl('getBuh'))
export const getCompanyManagement = createApiGetByCompanyId(getCompanyMethodUrl('getManagement'))
export const getCompanyHead = createApiGetByCompanyId(getCompanyMethodUrl('getHead'))
export const getCompanySigner = createApiGetByCompanyId(getCompanyMethodUrl('getSigner'))
export const getCompanyFounders = createApiGetByCompanyId(getCompanyMethodUrl('getFounders'))
export const getCompanyBeneficiaries = createApiGetByCompanyId(getCompanyMethodUrl('getBeneficiaries'))
export const getCompanyStuffInfo = createApiGetByCompanyId(getCompanyMethodUrl('getStaffInfo'))
export const getCompanyContactPerson = createApiGetByCompanyId(getCompanyMethodUrl('getContactPerson'))
export const getCompanyDocuments = createApiGetByCompanyId(getCompanyMethodUrl('getDocuments'))
export const getCompanyLiabilities = createApiGetByCompanyId(getCompanyMethodUrl('getLiabilities'))
export const getCompanyStatistic = createApiGetByCompanyId(getCompanyMethodUrl('getStatistics'))


// CREATE API POST
export const updateCompanyBgAgentBinding = async (companyId, payload) => await apiPost(`${API_URL_BG_AGENT_BINDING}/${companyId}`, payload)
export const updateCompanyWorkGroups = async (companyId, payload, onSuccess) => await apiPost(`${API_URL_COMPANY_WORKGROUP}/${companyId}`, payload, null, onSuccess)
export const addBankManager = createApiPost(`${API_URL_COMPANY_AGENT}/addManagerLogin`)
export const removeBankManager = createApiPost(`${API_URL_COMPANY_AGENT}/deleteManagerLogin`)
export const updateCbsManager = createApiPost(`${API_URL_COMPANY_AGENT}/updateAgentCbsId`)
export const addBillingSigner = createApiPost(`${API_URL_COMPANY_AGENT}/addBillingSigner`)
export const addPerson = createApiPost(`${API_URL_CRM}/createPerson`)
export const addBillingSchema = (billingSchema) => createApiPost(`${API_URL_COMPANY_AGENT}/addBillingSchema`)
  .call(null, _.omit(billingSchema, 'companyId', 'number'), { companyId: billingSchema.companyId, number: billingSchema.number })
export const updateBillingSchema = (billingSchema) => createApiPost(`${API_URL_COMPANY_AGENT}/updateBillingSchema`)
  .call(null, _.omit(billingSchema, 'companyId', 'number'), { companyId: billingSchema.companyId, number: billingSchema.number })
export const updateBillingContract = (billingContract) => createApiPost(`${API_URL_COMPANY_AGENT}/updateBillingContract`)
  .call(null, null, billingContract)
export const addBillingContract = (billingContract) => createApiPost(`${API_URL_COMPANY_AGENT}/addBillingContract`)
  .call(null, null, billingContract)
export const companyStartSubprocess = (payload, process_definition_key, onSuccess, rest) =>
  createApiPost(`/camunda/api/engine/engine/default/process-definition/key/${process_definition_key}/start`)
  .call(null, payload, null, onSuccess, rest)
export const addBaseSchema = (payload) => createApiPost(API_URL_BG_AGENT_BILLING_BASE_SCHEMA_AGENT_LINK)
  .call(null, null, payload)


// CREATE API GET
export const getCompanyBgAgentBinding = async (companyId) => await apiGet(`${API_URL_BG_AGENT_BINDING}/${companyId}`)
export const addCompanyAgentLogin = async (payload) => await apiGet(`${API_URL_COMPANY_AGENT}/addLogin`, payload)
export const removeCompanyAgentLogin = async (payload) => await apiGet(`${API_URL_COMPANY_AGENT}/deleteLogin`, payload)
export const addCompanyClientLogin = async (payload) => await apiGet(`${API_URL_COMPANY_CLIENT}/addLogin`, payload)
export const removeCompanyClientLogin = async (payload) => await apiGet(`${API_URL_COMPANY_CLIENT}/deleteLogin` , payload)
export const getCompanyWorkGroups = async (companyId) => await apiGet(`${API_URL_COMPANY_WORKGROUP}/${companyId}`)
export const getCompanyAgentStats = async (companyId, payload) => await apiGet(`${API_URL_COMPANY_AGENT_STATS}/${companyId}/ordersCount`, payload)
export const getCompanyAgentStatsByStatus = async (companyId, status, payload) => await apiGet(`${API_URL_COMPANY_AGENT_STATS}/${companyId}/orders/${status}`, payload)

// CREATE API GET (BY QUERY PARAMS)
export const searchPersons = createApiGet(`${API_URL_CRM}/searchPerson`)
export const searchAddress = (q) => createApiGet(`${API_URL}/suggest/address`).call(null, {q})
export const searchIdentityDocumentType = createApiGet(`${API_URL_REF}/getIdentityDocumentType`)
export const getCompanyStatisticInclude = createApiGet(getCompanyMethodUrl('includeOrderLoad'))
export const getCompanyStatisticExclude = createApiGet(getCompanyMethodUrl('excludeOrderLoad'))
export const removeBillingSchema = createApiGet(`${API_URL_COMPANY_AGENT}/deleteBillingSchema`)
export const getBaseSchemaList = createApiGet(API_URL_BG_AGENT_BILLING_BASE_SCHEMA)
export const removeBillingContract = createApiGet(`${API_URL_COMPANY_AGENT}/deleteBillingContract`)
export const getBankSignersBilling = createApiGet(`${API_URL_CRM}/getBankSignersBilling`)
export const removeBillingSigner = createApiGet(`${API_URL_CRM}/deleteRelation`)