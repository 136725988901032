import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Modal } from '../index'
import { Copy } from '../../../components/Copy/Copy'

const useStyles = createUseStyles({
	root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export const Principal = ({
  name,
  inn,
  kpp,
  companyId,
  orderTypeRefId,
  children,
}) => {
  const classes = useStyles()
  const isFactoring = orderTypeRefId === 'factoring'
  return (
    <Modal.Block iconName='shield' title={isFactoring ? 'Поставщик' : 'Принципал'}>
      <Modal.Content>
        <div className={classes.root}>
          <div>
            <Modal.Title>
              ИНН / Наименование {isFactoring ? 'поставщика' : 'принципала'}
            </Modal.Title>
            <Modal.Value>
              <Copy successText={"Название скопировано."} valueToCopy={name}>
                <Link
                  to={`/orders?client-deal=${companyId}&section=home`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {name}
                </Link>
              </Copy>
              <p>
                <Copy successText={"ИНН скопирован"} valueToCopy={inn}>
                  {" "}
                  ИНН {inn}{","}
                </Copy>
                <Copy successText={"КПП скопирован"} valueToCopy={kpp}>
                  КПП {kpp}
                </Copy>
              </p>
            </Modal.Value>
          </div>
          {children}
        </div>
      </Modal.Content>
    </Modal.Block>
  )
  }