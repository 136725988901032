import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
  headerFilter: {
    position: 'relative',
    zIndex: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 62,
    padding: [20, 20, 0, 0],
    '& > *': {
      marginLeft: 10,
    },
    '@media (min-width: 1537px)': {
      '& > *': {
        flex: 1,
      },
      '& > .board-item__checkbox--filter': {
        flex: 0,
      },
    },
    '&.hidden': {
      display: 'none',
    },
    '&.disabled': {
      '&:before': {
        display: 'block',
        content: '',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'hsla(0, 0%, 100%, .5)',
        zIndex: 9,
      },
    },
    '&.orders': {
      '& > *': {
        flex: 1,
      },
    },
    '& .filter-input__picker-wrap .filter-input': {
      width: 'auto',
    },
  },
}

const HeaderFilter = ({
  classes,
  className,
  hidden,
  disabled,
  children,
}) => 
  <div className={classnames(classes.headerFilter, className, { hidden, disabled })}>
    {children}
  </div>

export default injectSheet(styles)(HeaderFilter)