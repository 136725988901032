import React, { memo } from 'react'
import { useStyles } from './styles'

export const CommissionSetCounter = memo(({
  totalDraftsMatchedAmount,
  totalOrdersUnmatchedAmount,
  totalSelectedTasksUnmatchedAmount,
}) => {
  const classes = useStyles()

  const totalDifferenceAmount = totalSelectedTasksUnmatchedAmount - (totalOrdersUnmatchedAmount + totalDraftsMatchedAmount)

  return (
    <div style={{margin: '0 30px'}}>
      <div className={classes.item}>
        <span>Выбрано поставок на сумму:</span> <span className={totalDifferenceAmount < 0 && classes.amountError}>{totalOrdersUnmatchedAmount + totalDraftsMatchedAmount}</span>
      </div>
      <div className={classes.item}>
        <span>Сумма остатка по платежу:</span> <span>{totalSelectedTasksUnmatchedAmount}</span>
      </div>
      <div className={classes.item}>
        <span>Разница:</span> <span className={totalDifferenceAmount < 0 && classes.amountError}>{totalDifferenceAmount}</span>
      </div>
    </div>
  )
})