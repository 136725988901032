import React from 'react'
import format from 'date-fns/format'

import DatePicker from '../../../../../../components/DatePicker'

import { Conditions, transformFilterQuery } from '../../utils'

function toDate(dateStr) {
  if (!dateStr) return '';

  const parts = dateStr.split('.')
  return new Date(parts[2], parts[1] - 1, parts[0])
}

function formatISO(date) {
  if (!date) return '';

  return format(toDate(date), 'yyyy-MM-dd') + 'T00:00:00.000000Z'
}

const fields = {
  nameFrom: 'createdFrom',
  nameTo: 'createdTo'
}

export function DateFilter({ resetFilter, onChangeFilter, name }) {
  const onSelectDate = React.useCallback(
    ({ target: { value } }) => {
      const values = value.split('/')
      onChangeFilter(
        [
          transformFilterQuery(Conditions.LARGER, formatISO(values[0]), name),
          transformFilterQuery(Conditions.SMALLER, formatISO(values[1]), name)
        ].join(',')
      )
    },
    [onChangeFilter, name]
  )

  const onClear = React.useCallback(() => onChangeFilter(''), [onChangeFilter])

  return (
    <DatePicker
      name={`${fields.nameFrom}/${fields.nameTo}`}
      onSelectDate={onSelectDate}
      hasReset={resetFilter}
      onClear={onClear}
    />
  )
}
