import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
  },
  wrapper: {
    maxWidth: 472,
    margin: [0, 'auto'],
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  attention: {
    fontSize: 56,
    lineHeight: 1,
    fontWeight: 600,
    color: '#e7391e',
  },
  title: {
    marginTop: 16,
    fontSize: 24,
    lineHeight: 1,
    fontWeight: 600,
  },
  text: {
    marginTop: 16,
    color: '#858585',
    fontSize: 14,
    lineHeight: 1.5,
  }
})

export const OldBrowser = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.attention}>Внимание</div>
        <div className={classes.title}>Ваш браузер не поддерживается</div>
        <div className={classes.text}>
          Вы пользуетесь устаревшей версией браузера.
          Данная версия браузера не поддерживает многие современные технологии.
          Для корректной работы сайта рекомендуем установить последнюю версию {' '}
          <a href='http://www.google.com/chrome/' target='_blank' rel='noopener noreferrer'>Google Chrome</a>.
        </div>
      </div>
    </div>
  )
}