import { useMemo } from 'react'
import { uas } from '../../../../services/utility/charts/uas'
import { useChartSize } from '../../useChartSize'

const titles = {
  federalLaw: 'Закон',
  orderInitiatorType: 'Инициатор',
  orderProductsType: 'Продукт',
  orderBailsType: 'Обеспечение',
  productDuration: 'Срок',
  amounts: 'Сумма',
}
const colors = {
  federalLaw: ['#9BA7A7', '#C8D3D3'],
  orderInitiatorType: ['#996411', '#FBBB23', '#FF8C00'],
  orderProductsType: ['#996411', '#FBBB23', '#FF8C00'],
  orderBailsType: ['#9BA7A7', '#C8D3D3'],
  amounts: ['#8AB7BC', '#559DA4', '#3A858E', '#0E6B75', '#005E6B'],
  productDuration: ['#8A7BBC', '#55D9A4', '#3A588E', '#0EB675', '#00E56B'],
}

export const useAnalysisGraphicOptions = ({ data, onClickSeries }) => {
  const { width } = useChartSize({ fullWidth: false })

  const memoizedOptions = useMemo(() => {

    const response = data

    // get max time
    let maxTime = 0
    for (let l1 in response) {
      if (l1 === 'total') continue

      for (let l2 in response[l1]) {
        if (response[l1][l2].time > maxTime) {
          maxTime = response[l1][l2].time
        }
      }
    }
    maxTime += Math.round(maxTime / 16)

    return Object.keys(response)
      .filter(l1 => l1 !== 'total')
      .map(l1 => {
        const series = []
        let idx = 0

        for (var l2 in response[l1]) {
          series.push({
            color: colors[l1] ? colors[l1][idx] : null,
            data: [
              {
                y: Math.round(response[l1][l2].time),
                data: {
                  type: l1,
                  key: l2,
                  title: response[l1][l2].name,
                  median: response[l1][l2].median,
                  orderCount: response[l1][l2].orderCount,
                  time: response[l1][l2].time,
                },
              },
            ],
          })

          idx++
        }

        return {
          lang: {
            downloadPNG: 'Скачать PNG файл',
          },
          chart: {
            width,
            height: idx * 40 + 50,
            type: 'bar',
            spacing: [16, 32, 8, 32],
            events: {
              beforePrint: function () {
                this.update({
                  title: {
                    y: 12,
                  },
                })
              },
              afterPrint: function () {
                // if (!$container.hasClass('fullscreen')) {
                //     this.update({
                //         title: {
                //             y: -32
                //         }
                //     });
                // }
              },
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: ['downloadPNG'],
              },
            },
            scale: 4,
            chartOptions: {
              title: {
                y: 12,
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true,
                  },
                },
              },
            },
          },
          title: {
            text: titles[l1] || '',
            align: 'left',
            margin: 0,
            style: {
              fontSize: 16,
            },
          },
          tooltip: {
            useHTML: true,
            followPointer: true,
            formatter: function () {
              var output = ['<table class="chart-tooltip">']

              output.push(
                '<tr>' +
                  '<td></td>' +
                  '<th class="merge"></th>' +
                  '<th class="right">время</th>' +
                  '<th class="right">медиана</th>' +
                  '<th class="right">сделок</th>' +
                  '</tr>'
              )
              var point = this.point

              output.push(
                '<tr>' +
                  '<td><span style="color:' +
                  point.color +
                  ';">\u25A0 </span></td>' +
                  '<th class="merge">' +
                  point.data.title +
                  '</th>' +
                  '<td class="right">' +
                  uas.helpers.timeFormat(
                    Math.round(point.data.time * 60),
                    '%Hч:%Mм'
                  ) +
                  '</td>' +
                  '<td class="right">' +
                  uas.helpers.timeFormat(
                    Math.round(point.data.median * 60),
                    '%Hч:%Mм'
                  ) +
                  '</td>' +
                  '<td class="right">' +
                  point.data.orderCount +
                  '</td>' +
                  '</tr>'
              )

              output.push('</table>')

              return output.join(' ')
            },
          },
          xAxis: {
            title: {
              text: '',
            },
            labels: {
              enabled: false,
            },
            tickLength: 0,
          },
          yAxis: {
            min: 0,
            max: maxTime,
            title: {
              text: '',
            },
            showFirstLabel: false,
            showLastLabel: false,
            labels: {
              formatter: function () {
                return uas.helpers.timeFormat(this.value * 60, '%Hч:%Mм')
              },
            },
          },
          plotOptions: {
            bar: {
              groupPadding: 0,
              pointPadding: 0,
              maxPointWidth: 20,
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return this.point.data.title
                },
              },
            },
            series: {
              stickyTracking: false,
              events: {
                click: onClickSeries,
              },
              cursor: 'pointer',
            },
          },
          legend: {
            enabled: false,
          },
          series: series,
        }
      })
  }, [data, onClickSeries, width])

  return memoizedOptions
}
