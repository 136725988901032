import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  error: {
    marginTop: 4,
    fontSize: 12,
    lineHeight: 16/12,
    color: 'red',
    textAlign: 'left',
    display: 'block',
  },
})

export const Error = ({
  className,
  children,
}) => {
  const classes = useStyles()
  return (
    <div
      className={classnames(classes.error, className)}
      dangerouslySetInnerHTML={{__html: children}}
    />
  )
}