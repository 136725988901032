import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const MAX_COUNT_VISIBLE_ITEMS = 6

const TaskBlockLink = ({ title, count, data }) =>
  <Link className='dash__item' to={{
    pathname: '/tasks',
    state: {
      setFilter: true,
      data,
    },
  }}>
    <div>{title}</div>
    <span>{count}</span>
  </Link>

class TasksBlock extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onSelectFilter: PropTypes.func.isRequired,
    onActive: PropTypes.func.isRequired,
    name: PropTypes.string,
    total: PropTypes.oneOfType([() => null, PropTypes.number]),
    list: PropTypes.array,
    count: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    name: '',
    total: null,
    list: [],
  }

  onSelectTask = (clientId, clientName) => {
    const { id, onSelectFilter } = this.props
    onSelectFilter({ 
      typeId: id, 
      clientId, 
      clientName,
    })
  }

  render() {
    const { 
      title, 
      count, 
      list = [], 
      id, 
      name,
      isActive,
      onActive,
    } = this.props

    const visibleItems = list.slice(0, MAX_COUNT_VISIBLE_ITEMS)
    const invisibleItmes = list.slice(MAX_COUNT_VISIBLE_ITEMS)
    const hasInvisible = invisibleItmes.length > 0

    return (
      <div className='dash__block'>
        <Link className='dash__block-h' to={{
          pathname: '/tasks',
          state: {
            setFilter: true,
            data: { 
              [name]: id, 
            },
          },
        }}>
          {title} 
          <span>{count}</span>
        </Link>
        <div className='d-flex'>
          {visibleItems.map(({ id: companyId, title, count, name: itemName }) =>
            <TaskBlockLink 
              key={companyId}
              title={title}
              count={count}
              data={{
                [name]: id,
                [itemName]: companyId,
              }}
            />
          )}
          {hasInvisible ? 
            <div className='dash__drop' onClick={() => onActive(isActive ? '' : id)}>
              <div className='dash__drop-main'><span /><span /><span /></div>
              {isActive &&
                <div className='dash__drop-list'>
                  <div className='dash__drop-list-wrap'>
                    {invisibleItmes.map(({ id: companyId, title, count, name: itemName }) =>
                      <TaskBlockLink 
                        key={companyId}
                        title={title}
                        count={count}
                        data={{
                          [name]: id,
                          [itemName]: companyId,
                        }}
                      />
                    )}
                  </div>
                </div>
              }
            </div>
            : null
          }
        </div>
      </div>
    )
  }
}

export default TasksBlock