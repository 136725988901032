export const TASKS_FETCH = 'TASKS_FETCH'
export const TASKS_SUCCESS = 'TASKS_SUCCESS'
export const TASKS_ERROR = 'TASKS_ERROR'

export const NEXT_TASKS_FETCH = 'NEXT_TASKS_FETCH'
export const NEXT_TASKS_SUCCESS = 'NEXT_TASKS_SUCCESS'
export const NEXT_TASKS_ERROR = 'NEXT_TASKS_ERROR'

export const TASKS_SET_FILTER = 'TASKS_SET_FILTER'
export const TASKS_CLEAR_FILTERS = 'TASKS_CLEAR_FILTERS'

export const TASKS_INSERT = 'TASKS_INSERT'

export const TASKS_SET_ASSIGNED_TASK = 'TASKS_SET_ASSIGNED_TASK'
export const TASK_SET_ACTIVE = 'TASK_SET_ACTIVE'
export const TASK_CLEAR_ACTIVE = 'TASK_CLEAR_ACTIVE'

export const TASK_SELECT = 'TASK_SELECT'
export const TASK_UNSELECT = 'TASK_UNSELECT'
export const TASK_UNSELECT_ALL = 'TASK_UNSELECT_ALL'