import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  fieldset: {
    position: 'relative',
    '& + &': {
      marginTop: 20,
    },
  },
})

export const Fieldset = ({ className, children }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.fieldset, className)}>{children}</div>
  )
}
