import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Drilldown from 'highcharts-drilldown'
import { commonStatusStyles } from '../all3d/styles'
import { FullScreenIcon } from '../../Layout/FullScreenIcon'

Drilldown(Highcharts)

const DEFAULT_OPTIONS = {
  chart: {
    type: 'column',
    width: 800,
    height: 400,
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: ['downloadPNG'],
      },
    },
  },
  title: {
    text: 'Воронка',
  },
  subtitle: {
    text: 'Кликните по столбцу диаграммы для детализации',
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    type: 'category',
    crosshair: true,
  },
  yAxis: [
    // Primary yAxis
    { 
      title: {
        text: 'Суммы гарантий',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      labels: {
        format: '{value} млн. руб.',
        style: {
          color: Highcharts.getOptions().colors[0]
        },
      },
    },
    // Secondary yAxis
    // { 
    //   labels: {
    //     enabled: false,
    //     format: '{value} шт.',
    //   },
    //   opposite: true,
    //   title: {
    //     text: ' ',
    //     style: {
    //       color: Highcharts.getOptions().colors[1]
    //     },
    //   },
    // }, 
  ],
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: '{point.y}',
      },
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>'
  },
}

const toMillions = (value) => Number((value / 1000000).toFixed(3))

export const FunnelGraphic = commonStatusStyles(({
  classes,
  fullWidth,
  changeFullWidth,
}) => {
  const chartRef = useRef()

  const { data = [] } = useSelector(state => state?.Reports?.Funnel)

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.width = 1000
    }
  }, [chartRef])

  const seriesDataTotalAmount = data?.reduce((result, item) => {
    result.push({
      drilldown: item?.id,
      name: item?.name,
      y: toMillions(item?.totalAmount),
    })
    return result 
  }, [])

  // const seriesDataCounter = data?.reduce((result, item) => {
  //   result.push({
  //     drilldown: item?.id,
  //     name: item?.name,
  //     y: item?.counter,
  //   })
  //   return result 
  // }, [])

  const drilldown = {
    series: data?.reduce((result, item) => {
      result.push({
        id: item?.id,
        name: item?.name,
        data: item?.drilldowns?.map(itm => [itm?.name, toMillions(itm?.totalAmount)]),
      })
      return result
    }, [])
  }

  // console.log('seriesDataTotalAmount', seriesDataTotalAmount)
  // console.log('seriesDataCounter', seriesDataCounter)
  // console.log('drilldown', drilldown)

  return (
    <div className={classes.graphicContainer}>
      <FullScreenIcon fullWidth={fullWidth} onClick={changeFullWidth} />
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...DEFAULT_OPTIONS,
          series: [
            // {
            //   name: 'Воронка',
            //   type: 'column',
            //   colorByPoint: true,
            //   yAxis: 1,
            //   tooltip: {
            //     valueSuffix: ' шт.',
            //   },
            //   data: seriesDataCounter,
            // },
            {
              name: 'Воронка',
              colorByPoint: true,
              type: 'column',
              tooltip: {
                valueSuffix: ' млн. руб.',
              },
              color: 'rgba(0, 0, 0, 0.1)',
              data: seriesDataTotalAmount,
            },
          ],
          drilldown,
        }}
        ref={chartRef}
      />
    </div>
  )
})