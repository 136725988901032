import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  h2: {
    padding: 10,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: 36/24,
    color: '#0B1F35',
  },
  container: {
    marginTop: 20,
    border: [1, 'solid', '#B6BCC3'],
  },
  tableWrapper: {
    maxHeight: 500,
    overflow: 'auto',
  },
  table: {
    marginBottom: 0,
    '& thead': {
      '& th': {
        background: '#eee',
        textAlign: 'left',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        '&:first-child': {
          width: 40,
        },
        '& + th': {
          '& > div': {
            borderLeft: [1, 'solid', '#B6BCC3'],
          },
        },
        '& > div': {
          padding: 10,
          borderTop: [1, 'solid', '#B6BCC3'],
          fontSize: 12,
          lineHeight: 20/12,
          color: '#888',
          fontWeight: 600,
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: -1,
            left: 0,
            right: 0,
            height: 1,
            background: '#B6BCC3',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '&.sticky': {
          '&:first-of-type': {
            '& > td': {
              '& > div': {
                borderTop: [1, 'solid', '#B6BCC3'],
                borderBottom: 'none',
              },
            },
          },
          '& + &': {
            '& > td': {
              borderTop: [1, 'solid', '#B6BCC3'],
              borderBottom: 'none',
            },
          },
          '& > td': {
            position: 'sticky',
            top: 40,
            zIndex: 2,
            '& > div': {
              borderBottom: [1, 'solid', '#B6BCC3'],
            },
          },
          '& + tr:not(.sticky)': {
            '& > td': {
              '& > div': {
                borderTop: 'none',
              },
            },
          },
        },
        '&.draft': {
          '& > td': {
            '& > div': {
              background: '#cce5ff',
              color: '#004085',
            },
          },
        },
        '& + tr': {
          '& > td': {
            '& > div': {
              borderTop: [1, 'solid', '#B6BCC3'],
            },
          },
        },
      },
      '& td': {
        padding: 0,
        whiteSpace: 'nowrap',
        textAlign: 'left',
        verticalAlign: 'top',
        '&:first-child': {
          background: '#eee',
          padding: 0,
        },
        '& + td': {
          '& > div': {
            borderLeft: [1, 'solid', '#B6BCC3'],
          },
        },
        '&.colspan': {
          '& > div': {
            color: '#0B1F35',
            fontSize: 14,
            lineHeight: 20/14,
            fontWeight: 600,
            background: '#F3F4F5',
            padding: [10, 10, 10, 50],
          },
        },
        '& > div': {
          background: '#fff',
          minHeight: 40,
          padding: 10,
          fontSize: 14,
          lineHeight: 20/14,
          color: '#0B1F35',
          whiteSpace: 'nowrap',
          '&.status': {
            background: '#D5F3E2',
            padding: [6, 22],
            borderRadius: 999,
            color: '#A9B563',
            fontWeight: 600,
          },
          '&.verified': {
            background: '#FEF2E6',
            padding: 6,
            color: '#F57C00',
            fontSize: 10,
            lineHeight: 13/10,
            fontWeight: 600,
            textTransform: 'uppercase',
            borderRadius: 999,
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '&.rejected': {
            background: '#FDEAE9',
            padding: 6,
            color: '#FF5C5C',
            fontSize: 10,
            lineHeight: 13/10,
            fontWeight: 600,
            textTransform: 'uppercase',
            borderRadius: 999,
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '&.ellipsis': {
            // maxWidth: 70,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '&.bold': {
            fontWeight: 600,
          },
          '& > span': {
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '& > svg': {
            width: 12,
            height: 12,
            marginLeft: 5,
            display: 'inline-block',
            verticalAlign: 'top',
          },
        },
      },
    },
  },
  info: {
    background: '#F3F4F5',
    minHeight: 60,
    padding: 10,
    color: '#0B1F35',
    fontSize: 16,
    lineHeight: 20/16,
    borderTop: [1, 'solid', '#B6BCC3'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      fontWeight: 600,
    },
  },
  infoItem: {
    '& + &': {
      marginTop: 10,
    },
  },
  infoList: {
    marginTop: 5,
    paddingLeft: 20,
    listStyle: 'disc',
    '& > li': {
      marginTop: 5,
    },
  },
  checkbox: {
    width: 20,
    height: 20,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  checkboxOrders: {
    width: 150,
    whiteSpace: 'nowrap',
    marginLeft: 20
  },
  name: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  tabs: {
    padding: 10,
  },
  tabsOrders: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tab: {
    padding: [10, 20],
    border: [1, 'solid', '#ECECEC'],
    color: '#0b1f35',
    fontSize: 13,
    lineHeight: 20/13,
    fontWeight: 600,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    '&.active': {
      background: '#0b1f35',
      color: '#fff',
      '& > span': {
        background: '#fff',
        color: '#0b1f35',
      },
    },
    '& + &': {
      marginLeft: 20,
    },
    '& > span': {
      background: '#0b1f35',
      marginLeft: 8,
      padding: [0, 8],
      color: '#fff',
      orderRadius: 4,
      display: 'inline-block',
      verticalAlign: 'top',
    }
  },
  remove: {
    width: 16,
    height: 16,
    margin: 2,
    cursor: 'pointer',
    '& > svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  filters: {
    padding: 10,
    borderBottom: [1, 'solid', '#B6BCC3'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterItem: {
    flex: 1,
    '& + &': {
      marginLeft: 10,
    },
  },
  buttons: {
    marginLeft: 30,
    whiteSpace: 'nowrap',
  },
  operDays: {
    position: 'absolute',
    top: 8,
    right: 0,
    '& table': {
      background: '#eee',
      width: 300,
      margin: 0,
      border: [1, 'solid', '#B6BCC3'],
      '& td': {
        padding: 10,
        fontSize: 12,
        lineHeight: 16/12,
        fontWeight: 600,
        textTransform: 'uppercase',
        color: '#888',
        whiteSpace: 'nowrap',
        '&:first-child, &:last-child': {
          padding: 10,
        },
        '& + td': {
          borderLeft: [1, 'solid', '#B6BCC3'],
        },
      },
    },
  },
  amountError: {
    color: '#ef3124',
  }
})