import * as actionTypes from './actionTypes'

const initialState = {
  isFetching: false,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.PAYMENT_DONE:
      return {
        ...state,
        isFetching: true
      }
    case actionTypes.PAYMENT_DONE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case actionTypes.PAYMENT_DONE_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}