import React, { memo } from 'react'
import classnames from 'classnames'
import { Checkbox } from '../../../layout'
import { RowValue } from '../RowValue'
import { useStyles } from './styles'
import { ORDERS, TABS } from './config'

export const Orders = memo(({
  tab,
  order,
  searched,
  orders,
  payment,
  drafts,
  comissions,
  paymentReplications,
  disabledOrders,
  isDisabledOrdersRow,
  onChangeType,
  isPaymentReplications,
  ordersQueue,
  onSelectOrdersQueue,
  selectedNonDeliverableTransit,
  allWoLeftovers,
  setAllWoLeftovers,
  allDirectPayment,
  setAllDirectPayment,
  firstSelectedOrdersType
}) => {
  const classes = useStyles()

  const DATA = {
    'Непогашенные поставки': orders,
    'Уведомления по комиссии': comissions,
    'Репликация квитовок': paymentReplications,
  }

  const canDirectPayment = payment && payment?.payerInn === payment?.supplierInn

  return (
    <>
      <div className={classnames(classes.tabs, classes.tabsOrders)}>
        <div>
          {TABS.map((tabItem, index) =>
            <div
              key={index}
              className={classnames(classes.tab, { 'active': tabItem === tab })}
              onClick={() => onChangeType(tabItem)}
            >
              {tabItem} <span>{DATA[tabItem]?.length}</span>
            </div>
          )}
        </div>
        <div style={{display: 'inline-flex'}}>
          <div>
            <Checkbox
              id='directPayment'
              className={classnames(classes.checkbox, classes.checkboxOrders)}
              checked={allDirectPayment}
              onChange={e => setAllDirectPayment(e.target.checked)}
              label='Прямой платеж'
              disabled={drafts.length === 0 || !canDirectPayment ? true : false}
            />
          </div>
          <div>
            <Checkbox
              id='woLeftovers'
              className={classnames(classes.checkbox, classes.checkboxOrders)}
              checked={allWoLeftovers}
              onChange={e => setAllWoLeftovers(e.target.checked)}
              label='Без остатка'
              disabled={drafts.length === 0 ? true : false}
            />
          </div>
        </div>
      </div>
      <div
        className={classes.tableWrapper}
        // style={{ maxHeight: `${500 + (drafts?.length || 0) * 42}px`}}
      >
        <table className={classes.table}>
          <thead>
            <tr>
              <th><div>&nbsp;</div></th>
              {ORDERS[tab].map(({ title }, index) =>
                <th key={index}><div>{title}</div></th>
              )}
            </tr>
          </thead>
          <tbody>
            {searched > 0 &&
              <>
                <tr>
                  <td
                    className='colspan'
                    colSpan={ORDERS[tab].length + 1}
                  >
                    <div>Поставки</div>
                  </td>
                </tr>
                {DATA[tab].map((item) =>
                  <tr key={item.id}>
                    <td>
                      <div>
                        {!isPaymentReplications &&
                          <Checkbox
                            id={item.id}
                            className={classes.checkbox}
                            // checked={order?.id === item.id}
                            checked={ordersQueue?.find((o) => o?.id === item?.id)}
                            onChange={() => !disabledOrders && onSelectOrdersQueue(item)}
                            title={order?.id === item.id
                              ? 'Снять выбор'
                              : 'Выбрать'
                            }
                            disabled={isDisabledOrdersRow(item)}
                          />
                        }
                      </div>
                    </td>
                    {ORDERS[tab].map((props, index) =>
                      <RowValue key={index} item={item} selectedNonDeliverableTransit={selectedNonDeliverableTransit} firstSelectedOrdersType={firstSelectedOrdersType} isDisabledOrdersRow ={isDisabledOrdersRow(item)} {...props} />
                    )}
                  </tr>
                )}
              </>
            }
          </tbody>
        </table>
      </div>
    </>
  )
})