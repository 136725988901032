import React, { useState } from 'react'
import classnames from 'classnames'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { InputText, Select1 } from '../../../../layout'
import { getReportFunds } from '../../../../redux/Reports/Funds/actions'
import { commonStatusStyles } from '../all3d/styles'
import { ReportTab } from '../../Layout/ReportTab'
import { useProcessSelectOptions } from '../../useProcessSelectOptions'
import { FundsGraphic } from './FundsGraphic'
import { YYYYMMDD } from '../../../../utils'

export const FundsReport = commonStatusStyles(({
  classes,
  onCancel,
  fullWidth,
  changeFullWidth,
  settings,
}) => {
  const dispatch = useDispatch()

  const { processOptions, processDefault } = useProcessSelectOptions(settings.process)

  const { isFetching, data } = useSelector(state => state?.Reports?.Funds)

  const now = new Date()
  const currentDate = YYYYMMDD(now)

  const [process, setProcess] = useState(processDefault)
  const [date, setDate] = useState(currentDate)
  const [hours, setHours] = useState(12)
  const [minutes, setMinutes] = useState(30)
  const [sent, setSent] = useState(false)

  const onChangeProcess = (e) => setProcess(e.target.value)
  const onChangeDate = (e) => setDate(e.target.value)
  const onChangeHours = (e) => setHours(e.target.value)
  const onChangeMinutes = (e) => setMinutes(e.target.value)

  const onGetReport = () => {
    setSent(true)
    const hoursNumber = Number(hours)
    const minutesNumber = Number(minutes)
    const dateTime = moment(date)
      .add(!isNaN(hoursNumber) ? hoursNumber : 0, 'hours')
      .add(!isNaN(minutesNumber) ? minutesNumber : 0, 'minutes')
      .format('YYYY-MM-DD hh:mm')
    dispatch(getReportFunds({ process, dateTime }))
  }

  return (
    <ReportTab
      onOk={onGetReport}
      onCancel={onCancel}
      okButtonText='Получить график'
      disabled={false}
      loading={isFetching}
      title={settings.description}
    >
      <Col className={classnames(classes.form, { fullWidth })}>
        {processOptions.length > 1 && (
          <div className={classes.type}>
            <Select1
              name='bankSignerId'
              onChange={onChangeProcess}
              defaultValue={process}
              options={processOptions}
            />
          </div>
        )}
        <Row className={classes.form}>
          <div className='col-6'>
            <InputText
              type='date'
              name='dateFrom'
              value={date}
              maxDate={new Date()}
              onChange={onChangeDate}
            />
          </div>
          <div className='col-6'>
            <Row>
              <div className={classnames('col-6', classes.hoursContainer)}>
                <InputText
                  maxLength={2}
                  onChange={onChangeHours}
                  defaultValue={hours}
                />
                <label className={classes.hoursLabel}>ч</label>
              </div>

              <div className={classnames('col-6', classes.hoursContainer)}>
                <InputText
                  maxLength={2}
                  onChange={onChangeMinutes}
                  defaultValue={minutes}
                />
                <label className={classes.hoursLabel}>м</label>
              </div>
            </Row>
          </div>
        </Row>
      </Col>
      <div className={classes.content}>
        {sent &&
          (isFetching
            ? null
            : <FundsGraphic
                data={data}
                fullWidth={fullWidth}
                changeFullWidth={changeFullWidth}
                onCancel={onCancel}
              />
          )
        }
      </div>
    </ReportTab>
  )
})