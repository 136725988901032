import React from 'react';
import { Attribute } from '../Attributes'
import _ from 'lodash';
import { formattedDate } from '../../../../../utils'


export const Passport = props => {
    const { passport : data = {} } = props;
    return <div className="row">
        <Attribute col="4" attr={{label: 'Серия', value: _.get(data, 'series')}}/>
        <Attribute col="4" attr={{label: 'Номер', value: _.get(data, 'number')}}/>
        <Attribute col="4" attr={{label: 'Дата выдачи', value: formattedDate(_.get(data, 'issuedDate'))}}/>

        <Attribute col="8" attr={{label: 'Кем выдан', value: _.get(data, 'issuingAuthority')}}/>
        <Attribute col="4" attr={{label: 'Код подразделения', value: _.get(data, 'issuingAuthorityCode')}}/>
    </div>
}