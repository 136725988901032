import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
// import Modal from '../../../../../containers/Modal'
import ContractForm from './ContractForm'
import Contract from './Contract'
import { ModalSchemaForm } from '../../../../Modals'
import { UI, Modal } from './../../../../../layout'
import classnames from 'classnames'

const styles = {
  root: {
    paddingBottom: 40,
  },
  actions: {
    marginTop: 15,
    padding: [0, 20],
    textAlign: 'right',
  },
  items: {
    marginTop: 20,
  },
  title: UI.title,
  add: UI.addButton,
}

const BillingContracts = ({
  classes,
  companyId,
  billingId,
}) => {
  const billingContracts = useSelector(state =>
    state.Company &&
    state.Company.agentInfo &&
    state.Company.agentInfo.info &&
    state.Company.agentInfo.info.billingContracts  
  ) || []
  const [showContractModal, setShowContractModal] = useState(false)
  const [showSchemaModal, setShowSchemaModal] = useState(false)
  const [data, setData] = useState({})
  const [number, setNumber] = useState('')

  const onAddContract = () => setShowContractModal(true)

  const onEditContract = (contract) => {
    setShowContractModal(true)
    setData(contract)
  }

  const onEditSchema = ({ schema, number }) => {
    setShowSchemaModal(true)
    setData(schema)
    setNumber(number)
  }

  const onCloseContract = () => {
    setShowContractModal(false)
    setData({})
    setNumber('')
  }

  const onCloseSchema = () => {
    setShowSchemaModal(false)
    setData({})
    setNumber('')
  }
  
  return (
    <>
      <div className={classes.root}>
        <div className={classes.title}>Список договоров агента</div>
        <div className={classes.items}>
          {billingContracts.map((contract, index) => 
            <Contract 
              key={index}
              companyId={companyId} 
              contract={contract} 
              onEditContract={() => onEditContract(contract)}
              onEditSchema={onEditSchema}
            />
          )}
        </div>
        <div className={classes.actions}>
          <div className={classnames(classes.add, 'btn-form--ok')} onClick={onAddContract}>
            Добавить агентский договор
          </div>
        </div>
      </div>
      <hr />
      {showContractModal
        ? <Modal width='676px' height='auto' onClose={onCloseContract}>
            <ContractForm
              companyId={companyId}
              data={data} 
              onCancel={onCloseContract}
            />
          </Modal> 
        : null
      }
      {showSchemaModal
        ? <ModalSchemaForm 
            companyId={companyId}
            data={data}
            number={number}
            billingId={billingId}
            onClose={onCloseSchema}
          />
        : null
      }
    </>
  )
}

BillingContracts.propTypes = {
  companyId: PropTypes.string.isRequired,
}

export default injectSheet(styles)(BillingContracts)