import axios from 'axios'

import * as Tasks from './tasks'
import * as Orders from './orders'
import * as User from './user'
import * as Search from './search'
import * as Dashboard from './dashboard'
import * as Task from './task'
import * as Chart from './chart'
import * as ReportCommonStatus from './reports/commonStatus'
import * as Payments from './payments'
import * as Tariffs from './tariffs'
import * as Factoring from './factoring'
import * as Dadata from './dadata'
import * as Installment from './installment'
import * as Contracts from './contracts'
import * as Environment from './environment'

import store from '../../redux/configureStore'

axios.interceptors.request.use(async config => {
  try {
    const { User } = store.getState()
    const {
      settings: { authType }
    } = User
    if (authType === 'standard') {
      const { session_id } = User
      if (session_id) {
        const { url } = config
        const [location, params = ''] = url.split('?')
        let kvp = params.split('&')
        let i = kvp.length
        let x
        while (i--) {
          x = kvp[i].split('=')
          if (x[0] === 'session_id') {
            x[1] = session_id
            kvp[i] = x.join('=')
            break
          }
        }

        if (i < 0) {
          kvp.push(['session_id', session_id].join('='))
        }
        config.url = [location, kvp.join('&')].join('?')
      }
    }
    return config
  } catch (err) {
    console.log(err)
    return config
  }
})

export { 
  Tasks, 
  Orders,
  User, 
  Search, 
  Dashboard, 
  Task, 
  Chart, 
  ReportCommonStatus,
  Payments,
  Tariffs,
  Factoring,
  Dadata,
  Installment,
  Contracts,
  Environment,
}
