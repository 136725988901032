import { useState, useEffect } from 'react'

export const useScrolled = () => {
  const [scrolled, setScrolled] = useState(false)
  const onScroll = () => {
    if (!scrolled && window.pageYOffset > 0) {
      setScrolled(true)
    } else if (scrolled && window.pageYOffset === 0) {
      setScrolled(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    window.addEventListener('resize', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onScroll)
    }
  })
  return scrolled
}