import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    marginTop: '16px',
    position: 'relative',
    '& + &': {
      marginTop: '21px',
    },
  },
}

const Item = ({ children, classes }) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Item)