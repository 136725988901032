import React, { useState, useEffect } from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCompanyBgAgentBinding,
  updateCompanyBgAgentBinding,
  getCompanyWorkGroups,
} from '../../../../redux/Company/actions'
import Overlay from '../../../Overlay'
import TextFieldWithAutoComplete from '../../../TextFieldWithAutoComplete'
import { Row, UI, Modal } from '../../../../layout'
import ChangeLoginModal from './ChangeLoginModal'

const styles = {
  btn: UI.addButton,
  removeBtn: UI.removeButton,
  title: UI.title,
  table: UI.table,
  block: {
    marginTop: 40,
  },
  item: {
    marginTop: 20,
  },
}

const BANK_KEY = 'bankemployee'

const BgAgentBinding = ({
  companyId,
  classes,
}) => {
  const dispatch = useDispatch()

  const { bgAgentBinding, workGroups } = useSelector(state => state.Company)
  const { agentLogins } = useSelector(state => state.Settings.access) || {}

  const [selectedCompanyId, setSelectedCompanyId] = useState('')
  const [group, setGroup] = useState(null)

  const { fetching: bgAgentBindingFetching, agentCompanyId, displayName, inn, ogrn } = bgAgentBinding
  const { fetching: workGroupsFetching } = workGroups

  let _workGroups = []
  
  for (let key in workGroups) {
    if (workGroups[key] && workGroups[key].userRole) {
      _workGroups.push(workGroups[key])
    }
  }

  useEffect(() => {
    if (agentLogins) {
      dispatch(getCompanyBgAgentBinding(companyId))
      dispatch(getCompanyWorkGroups(companyId))
    }
  }, [companyId, agentLogins, dispatch])

  const onSelect = (payload) => setSelectedCompanyId(payload.id)

  const onUpdate = (value) => value === '' || (selectedCompanyId === agentCompanyId && value !== null)
    ? alert('Выберите компанию') 
    : dispatch(updateCompanyBgAgentBinding(companyId, { agentCompanyId: value }))

  const onOpenChangeLoginModal = (userId) => setGroup(userId)
  const onCloseChangeLoginModal = () => setGroup(null)

  const isBank = agentCompanyId === BANK_KEY
  const isAgent = !isBank && agentCompanyId

  const totalFetching = bgAgentBindingFetching || workGroupsFetching

  if (!agentLogins) {
    return 'Нет прав для просмотра'
  }

  if (totalFetching) {
    return <Overlay/>
  }

  return (
    <div>
      {!agentCompanyId && 'Компания не закреплена'}
      {isBank && 'Компания закреплена за банком'}
      {isAgent && `Компания закреплена за агентом ${displayName} (ИНН ${inn}, ОГРН ${ogrn})`}

      {agentCompanyId &&
        <div className={classes.item}>
          <div
            className={classnames(classes.removeBtn, 'btn-form--ok')}
            disabled={totalFetching}
            onClick={() => onUpdate(null)}
          >
            Открепить
          </div>
        </div>
      }
      
      {!isBank &&
        <div className={classes.item}>
          <div
            className={classnames(classes.btn, 'btn-form--ok')}
            disabled={totalFetching}
            onClick={() => onUpdate(BANK_KEY)}
          >
            Закрепить за банком
          </div>
        </div>
      }

      <div className={classes.block}>
        <Row>
          <div className='col-8'>
            <TextFieldWithAutoComplete
              classNames={{
                container: 'filter-input',
                input: 'filter-input__input',
              }}
              name='company'
              placeholder='Наименование компании'
              defaultValue=''
              value=''
              onSelect={onSelect}
              onClear={() => {}}
              payloadKeys={['id', 'displayName']}
              style={{ paddingLeft: '40px '}}
              withoutLink
              // hasReset={hasReset}
              // isFetching={isFetching}
            />
          </div>
          <div className='col-4'>
            <div
              className={classnames(classes.btn, 'btn-form--ok')}
              disabled={totalFetching}
              onClick={() => onUpdate(selectedCompanyId)}
            >
              {isAgent ? 'Перезакрепить' : 'Закрепить'} за агентом
            </div>
          </div>
        </Row>
      </div>

      <div className={classes.block}>
        <div className={classes.title}>Рабочие группы:</div>
        <table className={classes.table}>
          <thead>
            <tr>
              <td>Логин</td>
              <td>Роль</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {_workGroups.map((group, index) => 
              <tr key={index}>
                <td>
                  <div>{group.userId}</div>
                </td>
                <td>
                  <div>{group.userRoleName}</div>
                </td>
                <td>
                  <div
                    className={classnames(classes.btn, 'btn-form--ok')}
                    onClick={() => onOpenChangeLoginModal(group)}
                  >
                    Изменить
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {group &&
        <Modal width='482px' height='auto' onClose={onCloseChangeLoginModal}>
          <ChangeLoginModal 
            companyId={companyId}
            group={group}
            groups={_workGroups}
            onCancel={onCloseChangeLoginModal} 
          />
        </Modal>
      }

    </div>
  )
}

export default injectSheet(styles)(BgAgentBinding)