import React, { useState } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Value from './../Value'
import { Icon } from './../../../layout'
import { THEME_COLOR } from '../../../config'
import { plural, formattedDateTime } from '../../../utils'

const styles = {
  item: {
    position: 'relative',
    '& + &': {
      marginTop: 11,
    },
  },
  attachment: {
    background: '#E4E4E4',
    marginTop: 6,
    padding: [11, 25, 11, 25],
    borderRadius: 16,
    position: 'relative',
  },
  wrap: {
    paddingRight: 120,
    '&.long': {
      paddingRight: 310,
    },
  },
  wrap2: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  name: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1.8,
    color: '#000',
    display: 'inline-block',
  },
  date: {
    marginLeft: 20,
    color: '#727272',
    fontSize: 15,
    lineHeight: 1.5,
    fontWeight: 500,
    display: 'inline-block',
  },
  links: {
    position: 'absolute',
    top: 18,
    right: 25,
  },
  link: {
    paddingLeft: 28,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.4,
    color: THEME_COLOR,
    display: 'inline-block',
    position: 'relative',
    '&:hover': {
      color: THEME_COLOR,
      textDecoration: 'none',
    },
    '& + &': {
      marginLeft: 30,
    },
    '& svg': {
      width: 18,
      height: 18,
      fill: THEME_COLOR,
      position: 'absolute',
      left: 0,
      top: 0,
    }
  },
  value: {
    display: 'inline-block',
  },
  signInfoIcon: {
    marginLeft: 10,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
  },
  signInfo: {
    marginTop: 10,
    textAlign: 'right',
    fontSize: 14,
    lineHeight: 1.2,
  },
  signs: {
    marginTop: 10,
  },
  sign: {
    '& + &': {
      marginTop: 4,
    },
  },
}

const Item = ({
  name,
  files = [],
  classes,
}) => {
  const [isShowSignInfo, setIsShowSignInfo] = useState(false)

  const onShowSignInfo = () => setIsShowSignInfo(!isShowSignInfo)

  return (
    <div className={classes.item}>
      <Value>{name}</Value>
      {files.map(({ name, date, changed, url, signUrl, isSigned, signInfo }, index) => {
        const { signs } = signInfo || {}
        const signsL = signs?.length || 0
        return (
          <div key={`${name}-${index}`} className={classes.attachment}>
            <div className={classnames(classes.wrap, { 'long': isSigned && signInfo })}>
              <div className={classes.wrap2}>
                <Value className={classes.value}>
                  {isSigned && signInfo
                    ? name
                    : name.replace('.sig', '')
                  }
                </Value>
                {date &&
                  <div className={classes.date}>{date}</div>
                }
                {changed &&
                  <div className={classes.date}>{formattedDateTime(changed)}</div>
                }
                <div className={classes.links}>
                  <a 
                    className={classes.link} 
                    href={url} 
                    target='_blank' 
                    download={(isSigned && signInfo) ? name.replace('.sig', '') : name}
                    rel='noopener noreferrer'
                  >
                    <Icon iconName='download' />
                    <span>Оригинал</span>
                  </a>
                  {isSigned && signInfo &&
                    <>
                      <a 
                        className={classes.link}  
                        href={signUrl}
                        target='_blank' 
                        download={name}
                        rel='noopener noreferrer' 
                      >
                        <Icon iconName='download' />
                        <span>Подписанный</span>
                      </a>
                      <img
                        className={classes.signInfoIcon}
                        src={require('./sign.png')}
                        alt=''
                        onClick={onShowSignInfo}
                      />
                    </>
                  }
                </div>
                {isShowSignInfo && signs &&
                  <div className={classes.signInfo}>
                    Этот документ подписан {signsL} {plural(signsL, 'раз', 'раза', 'раз')}:
                    <div className={classes.signs}>
                      {(signs || []).map(({ subject, notAfter }, index) => {
                        let string = ''
                        if (subject?.SN) { string = `${subject?.SN}` }
                        if (subject?.G) { string = `${string} ${subject?.G}` }
                        if (subject?.T) { string = `${string}, ${subject?.T}` }
                        if (subject?.O) { string = `${string}, ${subject?.O}` }
                        if (notAfter) { string = `${string}, Сертификат истекает: ${formattedDateTime(notAfter)}` }
                        return (
                          <div className={classes.sign} key={index}>{string}</div>
                        )
                      })}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default injectSheet(styles)(Item)