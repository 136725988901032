import 'react-app-polyfill/ie9'
import React, { Component } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { isValidToken, onLogin, onLogout, setToken } from './utils/tokenStorage'
import { KeycloakProvider } from 'react-keycloak'
import keycloak from './keycloak'
import Overlay from './components/Overlay'
import { ModalWrapper } from './components/ModalWrapper'
import { OldBrowser } from './components/OldBrowser'
import NotFound from './pages/NotFound'
import AppRoutes from './routes'
import store from './redux/configureStore'
import { getAppSettings, authenticationUser } from './redux/User/actions'
import Theme from './themes'
import { isIE } from './utils'
import './static/scss/style.scss'
import { IS_DEV } from './config'

class App extends Component {
  state = {
    settingsFetch: true,
    settings: {},
  }

  componentDidMount() {
    const { settings, settingsFetch } = store.getState().User

    this.setState({ settings, settingsFetch })

    this.unsubscribe = store.subscribe(this.onUpdateAppSettings)
    store.dispatch(getAppSettings())
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onUpdateAppSettings = () => {
    const { settingsFetch: prevSettingsFetch } = this.state
    const { settings, settingsFetch } = store.getState().User

    if (prevSettingsFetch !== settingsFetch && !settingsFetch) {
      this.setState({ settings, settingsFetch })
      this.unsubscribe()
    }
  }

  onError = (props) => console.log(props)
  onToken = (token) => setToken(token)
  onEvent = (event) => {
    console.log(event)
    const loginEvents = ['onReady', 'onAuthError', 'onAuthRefreshError']
    const logoutEvents = ['onAuthLogout']
    if (loginEvents.indexOf(event) > -1 && !isValidToken()) {
      onLogin()
    }
    if (logoutEvents.indexOf(event) > -1) {
      onLogout()
    }

    if (event === 'onAuthSuccess') {
      store.dispatch(authenticationUser())
    }
  }

  renderApp = (isUseKeycloak) => 
    <Provider store={store}>
      <Theme>
        <AppRoutes isUseKeycloak={isUseKeycloak} />
        <ModalWrapper />
      </Theme>
    </Provider>

  render() {
    const { settings, settingsFetch } = this.state

    if (isIE) {
      return <OldBrowser />
    }

    if (settings.authType === 'standard') {
      return this.renderApp(false)
    }

    if (settings.authType === 'keycloak') {
      return (
        <KeycloakProvider
          keycloak={keycloak}
          initConfig={{
            // onLoad: 'login-required',
            checkLoginIframe: !IS_DEV,
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
          }}
          onError={this.onError}
          onToken={this.onToken}
          onEvent={this.onEvent}
        >
          {this.renderApp(true)}
        </KeycloakProvider>
      )
    }

    if (!settingsFetch) {
      return <NotFound text='Ошибка загрузки настроек приложения!' />
    }

    return <Overlay size='big' />
  }
}

render(
  <App />,
  document.getElementById('root')
)