import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getCompanyLimits } from '../../../redux/Company/actions'
import { createUseStyles } from 'react-jss'
import { Modal } from '../../../layout'
import { Limits } from '../../CustomDetail/CompanyInfo/Limits'

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
  },
})

export const ModalChangeLimits = ({
  companyId,
  process_definition_key,
  onCompanyStartSubprocess,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onGetCompanyLimits = useCallback(() => {
    dispatch(getCompanyLimits(companyId))
  }, [companyId, dispatch])

  useEffect(() => {
    onGetCompanyLimits()
  }, [onGetCompanyLimits])

  return (
    <Modal className={classes.root} onClose={onClose}>
      <Limits
        process_definition_key={process_definition_key}
        onCompanyStartSubprocess={onCompanyStartSubprocess}
        onGetCompanyLimits={onGetCompanyLimits}
        onClose={onClose}
      />
    </Modal>
  )
}