import React, { useMemo } from 'react'
import { taskReportStyles } from './styles'
import { uas } from '../../../../services/utility/charts/uas'

export const TasksGraphicItem = taskReportStyles(
  ({ classes, data, index, openDetails, dataLength, maxCount }) => {
    const { name, count } = data

    const onClickTitle = () => {
      openDetails(data)
    }

    const brightnessDelta = useMemo(
      () => dataLength > 1 ? Math.round((50 / (dataLength - 1)) * 100) / 100 : 1,
      [dataLength]
    )

    const backgroundColor = uas.helpers.colorBrightness(
      '#005E6A',
      index * brightnessDelta
    )
    const percent = Math.round((100 / maxCount) * count)

    return (
      <li className={classes.item}>
        <h5 className={classes.title} onClick={onClickTitle}>
          {name}
        </h5>
        <div
          className={classes.progress}
          style={{ backgroundColor, width: `${percent}%` }}
        >
          {count}
        </div>
      </li>
    )
  }
)
