import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    marginLeft: '20px',
    color: '#B8B8B8',
    fontSize: '15px',
    lineHeight: '22px',
    display: 'inline-block',
  },
}

const Code = ({ children, classes }) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Code)