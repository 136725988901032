import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import Overlay from '../../../Overlay'
import { Block, Title, SubTitle, Value, Conditions, Item, Bar, Legend, Form } from './Layout'
import { formatMoney, formattedDate, normilizeDate, YYYYMMDD } from '../../../../utils'

const useStyles = createUseStyles({
  info: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  input: {
    height: 38,
    padding: [10, 30],
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 500,
    border: [1, 'dashed', '#ddd'],
    borderRadius: 16,
    textAlign: 'center',
    outline: 'none',
  },
  submit: {
    background: '#22C7B5',
    minWidth: 310,
    minHeight: 40,
    marginBottom: 50,
    padding: [8, 25],
    color: '#fff',
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 600,
    borderRadius: 8,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '&:hover': {
      boxShadow: [0, 2, 4, '#ccc'],
    },
    '&:active': {
      background: '#199083',
    },
    '& .preloader': {
      borderRadius: 'inherit',
    },
  },
  error: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      fontSize: 20,
      lineHeight: 1.6,
      color: '#000',
      fontWeight: 500,
      textAlign: 'center',
    },
  },
  dateHelperWrapper: {
    position: 'relative',
  },
  dateHelper: {
    background: '#fff',
    padding: [10, 0],
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 500,
    borderRadius: 16,
    textAlign: 'center',
    position: 'absolute',
    left: 8,
    right: 36,
    top: 1,
    bottom: 1,
    pointerEvents: 'none',
  },
})

export const Limits = ({
  process_definition_key,
  onCompanyStartSubprocess,
  onGetCompanyLimits,
  onClose,
  readOnly,
}) => {
  const classes = useStyles()

  const { REACT_APP_LIMIT_AGGREGATE_FUNC } = useSelector(state => state?.Environment?.environment)

  const {
    limits: {
      limits,
      utilization = {},
      fetching: limitsFetching,
    },
    startSubProcess: {
      fetching: startSubProcessFetching,
    },
  } = useSelector(state => state?.Company)

  const isFetching =  limitsFetching || startSubProcessFetching

  const {
    createdDate,
    endDate,
    constraints = [],
    utilizedAmount,
    frozenAmount,
    totalAmount,
    maxOrderAmount,
  } = limits || {}

  const normilizedCreatedDate = new Date(normilizeDate(createdDate))
  const normilizeEndDate = new Date(normilizeDate(endDate))

  const minEndDate = YYYYMMDD(
    new Date(
      normilizedCreatedDate.setDate(normilizedCreatedDate.getDate() + 1)
    )
  )

  const [totalAmountState, setTotalAmountState] = useState(totalAmount)
  const [totalAmountStateAux, setTotalAmountStateAux] = useState(totalAmount)
  const [constraintsState, setConstraintsState] = useState([])
  const [constraintsStateAux, setConstraintsStateAux] = useState([])
  const [endDateState, setEndDateState] = useState(YYYYMMDD(normilizeEndDate))

  const onCalculatedTotalAmount = (array) => {
    const limitAmountArray = array.map(({limitAmount, overLimitAmount}) => overLimitAmount ? 0 : limitAmount)
    const maxLimitAmount = limitAmountArray?.length > 0
        ?  REACT_APP_LIMIT_AGGREGATE_FUNC === 'sum'
            ? limitAmountArray.reduce((result, item) => result + item, 0)
            : Math.max(...limitAmountArray)
        : 0
    const overLimitAmountTotal = array.reduce((result, { overLimitAmount }) => result + (overLimitAmount || 0), 0)
    return maxLimitAmount + overLimitAmountTotal
  }

  const calculatedTotalAmountState = onCalculatedTotalAmount(constraintsState)
  const calculatedTotalAmountStateAux = onCalculatedTotalAmount(constraintsStateAux)

  useEffect(() => {
    if (constraints?.length) {
      setConstraintsState([...constraints])
      setConstraintsStateAux([...constraints])
    }
  }, [constraints])

  useEffect(() => {
    if (constraintsState?.length) {
      setTotalAmountState(calculatedTotalAmountState - utilizedAmount - frozenAmount)
    }
  }, [constraintsState, calculatedTotalAmountState, utilizedAmount, frozenAmount])

  useEffect(() => {
    if (constraintsStateAux?.length) {
      setTotalAmountStateAux(calculatedTotalAmountStateAux - utilizedAmount - frozenAmount)
    }
  }, [constraintsStateAux, calculatedTotalAmountStateAux, utilizedAmount, frozenAmount])

  const onChangeEndDate = (e) => setEndDateState(e.target.value)

  const edit = (payload, index) => (item, idx) => index === idx ? { ...payload } : item
  const remove = (index) => (_, idx) => index !== idx

  const onAdd = (payload) => setConstraintsState([...constraintsState, payload])
  const onAddAux = (payload) => setConstraintsStateAux([...constraintsStateAux, payload])
  
  const onEdit = (payload, index) => setConstraintsState(constraintsState.map(edit(payload, index)))
  const onEditAux = (payload, index) => setConstraintsStateAux(constraintsStateAux.map(edit(payload, index)))

  const onRemove = (index) => setConstraintsState(constraintsState.filter(remove(index)))
  const onRemoveAux = (index) => setConstraintsStateAux(constraintsStateAux.filter(remove(index)))

  const onSubmit = () => {
    const payload = {
      variables: {
        StartForm_result: {
          type: 'Json',
          value: JSON.stringify({
            limits: {
              ...limits,
              endDate: formattedDate(endDateState),
              constraints: constraintsState,
            }
          })
        }
      }
    }
    onCompanyStartSubprocess({
      payload,
      process_definition_key,
      onSuccess: () => {},
      finallyCallback: () => {
        onGetCompanyLimits()
        onClose()
      }
    })
  }

  const COLORS = {
    used: '#8338EC',
    frozen: '#3A86FF',
    total: '#22DBAE',
  }

  const STATIC_VALUES = [
    {
      title: 'Максимальная сумма сделки',
      value: formatMoney(
        maxOrderAmount ||
        (constraints?.length > 0 && Math.max(...constraints.map(item => item.maxOrderAmount)))
      ),
      show: true,
    },
    {
      title: 'Сумма действующих БГ S2',
      value: formatMoney(utilization.usedAmount),
      show: !!utilization.usedAmount,
    },
    {
      title: 'Свободный лимит S2',
      value: formatMoney(utilization.totalAmount - utilization.usedAmount),
      show: !!(utilization.usedAmount && utilization.usedAmount),
    },
  ]

  if (isFetching) {
    return <Overlay />
  }

  if (!limits) {
    return (
      <div className={classes.error}>
        <p>Лимит не установлен</p>
      </div>
    )
  }

  return (
    <>
      <Block>
        <Title>Активные лимиты</Title>
        <div className={classes.info}>
          <Item>
            <SubTitle>Расчётный лимит</SubTitle>
            <Value>{formatMoney(calculatedTotalAmountState)}</Value>
          </Item>
          <Item>
            <SubTitle>Дата установки</SubTitle>
            <div className={classes.input}>{createdDate}</div>
          </Item>
          <Item>
            <SubTitle>Дата окончания</SubTitle>
            {readOnly
              ? <div className={classes.input}>
                  {normilizeEndDate.toLocaleDateString('ru-RU')}
                </div>
              : <div className={classes.dateHelperWrapper}>
                  <input
                    className={classes.input}
                    type='date'
                    value={endDateState}
                    onChange={onChangeEndDate}
                    min={minEndDate}
                    style={{ width: '150px', paddingLeft: '10px', paddingRight: '10px' }}
                  />
                  {endDateState &&
                    <div className={classes.dateHelper}>
                      {new Date(endDateState).toLocaleDateString('ru-RU')}
                    </div>
                  }
                </div>
            }
          </Item>
          {STATIC_VALUES.map(({ title, value, show }, index) => show
            ? <Item key={index}>
                <SubTitle>{title}</SubTitle>
                <div className={classes.input}>{value}</div>
              </Item>
            : null
          )}
        </div>
      </Block>
      <Block>
        <Bar
          COLORS={COLORS}
          utilizedAmount={utilizedAmount}
          frozenAmount={frozenAmount}
          calculatedTotalAmountState={calculatedTotalAmountState}
        />
      </Block>
      <Block>
        <Legend
          COLORS={COLORS}
          utilizedAmount={utilizedAmount}
          frozenAmount={frozenAmount}
          totalAmount={totalAmountState}
        />
      </Block>
      <Block>
        <Title>Условия</Title>
        <Conditions
          calculatedTotalAmountState={calculatedTotalAmountState}
          totalAmountState={totalAmountState}
          totalAmountStateAux={totalAmountStateAux}
          constraintsState={constraintsState}
          onEdit={onEdit}
          onEditAux={onEditAux}
          onRemove={onRemove}
          onRemoveAux={onRemoveAux}
          readOnly={readOnly}
        />
      </Block>
      {!readOnly &&
        <>
          <Block>
            <Title>Новое условие</Title>
            <Form
              calculatedTotalAmountState={calculatedTotalAmountState}
              totalAmountState={totalAmountState}
              totalAmountStateAux={totalAmountStateAux}
              onAdd={onAdd}
              onAddAux={onAddAux}
            />
          </Block>
          <Block>
            <div
              className={classes.submit}
              onClick={onSubmit}
            >
              Сохранить изменения лимита
            </div>
          </Block>
        </>
      }
    </>
  )
}