import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import {
  addCompanyAgentBillingContractSchema,
  updateCompanyAgentBillingContractSchema,
  addBaseSchema,
} from '../../../redux/Company/actions'
import {
  getBaseSchemaList,
  createBaseSchema,
  updateBaseSchema,
  // clearBaseSchema,
} from '../../../redux/Tariffs/actions'
import SchemaFormRates from './SchemaFormRates'
import Overlay from '../../Overlay'
import { InputText, Select1, Row, UI } from '../../../layout'

const styles = {
  root: {
    paddingBottom: 20,
    position: 'relative',
  },
  title: UI.h1,
  checkbox: {
    marginTop: '0 !important',
    '& input[type=checkbox]': {
      '&:disabled': {
        background: '#ddd',
        '& + .checkbox__text': {
          '&:before, &:after': {
            background: '#ddd',
          },
        },
      },
    },
    '& .checkbox__text': {
      paddingTop: 0,
      paddingLeft: 28,
      fontSize: 14,
      '&:before, &:after': {
        top: 0,
      },
    },
  },
  actions: {
    marginTop: 40,
    textAlign: 'right',
  },
  remove: UI.removeButton,
  cancel: UI.cancelButton,
  add: {
    ...UI.addButton,
    marginLeft: 20,
  },
}

const SchemaForm = ({
  classes,
  companyId,
  number,
  data = {},
  billingId,
  onClose,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { tariffSchemaId } = useParams()

  const { REACT_APP_NEW_BILLING } = useSelector(state => state?.Environment?.environment)

  const IS_NEW_BILLING = REACT_APP_NEW_BILLING === 'true'

  const hasDecreaseRates = useSelector(state =>
    state.Settings &&
    state.Settings.bgAgentBilling &&
    state.Settings.bgAgentBilling.decreaseRates
  )

  const { tariff, tariffs, isFetching } = useSelector(state => state.Tariffs) || {}

  const tariffSchema = (tariff && tariff.schema) || {}

  const [activeSchema, setActiveSchema] = useState({})

  const [submitted, setSubmitted] = useState(0)

  const [name, setName] = useState(tariff.name || '')
  const [productType, setProductType] = useState(tariff.productType || '')
  const [marketingCampaign, setMarketingCampaign] = useState(tariff.marketingCampaign || false)

  const [validFrom, setValidFrom] = useState(data.validFrom || tariff.validFrom || '')
  const [validTo, setValidTo] = useState(data.validTo || tariff.validTo || '')
  const [excessRate, setExcessRate] = useState(data.excessRate || tariffSchema.excessRate ||  '')
  const [decreaseRate, setDecreaseRate] = useState(data.decreaseRate || tariffSchema.decreaseRate || '')
  const [includeVAT, setIncludeVAT] = useState(!!data.includeVAT)
  const [individual, setIndividual] = useState(false)
  const [standardRates, setStandardRates] = useState(data.standardRates ||tariffSchema.standardRates || [])
  const [totalRates, setTotalRates] = useState(data.totalRates || tariffSchema.totalRates || [])
  const [totalRatesByAmount, setTotalRatesByAmount] = useState(data.totalRatesByAmount || tariffSchema.totalRatesByAmount || [])
  const [decreaseRates, setDecreaseRates] = useState(data.decreaseRates || tariffSchema.decreaseRates || [])
  const [totalDebtRatesByAmount, setTotalDebtRatesByAmount] = useState(data.totalDebtRatesByAmount || tariffSchema.totalDebtRatesByAmount || [])

  const isTariffSchemesPage = /tariff-schemes/.test(location.pathname)

  const errors = {
    name: !name,
    productType: !productType,
    validFrom: !validFrom,
    validTo: !validTo || new Date(validFrom).getTime() > new Date(validTo).getTime(),
    totalRatesByAmountFromAmount: !(
      totalRatesByAmount &&
      totalRatesByAmount.length &&
      (totalRatesByAmount[0].fromAmount || totalRatesByAmount[0].fromAmount === 0)
    ),
    totalRatesByAmountRate: !(
      totalRatesByAmount &&
      totalRatesByAmount.length &&
      (totalRatesByAmount[0].rate || totalRatesByAmount[0].rate === 0)
    ),
  }

  const isValid = !Object.values(errors).some(item => !!item)

  const onCreateOrUpdateBaseSchema = () => {
    const payload = {
      name,
      productType,
      marketingCampaign,
      validFrom,
      validTo,
      schema: {
        excessRate,
        decreaseRate,
        standardRates,
        totalRates,
        totalRatesByAmount,
        ...(hasDecreaseRates ? { decreaseRates } : {}),
        totalDebtRatesByAmount,
      },
    }
    const onSuccess = () => {
      onClose()
      dispatch(getBaseSchemaList())
    }
    if (isValid) {
      dispatch(tariffSchemaId
        ? updateBaseSchema(tariffSchemaId, payload, onSuccess)
        : createBaseSchema(payload, onSuccess)
      )
    }
  }

  // useEffect(() => {
  //   return () => dispatch(clearBaseSchema())
  // }, [tariffSchemaId, dispatch])

  useEffect(() => {
    if (submitted && isValid) {
      onCreateOrUpdateBaseSchema()
    }
  // eslint-disable-next-line
  }, [submitted])

  useEffect(() => {
    if (!isFetching && tariff && tariff.schema) {
      setName(tariff.name)
      setProductType(tariff.productType)
      setMarketingCampaign(tariff.marketingCampaign)
      setValidFrom(tariff.validFrom)
      setValidTo(tariff.validTo)
      setExcessRate(tariff.schema.excessRate)
      setDecreaseRate(tariff.schema.decreaseRate)
      setIncludeVAT(tariff.schema.includeVAT)
      setStandardRates(tariff.schema.standardRates)
      setTotalRates(tariff.schema.totalRates)
      setTotalRatesByAmount(tariff.schema.totalRatesByAmount)
      setDecreaseRates(tariff.schema.decreaseRates)
      setTotalDebtRatesByAmount(tariff.schema.totalDebtRatesByAmount)
    }
  }, [tariff, isFetching])

  useEffect(() => {
    if (!isFetching && tariffs && tariffs.length > 0) {
      const currentSchema = activeSchema.id ? activeSchema : {}
      const { validFrom, validTo, schema } = currentSchema
      validFrom && setValidFrom(validFrom)
      validTo && setValidTo(validTo)
      if (typeof schema ==='object' && Object.keys(schema).length > 0) {
        setExcessRate(schema.excessRate)
        setDecreaseRate(schema.decreaseRate)
        setIncludeVAT(schema.includeVAT)
        setStandardRates(schema.standardRates)
        setTotalRates(schema.totalRates)
        setTotalRatesByAmount(schema.totalRatesByAmount)
        setDecreaseRates(schema.decreaseRates)
        setTotalDebtRatesByAmount(schema.totalDebtRatesByAmount)
      }
    }
  }, [tariffs, activeSchema, isFetching, individual])

  const onChangeSelect = (e) => setActiveSchema(tariffs.find(({ id }) => e.target.value === id))

  const onChangeRates = (rates, setRates, payload, index) => {
    let updatedRates = [...rates]
    if (payload) {
      if (index > updatedRates.length - 1) {
        updatedRates.push(payload)
      } else {
        updatedRates = rates.map((item, itemIndex) => {
          if (itemIndex === index) {
            item = payload
          }
          return item
        })
      }
    } else {
      updatedRates = rates.filter((_, itemIndex) => itemIndex !== index)
    }
    setRates(updatedRates)
  }

  const onChangeName = (e) => setName(e.target.value)
  const onChangeProductType = (e) => setProductType(e.target.value)
  const onChangeMarketingCampaign = (e) => setMarketingCampaign(!marketingCampaign)

  const onChangeValidFrom = (e) => setValidFrom(e.target.value)
  const onChangeValidTo = (e) => setValidTo(e.target.value)
  const onChangeExcessRate = (e) => setExcessRate(e.target.value)
  const onChangeDecreaseRate = (e) => setDecreaseRate(e.target.value)
  const onChangeIncludeVAT = () => setIncludeVAT(!includeVAT)
  const onChangeIndividual= () => setIndividual(!individual)

  const onChangeStandardRates = (payload, index) =>
    onChangeRates(standardRates, setStandardRates, payload, index)

  const onChangeTotalRates = (payload, index) =>
    onChangeRates(totalRates, setTotalRates, payload, index)

  const onChangeTotalRatesByAmount = (payload, index) =>
    onChangeRates(totalRatesByAmount, setTotalRatesByAmount, payload, index)

  const onChangeDecreaseRates = (payload, index) =>
    onChangeRates(decreaseRates, setDecreaseRates, payload, index)

  const onChangeTotalDebtRatesByAmount = (payload, index) =>
    onChangeRates(totalDebtRatesByAmount, setTotalDebtRatesByAmount, payload, index)

  const schemaId = data.schemaId || tariffSchemaId
  
  const cc = IS_NEW_BILLING && individual // coconut condition
  const ac = !IS_NEW_BILLING // another condition
  const oc = cc || ac // overall condition

  const onSubmit = () => {
    if (isTariffSchemesPage) {
      setSubmitted(submitted + 1) 
    } else {
      const payload = {
        validFrom,
        validTo,
        excessRate,
        decreaseRate,
        includeVAT,
        companyId,
        schemaId,
        ...(oc ? { standardRates } : {}),
        ...(oc ? { totalRates } : {}),
        ...(oc ? { totalRatesByAmount } : {}),
        ...(oc && hasDecreaseRates ? { decreaseRates } : {}),
        ...(cc ? { totalDebtRatesByAmount } : {}), // only for coconut
      }

      // if (IS_NEW_BILLING) {
      //   payload = {
      //     companyId: payload.companyId,
      //     validFrom: payload.validFrom,
      //     validTo: payload.validTo,
      //     // id: 'string',
      //     // marketingCampaign: false,
      //     // name: 'string',
      //     productType: 'BG',
      //     schema: payload,
      //   }

      //   delete payload.companyId
      //   delete payload.schema.validFrom
      //   delete payload.schema.validTo
      // }

      if (schemaId) {
        dispatch(updateCompanyAgentBillingContractSchema(payload))
      } else {
        if (IS_NEW_BILLING && !individual) {
          dispatch(addBaseSchema({
            agentId: parseInt(billingId),
            baseSchemaId: parseInt(activeSchema.id),
            companyId,
          }))
        } else {
          dispatch(addCompanyAgentBillingContractSchema({ ...payload, number }))
        }
      }
    }
  }

  // const valid = validFrom && validTo && excessRate && decreaseRate

  const title = schemaId 
    ? `Изменить схему №${schemaId}` 
    : `Создать новую схему`

  const disabled = isTariffSchemesPage
    ? false
    : IS_NEW_BILLING && !individual

  const disabledSubmit = isTariffSchemesPage
    ? submitted && !isValid
    : disabled && !activeSchema.id

  if (isFetching) {
    return <Overlay />
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      {IS_NEW_BILLING &&
        <>
          {isTariffSchemesPage &&
            <>
              <Row>
                <div className='col-12'>
                  <InputText
                    type='text'
                    name='name'
                    label='Наименование схемы'
                    value={name}
                    onChange={onChangeName}
                    disabled={disabled}
                    error={submitted && errors.name}
                  />
                </div>
              </Row>
              <Row>
                <div className='col-12'>
                  <InputText
                    type='text'
                    name='productType'
                    label='Продукт'
                    value={productType}
                    onChange={onChangeProductType}
                    disabled={disabled}
                    error={submitted && errors.productType}
                  />
                </div>
              </Row>
              <Row>
                <div className='col-12'>
                  <label className={classnames('checkbox', classes.checkbox)}>
                    <input
                      type='checkbox' 
                      name='marketingCampaign'
                      defaultValue={!!marketingCampaign}
                      checked={!!marketingCampaign}
                      onChange={onChangeMarketingCampaign}
                      disabled={disabled}
                    />
                    <span className='checkbox__text'>Акционный тариф</span>
                  </label>
                </div>
              </Row>
            </>
          }
          {!isTariffSchemesPage &&
            <>
              <Row>
                <div className='col-12'>
                  <Select1
                    name='baseSchema'
                    onChange={onChangeSelect}
                    options={tariffs.map(({ id, name }) => ({ id, description: name }))}
                  />
                </div>
              </Row>
              <Row>
                <div className='col-12'>
                  <label className={classnames('checkbox', classes.checkbox)}>
                    <input 
                      type='checkbox' 
                      name='individual'
                      defaultValue={!!individual}
                      checked={!!individual}
                      onChange={onChangeIndividual}
                    />
                    <span className='checkbox__text'>Индивидуальные тарифы</span>
                  </label>
                </div>
              </Row>
            </>
          }
        </>
      }
      <Row>
        <div className='col-6'>
          <InputText
            type='date'
            name='validFrom'
            label='Действует с'
            value={validFrom}
            onChange={onChangeValidFrom}
            disabled={disabled}
            error={submitted && errors.validFrom}
          />
        </div>
        <div className='col-6'>
          <InputText 
            type='date'
            name='validTo'
            label='Действует по'
            value={validTo}
            onChange={onChangeValidTo}
            disabled={disabled}
            error={submitted && errors.validTo}
          />
        </div>
      </Row>
      <Row>
        <div className='col-6'>
          <InputText 
            type='text'
            name='excessRate'
            label='Доля агента при повышении'
            value={excessRate}
            onChange={onChangeExcessRate}
            disabled={disabled}
          />
        </div>
        <div className='col-6'>
          <InputText 
            type='text'
            name='decreaseRate'
            label='Компенсация агентом за понижение'
            value={decreaseRate}
            onChange={onChangeDecreaseRate}
            disabled={disabled}
          />
        </div>
      </Row>
      <Row>
        <div className='col-12'>
          <label className={classnames('checkbox', classes.checkbox)}>
            <input
              type='checkbox' 
              name='includeVAT'
              defaultValue={!!includeVAT}
              checked={!!includeVAT}
              onChange={onChangeIncludeVAT}
              disabled={disabled}
            />
            <span className='checkbox__text'>Включая НДС</span>
          </label>
        </div>
      </Row>

      <SchemaFormRates 
        values={standardRates}
        group='standardRates'
        title='Стандартные ставки (по конкретной гарантии)'
        name='fromAmount'
        label='Сумма равна или выше'
        onChange={onChangeStandardRates}
        valueRegexp={/\D/g}
        disabled={disabled}
      />

      <SchemaFormRates 
        values={totalRates}
        group='totalRates'
        title='Стандартные ставки (накопительно)'
        name='fromCount'
        label='Кол-во равно или выше'
        onChange={onChangeTotalRates}
        disabled={disabled}
      />

      <SchemaFormRates 
        values={totalRatesByAmount}
        group='totalRatesByAmount'
        title='Стандартные ставки (накопительно, руб)'
        name='fromAmount'
        label='Сумма равна или выше'
        onChange={onChangeTotalRatesByAmount}
        valueRegexp={/\D/g}
        disabled={disabled}
        errorFromAmount={submitted && errors.totalRatesByAmountFromAmount}
        errorRate={submitted && errors.totalRatesByAmountRate}
      />

      {hasDecreaseRates &&
        <SchemaFormRates 
          values={decreaseRates}
          group='decreaseRates'
          title='Стоимость снижения комиссии'
          name='fromPercent'
          label='Снижение комиссии выше'
          label2='Компенсация агентом за понижение'
          onChange={onChangeDecreaseRates}
          disabled={disabled}
        />
      }

      {IS_NEW_BILLING &&
        <SchemaFormRates 
          values={totalDebtRatesByAmount}
          group='totalDebtRatesByAmount'
          title='Стандартные ставки (от суммы задолжности на одного клиента)'
          name='fromAmount'
          label='Сумма задолженности на одного клиента равна или выше'
          onChange={onChangeTotalDebtRatesByAmount}
          valueRegexp={/\D/g}
          disabled={disabled}
        />
      }
      
      <div className={classes.actions}>
        <div className={classes.cancel} onClick={onClose}>Отменить</div>
        <button 
          type='button' 
          className={classnames(classes.add, 'btn-form--ok')}
          onClick={onSubmit}
          disabled={disabledSubmit}
          // disabled={!valid}
        >
          {schemaId ? 'Сохранить' : 'Создать'}
        </button>
      </div>
    </div>
  )
}

export default injectSheet(styles)(SchemaForm)