import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  h3: {
    fontSize: 18,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export const H3 = ({
  children,
  className,
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.h3, className)}>{children}</div>
  )
}