import React from 'react'
import { createUseStyles } from 'react-jss'
import { Fieldset as FieldsetMain } from '../../../../../../layout'

const useStyles = createUseStyles({
  fieldset: {
    marginTop: 10,
  },
})

export const Fieldset = ({ children }) => {
  const classes = useStyles()
  return (
    <FieldsetMain className={classes.fieldset}>{children}</FieldsetMain>
  )
}