import React, { useState } from 'react'
import injectSheet from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { getFile } from '../../../../redux/Files/actions'
import Overlay from '../../../Overlay'
import TextField from '../../TextField'
import Management from '../Management'
import Address from '../Address'
import BankAccounts from '../BankAccounts'
import Staff from '../Staff'
import * as formUtils from '../utils'
import { UI } from './../../../../layout'
import { API_URL } from '../../../../config'
import { formattedDate } from '../../../../utils'

const styles = {
  files: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  button: {
    ...UI.addButton,
    padding: 0,
    width: 180,
    height: 37,
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '&:disabled': {
      opacity: 1,
      cursor: 'default'
    },
    '& + &': {
      marginLeft: 10
    },
    '& > div': {
      borderRadius: 'inherit'
    },
  },
}

const DEFAULT_COUNT = 4

export const CompanyInfo = ({
  companyId,
  classes
}) => {
  const dispatch = useDispatch()

  const { company } = useSelector(state => state.Company.company)

  const [count, setCount] = useState(DEFAULT_COUNT)
  const [loadingEGRUL, setLoadingEGRUL] = useState(false)
  const [loadingFinAnaliz, setLoadingFinAnaliz] = useState(false)

  const onShow = () => setCount((_.get(company, 'OKVED') || []).length)
  const onHide = () => setCount(DEFAULT_COUNT)

  const onGetEGRUL = () => {
    setLoadingEGRUL(true)
    const url = `${API_URL}/company/egrul?companyId=${companyId}`
    const name = `${company.displayName} выписка ЕГРЮЛ`
    const type = 'application/pdf'
    const onSuccess = () => setLoadingEGRUL(false)
    const onError = () => setLoadingEGRUL(false)
    dispatch(getFile({ url, name, type, onSuccess, onError }))
  }

  const onGetFinAnaliz = () => {
    setLoadingFinAnaliz(true)
    const url = `${API_URL}/company/finAnaliz?companyId=${companyId}`
    const name = `${company.displayName} фин анализ`
    const type = 'application/pdf'
    const onSuccess = () => setLoadingFinAnaliz(false)
    const onError = () => setLoadingFinAnaliz(false)
    dispatch(getFile({ url, name, type, onSuccess, onError }))
  }

  return (
    <>
      <div className='row block-info'>
        <div className='justify-content-center col-12'>
          <div className='form-group' style={{ position: 'relative' }}>
            <span className='block_head'>Информация о компании</span>
            <div className={classes.files}>
              <button
                type='button'
                className={classes.button}
                disabled={loadingEGRUL}
                onClick={onGetEGRUL}
              >
                {loadingEGRUL
                  ? <Overlay size='small' />
                  : 'Выписка ЕГРЮЛ'
                }
              </button>
              <button
                type='button'
                className={classes.button}
                disabled={loadingFinAnaliz}
                onClick={onGetFinAnaliz}
              >
                {loadingFinAnaliz
                  ? <Overlay size='small' />
                  : 'Фин. анализ'
                }
              </button>
            </div>
          </div>
        </div>
        <div className='justify-content-center col-12'>
          <TextField {...formUtils.asFieldModal('Наименование', null, _.get(company, 'displayName'), true)} />
        </div>
        <div className='justify-content-center col-12 col-md-4'>
          <TextField {...formUtils.asFieldModal('ИНН', null, _.get(company, 'INN'), true)} />
        </div>
        <div className='justify-content-center col-12 col-md-4'>
          <TextField {...formUtils.asFieldModal('КПП', null, _.get(company, 'KPP'), true)} />
        </div>
        <div className='justify-content-center col-12 col-md-4'>
          <TextField {...formUtils.asFieldModal('ОГРН', null, _.get(company, 'OGRN'), true)} />
        </div>
        <div className='justify-content-center col-12 col-md-6'>
          <TextField {...formUtils.asFieldModal('Дата регистрации', null, formattedDate(_.get(company, 'registrationDate')), true)} />
        </div>
        <div className='justify-content-center col-12 col-md-6'>
          <TextField {...formUtils.asFieldModal('Дата внесения ОГРН в ЕГРЮЛ', null, formattedDate(_.get(company, 'ogrnDate')), true)} />
        </div>
        {/* <div className='justify-content-center col-12'>
          <TextField {...formUtils.asFieldModal('Адрес', null, _.get(company, 'legalAddress.result'), true)} />
        </div> */}
        <div className='justify-content-center col-12'>
          <TextField {...formUtils.asFieldModal('ИФНС', null, `${_.get(company, 'IFNS.code')}, ${_.get(company, 'IFNS.name')}, ${formattedDate(_.get(company, 'IFNS.nalogRegDate'))} `, true)} />
        </div>
        <div className='justify-content-center col-12 col-md-3'>
          <TextField {...formUtils.asFieldModal('ОКПО', null, _.get(company, 'OKPO'), true)} />
        </div>
        <div className='justify-content-center col-12 col-md-3'>
          <TextField {...formUtils.asFieldModal('ОКОПФ', null, _.get(company, 'OKOPF'), true)} />
        </div>
        <div className='justify-content-center col-12'>
          <div className='form-group'>
            <div className='table__title'>ОКВЭД</div>
            <table className='no-margin centered'>
              <thead>
              <tr>
                <td>Код</td>
                <td>Наименование</td>
              </tr>
              </thead>
              <tbody>
              {_.get(company, 'primaryOKVED.code')
                ? <tr>
                  <td style={{ width: '150px' }}>
                    {_.get(company, 'primaryOKVED.code')}{' '}
                    <span className='badge badge-info'>основной</span>
                  </td>
                  <td>{_.get(company, 'primaryOKVED.text')}</td>
                </tr>
                : null
              }
              {(_.get(company, 'OKVED') || []).filter((x, index) => index < count).map(x =>
                <tr key={x.code}>
                  <td style={{ width: '100px' }}>{x.code}</td>
                  <td>{x.text}</td>
                </tr>
              )}
              </tbody>
            </table>
            {count > DEFAULT_COUNT
              ? <div className='btn-all' onClick={onHide}>Скрыть</div>
              : (_.get(company, 'OKVED') || []).length > DEFAULT_COUNT
                ? <div className='btn-all' onClick={onShow}>Посмотреть все</div>
                : null
            }
          </div>
        </div>

        <div className='col-12'>
          <hr />
        </div>

        <div className='justify-content-center col-12'>
          <div className='form-group'>
            <span className='block_head'>Адреса</span>
          </div>
          <Address companyId={companyId} />
        </div>

        <div className='col-12'>
          <hr />
        </div>

        <div className='justify-content-center col-12'>
          <div className='form-group'>
            <span className='block_head'>Структура органов управления</span>
          </div>
          <Management companyId={companyId} />
        </div>

        <div className='col-12'>
          <hr />
        </div>

        <div className='justify-content-center col-12'>
          <div className='form-group'>
            <span className='block_head'>Персонал</span>
          </div>
          <Staff companyId={companyId} />
        </div>

        <div className='col-12'>
          <hr />
        </div>

        <div className='justify-content-center col-12'>
          <div className='form-group'>
            <span className='block_head'>Счета</span>
          </div>
          <BankAccounts companyId={companyId} />
        </div>

      </div>
    </>
  )
}

export default injectSheet(styles)(CompanyInfo)