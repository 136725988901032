import React, { useEffect, useState, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getFactoringContractsBySupplierInn,
  getFactoringDebtors,
  getFactoringView,
  createFactoringBlock,
} from '../../../../redux/Factoring/actions'
import { InputText, Fieldset, Select2, Textarea, RowGrid, Col6, Col12, Modal2 } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'
import { Search } from '../../../../services/api'

const useStyles = createUseStyles({
  addBlockForm: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
})

export const AddBlockForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { entityType } = useParams()

  const { blockTypes } = useSelector(state => state?.Factoring)
  const { username } = useSelector(state => state?.User)

  const blockTypeOptions = [
    { name: '', value: '' },
    ...blockTypes,
  ]

  const [counterpartyType, setCounterpartyType] = useState('Поставщик')
  const [supplierCompanyName, setSupplierCompanyName] = useState('')
  const [supplierId, setSupplierId] = useState('')
  const [supplierInn, setSupplierInn] = useState('')
  const [debtorId, setDebtorId] = useState('')
  const [debtorInn, setDebtorInn] = useState('')
  const [debtorCompanyName, setDebtorCompanyName] = useState('')
  const [factoringContractId, setFactoringContractId] = useState('')
  const [factoringContractNumber, setFactoringContractNumber] = useState('')
  const [supplyContractId, setSupplyContractId] = useState('')
  const [supplyContractNumber, setSupplyContractNumber] = useState('')
  const [supplyId, setSupplyId] = useState('')
  const [supplyNumber, setSupplyNumber] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('')
  const [debtors, setDebtors] = useState([])
  const [supplyContracts, setSupplyContracts] = useState([])
  // const [supplies, setSupplies] = useState([])
  const [factoringContractsBySupplierInn, setFactoringContractsBySupplierInn] = useState([])
  const [submitted, setSubmitted] = useState(0)

  const touched = useMemo(() => submitted > 0, [submitted])

  const supplyContractOptions = useMemo(() => {
    return supplyContracts.filter(item => item?.id === debtorId) || []
  }, [supplyContracts, debtorId])

  const isCounterpartyTypeSupplier = counterpartyType === 'Поставщик'

  // const supplyOptions = useMemo(() => {
  //   return [
  //     { value: '', name: '' },
  //     ...supplies.map(item => ({
  //       name: item.supplyNumber,
  //       value: item.id,
  //     }))
  //   ]
  // }, [supplies])

  const factoringContractOptions = useMemo(() => {
    return [
      { value: '', name: '' },
      ...factoringContractsBySupplierInn.map(item => ({
        value: item.id,
        name: item.number,
      }))
    ]
  }, [factoringContractsBySupplierInn])

  const payload = {
    userId: username,
    type: [type],
    supplierId,
    factoringContractId,
    debtorId,
    supplyContractId,
    supplyId,
    startDate,
    endDate,
    description,
  }

  const errors = {
    ...(type?.length > 0 ? {} : { type: 'Не выбран тип блокировки' }),
    ...(isCounterpartyTypeSupplier && !supplierId?.trim() ? { supplierId: 'Не указан поставщик' } : {}),
    ...(!isCounterpartyTypeSupplier && !debtorId?.trim() ? { debtorId: 'Не указан дебитор' } : {} ),
    // ...(factoringContractId?.trim() ? {} : { factoringContractId: 'Не указан договор факторинга' }),
    // ...(supplyContractId?.trim() ? {} : { supplyContractId: 'Не указан договор поставки' }),
    // ...(supplyId?.trim() ? {} : { supplyId: 'Не указана поставка' }),
    // ...(factoringContractId?.trim() ? {} : { factoringContractId: 'Не указан договор факторинга' }),
    ...(startDate?.trim() ? {} : { startDate: 'Не указана дата начала блокировки' }),
    // ...(endDate?.trim() ? {} : { endDate: 'Не указана дата окончания блокировки' }),
    // ...(description?.trim() ? {} : { description: 'Не указан комментарий' }),
  }

  const isValid = Object.keys(errors)?.length === 0

  useEffect(() => {
    if (supplyContractOptions?.length === 1) {
      const { id } = supplyContractOptions[0]
      setSupplyContractId(id)
    }
  }, [supplyContractOptions])

  useEffect(() => {
    if (supplyContractId && supplierInn && debtorInn && factoringContractId) {
      const entityType = 'supply'
      const payload = {
        supplierInn,
        debtorInn,
        factoringContractId,
        supplyContractId,
        limit: 1000,
      }
      const onSuccess = (data) => {
        // setSupplies(data)
      }
      dispatch(getFactoringView(
        entityType,
        payload,
        onSuccess,
        true,
      ))
    }
  }, [supplyContractId, supplierInn, debtorInn, factoringContractId, dispatch])

  const onChangeFactoringContract = (id, number, startDate) => {
    setFactoringContractId(id)
    setFactoringContractNumber(number)
    const onSuccess = (data) => {
      setDebtors([
        { name: '', value: '' },
        ...data.map(item => ({
          name: item.displayName,
          value: item.id,
          inn: item.inn,
        })),
      ])
      setSupplyContracts([{ name: '', value: '' }, ...data
        .reduce((result, item) => {
          item.supplyContracts.forEach(supplyContract => result.push({
            ...supplyContract,
            supplyContractId: supplyContract?.id,
            id: item.id,
          }))
          return result
        }, [])
        .map(item => ({
          ...item,
          name: item.number,
          value: item.supplyContractId,
        }))
      ])
    }
    dispatch(getFactoringDebtors(id, onSuccess))
  }

  const onChangeDebtorId = (e) => {
    setDebtorId(e.target.value)
    setDebtorInn(debtors.find(item => item?.value === e.target.value)?.inn)
  }

  const onChangeSupplierId = ({ id, displayName, INN }) => {
    setSupplierId(id)
    setSupplierInn(INN)
    setSupplierCompanyName(displayName)
    const entityType = 'factoring_contract'
    const payload = { supplierInn: INN }
    const onSuccess = (data) => setFactoringContractsBySupplierInn(data)
    dispatch(getFactoringContractsBySupplierInn(entityType, payload, onSuccess))
  }
  const onClearSupplierId = () => {
    setSupplierId('')
    setSupplierInn('')
    setSupplierCompanyName('')
  }

  const onChangeDebtorId2 = ({ id, displayName, INN }) => {
    setDebtorId(id)
    setDebtorInn(INN)
    setDebtorCompanyName(displayName)
  }
  const onClearDebtorId = () => {
    setDebtorId('')
    setDebtorInn('')
    setDebtorCompanyName('')
  }

  const onChangeSupplyId = (payload) => {
    setSupplyId(payload.id)
    setSupplyNumber(payload.number)
  }
  const onClearSupplyId = () => {
    setSupplyId('')
    setSupplyNumber('')
  }

  const onCreateFactoringBlock = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(createFactoringBlock(payload, entityType, onSuccess))
    }
  }

  useEffect(() => {
    if (touched) {
      onCreateFactoringBlock()
    }
  // eslint-disable-next-line
  }, [touched, submitted])

  console.log('payload [CURRENT]:', payload)
  console.log('errors', errors)

  return (
    <Modal2 className={classes.addBlockForm} onClose={onClose}>
      <div className={classes.wrapper}>
        <H3>Добавить блокировку</H3>
        <Form>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Select2
                  label='Тип контрагента'
                  name='counterpartyType'
                  options={[
                    { name: 'Поставщик', value: 'Поставщик' },
                    { name: 'Дебитор', value: 'Дебитор' },
                  ]}
                  value={counterpartyType}
                  onChange={e => setCounterpartyType(e.target.value)}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Select2
                  label='Тип блокировки'
                  name='type'
                  options={blockTypeOptions}
                  value={type}
                  onChange={e => setType(e.target.value)}
                  error={touched && errors.type}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          {isCounterpartyTypeSupplier &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <TextFieldWithAutoComplete
                    classNames={{
                      container: 'filter-input',
                      input: 'filter-input__input',
                    }}
                    name='supplierId'
                    placeholder='Поставщик'
                    label='Поставщик'
                    value={supplierCompanyName}
                    defaultValue={supplierCompanyName}
                    onSelect={onChangeSupplierId}
                    onClear={onClearSupplierId}
                    payloadKeys={['id', 'displayName', 'INN']}
                    meta={{
                      touched,
                      error: errors.supplierId,
                    }}
                    withoutLink
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {isCounterpartyTypeSupplier &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Select2
                    label='Договор факторинга'
                    name='factoringContract'
                    options={factoringContractOptions}
                    value={factoringContractId}
                    onChange={(e) => {
                      const id = e.target.value
                      const { number, startDate } = factoringContractsBySupplierInn.find(item => item?.id === id) || {}
                      onChangeFactoringContract(id, number, startDate)
                    }}
                    // error={touched && (errors.factoringContractId || errors.factoringContractDate)}
                    disabled={!supplierId}
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          <Fieldset>
            <RowGrid>
              <Col12>
                {isCounterpartyTypeSupplier
                  ? <Select2
                      label='Дебитор'
                      name='debtorId'
                      options={debtors}
                      value={debtorId}
                      onChange={onChangeDebtorId}
                      // error={touched && errors.debtorId}
                      disabled={!factoringContractId}
                    />
                  : <TextFieldWithAutoComplete
                      classNames={{
                        container: 'filter-input',
                        input: 'filter-input__input',
                      }}
                      name='debtorId'
                      placeholder='Дебитор'
                      label='Дебитор'
                      value={debtorCompanyName}
                      defaultValue={debtorCompanyName}
                      onSelect={onChangeDebtorId2}
                      onClear={onClearDebtorId}
                      payloadKeys={['id', 'displayName', 'INN']}
                      meta={{
                        touched,
                        error: errors.debtorId,
                      }}
                      withoutLink
                    />
                }
              </Col12>
            </RowGrid>
          </Fieldset>
          {isCounterpartyTypeSupplier &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Select2
                    label='Договор поставки'
                    name='supplyContractId'
                    options={[
                      { name: '', value: '' },
                      ...supplyContractOptions,
                    ]}
                    value={supplyContractId}
                    onChange={(e) => {
                      const id = e.target.value
                      const { number } = supplyContractOptions.find(item => item?.id === id) || {}
                      setSupplyContractId(id)
                      setSupplyContractNumber(number)
                    }}
                    // error={touched && errors.supplyContractId}
                    disabled={!debtorId}
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {isCounterpartyTypeSupplier &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <TextFieldWithAutoComplete
                    classNames={{
                      container: 'filter-input',
                      input: 'filter-input__input',
                    }}
                    name='supplyId'
                    placeholder='Поставка'
                    label='Поставка'
                    value={supplyNumber}
                    defaultValue={supplyNumber}
                    onSelect={onChangeSupplyId}
                    onClear={onClearSupplyId}
                    payloadKeys={['id', 'supplyContractNumber']}
                    request={Search.getSupplySuggestions}
                    requestPayload={{
                      supplierInn,
                      debtorInn,
                      factoringContractNumber,
                      supplyContractNumber,
                      limit: 1000,
                    }}
                    requestKey='number'
                    requestSubKeys={['supplierName', 'supplyDate']}
                    meta={{
                      touched,
                      error: errors.supplyId,
                    }}
                    withoutLink
                    disabled={!supplyContractId}
                  />
                  {/* <Select2
                    label='Поставка'
                    name='supplyId'
                    options={supplyOptions}
                    value={supplyId}
                    onChange={e => setSupplyId(e.target.value)}
                    error={touched && errors.supplyId}
                    disabled={!supplyContractId}
                  /> */}
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          <Fieldset>
            <RowGrid>
              <Col6>
                <InputText
                  type='date' 
                  name='startDate'
                  label='Дата начала блокировки'
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}
                  error={touched && errors?.startDate}
                />
              </Col6>
              <Col6>
                <InputText
                  type='date' 
                  name='endDate'
                  label='Дата окончания блокировки'
                  value={endDate}
                  onChange={e => setEndDate(e.target.value)}
                  error={touched && errors?.endDate}
                />
              </Col6>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Textarea
                  name='description'
                  label='Комментарий'
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  // error={touched && errors.description}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        </Form>
        <ButtonWrapper>
          <Button
            disabled={touched && !isValid}
            onClick={() => setSubmitted(submitted + 1)}
          >
            Создать
          </Button>
        </ButtonWrapper>
      </div>
    </Modal2>
  )
}