import React, { memo } from 'react'
import { formattedDate } from '../../../utils'
import { useStyles } from './styles'

export const OperDays = memo(({
  operDays,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.operDays}>
      <table>
        <tbody>
          <tr>
            {operDays?.map((item, index) =>
              <td key={index}>
                {item?.type}: {formattedDate(item?.date)}
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  )
})