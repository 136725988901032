import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createFactoringPayment, getFactoringAccountNumber, getFactoringCompany, getFactoringSupplyContract, resetFactoringReceiver } from '../../../../redux/Factoring/actions'
import { Modal2, Fieldset, InputText, Textarea, InputWithDadata, RowGrid, Col12 } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'
import { AddPaymentFormInner } from '../AddPaymentFormInner'
import { debounce } from 'lodash'
import { formattedCard } from '../../../../utils'

const useStyles = createUseStyles({
  addPaymentForm: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
  value: {
    marginTop: 4,
    padding: [6, 0],
    fontSize: 12,
    lineHeight: 1.2,
    color: '#777',
    fontFamily: 'Courier New,Courier,monospace',
  },
})

export const AddPaymentForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams()

  const [externalId, setExternalId] = useState('')
  const [number, setNumber] = useState('')
  const [amount, setAmount] = useState('')
  const [date, setDate] = useState('')
  const [purpose, setPurpose] = useState('')
  const [payerInn, setPayerInn] = useState('')
  const [payerName, setPayerName] = useState('')
  const [payerKpp, setPayerKpp] = useState('')
  const [payerAccountNumber, setPayerAccountNumber] = useState('')
  const [payerBankName, setPayerBankName] = useState('')
  const [payerBic, setPayerBic] = useState('')
  const [receiverInn, setReceiverInn] = useState('')
  const [receiverName, setReceiverName] = useState('')
  const [receiverKpp, setReceiverKpp] = useState('')
  const [receiverAccountNumber, setReceiverAccountNumber] = useState('')
  const [receiverBankName, setReceiverBankName] = useState('')
  const [receiverBic, setReceiverBic] = useState('')
  const [corrNumber, setReceiverCorrNumber] = useState('')
  const [submitted, setSubmitted] = useState(0)
  const [showAddPaymentFormInnerModal, setShowAddPaymentFormInnerModal] = useState(false)
  const [disabledReceiverBank, setDisabledReceiverBank] = useState(false)

  const onOpenInnerModal = () => setShowAddPaymentFormInnerModal(true)
  const onCloseInnerModal = () => setShowAddPaymentFormInnerModal(false)

  const touched = useMemo(() => submitted > 0, [submitted])

  const payload = {
    externalId,
    number,
    amount,
    date,
    purpose,
    payerInn,
    payerKpp,
    payerName,
    payerAccountNumber,
    payerBic,
    receiverInn,
    receiverKpp,
    receiverName,
    receiverAccountNumber,
    receiverBic,
  }

  const {
    accountNumber,
    supplyContract,
    accountCompany
  } = useSelector(state => state?.Factoring || {})

  const errors = {
    ...(externalId?.trim() ? {} : { externalId: 'Не указан ID платежа' }),
    ...(number?.trim() ? {} : { number: 'Не указан номер' }),
    ...(amount?.trim() ? {} : { amount: 'Не указана сумма' }),
    ...(date?.trim() ? {} : { date: 'Не указана дата' }),
    ...(purpose?.trim() ? {} : { purpose: 'Не указано назначение' }),
    ...(payerInn?.trim() ? {} : { payerInn: 'Не указан плательщик' }),
    ...(payerAccountNumber?.trim() ? {} : { payerAccountNumber: 'Не указан счёт плательщика' }),
    ...(payerBic?.trim() ? {} : { payerBic: 'Не указан банк плательщика' }),
    ...(receiverInn?.trim() ? {} : { receiverInn: 'Не указан получатель' }),
    ...(receiverAccountNumber?.trim() ? {} : { receiverAccountNumber: 'Не указан счёт получателя' }),
    ...(receiverBic?.trim() ? {} : { receiverBic: 'Не указан банк получателя' }),
  }

  const isValid = Object.keys(errors)?.length === 0

  const onChangeDate = (e) => setDate(e.target.value)
  const onChangePayerBank = (item) => {
    setPayerBankName(item.bankName)
    setPayerBic(item.bic)
  }
  const onChangeReceiverBank = (item) => {
    setReceiverBankName(item.bankName)
    setReceiverBic(item.bic)
    setReceiverCorrNumber(item.corrAccount)
  }

  const onChangeExternalId = (e) => setExternalId(e.target.value)
  const onChangeNumber = (e) => setNumber(e.target.value)
  const onChangeAmount = (e) => setAmount(e.target.value)
  const onChangePurpose = (e) => setPurpose(e.target.value)
  const onChangePayerAccountNumber = (e) => setPayerAccountNumber(e.target.value)
  const onChangeReceiverAccountNumber = (e) => {
    onClearReceiver()
    setReceiverAccountNumber(e.target.value)
  }

  const onChangePayer = ({ displayName, INN, KPP }) => {
    setPayerName(displayName)
    setPayerInn(INN)
    setPayerKpp(KPP)
  }
  const onClearPayerName = () => {
    setPayerInn('')
    setPayerName('')
    setPayerKpp('')
  }

  // const onChangeReceiver = ({ displayName, INN, KPP }) => {
  //   setReceiverName(displayName)
  //   setReceiverInn(INN)
  //   setReceiverKpp(KPP)
  // }

  const onClearReceiver = () => {
    setReceiverInn('')
    setReceiverName('')
    setReceiverKpp('')
    setReceiverBankName('')
    setReceiverBic('')
    setReceiverCorrNumber('')
  }

  const onSubmit = () => setSubmitted(submitted + 1)

  const onCreateFactoringPayment = (props) => dispatch(createFactoringPayment({
    payload,
    entityType: params?.entityType,
    ...props,
  }))

  const onCreateFactoringAccount = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    const onError = (message, status) => {
      if (status === 409) {
        onOpenInnerModal()
      }
    }
    if (isValid) {
      onCreateFactoringPayment({
        ignoreDuplicates: false,
        onSuccess,
        onError,
      })
    }
  }

  useEffect(() => {
    if (touched) {
      onCreateFactoringAccount()
    }
  // eslint-disable-next-line
  }, [touched, submitted])

  console.log('payload [CURRENT]:', payload)
  console.log('errors', errors)

  const debounceOnRequestReceiverAccountNumber = useCallback(
    debounce(receiverAccountNumber => {
      dispatch(getFactoringAccountNumber(receiverAccountNumber))
    }, 1000),
    [],
  )
  useEffect(() => {
    if(receiverAccountNumber) {
      debounceOnRequestReceiverAccountNumber(receiverAccountNumber)
    }
  }, [debounceOnRequestReceiverAccountNumber, dispatch, receiverAccountNumber])

  useEffect(() => {
    if (accountNumber?.entities?.[0]) {
      dispatch(getFactoringSupplyContract(accountNumber?.entities?.[0].entityId))
    }
  }, [accountNumber, dispatch])

  useEffect(() => {
    if(supplyContract) {
      dispatch(getFactoringCompany(supplyContract?.supplierInn))
    }
  }, [dispatch, supplyContract])

  useLayoutEffect(() => {
    if(accountNumber?.entities?.[0] && accountCompany?.compaines?.[0]) {
      onChangeReceiverBank(accountNumber)
      setDisabledReceiverBank(!!accountNumber?.bankName)
      setReceiverName(accountCompany?.compaines?.[0].displayName)
    }
  }, [accountCompany, accountNumber, accountNumber.bankName, accountNumber.bic])

  useEffect(() => {
    onClearReceiver()
    dispatch(resetFactoringReceiver())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal2
      className={classes.addPaymentForm}
      onClose={onClose}
    >
      <div className={classes.wrapper}>
        <H3>Добавить платёж</H3>
        <Form>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='externalId'
                  label='ID платежа'
                  value={externalId}
                  onChange={onChangeExternalId}
                  error={touched && errors?.externalId}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='number'
                  label='Номер'
                  value={number}
                  onChange={onChangeNumber}
                  error={touched && errors?.number}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='amount'
                  label='Сумма'
                  value={amount}
                  onChange={onChangeAmount}
                  error={touched && errors?.amount}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='date' 
                  name='date'
                  label='Дата'
                  value={date}
                  onChange={onChangeDate}
                  error={touched && errors?.date}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Textarea
                  name='purpose'
                  label='Назначение'
                  value={purpose}
                  onChange={onChangePurpose}
                  error={touched && errors.purpose}
                  maxLength={160}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <TextFieldWithAutoComplete
                  classNames={{
                    container: 'filter-input',
                    input: 'filter-input__input',
                  }}
                  name='payerInn'
                  placeholder='Плательщик'
                  label='Плательщик'
                  value={payerName}
                  defaultValue={payerName}
                  onSelect={onChangePayer}
                  onClear={onClearPayerName}
                  payloadKeys={['displayName', 'INN', 'KPP']}
                  meta={{
                    touched,
                    error: errors.payerInn,
                  }}
                  withoutLink
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='payerAccountNumber'
                  label='Счёт плательщика'
                  value={payerAccountNumber}
                  onChange={onChangePayerAccountNumber}
                  error={touched && errors?.payerAccountNumber}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputWithDadata
                  type='text'
                  service='bank'
                  name='payerBankName'
                  label='Банк плательщика'
                  placeholder='Введите название банка'
                  value={payerBankName}
                  onSelect={(item) => onChangePayerBank(item)}
                  error={touched && errors?.payerBic}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='receiverAccountNumber'
                  label='Счёт получателя'
                  value={receiverAccountNumber}
                  onChange={onChangeReceiverAccountNumber}
                  error={touched && errors?.receiverAccountNumber}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text'
                  name='receiverName'
                  label='Получатель'
                  value={receiverName}
                  error={touched && errors?.receiverName}
                  disabled={disabledReceiverBank}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text'
                  name='receiverName'
                  label='Банк получателя'
                  value={receiverBankName}
                  error={touched && errors?.receiverBic}
                  disabled={disabledReceiverBank}
                  children={
                    <div className={classes.value}>
                      {accountNumber && receiverBic && corrNumber
                        ? `БИК: ${receiverBic}, К/С: ${formattedCard(corrNumber)}`
                        : 'нет информации о банке'
                      }
                    </div>
                  }
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        </Form>
        <ButtonWrapper>
          <Button
            onClick={onSubmit}
            disabled={touched && !isValid}
          >
            Создать
          </Button>
        </ButtonWrapper>
      </div>
      {showAddPaymentFormInnerModal &&
        <AddPaymentFormInner
          payload={payload}
          onCreateFactoringPayment={onCreateFactoringPayment}
          onCloseExternalModal={onClose}
          onCloseInnerModal={onCloseInnerModal}
        />
      }
    </Modal2>
  )
}