import { useMemo } from 'react'
import { uas } from '../../../../services/utility/charts/uas'
import { useChartSize } from '../../useChartSize'

export const useFundsGraphicOptions = ({
  data,
  // chart,
  onClickArea,
  fullWidth,
}) => {
  const { width, height } = useChartSize({ fullWidth })

  const memoizedOptions = useMemo(() => {
    const response = data

    let $container = document.getElementsByClassName('highcharts-container')[0]
    let colors = ['#287782', '#FABA35']
    let points = []
    let levelIdx = []
    let levelPoint = []
    let levelNames = { bank: 'Банк', initiator: 'Инициатор' }

    levelIdx[0] = 0

    for (var l1 in response) {
      if (response.hasOwnProperty(l1)) {
        levelPoint[0] = {
          id: 'id_' + levelIdx[0],
          name: levelNames[l1] || l1,
          color: colors[levelIdx[0]],
          amount: 0,
        }

        levelIdx[1] = 0

        for (var l2 in response[l1]) {
          if (response[l1].hasOwnProperty(l2)) {
            levelPoint[1] = {
              id: levelPoint[0].id + '_' + levelIdx[1],
              // id: 'id_' + levelIdx[1],
              name: l2,
              parent: levelPoint[0].id,
              amount: 0,
            }

            points.push(levelPoint[1])

            levelIdx[2] = 0

            for (var l3 in response[l1][l2]) {
              if (response[l1][l2].hasOwnProperty(l3)) {
                levelPoint[2] = {
                  id: levelPoint[1].id + '_' + levelIdx[2],
                  name: response[l1][l2][l3].name,
                  parent: levelPoint[1].id,
                  value: response[l1][l2][l3].count,
                  type: 'task_type',
                  amount: response[l1][l2][l3].amount,
                }

                if (levelPoint[2].amount) {
                  levelPoint[1].amount += levelPoint[2].amount
                }

                points.push(levelPoint[2])

                levelIdx[3] = 0
                if (response[l1][l2][l3].assignee) {
                  for (var l4 in response[l1][l2][l3].assignee) {
                    if (response[l1][l2][l3].assignee.hasOwnProperty(l4)) {
                      points.push({
                        id: levelPoint[2].id + '_' + levelIdx[3],
                        name: l4,
                        parent: levelPoint[2].id,
                        value: response[l1][l2][l3].assignee[l4].count,
                        type: 'assignee',
                        color: colors[levelIdx[0]],
                        keys: [l1, l2, l3, 'assignee', l4],
                      })
                    }

                    levelIdx[3]++
                  }
                }

                levelIdx[2]++
              }
            }

            if (levelPoint[1].amount) {
              levelPoint[0].amount += levelPoint[1].amount
            }

            levelIdx[1]++
          }
        }

        points.push(levelPoint[0])

        levelIdx[0]++
      }
    }

    return {
      lang: {
        downloadPNG: 'Скачать PNG файл',
      },
      chart: {
        height,
        width,
        spacing: [8, 32, 8, 32],
        events: {
          beforePrint: function () {
            this.update({
              title: {
                y: 12,
              },
            })
          },
          afterPrint: function () {
            if (!$container.hasClass('fullscreen')) {
              this.update({
                title: {
                  y: -32,
                },
              })
            }
          },
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ['downloadPNG'],
          },
        },
        scale: 4,
        chartOptions: {
          title: {
            y: 12,
          },
          legend: {
            enabled: true,
            floating: false,
          },
        },
      },
      title: {
        text: 'Объемы средств по этапам',
        y: -32,
      },
      plotOptions: {
        treemap: {
          borderColor: '#999',
          dataLabels: {
            // useHTML: true,
            formatter: function () {
              var html = ''

              if (this.point.type === 'assignee') {
                if (this.point.name === 'unknown') {
                  html =
                    '<div style="text-align: center;">не назначено (' +
                    this.point.value +
                    ')</div>'
                } else {
                  html += '<div style="text-align: center;">'
                  html += this.point.name + ' '
                  html += '(' + this.point.value + ')'
                  html += '</div>'
                }
              } else {
                html =
                  '<div style="text-align: center;">' +
                  this.point.name +
                  ' (' +
                  this.point.value +
                  ')' +
                  '</div>'
              }

              return html
            },
            allowOverlap: true,
            color: '#FFFFFF',
            // backgroundColor: 'rgba(0, 0, 0, 0.2)',
            //borderRadius: '8px',
            style: {
              color: '#FFFFFF',
              fontSize: '12px',
              textShadow: '0 0 1px #000, 0 0 1px #000, 0 0 1px #000',
              // textShadow: 'none'
            },
          },
          cursor: 'pointer',
          events: {
            click: onClickArea,
          },
        },
      },
      tooltip: {
        // enabled: false,
        useHTML: true,
        formatter: function () {
          if (this.point.type === 'assignee') {
            if (this.point.name === 'unknown') {
              return (
                '<div style="text-align: center;">не назначено (' +
                this.point.value +
                ')</div>'
              )
            } else {
              return this.point.name + ' (' + this.point.value + ')'
            }
          } else {
            var html = ['<h5>' + this.point.name + '</h5>']

            html.push('<table class="chart-tooltip">')

            html.push(
              '<tr><th>Количество задач</th><td>' +
                this.point.value +
                '</td></tr>'
            )

            if (this.point.amount) {
              html.push(
                '<tr><th>Сумма</th><td>' +
                  uas.helpers.numberToCurrency(
                    uas.helpers.toRub(this.point.amount),
                    false
                  ) +
                  ' ₽</td></tr>'
              )
            }

            html.push('</table>')

            return html.join('')
          }
        },
      },
      series: [
        {
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          allowDrillToNode: true,
          drillUpButton: {
            text: '',
            position: {
              x: -100,
              y: -100,
            },
          },
          animationLimit: 1000,
          dataLabels: {
            enabled: false,
          },
          levelIsConstant: true,
          levels: [
            {
              level: 1,
              dataLabels: {
                enabled: true,
              },
              borderWidth: 3,
            },
          ],
          states: {
            hover: {
              borderColor: '#FFFFFF',
            },
          },
          data: points,
        },
      ],
    }
  }, [data, width, height, onClickArea])

  return memoizedOptions
}
