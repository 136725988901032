import React, { useEffect, useState, useMemo, memo, useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFactoringDistributePayment,
  // resetFactoringDistributePayment,
} from '../../../../redux/Factoring/actions'
import Overlay from '../../../../components/Overlay'
import { InputText, Modal2, Checkbox } from '../../../../layout'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'
import { formattedNumber } from '../../../../utils'
import { debounce } from 'lodash'

const useStyles = createUseStyles({
  matchingPaymentModal: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
  distributePayment: {
    marginTop: 30,
  },
  item: {
    fontSize: 14,
    '& + &': {
      marginTop: 8,
    },
    '& > span': {
      width: 210,
      fontWeight: 600,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  totalSum: {
    marginTop: 30,
    fontWeight: 600,
  },
  checkbox: {
    marginTop: 20,
  },
  loader: {
    position: 'absolute',
  },
})

const TYPES = {
  'Непогашенные поставки': 'поставке',
  'Уведомления по комиссии': 'комиссии',
}

const ENTITY_TYPES = {
  COMMISSION: 'Комиссия',
  FINANCING: 'Финансирование',
  SUPPLY_RETURN: 'Остаток по поставке',
  SUPPLY_PREPAY: 'Платеж поставщика',
  SUPPLY_PREPAY_RETURN: 'Возврат поставщику от дебитора',
  COMMISSION_DEBTOR: 'Комиссия',
  SUPPLY_RETURN_PREPAY: 'Выплата остатка оплаченная поставщиком',
  RETURN_OVER_SUPPLY: 'Возврат через поставку',
}

const CAN_DIRECT_PAYMENT_TYPES = ['supply', 'supplyPrepay']
const CAN_DIRECT_PAYMENT_TYPES_RU = ['Задолженность дебитора', 'Задолженность по поставке']

const ENTITY_TYPES_CHANGES = {
  FINANCING: 'SUPPLY_PREPAY',
  COMMISSION_DEBTOR: 'COMMISSION',
  SUPPLY_RETURN: 'SUPPLY_RETURN_PREPAY',
}

export const MatchingPaymentModal = memo(
  ({
    tab,
    isDraft,
    drafts,
    order,
    payment,
    autoDistributePayment,
    onAddOrEditDraft,
    onClose,
    setOrder,
    setError,
    allDirectPayment,
    setAllDirectPayment
  }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const {
      tasks,
      isFetching,
      distributePayment,
    } = useSelector(state => state?.Factoring || {})

    const orderId = order?.id
    const paymentId = payment?.id
    const paymentDate = payment?.date

    const taskUnmatchedAmount = useMemo(() => {
      return tasks?.items?.find(item => item?.data?.payment?.id === paymentId)?.data?.payment?.unmatchedAmount
    }, [paymentId, tasks])

    const orderUnmatchedAmount = useMemo(() => {
      return order?.unmatchedAmount
    }, [order])

    const [sum, setSum] = useState(Math.min(taskUnmatchedAmount, orderUnmatchedAmount))
    const [dueAmounts, setDueAmounts] = useState([])
    const [directPayment, setDirectPayment] = useState(false)
    const [woLeftovers, setWoLeftovers] = useState(false)
    const [submitted, setSubmitted] = useState(0)

    const touched = useMemo(() => submitted > 0, [submitted])

    // const totalSum = dueAmounts.reduce((result, item) => item ? result + parseFloat(item) : result, 0)
    const totalSum = distributePayment.reduce((result, item) => item ? result + parseFloat(item.dueAmount) : result, 0)

    const canDirectPayment =
      CAN_DIRECT_PAYMENT_TYPES.indexOf(order?.orderType) > -1 &&
      payment?.payerInn &&
      order?.supplierInn &&
      payment?.payerInn === order?.supplierInn

    const payload = useMemo(() => ({
      order: {
        ...order,
        orderType: canDirectPayment
          ? directPayment
            ? CAN_DIRECT_PAYMENT_TYPES[0]
            : CAN_DIRECT_PAYMENT_TYPES[1]
          : order.orderType,
        orderTypeRu: canDirectPayment
          ? directPayment
            ? CAN_DIRECT_PAYMENT_TYPES_RU[0]
            : CAN_DIRECT_PAYMENT_TYPES_RU[1]
          : order.orderTypeRu,
      },
      paymentId,
      matchedAmount: parseFloat(sum),
      details: distributePayment.reduce((result, { entityType, entityId, dueAmount }, index) => {
        const amount = parseFloat(dueAmount)
          result.push({
            entityId,
            amount,
            entityType: canDirectPayment && !directPayment
              ? ENTITY_TYPES_CHANGES[entityType] || entityType
              : canDirectPayment && directPayment && entityType === 'FINANCING'
                ? 'FINANCING_SUPPLIER'
                : entityType,
          })
        return result
      }, []),
      woLeftovers,
    }), [canDirectPayment, distributePayment, directPayment, order, paymentId, sum, woLeftovers])



    useEffect(() => {
      if (isDraft && drafts?.length > 0) {
        const currentDraft = drafts.find(item => item?.order?.id === payload?.order?.id)
        setDueAmounts(currentDraft?.details?.map(item => item?.amount))
        setWoLeftovers(currentDraft?.woLeftovers)
        setSum(totalSum)
      }
      if (!isDraft && distributePayment?.length > 0) {
        setDueAmounts(distributePayment.map(item => item?.dueAmount))
      }
    }, [isDraft, drafts, payload.order.id, distributePayment, totalSum])

    const isEdit = payload?.order?.id && drafts.some(item => item?.order?.id === payload?.order?.id)

    useEffect(() => {
      setDirectPayment(isEdit ? order?.orderType === CAN_DIRECT_PAYMENT_TYPES[0] : false)
    }, [isEdit, order])

    const errors = {
      ...(String(sum)?.trim() ? {} : { sum: 'Не указана cумма распределения' }),
      ...(parseFloat(sum).toFixed(2) === parseFloat(totalSum).toFixed(2)
        ? {}
        : { sum: 'Сумма распределения должна быть равна значению ИТОГО' }
      ),
      ...(dueAmounts.some(item => item === '' || item === undefined || item === null)
        ? { dueAmount: 'Не указана зачисляемая сумма' }
        : {}
      ),
      ...(parseFloat(sum) > parseFloat(orderUnmatchedAmount)
        ? { sum: `Сумма распределения превышает сумму ордера (${formattedNumber(orderUnmatchedAmount)})` }
        : {}
      ),
    }

    const isValid = Object.keys(errors)?.length === 0

    const onChangeDueAmount = (e, index) => setDueAmounts(
      dueAmounts.map((item, idx) => {
        if (index === idx) {
          item = e.target.value
        }
        return item
      })
    )

    const onSubmit = useCallback(() => {
      setSubmitted((prev) => prev + 1)
    }, [setSubmitted])

    // const onCloseAndReset = useCallback(() => {
    //   onClose()
    //   dispatch(resetFactoringDistributePayment())
    // }, [dispatch, onClose])

    const onSuccess = useCallback(() => {
      onAddOrEditDraft(payload)
      onClose()
    }, [payload, onAddOrEditDraft, onClose])

    useEffect(() => {
      if (touched && isValid) {
        onSuccess()
      }
    // eslint-disable-next-line
    }, [submitted])

    useEffect(() => {
      if (paymentId && orderId && paymentDate && sum && !isDraft) {
        dispatch(getFactoringDistributePayment({
          payload: {
            sum,
            orderId,
            woLeftovers,
            date: paymentDate,
          },
          onSuccess: () => {
            if (autoDistributePayment) {
              onSubmit()
            }
          },
          onError: (error) => {
            setError(error)
            setOrder(order)
          },
        }))
      }
    }, [dispatch, order, isDraft, paymentId, paymentDate, orderId, woLeftovers, sum, setError, setOrder, autoDistributePayment, onSubmit])

    const ref = useRef(false)

    const distributePaymentRequest = useMemo(
      () =>
        debounce((sum) => {
          if(ref.current === true) {
            dispatch(getFactoringDistributePayment({
              payload: {
                sum,
                orderId,
                woLeftovers,
                date: paymentDate,
              },
              onSuccess: () => {
                if (autoDistributePayment) {
                  onSubmit()
                }
              },
              onError: (error) => {
                setError(error)
                setOrder(order)
              },
            }))
          }
        }, 1000),
      [autoDistributePayment, dispatch, onSubmit, order, orderId, paymentDate, setError, setOrder, woLeftovers]
    )
    const onChangeSum = (e) => {
      const value = +(e.target.value.replace(/\s/g, ''))
      setSum(value)
      distributePaymentRequest(value)
    }

    useEffect(() => {
      setDueAmounts(payload.details.map(item => item.amount))
    }, [payload])

    useEffect(() => {
      ref.current = true
    }, [])

    if (autoDistributePayment) {
      return null
    }

    useEffect(() => {
      setDirectPayment(!!allDirectPayment)
    }, [allDirectPayment])

    return (
      <Modal2
        className={classes.matchingPaymentModal}
        onClose={onClose}
      >
        <div className={classes.wrapper}>
          <H3>Квитовка по {TYPES[tab]}</H3>
          <Form>
            <div className={classes.item}>
              <span>№ Платежа:</span> {payment?.number}
            </div>
            <div className={classes.item}>
              <span>Дата платежа:</span> {new Date(paymentDate)?.toLocaleDateString('ru-RU')}
            </div>
            <div className={classes.item}>
              <span>Плательщик:</span> {payment?.payerName}
            </div>
            <div className={classes.item}>
              <span>Сумма:</span> {formattedNumber(payment?.amount)}
            </div>
            <div className={classes.item}>
              <span>Остаток:</span> {formattedNumber(payment?.unmatchedAmount)}
            </div>
            {order?.externalEntity?.currency &&
              <div className={classes.item}>
                <span>Валюта:</span> {order.externalEntity.currency}
              </div>
            }
            <div className={classes.item}>
              <InputText
                type='text'
                name='sum'
                label='Сумма распределения'
                value={sum}
                onChange={onChangeSum}
                error={touched && errors?.sum}
              />
            </div>
            {distributePayment.map(({
              entityType,
              name,
              amount,
              repaymentAmount,
              debtAmountOriginal,
              dueAmount,
            }, index) =>
              <div className={classes.distributePayment} key={index}>
                <div className={classes.item}>
                  <span>{ENTITY_TYPES[entityType]}:</span> {name}
                </div>
                <div className={classes.item}>
                  <span>Общая сумма:</span> {formattedNumber(amount) || '0'}
                </div>
                <div className={classes.item}>
                  <span>Оплаченная сумма:</span> {formattedNumber(repaymentAmount) || '0'}
                </div>
                <div className={classes.item}>
                  <span>Остаток долга:</span> {debtAmountOriginal}
                </div>
                <div className={classes.item}>
                  <span>Сумма к распределению:</span> {formattedNumber(parseFloat(dueAmounts[index])) || '0'}
                </div>
                {order?.externalEntity?.currency &&
                  <div className={classes.item}>
                    <span>Валюта:</span> {order.externalEntity.currency}
                  </div>
                }
                <div className={classes.item}>
                  <InputText
                    type='text'
                    name='dueAmount'
                    label='Зачисляемая сумма'
                    value={dueAmount}
                    onChange={(e) => onChangeDueAmount(e, index)}
                    error={touched && !dueAmounts[index] && errors?.dueAmount}
                    disabled={true}
                  />
                </div>
              </div>
            )}
            {canDirectPayment &&
              <Checkbox
                id='directPayment'
                className={classes.checkbox}
                checked={directPayment}
                onChange={e => setDirectPayment(e.target.checked)}
                label='Прямой платеж'
              />
            }
            <Checkbox
              id='woLeftovers'
              className={classes.checkbox}
              checked={woLeftovers}
              onChange={e => setWoLeftovers(e.target.checked)}
              label='Без остатка'
            />
            <div className={classes.totalSum}>ИТОГО: {formattedNumber(totalSum)}</div>
          </Form>
          <ButtonWrapper>
            <Button
              onClick={onSubmit}
              disabled={touched && !isValid}
            >
              Связать
            </Button>
          </ButtonWrapper>
        </div>
        {isFetching &&
          <Overlay className={classes.loader} />
        }
      </Modal2>
    )
  }
)