import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getDashboard, clearDashboard } from '../../redux/Dashboard/actions'

import DashboardFooter from '../../components/Dashboard/Footer'
import DashboardTasksBlock from '../../components/Dashboard/TasksBlock'
import Overlay from '../../components/Overlay'

import { plural } from '../../utils'

class Dashboard extends Component {
  static propTypes = {
    onSelectTasks: PropTypes.func.isRequired,
    onCloseDashboard: PropTypes.func.isRequired,
  }

  state = {
    activeId: '',
  }

  componentDidMount() {
    this.props.getDashboard()
  }

  onSelectFilter = ({ typeId, clientId, clientName }) => {
    this.props.onSelectTasks({
      taskType: typeId,
      principalCompanyId: clientId,
      clientName,
    })
    this.props.onCloseDashboard('/tasks')
  }

  onActive = (activeId) => this.setState({ activeId })

  render() {
    const { isFetching, total, blocks, footer } = this.props
    const { activeId } = this.state

    if (isFetching && (total < 1)) {
      return <Overlay size='big' transparent />
    }

    return (
      <div className='container'>
        <div className='row'>
          <div className='col-12 dash'>
            <h1 className='dash__title'>
              <Link to={{
                pathname: '/tasks',
                state: {
                  setFilter: true,
                  data: {},
                }
              }}>
                У вас <span>{total}</span> {plural(total, 'задача', 'задачи', 'задач')}.
              </Link>
            </h1>
            <h2>Мы сгруппировали их так, чтобы вы могли закрыть их максимально быстро.</h2>
            {blocks.map(({ id, title, name, total, items, count }) => (
              <DashboardTasksBlock
                key={id}
                id={id}
                title={title}
                name={name}
                total={total}
                list={items}
                count={count}
                onSelectFilter={this.onSelectFilter}
                onActive={this.onActive}
                isActive={activeId === id}
              />
            ))}
            {footer.map(footer => 
              <DashboardFooter 
                key={footer.name} 
                {...footer} 
                onSelectFilter={this.onSelectFilter} 
              />
            )}
            <DashboardFooter footer={footer} onSelectFilter={this.onSelectFilter} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ Dashboard }) => {
  return {
    isFetching: Dashboard.isFetching,
    total: Dashboard.total,
    blocks: Dashboard.blocks,
    footer: Dashboard.footer,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getDashboard: () => {
      dispatch(getDashboard())
    },
    clearDashboard: () => {
      dispatch(clearDashboard())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)