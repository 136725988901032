import axios from 'axios'
import { API_URL } from '../../config'
import { createSearchString } from '../../utils'
import { ERRORS } from '../../config'

export const getFactoringTasks = async ({ taskDefinitionKey, type }, filters) => {
  const searchString = createSearchString(filters)
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/gateway/task?taskDefinitionKey=${taskDefinitionKey}&type=${type}&${searchString}`,
      data: type
        ? {
            // [type === 'PAYMENT' ? 'monitoring' : type?.toLowerCase()]: { ...filters }
            [type?.toLowerCase()]: { ...filters }
          }
        : {},
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringTasks2 = async ({ type }, filters) => {
  const searchString = createSearchString(filters)
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/gateway/task/${type}?${searchString}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const completeFactoringTask = async (id, type, payload) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/gateway/task/${type}/${id}`,
      data: payload,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringView = async (entityType, filters) => {
  const searchString = createSearchString(filters)
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/grid/${entityType?.toUpperCase()}?${searchString}`,
      data: {},
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringShortInfo = async (entityType, companyId) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/${entityType?.toUpperCase()}/${companyId}/short`,
      data: {},
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const sendFactoringMessage = async (payload) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/view/comment`,
      data: payload,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringMessages = async (entityType, entityId) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/comment/${entityType.toUpperCase()}/${entityId}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const createFactoringEntity = async (payload, entityType, ignoreDuplicates) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: ignoreDuplicates
        ? `${API_URL}/view/entity/${entityType.toUpperCase()}?ignoreDuplicates=${ignoreDuplicates}`
        : `${API_URL}/view/entity/${entityType.toUpperCase()}`,
      data: payload,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (error) {
    console.log(error)
    const { status, message } = error?.response?.data || {}
    return {
      isSuccess: false,
      message: message || (typeof error?.response?.data === 'string'
        ? error?.response?.data
        : error?.message
      ),
      status,
    }
  }
}

export const startFactoringProcess = async (payload, processDefinitionKey) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/gateway/process/start/${processDefinitionKey}`,
      data: payload,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const startFactoringProcessTransit = async (payload, processDefinitionKey) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/gateway/process/start/${processDefinitionKey}/transit`,
      data: payload,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const startFactoringProcessContractPreparation = async (payload, processDefinitionKey) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/gateway/process/start/${processDefinitionKey}/contractPreparation`,
      data: payload,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const startFactoringProcessSupplyUpdate = async (payload, processDefinitionKey) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/gateway/process/start/${processDefinitionKey}/supplyUpdate`,
      data: payload,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringRefDocuments = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/gateway/ref/document`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringDeferSettings = async ({ entityType, entityId }) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/defersettings/${entityType}/${entityId}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringRefOperationCodeTypes = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/ref/operation/code`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringDebtors = async (factoringContractId) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/factoring/${factoringContractId}/debtors`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringDuplicateSettings = async ({ entityId, entityType }) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/duplicatesettings/${entityType.toUpperCase()}/${entityId}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringPriceMatrix = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/factoring/${id}/rates`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringSupplyCommissions = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/supply/${id}/commissions`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringAccountEntityTypes = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/ref/account/entitytype`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringSuretyTypes = async (factoringContractId) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/factoring/${factoringContractId}/surety/`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringRefRateTypes = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/ref/rate`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringRefRateConfig = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/ref/rate/config`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringRefBlockTypes = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/ref/block`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringRefOperationTypes = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/ref/status/operation`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringRefAccountTypes = async (nameLike) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: nameLike
        ? `${API_URL}/view/ref/account/type?nameLike=${nameLike?.toLowerCase()}`
        : `${API_URL}/view/ref/account/type`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
        list: data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringRefOfferingKeys = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/ref/offeringkey`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringRefIssues = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/gateway/ref/issue`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringRefLimitTypes = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/ref/limit/constraint/type`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringMonIssueNotifications = async (filters) => {
  const searchString = createSearchString(filters)
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/grid/MON_ISSUE_NOTIFICATION?${searchString}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringMonIssueNotificationTab = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/issue/notification/${id}/items`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringSupplyRegistrySupplyTab = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/supplyregistry/${id}/supply`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringPaymentOrders = async ({ filters, orderType }) => {
  const searchString = createSearchString(filters)
  const orderTypeString = orderType.reduce((result, item) => `${result}&orderType=${item}`, '')
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/gateway/task/orders?${searchString}&fullyMatched=false${orderTypeString}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringMatchingsByTaskId = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/gateway/task/${id}/matchings`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringMatchingsByPaymentId = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/gateway/task/matching/${id}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringDistributePayment = async ({ orderId, sum, date, woLeftovers }) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/gateway/payment/distribute/${orderId}?woLeftovers=${woLeftovers}`,
      data: {
        sum,
        date,
      },
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    return {
      isSuccess: false,
      message: ERRORS[err?.response?.data] || err?.message,
    }
  }
}

export const deleteFactoringOperation = async (id) => {
  try {
    const { data } = await axios({
      method: 'DELETE',
      url: `${API_URL}/view/operation/${id}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringRefMasterOperationTypes = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/ref/master/operation/type`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringRefMatchingReasons = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/gateway/ref/matchingreason`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringMasterOperation = async ({ type, entityId }) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/operation/master/${type}/${entityId}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringCounterPartyCommon = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/counterparty/${id}/common`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringCounterPartyAddress = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/counterparty/${id}/address`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringCounterPartyState = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/counterparty/${id}/state`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringCounterPartyInfo = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/counterparty/${id}/info`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringCounterPartySigner = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/counterparty/${id}/signer`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringCounterPartyContact = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/counterparty/${id}/contact`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringFactoringContractCommon = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/contract/factoring/${id}/common`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringFactoringContractMain = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/contract/factoring/${id}/main`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringFactoringContractContract = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/contract/factoring/${id}/supply/contract`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringFactoringContractAccount = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view//detail/contract/factoring/${id}/account`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringFactoringContractSurety = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view//detail/contract/factoring/${id}/surety`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringFactoringContractTariff = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/contract/factoring/${id}/tariff`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringFactoringContractFeatures = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/contract/factoring/${id}/feature`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringSupplyContractCommon = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/contract/supply/${id}/common`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringAccountEntities = async (number) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/account/${number}/entities`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringConstraintOrders = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/limit/${id}/orders`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringSupplyCommonTab = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/supply/${id}/common`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringSupplyDocumentTab = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/supply/${id}/document`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringSupplyFinancingTab = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/detail/supply/${id}/financing`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringHolidaysByPeriod = async (startDate, endDate) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/calendar/holidays/${startDate}/${endDate}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const setFactoringHolidayDate = async (date) => {
  try {
    const { data } = await axios({
      method: 'PUT',
      url: `${API_URL}/view/calendar/holiday/${date}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const deleteFactoringHolidayDate = async (date) => {
  try {
    const { data } = await axios({
      method: 'DELETE',
      url: `${API_URL}/view/calendar/holiday/${date}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const enableFactoringHolidayDate = async (date) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/view/calendar/holiday/${date}/enable`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const disableFactoringHolidayDate = async (date) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/view/calendar/holiday/${date}/disable`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringOperDays = async (showClosed = false) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/calendar/operday?showClosed=${showClosed}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data: data?.filter(item => item?.type !== 'Ручная квитовка'),
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringAccountNumber = async (number) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/accounting/account/${number}`,
    })
      return {
        isSuccess: true,
        data,
      }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringSupplyContract = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/contract/contract/factoring/${id}/`,
    })
      return {
        isSuccess: true,
        data,
      }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}

export const getFactoringCompany = async (supplierInn) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/contract/company/SUPPLIER?inns=${supplierInn}`,
    })
      return {
        isSuccess: true,
        data,
      }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
    }
  }
}