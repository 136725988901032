import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getFieldName } from '../utils'
import { STYLES } from '../config'

const useStyles = createUseStyles({
  root: {
    marginBottom: '0 !important',
    '& thead': {
      '& tr': {
        borderBottom: [1, 'solid', '#ddd'],
      },
    },
    '& tbody': {
      '& tr + tr': {
        borderTop: [1, 'solid', '#ddd'],
      },
    },
    '& th': {
      background: '#eee',
      padding: [12, 6],
      fontSize: 10,
      lineHeight: 1.3,
      fontWeight: 600,
      color: '#888',
      textAlign: 'left',
      position: 'sticky',
      top: 0,
    },
    '& td': {
      padding: '12px 6px !important',
      fontSize: 12,
      lineHeight: 1.5,
      fontWeight: 500,
      color: '#4a4a4a',
      textAlign: 'left !important',
      verticalAlign: 'middle',
      cursor: 'pointer',
      '&:first-child': {
        maxWidth: 150,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '&:last-child': {
        textAlign: 'left !important',
      },
    },
  },
  status: {
    padding: [2, 10],
    fontSize: 11,
    lineHeight: 1.5,
    fontWeight: 500,
    borderWidth: 1, 
    borderStyle: 'solid',
    borderRadius: 6,
    display: 'inline-block',
  },
  type: {
    width: 146,
    padding: 2,
    fontSize: 11,
    lineHeight: 1.5,
    fontWeight: 500,
    borderRadius: 6,
    display: 'inline-block',
    textAlign: 'center',
    whiteSpace: 'normal',
  },
  date: {
    color: '#9B9B9B',
  },
  reason: {
    whiteSpace: 'normal',
  },
})

const Table = ({ items, onClick }) => {
  const classes = useStyles()

  const { orders } = useSelector(state => state?.Company?.orders) || {}

  const headers = orders?.fields
  const hasHeaders = headers?.length > 0

  return (
    <table className={classes.root}>
      <thead>
        {hasHeaders && 
          <tr>
            {headers.map(({ id }) => getFieldName(id, orders)
              ? <th key={id}>{getFieldName(id, orders)}</th>
              : null
            )}
          </tr>
        }
      </thead>
      <tbody>
        {items?.length > 0
          ? items.map(({ orderId, fields }) => 
              <tr key={orderId} onClick={() => onClick(orderId)}>
                {fields?.length > 0 && fields.map(({ id, value }) =>
                  <td key={id}>
                    {getFieldName(id, orders) && value
                      ? <span
                          className={classnames({
                            [classes.status]: id === 'orderStatus',
                            [classes.type]: id === 'orderTypeName',
                            [classes.date]: id === 'provisionedDate',
                            [classes.reason]: id === 'lostReason',
                          })}
                          style={STYLES[id] ? STYLES[id][value] : {}}
                        >
                          {value}
                        </span>
                      : '-'
                    }
                  </td>
                )}
              </tr>
            )
          : <tr className='no-hover'>
              <td colSpan='8'>Сделки не найдены</td>
            </tr>
        }
      </tbody>
    </table>
  )
}

export default Table