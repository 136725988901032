import React from 'react'
import injectSheet from 'react-jss'
import { Icon } from './../../../layout'

const styles = {
  root: {
    display: 'flex',
    minHeight: 'calc(100% - 116px)',
    position: 'relative',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    '& svg': {
      fill: '#E3E3E3',
      width: '46px',
      height: '45px',
      display: 'block',
    },
  },
  title: {
    marginTop: '36px',
    display: 'inline-block',
    fontSize: '24px',
    fontWeight: 600,
    color: '#000',
  },
  text: {
    marginTop: '24px',
    textAlign: 'center',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 500,
    color: '#4a4a4a',
  },
}

const EmptyTasksList = ({ classes }) =>
  <div className={classes.root}>
    <div className={classes.block}>
      <Icon iconName='face' />
      <div className={classes.title}>Список задач пуст</div>
      <div className={classes.text}>
        Похоже у вас нет ни одной задачи! 
        <br/> 
        Вы счастливый человек.
      </div>
    </div>
  </div>

export default injectSheet(styles)(EmptyTasksList)