import React from 'react'
import _ from 'lodash'
import { formattedDate } from '../../../../../utils'

export const Attribute = props => {
  const { attr } = props
  const label = _.get(attr, 'label') || ''
  let value = _.get(attr, 'value') || ''
  const col = _.get(props, 'col') || 12

  if(_.get(attr, 'type') === 'date') value = formattedDate(value).includes('NaN') ? value : formattedDate(value);
  
  return ( 
    <div className={`justify-content-center col-${col}`}>
      <label>{label}</label>
      <input type='text' className='center' value={value} readOnly />
    </div>
  )
}

export const AttributeBoolean = props => {
  const { attr } = props
  const label = _.get(attr, 'label') || ''
  const value = _.get(attr, 'value')
  const checked = !!value
  const col = _.get(props, 'col') || 12
  
  return (
    <div className={`justify-content-center col-${col}`}>
      <input
        name={label} 
        type='checkbox' 
        className='center'
        checked={checked}
        readOnly
      />
      <label htmlFor={label}>{label}</label>
    </div>
  )
}

export const Attributes = ({ name, items = {} }) =>
  <div className='row'>
    <div className='block_head col-12'>{name}</div>
    {Object.entries(items || {}).map(([key, attr]) => {
      const normalizedAttr = {...attr, label: attr.name}
      switch (attr.type) {
        case 'boolean':
          return <AttributeBoolean key={key} attr={normalizedAttr} col='12' />
        default:
          return <Attribute key={key} attr={normalizedAttr} col='12' />
      }
    })}
  </div>