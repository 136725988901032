import axios from 'axios'
import { API_URL, API_SPA_URL } from '../../config'
import { createSearchString } from '../../utils'

function createHash(string) {
  let hash = 0
  if (string.length === 0) {
    return hash
  }

  for (let i = 0; i < string.length; i++) {
    const chr = string.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0 // Convert to 32bit integer
  }

  return `${-hash}`
}

let cancelFindByString

export const findByString = async (query) => {
  try {
    cancelFindByString && cancelFindByString.cancel()
    cancelFindByString = axios.CancelToken.source()
    const {data: {error_code: status, ...rest}} = await axios({
      method: 'GET',
      url: `${API_SPA_URL}/company/search?q=${query}`,
      cancelToken: cancelFindByString.token
    })
    if (status === 0) {
      return {
        isSuccess: true,
        list: rest.company,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const selectByString = async (query) => {
  try {
    const {data: {error_code: status, ...rest}} = await axios({
      method: 'GET',
      url: `${API_SPA_URL}/company/select?q=${query}`,
    })
    if (status === 0) {
      const preparedOther = rest.other.map((item) => ({
        ...item,
        id: {
          companyTypeRefId: item.companyTypeRefId,
          INN: item.INN,
          displayName: item.displayName,
          fullName: item.fullName,
        },
        key: createHash(new Date().getTime().toString())
      }))
      return {
        isSuccess: true,
        list: [
          ...rest.elastic,
          ...preparedOther,
        ],
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringContractSuggestions = async (query) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/grid/FACTORING_CONTRACT?numberLike=${query}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        list: data.data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getSupplyContractSuggestions = async (query) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/grid/SUPPLY_CONTRACT?numberLike=${query}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        list: data.data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getSupplySuggestions = async (query, requestPayload) => {
  const searchString = requestPayload
    ? `&${createSearchString(requestPayload)}`
    : ''
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/grid/SUPPLY?supplyNumberLike=${query}${searchString}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        list: data.data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringAccountSuggestions = async (query) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/grid/ACCOUNT?numberLike=${query}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        list: data.data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringFinancingSuggestions = async (query) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/grid/FINANCING?numberLike=${query}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        list: data.data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringHoldingSuggestions = async (query) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${API_URL}/view/holding?nameLike=${query}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        list: data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getSuggestionsByUrl = async (query, url, autocompleteParam, autocompleteResponsePath) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${url}?${autocompleteParam}=${query}`,
    })
    return {
      isSuccess: true,
      list: autocompleteResponsePath
        ? data[autocompleteResponsePath] || []
        : data?.data || [],
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}