import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  status: {
    background: '#fff',
    width: 100,
    padding: [2, 10],
    fontSize: 11,
    lineHeight: 16/11,
    fontWeight: 700,
    color: '#B1050D',
    border: [1, 'solid', '#B1050D'],
    borderRadius: 6,
    textAlign: 'center',
    '&.enabled': {
      background: '#E7FFFC',
      color: '#22C7B5',
      borderColor: '#22C7B5',
    },
  },
})

export const Status = ({ enabled }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.status, { enabled })}>
      {enabled ? 'Подключен' : 'Отключен'}
    </div>
  )
}