import React from 'react'
import classnames from 'classnames'
import { useStyles } from '../../Layout/DetailsTable/styles'

export const StandardsDetailsItem = ({
  data: {
    // user, // TODO ???
    orderCount, 
    taskCount, 
    time,
    timeLimit,
    inTimePrc,
    median,
  },
  isHeader,
}) => {
  const classes = useStyles()
  return (
    <tr className={classnames(classes.row, { isHeader })}>
      {/* <td className={classes.tdLeft}>
        <div className={classes.tableText}>{user}</div>
      </td> */}
      <td className={classes.tdRight}>
        <div className={classes.tableText}>{orderCount}</div>
      </td>
      <td className={classes.tdRight}>
        <div className={classes.tableText}>{taskCount}</div>
      </td>
      <td className={classes.tdRight}>
        <div className={classes.tableText}>{time}</div>
      </td>
      <td className={classes.tdRight}>
        <div className={classes.tableText}>{timeLimit}</div>
      </td>
      <td className={classes.tdRight}>
        <div className={classes.tableText}>{inTimePrc}</div>
      </td>
      <td className={classes.tdRight}>
        <div className={classes.tableText}>{median}</div>
      </td>
    </tr>
  )
}