import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { getCompanyClientLogins } from '../../../../redux/Company/actions'

import Overlay from '../../../Overlay'
import ClientLogins from './ClientLogins'

const ClientInfo = ({
  companyId,
  fetching,
  getCompanyClientLogins,
}) => {
  useEffect(() => {
    getCompanyClientLogins(companyId)
  }, [getCompanyClientLogins, companyId])

  if (fetching) {
    return <Overlay />
  }
  
  return <ClientLogins companyId={companyId} />
}

ClientInfo.propTypes = {
  fetching: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
  getCompanyClientLogins: PropTypes.func.isRequired,
}

const mapStateToProps = ({ Company }) => {
  return {
    fetching: (
      Company && 
      Company.clientLogins && 
      Company.clientLogins.fetching 
    ) || false,
  }
}

const dispatchMapToProps = (dispatch, ownProps) => {
  return {
    getCompanyClientLogins: (companyId) => {
      dispatch(getCompanyClientLogins(companyId))
    },
  }
}

export default connect(mapStateToProps, dispatchMapToProps)(ClientInfo)