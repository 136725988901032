import React from 'react'
import { BUILD_ID } from '../../config'

const LOGOS = {
  'pear': <img src={require(`./../../images/logo-abs.svg`)} alt='' />,
  'pear-leasing': <img src={require(`./../../images/logo-abs.svg`)} alt='' />,
  'papaya': <img src={require(`./../../images/logo-akbb.svg`)} alt='' />,
  'apricot': <img src={require(`./../../images/logo-gaz.svg`)} alt='' />,
  'lime': <img src={require(`./../../images/logo-mts.svg`)} alt='' />,
  'pomelo': <img src={require(`./../../images/logo-souz.svg`)} alt='' />,
  'longan': <img src={require(`./../../images/logo-uralfd.svg`)} alt='' />,
  'mangosteen': <img src={require(`./../../images/logo-keb.svg`)} alt='' />,
  'guava': <img src={require(`./../../images/logo-solidarnost.svg`)} alt='' />,
  'coconut': <img src={require(`./../../images/logo-alfabank.svg`)} alt='' />,
  'coconut-factoring': <img src={require(`./../../images/logo-alfabank.svg`)} alt='' />,
  'passionfruit': <img src={require(`./../../images/logo-rosbank.svg`)} alt='' />,
}

export const Logo = () => LOGOS[BUILD_ID] || null
