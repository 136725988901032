import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    textAlign: 'center !important',
    paddingRight: '0 !important',
  },
}

const Td3 = ({
  classes,
  children,
}) => <td className={classes.root}>{children}</td>
                
export default injectSheet(styles)(Td3)