import React from 'react'
import { useHistory } from 'react-router-dom'
import { ModalSchemaForm } from './../../components/Modals'

const TariffModal = () => {
  const history = useHistory()
  const onBack = () => history.push('/tariff-schemes')
  return <ModalSchemaForm onClose={onBack} />
}

export default TariffModal