import { getReportCommonStatus as _getReportCommonStatus } from '../../../services/api/reports/commonStatus'
import { setErrorContent } from '../../Error/actions'
import { logoutProcess } from '../../User/actions'
import * as actionTypes from './actionTypes'

export const getReportCommonStatus = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.GET_REPORT_COMMON_STATUS_PENDING,
      data: {},
    })
    const { isSuccess, ...res } = await _getReportCommonStatus(payload)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({
      type: actionTypes.GET_REPORT_COMMON_STATUS_SUCCESS,
      data: res,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_REPORT_COMMON_STATUS_ERROR })
  }
}