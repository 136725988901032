import React from 'react'
import injectSheet from 'react-jss'
import { Icon } from './../../../layout'
import { THEME_COLOR } from '../../../config'

const styles = {
	root: {
		background: THEME_COLOR,
		marginTop: 20,
		padding: [9, 24, 7, 57],
		borderRadius: 8,
		color: '#fff',
		fontSize: 16,
		lineHeight: 1.5,
		fontWeight: 600,
    display: 'inline-block',
    position: 'relative',
    '&:hover': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& svg': {
      fill: '#fff',
      width: 24,
      height: 24,
      display: 'block',
      position: 'absolute',
      left: 23,
      top: 7,
    },
  },
}

const DownloadAllButton = ({ file, classes }) =>  
  <a 
    className={classes.root} 
    href={file.url} 
    target='_blank' 
    download={file.name} 
    rel='noopener noreferrer'
  >
    <Icon iconName='download-all' />
    Скачать документы
  </a>

export default injectSheet(styles)(DownloadAllButton)