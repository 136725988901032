import { connect } from 'react-redux'
import { FilesList } from './FilesList'
import * as actions from '../../../../../redux/RegisterUploads/actions'

function mapStateToProps({ RegisterUploads }) {
  return {
    fileProgress: RegisterUploads.fileProgress
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uploadFile: files => dispatch(actions.uploadFile(files)),
    onAllFilesUploaded: () => dispatch(actions.resetFileList())
  }
}

export const FilesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilesList)
