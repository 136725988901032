import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: -10,
    marginLeft: -10,
    position: 'relative',
    '& + &': {
      marginTop: 15,
    },
  },
})

export const Row = ({
  className,
  children
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.row, className)}>
      {children}
    </div>
  )
}