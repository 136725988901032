import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  form: {
    marginTop: 30,
  },
})

export const Form = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.form}>{children}</div>
  )
}