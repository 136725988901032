import injectSheet from 'react-jss'

export const taskReportStyles = injectSheet({
  list: {
    width: '100%',
  },
  item: {
    padding: '6px 0',
  },
  title: {
    marginBottom: '2px',
    cursor: 'pointer',
    color: '#333',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  progress: {
    height: '24px',
    borderRadius: '6px',
    lineHeight: '24px',
    color: '#ffffff',
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
    padding: '0 16px',
  },
})
