import { modalWrapperService } from '../../services/modal-wrapper.service'
import { RegistryPaymentsList, FileUploader } from './components'
import React from 'react'

export class RegistersPageModalService {
  static openModalWithPayments(regId) {
    modalWrapperService.openModal(<RegistryPaymentsList regId={regId} />, true)
  }

  static openModalWithUploadZone() {
    modalWrapperService.openModal(<FileUploader />, true)
  }
}
