import React from 'react'
import { createUseStyles } from 'react-jss'
// import Spinner from '../Spinner'
// import { Spinner } from 'reactstrap'
import Overlay from '../Overlay'
import { Modal2 } from '../../layout'
import UI from '../../layout/UI'

const useStyles = createUseStyles({
  confirmModal: {
    padding: [30, 0, 30, 30],
    position: 'relative',
  },
  wrapper: {
    paddingRight: 30,
  },
  title: {
    fontSize: 16,
    lineHeight: 20/16,
  },
  actions: {
    marginTop: 30,
    textAlign: 'right',
  },
  cancel: {
    ...UI.cancelButton,
    marginRight: 20,
  },
  submit: UI.addButton,
})

export const ConfirmModal = ({ 
  title,
  submitTitle,
  cancelTitle = 'Отменить',
  isSending, 
  onCancel, 
  onSubmit, 
}) => {
  const classes = useStyles()
  return isSending
    ? <Overlay/>
    : <Modal2 className={classes.confirmModal} onClose={onCancel}>
        <div className={classes.wrapper}>
          <div className={classes.title}>{title}</div>
          <div className={classes.actions}>
            <div className={classes.cancel} onClick={onCancel}>{cancelTitle}</div>
            <div className={classes.submit} disabled={isSending} onClick={onSubmit}>
              <span>{submitTitle}</span>
              {/* {isSending ?
                <Spinner color='success' />
                : <span>{submitTitle}</span>
              } */}
            </div>
          </div>
        </div>
      </Modal2>
}