import ReactGitInfo from 'react-git-info/macro'
import { version } from '../package.json'

const REACT_GIT_INFO = ReactGitInfo()

export const API_URL = '/api'
export const API_SPA_URL = '/spa/api'

export const ENV = process.env.REACT_APP_ENV
export const BUILD_ID = process.env.REACT_APP_BUILD_ID

console.info(`REACT_APP_ENV - ${ENV}`)
console.info(`REACT_APP_BUILD_ID - ${BUILD_ID}`)
console.info(`REACT_APP_VERSION - ${version}`)
console.info(`REACT_GIT_INFO - `, REACT_GIT_INFO)

export const THEME_COLOR = process.env.REACT_APP_THEME_COLOR || '#242232'

export const IS_PROD = ENV === 'prod'
// export const IS_DEV = ENV === 'dev'

export const IS_DEV = process.env.NODE_ENV === 'development'

export const IS_DEFAULT = BUILD_ID === undefined
export const IS_PEAR = BUILD_ID === 'pear'
export const IS_PEAR_LEASING = BUILD_ID === 'pear-leasing'
export const IS_PAPAYA = BUILD_ID === 'papaya'
export const IS_RAMBUTAN = BUILD_ID === 'rambutan'
export const IS_APRICOT = BUILD_ID === 'apricot'
export const IS_COCONUT_FACTORING = BUILD_ID === 'coconut-factoring'
export const IS_COCONUT = BUILD_ID === 'coconut' || IS_COCONUT_FACTORING
export const IS_LIME = BUILD_ID === 'lime'
export const IS_POMELO = BUILD_ID === 'pomelo'
export const IS_LONGAN = BUILD_ID === 'longan'
export const IS_MANGOSTEEN = BUILD_ID === 'mangosteen'
export const IS_GUAVA = BUILD_ID === 'guava'
export const IS_PASSIONFRUIT = BUILD_ID === 'passionfruit'

export const DEFAULT_PAGE_SIZE = 42

export const FACTORING_VIEWS = [
  { entityType: 'counterparty', title: 'Контрагенты', entityName: 'контрагента' },
  { entityType: 'factoring_contract', title: 'Договоры', entityName: 'договора' },
  { entityType: 'supply_contract', title: 'Контракты', entityName: 'контракта' },
  { entityType: 'financing', title: 'Финансирования', entityName: 'финансирования' },
  { entityType: 'rate', title: 'Ставки', entityName: 'ставки' },
  { entityType: 'block', title: 'Блокировки', entityName: 'блокировки' },
  { entityType: 'limit', title: 'Лимиты', entityName: 'лимита' },
  { entityType: 'supply', title: 'Поставки', entityName: 'поставки' },
  { entityType: 'operation', title: 'Проводки', entityName: 'проводки' },
  { entityType: 'product', title: 'Тарифы', entityName: 'тарифа' },
  { entityType: 'order', title: 'Ожидание оплаты', entityName: 'ожидания оплаты' },
  { entityType: 'account', title: 'Счета', entityName: 'счёта' },
  { entityType: 'payment', title: 'Платежи', entityName: 'платежа' },
  { entityType: 'reserve_msfo', title: 'Резервы МСФО', entityName: 'резерва МСФО' },
  { entityType: 'reserve_rsbu', title: 'Резервы РСБУ', entityName: 'резерва РСБУ' },
  { entityType: 'notification', title: 'Уведомления', entityName: 'уведомления' },
  { entityType: 'master_operation', title: 'События', entityName: 'События' },
  { entityType: 'supply_registry', title: 'Реестры поставок', entityName: 'Реестры поставок', hide: true },
]

export const ERRORS = {
  REDIRECT_TO_WO_SUPPLY_TRANSIT: 'Зачисление на поставку без финансирования невозможно, только беспоставочный транзит!',
}

export const SETTINGS = {
  authType: process.env.REACT_APP_AUTHTYPE || 'keycloak', // 'keycloak' || 'standart'
  formType: 'camunda', // 'camunda' || 'custom'
  COPYRIGHT: 'FarZoom #ACTUAL_DATE# © v. 1.2.9.1 ßeta 2',
  PHONE: '+7 (495) 799–09–83',
  EMAIL: 'support@farzoom.com',
  statusItems: [
    {
      key: 'total',
      text: 'Всего',
    },
    {
      key: 'assigned',
      text: 'К выполнению',
      className: 'purple',
    },
    {
      key: 'inprogress',
      text: 'На стороне банка',
      className: 'yellow',
    },
    {
      key: 'lost',
      text: 'Отказано',
      className: 'red',
    },
    {
      key: 'sold',
      text: 'Одобрено',
      className: 'green',
    },
  ],
}

export const AXIS_IDS = {
  y1: 'y-axis-1',
  y2: 'y-axis-2',
}

export const FACTORING_EMPTY_TYPE_OPTION = {
  name: '',
  value: '',
}

export const FACTORING_ENTITY_TYPE_OPTIONS = [
  FACTORING_EMPTY_TYPE_OPTION,
  { name: 'Общебанковский', value: 'COMMON' },
  { name: 'Компания', value: 'CLIENT' },
  { name: 'Договор факторинга', value: 'AGREEMENT_PB' },
  // { name: 'Поставщик/Дебитор', value: 'SUPPLIER_DEBTOR' },
  { name: 'Договор факторинга/Дебитор', value: 'AGREEMENT_PB_DEBTOR' },
]