import React from 'react'

export function RegistryPaymentsLoader() {
  return (
    <span className="payment__loading">
      <svg
        width="28"
        height="28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.833 15.969h-2.645a1.969 1.969 0 010-3.938h2.645a1.969 1.969 0 110
          3.938zm-2.085-9.096l-1.792 1.792a1.863 1.863 0
          11-2.822-2.418c.037-.037.08-.066.119-.096l-.012-.012
          1.978-1.98.012.012c.033-.039.06-.082.097-.118a1.866 1.866 0 012.64 2.639
          1.847 1.847 0 01-.22.181zm-1.984 12.272c.037.037.066.08.097.119l.014-.01
          1.98 1.98-.013.011c.04.033.082.06.118.096a1.867 1.867 0 01-2.64 2.64
          1.846 1.846 0 01-.18-.221l-1.792-1.792a1.863 1.863 0
          112.415-2.821l.001-.002zm-7.753 8.647a1.969 1.969 0
          01-1.97-1.969v-2.645a1.969 1.969 0 013.938 0v2.645a1.969 1.969 0
          01-1.968 1.969zm0-21a1.969 1.969 0 01-1.97-1.969V2.178a1.969 1.969 0
          013.938 0v2.645a1.969 1.969 0 01-1.968 1.969zM8.654 21.967l-1.791
          1.792A1.863 1.863 0 114.04 21.34c.036-.037.079-.066.118-.097l-.012-.011
          1.978-1.979.012.012c.033-.04.06-.082.097-.119a1.867 1.867 0 112.42
          2.82zM6.226 8.887a1.85 1.85 0 01-.181-.222L4.253 6.873a1.863 1.863 0
          112.418-2.822c.037.037.066.08.097.119l.013-.01 1.98
          1.979-.012.012c.038.032.081.06.118.096a1.867 1.867 0 11-2.642
          2.64h.001zM4.813 12.03h.02a1.969 1.969 0 110 3.938H2.188a1.969 1.969 0
          010-3.938h2.624z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="14.01"
            y1=".209"
            x2="14.01"
            y2="27.792"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#504E5B" />
            <stop offset="1" stopColor="#504E5B" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  )
}
