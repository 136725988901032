import React, { useEffect } from 'react'
import Header from '../../containers/Header'
import { Burger } from '../../components/Burger'
import PropTypes from 'prop-types'
import { registerType } from './types'
import { RegistersPageFilter, RegistersList } from './components'
import { RegistersPageModalService } from './registers-page-modal.service'
import './RegistersPage.style.scss'

export const RegistersPage = ({
  getRegisters,
  registers,
  isFetching,
}) => {
  useEffect(() => {
    getRegisters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Burger />
      <div className='registers__main'>
        <div className='registers__container'>
          <Header burger>
            <div className='registers__top'>
              <RegistersPageFilter onFiltersChange={getRegisters} />
            </div>
          </Header>
          <div className='registers__main__title'>Реестры платежей</div>
          <div className='registers'>
            <RegistersList registers={registers} isFetching={isFetching} />
          </div>
        </div>
      </div>
      <div
        className='btn-options'
        onClick={RegistersPageModalService.openModalWithUploadZone}
      >
        <span
          className='btn-options__link'
          style={{ backgroundColor: '#8C070D' }}
        />
      </div>
    </>
  )
}

RegistersPage.propTypes = {
  getRegisters: PropTypes.func.isRequired,
  registers: PropTypes.arrayOf(registerType),
  isFetching: PropTypes.bool
}
