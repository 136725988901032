import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { addCompanyAgentBillingSigner } from '../../../../redux/Company/actions'
// import TextField from '../../TextField'
import TextFieldWithSuggest from '../../../TextFieldWithSuggest'
import { searchPersons } from '../../../../services/api/company'
import AddPerson from './AddPerson'
import { Row, InputText, UI, Modal } from './../../../../layout'
import classnames from 'classnames'

const styles = {
  title: UI.h1,
  actions: {
    marginTop: '30px',
    textAlign: 'right',
  },
  add: UI.addButton,
  cancel: {
    ...UI.cancelButton,
    marginRight: '20px',
  },
}

const AddBillingSigner = ({
  classes,
  companyId,
  addCompanyAgentBillingSigner,
  onCancel,
}) => {
  const [personId, setPersonId] = useState('')
  const [position, setPosition] = useState('')
  const [authorizingDoc, setAuthorizingDoc] = useState('')
  const [showAddPersonModal, setShowAddPersonModal] = useState(false)

  const onChangePerson = (item) => setPersonId(item ? item.id : '')
  const onChangePosition = (e) => setPosition(e.target.value)
  const onChangeAuthorizingDoc = (e) => setAuthorizingDoc(e.target.value)

  const onOpen = () => setShowAddPersonModal(true)
  const onClose = () => setShowAddPersonModal(false)

  const onSubmit = () => addCompanyAgentBillingSigner({ companyId, personId, position, authorizingDoc })

  const onSearch = async (value) => {
    try {
      const { persons } = await searchPersons({ q: value })
      return persons
    } catch (err) {
      console.log(err)
    }
  }

  const getValue = (data) => data.displayName

  // const valid = personId && position && authorizingDoc

  return (
    <Fragment>
      <div className={classes.title}>Новый подписант</div>
      <Row>
        <div className='col-12'>
          <TextFieldWithSuggest 
            name='personId'
            label='Имя подписанта'
            value={personId}
            search={onSearch}
            input={{
              value: personId,
              onChange: onChangePerson,
            }}
            getValue={getValue}
            onSelect={() => {}}
            onClear={() => {}}
            onModal={onOpen}
          />
        </div>
      </Row>
      <Row>
        <div className='col-6'>
          <InputText
            type='text' 
            name='position'
            label='Должность'
            value={position}
            onChange={onChangePosition}
          />
        </div>
        <div className='col-6'>
          <InputText
            type='text' 
            name='authorizingDoc'
            label='Основания полномочий'
            value={authorizingDoc}
            onChange={onChangeAuthorizingDoc}
          />
        </div>
      </Row>
      <div className={classes.actions}>
        <div className={classes.cancel} onClick={onCancel}>Отменить</div>
        <button 
          type='button'
          className={classnames(classes.add, 'btn-form--ok')} 
          onClick={onSubmit} 
          // disabled={!valid}
        >
          Добавить
        </button>
      </div>
      {showAddPersonModal ? 
        <Modal width='650px' height='auto' onClose={onClose}>
          <AddPerson
            companyId={companyId}
            onCancel={onClose}
          />
        </Modal> 
        : null
      }
    </Fragment>
  )
}

const mapStateToProps = (store) => {
  return {}
}

const dispatchMapToProps = (dispatch, ownProps) => {
  return {
    addCompanyAgentBillingSigner: (payload) => {
      dispatch(addCompanyAgentBillingSigner(payload))
    },
  }
}

AddBillingSigner.propTypes = {
  companyId: PropTypes.string.isRequired,
  addCompanyAgentBillingSigner: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, dispatchMapToProps)(injectSheet(styles)(AddBillingSigner))