import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { removeCompanyAgentLogin } from '../../../../redux/Company/actions'
import { ConfirmModal } from './../../../ConfirmModal'
import { Modal, UI } from '../../../../layout'
import AddAgent from './AddAgent'
import classnames from 'classnames'

const styles = {
  root: {
    position: 'relative',
  },
  table: UI.table,
  item: {
    width: '20%',
    display: 'inline-block',
    verticalAlign: 'top',
    '&:first-child': {
      width: '80%',
    },
    '& + &': {
      paddingLeft: 20,
    },
  },
  title: UI.title,
  add: UI.addButton,
  remove: UI.removeButton,
}

const AgentLogins = ({
  classes,
  companyId,
}) => {
  const dispatch = useDispatch()

  const agentLogins = useSelector(state =>
    state.Company &&
    state.Company.agentInfo &&
    state.Company.agentInfo.info &&
    state.Company.agentInfo.info.agentLogins
  ) || []

  const [username, setUsername] = useState('')
  const [showAddLoginModal, setShowAddModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const onOpenConfirmModal = (username) => {
    setShowConfirmModal(true)
    setUsername(username)
  }
  const onCloseConfirmModal = () => setShowConfirmModal(false)

  const onOpenAddLoginModal = () => setShowAddModal(true)
  const onCloseAddLoginModal = () => setShowAddModal(false)

  const onRemove = () => dispatch(removeCompanyAgentLogin({ companyId, username }))

  const hasLogins = agentLogins && agentLogins.length

  return (
    <div className={classes.root}>
      <div className='row block-info'>
        <div className='col-12'>
          <div className={classes.title}>Список логинов агента</div>
          <table className={classes.table}>
            <thead>
              <tr>
                <td>Логин</td>
                <td>Действие</td>
              </tr>
            </thead>
            <tbody>
              {hasLogins
                ? <>
                    {agentLogins.map(item => 
                      <tr key={item}>
                        <td>
                          <div>{item}</div>
                        </td>
                        <td className='text-right'>
                          <div
                            className={classes.remove}
                            onClick={() => onOpenConfirmModal(item)}
                          >
                            Удалить
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                : <tr className='no-hover'>
                    <td
                      colSpan='2'
                      className='text-mute'
                    >
                      К данному агенту не привязано ни одного логина
                    </td>
                  </tr>
              }
              <tr className='no-hover'>
                <td>&nbsp;</td>
                <td>
                  <div
                    className={classnames(classes.add, 'btn-form--ok')}
                    onClick={onOpenAddLoginModal}
                  >
                    Добавить логин
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {showAddLoginModal &&
            <Modal width='482px' height='auto' onClose={onCloseAddLoginModal}>
              <AddAgent 
                companyId={companyId} 
                onCancel={onCloseAddLoginModal} 
              />
            </Modal>
          }
          {showConfirmModal &&
            <ConfirmModal
              title={`Вы действительно хотите удалить логин ${username}?`}
              submitTitle='Удалить'
              onCancel={onCloseConfirmModal}
              onSubmit={onRemove}
            />
          }
        </div>
      </div>
    </div>
  )
}

AgentLogins.propTypes = {
  companyId: PropTypes.string.isRequired,
}

export default injectSheet(styles)(AgentLogins)