import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { getCompanyBeneficiariesInfo } from '../../../../redux/Company/actions';
// import * as utils from '../utils';
import { Attributes } from '../Helpers/Attributes';
import Overlay from '../../../Overlay';
import { User } from '../Helpers/User';
import { Passport } from '../Helpers/Passport';
import { Attribute } from '../Helpers/Attributes';

export class Beneficiaries extends React.Component{
    async loadData(){
        const { dispatch, companyId } = this.props;
        dispatch(getCompanyBeneficiariesInfo(companyId));
    }

    componentDidMount(){
        const { fetching } = this.props;
        !fetching && this.loadData();
    }

    componentDidUpdate(prev){
        const { companyId: oldCompanyId } = prev;
        const { companyId } = this.props;
        if(oldCompanyId !== companyId){
            this.loadData();
        }
    }

    render() {
        const { beneficiaries: {persons = [], companies= []} = {}, fetching } = this.props;
        let counter = 0;

        return !fetching ? <div className="block-info">

            {
                
                (persons || []).map((data, index) => {
                    counter++;
                    return <React.Fragment key={index}>
                        <div className="block_head">
                            Бенефициарный владелец {index + 1} - <span className="text-info">{ _.get(data, 'share.percent') || 0 } %</span>
                        </div>
                        <User user={data}/>

                        <div className="row">
                            <Attribute col="12" attr={{label: 'Доля, %', value: _.get(data, 'share.percent')}}/>
                        </div>
                        <br/>
                        <div className="block_head">
                            Паспортные данные 
                        </div>
                        <Passport passport={_.get(data, 'identityDocuments[0]')}/>
                        <br/>
                        <Attributes {...data.personAttributes}/>
                        <br/>
                        <hr/>
                    </React.Fragment>
                })
            }
            {
                (companies || []).map((data, index) => {
                    return <React.Fragment  key={index + counter}>
                        <div className="block_head">
                            Бенефициарный владелец  {index + counter + 1} - <span className="text-info">{_.get(data, 'share.percent') || 0} %</span>
                        </div>

                        <div className="row">
                            <Attribute col="12" attr={{label: ' Компания', value: _.get(data, 'displayName')}}/>
                            <Attribute col="6" attr={{label: 'ИНН', value: _.get(data, 'INN')}}/>
                            <Attribute col="6" attr={{label: 'ОГРН', value: _.get(data, 'OGRN')}}/>
                            <Attribute col="12" attr={{label: 'Доля, %', value: _.get(data, 'share.percent')}}/>
                        </div>
                        <br/>
                        <hr/>
                    </React.Fragment>
                })
            }

            
        </div> : <Overlay/>;
    }
}

const mapStateToProps = ( { Company }) => {
    return {
        ...Company.beneficiariesInfo
    }
}

export default connect(mapStateToProps)(Beneficiaries);