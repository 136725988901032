import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import injectSheet from 'react-jss'
import { getCompanyAgentInfo, registerCompanyAsAgent } from '../../../../redux/Company/actions'
import Overlay from '../../../Overlay'
import AgentLogins from '../AgentLogins'
import BillingSigners from './BillingSigners'
import BillingContracts from './BillingContracts/index'
import BankManager from './BankManager'
import CbsManager from './CbsManager'
import Report from './Report'
import Stats from './Stats'
import { UI } from '../../../../layout'
import './index.scss'

const styles = {
  root: {
    paddingBottom: 40,
  },
  id: {
    paddingBottom: 40,
    fontSize: 14,
    lineHeight: 1.43,
  },
  message: {
    fontSize: 14,
    lineHeight: 1.43,
  },
  add: {
    ...UI.addButton,
    marginTop: 20,
  },
}

const AgentInfo = ({
  classes,
  companyId,
  billingId,
}) => {
  const dispatch = useDispatch()

  const { agentInfo } = useSelector(state => state.Company) || {}
  const { access } = useSelector(state => state.Settings) || {}
  const { REACT_APP_AGENT_STATS } = useSelector(state => state?.Environment?.environment)

  const IS_AGENT_STATS = REACT_APP_AGENT_STATS === 'true'

  const { info, fetching } = agentInfo || {}
  const { reportLink } = info || {}
  const { agentLogins } = access || {}

  useEffect(() => {
    if (agentLogins) {
      dispatch(getCompanyAgentInfo(companyId))
    }
  }, [companyId, agentLogins, dispatch])
    
  const onRegisterCompanyAsAgent = () => dispatch(registerCompanyAsAgent(companyId))
  
  if (fetching) {
    return <Overlay />
  }

  return (
    <div className={classes.root}>
      {!!billingId
        ? <>
            <div className={classes.id}>
              Данная компания является Агентом. 
              Идентификатор в биллинговой системе: {billingId}
            </div>
            {agentLogins &&
              <>
                <AgentLogins companyId={companyId} />
                <BillingSigners companyId={companyId} />
                <BankManager companyId={companyId} />
                <CbsManager companyId={companyId} />
                <BillingContracts
                  companyId={companyId}
                  billingId={billingId}
                />
                <Report companyId={companyId} link={reportLink} />
                {IS_AGENT_STATS &&
                  <Stats companyId={companyId} />
                }
              </>
            }
          </>
        : <>
            <div className={classes.message}>Эта компания не является агентом</div>
            {agentLogins &&
              <div className={classes.add} onClick={onRegisterCompanyAsAgent}>
                Зарегистрировать компанию в качестве агента
              </div>
            }
          </>
      }
    </div>
  )
}

export default injectSheet(styles)(AgentInfo)