import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    marginTop: 20,
    fontSize: 10,
    lineHeight: 1.2,
    textAlign: 'right',
  },
})

const Stats = ({
  filtered,
  total,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>Показано {filtered} из {total}</div>
  )
}

export default Stats