import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import _ from 'lodash'
import { InputText, UI } from './../../layout'

import ClearButton from '../ClearButton'

import LoadingIcon from '../../static/img/loading.svg'

const styles = {
  dropdown: {
    maxHeight: 200,
    overflowY: 'auto',
    float: 'none',
    right: 0,
    display: 'block',
  },
  input: {
    paddingLeft: 45,
  },
  item: {
    cursor: 'pointer',
    padding: [5, 10],
    '& + &': {
      borderTop: [1, 'solid', 'lightgray'],
    },
    '&:hover': {
      background: 'lightgray',
    },
    '&.default': {
      cursor: 'default',
      '&:hover': {
        background: 'transparent'
      },
    },
  },
  add: {
    ...UI.addButton,
    width: '100%',
    marginTop: 20,
  },
}

const TextFieldWithSuggest = ({
  classes,
  name,
  classNames,
  placeholder,
  label,
  meta,
  input,
  search,
  getValue,
  onModal,
  error,
}) => {
  const [focused, setFocused] = useState(false)
  const [typing, setTyping] = useState(false)
  const [value, setValue] = useState('')
  const [list, setList] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    window.addEventListener('click', onOutsideClick)

    return () => window.removeEventListener('click', onOutsideClick)
  })

  const _getValue = (item) => {
    if (typeof getValue === 'function' && item) {
      return getValue(item) || value
    }
    return value
  }

  const elementRef = useRef(null)
  const inputRef = useRef(null)

  const onClear = () => {
    if (input.onChange) {
      input.onChange('')
    }
    setValue('')
    setList([])
    inputRef.current.focus()
  }

  const onFocus = () => {
    setFocused(true)
    inputRef.current.select()
  }

  const onOutsideClick = ({ target }) => {
    if (elementRef && elementRef.current && elementRef.current.contains(target)) {
      return
    }
    setFocused(false)
  }

  const onClearTyping = _.debounce(() => setTyping(false), 500)

  const onChange = (e) => {
    setValue(e.target.value)
    setTyping(true)
    onLoadSuggestions(e.target.value)
    onClearTyping()
  }

  const onLoadSuggestions = _.debounce(async (value) => {
    let list
    setIsFetching(true)
    setList([])
    try {
      list = await search(value)
      setIsFetching(false)
      setList(list)
    } catch (err) {
      console.log(err)
      setIsFetching(true)
      setList([])
    } 
  }, 100)

  const onSelectItem = (item) => {
    if (input.onChange) { 
      input.onChange(item)
    }
    setList([])
    setFocused(false)
    setValue(_getValue(item))
  }

  const renderSearchResults = () => {
    const isInitialState = !value && !isFetching 
    const isNotFound = value && list && !list.length && !isFetching

    return (
      <div className={classnames('dropdown-menu', classes.dropdown)}>
        {isInitialState
          ? <div className={classnames(classes.item, 'default')}>Начните вводить значение для поиска</div> 
          : null
        }
        {isFetching 
          ? <div className={classnames(classes.item, 'default')}>Поиск...</div> 
          : null
        }
        {isNotFound
          ? <div className={classnames(classes.item, 'default')}>
              Ничего не найдено 
              {onModal && 
                <div className={classnames(classes.add, 'btn-form--ok')} onClick={onModal}>Создать подписанта</div>
              }
            </div> 
          : null
        }
        {list && list.length && !isFetching
          ? list.map((item, index) =>
              <div 
                key={index} 
                className={classes.item} 
                onClick={() => onSelectItem(item)}
              >
                {item.displayName || item.value}
              </div>
            )
          : null 
        }
      </div>
    )
  }

  const onClickSearchIcon = () => inputRef.current.focus()

  return (
    <div className={classnames('dropdown', classNames.container)}>
      <div ref={elementRef}>
        <InputText
          getRef={inputRef}
          type='text'
          label={label}
          className={classnames(classes.input, classNames.input)}
          placeholder={placeholder}
          name={name}
          value={value}
          onFocus={onFocus}
          onChange={onChange}
          error={error}
        >
          {isFetching || typing
            ? <img className='input-loader' src={LoadingIcon} alt='' />
            : <i className='icon icon-search' onClick={onClickSearchIcon} />
          }
          <ClearButton
            onClear={onClear}
            isHidden={!value.length}
          />
        </InputText>
        {focused 
          ? renderSearchResults() 
          : null
        }
      </div>
      {meta && meta.touched && meta.error && 
        <span className={classNames.error}>{meta.error}</span>
      }
    </div>
  )
}

TextFieldWithSuggest.propTypes = {
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  classNames: PropTypes.shape({
    container: PropTypes.string,
    input: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  findAll: PropTypes.bool,
}

TextFieldWithSuggest.defaultProps = {
  placeholder: '',
  defaultValue: '',
  value: '',
  classNames: {
    container: '',
    input: '',
    error: ''
  },
  meta: {
    touched: false,
    error: ''
  },
  findAll: false,
}

export default injectSheet(styles)(TextFieldWithSuggest)