import * as React from 'react'
import Modal from 'react-modal'
import { modalWrapperService } from '../../services/modal-wrapper.service'
import classnames from 'classnames'
import './style.scss'
import { ContentWrapper } from './components'

export class ModalWrapper extends React.Component {
  modalWrapperService = modalWrapperService
  state = {
    component: null,
    isOpened: false,
    animateWrapper: false
  }

  componentDidMount() {
    Modal.setAppElement('#root')
    this.modalWrapperService.setComponentUpdater(this.setState.bind(this))
  }

  handleCloseModal = () => this.modalWrapperService.closeModal()

  render() {
    const wrapperClassName = classnames({
      reactModalContentAnimatedFadeIn:
        this.state.animateWrapper && this.state.isOpened,
      reactModalContentAnimatedFadeOut:
        this.state.animateWrapper && !this.state.isOpened,
      reactModalContentIsVisible:
        !this.state.animateWrapper && this.state.isOpened
    })
    const overlayClassName = classnames({
      reactModalOverlayCustomFadeIn: this.state.isOpened,
      reactModalOverlayCustomFadeOut: !this.state.isOpened
    })
    return (
      <Modal
        closeTimeoutMS={300}
        className={wrapperClassName}
        overlayClassName={overlayClassName}
        isOpen={this.state.isOpened}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={this.handleCloseModal}
        shouldFocusAfterRender={false}
      >
        <ContentWrapper onClose={this.handleCloseModal}>
          {this.state.component}
        </ContentWrapper>
      </Modal>
    )
  }
}
