import React, { useEffect } from 'react'
// import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import injectSheet from 'react-jss'
import { clearErrorContent } from '../../redux/Error/actions'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const styles = {
  // root: {
  //   background: '#ff0000',
  //   padding: '14px 20px',
  //   textAlign: 'center',
  //   position: 'fixed',
  //   top: 0,
  //   left: 0,
  //   right: 0,
  //   zIndex: 40000,
  // },
  // text: {
  //   marginRight: '10px',
  //   color: '#fff',
  //   fontSize: '12px',
  //   lineHeight: '16px',
  //   fontWeight: 500,
  //   display: 'inline-block',
  //   verticalAlign: 'top',
  // },
  // close: {
  //   backgroundColor: 'transparent',
  //   padding: 0,
  //   border: 'none',
  //   opacity: 1,
  //   transition: 'opacity 300ms ease',
  //   '&:hover': {
  //     textDecoration: 'none',
  //     opacity: 0.8,
  //     cursor: 'pointer',
  //   },
  //   '&:focus': {
  //     outline: 'none',
  //   },
  // },
  // icon: {
  //   fontSize: '16px',
  //   color: '#fff',
  //   position: 'absolute',
  //   top: '50%',
  //   right: '16px',
  //   transform: 'translateY(-50%)',
  // },
  toast: {
    zIndex: 40000,
  },
}

const TIMEOUT = 3000

const SnackBar = ({ classes }) => {
  const dispatch = useDispatch()

  const { text, show } = useSelector(state => state.Error)

  useEffect(() => {
    if (show) {
      const onClose = () => dispatch(clearErrorContent())
      
      const timer = setTimeout(onClose, TIMEOUT + 1000)

      return () => clearTimeout(timer)
    }
  }, [show, dispatch])

  const isObject = typeof text === 'object' && text !== null

  const onError = (text) => text && toast.error(text, { containerId: 'ToastContainerId' })

  useEffect(() => {
    isObject 
      ? Object.keys(text).map(key => onError(`${key} - ${text[key]}`))
      : onError(text)
  })

  return (
    <>
      {/* <div className={classes.root}>
        <div className={classes.text}>
          {isObject
            ? <>
                {Object.keys(text).map(key =>
                  <div key={key}>{key} - {text[key]}</div>
                )}
              </>
            : text
          )}
        </div>
        <div className={classes.close} onClick={onClose}>
          <i className={classnames(classes.icon, 'icon-close-s')} />
        </div>
      </div> */}
      <ToastContainer
        className={classes.toast}
        enableMultiContainer 
        containerId='ToastContainerId'
        autoClose={TIMEOUT} 
        position={toast.POSITION.BOTTOM_RIGHT}
        closeOnClick
        pauseOnHover
        draggable 
      />
    </>
  )
}

export default injectSheet(styles)(SnackBar)