import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Icon } from '../../../../layout'
import { IS_COCONUT_FACTORING } from '../../../../config'

const useStyles = createUseStyles({
  buttonEdit: {
    width: 40,
    height: IS_COCONUT_FACTORING ? 56 : 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& > svg': {
      width: 20,
      height: 20,
      display: 'block',
    },
  },
})

export const ButtonEdit = ({ className, ...rest }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.buttonEdit, className)} {...rest}>
      <Icon iconName='edit2' />
    </div>
  )
}