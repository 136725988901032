const uas1 = {}

uas1.helpers = (function () {
  var entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;',
  }

  function escapeHTML(string) {
    if (string) {
      // eslint-disable-next-line no-useless-escape
      return String(string).replace(/[&<>"'`=\/]/g, function fromEntityMap(s) {
        return entityMap[s]
      })
    } else if (typeof string === 'undefined') {
      return ''
    } else {
      return string
    }
  }

  function abbr(s) {
    var r = ''

    if (s) {
      var a = s.split(' ')

      if (a[0]) r += a[0][0]

      if (a[1]) r += a[1][0]
    }

    return r
  }

  function reverseString(str) {
    return str.split('').reverse().join('')
  }

  function truncateString(str, length, from, ellipsis, split) {
    var str1, str2, len1, len2

    if (str.length <= length) {
      return str.toString()
    }

    ellipsis = ellipsis || '...'
    switch (from) {
      case 'left':
        str2 = split
          ? truncateWord(str, length, true)
          : str.slice(str.length - length)
        return ellipsis + str2
      case 'middle':
        len1 = Math.ceil(length / 2)
        len2 = Math.floor(length / 2)
        str1 = split ? truncateWord(str, len1) : str.slice(0, len1)
        str2 = split
          ? truncateWord(str, len2, true)
          : str.slice(str.length - len2)
        return str1 + ellipsis + str2
      default:
        str1 = split ? truncateWord(str, length) : str.slice(0, length)
        return str1 + ellipsis
    }
  }

  function truncateWord(str, limit, fromLeft) {
    if (fromLeft) {
      return reverseString(truncateWord(reverseString(str), limit))
    }

    var trimChars =
        '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF',
      reg = RegExp('(?=[' + trimChars + '])'),
      words = str.split(reg),
      count = 0

    return words
      .filter(function (word) {
        count += word.length
        return count <= limit
      })
      .join('')
  }

  function pad(number, length) {
    return new Array((length || 2) + 1 - String(number).length).join(0) + number
  }

  function numberToCurrency(value, unit) {
    if (unit === false) {
      unit = ''
    } else if (!unit || unit === true) {
      unit = '₽&nbsp;'
    } else {
      unit = unit + '&nbsp;'
    }

    if (isNaN(value)) {
      return unit + '0,00'
    } else {
      return (
        unit +
        parseFloat(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, '$1 ')
          .replace(/\./g, ',')
      )
    }
  }

  function currencyToNumber(value) {
    var result = 0

    if (value) {
      // eslint-disable-next-line no-useless-escape
      result = parseFloat(value.replace(/[^0-9.,]*/g, '').replace(/\,/g, '.'))

      if (isNaN(result)) {
        result = 0
      }
    }

    return result
  }

  function fieldErrorAdd(field, text) {
    var $field = field,
      $parent = field.parent(),
      $error = $parent.find('span.error')

    if ($field.length) {
      field = $field.get(0)

      $field.addClass('invalid')

      if (field.id) {
        ;('label[for=' + field.id + ']', field.form).addClass('invalid')
      }

      if ($parent.hasClass('field')) {
        if ($field.is('[type=hidden]')) {
          $parent.find('input.field-mask').addClass('invalid')
        }

        if ($error.length) {
          $error.text(text)
        } else {
          $field.after('<span class="error">' + text + '</span>')
        }
      } else {
        $field.prop('title', text)
      }
    }
  }

  function fieldErrorRemove(field) {
    var $field = field,
      $parent = field.parent('.field')

    if ($field.length && $field.hasClass('invalid')) {
      field = $field.get(0)

      $field.removeClass('invalid')
      $field.removeAttr('title')

      if (field.id) {
        ;('label[for=' + field.id + ']', field.form).removeClass('invalid')
      }

      $parent.find('span.error').remove()

      if ($field.is('[type=hidden]')) {
        $parent.find('input.field-mask').removeClass('invalid')
      } else if ($field.is('.field-mask')) {
        $parent.find('input[type=hidden]').removeClass('invalid')
      }
    }
  }

  function colorBrightness(hex, percent) {
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '')

    // convert 3 char codes --> 6, e.g. E0F --> EE00FF
    if (hex.length === 3) {
      hex = hex.replace(/(.)/g, '$1$1')
    }

    var r = parseInt(hex.substr(0, 2), 16),
      g = parseInt(hex.substr(2, 2), 16),
      b = parseInt(hex.substr(4, 2), 16)

    return (
      '#' +
      (0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
        .toString(16)
        .substr(1) +
      (0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
        .toString(16)
        .substr(1) +
      (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
    )
  }

  function timeFormat(value, format) {
    value = parseInt(value, 0)

    if (isNaN(value)) return ''

    value = Math.round(value)

    var s = value % 60,
      m = parseInt((value / 60) % 60, 10),
      h = parseInt(value / 3600, 10)

    format = format || '%H:%M:%S'

    m = parseInt(m, 10) < 10 ? '0' + m : m
    s = parseInt(s, 10) < 10 ? '0' + s : s

    return format.replace('%H', h).replace('%M', m).replace('%S', s)
  }

  function toRub(value) {
    return value ? value / 100 : 0
  }

  return {
    escapeHTML: escapeHTML,
    abbr: abbr,
    truncateString: truncateString,
    pad: pad,
    numberToCurrency: numberToCurrency,
    currencyToNumber: currencyToNumber,
    fieldErrorAdd: fieldErrorAdd,
    fieldErrorRemove: fieldErrorRemove,
    colorBrightness: colorBrightness,
    timeFormat: timeFormat,
    toRub: toRub,
  }
})()

export const uas = uas1
