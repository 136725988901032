import React, { useState } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { InputText, Select1 } from '../../../../layout'
import { getReportFunnel } from '../../../../redux/Reports/Funnel/actions'
import { commonStatusStyles } from '../all3d/styles'
import { ReportTab } from '../../Layout/ReportTab'
import { useProcessSelectOptions } from '../../useProcessSelectOptions'
import { FunnelGraphic } from './FunnelGraphic'
import { YYYYMMDD } from '../../../../utils'

const FUNNEL_TYPES = [
  { id: 'DEFAULT', description: 'Стандарт' },
  { id: 'VINTAGE', description: 'Винтаж' },
]

export const FunnelReport = commonStatusStyles(({
  classes,
  onCancel,
  fullWidth,
  changeFullWidth,
  settings,
}) => {
    const dispatch = useDispatch()

    const { processOptions, processDefault } = useProcessSelectOptions(settings.process)

    const { isFetching, data } = useSelector(state => state?.Reports?.Funnel)

    const now = new Date()
    const currentDate = YYYYMMDD(now)
    const firstDayOfMonth = YYYYMMDD(new Date(now.getFullYear(), now.getMonth(), 1))

    const [orderTypeRefId, setOrderTypeRefId] = useState(processDefault)
    const [funnelType, setFunnelType] = useState(FUNNEL_TYPES[0].id)
    const [dateFrom, setDateFrom] = useState(firstDayOfMonth)
    const [dateTo, setDateTo] = useState(currentDate)
    const [sent, setSent] = useState(false)

    const onChangeOrderTypeRefId = (e) => setOrderTypeRefId(e.target.value)
    const onChangeFunnelType = (e) => setFunnelType(e.target.value)
    const onChangeDateFrom = (e) => setDateFrom(e.target.value)
    const onChangeDateTo = (e) => setDateTo(e.target.value)

    const onGetReport = () => {
      setSent(true)
      dispatch(getReportFunnel({ orderTypeRefId, dateFrom, dateTo, funnelType }))
    }

    return (
      <ReportTab
        onOk={onGetReport}
        onCancel={onCancel}
        okButtonText='Получить график'
        disabled={false}
        loading={isFetching}
        title={settings.description}
      >
        <Col className={classnames(classes.form, { fullWidth })}>
          <Row>
            <div className='col-6'>
              {processOptions.length > 1 &&
                <div className={classes.type}>
                  <Select1
                    name='orderTypeRefId'
                    label='Тип заявки'
                    onChange={onChangeOrderTypeRefId}
                    defaultValue={orderTypeRefId}
                    options={processOptions}
                  />
                </div>
              }
            </div>
            <div className='col-6'>
              <div className={classes.type}>
                <Select1
                  name='funnelType'
                  label='Тип воронки'
                  onChange={onChangeFunnelType}
                  defaultValue={funnelType}
                  options={FUNNEL_TYPES}
                />
              </div>
            </div>
          </Row>
          <Row>
            <div className='col-6'>
              <InputText
                type='date'
                name='dateFrom'
                label='Дата с'
                value={dateFrom}
                maxDate={new Date(dateTo)}
                onChange={onChangeDateFrom}
              />
            </div>
            <div className='col-6'>
              <InputText
                type='date'
                name='dateTo'
                label='Дата по'
                value={dateTo}
                minDate={new Date(dateFrom)}
                onChange={onChangeDateTo}
              />
            </div>
          </Row>
        </Col>
        <div className={classes.content}>
          {sent &&
            (isFetching
              ? null
              : (data && Object.keys(data).length > 0
                  ? <FunnelGraphic
                      data={data}
                      fullWidth={fullWidth}
                      changeFullWidth={changeFullWidth}
                      onCancel={onCancel}
                    />
                  : <div className={classes.noData}>Нет данных</div>
                )
            )
          }
        </div>
      </ReportTab>
    )
  }
)