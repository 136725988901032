import * as actionTypes from './actionTypes'
import { Environment } from '../../services/api'
import { setErrorContent } from '../Error/actions'

export const getEnvironment = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_ENVIRONMENT })
    const { data } = await Environment.getEnvironment()
    dispatch({ 
      type: actionTypes.GET_ENVIRONMENT_SUCCESS, 
      data,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_ENVIRONMENT_ERROR })
  }
}
