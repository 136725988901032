import injectSheet from 'react-jss'

export const modalAlternativeStyles = injectSheet({
  common: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 5000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  background: {
    zIndex: 4000,
    background: '#1e1e1e',
    opacity: 0.9,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  wrapper: {
    zIndex: 5000,
    display: 'flex',
  },
  modal: {
    boxSizing: 'border-box',
    background: '#fff',
    maxWidth: ' 100%',
    height: 'auto',
    padding: '30px',
    overflowY: 'auto',
    borderRadius: '16px',
    maxHeight: 'calc(100vh - 32px)',
    '&.hideOverflow': {
      overflowY: 'hidden',
    },
    '&.noPadding': {
      padding: '0',
    },
  },
})
