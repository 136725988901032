import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Icon } from '../../../../../../layout'
import { Status } from '../../Layout'
import { THEME_COLOR } from '../../../../../../config'

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subItem: {
    '&:nth-child(1)': {
      width: 180,
      '& $value': {
        fontSize: 15,
        lineHeight: 22/15,
        fontWeight: 700,
        color: '#504E5B',
      },
    },
  },
  key: {
    fontSize: 10,
    lineHeight: 15/10,
    fontWeight: 500,
    color: '#7d7d7d',
    leterSpacing: '0.01em',
  },
  value: {
    fontSize: 14,
    lineHeight: 18/14,
    fontWeight: 500,
    color: '#000',
  },
  collapse: {
    width: 24,
    height: 24,
    padding: 2,
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'background 300ms ease, transform 300ms ease',
    '&:active': {
      background: THEME_COLOR,
    },
    '&.open': {
      transform: 'rotateZ(180deg)',
    },
    '& > svg': {
      width: 20,
      height: 20,
      display: 'block',
    },
  },
})

export const Header = ({
  open,
  name,
  date,
  enabled,
  orderNumber,
  onCollapse,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.header}>
      <div className={classes.subItem}>
        <div className={classes.key}>Продукт</div>
        <div className={classes.value}>{name}</div>
      </div>
      <div className={classes.subItem}>
        <div className={classes.key}>Заявка</div>
        <div className={classes.value}>№ {orderNumber}</div>
      </div>
      <div className={classes.subItem}>
        <div className={classes.key}>Дата</div>
        <div className={classes.value}>{date}</div>
      </div>
      <div className={classes.subItem}>
        <Status enabled={enabled} />
      </div>
      <div className={classes.subItem}>
        <div className={classnames(classes.collapse, { open })} onClick={onCollapse}>
          <Icon iconName='collapse' />
        </div>
      </div>
    </div>
  )
}