import React, { useEffect, useState, memo, useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { getFactoringRefMatchingReasons } from '../../../../redux/Factoring/actions'
import { Fieldset, InputText, Modal2, Select2 } from '../../../../layout'
import { formattedNumber, onlyDigitsWithDot } from '../../../../utils'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'

const useStyles = createUseStyles({
  unexplainedModal: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
  item: {
    fontSize: 14,
    '& + &': {
      marginTop: 8,
    },
    '&:last-child': {
      marginTop: 16,
    },
    '& > span': {
      width: 160,
      fontWeight: 600,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
})

export const UnexplainedModal = memo(
  ({
    payments,
    payment,
    onAddDraft,
    onAddDrafts,
    onClose,
  }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [matchedAmount, setMatchedAmount] = useState(payment?.unmatchedAmount)
    const [matchingReason, setMatchingReason] = useState('RECEPIENT_NOT_DEFINED')
    const [submitted, setSubmitted] = useState(0)

    const { matchingReasons } = useSelector(state => state?.Factoring || {})

    const touched = useMemo(() => submitted > 0, [submitted])

    const matchingReasonsOptions = matchingReasons.map(item => ({
      name: item?.name,
      value: item?.id,
    }))

    const onChangeMatchingReason = (e) => setMatchingReason(e.target.value)

    const getPayload = useCallback((payment, matchedAmountByPayment = false) => ({
      matchingReason,
      details: [],
      matchedAmount: matchedAmountByPayment
        ? payment?.unmatchedAmount
        : matchedAmount,
      order: {
        amount: 1000000000000000,
        externalId: 'unknown',
        number: '-',
        orderDate: new Date().toISOString(),
        orderType: 'unknown',
        orderTypeRu: 'Невыясненные',
      },
      paymentId: payment?.id,
    }), [matchedAmount, matchingReason])

    const errors = {
      ...(String(matchedAmount)?.trim() ? {} : { matchedAmount: 'Не указана сумма к возврату' }),
      ...(matchedAmount <= payment?.unmatchedAmount ? {} : { matchedAmount: 'Сумма на невыясненные не должна превышать сумму остатка' }),
      ...(matchingReason ? {} : { matchingReason: 'Не указана причина невыясненных' }),
    }

    const isValid = Object.keys(errors)?.length === 0

    const multiplePayments = payments?.length > 1

    useEffect(() => {
      dispatch(getFactoringRefMatchingReasons())
    }, [dispatch])
    
    useEffect(() => {
      if (touched) {
        if (multiplePayments) {
          const payload = payments.map(item => getPayload(item, true))
          onAddDrafts(payload)
          onClose()
        } else {
          if (isValid) {
            const payload = getPayload(payment, false)
            onAddDraft(payload)
            onClose()
          }
        }
      }
    // eslint-disable-next-line
    }, [touched, submitted])

    return (
      <Modal2 className={classes.unexplainedModal} onClose={onClose}>
        <div className={classes.wrapper}>
          <H3>Невыясненные</H3>
          <Form>
            {!multiplePayments &&
              <Fieldset>
                <div className={classes.item}>
                  <span>№ Платежа:</span> {payment?.number}
                </div>
                <div className={classes.item}>
                  <span>Дата платежа:</span> {new Date(payment?.date)?.toLocaleDateString('ru-RU')}
                </div>
                <div className={classes.item}>
                  <span>Плательщик:</span> {payment?.payerName}
                </div>
                <div className={classes.item}>
                  <span>Сумма:</span> {formattedNumber(payment?.amount)}
                </div>
                <div className={classes.item}>
                  <span>Остаток:</span> {formattedNumber(payment?.unmatchedAmount)}
                </div>
                <div className={classes.item}>
                  <InputText
                    type='text' 
                    name='sum'
                    label='Сумма на невыясненные'
                    value={matchedAmount}
                    onChange={e => setMatchedAmount(e.target.value
                      ? onlyDigitsWithDot(e.target.value)
                      : e.target.value
                    )}
                    error={touched && errors?.matchedAmount}
                  />
                </div>
              </Fieldset>
            }
            <Fieldset>
              <Select2
                label='Причина невыясненных'
                name='matchingReason'
                options={matchingReasonsOptions}
                value={matchingReason}
                onChange={onChangeMatchingReason}
                error={touched && errors.matchingReason}
              />
            </Fieldset>
          </Form>
          <ButtonWrapper>
            <Button
              onClick={() => setSubmitted(submitted + 1)}
              disabled={touched && !isValid}
            >
              Создать
            </Button>
          </ButtonWrapper>
        </div>
      </Modal2>
    )
  }
)