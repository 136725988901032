import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Alert } from './Layout'
import { Icon } from '../../layout'
import { formattedNumber, getValue } from '../../utils'

const useStyles = createUseStyles({
  warningTd: {
    width: 40,
  },
  disabledRow: {
    opacity: '0.4'
  }
})

const getDate = (value) => new Date(value).toLocaleDateString('ru-RU')
const getTime = (value) => new Date(value).toLocaleTimeString('ru-RU')

const getWarningColor = (value) => {
  switch(true) {
    case value >= 0 && value <= 3: return '#28a745'
    case value >= 4 && value <= 5: return '#ffc107'
    case value >= 6: return '#dc3545'
    default: return '#000'
  }
}

export const renderValue = ({
  item,
  field,
  type,
  linkData = {},
  selectedNonDeliverableTransit
}) => {
  const value = getValue(item, field)
  const date = getDate(value)
  const time = getTime(value)
  const datetime = `${date}, ${time}`
  const periods = typeof value === 'string' && value?.includes(' - ') ? value.split(' - ') : []
  const period = periods?.length > 0 ? `${getDate(periods[0])} - ${getDate(periods[1])}` : ''
  const {
    entityType = '',
    entityId = '',
  } = linkData
  const link = `/factoring/${entityType}/${item && entityId ? item[entityId] : ''}#hideTable`
  switch(true) {
    case field === 'description': {
      return <div style={{width: 300, whiteSpace: 'normal'}}>{value}</div>
    }
    case type === 'link': return <Link to={link} target='_blank'>{value}</Link>
    case type === 'date' && value?.includes(' - '): return period
    case type === 'date' && !!value && value !== '-': return date
    case type === 'datetime' && value && value !== '-': return datetime
    case type === 'amount': return value === 1000000000000000 ? '-' : formattedNumber(value)
    case type === 'rate': return value
    case type === 'scale': return value
    case type === 'boolean' && field === 'indicative': return value === true
      ? 'Дебитор'
      : value === false
        ? 'Поставщик'
        : '-'
    case type === 'boolean' && value && field === 'fullyMatched': return <Alert type='success'>Да</Alert>
    case type === 'boolean' && value && field !== 'fullyMatched': return 'Да'
    case type === 'boolean' && !value && field === 'fullyMatched': return <Alert type='danger'>Нет</Alert>
    case type === 'boolean' && !value && field !== 'fullyMatched': return 'Нет'
    case type === 'status': return <Alert type='success'>{value || '-'}</Alert>
    case type === 'warning' && selectedNonDeliverableTransit?.includes(item?.externalEntity?.factoringContractId): {
      const iconStyles = {
        width: 20,
        height: 20,
        marginLeft: 0,
        fill: '#ffc107',
        display: 'block',
      }
      return value === null ? <Icon iconName='warning' style={iconStyles} /> : '-'
    }
    case type === 'warning': {
      const iconStyles = {
        width: 20,
        height: 20,
        marginLeft: 0,
        fill: getWarningColor(value),
        display: 'block',
      }
      return value >= 0 && value !== null ? <Icon iconName='warning' style={iconStyles} /> : '-'
    }
    case typeof value === 'object': return value ? JSON.stringify(value) : '-'
    case !!value || value === 0: return value
    default: return '-'
  }
}

export const RowValue = ({
  field,
  item,
  type,
  linkData,
  style,
  onClick,
  selectedNonDeliverableTransit,
  isDisabledOrdersRow
}) => {
  const classes = useStyles()
  return (
    <td
      className={classnames({[classes.warningTd]: type === 'warning'}, isDisabledOrdersRow && classes.disabledRow)}
      style={style}
      onClick={onClick}
    >
      <div>
        {renderValue({ field, item, type, linkData, selectedNonDeliverableTransit })}
      </div>
    </td>
  )
}