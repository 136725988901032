import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Button, ButtonWrapper, H3 } from '../../Layout'
import { Item } from './Item'
import { Toast } from '../../../../components/Toast'

const FIELD_LABELS = {
  financingSum: 'Сумма финансирования',
  approvedSum: 'Подтверждённая сумма поставки',
  acceptanceDate: 'Дата приёмки поставки дебитором',
}

const getItems = (object) => {
  let result = []
  for (var key in object) {
    result.push({ [key]: object[key] })
  }
  return result
}

export const ModifyModal = ({
  classes,
  textCode,
  completeTasks,
  config,
  queue,
  setQueue,
  setGlobalSubmitted,
  onClose,
}) => {
  const [submitted, setSubmitted] = useState(0)
  const [validated, setValidated] = useState(false)
  const [valuesNoGreater, setValuesNoGreater] = useState([])

  const { tasks } = useSelector(state => state?.Factoring || {})

  const items = getItems(config)

  const showNotification = useMemo(() => {
    return valuesNoGreater?.length > 0 && valuesNoGreater?.some(item => !item)
  }, [valuesNoGreater])

  const onShowToast = () => Toast({
    message: 'Подтверждённая сумма больше суммы поставки',
    type: 'error',
  })

  const onSubmit = () => showNotification
    ? onShowToast()
    : setSubmitted(submitted + 1)

  return (
    <>
      <H3>{textCode}</H3>
      <div className={classes.container}>
        <div style={{minHeight: 300}}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>
                  <div>Номер</div>
                </th>
                {items.map((item, index) => {
                  const name = Object.keys(item)[0]
                  const title = FIELD_LABELS[name]
                  return (
                    <th key={index}><div>{title}</div></th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {completeTasks.map(({ id }, index) => {
                const task = tasks?.items?.find(item => item?.id === id) || {}
                return (
                  <Item
                    key={index}
                    index={index}
                    task={task}
                    items={items}
                    submitted={submitted}
                    queue={queue}
                    showNotification={showNotification}
                    setQueue={setQueue}
                    setValidated={setValidated}
                    setGlobalSubmitted={setGlobalSubmitted}
                    setValuesNoGreater={setValuesNoGreater}
                    onClose={onClose}
                  />
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ButtonWrapper>
        <Button
          onClick={onSubmit}
          disabled={submitted && !validated}
        >
          Подтвердить
        </Button>
      </ButtonWrapper>
    </>
  )
}