import axios from 'axios'
import { API_URL } from '../../config'

export const paymentDone = async ({ installmentId, paymentScheduleId }) => {
  try {
    const { data: { error_code } } = await axios({
      method: 'POST',
      url: `${API_URL}/installment/paymentDone?installmentId=${installmentId}&paymentScheduleId=${paymentScheduleId}`
    })
    return {
      isSuccess: error_code === 0,
    }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}