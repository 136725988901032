import React, { useState, useEffect } from 'react'
import injectSheet from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { getBaseSchemaList, clearBaseSchema } from '../../redux/Tariffs/actions'
import { Burger } from '../../components/Burger'
import { ModalSchemaForm } from '../../components/Modals'
import TariffSchema from './TariffSchema'

const styles = {
  root: {
    padding: [14, 68, 82],
    textAlign: 'center',
    position: 'relative',
  },
}

const TariffSchemes = ({
  classes,
}) => {
  const dispatch = useDispatch()

  const { tariffs, isFetching } = useSelector(state => state.Tariffs)

  const [showSchemaModal, setShowSchemaModal] = useState(false)

  useEffect(() => {
    dispatch(getBaseSchemaList())
  }, [dispatch])

  const onAdd = () => {
    dispatch(clearBaseSchema())
    onShowSchema()
  }

  const onShowSchema = () => setShowSchemaModal(true)
  const onCloseSchema = () => setShowSchemaModal(false)

  return (
    <>
      <Burger />
      <div className={classes.root}>
        {tariffs.map(item => 
          <TariffSchema
            key={item.id}
            isFetching={isFetching}
            {...item}
          />
        )}
      </div>
      <div className='btn-options'>
        <div className='btn-options__link' onClick={onAdd} />
      </div>
      {showSchemaModal &&
        <ModalSchemaForm onClose={onCloseSchema} />
      }
    </>
  )
}

export default injectSheet(styles)(TariffSchemes)