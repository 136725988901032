import React from 'react'
import PropTypes from 'prop-types'

import TableTitle from './TableTitle'

const TableRow = ({
  heading,
  head,
  body,
}) =>
  <div className='table-row'>
    <TableTitle>{heading}</TableTitle>
    <table className='table-m table-charts'>
      {head && head.length > 0 &&
        <thead>
          <tr>
            <td></td>
            {head.map((item, index) =>
              <td key={index}>{item}</td>
            )}
          </tr>
        </thead>
      }
      {body && body.length > 0 &&
        <tbody>
          {body.map(({ color, heading, values }, index) =>
            <tr className={color} key={index}>
              <td>{heading}</td>
              {values && values.length > 0 &&
                <>
                  {values.map((column, column_index) =>
                    <td key={column_index}>{column}</td>
                  )}
                </>
              }
            </tr>
          )}
        </tbody>
      }
    </table>
  </div>

TableRow.propTypes = {
  heading: PropTypes.string.isRequired,
  head: PropTypes.array.isRequired,
  body: PropTypes.array.isRequired,
  // body: PropTypes.arrayOf(PropTypes.shape({
  //   heading: PropTypes.string.isRequired,
  //   color: PropTypes.oneOf([PropTypes.string, null]),
  //   values: PropTypes.array.isRequired,
  // })).isRequired,
}

TableRow.defaultProps = {
  heading: '',
  head: [],
  body: [],
}

export default TableRow