import React, { useEffect, useState, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { getFactoringDeferSettings } from '../../../../redux/Factoring/actions'
import { Modal2, InputText, Select2, Radio, Fieldset, Label, RowGrid, Col1, Col4, Col6, Col11, Col12 } from '../../../../layout'
import { Button, ButtonWrapper, ButtonAdd, ButtonRemove, Form, H3 } from '../../Layout'
import styles from '../../styles'
import { onlyDigitsWithDot } from '../../../../utils'

const useStyles = createUseStyles({
  addSupplyRegisterFormInner: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
  ...styles,
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
  },
  actionBtn: {
    ...styles.actionBtn,
    width: 56,
    height: 56,
  },
})

const transitOptions = [
  { name: 'Финансирование', value: 'NONE' },
  { name: 'Транзит', value: 'TRANSIT' },
  { name: 'Взаимозачёт', value: 'OFFSET' },
]

const whenToFundOptions = [
  { name: '', value: '' },
  { name: 'Сразу', value: 'IMMEDIATELY' },
  { name: 'В указанную дату', value: 'BY_DATE' },
  { name: 'По запросу', value: 'ON_REQUEST' },
  { name: 'По контракту', value: 'BY_CONTRACT' },
]

const CONFIRMATION_DOCUMENT_INITIAL_STATE = {
  type: '',
  number: '',
  date: '',
}

const originalsOptions = [
  { value: true, label: 'Оригиналы документов' },
  { value: false, label: 'Копии документов' },
]

export const AddSupplyRegisterFormInner = ({
  supplyContracts,
  debtors,
  supplierInn,
  factoringContractDate,
  factoringContractNumber,
  financing,
  index,
  onAddFinancing,
  onEditFinancing,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { documents, deferSettings } = useSelector(state => state?.Factoring)

  const deferDaysOptions = (deferSettings?.settings11 || [])?.map(item => ({
    name: item?.daysQuantity,
    value: item?.daysQuantity,
  }))

  const confirmationDocumentTypeOptions = documents.reduce((result, { id, name }) => {
    result.push({ name, value: id })
    return result
  }, [{ name: '', value: '' }])

  const isEdit = index >=0

  const initialValues = {
    debtorInn: isEdit ? financing.debtorInn : '',
    supplyContractId: isEdit ? supplyContracts?.find(item => item?.number === financing.supplyContractNumber)?.id : '',
    deferPeriod: isEdit ? financing.deferPeriod : '',
    supplyContractDate: isEdit ? financing.supplyContractDate : '',
    supplyContractNumber: isEdit ? financing.supplyContractNumber : '',
    confirmationDocument: isEdit
      ? financing.confirmationDocument
      : [
          { ...CONFIRMATION_DOCUMENT_INITIAL_STATE },
          { ...CONFIRMATION_DOCUMENT_INITIAL_STATE },
        ],
    acceptanceDate: isEdit ? financing.acceptanceDate : '',
    supplySum: isEdit ? financing.supplySum : '',
    sum: isEdit ? financing.sum : '',
    number: isEdit ? financing.number : '',
    date: isEdit ? financing.date : '',
    financingDate: isEdit ? financing.financingDate : '',
    transit: isEdit ? financing.transit : transitOptions[0].value,
    note: isEdit ? financing.note : '',
    whenToFund: isEdit ? financing.whenToFund : whenToFundOptions[0].value,
    hasOriginals: isEdit ? financing.hasOriginals : originalsOptions[0].value,
  }
  
  const [debtorInn, setDebtorInn] = useState(initialValues.debtorInn)
  const [supplyContractId, setSupplyContractId] = useState(initialValues.supplyContractId)
  const [deferPeriod, setDeferPeriod] = useState(initialValues.deferPeriod)
  const [supplyContractDate, setSupplyContractDate] = useState(initialValues.supplyContractDate)
  const [supplyContractNumber, setSupplyContractNumber] = useState(initialValues.supplyContractNumber)
  const [confirmationDocument, setConfirmationDocument] = useState(initialValues.confirmationDocument)
  const [acceptanceDate, setAcceptanceDate] = useState(initialValues.acceptanceDate)
  const [supplySum, setSupplySum] = useState(initialValues.supplySum)
  const [sum, setSum] = useState(initialValues.sum)
  const [financingDate, setFinancingDate] = useState(initialValues.financingDate)
  const [transit, setTransit] = useState(initialValues.transit)
  const [note, setNote] = useState(initialValues.note)
  const [whenToFund, setWhenToFund] = useState(initialValues.whenToFund)
  const [hasOriginals, setHasOriginals] = useState(initialValues.hasOriginals)
  const [submitted, setSubmitted] = useState(0)

  const touched = useMemo(() => submitted > 0, [submitted])

  const isByDate = whenToFund === 'BY_DATE'
  const noTransit = transit === 'NONE'

  const supplyContractsByDebtor = useMemo(() => {
    return supplyContracts.filter(item => item?.inn === debtorInn) || []
  }, [supplyContracts, debtorInn])

  useEffect(() => {
    if (supplyContractId) {
      dispatch(getFactoringDeferSettings({
        entityId: supplyContractId,
        entityType: 'SUPPLY_CONTRACT',
      }))
    }
  }, [supplyContractId, dispatch])

  useEffect(() => {
    if (supplyContractsByDebtor?.length === 1) {
      const { id, date, number } = supplyContractsByDebtor[0]
      setSupplyContractId(id)
      setSupplyContractDate(date)
      setSupplyContractNumber(number)
    }
  }, [debtorInn, supplyContractsByDebtor])

  const onChangeSupplyContract = (e) => {
    const id = e.target.value
    const { date, number } = supplyContracts?.find(item => item?.id === id) || {}
    setSupplyContractId(id)
    setSupplyContractDate(date)
    setSupplyContractNumber(number)
  }

  const onChangeDeferPeriod = (e) => setDeferPeriod(e?.target?.value)

  const onChangeConfirmationDocument = (value, name, index) => {
    const updateConfirmationDocument = confirmationDocument.map((item, idx) => {
      if (index === idx) {
        item[name] = value
      }
      return item
    })
    setConfirmationDocument(updateConfirmationDocument)
  }

  const onChangeTransit = (e) => {
    setTransit(e.target.value)
    if (e.target.value !== 'NONE') {
      setWhenToFund(whenToFundOptions[3].value)
    }
  }

  const onAddDocument = () => setConfirmationDocument([
    ...confirmationDocument,
    {...CONFIRMATION_DOCUMENT_INITIAL_STATE},
  ])

  const onRemoveDocument = (index) => setConfirmationDocument(
    confirmationDocument.filter((_, idx) => index !== idx)
  )

  const payload = {
    acceptanceDate,
    confirmationDocument,
    currency: 'RUR',
    debtorInn,
    deferPeriod,
    factoringContractDate,
    factoringContractNumber,
    sum,
    supplierInn,
    supplyContractDate,
    supplyContractNumber,
    supplySum,
    transit,
    whenToFund,
    note,
    hasOriginals,
    ...(isByDate ? { financingDate } : {}),
  }

  const errors = {
    ...(debtorInn?.trim() ? {} : { debtorInn: 'Не указан дебитор' }),
    ...(supplyContractNumber?.trim() ? {} : { supplyContractNumber: 'Не указан номер договора поставки' }),
    ...(deferPeriod?.trim() ? {} : { deferPeriod: 'Не указано количество дней отсрочки' }),
    ...(supplyContractDate?.trim() ? {} : { supplyContractDate: 'Не указана дата договора поставки' }),
    ...(confirmationDocument[0].type.trim() ? {} : { confirmationDocumentType: 'Не указан тип основного документа' }),
    ...(confirmationDocument[0].number.trim() ? {} : { confirmationDocumentNumber: 'Не указан номер основного документа' }),
    ...(confirmationDocument[0].date.trim() ? {} : { confirmationDocumentDate: 'Не указана дата основного документа' }),
    ...(supplySum.trim() ? {} : { supplySum: 'Не указана сумма поставки' }),
    ...(whenToFund.trim() ? {} : { whenToFund: 'Не указано когда финансировать' }),
    ...(isByDate && !financingDate.trim() ? { financingDate: 'Не указана дата финансирования' } : {}),
  }

  const isValid = Object.keys(errors)?.length === 0

  const onSubmit = () => setSubmitted(submitted + 1)

  useEffect(() => {
    if (isValid && touched) {
      if (isEdit) {
        onEditFinancing(payload, index)
      } else {
        onAddFinancing(payload)
      }
      onClose()
    }
  // eslint-disable-next-line
  }, [submitted])

  console.log('payload [2-nd form]:', payload)
  console.log('errors [2-nd form]', errors)

  return (
    <Modal2
      className={classes.addSupplyRegisterFormInner}
      onClose={onClose}
    >
      <div className={classes.wrapper}>
        <H3>Реестр поставок</H3>
        <Form>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Select2
                  label='Дебитор'
                  name='debtorInn'
                  options={debtors}
                  value={debtorInn}
                  onChange={e => setDebtorInn(e.target.value)}
                  error={touched && errors.debtorInn}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <RowGrid>
                  <Col6>
                    <Select2
                      label='Номер договора поставки'
                      name='factoringContract'
                      options={[
                        { name: '', value: '' },
                        ...supplyContractsByDebtor,
                      ]}
                      value={supplyContractId}
                      onChange={onChangeSupplyContract}
                      error={touched && (errors.supplyContractNumber || errors.supplyContractDate)}
                    />
                  </Col6>
                  <Col6>
                    <Select2
                      label='Отсрочка (дни)'
                      name='deferPeriod'
                      options={[
                        { name: '', value: '' },
                        ...deferDaysOptions,
                      ]}
                      value={deferPeriod}
                      onChange={onChangeDeferPeriod}
                      error={touched && errors.deferPeriod}
                    />
                  </Col6>
                </RowGrid>
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col4>
                <Select2
                  label='Тип документа'
                  name='type'
                  options={confirmationDocumentTypeOptions}
                  value={confirmationDocument[0].type}
                  onChange={e => onChangeConfirmationDocument(e.target.value, e.target.name, 0)}
                  error={touched && errors.confirmationDocumentType}
                />
              </Col4>
              <Col4>
                <InputText
                  type='text' 
                  name='number'
                  label='Номер документа'
                  value={confirmationDocument[0].number}
                  onChange={e => onChangeConfirmationDocument(e.target.value, e.target.name, 0)}
                  error={touched && errors?.confirmationDocumentNumber}
                />
              </Col4>
              <Col4>
                <InputText
                  type='date' 
                  name='date'
                  label='Дата документа'
                  value={confirmationDocument[0].date}
                  onChange={e => onChangeConfirmationDocument(e.target.value, 'date', 0)}
                  error={touched && errors?.confirmationDocumentDate}
                />
              </Col4>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='date' 
                  name='acceptanceDate'
                  label='Дата приемки'
                  value={acceptanceDate}
                  onChange={e => setAcceptanceDate(e.target.value)}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Label>Документы</Label>
                {originalsOptions.map(({ value, label }, index) =>
                  <Radio
                    key={index}
                    id={`hasOriginals-${value}`}
                    name='hasOriginals'
                    label={label}
                    value={value}
                    checked={value === hasOriginals}
                    onChange={_ => setHasOriginals(value)}
                    // error={touched && errors?.hasOriginals}
                  />
                )}
                {/* {touched && errors?.hasOriginals &&
                  <Error>{errors.hasOriginals}</Error>
                } */}
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='supplySum'
                  label='Сумма поставки'
                  value={supplySum}
                  onChange={e => setSupplySum(onlyDigitsWithDot(e.target.value))}
                  error={touched && errors?.supplySum}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Select2
                  label='Тип поставки'
                  name='transit'
                  options={transitOptions}
                  value={transit}
                  onChange={onChangeTransit}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          {noTransit &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Select2
                    label='Когда финансировать'
                    name='whenToFund'
                    options={whenToFundOptions}
                    value={whenToFund}
                    onChange={e => setWhenToFund(e.target.value)}
                    error={touched && errors?.whenToFund}
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {isByDate &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <InputText
                    type='date' 
                    name='financingDate'
                    label='Дата финансирования'
                    value={financingDate}
                    onChange={e => setFinancingDate(e.target.value)}
                    error={touched && errors?.financingDate}
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          <Fieldset>&nbsp;</Fieldset>
          <Fieldset>
            <H3>ДОПОЛНИТЕЛЬНО:</H3>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='sum'
                  label='Сумма финансирования'
                  value={sum}
                  onChange={e => setSum(onlyDigitsWithDot(e.target.value))}
                  error={touched && errors?.sum}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <H3>Подтверждающие дополнительные документы:</H3>
          </Fieldset>
          {confirmationDocument.map(({ type, number, date }, index) => {
            const canRemoveDocument = confirmationDocument.length > 2
            const Column = canRemoveDocument ? Col11 : Col12
            return index === 0
              ? null
              : <Fieldset key={index}>
                  <RowGrid>
                    <Column>
                      <RowGrid>
                        <Col4>
                          <Select2
                            label='Тип доп. документа'
                            name='type'
                            options={confirmationDocumentTypeOptions}
                            value={type}
                            onChange={e => onChangeConfirmationDocument(e.target.value, e.target.name, index)}
                          />
                        </Col4>
                        <Col4>
                          <InputText
                            type='text' 
                            name='number'
                            label='Номер доп. документа'
                            value={number}
                            onChange={e => onChangeConfirmationDocument(e.target.value, e.target.name, index)}
                          />
                        </Col4>
                        <Col4>
                          <InputText
                            type='date' 
                            name='date'
                            label='Дата доп. документа'
                            value={date}
                            onChange={e => onChangeConfirmationDocument(e.target.value, 'date', index)}
                          />
                        </Col4>
                      </RowGrid>
                    </Column>
                    {canRemoveDocument &&
                      <Col1>
                        <Label>&nbsp;</Label>
                        <div className={classes.actions}>
                          <ButtonRemove
                            className={classes.actionBtn}
                            title='Удалить дополнительный документ'
                            onClick={() => onRemoveDocument(index)}
                          />
                        </div>
                      </Col1>
                    }
                  </RowGrid>
                </Fieldset>
            })
          }
          <Fieldset>
            <ButtonAdd
              title='Добавить дополнительный документ'
              onClick={onAddDocument}
            >
              Добавить дополнительный документ
            </ButtonAdd>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='note'
                  label='Примечание'
                  value={note}
                  onChange={e => setNote(e.target.value)}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        </Form>
        <ButtonWrapper>
          <Button disabled={touched && !isValid} onClick={onSubmit}>
            {isEdit
              ? 'Отредактировать поставку'
              : 'Сохранить поставку'
            }
          </Button>
        </ButtonWrapper>
      </div>
    </Modal2>
  )
}