import React from 'react'
import { useStyles } from './styles'
import Overlay from '../../../Overlay'
import { ModalAlternative } from '../../../../layout/Modal/Modal/Alternative/ModalAlternative'

export const DetailsTable = ({
  children,
  data,
  loading = false,
  onClose,
  Item,
  listKey = 'tasks',
  onClick,
}) => {
  const classes = useStyles()
  const {
    name: title,
    [listKey]: list,
  } = data
  return (
    <ModalAlternative onClose={onClose} hideOverflow noPadding>
      <section className={classes.container}>
        <h2 className={classes.header}>{title}</h2>
        {!loading && list?.length > 0
          ? <div className={classes.tableWrapper}>
              <table className={classes.table}>
                <tbody>
                  {children}
                  {list.map((item, index) =>
                    <Item key={index} data={item} onClick={onClick} />
                  )}
                </tbody>
              </table>
            </div>
          : <div className={classes.emptyContainer}>
              {loading
                ? <Overlay />
                : <span>По этому запросу нет данных.</span>
              }
            </div>
        }
      </section>
    </ModalAlternative>
  )
}