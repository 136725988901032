import React, { useEffect } from 'react'
import { Route, useHistory , useLocation } from 'react-router-dom'
import Tasks from './Tasks'
import { ModalDeal } from './../../components/Modals'

const TasksPage = (props) => {
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    if (location?.state?.fromTask) {
      // BG-545 + BG-802
      setTimeout(() => {
        history.push('/tasks')
      }, 0)
    }
  }, [history, location])
  return (
    <>
      <Tasks {...props} />
      <Route exact path='/tasks/order/:orderId' component={ModalDeal} />
    </>
  )
}

export default TasksPage