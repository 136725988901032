import axios from 'axios'
import { API_URL } from '../../../config'

export const getReportCommonStatus = async ({ process, dateFrom, dateTo }) => {
  try {
    const { data: { error_code, ...rest } } = await axios({
      method: 'GET',
      url: `${API_URL}/report/all3d?orderTypeRefId=${process}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    })
    if (error_code === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: error_code === 5,
      message: rest.error,
    }
  } catch (err) {
    console.error(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}