import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createFactoringLimit } from '../../../../redux/Factoring/actions'
import {
  Modal2,
  InputText,
  Checkbox,
  Radio,
  Select2,
  Fieldset,
  Label,
  Error,
  RowGrid,
  Col1,
  Col4,
  Col11,
  Col12,
} from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Search } from '../../../../services/api'
import { Button, ButtonWrapper, ButtonAdd, ButtonRemove, Form, H3 } from '../../Layout'
import styles from '../../styles'
import {
  ROLES,
  PRODUCTS,
  LIMIT_TYPES,
  ROLE_OPTIONS,
  PRODUCTS_OPTIONS,
  ORIGINALS_OPTIONS,
  INDICATIVE_OPTIONS,
  DEFAULT_DEBTOR,
  DEFAULT_SUPPLIER,
  DEFAULT_CONTRACT_PB_ID,
} from './config'

const useStyles = createUseStyles({
  addLimitForm: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
  ...styles,
  addBtn: {
    marginTop: 10,
  },
})

const {
  HOLDING,
  DOCUMENT,
  PROVISIONS,
  LEGAL_ENTITY,
  SUPPLIER_DEBTOR,
  FACTORING_CONTRACT,
  SUPPLIER_MANY_DEBTOR,
  DOCUMENT_LEGAL_ENTITY,
} = LIMIT_TYPES

export const AddLimitForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { entityType } = useParams()

  const { limitTypes } = useSelector(state => state?.Factoring)

  const [type, setType] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [inn, setInn] = useState('')
  const [ogrn, setOgrn] = useState('')
  const [holdingId, setHoldingId] = useState('')
  const [holdingName, setHoldingName] = useState('')
  const [indicative, setIndicative] = useState(INDICATIVE_OPTIONS[0].value)
  const [role, setRole] = useState(ROLE_OPTIONS[0].value)
  const [products, setProducts] = useState([])
  const [originals, setOriginals] = useState([])
  const [limitAmount, setLimitAmount] = useState('')
  const [beginDate, setBeginDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [confirmationDate, setConfirmationDate] = useState('')
  const [suppliers, setSuppliers] = useState([{ ...DEFAULT_SUPPLIER }])
  const [debtors, setDebtors] = useState([{ ...DEFAULT_DEBTOR }])
  const [contractPbIds, setContractPbIds] = useState([{ ...DEFAULT_CONTRACT_PB_ID }])
  const [submitted, setSubmitted] = useState(0)

  const touched = useMemo(() => submitted > 0, [submitted])

  const isRegress = products.find(item => item === PRODUCTS.REGRESS)
  const isDebtor = role === ROLES.DEBTOR
  const isSupplier = role === ROLES.SUPPLIER

  const isFactoringContract = type === FACTORING_CONTRACT
  const isSupplierDebtor = type === SUPPLIER_DEBTOR
  const isDocument = type === DOCUMENT
  const isDocumentLegalEntity = type === DOCUMENT_LEGAL_ENTITY
  const isHolding = type === HOLDING
  const isSupplierManyDebtor = type === SUPPLIER_MANY_DEBTOR
  const isProvisions = type === PROVISIONS
  const isLegalEntity = type === LEGAL_ENTITY

  const _ROLE_OPTIONS =
    isDocument ||
    isSupplierDebtor ||
    isFactoringContract ||
    isSupplierManyDebtor ||
    isDocumentLegalEntity
      ? ROLE_OPTIONS?.slice(0, 1)
      : ROLE_OPTIONS

  const _ORIGINALS_OPTIONS =
    isDocument ||
    isDocumentLegalEntity
      ? ORIGINALS_OPTIONS?.slice(1, 2)
      : ORIGINALS_OPTIONS

  const showCompanyName = !isHolding
  const showHoldings = isHolding
  const showContractPbIds = isFactoringContract || isSupplierDebtor || isDocument || isSupplierManyDebtor
  const showSuppliers = isDebtor && (isSupplierDebtor || isDocument)
  const showDebtors = isSupplier && (isSupplierDebtor || isSupplierManyDebtor || isDocument)
  const showOriginals = isDocument || isDocumentLegalEntity
  const showRole = !isProvisions
  const showIndicative = ((isSupplier || isHolding || isLegalEntity) && !isDocumentLegalEntity && !isProvisions)
  const showAddDebitorButton = !isSupplierDebtor
  const showAddContractButton = !isFactoringContract && !isSupplierDebtor && !isDocument && !isSupplierManyDebtor

  const getPayload = () => {
    let payload = {
      role,
      products,
      limitAmount,
      beginDate,
      endDate,
      confirmationDate,
      type,
      inn: isHolding
        ? ''
        : inn,
    }

    if (isHolding) {
      payload = {
        ...payload,
        holdingId,
        indicative,
      }
    }

    if (showHoldings) {
      if (isSupplier) {
        payload = {
          ...payload,
          supplierHoldings: [holdingId],
        }
      }
      if (isDebtor) {
        payload = {
          ...payload,
          debtorHoldings: [holdingId],
        }
      }
    }

    if (isRegress) {
      payload = {
        ...payload,
        subProducts: ['SECURITY', 'NON_SECURITY'],
      }
    }

    if (isDebtor) {
      payload = {
        ...payload,
        indicative,
        debtors: inn && ogrn ? [{ inn, ogrn }] : [],
      }
    }

    if (isSupplier) {
      payload = {
        ...payload,
        suppliers: inn && ogrn ? [{ inn, ogrn }] : [],
      }
    }

    if (showDebtors) {
      const isEmptyDebtors = debtors?.length === 1 && debtors[0]?.inn === '' && debtors[0]?.ogrn === ''
      payload = {
        ...payload,
        indicative,
        debtors: isEmptyDebtors
          ? []
          : debtors.map(({ INN = '', OGRN = '' }) => ({ inn: INN, ogrn: OGRN })),
      }
    }

    if (showSuppliers) {
      const isEmptySuppliers = suppliers?.length === 1 && suppliers[0]?.inn === '' && suppliers[0]?.ogrn === ''
      payload = {
        ...payload,
        suppliers: isEmptySuppliers
          ? []
          : suppliers.map(({ INN = '', OGRN ='' }) => ({ inn: INN, ogrn: OGRN })),
      }
    }

    if (showContractPbIds) {
      const isEmptyContractPbIds = contractPbIds?.length === 1 && contractPbIds[0]?.id === ''
      payload = {
        ...payload,
        contractPbIds: isEmptyContractPbIds
          ? []
          : contractPbIds.map(({ id = '' }) => id),
      }
    }

    if (showOriginals) {
      payload = {
        ...payload,
        originals,
      }
    }

    if (isDocumentLegalEntity || isFactoringContract || isLegalEntity) {
      payload = {
        ...payload,
        indicative,
      }
    }

    if (!showRole) {
      delete payload.role
    }

    return payload
  }

  const errors = {
    ...(type?.trim() ? {} : { type: 'Не выбран вид лимита' }),
    ...(showCompanyName && !inn?.trim() ? { inn: 'Не выбран владелец лимита' } : {}),
    ...(showHoldings && !holdingId?.trim() ? { holdingId: 'Не выбрана группа компаний' } : {}),
    ...(products?.length > 0 ? {} : { products: 'Не выбран продукт' }),
    ...(showOriginals && originals?.length === 0 ? { originals: 'Не выбрано значение' } : {}),
    ...(limitAmount?.trim() ? {} : { limitAmount: 'Не указана сумма' }),
    ...(beginDate?.trim() ? {} : { beginDate: 'Не указана дата окончания действия' }),
    ...(endDate?.trim() ? {} : { endDate: 'Не указана дата начала действия' }),
    ...(confirmationDate?.trim() ? {} : { confirmationDate: 'Не указана дата подтверждения' }),
    ...(
      showSuppliers &&
      (suppliers?.length === 0 || (suppliers?.length === 1 && suppliers[0]?.inn === '' && suppliers[0]?.ogrn === ''))
        ? { suppliers: 'Не выбран поставщик' }
        : {}
    ),
    ...(
      showDebtors &&
      (debtors?.length === 0 || (debtors?.length === 1 && debtors[0]?.inn === '' && debtors[0]?.ogrn === ''))
        ? { debtors: 'Не выбран дебитор' }
        : {}
    ),
    ...(
      showContractPbIds &&
      (contractPbIds?.length === 0 || (contractPbIds?.length === 1 && contractPbIds[0]?.id === ''))
        ? { contractPbIds: 'Не выбран номер договора факторинга' }
        : {}
    ),
  }

  const isValid = Object.keys(errors)?.length === 0

  const onChangeLimitType = (e) => {
    const { value } = e?.target || {}
    const isIndicativeNull = [DOCUMENT_LEGAL_ENTITY, PROVISIONS].indexOf(value) > -1
    const isIndicativeFalse = [HOLDING, LEGAL_ENTITY].indexOf(value) > -1
    const isIndicativeTrue = !isIndicativeFalse && role === ROLES.DEBTOR
    const isNullRole = [PROVISIONS].indexOf(value) > -1
    const isDefaultProducts = [FACTORING_CONTRACT].indexOf(value) > -1
    setType(value)
    setCompanyName('')
    setInn('')
    setOgrn('')
    setHoldingId('')
    setHoldingName('')
    setIndicative(isIndicativeNull
      ? null
      : isIndicativeFalse
        ? false
        : isIndicativeTrue
          ? true
          : INDICATIVE_OPTIONS[0].value
    )
    setRole(isNullRole ? null : ROLE_OPTIONS[0].value)
    setProducts(isDefaultProducts ? [PRODUCTS_OPTIONS[0].value] : [])
    setOriginals([])
    setLimitAmount('')
    setBeginDate('')
    setEndDate('')
    setConfirmationDate('')
    setSuppliers([{ ...DEFAULT_SUPPLIER }])
    setDebtors([{ ...DEFAULT_DEBTOR }])
    setContractPbIds([{ ...DEFAULT_CONTRACT_PB_ID }])
  }

  const onChangeCompany = ({ INN, OGRN, displayName }) => {
    setInn(INN)
    setOgrn(OGRN)
    setCompanyName(displayName)
  }
  const onClearCompany = () => {
    setInn('')
    setCompanyName('')
  }

  const onChangeHolding = ({ id, name }) => {
    setHoldingId(id)
    setHoldingName(name)
  }
  const onClearHolding = () => {
    setHoldingId('')
    setHoldingName('')
  }

  const onChangeSuppliers = (payload, index) => setSuppliers(
    suppliers.map((item, idx) => index === idx
      ? Object.keys(payload).reduce((result, i) => {
          result[i] = payload[i]
          return result
        }, {})
      : item
    )
  )
  const onClearSuppliers = (index) => setSuppliers(
    suppliers.map((item, idx) => index === idx
      ? { inn: '', ogrn: '', displayName: '' }
      : item
    )
  )

  const onChangeDebtors = (payload, index) => setDebtors(
    debtors.map((item, idx) => index === idx
      ? Object.keys(payload).reduce((result, i) => {
          result[i] = payload[i]
          return result
        }, {})
      : item
    )
  )
  const onClearDebtors = (index) => setDebtors(
    debtors.map((item, idx) => index === idx
      ? { inn: '', ogrn: '', displayName: '' }
      : item
    )
  )

  const onChangeContractPbIds = (payload, index) => setContractPbIds(
    contractPbIds.map((item, idx) => index === idx ? payload : item)
  )
  const onClearContractPbIds = (index) => setContractPbIds(
    contractPbIds.map((item, idx) => index === idx ? { id: '', number: '' } : item)
  )

  const onChangeBeginDate = (e) => setBeginDate(e.target.value)
  const onChangeEndDate = (e) => setEndDate(e.target.value)
  const onChangeConfirmationDate = (e) => setConfirmationDate(e.target.value)
  const onChangeIndicative = (value) => setIndicative(value)
  const onChangeRole = (value) => {
    setRole(value)
    if (value === ROLES.DEBTOR && !isHolding) {
      onChangeIndicative(true)
    }
    if (isHolding || isLegalEntity) {
      onChangeIndicative(value === ROLES.DEBTOR)
    }
  }

  const onChangeProducts = (value) => setProducts(
    !!products.find(item => item === value)
      ? products.filter(item => item !== value)
      : isFactoringContract
        ? [value]
        : [...products, value]
  )

  const onChangeHasOriginals = (value) => setOriginals(
    !!originals.find(item => item === value)
      ? originals.filter(item => item !== value)
      : [...originals, value]
  )

  const onChangeLimitAmount = (e) => setLimitAmount(e.target.value)

  const onAddDebitor = () => setDebtors([...debtors, { 'inn': '', 'ogrn': '' }])
  const onAddSupplier = () => setSuppliers([...suppliers, { 'inn': '', 'ogrn': '' }])
  const onAddContractPbId = () => setContractPbIds([...contractPbIds, ''])

  const onRemoveDebitor = (index) => setDebtors(debtors.filter((_, idx) => index !== idx))
  const onRemoveSupplier = (index) => setSuppliers(suppliers.filter((_, idx) => index !== idx))
  const onRemoveContractPbId = (index) => setContractPbIds(contractPbIds.filter((_, idx) => index !== idx))

  const onSubmit = () => setSubmitted(submitted + 1)

  const onCreateFactoringLimit = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(createFactoringLimit(getPayload(), entityType, onSuccess))
    }
  }

  useEffect(() => {
    if (touched) {
      onCreateFactoringLimit()
    }
  // eslint-disable-next-line
  }, [touched, submitted])

  console.log('payload [CURRENT]:', getPayload())
  console.log('errors', errors)

  return (
    <Modal2 className={classes.addLimitForm} onClose={onClose}>
      <div className={classes.wrapper}>
        <H3>Добавить лимит</H3>
        <Form>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Select2
                  label='Вид лимита'
                  name='type'
                  options={[
                    {
                      value: '',
                      name: '',
                    },
                    ...limitTypes,
                  ]}
                  value={type}
                  onChange={onChangeLimitType}
                  error={touched && errors.type}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          {showCompanyName &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <TextFieldWithAutoComplete
                    classNames={{
                      container: 'filter-input',
                      input: 'filter-input__input',
                    }}
                    name='inn'
                    placeholder='Наименование компании'
                    label='Наименование компании'
                    value={companyName}
                    defaultValue={companyName}
                    onSelect={onChangeCompany}
                    onClear={onClearCompany}
                    payloadKeys={['INN', 'OGRN', 'displayName']}
                    meta={{
                      touched,
                      error: errors.inn,
                    }}
                    withoutLink
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {showHoldings &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <TextFieldWithAutoComplete
                    classNames={{
                      container: 'filter-input',
                      input: 'filter-input__input',
                    }}
                    name='id'
                    placeholder='Наименование группы компаний'
                    label='Наименование группы компаний'
                    value={holdingName}
                    defaultValue={holdingName}
                    onSelect={onChangeHolding}
                    onClear={onClearHolding}
                    payloadKeys={['id', 'name']}
                    request={Search.getFactoringHoldingSuggestions}
                    requestKey='name'
                    requestSubKeys={[]}
                    meta={{
                      touched,
                      error: errors.holdingId,
                    }}
                    withoutLink
                  />
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {showRole &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Label>Роль</Label>
                  {_ROLE_OPTIONS.map(({ value, label }, index) =>
                    <Radio
                      key={index}
                      id={`role-${value}`}
                      name='role'
                      label={label}
                      value={value}
                      checked={role === value}
                      onChange={() => onChangeRole(value)}
                    />
                  )}
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {showIndicative &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Label>Владелец лимита</Label>
                  {INDICATIVE_OPTIONS.map(({ value, label }, index) =>
                    <Radio
                      key={index}
                      id={`indicative-${value}`}
                      name='indicative'
                      label={label}
                      value={value}
                      checked={indicative === value}
                      onChange={() => onChangeIndicative(value)}
                      disabled={isHolding || isLegalEntity}
                    />
                  )}
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          <Fieldset>
            <RowGrid>
              <Col4>
                <InputText
                  type='date' 
                  name='beginDate'
                  label='Дата начала действия'
                  value={beginDate}
                  onChange={onChangeBeginDate}
                  error={touched && errors?.beginDate}
                />
              </Col4>
              <Col4>
                <InputText
                  type='date' 
                  name='endDate'
                  label='Дата окончания действия'
                  value={endDate}
                  onChange={onChangeEndDate}
                  error={touched && errors?.endDate}
                />
              </Col4>
              <Col4>
                <InputText
                  type='date' 
                  name='confirmationDate'
                  label='Дата подтверждения'
                  value={confirmationDate}
                  onChange={onChangeConfirmationDate}
                  error={touched && errors?.confirmationDate}
                />
              </Col4>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <InputText
                  type='text' 
                  name='limitAmount'
                  label='Сумма'
                  value={limitAmount}
                  onChange={onChangeLimitAmount}
                  error={touched && errors?.limitAmount}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
          <Fieldset>
            <RowGrid>
              <Col12>
                <Label>Продукт</Label>
                {PRODUCTS_OPTIONS.map(({ value, label }, index) =>
                  <Checkbox
                    key={index}
                    id={`products-${value}`}
                    name='products'
                    label={label}
                    value={value}
                    checked={products?.find(item => item === value)}
                    onChange={() => onChangeProducts(value)}
                    error={touched && errors?.products}
                  />
                )}
                {touched && errors?.products &&
                  <Error>{errors.products}</Error>
                }
              </Col12>
            </RowGrid>
          </Fieldset>
          {showOriginals &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Label>Документы</Label>
                  {_ORIGINALS_OPTIONS.map(({ value, label }, index) =>
                    <Checkbox
                      key={index}
                      id={`originals-${value}`}
                      name='originals'
                      label={label}
                      value={value}
                      checked={originals?.find(item => item === value)}
                      onChange={() => onChangeHasOriginals(value)}
                      error={touched && errors?.originals}
                    />
                  )}
                  {touched && errors?.originals &&
                    <Error>{errors.originals}</Error>
                  }
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {showSuppliers &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Label>Поставщик</Label>
                  {suppliers.map(({ displayName }, index) => {
                    const showRemoveButton = suppliers?.length > 1
                    const Column = showRemoveButton ? Col11 : Col12
                    return (
                      <RowGrid key={index}>
                        <Column> 
                          <TextFieldWithAutoComplete
                            classNames={{
                              container: 'filter-input',
                              input: 'filter-input__input',
                            }}
                            name='suppliers'
                            placeholder='Поставщик'
                            value={displayName}
                            defaultValue={displayName}
                            onSelect={(payload) => onChangeSuppliers(payload, index)}
                            onClear={() => onClearSuppliers(index)}
                            payloadKeys={['INN', 'OGRN', 'displayName']}
                            withoutLink
                            meta={{
                              touched,
                              error: errors.suppliers,
                            }}
                          />
                        </Column>
                        {showRemoveButton &&
                          <Col1>
                            <ButtonRemove
                              title='Удалить поставщика'
                              onClick={() => onRemoveSupplier(index)}
                            />
                          </Col1>
                        }
                      </RowGrid>
                    )
                  })}
                  <ButtonAdd
                    className={classes.addBtn}
                    onClick={onAddSupplier}
                  >
                    Добавить поставщика
                  </ButtonAdd>
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {showDebtors &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Label>Дебитор</Label>
                  {debtors.map(({ displayName }, index) => {
                    const showRemoveButton = debtors?.length > 1
                    const Column = showRemoveButton ? Col11 : Col12
                    return (
                      <RowGrid key={index}>
                        <Column>
                          <TextFieldWithAutoComplete
                            classNames={{
                              container: 'filter-input',
                              input: 'filter-input__input',
                            }}
                            name='debtors'
                            placeholder='Дебитор'
                            value={displayName}
                            defaultValue={displayName}
                            onSelect={(payload) => onChangeDebtors(payload, index)}
                            onClear={() => onClearDebtors(index)}
                            payloadKeys={['INN', 'OGRN', 'displayName']}
                            withoutLink
                            meta={{
                              touched,
                              error: errors.debtors,
                            }}
                          />
                        </Column>
                        {showRemoveButton &&
                          <Col1>
                            <ButtonRemove
                              title='Удалить дебитора'
                              onClick={() => onRemoveDebitor(index)}
                            />
                          </Col1>
                        }
                      </RowGrid>
                    )
                  })}
                  {showAddDebitorButton &&
                    <ButtonAdd
                      className={classes.addBtn}
                      onClick={onAddDebitor}
                    >
                      Добавить дебитора
                    </ButtonAdd>
                  }
                </Col12>
              </RowGrid>
            </Fieldset>
          }
          {showContractPbIds &&
            <Fieldset>
              <RowGrid>
                <Col12>
                  <Label>Номер договора факторинга</Label>
                  {contractPbIds.map((item, index) => {
                    const showRemoveButton = contractPbIds?.length > 1
                    const Column = showRemoveButton ? Col11 : Col12
                    return (
                      <RowGrid key={index}>
                        <Column>
                          <TextFieldWithAutoComplete
                            classNames={{
                              container: 'filter-input',
                              input: 'filter-input__input',
                            }}
                            name='contractPbIds'
                            placeholder='Номер договора факторинга'
                            value={item?.number || ''}
                            defaultValue={item?.number || ''}
                            onSelect={(payload) => onChangeContractPbIds(payload, index)}
                            onClear={() => onClearContractPbIds(index)}
                            payloadKeys={['id', 'number']}
                            request={Search.getFactoringContractSuggestions}
                            requestKey='number'
                            requestSubKeys={['displayName', 'supplierInn', 'startDate']}
                            withoutLink
                            meta={{
                              touched,
                              error: errors.contractPbIds,
                            }}
                          />
                        </Column>
                        {showRemoveButton &&
                          <Col1>
                            <ButtonRemove
                              title='Удалить номер договора факторинга'
                              onClick={() => onRemoveContractPbId(index)}
                            />
                          </Col1>
                        }
                      </RowGrid>
                    )
                  })}
                  {showAddContractButton &&
                    <ButtonAdd
                      className={classes.addBtn}
                      onClick={onAddContractPbId}
                    >
                      Добавить номер договора факторинга
                    </ButtonAdd>
                  }
                </Col12>
              </RowGrid>
            </Fieldset>
          }
        </Form>
        <ButtonWrapper>
          <Button
            onClick={onSubmit}
            disabled={touched && !isValid}
          >
            Создать
          </Button>
        </ButtonWrapper>
      </div>
    </Modal2>
  )
}