import React, { Fragment } from 'react'

const TextField = ({
  input: { 
    value, 
    ...inputProps
  }, 
  label, 
  disabled, 
  defaultValue
}) =>
  <Fragment>
    {label 
      ? <label>{label}</label>
      : null
    }
    <div className='task-form__input-wrap'>
      <input
        type="text"
        className='center'
        disabled={disabled}
        value={value || defaultValue}
        {...inputProps}
      />
    </div>
  </Fragment>

export default TextField