import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

import { removeCompanyClientLogin } from '../../../../redux/Company/actions'

import { ConfirmModal } from '../../../ConfirmModal'
import { Modal, UI } from '../../../../layout'
import AddClient from './AddClient'
import classnames from 'classnames'

const styles = {
  root: {
    position: 'relative',
  },
  table: UI.table,
  item: {
    width: '20%',
    display: 'inline-block',
    verticalAlign: 'top',
    '&:first-child': {
      width: '80%',
    },
    '& + &': {
      paddingLeft: '20px',
    },
  },
  title: UI.title,
  add: UI.addButton,
  remove: UI.removeButton,
}


const ClientInfo = ({
  classes,
  companyId,
  clientLogins,
  removeCompanyClientLogin,
}) => {
  const [username, setUsername] = useState('')
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const onOpenConfirmModal = (username) => {
    setShowConfirmModal(true)
    setUsername(username)
  }
  const onCloseConfirmModal = () => setShowConfirmModal(false)

  const onOpenModal = () => setShowLoginModal(true)
  const onCloseModal = () => setShowLoginModal(false)

  const onRemove = () => removeCompanyClientLogin({ companyId, username })

  const hasLogins = clientLogins && clientLogins.length

  return (
    <Fragment>
      <div className='row block-info'>
        <div className='col-12'>
          <div className='flex-row flex-row--clints'>
            <h1>Параметры клиента</h1>
            <div className='add__cliens'>
              <div className={classnames(classes.add, 'btn-form--ok')} onClick={onOpenModal}>Добавить нового пользователя</div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <div className={classes.title}>Учётные записи клиента</div>
          <table className={classes.table}>
            <thead>
              <tr>
                <td>Логин</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {hasLogins ? (
                <Fragment>
                  {clientLogins.map(item => 
                    <tr key={item}>
                      <td>{item}</td>
                      <td className='text-right'>
                        <div 
                          className={classes.remove}
                          onClick={() => onOpenConfirmModal(item)}
                        >
                          Удалить
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ) : (
                <tr className='no-hover'>
                  <td colSpan='2' className='text-mute'>
                    К данному клиенту не привязано ни одного логина
                  </td>
                </tr> 
              )}
            </tbody>
          </table>
        </div>
      </div>
      {showLoginModal && 
        <Modal width='482px' height='auto' onClose={onCloseModal}>
          <AddClient 
            companyId={companyId} 
            onCancel={onCloseModal} 
          />
        </Modal>
      }
      {showConfirmModal &&
        <ConfirmModal
          title={`Вы действительно хотите удалить логин ${username}?`}
          submitTitle='Удалить'
          onCancel={onCloseConfirmModal}
          onSubmit={onRemove}
        />
      }
    </Fragment>
  )
}

ClientInfo.propTypes = {
  companyId: PropTypes.string.isRequired,
  clientLogins: PropTypes.array.isRequired,
  removeCompanyClientLogin: PropTypes.func.isRequired,
}

const mapStateToProps = ({ Company }) => {
  return {
    clientLogins: (Company && Company.clientLogins && Company.clientLogins.clientLogins) || [],
  }
}

const dispatchMapToProps = (dispatch, ownProps) => {
  return {
    removeCompanyClientLogin: (payload) => {
      dispatch(removeCompanyClientLogin(payload))
    },
  }
}

export default connect(mapStateToProps, dispatchMapToProps)(injectSheet(styles)(ClientInfo))