import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    minHeight: 32,
    marginTop: 3,
    color: '#000',
    fontSize: 20,
    lineHeight: 1.6,
    fontWeight: 500,
    position: 'relative',
    '& span': {
      display: 'block',
      width: 10,
      height: 10,
      borderRadius: '50%',
      position: 'absolute',
      left: -20,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
})

export const Value = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>{children}</div>
  )
}