import * as actionTypes from './actionTypes'
import { Payments } from '../../services/api'
import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'

export const getRegisters = filters => async dispatch => {
  try {
    dispatch({ type: actionTypes.GET_REGISTERS })
    const {
      isSuccess,
      registers,
      message,
      needLogout
    } = await Payments.getRegisters(filters)

    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_REGISTERS_SUCCESS,
      data: registers
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_REGISTERS_ERROR })
  }
}
