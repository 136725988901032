import {
  getReportTasks as _getReportTasks,
  getReportTasksDetails as _getReportTasksDetails,
  getReportTasksTasks as _getReportTasksTasks,
} from '../../../services/api/reports/tasks'
import { setErrorContent } from '../../Error/actions'
import { logoutProcess } from '../../User/actions'
import * as actionTypes from './actionTypes'

export const getReportTasks = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.GET_REPORT_TASKS_PENDING,
      data: payload,
    })
    const { isSuccess, ...res } = await _getReportTasks(payload)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({
      type: actionTypes.GET_REPORT_TASKS_SUCCESS,
      data: res.result,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_REPORT_TASKS_ERROR })
  }
}

export const getReportTasksDetails = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.GET_REPORT_TASKS_DETAILS_PENDING,
      data: payload,
    })
    const { isSuccess, ...res } = await _getReportTasksDetails(payload)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({
      type: actionTypes.GET_REPORT_TASKS_DETAILS_SUCCESS,
      data: {
        result: res.result,
        payload,
      },
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_REPORT_TASKS_DETAILS_ERROR })
  }
}

export const getReportTasksClearDetails = () => async (dispatch) => 
  dispatch({ type: actionTypes.REPORT_TASKS_CLEAR_DETAILS })

export const getReportTasksTasks = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.GET_REPORT_TASKS_TASKS_PENDING,
      data: payload,
    })
    const { isSuccess, ...res } = await _getReportTasksTasks(payload)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({
      type: actionTypes.GET_REPORT_TASKS_TASKS_SUCCESS,
      data: res.result,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_REPORT_TASKS_TASKS_ERROR })
  }
}