import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { THEME_COLOR } from '../../../../config'

const useStyles = createUseStyles({
  button: {
    background: THEME_COLOR,
    padding: [17, 30],
    fontSize: 14,
    lineHeight: 1,
    border: 'none',
    borderRadius: 5,
    color: '#fff',
    fontWeight: 600,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    '&.inverse': {
      background: '#fff',
      border: [1, 'solid', THEME_COLOR],
      color: THEME_COLOR,
    },
    '&:disabled': {
      background: 'rgba(80, 78, 91, 0.4)',
      cursor: 'default',
    },
    '& + &': {
      marginLeft: 10,
    },
  },
})

export const Button = ({
  children,
  className,
  inverse,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <button
      className={classnames(classes.button, className, { inverse })}
      {...rest}
    >
      {children}
    </button>
  )
}