import React from 'react'
import { Route } from 'react-router-dom'
import { ModalDeal } from './../../components/Modals'
import Orders from './Orders'

const OrdersPage = (props) =>
  <>
    <Orders {...props} />
    <Route
      exact
      path='/orders/:orderId'
      component={ModalDeal}
    />
  </>

export default OrdersPage