import React from 'react'
import { createUseStyles } from 'react-jss'
// import Spinner from '../Spinner'
// import { Spinner } from 'reactstrap'
import Overlay from '../../Overlay'
import { Modal, UI } from '../../../layout'

const useStyles = createUseStyles({
  root: {
    padding: 20,
    position: 'relative',
  },
  title: {
    fontSize: 16,
    lineHeight: 1.25,
  },
  actions: {
    marginTop: 30,
    textAlign: 'right',
  },
  cancel: {
    ...UI.cancelButton,
    marginRight: 20,
  },
  submit: UI.addButton,
})

export const ModalConfirm = ({
  title,
  submitTitle,
  cancelTitle = 'Отменить',
  isSending, 
  onCancel, 
  onSubmit, 
}) => {
  const classes = useStyles()
  return isSending
    ? <Overlay />
    : <Modal width='auto' onClose={onCancel}>
        <div className={classes.title}>{title}</div>
        <div className={classes.actions}>
          <div className={classes.cancel} onClick={onCancel}>{cancelTitle}</div>
          <div className={classes.submit} disabled={isSending} onClick={onSubmit}>
            <span>{submitTitle}</span>
            {/* {isSending ?
              <Spinner color='success' />
              : <span>{submitTitle}</span>
            } */}
          </div>
        </div>
      </Modal>
}