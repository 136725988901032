import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import SchemaFormRatesItem from './SchemaFormRatesItem'
import { UI } from '../../../layout'

const styles = {
  root: {
    marginTop: 30,
    paddingTop: 20,
    borderTop: [1, 'solid', 'rgba(0, 0, 0, 0.1)'],
    position: 'relative',
  },
  title: {
    paddingBottom: 20,
    fontSize: 20,
    lineHeight: 1.2,
    '&.error': {
      color: 'red',
    },
  },
  add: {
    ...UI.addButton,
    width: '100%',
    marginTop: 20,
    '&.disabled': {
      background: '#b8b8b8',
    },
  },
}

export const SchemaFormRates = ({
  classes,
  values,
  title, 
  group,
  name,
  label,
  label2 = 'Cтавка',
  onChange,
  valueRegexp,
  disabled,
  errorFromAmount,
  errorRate,
}) => {
  const emptyItem = { 
    [name]: '', 
    rate: '', 
  }
  const onAdd = () => disabled ? {} : onChange(emptyItem, values.length)
  const onRemove = (index) => disabled ? {} : onChange(false, index)

  const hasValues = values && values.length > 0

  const error = errorFromAmount || errorRate
  
  return (
    <div className={classes.root}>
      <div className={classnames(classes.title, { error })}>{title}</div>
      {hasValues
        ? values.map((item, index) =>
            <SchemaFormRatesItem 
              key={index}
              values={item}
              name={name}
              label={label}
              label2={label2}
              group={group}
              onChange={(item) => onChange(item, index)}
              onRemove={() => onRemove(index)}
              valueRegexp={valueRegexp}
              disabled={disabled}
              errorFromAmount={errorFromAmount}
              errorRate={errorRate}
            />
          )
        : <div>Ставки расчета отсутствуют.</div>
      }
      <div
        className={classnames(classes.add, 'btn-form--ok', { disabled })}
        onClick={onAdd}
      >
        Добавить ставку расчета
      </div>
    </div>
  )
}

SchemaFormRates.propTypes = {
  values: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired, 
  group: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  valueRegexp: PropTypes.any,
}

export default injectSheet(styles)(SchemaFormRates)