import React from 'react'
import { createUseStyles } from 'react-jss'
import { Modal2, Fieldset } from '../../../../layout'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'

const useStyles = createUseStyles({
  addPaymentFormInner: {
    padding: [30, 0, 30, 30],
  },
  wrapper: {
    paddingRight: 30,
  },
  block: {
    '& + &': {
      marginTop: 24,
    },
  },
  string: {
    fontSize: 16,
    lineHeight: 24/16,
    '& + &': {
      marginTop: 8,
    },
  },
})

export const AddPaymentFormInner = ({
  payload,
  onCreateFactoringPayment,
  onCloseInnerModal,
  onCloseExternalModal,
}) => {
  const classes = useStyles()
  const onSubmit = () => onCreateFactoringPayment({
    ignoreDuplicates: true,
    onSuccess: () => {
      onCloseInnerModal()
      onCloseExternalModal()
    }
  })
  return (
    <Modal2
      className={classes.addPaymentFormInner}
      onClose={onCloseInnerModal}
    >
      <div className={classes.wrapper}>
        <H3>Добавить платёж с похожими параметрами (принудительно)</H3>
        <Form>
          <Fieldset>
            <div className={classes.block}>
              <div className={classes.string}>Найдено совпадение с уже имеющейся записью по полям:</div>
              <div className={classes.string}>- Номер платежа</div>
              <div className={classes.string}>- Сумма</div>
              <div className={classes.string}>- Дата платежа</div>
            </div>
            <div className={classes.block}>
              <div className={classes.string}>Необходимо проверить правильность ввода: externalId - {payload.externalId}</div>
            </div>
            <div className={classes.block}>
              <div className={classes.string}>Если externalId - верный, то создайте платеж через кнопку "Создать".</div>
            </div>
            <div className={classes.block}>
              <div className={classes.string}>Если externalId - неверный, то отмените платеж через кнопку "Отменить", измените externalId и создайте платеж.</div>
            </div>
          </Fieldset>
        </Form>
        <ButtonWrapper>
          <Button onClick={onCloseInnerModal} inverse>Отменить</Button>
          <Button onClick={onSubmit}>Создать</Button>
        </ButtonWrapper>
      </div>
    </Modal2>
  )
}