import * as actionTypes from './actionTypes'
import { 
  getCompanyAgentInfo,
  getCompanyClientLogins,
  getCompanyBgAgentBinding,
  getCompanyWorkGroups,
} from './actions'

const company = store => next => (action) => {
  if (action.error && action.error.status) {
    switch (action.error.status) {
      case 403:
        // do something
        break
      case 404:
        // do something
        break
      default:
        // do something
    }
  }

  const result = next(action) || {}

  const meta = result.meta || {}

  const companyId = meta.companyId || meta.meta
  // const relationId = meta.relationId

  switch (action.type) {
    case actionTypes.COMPANY_ADD_AGENT_LOGIN_SUCCESS:
    case actionTypes.COMPANY_REMOVE_AGENT_LOGIN_SUCCESS:
    case actionTypes.COMPANY_BANK_MANAGER_ADD_SUCCESS:
    case actionTypes.COMPANY_BANK_MANAGER_REMOVE_SUCCESS:
    case actionTypes.COMPANY_AGENT_BILLING_SIGNER_ADD_SUCCESS:
    case actionTypes.COMPANY_AGENT_BILLING_SIGNER_REMOVE_SUCCESS:
    case actionTypes.COMPANY_AGENT_BILLING_CONTRACT_ADD_SUCCESS:
    case actionTypes.COMPANY_AGENT_BILLING_CONTRACT_REMOVE_SUCCESS:
    case actionTypes.COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_ADD_SUCCESS:
    case actionTypes.COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_REMOVE_SUCCESS:
    case actionTypes.COMPANY_AGENT_BILLING_CONTRACT_SCHEMA_EDIT_SUCCESS:
    case actionTypes.COMPANY_AGENT_PERSON_ADD_SUCCESS:
    case actionTypes.COMPANY_AGENT_BILLING_ADD_BASE_SCHEMA_SUCCESS:
      store.dispatch(getCompanyAgentInfo(companyId))
      break
    case actionTypes.COMPANY_ADD_CLIENT_LOGIN_SUCCESS:
    case actionTypes.COMPANY_REMOVE_CLIENT_LOGIN_SUCCESS:
      store.dispatch(getCompanyClientLogins(companyId))
      break

    // for PEAR-1775
    // case actionTypes.COMPANY_AGENT_BILLING_SIGNER_REMOVE_SUCCESS:
    //   store.dispatch(getCompanyAgentInfo(companyId, relationId))
    //   break

    case actionTypes.COMPANY_UPDATE_BG_AGENT_BINDING_SUCCESS:
      store.dispatch(getCompanyBgAgentBinding(companyId))
      break
    case actionTypes.COMPANY_UPDATE_WORK_GROUPS_SUCCESS:
      store.dispatch(getCompanyWorkGroups(companyId))
      break

    default:
      // do something
  }

  return result
}

export default company