import React from 'react'
import { createUseStyles } from 'react-jss'
import { SwitchSlider as SwitchSliderMain } from '../../../../../../layout'

const useStyles = createUseStyles({
  switchSlider: {
    '& + &': {
      marginTop: 10,
    },
  },
})

export const SwitchSlider = (props) => {
  const classes = useStyles()
  return (
    <SwitchSliderMain className={classes.switchSlider} {...props} />
  )
}