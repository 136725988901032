import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getNextProcessTask } from '../../../../services/api/task'
import { setErrorContent } from '../../../../redux/Error/actions'
import { insertTask, setActiveTask } from '../../../../redux/Tasks/actions'
import Overlay from '../../../../components/Overlay'

class CamundaAddTask extends Component {
  state = { 
    loading: false, 
  }

  static propTypes = {
    activeDefinitionKey: PropTypes.string,
    title: PropTypes.string,
    onCloseModal: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  static getFormScript(processDefinitionKey) {
    return `
      ngapp.init($('#create-task')[0], '#process_definition_key#', function (CamSDK) {
        var camClient = new CamSDK.Client({
          mock: false,
          apiUri: '/camunda/api/engine',
        });
        var processService = new camClient.resource('process-definition');
        // var $container = $('.start-form-section').parent();
    
        processService.getByKey('#process_definition_key#', function (err, def) {
          if (err) {
            setErrorNotification(err.message);
            setTimeout(function () {
              clearErrorNotification();
            }, 3000);
            return;
          }
    
          processService.startForm({id: def.id}, function (err, taskFormInfo) {
            if (err) {
              setErrorNotification(err.message);
              setTimeout(function () {
                clearErrorNotification();
              }, 3000);
              return;
            }
    
            var url = taskFormInfo.key.replace('embedded:app:', taskFormInfo.contextPath + '/');
    
            new CamSDK.Form({
              client: camClient,
              formUrl: url,
              processDefinitionId: def.id,
              containerElement: $('#camunda'),
              done: function (err, camForm) {
                if (err) {
                  console.log('all', err);
                  throw err;
                }
    
                camForm.on('submit-success', function () {
                  removePreloader();
                });
    
                camForm.on('submit-error', function (evt, res) {
                  console.log('submit-error', res);
                  removePreloader();
                  setErrorNotification(res[0]);
                  setTimeout(function () {
                    clearErrorNotification();
                  }, 3000);
                });
    
                $('#camunda_submit').click(function (e) {
                  e.preventDefault();
                  setPreloader();
                  camForm.submit(function (err, data) {


                    if (err) {
                      removePreloader();
                      var $scope = angular.element('#create-task form').scope();
                      if ($scope.$$camForm.$valid) {
                        setErrorNotification(err.message);
                        setTimeout(function () {
                          clearErrorNotification();
                        }, 3000);
                      } else {
                        setErrorNotification(err);
                        setTimeout(function () {
                          clearErrorNotification();
                        }, 3000);
                      }
                      if (window.onError && err.message) {
                        window.onError(err.message)
                      }
                    } else {
                      window['submitSuccess-#process_definition_key#'] && window['submitSuccess-#process_definition_key#'](data);
                    }

                  });
                });
              }
            });
          });
        });
        
        
        function setErrorNotification(content) {
          var innerHtml =
            '<span class="notification__text">' +
            content +
            '</span>' +
            '<button type="button" class="notification__reload-link">' +
            '    <i class="icon icon-close-s" />' +
            '</button>'
            .replace(/#content#/g, content);
          
          var elem = $(document.createElement('div'))
            .addClass('notification')
            .attr('id', 'error-note')
            .html(innerHtml);
            
          $('#root').append(elem);
          
          $('.notification__reload-link').click(function() {
            clearErrorNotification();
          });
        }
        
        function clearErrorNotification() {
          $('#error-note').remove();
        }
        
        function setPreloader() {
          var innerHtml = 
            '<svg viewBox="-2000 -1000 4000 2000">' +
              '<path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z" />' +
              '<use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px" />' +
            '</svg>';
            
          var elem = $(document.createElement('div'))
            .addClass('preloader preloader--big')
            .attr('id', 'preloader')
            .html(innerHtml);
            
          $('#root').append(elem);
        }
        
        function removePreloader() {
          $('#preloader').remove();
        }
      });
    `
      .replace(/#process_definition_key#/g, processDefinitionKey)
  }

  componentDidMount() {
    const { isWizzard, processInstanceId } = this.props
    if (isWizzard && processInstanceId) {
      this.setState({ processInstanceId }, this.waitNextTask)
    } else {
      this.initNgApp()
    }
  }

  initNgApp() {
    const { activeDefinitionKey, onCloseModal } = this.props

    // window[`submitSuccess-${activeDefinitionKey}`] = this.onFormSubmitSuccess.bind(this)
    // return // TODO: remove before commit

    if (typeof activeDefinitionKey === 'undefined') {
      onCloseModal()
    }

    window[`submitSuccess-${activeDefinitionKey}`] = this.onFormSubmitSuccess.bind(this)
    window.onError = this.onError.bind(this)

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.innerHTML = CamundaAddTask.getFormScript(activeDefinitionKey)

    const taskDetailBlock = document.querySelector('#create-task')
    taskDetailBlock.appendChild(script)
  }

  componentWillUnmount() {
    const { activeDefinitionKey } = this.props
    delete window[`submitSuccess-${activeDefinitionKey}`]
    delete window.onError
  }

  onFormSubmitSuccess(processInstance = {}) {
    const { history, onCloseModal, process } = this.props
    // onCloseModal && onCloseModal()
    // history.push('/tasks')
    // return
    if (process.isWizzard && processInstance.id) {
      this.setState({ processInstanceId: processInstance.id }, this.waitNextTask)
    } else {
      onCloseModal && onCloseModal()
      history.push('/tasks')
    }
  }

  waitNextTask = async (iteration = 0) => {
    const { 
      dispatch, 
      settings, 
      history, 
      //onCloseModal, 
    } = this.props
    this.setState({ loading: true })
    const nextTask = await this.getNextTask()
    if (nextTask) {
      dispatch(insertTask(nextTask))

      dispatch(setActiveTask(nextTask.id))
      this.setState({ loading: false })
      //onCloseModal && onCloseModal()
      history.push(`/tasks/${nextTask.id}`, {
        title: nextTask.title
      })
    } else {
      if ((iteration * settings.poolingInterval) < settings.poolingMaxTime && (nextTask === undefined)) {
        setTimeout(() => { 
          this.waitNextTask(iteration + 1) }, 
        settings.poolingInterval * 1000)
      } else {
        this.setState({ 
          loading: false, 
          hasNotNextTask: true, 
        })
      }
    }
  }

  getNextTask = async () => {
    const { processInstanceId } = this.state
    try {
      const { isSuccess, wizzardNoNextText, ...resp } = await getNextProcessTask(processInstanceId)
      if (!isSuccess) {
        return null
      }
      if (wizzardNoNextText) {
        this.setState({ wizzardNoNextText })
      }
      return resp.nextTask
    } catch (err) {
      this.onError(err)
      return null
    }
  }

  onStartProcess = (e) => {
    const { activeDefinitionKey } = this.props
    if (!e.defaultPrevented) {
      window[`submitSuccess-${activeDefinitionKey}`] && window[`submitSuccess-${activeDefinitionKey}`](Date.now())
    }
  }

  onError = (error) => this.props.dispatch(setErrorContent(error))

  render() {
    const { title, onCloseModal } = this.props
    const { loading, hasNotNextTask, wizzardNoNextText } = this.state

    return [
      <div key={0} className='modal-content__header'>
        <div>
          <div className='modal-content__title modal-content__title--task'>
            <span className='task-title' dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </div>
      </div>,
      <div key={1} className='modal-content__body modal-content__body--has-padding' id='create-task'>
        {loading && !hasNotNextTask 
          ? <Overlay /> 
          : !hasNotNextTask
            ? <div id='camunda' >
                <div className='loading-indicator'>Подождите, идет загрузка...</div>
              </div> 
            : <div className='loading-indicator'>{wizzardNoNextText  || 'Нет следующей задачи'}</div>
        }
      </div>,
      <div key={2} className='modal-content__footer'>
        {!hasNotNextTask 
          ? <>
              <button className='btn-form btn-form--none' type='button' onClick={onCloseModal}>Отменить</button>
              <button  className='btn-form btn-form--ok' type='button' id='camunda_submit' onClick={this.onStartProcess}>Разместить заявку</button>
            </> 
          : <button className='btn-form btn-form--ok' type='button' onClick={onCloseModal}>Закрыть</button>
          }
      </div>
    ]
  }
}

const mapStateToProps = ({ Tasks, User, Settings }, ownProps) => {
  const title = User.processDefinitionKeys.reduce((result, item) => {
    if (item.process_definition_key === ownProps.activeDefinitionKey) {
      return item.process_name
    }
    return result
  }, 'Создание')
  const process = (Settings.process_definition_keys || []).find(item => item.process_definition_key === ownProps.activeDefinitionKey) || {}
  return {
    title,
    settings: Settings.nextTask || {},
    process
  }
}

export default withRouter(connect(mapStateToProps)(CamundaAddTask))