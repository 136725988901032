import React from 'react'
import { Modal } from '../index'

const Documents = ({ 
  title, 
  documents, 
  className,
  emailToken,
  orderId,
  children,
}) => documents?.length > 0
  ? <Modal.Block
      iconName='document'
      title={title}
      emailToken={emailToken}
      orderId={orderId}
    >
      <Modal.Content className={className}>
        {children}
        {documents.map((item, index) =>
          <Modal.Item key={index} {...item} />
        )}
        {/* <Modal.DownloadAllButton file={{ name: 'Имя файла.doc', url: '#' }} /> */}
      </Modal.Content>
    </Modal.Block>
  : null

export default Documents