import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    width: 150,
    textAlign: 'left',
  },
}

const Td2 = ({
  classes,
  children,
}) => <td className={classes.root}>{children}</td>
                
export default injectSheet(styles)(Td2)