import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Col, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyDocumentsInfo } from '../../../../redux/Company/actions'
import { getFile } from '../../../../redux/Files/actions'
import { InputText, UI } from '../../../../layout'
import Overlay from '../../../Overlay'
import { formattedDate, YYYYMMDD, formattedDateTime } from '../../../../utils'
import { THEME_COLOR } from '../../../../config'

const useStyles = createUseStyles({
  document: {
    '& .load-download a': {
      color: THEME_COLOR,
    },
  },
  downloadAll: {
    padding: [16, 16, 24, 16],
    textAlign: 'right',
    '& span': {
      color: THEME_COLOR,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  button: {
    ...UI.addButton,
    width: '100%',
    height: 40,
    marginTop: 22,
    fontSize: 14,
    lineHeight: 26/14,
    padding: [7, 20],
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
  fileName: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  date: {
    marginLeft: 20,
    color: '#727272',
    fontSize: 15,
    lineHeight: 1.5,
    fontWeight: 500,
    display: 'inline-block',
    verticalAlign: 'top',
  },
})

export const Document =({
  document: {
    name,
    files = [],
  },
}) => {
  const classes = useStyles()
  return (
    <div className={classes.document}>
      <div className='block_head'>{name}</div>
      <div className='row'>
        {files.map(({ url, name, isSigned, signUrl, changed }, index) =>
          <div className='col-12' key={`${name}-${index}`}>
            <div className={classnames('load', { 'load__signed': signUrl })}>
              <div className={classes.fileName}>{name}</div>
              {changed &&
                <div className={classes.date}>{formattedDateTime(changed)}</div>
              }
              <div className='load-download'>
                <a
                  href={url}
                  target='_blank'
                  download={isSigned ? name.replace('.sig', '') : name}
                  rel='noopener noreferrer'
                >
                  Оригинал
                </a>
                {isSigned &&
                  <a
                    href={signUrl}
                    target='_blank'
                    download={name}
                    rel='noopener noreferrer'
                  >
                    Подписанный
                  </a>
                }
              </div>
            </div>
          </div>
        )}
      </div>
      <br/>
    </div>
  )
}

const getDateInclusive = (date) => {
  const result = new Date(date)
  result.setHours(23)
  result.setMinutes(59)
  result.setSeconds(59)
  result.setMilliseconds(999)
  return result
}

export const Documents = ({
  companyId,
  displayName,
  INN,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const now = new Date()
  const currentDate = YYYYMMDD(now)
  // const firstDayOfMonth = YYYYMMDD(new Date(now.getFullYear(), now.getMonth(), 1))

  const companyNameWithINN = `${displayName} (ИНН: ${INN})`

  const {
    documents = [],
    fetching,
  } = useSelector(state => state?.Company?.documentsInfo)

  const {
    REACT_APP_CAN_DOWNLOAD_ARCHIVAL_DOCUMENTS,
  } = useSelector(state => state?.Environment?.environment)

  const CAN_DOWNLOAD_ARCHIVAL_DOCUMENTS = REACT_APP_CAN_DOWNLOAD_ARCHIVAL_DOCUMENTS === 'true'

  // const [dateFrom, setDateFrom] = useState(firstDayOfMonth)
  const [dateTo, setDateTo] = useState(currentDate)

  useEffect(() => {
    dispatch(getCompanyDocumentsInfo(companyId))
  }, [companyId, dispatch])

  // const onChangeDateFrom = (e) => setDateFrom(e.target.value)
  const onChangeDateTo = (e) => setDateTo(e.target.value)

  const onGetAllFiles = () => {
    // const url = `/docapi/contragents/${companyId}/zip`
    const url = `/download/zip/generate/contragents/${companyId}/`
    const name = `${companyNameWithINN} - все документы.zip`
    const type = 'application/zip'
    dispatch(getFile({ url, name, type }))
  }

  const onGetArchiveZip = () => {
    const baseUrl = `/docapi/zip/betweenDates/contragents/${companyId}`
    const url = `${baseUrl}?dateFrom=${new Date('1970-01-01').toISOString()}&dateTo=${new Date(getDateInclusive(dateTo)).toISOString()}`
    const name = `Архивные документы до ${formattedDate(dateTo)} (включительно) по ${companyNameWithINN}.zip`
    const type = 'application/zip'
    const errorMessage = `Документы, загруженные до ${formattedDate(dateTo)} (включительно), для ${companyNameWithINN} отсутствуют`
    dispatch(getFile({ url, name, type, errorMessage }))
  }

  return fetching
    ? <Overlay />
    : <div className='block-info'>
        {documents?.length > 0
          ? <div className={classes.downloadAll}>
              <span onClick={onGetAllFiles}>Скачать все</span>
            </div>
          : null
        }
        {documents.map((document, index) =>
          <Document key={index} document={document}/>
        )}
        {CAN_DOWNLOAD_ARCHIVAL_DOCUMENTS &&
          <div className={classes.document}>
            <div className='block_head'>Архивные документы</div>
            <div className='row'>
              <Col>
                <Row>
                  {/* <div className='col-6'>
                    <InputText
                      type='date'
                      name='dateFrom'
                      label='Дата с'
                      value={dateFrom}
                      maxDate={new Date(dateTo)}
                      onChange={onChangeDateFrom}
                    />
                  </div> */}
                  <div className='col-6'>
                    <InputText
                      type='date'
                      name='dateTo'
                      label='Получить документы до указанной даты (включительно)'
                      value={dateTo}
                      // minDate={new Date(dateFrom)}
                      onChange={onChangeDateTo}
                    />
                  </div>
                  <div className='col-6'>
                    <div
                      className={classes.button}
                      onClick={onGetArchiveZip}
                    >
                      Скачать архивные документы
                    </div>
                  </div>
                </Row>
              </Col>
            </div>
          </div>
        }
      </div>
}