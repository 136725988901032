import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Icon } from '../../../../layout'
import { IS_COCONUT_FACTORING } from '../../../../config'

const useStyles = createUseStyles({
  buttonRemove: {
    width: 40,
    height: IS_COCONUT_FACTORING ? 56 : 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& > svg': {
      width: 16,
      height: 16,
      display: 'block',
      fill: '#4a4a4a',
    },
  },
})

export const ButtonRemove = ({ className, ...rest }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.buttonRemove, className)} {...rest}>
      <Icon iconName='close2' />
    </div>
  )
}