import React, { useState, useRef } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Icon } from '../../../../../layout'
import { useOutsideClick } from '../../../../../hooks'
import { getFilteredItems } from '../utils'
import { GET_FILTERS } from '../config'

const useStyles = createUseStyles({
  root: {
    minWidth: 220,
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: 10,
    },
    // '&:nth-child(1)': {
    //   width: 460,
    // },
    // '&:nth-child(2)': {
    //   width: 280,
    // },
  },
  block: {
    padding: [7, 36, 7, 15],
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 600,
    border: [1, 'solid', '#eee'],
    borderRadius: 12,
    cursor: 'pointer',
    position: 'relative',
    '&.open': {
      '& svg': {
        transform: 'translateY(-50%) rotate(-180deg)',
      },
    },
    '& svg': {
      width: 18,
      height: 18,
      display: 'block',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 7,
      transition: 'transform 300ms ease',
    },
  },
  list: {
    background: '#fff',
    border: [1, 'solid', '#eee'],
    boxShadow: [0, 8, 24, 0, 'rgba(0,53,136,.15)'],
    borderRadius: 12,
  },
  item: {
    padding: [7, 15],
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 600,
    cursor: 'pointer',
    transition: 'background 300ms ease',
    position: 'relative',
    // '&.active': {
    //   background: '#C9EAE7',
    // },
    '&:first-child': {
      borderRadius: [12, 12, 0, 0],
    },
    '&:last-child': {
      borderRadius: [0, 0, 12, 12],
    },
    '&:hover': {
      background: '#eaecf5',
    },
    '& span': {
      color: '#969aa3',
    },
    '& svg': {
      width: 24,
      height: 24,
      display: 'block',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 8,
    }
  },
})

const Filter = ({
  placeholder,
  list,
  options,
  filters,
  index,
  onFilter,
}) => {
  const classes = useStyles()
  const filterRef = useRef()

  const [open, setOpen] = useState(false)

  const onOpen = () => setOpen(!open)
  const onClose = () => setOpen(false)

  const onSelect = (key, index) => {
    onFilter(key, index)
    onOpen(index)
  }

  useOutsideClick(filterRef, onClose)

  const getSelectedValues = () => filters[index].reduce((result, item, idx) => {
    const { value } = GET_FILTERS(list)[index]?.options.find(({ key }) => key === item)
    const divider = idx === 0 ? '' : ', '
    result = `${result}${divider}${value}`
    return result
  }, '')

  return (
    <div className={classes.root} ref={filterRef}>
      <div className={classnames(classes.block, { open })} onClick={onOpen}>
        {getSelectedValues()?.length > 0
          ? getSelectedValues()
          : placeholder
        }
        <Icon iconName='filter-arrow-bottom' />
      </div>
      {open &&
        <div className={classes.list}>
          {options.map(({ key, value }, idx) => {
            const active = filters[index].indexOf(key) > -1
            const _filters = [...filters]
            _filters[index] = [key]
            const ordersByKey = getFilteredItems(list, _filters)
            const count = ordersByKey.length
            return (
              <div
                key={idx}
                className={classnames(classes.item, { active })}
                onClick={() => onSelect(key, index)}
              >
                {value} <span> · {count}</span>
                {active && <Icon iconName='check' />}
              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

export default Filter