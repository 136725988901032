import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Icon } from './../../layout'
import { THEME_COLOR } from '../../config'

const styles = {
  dropdown: {
    background: '#fff',
    width: 'auto',
    height: 40,
    border: [1, 'solid', '#ddd'],
    borderRadius: 16,
    position: 'relative',
  },
  toggle: {
    background: 'transparent',
    width: '100%',
    height: 'inherit',
    padding: [0, 30, 0, 10],
    color: '#151b3d',
    border: 'none',
    borderRadius: 'inherit',
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:after': {
      display: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  icon: {
    width: 16,
    height: 16,
    position: 'absolute',
    top: '50%',
    right: 8,
    transform: 'translateY(-50%)',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  default: {
    color: '#757575',
  },
  menu: {
    padding: 0,
    border: 'none',
    width: 'auto',
    borderRadius: 0,
    boxShadow: [0, 2, 10, 'rgba(0, 0, 0, 0.2)'],
    overflowY: 'auto',
    maxHeight: 300,
  },
  item: {
    padding: [5, 10, 6],
    fontSize: 14,
    lineHeight: 1.7,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    '&:hover': {
      background: THEME_COLOR,
      color: '#fff',
      cursor: 'pointer',
    },
    '&:not(.hide-active)': {
      '&.active': {
        background: '#A3ABB1',
        color: '#fff',
      },
    },
    '&.group': {
      fontWeight: 'bold',
      '&:hover': {
        cursor: 'default',
        background: 'transparent',
      },
      '&:active': {
        color: '#16181b',
      },
    },
    '&.group-item': {
      paddingLeft: 25,
    },
  },
}

class Dropdown extends Component {
  static propsTypes = {
    defaultActive: PropTypes.number,
    name: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    onSelectItem: PropTypes.func.isRequired,
    toggleClassName: PropTypes.string,
    defaultText: PropTypes.string,
    disabled: PropTypes.bool,
    hideDefaultItem: PropTypes.bool,
  }
  static defaultProps = {
    defaultActive: 0,
    list: [],
    defaultText: '',
    hideDefaultItem: false,
  }

  state = {
    isOpen: false,
    activeIndex: this.props.defaultActive,
    mouseInMenu: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.hasReset !== prevProps.hasReset) {
      this.setState({ activeIndex: this.props.defaultActive })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { activeIndex: stateActive } = this.state
    const { defaultActive: propsActive } = nextProps
    if (stateActive !== propsActive && propsActive) {
      this.setState({ activeIndex: propsActive })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onOutsideClick, false)
  }

  onToggleDropdown = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
      document.removeEventListener('click', this.onOutsideClick)
    } else {
      this.setState({ isOpen: true })
      document.addEventListener('click', this.onOutsideClick)
    }
  }

  onSelectItem = index => {
    const { name, list, onSelectItem, isChart, handleSelectItem } = this.props

    this.setState({
      activeIndex: index,
      isOpen: false,
    })

    document.removeEventListener('click', this.onOutsideClick)

    if (isChart) {
      if (handleSelectItem) {
        handleSelectItem(list[index], index)
      }
    }
    if (onSelectItem) {
      onSelectItem(name, list[index].id, index)
    }
  }

  onOutsideClick = ({ target }) => {
    if (this.dropdown && this.dropdown.contains(target)) {
      return
    }
    this.onToggleDropdown()
  }

  onMouseEnterMenu = () => this.setState({ mouseInMenu: true })
  onMouseLeaveMenu = () => this.setState({ mouseInMenu: false })

  renderToggleText() {
    const { activeIndex } = this.state
    const { list, defaultText, hideDefaultItem, classes } = this.props

    if (hideDefaultItem) {
      if (activeIndex === 0) {
        return <span className={classes.default}>{defaultText}</span>
      }
      return list[activeIndex].name
    }

    if (defaultText) {
      return `${defaultText} ${list[activeIndex].name.toLowerCase()}`
    }

    if (activeIndex === 0) {
      return (
        <span className={classes.default}>
          {list[activeIndex] ? list[activeIndex].name : ''}
        </span>
      )
    }

    return list[activeIndex] ? list[activeIndex].name : ''
  }

  render() {
    const { isOpen, activeIndex, mouseInMenu } = this.state
    const {
      list,
      toggleClassName,
      disabled,
      hideDefaultItem,
      className,
      classes,
    } = this.props

    return (
      <div
        className={classnames(classes.dropdown, className, {
          show: isOpen,
          disabled,
        })}
        ref={node => this.dropdown = node}
      >
        <button
          onClick={this.onToggleDropdown}
          className={classnames(classes.toggle, toggleClassName)}
          type="button"
          data-toggle="dropdown"
        >
          {this.renderToggleText()}
          <div className={classes.icon}>
            <Icon iconName="dropdown-arrow" />
          </div>
        </button>
        <div
          className={classnames('dropdown-menu', classes.menu, {
            show: isOpen,
            'hide-active': mouseInMenu,
          })}
          onMouseEnter={this.onMouseEnterMenu}
          onMouseLeave={this.onMouseLeaveMenu}
        >
          {list.map((item, index) => {
            if (hideDefaultItem && index === 0) {
              return null
            }
            if (item.type === 'group-name') {
              return (
                <span
                  key={item.name}
                  className={classnames('dropdown-item', classes.item, 'group')}
                >
                  {item.name}
                </span>
              )
            }
            return (
              <span
                key={index}
                onClick={this.onSelectItem.bind(this, index)}
                className={classnames('dropdown-item', classes.item, {
                  active: activeIndex === index,
                  'group-item': item.type === 'group-item',
                })}
              >
                {item.name}
              </span>
            )
          })}
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(Dropdown)
