import React, { useMemo, useState } from 'react'
import { taskReportStyles } from './styles'
import { TaskDetails } from './TaskDetails'
import { TasksGraphicItem } from './TasksGraphicItem'

export const TasksGraphic = taskReportStyles(({
  classes,
  data,
  process,
  dateFrom,
  dateTo,
  groups,
  onCancel,
}) => {
  const [details, setDetails] = useState()

  const maxCount = useMemo(
    () => Math.max(...data.map(({ count }) => count || 0)),
    [data]
  )

  const openDetails = (value) => setDetails(value)
  const onCloseDetails = () => setDetails()
  const onClick = () => {
    onCloseDetails()
    onCancel()
  }

  return (
    <ul className={classes.list}>
      {data.map((item, index) =>
        <TasksGraphicItem
          key={`${item.processDefinitionKey}-${item.taskDefinitionKey}`}
          data={item}
          index={index}
          openDetails={openDetails}
          dataLength={data.length}
          maxCount={maxCount}
        />
      )}
      {details &&
        <TaskDetails
          details={details}
          onClose={onCloseDetails}
          process={process}
          dateFrom={dateFrom}
          dateTo={dateTo}
          groups={groups}
          onClick={onClick}
        />
      }
    </ul>
  )
})