import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'
import Skeleton from '../../components/Card/Skeleton'
import { useSelector } from 'react-redux'

const style = {
  root: {
    width: 264,
    minHeight: 200,
    marginTop: 20,
    marginRight: 20,
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
  wrapper: {
    background: '#fff',
    width: 'inherit',
    minHeight: 'inherit',
    padding: [76, 20, 40, 20],
    borderRadius: 16,
    textAlign: 'left',
    display: 'block',
    position: 'relative',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    transition: 'border 300ms ease',
    '&:hover': {
      borderColor: '#EAEAEA',
      boxShadow: [0, 4, 12, 'rgba(0, 0, 0, 0.08)'],
      textDecoration: 'none',
    },
  },
  title: {
    color: '#151B3D',
    fontSize: 15,
    lineHeight: 1.1,
    fontWeight: 'bold',
    display: 'block',
    position: 'absolute',
    top: 15,
    left: 20,
    right: 20,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  executionPercent: {
    background: '#EAECF5',
    width: '100%',
    height: 6,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 4,
    display: 'block',
    '& > span': {
      background: '#333',
      height: 'inherit',
      borderRadius: [4, 0, 0, 4],
      display: 'inline-block',
      verticalAlign: 'top',
      position: 'relative',
      '& > i': {
        background: '#fff',
        padding: [0, 6],
        position: 'absolute',
        top: -7,
        right: 0,
        display: 'inline-block',
        verticalAlign: 'top',
        border: [1.5, 'solid', '#333'],
        fontSize: 11,
        lineHeight: 1.6,
        borderRadius: 10,
      },
    },
  },
  principal: {
    maxWidth: '100%',
    marginTop: 10,
    color: '#151B3D',
    fontSize: 14,
    lineHeight: 1.7,
    fontWeight: 500,
    display: 'inline-block',
    verticalAlign: 'top',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  status: {
    padding: [1, 6],
    fontSize: 11,
    lineHeight: 1.2,
    letterSpacing: '0.01em',
    fontWeight: 600,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    position: 'absolute',
    left: 20,
    bottom: 15,
    // '&.new': {
    //   color: '#1F7FDE',
    //   borderColor: '#1F7FDE',
    // },
    // '&.inprogress': {
    //   color: '#EB9A42',
    //   borderColor: '#EB9A42',
    // },
    // '&.executed': {
    //   color: '#22C7B5',
    //   borderColor: '#22C7B5',
    // },
    // '&.lost': {
    //   color: '#F93433',
    //   borderColor: '#F93433',
    // },
  },
  minor: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1,
    marginBottom: 9,
    color: '#9b9b9b',
    minHeight: 14,
  },
  footer: {
    color: '#9b9b9b',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.67,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  amount: {
    height: 17,
    marginBottom: 12,
    color: '#000',
    fontSize: 15,
    lineHeight: 1.15,
    fontWeight: 500,
    overflow: 'hidden',
  },
}

const Order = ({
  classes,
  id,
  title, 
  items,
  isFetching,
  entityUrl,
}) => {
  const {
    REACT_APP_PROGRESS_BAR_EXECUTED_COLOR,
    REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    REACT_APP_PROGRESS_BAR_LOST_COLOR,
    REACT_APP_PROGRESS_BAR_EXPIRED_COLOR,
  } = useSelector(state => state?.Environment?.environment)

  const COLORS = {
    executed: REACT_APP_PROGRESS_BAR_EXECUTED_COLOR,
    inprogress: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    assignedAgentRework: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    inprogressReview: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    soldLimitSet: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    lostTimerReject: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    assignedClientSignDocs: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    assignedAgentAgreeLayout: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    assignedAgentInput: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    assignedClientApprove: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    assignedAgentAgreeParameters: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    layoutApprove: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    inprogressVerify: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    soldIssued: REACT_APP_PROGRESS_BAR_IN_PROGRESS_COLOR,
    lost: REACT_APP_PROGRESS_BAR_LOST_COLOR,
    lostBankReject: REACT_APP_PROGRESS_BAR_LOST_COLOR,
    lostClientReject: REACT_APP_PROGRESS_BAR_LOST_COLOR,
    expired: REACT_APP_PROGRESS_BAR_EXPIRED_COLOR,
  }
  
  const hasItems = items && items.length > 0

  const getContent = ({
    type,
    title,
    executionPercent,
    color,
    principalCompanyId,
    titleLeft,
    titleRight,
  }) => {
    const _color = COLORS[color]
    switch(type) {
      case 'money': return (
        <div className={classes.amount}>{title} руб.</div>
      )
      case 'minor': return (
        <div className={classes.minor}>{title}</div>
      )
      case 'executionPercent': return (
        <span className={classes.executionPercent}>
          <span style={{
            background: _color,
            width: `${executionPercent}%`,
          }}>
            <i style={{
              left: executionPercent >= 50 ? 'auto' : '100%',
              right: executionPercent >= 50 ? '-1px' : 'auto',
              borderColor: _color,
              // color: _color,
            }}>
              {executionPercent}%
            </i>
          </span>
        </span>
      )
      case 'principal': return (
        <Link 
          className={classes.principal} 
          to={`${entityUrl}?client-deal=${principalCompanyId}&section=home`} 
          target='_blank' 
          rel='noopener noreferrer'
        >
          {title}
        </Link>
      )
      case 'status': return (
        <span className={classes.status} style={{
          color: _color,
          borderColor: _color,
        }}>
          {title}
        </span>
      )
      case 'footer': return (
        <div className={classes.footer}>
          <div>{titleLeft}</div>
          <div>{titleRight}</div>
        </div>
      )
      default: return null
    }
  }

  if (!hasItems) {
    return null
  }

  const { color } = items?.find(({ type }) => type === 'status') || {}

  return (
    <div className={classes.root}>
      {isFetching 
        ? <Skeleton />
        : <div className={classes.wrapper}>
            <Link
              className={classes.title}
              to={`${entityUrl}/${id}`}
            >
              {title}
            </Link>
            {items.map((item, index) => {
              return (
                <Fragment key={index}>{getContent({...item, color})}</Fragment>
              )
            })}
          </div>
      }
    </div>
  )}

export default injectSheet(style)(Order)