import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { addCompanyAgentLogin } from '../../../../redux/Company/actions'
import { Row, InputText, UI } from '../../../../layout'
import classnames from 'classnames'

const styles = {
  title: UI.h1,
  actions: {
    marginTop: 30,
    textAlign: 'right',
  },
  add: UI.addButton,
  cancel: {
    ...UI.cancelButton,
    marginRight: 20,
  },
}

const AddAgent = ({
  classes,
  companyId,
  addCompanyAgentLogin,
  onCancel,
}) => {
  const [value, setValue] = useState('')

  const onChange = (e) => {
    const validateValue = (string = '') => string.replace(/[^A-Za-z0-9_.@-]/g, '')
    setValue(validateValue(e.target.value))
  }
  const onSubmit = (username) => addCompanyAgentLogin({ companyId, username })

  return (
    <>
      <div className={classes.title}>Новый логин агента</div>
      <Row>
        <div className='col-12'>
          <InputText
            type='text'
            value={value}
            onChange={onChange}
            autoFocus
          />
        </div>
      </Row>
      <div className={classes.actions}>
        <div className={classes.cancel} onClick={onCancel}>Отменить</div>
        <button 
          type='button'
          className={classnames(classes.add, 'btn-form--ok')} 
          onClick={() => onSubmit(value)}
          //disabled={!value}
        >
          Добавить
        </button>
      </div>
    </>
  )
}

const mapStateToProps = (store) => {
  return {}
}

const dispatchMapToProps = (dispatch, ownProps) => {
  return {
    addCompanyAgentLogin: (payload) => {
      dispatch(addCompanyAgentLogin(payload))
    },
  }
}

AddAgent.propTypes = {
  companyId: PropTypes.string.isRequired,
  addCompanyAgentLogin: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, dispatchMapToProps)(injectSheet(styles)(AddAgent))