import React, { useState } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Modal } from './../../index'
import Datepicker from './Datepicker'
import Item from './Item'
import Wrapper from './Wrapper'
import Scoring from './Scoring'

const styles = {
  scorings: {
    position: 'relative',
  },
  title: {
    paddingLeft: 70,
    paddingRight: 300,
  },
  isFactoring: {
    padding: 18,
  },
}

export const getScoringColorStyle = (negative) => {
  switch(negative) {
    case true:
      return { background: '#FFF2F1', color: '#F93433', border: '1px solid #FFABA4' } // red
    case false:
      return  { background: '#F7FEEE', color: '#55C31E', border: '1px solid #BBE996' } // green
    default:
      return { background: '#E8F8FF', color: '#1E9DFF', border: '1px solid #96D8FF' } // blue
  }
}

const Scorings = ({
  isFactoring,
  data,
  classes,
  title = 'Скоринги'
}) => {
  const initialState = {
    state: {},
    active: {},
  }
  const [state, setState] = useState(initialState.state)
  const [active, setActive] = useState(initialState.active)

  const onOpen = (code) => (url) => setState({
    ...state,
    [`${code}--${url}`]: !state[`${code}--${url}`],
  })

  const onOpenAll = (code) => (urls) => {
    let newState = {}
    urls.forEach(url =>
      newState[`${code}--${url}`] = !state[`${code}--${url}`]
    )
    setState({
      ...state,
      ...newState,
    })
  }

  const onChange = (id, index) => {
    setActive({
      ...active,
      [index]: id,
    })
  }

  if (isFactoring && data) {
    return (
      <Wrapper className={classnames({ [classes.isFactoring]: isFactoring })}>
        <Scoring
          name={data.name}
          code={data.key}
          value={data.value}
          negative={data.negative}
          formula={data.formula}
          scoringChilds={data.scoringChilds}
          onOpen={onOpen(data.key)}
          onOpenAll={onOpenAll(data.key)}
          state={state}
          open
        />
      </Wrapper>
    )
  }

  return data
    ? <Modal.Block iconName='bar-chart' title={title}>
        <div className={classes.scorings}>
          {data?.scoringTypes?.map(({ name, periods }, index) => {
            const id = active[index] || periods[0].id
            const items = data.scoringItems.filter(item => item.id === id)
            return items && items.length > 0
              ? <Item key={index}>
                  <Modal.Value className={classes.title}>{name}</Modal.Value>
                  <Datepicker
                    index={index}
                    items={periods}
                    onChange={onChange}
                  />
                  {items.map((item, index) =>
                    <Wrapper key={index}>
                      <Scoring
                        name={item.name}
                        code={item.key}
                        value={item.value}
                        negative={item.negative}
                        formula={item.formula}
                        scoringChilds={item.scoringChilds}
                        onOpen={onOpen(item.key)}
                        onOpenAll={onOpenAll(item.key)}
                        state={state}
                        open
                      />
                    </Wrapper>
                  )}
                </Item>
              : null
          })}
        </div>
      </Modal.Block>
    : null
}

export default injectSheet(styles)(Scorings)