import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    marginTop: 20,
    marginBottom: 0,
    tableLayout: 'fixed',
  },
}

const Table = ({
  classes,
  children,
  ...rest
}) => <table className={classes.root} {...rest}>{children}</table>
                
export default injectSheet(styles)(Table)