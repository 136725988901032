import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import { Row, Col4, Col6, Col12 } from '../../../../../../layout'
import { Block, Body, Fieldset, Header, InputText, Item, SubTitle, SwitchSlider } from '../../Layout'

export const CorpCard = ({
  date,
  enabled,
  orderNumber,
  sendToPhone,
  plugCashback,
}) => {
  const [open, setOpen] = useState(false)
  return (
    <Item>
      <Header
        name='Корпоративная карта'
        open={open}
        date={date}
        enabled={enabled}
        orderNumber={orderNumber}
        onCollapse={() => setOpen(!open)}
      />
      <Collapse isOpen={open}>
        <Body>
          <Block>
            <SubTitle>Держатель</SubTitle>
            <Fieldset>
              <Row>
                <Col4>
                  <InputText
                    label='Фамилия'
                    value={''}
                    disabled
                  />
                </Col4>
                <Col4>
                  <InputText
                    label='Имя'
                    value={''}
                    disabled
                  />
                </Col4>
                <Col4>
                  <InputText
                    label='Отчество'
                    value={''}
                    disabled
                  />
                </Col4>
              </Row>
            </Fieldset>
            <Fieldset>
              <Row>
                <Col6>
                  <InputText
                    label='Серия'
                    value={''}
                    disabled
                  />
                </Col6>
                <Col6>
                  <InputText
                    label='Номер'
                    value={''}
                    disabled
                  />
                </Col6>
              </Row>
            </Fieldset>
            <Fieldset>
              <Row>
                <Col6>
                  <InputText
                    label='Дата выдачи'
                    value={''}
                    disabled
                  />
                </Col6>
                <Col6>
                  <InputText
                    label='Код подразделения'
                    value={''}
                    disabled
                  />
                </Col6>
              </Row>
            </Fieldset>
            <Fieldset>
              <Row>
                <Col12>
                  <InputText
                    label='Кем выдан'
                    value={''}
                    disabled
                  />
                </Col12>
              </Row>
            </Fieldset>
            <Fieldset>
              <Row>
                <Col12>
                  <InputText
                    label='Телефон'
                    value={''}
                    disabled
                  />
                </Col12>
              </Row>
            </Fieldset>
            <Fieldset>
              <SwitchSlider
                id='sendToPhone'
                name='sendToPhone'
                label='Подключить услугу "СМС информирование по корпоративной карте"'
                checked={sendToPhone}
                disabled
              />
              <SwitchSlider
                id='plugCashback'
                name='plugCashback'
                label='Подключить услугу "Cashback"'
                checked={plugCashback}
                disabled
              />
            </Fieldset>
            <Fieldset>
              <Row>
                <Col12>
                  <InputText
                    label='Кодовое слова (только буквы на русском языке)'
                    value={''}
                    disabled
                  />
                </Col12>
              </Row>
            </Fieldset>
            <Fieldset>
              <Row>
                <Col12>
                  <InputText
                    label='Штрих-код от Welcomeback'
                    value={''}
                    disabled
                  />
                </Col12>
              </Row>
            </Fieldset>
          </Block>
        </Body>
      </Collapse>
    </Item>
  )
}