import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon } from './../../layout'

const ClearButton = ({ onClear, isHidden }) => 
  <button
    type='button'
    className={classnames('filter-input-cleaner', {
      'hidden': isHidden,
    })}
    onClick={onClear}
  >
    <Icon iconName='clear' />
  </button>

ClearButton.propTypes = {
  onClear: PropTypes.func.isRequired,
  isHidden: PropTypes.bool,
}

ClearButton.defaultProps = { 
  isHidden: true, 
}

export default ClearButton