import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  AddLimitForm,
  AddSupplyRegisterForm,
  AddRateForm,
  AddBlockForm,
  AddAccountForm,
  AddPaymentForm,
  AddOperationForm,
  AddFactoringContractForm,
} from '../Modals'
import { ButtonPlus } from '../Layout'

export const FactoringModal = ({
  entityType,
  parsedFilters,
  onGetFactoringView,
}) => {
  const [showModal, setShowModal] = useState(false)
  const onShowModal = () => setShowModal(true)
  const onCloseModal = () => setShowModal(false)
  const { factoring } = useSelector(state => state?.Settings || {})
  const { views = [] } = factoring || {}
  const { canEdit } = views?.find(item => item?.viewKey === entityType) || {}
  const modalProps = {
    onGetFactoringView,
    parsedFilters,
    onClose: onCloseModal,
  }
  const modals = {
    limit: <AddLimitForm {...modalProps} />,
    financing: <AddSupplyRegisterForm {...modalProps} />,
    rate: <AddRateForm {...modalProps} />,
    payment: <AddPaymentForm {...modalProps} />,
    block: <AddBlockForm {...modalProps} />,
    account: <AddAccountForm {...modalProps} />,
    operation: <AddOperationForm {...modalProps} />,
    factoring_contract: <AddFactoringContractForm {...modalProps} />,
  }
  return modals[entityType]
    ? <>
        <ButtonPlus onClick={onShowModal} disabled={!canEdit}>+</ButtonPlus>
        {showModal && modals[entityType]}
      </>
    : null
}