import * as actionTypes from './actionTypes'
import { Tariffs } from '../../services/api'
import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'

export const getBaseSchemaList = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_BASE_SCHEMA_LIST })
    const { isSuccess, ...res } = await Tariffs.getBaseSchemaList()
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ 
      type: actionTypes.GET_BASE_SCHEMA_LIST_SUCCESS, 
      data: res,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_BASE_SCHEMA_LIST_ERROR })
  }
}

export const getBaseSchema = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_BASE_SCHEMA })
    const { isSuccess, ...res } = await Tariffs.getBaseSchema(id)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ 
      type: actionTypes.GET_BASE_SCHEMA_SUCCESS, 
      data: res,
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_BASE_SCHEMA_ERROR })
  }
}

export const createBaseSchema = (payload, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CREATE_BASE_SCHEMA })
    const { isSuccess, ...res } = await Tariffs.createBaseSchema(payload)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ 
      type: actionTypes.CREATE_BASE_SCHEMA_SUCCESS, 
      data: res,
    })
    onSuccess && onSuccess()
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.CREATE_BASE_SCHEMA_ERROR })
  }
}

export const updateBaseSchema = (id, payload, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATE_BASE_SCHEMA })
    const { isSuccess, ...res } = await Tariffs.updateBaseSchema(id, payload)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ 
      type: actionTypes.UPDATE_BASE_SCHEMA_SUCCESS, 
      data: res,
    })
    onSuccess && onSuccess()
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.UPDATE_BASE_SCHEMA_ERROR })
  }
}

export const clearBaseSchema = () => (dispatch) => 
  dispatch({ type: actionTypes.CLEAR_BASE_SCHEMA })