import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { DateRangePicker } from 'react-date-range'
import { ru } from 'react-date-range/dist/locale'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import LoadingIcon from '../../static/img/loading.svg'

import { staticRanges, staticInputRanges } from './getStaticData'
import ClearButton from '../ClearButton'

class DatePicker extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    defaultActive: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    onSelectDate: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
  }
  static defaultProps = {
    defaultActive: {
      from: new Date(),
      to: new Date(),
    },
  }

  state = {
    focused: false,
    typing: false,
    showPicker: false,
    selectCount: 0,
    startDate: this.props.defaultActive.from,
    endDate: this.props.defaultActive.to,
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isFetching && !this.props.isFetching) {
      if (prevState.typing && !prevState.focused) {
        this.setState({ typing: false })
      }
    }

    if (this.props.hasReset !== prevProps.hasReset && this.props.hasReset === true) {
      this.onClearField()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onOutsideClick, false)
  }

  static prepareInputValue(stateFrom, stateTo, propsFrom, propsTo) {
    if (stateFrom !== propsFrom && stateFrom === stateTo) {
      return `${DatePicker.convertDateValue(stateFrom)}/`
    }
    if (stateFrom !== propsFrom && stateTo !== propsTo) {
      return `${DatePicker.convertDateValue(stateFrom)}/${DatePicker.convertDateValue(stateTo)}`
    }
    return ''
  }

  static convertDateValue(value) {
    const date = new Date(value)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')

    return `${day}.${month}.${date.getFullYear()}`
  }

  onSelect = (ranges) => {
    const {name} = this.props
    const values = ranges[name]
    const selectCount = values.startDate === values.endDate ? 1 : 2
    this.setState({
      startDate: values.startDate,
      endDate: values.endDate,
      selectCount,
      typing: true,
    }, this.onCheckEndSelectDate)
  }

  onCheckEndSelectDate = () => {
    const {selectCount} = this.state
    if (selectCount === 2) {
      this.onEndSelect()
    }
  }

  onEndSelect = () => {
    const {onSelectDate} = this.props
    const {selectCount} = this.state
    this.onHidePicker()
    this.setState({ focused: false })
    if (selectCount) {
      onSelectDate({ target: this.input })
    } else {
      this.onClearField()
    }
  }

  onFocusPicker = () => {
    document.addEventListener('click', this.onOutsideClick, false)
    const { selectCount } = this.state
    this.setState(Object.assign(
      {},
      { showPicker: true, focused: true },
      selectCount !== 1 ? {selectCount: 0} : {}
    ))
  }

  onHidePicker = () => {
    document.removeEventListener('click', this.onOutsideClick, false)
    this.setState({ showPicker: false })
  }

  onOutsideClick = (event) => {
    if (this.picker && this.picker.contains(event.target)) return
    this.onEndSelect()
  }

  onNativeType = () => ({})

  onClearField = () => {
    const { name, onClear } = this.props
    const { defaultActive } = DatePicker.defaultProps
    this.setState({
      selectCount: 0,
      startDate: defaultActive.from,
      endDate: defaultActive.to,
    })
    onClear(name, '')
  }

  render() {
    const {typing, selectCount, showPicker, startDate, endDate} = this.state
    const {isFetching, name, defaultActive} = this.props

    const value = selectCount
      ? DatePicker.prepareInputValue(startDate, endDate, defaultActive.from, defaultActive.to)
      : ''
    const range = {
      key: name,
      startDate,
      endDate,
    }

    return (
      <div
        className='filter-input__picker-wrap'
        ref={node => { this.picker = node }}
      >
        <div className='filter-input'>
          {isFetching && typing
            ? <img className='input-loader' src={LoadingIcon} alt='' />
            : <i className='icon icon-calendar' />
          }
          <input
            ref={node => { this.input = node }}
            type='text'
            className={cx('filter-input__input', {
              'filter-input__input--active': showPicker
            })}
            name={name}
            value={value}
            placeholder='Даты'
            autoComplete='off'
            onChange={this.onNativeType}
            onFocus={this.onFocusPicker}
          />
          <ClearButton
            onClear={this.onClearField}
            isHidden={!value.length}
          />
        </div>
        <div className={cx('filter-input__picker', {
          'filter-input__picker--show': showPicker,
        })}>
          <DateRangePicker
            locale={ru}
            staticRanges={staticRanges}
            inputRanges={staticInputRanges}
            color='#eb9a42'
            rangeColors={['#eb9a42', '#3ecf8e', '#fed14c']}
            ranges={[range]}
            moveRangeOnFirstSelection={false}
            onChange={this.onSelect}
          />
        </div>
      </div>
    )
  }
}

export default DatePicker